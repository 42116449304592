import LayerSelector from "./LayerSelector";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const mapStateToProps = (state) => {
  return {
    title: "Layer",
    subtitle: "Select which layer you are looking at from this program.",
    loading: false,
    layers: pricingSelectors.selectResolvedLayers(state),
    selectedId: rateChangeSlice.selectRateChange(state)?.activeLayerId ?? null,
  };
};

const mapDispatchToProps = {
  onSelectId: rateChangeSlice.updateRateChangeItem("activeLayerId"),
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerSelector);
