import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.docGen(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const docGenApi = createApi({
  reducerPath: "docGenApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    listTemplates: builder.query({
      query: () => ({
        url: "list-templates",
      }),
    }),
    fillTemplate: builder.mutation({
      queryFn: async ({
        templateId,
        templateForm,
        emailTargetId,
        content,
        filename,
      }) => {
        try {
          const make_endpoint = () => {
            if (templateForm === "WORD_JINJA") {
              return "fill-template";
            } else if (templateForm === "WORD") {
              return "fill-word-template";
            } else if (templateForm === "EXCEL") {
              return "fill-excel-template";
            } else {
              logger.error(`Unknown template form: ${templateForm}`);
              return "fill-template";
            }
          };

          const make_body = () => {
            if (templateForm === "WORD_JINJA") {
              return {
                templateId,
                emailTargetId,
                submission: content?.submission ?? {},
                layerId: content?.layerId,
              };
            } else {
              return {
                templateId,
                emailTargetId,
                data: {
                  content,
                },
              };
            }
          };

          const response = await utils.authenticatedFetch(
            config.endpoints.docGen(make_endpoint()),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(make_body()),
            }
          );
          const blob = await response.blob();
          FileSaver.saveAs(blob, filename);
          return { data: "succeeded" };
        } catch (e) {
          logger.exception(e);
          return {
            error: String(e),
          };
        }
      },
    }),
  }),
});

export const { useFillTemplateMutation, useListTemplatesQuery } = docGenApi;
