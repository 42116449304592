import Button from "./Button";
import ErrorIcon from "@mui/icons-material/Error";
import GetAppIcon from "@mui/icons-material/GetApp";
import { Tooltip } from "@mui/material";

const DownloadButton = ({
  hasError,
  error,
  onClick,
  isLoading = false,
  isDisabled = false,
  children,
}) => {
  return (
    <table>
      <tbody>
        <tr>
          <td style={{ padding: 0 }}>
            <Button
              onClick={onClick}
              isDisabled={isDisabled}
              isLoading={isLoading}
            >
              {children || (
                <>
                  <GetAppIcon /> {" Download"}
                </>
              )}
            </Button>
          </td>
          {hasError ? (
            <td style={{ padding: "0 0 0 4px" }}>
              <Tooltip title={error}>
                <ErrorIcon sx={{ verticalAlign: "middle" }} color={"error"} />
              </Tooltip>
            </td>
          ) : (
            <></>
          )}
        </tr>
      </tbody>
    </table>
  );
};

export default DownloadButton;
