import { Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useConfigQuery } from "services/staticDataService";

const FormattedDatePicker = ({
  value,
  onChangeDate,
  helperText,
  clearable = true,
  compact = false,
  ...datePickerProps
}) => {
  const { data: dateFormat } = useConfigQuery("date_format");
  const textFieldProps = compact
    ? {
        size: "small",
        inputProps: { sx: { maxWidth: "7rem" } },
        helperText,
      }
    : {
        variant: "filled",
        InputLabelProps: { shrink: true },
        helperText,
      };

  return (
    <Stack direction={"column"}>
      <DatePicker
        {...datePickerProps}
        slotProps={{
          field: {
            clearable,
          },
          textField: textFieldProps,
          actionBar: {
            actions: ["clear"],
          },
        }}
        format={dateFormat?.date_format ?? "YYYY-MM-DD"}
        value={(value || "") === "" ? null : dayjs(value)}
        onChange={(e) => {
          if (e == null) {
            onChangeDate(null);
          } else if (dayjs(e).isValid()) {
            onChangeDate(dayjs(e).format("YYYY-MM-DD"));
          }
        }}
      />
    </Stack>
  );
};

export default FormattedDatePicker;
