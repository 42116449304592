import PeerReview from "./review/PeerReview";
import ErrorDialog from "components/error/ErrorDialog";
import Dashboard from "components/layout/Dashboard";
import DashboardContent from "components/layout/DashboardContent";
import DashboardHeader from "components/layout/DashboardHeader";
import Review from "components/review/Review";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as userSelectors from "store/selectors/user/userSelectors";

const ReviewApp = () => {
  const userConfig = useSelector(userSelectors.selectUserConfig);

  const tabs = [
    {
      title: "Submission Status",
      path: "submissions",
      element: <Review />,
    },
    {
      title: "Peer Review",
      path: "peer-review",
      element: <PeerReview />,
    },
  ].filter(
    (tab) => userConfig?.componentVisibility?.[`review.${tab?.path}`] ?? true
  );

  return (
    <div className={"app"}>
      <ErrorDialog />
      <Dashboard>
        <DashboardHeader
          tabs={tabs}
          hideActiveSubmission
          isFullWidth
          topNavBorderless={true}
        />
        <DashboardContent isFullWidth>
          <Routes>
            {tabs.map(({ element, path }) => (
              <Route key={path} path={`${path}/*`} element={element} />
            ))}
            <Route
              path={"*"}
              element={<Navigate to={tabs[0].path} replace={true} />}
            />
          </Routes>
        </DashboardContent>
      </Dashboard>
    </div>
  );
};

export default ReviewApp;
