import * as ids from "common/ids";
import * as exposures from "domain/exposures";
import _ from "lodash";
import { createSelector } from "reselect";
import { getInceptionYear } from "store/selectors/input/program/programSelectors";

const getExtraKeys = (state) => state?.input?.exposure?.extraKeys ?? [];
const getRemovedKeys = (state) => state?.input?.exposure?.removedKeys ?? [];
const getCustomKeysRaw = (state) => state?.input?.exposure?.customKeys ?? [];
export const getCustomKeys = createSelector([getCustomKeysRaw], (customKeys) =>
  customKeys.filter((item) => !item.deleted)
);
export const getData = (state) => state?.input?.exposure?.data ?? {};
export const getDataYears = createSelector([getData], (data) =>
  Object.keys(data)
);
export const getExposureMetrics = (state) => state?.temp?.exposureMetrics ?? {};
const getAllExposureMetrics = createSelector(
  [getExposureMetrics],
  (exposureMetrics) => exposureMetrics.all || []
);
export const getExposureMetricsNotReady = createSelector(
  [getExposureMetrics],
  (exposureMetrics) =>
    exposureMetrics.loadingForIndustryClass ||
    exposureMetrics.loadingAll ||
    exposureMetrics.hadErrorLoadingForIndustryClass ||
    exposureMetrics.hadErrorLoadingAll
);
export const getExposureMetricsForIndustryClass = createSelector(
  [getExposureMetrics],
  (exposureMetrics) => exposureMetrics.forIndustryClass || []
);

export const isExposureListUpdating = createSelector(
  [getExposureMetrics],
  (exposureMetrics) => {
    return exposureMetrics.loadingList || exposureMetrics.loadingIndustryClass;
  }
);

export const isExposureListInError = createSelector(
  [getExposureMetrics],
  (exposureMetrics) => {
    return (
      exposureMetrics.listHasError || exposureMetrics.industryClassHasError
    );
  }
);

export const _combineForExposuresInAllSubmissionsList = (
  extraKeys,
  removedKeys,
  industryClass,
  exposureMetricsNotReady,
  all
) => {
  if (exposureMetricsNotReady) {
    return [];
  }
  const industryClassSet = new Set(industryClass);
  const removedKeysSet = new Set(removedKeys);
  const targetKeys = new Set([
    ...industryClass.filter((_) => !removedKeysSet.has(_)),
    ...extraKeys.filter((_) => !industryClassSet.has(_)),
  ]);
  return all.filter((_) => targetKeys.has(_.key));
};

export const getExposuresInAllSubmissionsList = createSelector(
  [
    getExtraKeys,
    getRemovedKeys,
    getExposureMetricsForIndustryClass,
    getExposureMetricsNotReady,
    getAllExposureMetrics,
  ],
  _combineForExposuresInAllSubmissionsList
);

export const getExposureList = createSelector(
  [getExposuresInAllSubmissionsList, getCustomKeys],
  (exposureList, customKeys) => {
    return [...exposureList, ...customKeys];
  }
);

export const getExposureToggleList = createSelector(
  [
    getExtraKeys,
    getRemovedKeys,
    getAllExposureMetrics,
    getExposureMetricsForIndustryClass,
  ],
  (extraKeys, removedKeys, all, forIndustryClass) => {
    return all.map((metric) => {
      if (forIndustryClass.includes(metric.key)) {
        return {
          ...metric,
          fromTemplate: true,
          selected: !removedKeys.includes(metric.key),
        };
      }
      return {
        ...metric,
        fromTemplate: false,
        selected: extraKeys.includes(metric.key),
      };
    });
  }
);

export const getCustomKeysToDisplay = createSelector(
  [getCustomKeys],
  (customKeys) => {
    return [
      ...customKeys.map((item) => ({ ...item, newExposure: false })),
      {
        name: "",
        units: "",
        key: `CUSTOM${ids.getUniqueKey()}`,
        newExposure: true,
      },
    ];
  }
);

export const getLatestTurnover = createSelector([getData], (data) =>
  exposures.latestTurnover(data)
);

const _getExposuresUsed = (data, exposureList) => {
  const usedKeys = exposures.extractAllKeys(data);
  return exposureList.filter((x) => usedKeys.has(x.key));
};

export const getExposuresInAllSubmissionsUsed = createSelector(
  [getData, getExposuresInAllSubmissionsList],
  _getExposuresUsed
);

export const getExposuresUsed = createSelector(
  [getData, getExposureList],
  _getExposuresUsed
);

export const selectExposureData = (state) => state.input?.exposure?.data ?? {};

export const getHazardProfile = (state) =>
  state.input?.exposure?.hazardProfile ?? null;

export const selectHazardProfileInput = createSelector(
  [getHazardProfile],
  (hazardProfile) => {
    return {
      files: hazardProfile?.files ?? [],
    };
  }
);

export const selectHazardProfileFiles = createSelector(
  [getHazardProfile],
  (hazardProfile) => hazardProfile?.files ?? []
);

export const getPremiumDeductible = (state) =>
  state.input?.exposure?.premDeduct ?? null;

export const selectPremiumDeductibleInput = createSelector(
  [getPremiumDeductible],
  (premiumDeductible) => {
    return {
      files: premiumDeductible?.files ?? [],
    };
  }
);

export const selectPremiumDeductibleFiles = createSelector(
  [getPremiumDeductible],
  (premiumDeductible) => premiumDeductible?.files ?? []
);

export const selectFiles = createSelector(
  [selectPremiumDeductibleInput, selectHazardProfileInput],
  (premiumDeductible, hazardProfile) => ({
    premiumDeductible,
    hazardProfile,
  })
);

export const getExposuresYears = createSelector(
  [getInceptionYear, getDataYears],
  (inceptionYear, years) => {
    if (inceptionYear == null && years.length === 0) {
      return [];
    }
    const minYear = Math.min(...years);
    const maxYear =
      inceptionYear != null ? inceptionYear + 1 : Math.max(...years);
    return _.range(maxYear, minYear - 1, -1);
  }
);

export const getExposuresYearsToDisplay = createSelector(
  [getInceptionYear, getExposuresYears],
  (inceptionYear, years) => {
    if (years.length === 0) {
      if (inceptionYear) {
        return [inceptionYear + 1, inceptionYear];
      }
      return [];
    }
    return [...years, years[years.length - 1] - 1];
  }
);

export const getExposuresLabels = createSelector(
  [getInceptionYear, getExposuresYearsToDisplay],
  (inceptionYear, years) => {
    return Object.fromEntries(
      years
        .filter((year) => year > inceptionYear)
        .map((year) => [year, ["projected"]])
    );
  }
);

export const selectIsShowingCommentFunction = (state) => {
  const commentVisibility = state?.input?.exposure?.commentVisibility ?? {};
  const defaultVisible = !!commentVisibility.defaultVisible;
  const exceptKeys = commentVisibility.exceptKeys ?? [];
  return (key) =>
    defaultVisible ? !exceptKeys.includes(key) : exceptKeys.includes(key);
};

export const selectLossCategories = createSelector(
  [getExposureMetrics],
  /**
   * @returns {{key: string, display: string}[]}
   */
  (exposureMetrics) => exposureMetrics?.lossCategories
);

export const getActiveFile = (state) => {
  return state?.input?.exposure?.hazardProfile?.activeFile || null;
};

export const getActiveFileValid = (state) => {
  return !!getActiveFile(state);
};

export const getHazardSheet = createSelector([getActiveFile], (activeFile) => {
  if (!activeFile || !activeFile.fileId) {
    return {};
  }
  const { fileId, activeSheet, topLeft, sheetSummaries } = activeFile;
  const sheet = sheetSummaries?.[activeSheet] || {};
  return {
    fileId,
    sheet,
    skipRows: topLeft?.row || 0,
    skipColumns: topLeft?.column || 0,
  };
});

export const selectHazardProfile = (state) =>
  state?.input?.exposure?.hazardProfile ?? null;

const addFixedStateMapping = (value) => {
  const conversions = [];
  conversions.push({
    conversion_type: "CONVERT_NULL_LIKE",
    inc_blank_strings: true,
    value,
  });
  conversions.push({
    conversion_type: "TO_STRING",
  });
  return {
    source: null,
    target: "_STATE",
    conversions,
  };
};

const addSicMapping = (source) => {
  const conversions = [];
  conversions.push({
    conversion_type: "TO_STRING",
  });
  conversions.push({
    conversion_type: "KEEP_CHARACTERS",
    characters: "0-9.",
  });
  conversions.push({
    conversion_type: "TO_INT",
    allow_null: true,
  });
  conversions.push({
    conversion_type: "NULL_LIKE_DELETE_ROW",
    inc_blank_strings: true,
  });
  return {
    source,
    target: "_SIC",
    conversions,
  };
};

const addFloatMapping = (source, target) => {
  return {
    source,
    target,
    conversions: [{ conversion_type: "TO_FLOAT" }],
  };
};

const addStringMapping = (source, target) => {
  return {
    source,
    target,
    conversions: [{ conversion_type: "TO_STRING" }],
  };
};

export const getColumns = (state) => getActiveFile(state)?.columns || [];

export const getColumnMappingSuggestions = (state) => {
  return getActiveFile(state)?.columnMappingSuggestions || {};
};

export const getColumnMapping = (state) =>
  getActiveFile(state)?.columnMapping || {};

export const getCompleteColumnMapping = (state) => {
  const resp = { ...getColumnMapping(state) };
  const suggestions = getColumnMappingSuggestions(state);
  for (const key in suggestions) {
    if (resp[key] === undefined) resp[key] = suggestions[key];
  }
  return resp;
};

export const getHazardTransformationRequest = createSelector(
  [getActiveFile, getHazardSheet, getCompleteColumnMapping],
  (activeFile, hazardSheet, completeColumnMapping) => {
    if (activeFile == null) {
      return activeFile;
    }
    const columnMapping = [];
    columnMapping.push(addSicMapping(completeColumnMapping["_SIC"]));
    columnMapping.push(
      addFloatMapping(completeColumnMapping["_PREMIUM"], "_PREMIUM")
    );

    const stateMapping = completeColumnMapping["_STATE"];
    if (stateMapping)
      columnMapping.push(addStringMapping(stateMapping, "_STATE"));
    else if (activeFile.state)
      columnMapping.push(addFixedStateMapping(activeFile.state));
    else throw Error("State not specified");

    return {
      fileId: activeFile.fileId,
      sheetName: hazardSheet.sheet.name,
      skipRows: hazardSheet.skipRows,
      skipColumns: hazardSheet.skipColumns,
      columnMapping,
    };
  }
);

export const selectResolvedExposure = createSelector(
  [selectExposureData],
  (data) => {
    return {
      data: data ?? {},
    };
  }
);
