export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_INSURED_NAME = "UPDATE_INSURED_NAME";
export const UPDATE_RISK_DESCRIPTION = "UPDATE_RISK_DESCRIPTION";
export const UPDATE_INCEPTION = "UPDATE_INCEPTION";
export const UPDATE_REFERENCE = "UPDATE_REFERENCE";
export const UPDATE_WHETHER_IN_US = "UPDATE_WHETHER_IN_US";
export const UPDATE_TURNOVER = "UPDATE_TURNOVER";
export const UPDATE_INDUSTRY_CLASS = "UPDATE_INDUSTRY_CLASS";
export const UPDATE_BROKERAGE = "UPDATE_BROKERAGE";
export const UPDATE_REQUESTED_BROKERAGE = "UPDATE_REQUESTED_BROKERAGE";
export const UPDATE_PROFIT = "UPDATE_PROFIT";
export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const UPDATE_EXPIRATION = "UPDATE_EXPIRATION";
export const UPDATE_PRO_RATA_ADJUSTMENT = "UPDATE_PRO_RATA_ADJUSTMENT";
export const UPDATE_OTHER_ACQUISITION_COSTS = "UPDATE_OTHER_ACQUISITION_COSTS";
export const UPDATE_INSURED_ADDRESS = "UPDATE_INSURED_ADDRESS";
export const UPDATE_PRIOR_SUBMISSION = "UPDATE_PRIOR_SUBMISSION";
export const UPDATE_CURRENCY = "UPDATE_CURRENCY";
export const UPDATE_REASSURED = "UPDATE_REASSURED";
export const UPDATE_PAYMENT_TERMS = "UPDATE_PAYMENT_TERMS";
export const ADD_DUNS = "ADD_DUNS";
export const REMOVE_DUNS = "REMOVE_DUNS";
export const CLEAR_COMPANY_NAICS = "CLEAR_COMPANY_NAICS";
export const UPDATE_PROGRAM_NOTES = "UPDATE_PROGRAM_NOTES";
export const UPDATE_RISK_SUMMARY = "UPDATE_RISK_SUMMARY";
export const UPDATE_DATA_QUALITY = "UPDATE_DATA_QUALITY";
export const UPDATE_MODEL = "UPDATE_MODEL";
export const UPDATE_STATE_OF_FILING = "UPDATE_STATE_OF_FILING";
