import ManualCatExposures from "./ManualCatExposures";
import SovCatExposures from "./SovCatExposures";

const CatExposures = () => {
  return (
    <>
      <SovCatExposures />
      <ManualCatExposures />
    </>
  );
};

export default CatExposures;
