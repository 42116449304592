import RootApp from "./RootApp";
import AmplifyAuthenticator from "./auth/AmplifyAuthenticator";
import CognitoHostedUiAuthenticator from "./auth/CognitoHostedUiAuthenticator";
import DefaultLoader from "./common/DefaultLoader";
import OfflineBanner from "./common/OfflineBanner";
import { Amplify } from "aws-amplify";
import * as config from "config";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as userActions from "store/actions/user/userActions";
import * as userReducer from "store/reducers/user/userReducer";
import * as userSelectors from "store/selectors/user/userSelectors";

const Loader = () => (
  <DefaultLoader
    color={"#dc7f4c"}
    size={100}
    style={{
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  />
);

const Content = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.getUserAttributes());
  }, [dispatch]);

  const userAttributesStatus = useSelector(
    (state) => userSelectors.selectUser(state).attributesStatus
  );

  if (userAttributesStatus === userReducer.ATTRIBUTES_STATUS.SUCCEEDED) {
    return <RootApp />;
  } else {
    return (
      <>
        <Loader />
        <OfflineBanner />
      </>
    );
  }
};

const AmplifyAuthenticatedApp = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolClientId: config.COGNITO_USER_POOL_CLIENT_ID,
        loginWith: {
          email: true,
          phone: false,
          username: false,
        },
        passwordFormat: {
          minLength: 16,
          requireLowercase: true,
          requireUppercase: true,
          requireNumbers: true,
          requireSpecialCharacters: false,
        },
      },
    },
  });
  return (
    <AmplifyAuthenticator>
      <Content />
    </AmplifyAuthenticator>
  );
};

const CognitoHostedUiAuthenticatedApp = ({
  userPoolClientId,
  domain,
  redirectUrl,
}) => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolClientId,
        loginWith: {
          oauth: {
            domain: domain,
            scopes: ["openid", "email", "profile"],
            redirectSignIn: [redirectUrl],
            redirectSignOut: [redirectUrl],
            responseType: "code",
          },
        },
      },
    },
  });
  return (
    <CognitoHostedUiAuthenticator>
      <Content />
    </CognitoHostedUiAuthenticator>
  );
};

const AuthenticatedApp = () => {
  const {
    data: authConfig,
    isLoading: isAuthConfigLoading,
  } = staticDataService.useRetrieveAuthConfigQuery();

  if (isAuthConfigLoading) {
    return <Loader />;
  } else if (authConfig?.useCognitoHostedUi) {
    return (
      <CognitoHostedUiAuthenticatedApp
        userPoolClientId={authConfig?.cognitoHostedUiClientId}
        domain={authConfig?.cognitoHostedUiDomain}
        redirectUrl={authConfig?.cognitoHostedUiRedirectUrl}
      />
    );
  } else {
    return <AmplifyAuthenticatedApp />;
  }
};

const FeatureFlaggedAuthenticatedApp = () => {
  if (!config.DISABLE_COGNITO_HOSTED_UI) {
    return <AuthenticatedApp />;
  } else {
    return <AmplifyAuthenticatedApp />;
  }
};

export default FeatureFlaggedAuthenticatedApp;
