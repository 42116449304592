import CompanyConsole from "./CompanyConsole";
import GlobalConsole from "./GlobalConsole";
import { Navigate, Route, Routes } from "react-router-dom";

const Console = () => {
  return (
    <Routes>
      <Route path={"global/*"} element={<GlobalConsole />} />
      <Route path={":companyId/*"} element={<CompanyConsole />} />
      <Route path={"/"} element={<Navigate to={"global"} replace={true} />} />
    </Routes>
  );
};

export default Console;
