import * as peersActions from "../../../store/actions/peers/peersActions";
import * as peersSelectors from "../../../store/selectors/peers/peersSelectors";
import * as utils from "../../../utils";
import Component from "../../Component";
import DefaultLoader from "../../common/DefaultLoader";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Disabled from "components/common/Disabled";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as submissionsService from "services/submissionsService";

const IndustryClassComponent = (props) => {
  return (
    <>
      {props.loadingIndustryClasses ? (
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            width: "100%",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          size={60}
        />
      ) : (
        <FormControl variant={"filled"} fullWidth>
          <InputLabel className={"input-label"}>{"Industry Class"}</InputLabel>
          <Disabled ifReadOnly>
            <Select
              id={"industry-class-selector"}
              value={props.industryClass}
              onChange={(event) =>
                props.updateIndustryClass(event.target.value)
              }
              inputProps={{
                name: "program-inputs-industry-class",
                id: "program-inputs-industry-class",
              }}
              name={"industry-class"}
              className={"program-inputs-industry-class"}
            >
              <MenuItem key={`Industry_Class_NULL`} value={"NO_SELECTION"} />
              {props.industryClasses.map((industryClass) => {
                return (
                  <MenuItem
                    key={`Industry_Class_${industryClass.key}`}
                    value={industryClass.key}
                  >
                    {industryClass.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Disabled>
        </FormControl>
      )}
    </>
  );
};

const PeerIndustryClass = connect(
  (state) => {
    return {
      industryClass:
        peersSelectors.getPeerIndustryClass(state) || "NO_SELECTION",
      industryClasses: state.temp.exposureMetrics?.industryClassChoices || [],
      loadingIndustryClasses:
        state.temp.exposureMetrics?.loadingIndustryClasses,
    };
  },
  {
    updateIndustryClass: peersActions.updateIndustryClass,
  }
)(IndustryClassComponent);

export const compareStringItems = (item) => (a, b) =>
  String(a?.[item] ?? "").localeCompare(String(b?.[item] ?? ""));

export const filterPeers = (submissions, { industryClass, existingPeers }) => {
  const existingPeerFilenames = existingPeers.map((p) => p.filename);
  return Array.from(submissions ?? [])
    .filter((s) => s.industryClass === industryClass)
    .filter((s) => !existingPeerFilenames.includes(s.filename))
    .toSorted(
      (a, b) =>
        -compareStringItems("inception")(a, b) ||
        -compareStringItems("savedAt")(a, b) ||
        compareStringItems("submissionId")(a, b)
    )
    .splice(0, 25);
};

const IndustryClassPeers = ({ peerIndustryClass, peers, addPeer }) => {
  const {
    data: submissions,
    refetch: refetchSubmissions,
  } = submissionsService.useListSubmissionsQuery();

  useEffect(() => {
    refetchSubmissions();
  }, [refetchSubmissions]);

  const availablePeers = filterPeers(submissions ?? [], {
    industryClass: peerIndustryClass,
    existingPeers: peers,
  });

  return (
    <Component
      title={"Industry Class Peers"}
      subtitle={
        "The following recent deals have the same Industry Class as this deal."
      }
      xs_width={12}
      lg_width={6}
    >
      <Box mb={2}>
        <PeerIndustryClass />
      </Box>
      {availablePeers.length > 0 ? (
        <TableContainer style={{ maxHeight: 450 }}>
          <Table stickyHeader className={"peers-table"}>
            <TableBody>
              {availablePeers.map((submission) => (
                <TableRow
                  key={submission.submissionId}
                  xs={12}
                  className={"search-row"}
                  onClick={() => addPeer(submission)}
                >
                  <TableCell className={"search-cell search-result-insured"}>
                    <span>{submission.insured}</span>
                  </TableCell>
                  <TableCell className={"search-cell search-result-inception"}>
                    <span>{utils.formatDate(submission.inception)}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span>{"No Industry Class peers available to add."}</span>
      )}
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    peerIndustryClass: peersSelectors.getPeerIndustryClass(state),
    peers: peersSelectors.getPeers(state),
  };
};

const mapDispatchToProps = {
  addPeer: peersActions.loadPeer,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndustryClassPeers);
