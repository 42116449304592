import * as dates from "common/dates";
import * as numbers from "common/numbers";
import { detailedDiff } from "deep-object-diff";
import { latestTurnover, exposureSeries } from "domain/exposures";
import * as layerResolvers from "domain/layerResolvers";
import * as pricing from "domain/pricing";
import * as pricingResolvers from "domain/pricingResolvers";
import { createSelector } from "reselect";
import * as layerDefaultsStore from "store/layerDefaults";
import * as lossDevelopmentStore from "store/lossDevelopment";
import * as towers from "store/selectors/common/towers";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as exposuresSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as flagsSelectors from "store/selectors/input/flags/flagsSelectors";
import * as inputSelectors from "store/selectors/input/inputSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const getBaseLayer = (state) => state.pricing.baseLayer;
const getExpectedLosses = (state) => state.pricing.baseLayer?.expectedLosses;
export const getActiveLayerIndex = (state) => state.pricing.activeLayer;
const getTowerPricingScalingLayerIndex = (state) =>
  state.pricing.towerPricingScalingLayer;
const getUnderwriterSelectedNumberOfClaims = (state) =>
  state.pricing.frequency?.underwriterSelectedNumberOfClaims;
const getDefaultAverageNumberOfClaims = (state) =>
  state.pricing.frequency?.averageNumberOfClaims?.[0]?.claims;
const getImprovexWeight = (state) => state.pricing.improvexWeight;
const getImprovexClaims = (state) => state.pricing.frequency?.improvex?.claims;
const getInsuredWeight = (state) => state.pricing.insuredWeight;
export const getTowerRaw = (state) => state?.pricing?.layers || [];
const selectSelectedExposureMetricKey = (state) =>
  state.pricing?.selectedExposureMetric?.key;

const selectExposureSeries = createSelector(
  [exposuresSelectors.getData, selectSelectedExposureMetricKey],
  (data, key) => exposureSeries(data, key)
);

const selectLatestTurnover = createSelector(
  [exposuresSelectors.getData],
  (data) => latestTurnover(data)
);

export const getTower = createSelector(
  [
    getTowerRaw,
    programSelectors.selectResolvedProgram,
    claimsSelectors.getClaims,
    peopleSelectors.selectResolvedPeople,
    layerDefaultsStore.select,
  ],
  (layers, program, claims, people, layerDefaults) =>
    towers
      .fillAttachmentInLayers(layers)
      .map((layer) =>
        layerResolvers.resolveLayer(layer, {
          program,
          claims,
          people,
          layerDefaults,
        })
      )
      .map((layer) => ({
        ...layer,
        uwSelectedMeasure: pricing.calculatePricePerspectives({
          layer,
          price: pricing.extractLayerSelectedPrice({ layer }),
        }),
      }))
);

export const selectSimpleBaseLayer = createSelector(
  [getBaseLayer],
  (baseLayer) => ({
    limit: baseLayer.limit,
    attachment: baseLayer.attachment,
  })
);

export const selectClaimsDateRange = (state) => ({
  start: state.pricing?.claimsDateRange?.start,
  end: state.pricing?.claimsDateRange?.end,
});

/**
 * @returns {string[]?}
 */
export const selectClaimsIncludedLossTypes = (state) =>
  state.pricing?.claimsIncludedLossTypes ?? null;

export const selectPricingClaims = createSelector(
  [
    claimsSelectors.selectInputClaims,
    selectClaimsDateRange,
    selectClaimsIncludedLossTypes,
  ],
  (claimsQuery, claimsDateRange, claimsIncludedLossTypes) => {
    return {
      ...claimsQuery,
      start: claimsDateRange.start,
      end: claimsDateRange.end,
      includedLossTypes: claimsIncludedLossTypes,
    };
  }
);

export const selectRecalculatedInsuredExpectedEventsRequest = createSelector(
  [
    selectExposureSeries,
    programSelectors.getTurnoverCategory,
    selectSimpleBaseLayer,
    programSelectors.getPricingNaicsCode,
    selectLatestTurnover,
    programSelectors.getInUs,
    selectPricingClaims,
  ],
  (
    exposureSeries,
    turnoverCategory,
    baseLayer,
    naicsCode,
    currentTurnover,
    in_US,
    claimsQuery
  ) => {
    if (naicsCode == null) {
      return {
        error: {
          title: "MISSING_NAICS_CODE",
          message: "A NAICS Code is required.",
        },
      };
    }
    if (baseLayer.limit == null || baseLayer.attachment == null) {
      return {
        error: {
          title: "MISSING_BASE_LAYER",
          message: "A base layer is required.",
        },
      };
    }
    return {
      averagesOverLastYears: [6, 3],
      exposureSeries,
      turnoverCategory,
      baseLayer,
      naicsCode,
      currentTurnover,
      in_US,
      claimsQuery,
    };
  }
);

export const selectRecalculatedInsuredExpectedEventsRequestDiff = createSelector(
  [
    selectRecalculatedInsuredExpectedEventsRequest,
    (state) => state.pricing?.frequency?.request,
  ],
  (prospective, previous) => detailedDiff(prospective, previous)
);

export const getTowerToDisplay = createSelector(
  [getTower],
  towers.addEmptyLayerWithAttachment
);

export const getActiveLayer = createSelector(
  [getTower, getActiveLayerIndex],
  (tower, activeLayer) => tower[activeLayer]
);

export const getTowerPricingScalingLayer = createSelector(
  [getTower, getTowerPricingScalingLayerIndex],
  (tower, scalingLayer) => tower[scalingLayer]
);

const getInsuredClaims = createSelector(
  [getUnderwriterSelectedNumberOfClaims, getDefaultAverageNumberOfClaims],
  (underwriterSelectedNumberOfClaims, defaultAverageNumberOfClaims) => {
    return underwriterSelectedNumberOfClaims || defaultAverageNumberOfClaims;
  }
);

export const getBlendedExpected = createSelector(
  [getImprovexWeight, getInsuredWeight, getImprovexClaims, getInsuredClaims],
  (improvexWeight, insuredWeight, improvexClaims, insuredClaims) => {
    if (improvexWeight === 100) {
      return improvexClaims;
    }
    if (insuredWeight === 100) {
      return insuredClaims;
    }
    if (
      [improvexWeight, insuredWeight, improvexClaims, insuredClaims].some(
        (a) => a === undefined
      )
    ) {
      return undefined;
    }
    return (
      (improvexWeight * improvexClaims + insuredWeight * insuredClaims) / 100
    );
  }
);

export const getNumberOfLossesForPricingPretty = createSelector(
  [getExpectedLosses, getBlendedExpected],
  (expectedLosses, blendedExpectedLosses) => {
    if (
      expectedLosses === undefined ||
      expectedLosses === null ||
      expectedLosses === ""
    ) {
      if (blendedExpectedLosses) {
        return numbers.sfString(blendedExpectedLosses, 3);
      }
    }

    return expectedLosses
      ? expectedLosses.toLocaleString("en-US", { maximumFractionDigits: 10 })
      : expectedLosses;
  }
);

export const getNumberOfLossesForPricingNumber = createSelector(
  [getExpectedLosses, getBlendedExpected],
  (expectedLosses, blendedExpectedLosses) => {
    if (
      expectedLosses === undefined ||
      expectedLosses === null ||
      expectedLosses === ""
    ) {
      return blendedExpectedLosses;
    }
    return expectedLosses;
  }
);

export const purePremiumRequest = createSelector(
  [
    selectPricingClaims,
    inputSelectors.ilfQuery,
    getActiveLayer,
    getBaseLayer,
    getNumberOfLossesForPricingNumber,
    flagsSelectors.getJudgmentFactors,
  ],
  (claimsQuery, ilfQuery, layer, baseLayer, expectedLosses, judgments) => ({
    claimsQuery: {
      start: dates.validatedDateStr(baseLayer?.from),
      end: dates.validatedDateStr(baseLayer?.to),
      ...claimsQuery,
    },
    ilfQuery,
    layer: {
      limit: layer.limit,
      attachment: layer.attachment,
    },
    baseLayer: {
      limit: baseLayer.limit,
      attachment: baseLayer.attachment,
    },
    expectedLosses,
    judgments,
  })
);

const getRateChange = (state) => state.rateChange;

const getManualAdjustmentsRaw = createSelector(
  [getRateChange],
  (rateChange) => rateChange?.manualAdjustments || []
);

const getManualAdjustmentFactors = createSelector(
  [getManualAdjustmentsRaw],
  (manualAdjustments) =>
    manualAdjustments
      .map((x) => x.value || "0")
      .filter((x) => x !== "" && x !== "-")
);

export const getManualAdjustments = createSelector(
  [getManualAdjustmentsRaw],
  (manualAdjustments) => {
    return [
      ...manualAdjustments.map((manualAdjustment) => ({
        value: "",
        comments: "",
        canDelete: true,
        ...manualAdjustment,
      })),
      { value: "", comments: "", canDelete: false },
    ];
  }
);

export const getManualAdjustmentFactor = createSelector(
  [getManualAdjustmentFactors],
  (manualAdjustments) =>
    manualAdjustments
      .map((x) => (100 + parseFloat(x)) / 100)
      .reduce((a, b) => a * b, 1)
);

export const getManualAdjustmentFactorPretty = createSelector(
  [getManualAdjustmentFactor],
  (manualAdjustmentFactor) => (manualAdjustmentFactor || 0) * 100 - 100
);

export const selectLayers = (state) => state.pricing?.layers ?? [];

export const selectResolvedLayers = stateSelectors.createSelector(
  [
    selectLayers,
    programSelectors.selectResolvedProgram,
    claimsSelectors.getClaims,
    peopleSelectors.selectResolvedPeople,
    layerDefaultsStore.select,
  ],
  (layers, program, claims, people, layerDefaults) =>
    towers
      .fillAttachmentInLayers(layers)
      .map((layer) =>
        layerResolvers.resolveLayer(layer, {
          program,
          claims,
          people,
          layerDefaults,
        })
      )
      .map((layer) => ({
        ...layer,
        uwSelectedMeasure: pricing.calculatePricePerspectives({
          layer,
          price: pricing.extractLayerSelectedPrice({ layer }),
        }),
      }))
);

export const selectActiveLayerIndex = (state) => state.pricing?.activeLayer;

export const selectResolvedActiveLayer = stateSelectors.createSelector(
  [selectResolvedLayers, selectActiveLayerIndex],
  (layers, activeLayerIndex) => layers[activeLayerIndex]
);

export const selectBaseLayer = (state) => state.pricing?.baseLayer ?? {};

export const selectResolvedPricingInputs = stateSelectors.createSelector(
  [(state) => state, lossDevelopmentStore.selectUltimateSummary],
  (state, ultimateSummary) => {
    const pricingInputs = pricingResolvers.resolvePricingInputs(state.pricing);
    if ("burningCost" in pricingInputs) {
      pricingInputs.burningCost.exposure =
        state?.temp?.exposure?.hazardSummary?.value?.states?.flatMap(
          (stateProfile) =>
            stateProfile?.profile?.map((groupProfile) => ({
              state: stateProfile?.state ?? "Unknown",
              hazardGroup: groupProfile?.hazardGroup ?? "Unknown",
              split: groupProfile?.premium ?? 0.0,
            }))
        ) ?? [];
    }
    return {
      ...pricingInputs,
      burningCost: {
        ...pricingInputs.burningCost,
        groundUpLossRatio:
          pricingInputs.burningCost?.manualGroundUpLossRatio ??
          ultimateSummary?.lossRatio?.selection?.value,
      },
    };
  }
);

export const selectPricingContext = createSelector(
  [
    programSelectors.selectResolvedProgram,
    selectResolvedLayers,
    selectPricingClaims,
    exposuresSelectors.selectExposureData,
    flagsSelectors.selectUWJudgments,
    selectResolvedPricingInputs,
    exposuresSelectors.selectFiles,
    selectActiveLayerIndex,
    (_, context = {}) => context,
  ],
  (
    program,
    layers,
    claimsQuery,
    exposures,
    uwJudgments,
    pricingInputs,
    files,
    activeLayerIndex,
    context
  ) => {
    const layerIndex = context.layerId
      ? layers.findIndex((layer) => layer.id === context.layerId)
      : activeLayerIndex;
    return {
      program,
      layer: layers[layerIndex],
      claimsQuery,
      exposures,
      uwJudgments,
      pricingInputs,
      files,
    };
  }
);

export const selectActiveLayerPricingSelectedModel = createSelector(
  [selectResolvedActiveLayer],
  (layer) => {
    return layer?.pricing?.selectedModel ?? null;
  }
);

export const selectActiveLayerPricingSelectedModelConfig = createSelector(
  [
    staticDataSelectors.selectAnalyticsConfig,
    selectActiveLayerPricingSelectedModel,
  ],
  (analyticsConfig, model) => {
    return (
      (analyticsConfig?.layerPricing?.models ?? []).filter(
        (modelConfig) => modelConfig.key === model
      )[0] ?? null
    );
  }
);

export const selectActiveLayerPrices = stateSelectors.createSelector(
  [selectActiveLayerPricingSelectedModelConfig, selectResolvedActiveLayer],
  (modelConfig, layer) => {
    if (modelConfig == null) {
      return {};
    }
    return Object.fromEntries(
      modelConfig.measures.map((measureConfig) => [
        measureConfig.key,
        pricing.calculatePricePerspectives({
          layer,
          price: pricing.extractLayerPrice({
            layer,
            model: modelConfig.key,
            measure: measureConfig.key,
          }),
        }),
      ])
    );
  }
);

export const selectActiveLayerSelectedPrice = stateSelectors.createSelector(
  [selectActiveLayerPricingSelectedModel, selectResolvedActiveLayer],
  (model, layer) => {
    return pricing.calculatePricePerspectives({
      layer,
      price: pricing.extractLayerSelectedPrice({ layer, model }),
    });
  }
);

export const formatGrossPremiumChartData = createSelector(
  [
    selectActiveLayerPricingSelectedModelConfig,
    selectActiveLayerPrices,
    selectActiveLayerSelectedPrice,
  ],
  (modelConfig, prices, selectedPrice) => {
    if (modelConfig == null) {
      return [
        {
          label: "",
          value: 0,
        },
      ];
    }
    return [
      ...modelConfig.measures
        .filter((measureConfig) => !measureConfig.excludeFromPricingChart)
        .map((measureConfig) => ({
          label: measureConfig.name,
          value: prices[measureConfig.key]?.requiredPremium || 0,
        })),
      {
        label: modelConfig.selectedPrice?.name ?? "Selected Price",
        value: selectedPrice.requiredPremium || 0,
      },
    ];
  }
);

export const getChartMaximum = createSelector(
  [formatGrossPremiumChartData, getActiveLayer],
  (chartData, layer) => {
    return numbers.roundUpNice(
      Math.max(5e-6, ...chartData.map((x) => x.value), layer?.grossPremium ?? 0)
    );
  }
);

export const getAssumptions = (state) => ({
  ...staticDataSelectors.pricingAssumptionsDefVals(state),
  ...(state.pricing?.assumptions || {}),
});
