import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import * as numbers from "common/numbers";
import DefaultLoader from "components/common/DefaultLoader";
import * as config from "config";
import { connect } from "react-redux";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

const ordered = (map, marmaladeDisplay) => {
  const orderedList = [];
  for (const k in map) {
    const mValue = map[k];
    if (typeof mValue === "object") {
      for (const v of mValue) {
        if (marmaladeDisplay && v in marmaladeDisplay)
          orderedList.push({ spreadsheet: k, marmalade: marmaladeDisplay[v] });
        else orderedList.push({ spreadsheet: k, marmalade: v });
      }
    } else {
      if (marmaladeDisplay && mValue in marmaladeDisplay)
        orderedList.push({
          spreadsheet: k,
          marmalade: marmaladeDisplay[mValue],
        });
      else orderedList.push({ spreadsheet: k, marmalade: mValue });
    }
  }
  orderedList.sort((a, b) =>
    a.spreadsheet < b.spreadsheet ? -1 : a.spreadsheet > b.spreadsheet ? +1 : 0
  );
  return orderedList;
};

const invertMap = (mapin) => {
  const mapout = {};
  for (const k in mapin)
    mapout[mapin[k]] = mapout[mapin[k]]
      ? mapout[mapin[k]][0] === mapin[k]
        ? [k]
        : [...mapout[mapin[k]], k]
      : [k];
  return mapout;
};

const keyDispToMap = (keyDisp) => {
  const mapout = {};
  keyDisp?.forEach((e) => (mapout[e.key] = e.display));
  return mapout;
};

const mappingTable = (title, map) => {
  return (
    <Table
      sx={{ border: "1px solid lightgray", margin: "5px", width: "330px" }}
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={"2"}>{title}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{" Spreadsheet "}</TableCell>
          <TableCell>{" Marmalade "}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map.map((e, i) => {
          return (
            <TableRow key={i}>
              <TableCell>{e.spreadsheet}</TableCell>
              <TableCell>{e.marmalade}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

const AppliedExcessTable = ({ appliedExcess }) => {
  return (
    <Table
      sx={{ border: "1px solid lightgray", margin: "5px", width: "330px" }}
    >
      <TableHead>
        <TableRow>
          <TableCell colSpan={"2"}>{"Applied Excess"}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!!appliedExcess?.__DEFAULT__ ? (
          <>
            <TableRow>
              <TableCell>{"Excess"}</TableCell>
              <TableCell>
                {numbers.abbreviated(appliedExcess.__DEFAULT__.excess)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{"Is removed from reported loss?"}</TableCell>
              <TableCell>
                {appliedExcess.__DEFAULT__["is-excess-removed"] ? "Yes" : "No"}
              </TableCell>
            </TableRow>
          </>
        ) : (
          <TableRow>
            <TableCell colSpan={2}>{"No excess recorded."}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const ClaimsMapping = ({ claimsDisplayMapping, targets, lossCategories }) => {
  const data = claimsDisplayMapping.data || {};
  const state = claimsDisplayMapping.state;

  const targetMap = keyDispToMap(targets);

  const spSheetMap = invertMap(data["column-mapping"] || {});

  const lossCategoryMap = keyDispToMap(lossCategories);

  const columnMapping = ordered(spSheetMap, targetMap);
  const coverageMapping = ordered(data["coverage-mapping"] || {}, {
    ...lossCategoryMap,
    [config.CLAIMS_MAPPING_IGNORE_VALUE]: "N/A",
  });
  const openClosedMapping = ordered(data["open-closed-mapping"] || {}, {
    C: "Closed",
    O: "Open",
    [config.CLAIMS_MAPPING_IGNORE_VALUE]: "N/A",
  });

  return claimsDisplayMapping.claimsKey == null ? (
    ""
  ) : state === "FAILED" ? (
    <center>
      {"Column name translations are not available at the moment."}
    </center>
  ) : state === "LOADING" ? (
    <div>
      <DefaultLoader
        color={"#dc7f4c"}
        variant={"contained"}
        width={"50px"}
        height={"20px"}
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      />
    </div>
  ) : (
    <>
      <TableContainer
        sx={{ width: "48%", display: "inline-block", verticalAlign: "top" }}
        size={"small"}
        padding={"none"}
      >
        {mappingTable("Spreadsheet column name translations", columnMapping)}
      </TableContainer>
      <TableContainer
        sx={{ width: "48%", display: "inline-block", verticalAlign: "top" }}
        size={"small"}
        padding={"none"}
      >
        {mappingTable("Open/closed value translations", openClosedMapping)}
        {mappingTable("Coverage value translations", coverageMapping)}
        <AppliedExcessTable appliedExcess={data["applied-excess"]} />
      </TableContainer>
    </>
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state) => ({
  claimsDisplayMapping: claimsSelectors.selectClaimsFileColumnMappingDisplay(
    state
  ),
  targets: claimsSelectors.selectTargetClaimsColumns(state),
  lossCategories: exposureSelectors.selectLossCategories(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsMapping);
