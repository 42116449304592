import LevelledScenarios from "./LevelledScenarios";
import ValuedScenarios from "./ValuedScenarios";
import Notes from "components/common/Notes";
import Visible from "components/common/Visible";
import { connect } from "react-redux";
import * as scenariosActions from "store/actions/input/scenarios/scenariosActions";

export const ScenariosComponent = ({ note, updateNote }) => {
  return (
    <>
      <Visible
        byTag={"input.scenarios.levelledScenarios"}
        defaultVisible={true}
      >
        <LevelledScenarios />
      </Visible>
      <Visible byTag={"input.scenarios.valuedScenarios"} defaultVisible={false}>
        <ValuedScenarios />
      </Visible>
      <Visible byTag={"input.scenarios.notes"} defaultVisible={true}>
        <Notes sm_width={12} lg_width={6} update={updateNote} value={note} />
      </Visible>
    </>
  );
};

const mapDispatchToProps = {
  updateNote: scenariosActions.updateScenariosNote,
};

const mapStateToProps = (state) => {
  return {
    note: state.input.scenarios?.note || "",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosComponent);
