import * as config from "../../../../config";
import * as utils from "../../../../utils";
import * as types from "../../../actions/temp/images/imagesActionTypes";
import * as actions from "../../../actions/temp/images/imagesActions";
import * as logger from "common/logger";
import { call, put, select, takeLeading } from "redux-saga/effects";

export function* cacheImage(action) {
  try {
    const { submissionId, imageId, imageType } = action.payload;
    const cachedURL = yield select(
      (state) => state?.temp?.images?.[submissionId]?.[imageId]
    );
    if (cachedURL != null) {
      return;
    }
    const result = yield call(
      utils.authenticatedFetch,
      config.endpoints.persistence(
        "image?" + new URLSearchParams({ submissionId, imageId, imageType })
      )
    );
    const blob = yield call([result, "blob"]);
    const url = URL.createObjectURL(blob);
    yield put(actions.imageCached(submissionId, imageId, url));
  } catch (e) {
    logger.exception(e);
  }
}

export default function* imagesSaga() {
  yield takeLeading(types.CACHE_IMAGE, cacheImage);
}
