import * as logger from "common/logger";
import * as config from "config";
import { put, select, takeLatest } from "redux-saga/effects";
import * as types from "store/actions/peers/peersActionTypes";
import * as actions from "store/actions/peers/peersActions";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as utils from "utils";

function* _updatePeers_claimsImpl(action) {
  yield put(actions.updatingPeerPricing());
  const result = yield utils.authenticatedFetch(
    config.endpoints.claims("pricing/peer-pricing"),
    { method: "post", body: JSON.stringify(action.payload) }
  );
  const data = yield result.json();
  yield put(actions.receivedPeerPricing(data));
}

function* _updatePeers_analyticsImpl(action) {
  yield put(actions.updatingPeerPricing());
  const analyticsConfig = yield select(
    staticDataSelectors.selectAnalyticsConfig
  );
  const state = yield select(persistenceSelectors.selectPersistedState);
  const result = yield utils.authenticatedFetch(
    config.endpoints.analytics("calculate-peer-analytics"),
    {
      method: "post",
      body: JSON.stringify({
        engine: analyticsConfig?.peerPricing?.engines,
        exposure: state?.peers?.exposureMetric?.key,
        submission: { state },
      }),
    }
  );
  const data = yield result.json();
  if (data.failure) {
    logger.error(`Peer pricing failed: ${data.failure}`);
    yield put(actions.peerPricingFailed());
    return;
  }
  yield put(
    actions.receivedPeerPricing({
      ...data,
      request: action.payload,
    })
  );
}

export function* updatePeers(action) {
  try {
    const analyticsConfig = yield select(
      staticDataSelectors.selectAnalyticsConfig
    );

    const backend = analyticsConfig?.peerPricing?.backend ?? "claims";

    if (backend === "analytics") {
      yield* _updatePeers_analyticsImpl(action);
    } else {
      yield* _updatePeers_claimsImpl(action);
    }
  } catch (e) {
    logger.exception(e);
    yield put(actions.peerPricingFailed());
  }
}

export default function* peersSaga() {
  yield takeLatest(types.RECALCULATE_PEER_PRICING, updatePeers);
}
