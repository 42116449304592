import { Stack, Paper, Typography, Tooltip } from "@mui/material";
import * as dates from "common/dates";
import Component from "components/Component";
import { useSelector } from "react-redux";
import * as submissionsService from "services/submissionsService";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const AllocationCard = ({ allocation }) => {
  return (
    <Paper sx={{ padding: 2, width: 500 }}>
      <Stack direction={"column"} spacing={1}>
        <Tooltip title={allocation?.createTime}>
          <Typography>
            {dates.formatDateTime(allocation?.createTime)}
          </Typography>
        </Tooltip>
        <Stack direction={"row"} spacing={0.5}>
          <Typography>
            <b>{"Allocated To:"}</b>
          </Typography>
          <Typography>{allocation?.recipientEmail}</Typography>
        </Stack>
        <Stack direction={"row"} spacing={0.5}>
          <Typography>
            <b>{"Allocated By:"}</b>
          </Typography>
          <Typography>{allocation?.senderEmail}</Typography>
        </Stack>
        <Typography>{allocation?.comments}</Typography>
      </Stack>
    </Paper>
  );
};

const Allocations = () => {
  const submissionId = useSelector(persistenceSelectors.selectSubmissionId);

  const {
    data: allocations,
    isLoading: isAllocationLoading,
  } = submissionsService.useListAllocationsQuery(
    { submissionId: submissionId },
    { skip: !submissionId }
  );

  const sortedAllocations = [...(allocations ?? [])].sort((a, b) =>
    (b?.createTime ?? "_").localeCompare(a?.createTime ?? "_")
  );

  return (
    <Component title={"Allocations"} isFetching={isAllocationLoading}>
      <Stack direction={"column"} alignItems={"start"} spacing={2}>
        {sortedAllocations.map((allocation) => (
          <AllocationCard key={allocation.id} allocation={allocation} />
        ))}
      </Stack>
    </Component>
  );
};

export default Allocations;
