import Grid from "@mui/material/Grid";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import NumberTextField from "components/common/NumberTextField";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { connect, useDispatch, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as pricingActionTypes from "store/actions/pricing/pricingActionTypes";
import * as pricingActions from "store/actions/pricing/pricingActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const useRecommendedBaseLayerQuery = () => {
  const claimsQuery = useDebouncedValue(
    useSelector(pricingSelectors.selectPricingClaims)
  );
  const {
    currentData,
    isFetching,
    isError,
  } = claimsService.useRecommendedBaseLayerQuery(claimsQuery);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetching) {
      dispatch({
        type: pricingActionTypes.UPDATE_RECOMMENDED_BASE_LAYER,
        payload: isError ? null : currentData,
      });
    }
  }, [dispatch, currentData, isError, isFetching]);

  return { isFetching, isError, errorMessage: null };
};

const HelperText = ({ value }) => {
  const { isFetching, isError, errorMessage } = useRecommendedBaseLayerQuery();

  if (isError) {
    return <span>{errorMessage || "Error"}</span>;
  }
  if (isFetching) {
    return (
      <>
        <span>{"Recommended:"}</span>
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            display: "inline-block",
            position: "relative",
            top: "4px",
            left: "6px",
          }}
          size={16}
        />
      </>
    );
  }
  return value != null ? (
    <NumberFormat
      displayType={"text"}
      thousandSeparator={true}
      prefix={"Recommended: "}
      value={value}
      decimalScale={0}
    />
  ) : (
    <span>{"Recommended:"}</span>
  );
};

const BaseLayer = (props) => {
  return (
    <Component
      title={"Pricing Parameters"}
      subtitle={
        "Adjust the base layer terms, claims date range and underwriter selected losses for pricing."
      }
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Base Layer Limit"}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
            fullWidth
            onChangeNumber={props.updateBaseLayerLimit}
            value={props.baseLayer?.limit ?? null}
            helperText={
              <HelperText value={props.recommendedBaseLayer?.limit} />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Base Layer Attachment"}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
            fullWidth
            onChangeNumber={props.updateBaseLayerAttachment}
            value={props.baseLayer?.attachment ?? null}
            helperText={
              <HelperText value={props.recommendedBaseLayer?.attachment} />
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormattedDatePicker
            label={"From"}
            onChangeDate={props.updateBaseLayerFrom}
            value={props.baseLayer?.from || ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <FormattedDatePicker
            label={"To"}
            onChangeDate={props.updateBaseLayerTo}
            value={props.baseLayer?.to || ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <NumberTextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            label={"Losses for Pricing"}
            variant={"filled"}
            autoComplete={"off"}
            onChange={props.updateBaseLayerExpectedNumberOfBlendedLosses}
            value={props.expectedLosses ?? ""}
            fullWidth
            data-testid={"losses-for-pricing"}
          />
        </Grid>
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {
  updateBaseLayerLimit: pricingActions.updateBaseLayerLimit,
  updateBaseLayerAttachment: pricingActions.updateBaseLayerAttachment,
  updateBaseLayerFrom: pricingActions.updateBaseLayerFrom,
  updateBaseLayerTo: pricingActions.updateBaseLayerTo,
  updateBaseLayerExpectedNumberOfBlendedLosses:
    pricingActions.updateBaseLayerExpectedNumberOfBlendedLosses,
};

const mapStateToProps = (state) => {
  return {
    baseLayer: state?.pricing?.baseLayer ?? null,
    recommendedBaseLayer: state?.pricing?.recommendedBaseLayer ?? null,
    expectedLosses: pricingSelectors.getNumberOfLossesForPricingPretty(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseLayer);
