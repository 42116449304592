import { Autocomplete, TextField } from "@mui/material";
import * as dates from "common/dates";
import { useEffect, useState } from "react";
import * as submissionsService from "services/submissionsService";

export const compareStringItems = (item) => (a, b) =>
  String(a?.[item] ?? "").localeCompare(String(b?.[item] ?? ""));

export const descending = (comparison) => -comparison;

export const makeQueryMatcher = (searchQuery) => {
  if (!searchQuery) {
    return () => true;
  }

  const searchTokens = searchQuery.trim().toLowerCase().split(/\W+/);

  const matchableFields = [
    "insured",
    "riskDescription",
    "inception",
    "reference",
  ];

  return (submission) => {
    const values = matchableFields.map((field) =>
      String(submission?.[field] ?? "").toLowerCase()
    );
    return searchTokens.every((token) =>
      values.some((value) => value.includes(token))
    );
  };
};

export const filterSubmissions = (submissions, { query }) => {
  const matchesQuery = makeQueryMatcher(query);
  return Array.from(submissions ?? [])
    .filter(matchesQuery)
    .toSorted(
      (a, b) =>
        descending(compareStringItems("inception")(a, b)) ||
        descending(compareStringItems("savedAt")(a, b)) ||
        compareStringItems("submissionId")(a, b)
    )
    .splice(0, 50);
};

const SubmissionSelector = ({
  label,
  textFieldInputProps,
  excludeFilenames,
  onSelect,
}) => {
  const {
    data: allSubmissions,
    refetch: refetchSubmissions,
  } = submissionsService.useListSubmissionsQuery();

  useEffect(() => {
    refetchSubmissions();
  }, [refetchSubmissions]);

  const submissions = excludeFilenames
    ? allSubmissions?.filter((s) => !excludeFilenames.includes(s.filename)) ??
      []
    : allSubmissions ?? [];

  const makeSubmissionName = (submission) => {
    const inception = dates.formatDate(submission?.inception);
    return !!inception
      ? `${submission?.insured} (${inception})`
      : submission?.insured ?? "<Unkown>";
  };

  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      value={null}
      inputValue={inputValue}
      onInputChange={(_, value) => setInputValue(value)}
      onChange={(_, value, reason) => {
        if (reason === "selectOption") {
          onSelect(value);
          setInputValue("");
        }
      }}
      options={submissions}
      getOptionLabel={makeSubmissionName}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option?.filename}>
            {makeSubmissionName(option)}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          type={"text"}
          label={label}
          data-testid={`submission-selector-${label}`}
          variant={"filled"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
            ...textFieldInputProps,
          }}
          fullWidth
        />
      )}
      filterOptions={(options, { inputValue }) =>
        filterSubmissions(options, { query: inputValue })
      }
      noOptionsText={"No matching submissions"}
      sx={{
        "& .MuiFilledInput-root": {
          paddingRight: "8px!important",
        },
      }}
      fullWidth
    />
  );
};

export default SubmissionSelector;
