import * as fileUtils from "../../../fileUtils";
import Disabled from "../../common/Disabled";
import IconButton from "../../common/IconButton";
import SmallLoader from "../../common/SmallLoader";
import EditableFilename from "./EditableFilename";
import FileListTreeItem from "./FileListTreeItem";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/GetApp";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import { TreeItem } from "@mui/x-tree-view";
import Visible from "components/common/Visible";
import React, { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { BsFolder2 } from "react-icons/bs";

const Blank = () => <div style={{ width: "40px" }}>&nbsp;</div>;
export const DRAG_N_DROP_TYPE_DIR = "DIR";
export const DRAG_N_DROP_TYPE_FILE = "FILE";
export const DeleteDirButton = ({ dirSupport }) => {
  if (!dirSupport.canDelete()) return <Blank />;
  return (
    <Disabled ifReadOnly>
      <IconButton
        icon={DeleteIcon}
        onClick={(e) => {
          e.stopPropagation();
          dirSupport.deleteDirectory();
        }}
      />
    </Disabled>
  );
};

export const AddDirectory = ({ dirSupport }) => {
  if (!dirSupport.canAddDirectory()) return <Blank />;
  return (
    <Disabled ifReadOnly>
      <IconButton
        icon={CreateNewFolderIcon}
        onClick={(e) => {
          e.stopPropagation();
          dirSupport.addDirectory();
        }}
      />
    </Disabled>
  );
};

const DownloadDir = ({ dirSupport }) => {
  const [isLoading, setIsLoading] = useState(0);
  class counter {
    constructor(num) {
      this.count = num;
      setIsLoading(num);
    }
    dec() {
      this.count -= 1;
      setIsLoading(this.count);
    }
  }
  const doDownload = async () => {
    const cnt = new counter(Object.keys(dirSupport.getFiles()).length);

    dirSupport.getFiles().forEach((file) => {
      fileUtils
        .saveFile(file.getFileId(), file.filename())
        .then(() => cnt.dec())
        .catch(() => cnt.dec());
    });
  };
  return (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <Disabled ifReadOnly>
        <IconButton
          icon={DownloadIcon}
          onClick={(e) => {
            e.stopPropagation();
            doDownload(e);
          }}
          isDisabled={isLoading > 0}
        />
      </Disabled>
    </div>
  );
};
const DownloadDirButton = ({ dirSupport }) => {
  return (
    <Disabled ifReadOnly>
      {dirSupport.noFiles() ? (
        <Blank />
      ) : dirSupport.areFilesLoading() ? (
        <SmallLoader />
      ) : (
        <DownloadDir dirSupport={dirSupport} />
      )}
    </Disabled>
  );
};

const DirListTreeItem = ({ dirSupport, selected, setSelected }) => {
  const [isEditing, setEditing] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [dragProps, dragRef] = useDrag({
    type: DRAG_N_DROP_TYPE_DIR,
    item: () => ({ type: DRAG_N_DROP_TYPE_DIR, dirId: dirSupport.getId() }),
    canDrag: !isEditing,
    end: (item, monitor) => {
      const dropRes = monitor.getDropResult();
      if (dropRes) {
        const selectedIds = selected.map((support) => support.getUniqueId());
        if (selectedIds.includes(dirSupport.getUniqueId())) {
          for (const currentSupport of selected) {
            if (currentSupport.isDir) {
              currentSupport.moveDir(dropRes.dir);
            } else {
              if (dropRes.type === DRAG_N_DROP_TYPE_DIR)
                currentSupport.moveFileToDirectory(dropRes.dir);
              else {
                currentSupport.moveFileToFile(dropRes.dir);
              }
            }
          }
        } else {
          dirSupport.moveDir(dropRes.dir);
        }
      }
      setSelected([]);
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [dropProps, dropRef] = useDrop({
    accept: [DRAG_N_DROP_TYPE_DIR, DRAG_N_DROP_TYPE_FILE],
    drop: (item, monitor) => {
      return {
        type: DRAG_N_DROP_TYPE_DIR,
        dir: dirSupport.getId(),
        name: dirSupport.getName(),
      };
    },
    canDrop: (item, monitor) => {
      if (item.type === DRAG_N_DROP_TYPE_FILE) return true;
      return dirSupport.canDropOnMe(item.dirId);
    },
    collect: (monitor) => {
      return {
        highlighted: monitor.canDrop() && monitor.isOver(),
      };
    },
  });
  const dirButtons = dirSupport.hasCreateDirButtons()
    ? dirSupport.createDirButtons()
    : [
        <AddDirectory dirSupport={dirSupport} key={"add-button"} />,
        <DeleteDirButton dirSupport={dirSupport} key={"del-button"} />,
        <DownloadDirButton
          dirSupport={dirSupport}
          key={"download-dir-button"}
        />,
      ];
  const dropStyleProps = {};
  if (dropProps.highlighted) dropStyleProps.border = "4px solid orange";
  if (dirSupport.isRoot()) dropStyleProps.paddingRight = "10px";

  const dragFix = (el) => {
    el?.addEventListener("focusin", (e) => e.stopImmediatePropagation());
  };

  const dragDropRef = (el) => {
    if (!dirSupport.isRoot()) dragRef(el);
    dropRef(el);
  };

  const dir = (
    <Box
      style={dropStyleProps}
      sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}
    >
      <div
        ref={dragDropRef}
        style={{ flexGrow: "2", display: "flex", flexDirection: "row" }}
      >
        <Box sx={{ mr: 1 }}>
          <Avatar
            variant={"rounded"}
            sx={{
              background: "transparent",
              width: "30px",
              height: "30px",
            }}
          >
            <BsFolder2
              style={{
                color: "black",
                background: "transparent",
                border: "white",
              }}
            />
          </Avatar>
        </Box>
        <EditableFilename
          value={dirSupport.getName() || ""}
          extension={""}
          onSubmit={(newName) => dirSupport.setDirectoryName(newName)}
          id={`editable-dir-name-${dirSupport.getId()}`}
          style={{ fontWeight: "inherit", flexGrow: 1 }}
          uploaded={true}
          onEdit={() => {
            setEditing(true);
          }}
          onClose={() => {
            setEditing(false);
          }}
        />
      </div>
      {dirButtons}
    </Box>
  );
  const children = (
    <>
      {dirSupport.getDirectories().map((dir, index) => (
        <DirListTreeItem
          key={`dir-${dir.id}-${index}`}
          selected={selected}
          setSelected={setSelected}
          dirSupport={dir}
        />
      ))}
      {dirSupport.getFiles().map((file, index) => {
        return (
          <FileListTreeItem
            selected={selected}
            setSelected={setSelected}
            support={file}
            createFileButtons={dirSupport.getCreateFileButtons()}
            key={`File-${file.getFileId()}-${index}`}
          />
        );
      })}
    </>
  );
  if (dirSupport.isRoot())
    return (
      <>
        <Visible byTag={"<Attachments>.rootFolder"}>{dir}</Visible>
        {children}
      </>
    );
  return (
    <TreeItem
      ref={dragFix}
      key={dirSupport.getUniqueId()}
      nodeId={dirSupport.getUniqueId()}
      label={dir}
    >
      {children}
    </TreeItem>
  );
};

export default DirListTreeItem;
