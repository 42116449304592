import { v4 as uuidv4 } from "uuid";

export const getUniqueKey = () => {
  return "_" + uuidv4().toUpperCase().replace(/-/g, "_");
};

export const getFilename = () => {
  return `${uuidv4()}.json`;
};

export const makeNewId = () => uuidv4();
