import {
  ChevronRightRounded as ExpandIcon,
  ExpandMoreRounded as CollapseIcon,
} from "@mui/icons-material";
import { Box, Collapse, Stack } from "@mui/material";
import { toSentenceCase } from "common/strings";
import DetailsByScenario from "components/scenarios/DetailsByScenario";
import ScenarioMetrics from "components/scenarios/ScenarioMetrics";
import { findIndex } from "lodash";
import { useState } from "react";

const sortByScenarioAndLevel = (scenarios, scenarioLabels) => {
  const copy = JSON.parse(JSON.stringify(scenarios));

  return copy.sort((a, b) => {
    const scenarioA =
      scenarioLabels[a.scenario_key] || toSentenceCase(a.scenario_key);
    const scenarioB =
      scenarioLabels[b.scenario_key] || toSentenceCase(b.scenario_key);
    return (
      scenarioA.localeCompare(scenarioB) ||
      b.scenario_level - a.scenario_level ||
      b.insured.localeCompare(a.insured)
    );
  });
};

const Summary = ({ scenarios, scenarioLabels, scenarioCategories }) => {
  const [expandedScenarios, setExpandedScenarios] = useState([]);
  const data = sortByScenarioAndLevel(scenarios, scenarioLabels);
  let prevScenario = null;

  const toggleExpanded = (key) => {
    const idx = findIndex(expandedScenarios, (x) => x === key);
    idx === -1
      ? setExpandedScenarios((prev) => [...prev, key])
      : setExpandedScenarios((prev) => prev.filter((_, i) => i !== idx));
  };

  return (
    <>
      {data.map((row, index) => {
        const key = row.scenario_key;
        const matchesPrev = key === prevScenario;
        const isExpanded = !matchesPrev && expandedScenarios.includes(key);
        prevScenario = key;

        return (
          !matchesPrev && (
            <div key={`SUMMARY_${index}`} style={{ marginTop: "1rem" }}>
              <Stack
                direction={"row"}
                sx={{
                  padding: "0 0.5rem",
                  color: "secondary.dark",
                  userSelect: "none",
                  cursor: "pointer",
                }}
                onClick={() => toggleExpanded(key)}
              >
                <b>{scenarioLabels[key] || toSentenceCase(key)}</b>
                {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
              </Stack>
              <Box
                sx={{
                  marginTop: "0.375rem",
                  padding: "0.625rem 0.375rem",
                  background: "#edf0f3",
                  borderRadius: "0.625rem",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#e1e4e7",
                  },
                }}
                onClick={() => toggleExpanded(key)}
              >
                <ScenarioMetrics
                  scenarios={data.filter((x) => x.scenario_key === key)}
                  categories={scenarioCategories}
                  aggregateMode={true}
                />
              </Box>
              <Collapse in={isExpanded}>
                <DetailsByScenario
                  scenarios={data.filter((x) => x.scenario_key === key)}
                  scenarioLabels={scenarioLabels}
                  aggregateMode={true}
                />
              </Collapse>
            </div>
          )
        );
      })}
    </>
  );
};

export default Summary;
