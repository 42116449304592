import Grid from "@mui/material/Grid";
import * as chartUtils from "chartUtils";
import Component from "components/Component";
import DocumentsIssued from "components/common/DocumentsIssued";
import Notes from "components/common/Notes";
import Visible from "components/common/Visible";
import ActiveLayerEditor from "components/layers/ActiveLayerEditor";
import ActiveLayerPremiumAdjustmentsEditor from "components/layers/ActiveLayerPremiumAdjustmentsEditor";
import ActiveLayerSelector from "components/layers/ActiveLayerSelector";
import BaseLayer from "components/pricing/layerPricing/BaseLayer";
import LayerPricingChart from "components/pricing/layerPricing/LayerPricingChart";
import PricingTable from "components/pricing/layerPricing/PricingTable";
import UpdateLossFrequency from "components/pricing/lossFrequency/UpdateLossFrequency";
import * as config from "config";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const LayerPricing = (props) => {
  return (
    <>
      <Visible byTag={"pricing.layerPricing.updateLossFrequency"}>
        <UpdateLossFrequency isCompact />
      </Visible>
      <Grid item xs={12} md={4}>
        <Grid container spacing={3} direction={"column"}>
          <Visible byTag={"pricing.layerPricing.baseLayer"}>
            <BaseLayer />
          </Visible>
          <ActiveLayerEditor
            title={"Pricing Inputs"}
            subtitle={"The terms of the selected layer that is being priced."}
            error={props.layer?.hasError}
          />
          <Visible
            byTag={"pricing.layerPricing.documentsIssued"}
            defaultVisible={true}
          >
            <DocumentsIssued layer={props.layer} />
          </Visible>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <Grid container spacing={config.GRID_SPACING} direction={"column"}>
          <Component
            title={`Pricing Results for $${chartUtils.tickFormatter(
              props.layer?.limit
            )} xs $${chartUtils.tickFormatter(props.layer?.attachment)} Layer`}
          >
            <PricingTable />
          </Component>
          <LayerPricingChart />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <ActiveLayerSelector />
      </Grid>
      <Visible byTag={"pricing.layerPricing.premiumAdjustments"}>
        <Grid item xs={12}>
          <ActiveLayerPremiumAdjustmentsEditor />
        </Grid>
      </Visible>
      <Notes update={props.updateNote} value={props.note} />
    </>
  );
};

export const mapDispatchToProps = {
  updateNote: pricingActions.updateLayerPricingNote,
};

export const mapStateToProps = (state) => {
  return {
    layer: pricingSelectors.getActiveLayer(state),
    note: state.pricing.note || "",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerPricing);
