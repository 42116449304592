import * as _ from "lodash";

export const extractColumnMatchStateFromQuery = ({ query, columnKey }) => {
  if (_.includes(query?.requiredMatchColumns, columnKey)) {
    return "REQUIRED";
  } else if (_.includes(query?.matchColumns, columnKey)) {
    return "CONSIDERED";
  } else {
    return "IGNORED";
  }
};

export const setColumnMatchState = ({ query, columnKey, state }) => {
  const sanitize = (list) => (list ?? []).filter((x) => x !== columnKey);

  return {
    ...query,
    requiredMatchColumns: [
      ...sanitize(query?.requiredMatchColumns),
      ...(state === "REQUIRED" ? [columnKey] : []),
    ],
    matchColumns: [
      ...sanitize(query?.matchColumns),
      ...(state === "CONSIDERED" ? [columnKey] : []),
    ],
  };
};
