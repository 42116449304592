import * as config from "../../../config";
import * as utils from "../../../utils";
import * as types from "./userActionTypes";
import * as logger from "common/logger";

export const getUserAttributes = (withAttributes) => (dispatch) => {
  dispatch({
    type: types.LOAD_USER_ATTRIBUTES_STARTED,
  });
  utils
    .authenticatedFetch(config.endpoints.user("attributes"))
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: types.LOAD_USER_ATTRIBUTES_RETURNED,
        payload: data,
      });
      if (withAttributes) {
        withAttributes(data);
      }
    })
    .catch((error) => {
      logger.exception(error);
    });
};

export const logoutUser = () => ({
  type: types.LOGOUT_USER,
});
