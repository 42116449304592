import { createAction } from "@reduxjs/toolkit";

export const updateValue = createAction(
  "programTower/updateValue",
  ({ layerIndex, key, value }) => {
    return {
      payload: {
        layerIndex,
        key,
        value,
      },
    };
  }
);

export const deleteLayer = createAction(
  "programTower/deleteLayer",
  ({ layerIndex }) => {
    return {
      payload: {
        layerIndex,
      },
    };
  }
);
