import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import classnames from "classnames";
import SheetPreviewCell from "components/inputs/claims/SheetPreviewCell";
import React from "react";
import Spreadsheet from "react-spreadsheet";
import * as utils from "utils";

const convForSpreadsheet = (sheetSummaries, sheetNumber) => {
  const data = sheetSummaries[sheetNumber].data;
  const summaryData = [];
  for (var rowInd = 0; rowInd < data.length; rowInd++) {
    const row = [];
    for (var colInd = 0; colInd < data[rowInd].length; colInd++) {
      row.push({
        value: data[rowInd][colInd],
        row: rowInd,
        col: colInd,
      });
    }
    summaryData.push(row);
  }
  return summaryData;
};
const SheetSelector = ({
  name,
  sheetSummaries,
  setActiveSheet,
  activeSheet,
  sheetTopLeftSelected,
  topLeft,
}) => {
  const HighlightedDataViewer = ({ row, column, data, rootRef }) => {
    const active = topLeft && topLeft.row === row && topLeft.column === column;

    const onBorder =
      topLeft &&
      ((topLeft.row === row && topLeft.column < column) ||
        (topLeft.column === column && topLeft.row < row));

    const inside = topLeft && topLeft.row < row && topLeft.column < column;

    return (
      <td
        ref={rootRef}
        className={classnames(
          "sheet-preview-cell",
          active && "sheet-preview-cell-active",
          onBorder && "sheet-preview-cell-border",
          inside && "sheet-preview-cell-content",
          data && data.className
        )}
        tabIndex={0}
        onMouseDown={() => sheetTopLeftSelected({ row, column })}
      >
        <span>{data?.value || ""}</span>
      </td>
    );
  };
  return (
    <>
      <DialogContentText>{"Select the required sheet."}</DialogContentText>
      <form className={"form"} noValidate>
        <FormControl
          margin={"normal"}
          className={"sheet-select-form"}
          variant={"filled"}
          fullWidth
        >
          <InputLabel>{"Selected sheet"}</InputLabel>
          <Select
            autoFocus
            value={activeSheet}
            onChange={(e) => setActiveSheet(e.target.value)}
            inputProps={{
              name,
              id: name.replace(" ", "-"),
            }}
            className={"claims-sheet-select-select"}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {sheetSummaries.map((sheet, index) => (
              <MenuItem key={index} value={index}>
                {`${sheet.name} (${sheet.rows} rows)`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
      {sheetSummaries[activeSheet] && (
        <>
          <DialogContentText>
            {`Select the starting location for this table. Currently: ${utils.getCellName(
              topLeft
            )}`}
          </DialogContentText>
          <Spreadsheet
            data={convForSpreadsheet(sheetSummaries, activeSheet)}
            onActivate={(points) => sheetTopLeftSelected(points)}
            Cell={SheetPreviewCell}
            DataViewer={HighlightedDataViewer}
          />
        </>
      )}
    </>
  );
};

export default SheetSelector;
