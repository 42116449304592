import { FormControlLabel, FormGroup, Stack, Switch } from "@mui/material";
import { useEffect } from "react";

const BooleanInput = ({ defn, options, setOptions }) => {
  useEffect(() => {
    if (options[defn.name] == null) {
      setOptions({
        ...options,
        [defn.name]: false,
      });
    }
  });

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              value={options[defn.name]}
              onChange={(event) =>
                setOptions({
                  ...options,
                  [defn.name]: event.target.checked,
                })
              }
            />
          }
          label={defn.label}
        />
      </FormGroup>
    </Stack>
  );
};

export default BooleanInput;
