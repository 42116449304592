import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import { connect } from "react-redux";
import * as printActions from "store/actions/print/printActions";

const PrintSettingsDialog = ({ open, onClose, ...props }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{"Settings"}</DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.elementVisibility.exposureComments ?? false}
                onChange={(_) => {
                  props.toggleElementVisibility("exposureComments");
                }}
              />
            }
            label={"Show Exposure Notes"}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={onClose}>
          {"OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    elementVisibility: state.print.elementVisibility,
  };
};

const mapDispatchToProps = {
  toggleElementVisibility: printActions.toggleElementVisibility,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintSettingsDialog);
