import { tabularMimeTypes } from "../../../fileUtils";
import * as selectors from "../../../store/selectors/input/exposure/premDeductSelectors";
import FilesFileUpload from "components/FilesFileUpload";
import Notes from "components/common/Notes";
import PremDeductDownloader from "components/inputs/premDeduct/PremDeductDownloader";
import PremDeductFileUploadDialog from "components/inputs/premDeduct/PremDeductFileUploadDialog";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as premDeductActions from "store/actions/input/exposure/premDeductActions";

export const PremDeductComponent = ({ note, updateNote }) => {
  const [sheetsDialogOpen, setSheetsDialogOpen] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [downloadFileId, setDownloadFileId] = useState(null);

  const startDownloading = () => {
    setDownloadStatus({ status: "LOADING" });
    setSheetsDialogOpen(true);
    setDownloadFileId(null);
  };
  const startDownloaded = (fileId) => {
    setDownloadStatus({ status: "LOADED" });
    setDownloadFileId(fileId);
  };
  const onDrop = async (fileId) => {
    try {
      startDownloading();
      startDownloaded(fileId);
    } catch (error) {
      setDownloadStatus({ status: "ERROR", msg: "Cannot load file" });
    }
  };

  const uploadComplete = () => {
    setSheetsDialogOpen(false);
    setDownloadStatus(null);
    setDownloadFileId(null);
  };

  return (
    <>
      <PremDeductFileUploadDialog
        {...{
          error: downloadStatus?.status === "ERROR" ? downloadStatus.msg : null,
          fileId: downloadFileId,
          open: sheetsDialogOpen,
          uploadComplete,
        }}
      />
      <FilesFileUpload
        onDrop={onDrop}
        size={{ md: 4 }}
        asAtDateNotRequired={true}
        oneAtATime={true}
        waitText={true}
        mimeTypes={tabularMimeTypes}
      />
      <PremDeductDownloader />
      <Notes md_width={12} lg_width={6} update={updateNote} value={note} />
    </>
  );
};

const mapDispatchToProps = {
  updateNote: premDeductActions.updatePremDeductNote,
};

const mapStateToProps = (state) => ({
  files: selectors.files(state),
  note: selectors.note(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PremDeductComponent);
