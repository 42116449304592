import * as hazardActionTypes from "store/actions/input/exposure/hazardActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

export const INIT_STATE = {
  hazardSummary: {
    state: "IDLE",
    error: null,
    value: null,
  },
};

const exposureReducer = (state = INIT_STATE, action) => {
  if (state == null) {
    state = { ...INIT_STATE };
  }
  switch (action.type) {
    case persistenceActionTypes.NEW_SUBMISSION:
    case persistenceActionTypes.RENEW_SUBMISSION:
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case hazardActionTypes.UPDATE_HAZARD_PROFILE_SUMMARY_STARTED:
      return {
        ...state,
        hazardSummary: {
          state: "RUNNING",
          error: null,
          value: null,
        },
      };
    case hazardActionTypes.UPDATE_HAZARD_PROFILE_SUMMARY_SUCCEEDED:
      return {
        ...state,
        hazardSummary: {
          state: "SUCCEEDED",
          error: null,
          value: action.payload,
        },
      };
    case hazardActionTypes.UPDATE_HAZARD_PROFILE_SUMMARY_FAILED:
      return {
        ...state,
        hazardSummary: {
          state: "FAILED",
          error:
            action.payload ?? "Failed to calculate hazard profile summary.",
          value: null,
        },
      };
    default:
      return state;
  }
};

export default exposureReducer;
