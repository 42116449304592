import {
  ArrowBackRounded as PreviousItemIcon,
  ArrowForwardRounded as NextItemIcon,
  OpenInNew as OpenLinkIcon,
} from "@mui/icons-material";
import {
  Button,
  Box,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { readableDate } from "common/dates";
import { extractNameFromEmail } from "common/strings";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import SnapshotPricing from "components/summary/snapshots/SnapshotPricing";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as submissionsService from "services/submissionsService";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const PrevNext = ({ versions, activeVersion, onChange }) => {
  if (!versions || versions.length < 2) {
    return <></>;
  }

  const activeIndex = versions.findIndex((x) => x.id === activeVersion);
  const previousDisabled = activeIndex === 0;
  const nextDisabled = activeIndex === versions.length - 1;

  const previous = () => {
    if (activeIndex === -1) {
      if (versions.length > 0) {
        onChange(versions[0].id);
      }
    } else {
      if (activeIndex > 0) {
        onChange(versions[activeIndex - 1].id);
      }
    }
  };

  const next = () => {
    if (activeIndex === -1) {
      if (versions.length > 0) {
        onChange(versions[0].id);
      }
    } else {
      if (activeIndex + 1 < versions.length) {
        onChange(versions[activeIndex + 1].id);
      }
    }
  };

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <span>
        <IconButton
          scale={"small"}
          icon={PreviousItemIcon}
          tooltip={"Newer"}
          onClick={() => previous()}
          isDisabled={previousDisabled}
        />
      </span>
      <span>
        <IconButton
          scale={"small"}
          icon={NextItemIcon}
          tooltip={"Older"}
          onClick={() => next()}
          isDisabled={nextDisabled}
        />
      </span>
    </Stack>
  );
};

const Sidebar = ({ loading, versions, activeVersion, onChange }) => {
  return (
    <Stack width={250} spacing={0.75} flexShrink={0}>
      {loading ? (
        <Skeleton variant={"rounded"} width={"100%"} height={54} />
      ) : (
        versions.map((version, index) => {
          const isActive = version.id === activeVersion;
          const number = versions.length - index;

          return (
            <Button
              variant={isActive ? "contained" : null}
              disableElevation
              color={"primary"}
              sx={{ color: isActive ? null : "black", textTransform: "unset" }}
              key={version.id}
              onClick={() => onChange && onChange(version.id)}
            >
              <Stack width={"100%"}>
                <Typography variant={"subtitle2"} textAlign={"left"}>
                  {"Version "}
                  {number}
                </Typography>
                <Typography variant={"caption"} textAlign={"left"}>
                  {readableDate(version.timestamp, { includeTime: true })}
                </Typography>
              </Stack>
            </Button>
          );
        })
      )}
    </Stack>
  );
};

const SavedBy = ({ version }) => {
  if (!version) {
    return <></>;
  }

  let date = readableDate(version.savedAt, { includeTime: true }).toLowerCase();
  if (!date.startsWith("today") && !date.startsWith("yesterday")) {
    date = "on " + date;
  }

  return (
    <Typography variant={"subtitle2"} padding={1}>
      {"Saved by"}
      <Tooltip title={version.savedBy}>
        <span> {extractNameFromEmail(version.savedBy).name} </span>
      </Tooltip>
      {date}
    </Typography>
  );
};

const Snapshots = () => {
  const submissionId = useSelector(persistenceSelectors.selectSubmissionId);

  const {
    data: versions,
    isLoading: isListLoading,
  } = submissionsService.useListVersionsQuery(
    { submissionId: submissionId },
    { skip: !submissionId }
  );

  const [activeVersionId, setActiveVersionId] = useState(null);

  if (!activeVersionId && versions != null && versions.length > 0) {
    setActiveVersionId(versions[0].id);
  }

  const {
    data: activeVersion,
    isLoading: isVersionLoading,
  } = submissionsService.useRetrieveVersionQuery(
    { submissionId: submissionId, versionId: activeVersionId },
    { skip: !submissionId || !activeVersionId }
  );

  return (
    <Component
      title={"Snapshots"}
      options={
        <PrevNext
          versions={versions ?? []}
          activeVersion={activeVersionId}
          onChange={(version) => {
            setActiveVersionId(version);
          }}
        />
      }
    >
      <Stack direction={"row"} alignItems={"flex-start"} spacing={2}>
        <Sidebar
          loading={isListLoading}
          versions={versions ?? []}
          activeVersion={activeVersionId}
          onChange={(version) => {
            setActiveVersionId(version);
          }}
        />
        <Box
          width={"100%"}
          textAlign={"center"}
          sx={{
            overflowX: "auto",
            border: "1px solid gainsboro",
            borderRadius: "4px",
          }}
        >
          {isListLoading || isVersionLoading ? (
            <DefaultLoader color={"#dc7f4c"} size={48} />
          ) : (
            <Stack>
              <SnapshotPricing state={activeVersion?.state ?? {}} />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                spacing={1}
              >
                <SavedBy version={activeVersion} />
                {activeVersion?.state != null && (
                  <Link
                    to={`/submissions/${submissionId}/version/${activeVersionId}`}
                    target={"_blank"}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <OpenLinkIcon fontSize={"small"} />
                    </Box>
                  </Link>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>
    </Component>
  );
};

export default Snapshots;
