import Section from "./Section";
import { SettingsBackupRestore as ResetIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as endorsementsStore from "store/endorsements";
import * as staticDataHooks from "store/hooks/staticDataHooks";

export const Endorsements = () => {
  const dispatch = useDispatch();
  const [queryConfig] = staticDataService.useLazyConfigQuery();

  const translateLabels = staticDataHooks.useTranslator("uiLabels");

  const endorsements = useSelector(endorsementsStore.selectInput);
  const config = endorsements?.config;

  const resetEndorsements = useCallback(async () => {
    const { data: endorsementsConfig } = await queryConfig("endorsements");
    const { data: submissionDefaults } = await queryConfig(
      "submission_defaults"
    );

    dispatch(
      endorsementsStore.set({
        ...submissionDefaults?.endorsements,
        config: endorsementsConfig ?? {},
      })
    );
  }, [queryConfig, dispatch]);

  useEffect(() => {
    if (config == null) {
      resetEndorsements();
    }
  }, [config, resetEndorsements]);

  return (
    <Component
      title={translateLabels("ENDORSEMENTS", { default: "Endorsements" })}
      options={
        <Disabled ifReadOnly>
          <IconButton
            variant={"bright"}
            icon={ResetIcon}
            onClick={resetEndorsements}
            tooltip={"Reset endorsements to the defaults"}
          />
        </Disabled>
      }
    >
      {config != null ? (
        <Stack direction={"column"} spacing={2}>
          {config?.sections?.map((section) => (
            <Section key={section.key} config={config} section={section} />
          ))}
          {config?.sections == null && (
            <Typography
              variant={"body"}
              color={"silver"}
              textAlign={"center"}
              sx={{ margin: "4rem 0 8rem 0 !important" }}
            >
              {"No endorsements are configured at present"}
            </Typography>
          )}
        </Stack>
      ) : (
        <BigLoader />
      )}
    </Component>
  );
};

export default Endorsements;
