export const ClaimsMerger = class {
  merged = {};
  addMax(rowkeys, mergekey, row) {
    const rVal = rowkeys
      .map((k) => row[k])
      .filter((v) => v)
      .reduce((m, v) => Math.max(m, v), 0);
    if (this.merged[mergekey] === undefined) this.merged[mergekey] = rVal;
    else this.merged[mergekey] += rVal;
  }

  cleanYear(year) {
    if (!year) return year;
    const tPos = year.indexOf("T");
    if (tPos >= 0) return year.substring(0, tPos);
    return year;
  }

  setYear(rowkey, mergekey, row) {
    const mergedV = this.merged[mergekey];
    const rowV = this.cleanYear(row[rowkey]);
    if (mergedV === undefined) {
      if (rowV) this.merged[mergekey] = rowV;
    } else if (mergedV && rowV) {
      this.merged[mergekey] = mergedV < rowV ? mergedV : rowV;
    } else if (rowV) this.merged[mergekey] = rowV;
  }

  set(rowkey, mergekey, row) {
    if (this.merged[mergekey] === undefined)
      this.merged[mergekey] = row[rowkey];
    else if (this.merged[mergekey] !== row[rowkey]) this.merged[mergekey] = "";
  }

  setOC(rowkey, mergekey, row) {
    if (this.merged[mergekey] === undefined || row[rowkey] === "O")
      this.merged[mergekey] = row[rowkey];
  }

  setCoverage(row) {
    const cv = this.merged["coverage"];
    const nv = row["_COVERAGE"];

    if (nv) {
      const list = this.merged["coverageList"];
      if (!list) this.merged["coverageList"] = [nv];
      else if (!list.includes(nv)) list.push(nv);
      if (!cv || nv === "GL") this.merged["coverage"] = nv;
    }
  }

  doMerge(claimsState, toggleClaimExclude, createMergedClaim, stopMerging) {
    this.merged = {};
    const mergedFrom = Object.values(claimsState)
      .filter((v) => v.selected)
      .map((v) => {
        const r = v.row;
        toggleClaimExclude(r.key, r.index, true);
        this.set("_CLAIMANT_NAME", "claimant", r);
        this.setCoverage(r);
        this.set("_DATE_CLOSED", "dateClosed", r);
        this.set("_DESCRIPTION", "description", r);
        this.setYear("_DATE_OF_LOSS", "lossYear", r);
        this.setOC("_OPEN_CLOSED", "oc", r);
        this.addMax(["_TOTAL_LOSS", "_REPORTED_TOTAL_LOSS"], "lossAmount", r);
        return { key: r.key, index: r.index };
      });
    if (mergedFrom.length) {
      if (this.merged["oc"] === "O") this.merged["dateClosed"] = "";
      this.merged["mergedFrom"] = mergedFrom;

      if (this.merged["coverageList"]) this.merged["coverageList"].sort();
      createMergedClaim(this.merged);
    }
    stopMerging();
  }
};

export default ClaimsMerger;
