import adminSaga from "./admin/adminSaga";
import attachmentsSaga from "./attachments/attachmentsSaga";
import imagesSaga from "./images/imagesSaga";
import staticDataSaga from "./staticData/staticDataSaga";
import { fork } from "redux-saga/effects";

export default function* tempSaga() {
  yield fork(imagesSaga);
  yield fork(attachmentsSaga);
  yield fork(staticDataSaga);
  yield fork(adminSaga);
}
