import AuditLogs from "./AuditLogs";
import CompanySelector from "./CompanySelector";
import Transfers from "./Transfers";
import UserConfig from "./UserConfig";
import UserManagement from "./UserManagement";
import {
  Face as UsersIcon,
  PolicyRounded as AuditIcon,
  Settings as SettingsIcon,
  Storefront as TransferIcon,
} from "@mui/icons-material";
import Footer from "components/common/Footer";
import Navigation from "components/common/Navigation";
import { useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

const CompanyConsole = () => {
  const [navigationState, setNavigationState] = useState({});

  const { companyId } = useParams();

  const menuItems = [
    { text: "Users", icon: <UsersIcon />, route: "users" },
    { text: "Settings", icon: <SettingsIcon />, route: "settings" },
    { text: "Transfers", icon: <TransferIcon />, route: "transfers" },
    { text: "Audit Logs", icon: <AuditIcon />, route: "audit-logs" },
  ];

  return (
    <div className={"app"}>
      <Navigation
        menuItems={menuItems}
        rightMenuComponent={<CompanySelector companyId={companyId} />}
        parentRoute={`/admin/${companyId}`}
      />
      <Routes>
        <Route
          path={"settings/*"}
          element={
            <UserConfig
              navigationState={navigationState}
              setNavigationState={setNavigationState}
            />
          }
        />
        <Route path={"users/*"} element={<UserManagement />} />
        <Route path={"transfers/*"} element={<Transfers />} />
        <Route path={"audit-logs/*"} element={<AuditLogs />} />
        <Route
          path={"*"}
          element={<Navigate to={"settings"} replace={true} />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default CompanyConsole;
