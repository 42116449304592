import * as fileUtils from "../../../fileUtils";
import DownloadButton from "../DownloadButton";
import IconButton from "../IconButton";
import SmallLoader from "../SmallLoader";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useState } from "react";

const DownloadOriginalButton = ({
  sourceMetadata,
  isDisabled,
  smallButton,
  newName,
  ...props
}) => {
  const [originalDownloadState, setOriginalDownloadState] = useState(null);
  const download = (e) => {
    e.stopPropagation();
    setOriginalDownloadState({ state: "DOWNLOADING" });
    fileUtils
      .saveFile(sourceMetadata?.id, newName || sourceMetadata?.originalFilename)
      .then(() => {
        setOriginalDownloadState({ state: "COMPLETED" });
      })
      .catch((error) => {
        setOriginalDownloadState({ state: "ERRORED", error });
      });
  };
  if (smallButton) {
    if (originalDownloadState?.state === "DOWNLOADING") {
      return <SmallLoader />;
    } else {
      return (
        <IconButton
          icon={GetAppIcon}
          onClick={download}
          isDisabled={isDisabled}
          error={originalDownloadState?.error}
          {...props}
        />
      );
    }
  } else {
    return (
      <DownloadButton
        onClick={download}
        isLoading={originalDownloadState?.state === "DOWNLOADING"}
        isDisabled={isDisabled}
        error={originalDownloadState?.error}
        {...props}
      />
    );
  }
};

export default DownloadOriginalButton;
