import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import * as logger from "common/logger";
import { makePrettyNumberColDef } from "components/common/dataGrid/prettyNumberColumn";
import { useDispatch, useSelector } from "react-redux";
import * as programPricingStore from "store/programPricing";

const makeInputBasedCellClassName = (params) => {
  const value = params?.value ?? null;
  const inputValue = params?.row?.[`__input__${params?.field}`] ?? null;
  if (inputValue == null) {
    return "defaulted";
  } else if (inputValue === value) {
    return "user-input";
  } else {
    return "user-input-override";
  }
};

const makeColumns = () => {
  return [
    {
      field: "label",
      headerName: "Coverage Type",
      headerAlign: "center",
      editable: false,
      width: 200,
    },
    makePrettyNumberColDef({
      field: "sublimits",
      headerName: "$",
      headerAlign: "center",
      prefix: "$",
      width: 200,
      sortable: false,
      editable: true,
      cellClassName: makeInputBasedCellClassName,
    }),
    makePrettyNumberColDef({
      field: "deductibles",
      headerName: "$",
      headerAlign: "center",
      prefix: "$",
      width: 100,
      sortable: false,
      editable: true,
      cellClassName: makeInputBasedCellClassName,
    }),
    makePrettyNumberColDef({
      field: "deductiblePercentages",
      headerName: "%",
      headerAlign: "center",
      suffix: "%",
      width: 100,
      sortable: false,
      editable: true,
      cellClassName: makeInputBasedCellClassName,
    }),
    makePrettyNumberColDef({
      field: "deductibleMins",
      headerName: "Min",
      headerAlign: "center",
      width: 100,
      sortable: false,
      editable: true,
      cellClassName: makeInputBasedCellClassName,
    }),
    makePrettyNumberColDef({
      field: "deductibleMaxs",
      headerName: "Max",
      headerAlign: "center",
      width: 100,
      sortable: false,
      editable: true,
      cellClassName: makeInputBasedCellClassName,
    }),
    {
      field: "coverageNotes",
      headerName: "Notes",
      headerAlign: "center",
      type: "text",
      width: 300,
      sortable: false,
      editable: true,
    },
  ];
};

const useCoverages = (perils) => {
  const programPricing = useSelector(programPricingStore.select);
  const programPricingInput = useSelector(programPricingStore.selectInput);

  const fields = [
    "coverageConfig",
    "sublimits",
    "deductibles",
    "deductiblePercentages",
    "deductibleMins",
    "deductibleMaxs",
    "coverageNotes",
  ];

  const coverages = (perils ?? []).map((peril) => ({
    id: peril,
    label: programPricing?.coverageConfig?.perils?.[peril]?.name ?? peril,
    ...Object.fromEntries(
      fields.map((field) => [field, programPricing?.[field]?.[peril] ?? null])
    ),
    ...Object.fromEntries(
      fields.map((field) => [
        `__input__${field}`,
        programPricingInput?.[field]?.[peril] ?? null,
      ])
    ),
  }));

  return coverages;
};

const CoveragesGrid = ({ perils, disabled = false }) => {
  const dispatch = useDispatch();

  const coverages = useCoverages(perils);

  const columns = makeColumns().map((column) =>
    disabled ? { ...column, editable: false } : column
  );

  const updateCoverage = ({ peril, field, value }) => {
    setTimeout(
      () =>
        dispatch(
          programPricingStore.updatePathValue({
            path: [field, peril],
            value: value,
          })
        ),
      0
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
        "& .defaulted": {
          color: "green",
        },
        "& .user-input-override": {
          color: "red",
        },
        "& .MuiDataGrid-columnHeader": {
          border: "1px solid #e0e0e0",
        },
        "& .MuiDataGrid-cell": {
          border: "1px solid #e0e0e0",
        },
      }}
    >
      <DataGrid
        rows={coverages}
        columns={columns}
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        autoHeight
        onCellEditStart={(params, event) => {
          if (params?.reason === "deleteKeyDown") {
            updateCoverage({
              peril: params?.id,
              field: params?.field,
              value: null,
            });
            event.defaultMuiPrevented = true;
            return;
          }
        }}
        processRowUpdate={(newRow, oldRow) => {
          Object.entries(newRow)
            .filter(([field, value]) => oldRow?.[field] !== value)
            .forEach(([field, value]) => {
              updateCoverage({ peril: newRow.id, field, value });
            });
          return newRow;
        }}
        onProcessRowUpdateError={(error) => logger.error(error)}
        columnGroupingModel={[
          {
            groupId: "sublimits",
            headerName: "Sublimits",
            headerAlign: "center",
            children: [{ field: "sublimits" }],
          },
          {
            groupId: "deductibles",
            headerName: "Deductibles",
            headerAlign: "center",
            children: [
              { field: "deductibles" },
              { field: "deductiblePercentages" },
              { field: "deductibleMins" },
              { field: "deductibleMaxs" },
            ],
          },
        ]}
      />
    </Box>
  );
};

export default CoveragesGrid;
