export const UNDERWRITER_TYPE = "UNDERWRITER";
export const BROKER_TYPE = "BROKER";
export const SURPLUS_BROKER_TYPE = "SURPLUS_BROKER";

export const EMPTY_PERSON = {
  id: null,
  name: "",
  company: "",
  email: null,
  licenseNumber: "",
};

export const EMPTY_UNDERWRITER = {
  ...EMPTY_PERSON,
  type: UNDERWRITER_TYPE,
};

export const EMPTY_BROKER = {
  ...EMPTY_PERSON,
  type: BROKER_TYPE,
};

export const EMPTY_SURPLUS_BROKER = {
  ...EMPTY_PERSON,
  type: SURPLUS_BROKER_TYPE,
};

const notBlankOrNull = (x) => x && x !== "";

const notEmptyPerson = (p) =>
  notBlankOrNull(p.id) ||
  notBlankOrNull(p.name) ||
  notBlankOrNull(p.company) ||
  notBlankOrNull(p.email);

export const extractSurplusBrokerFromPeople = (people) => {
  const surplusBrokers = (people?.people ?? []).filter(
    (p) => p.type === SURPLUS_BROKER_TYPE
  );
  if (surplusBrokers.length > 0) {
    const broker = surplusBrokers[0];
    if (notEmptyPerson(broker)) {
      const { type, ...data } = broker;
      return data;
    }
  }
  return null;
};

export const extractBrokerFromPeople = (people) => {
  const brokers = (people?.people ?? []).filter((p) => p.type === BROKER_TYPE);
  if (brokers.length > 0) {
    const broker = brokers[0];
    if (notEmptyPerson(broker)) {
      const { type, ...data } = broker;
      return data;
    }
  }
  return null;
};

export const exrtractNthUnderwriterFromPeople = (n) => (people) => {
  const underwriters = (people?.people ?? []).filter(
    (p) => p.type === UNDERWRITER_TYPE
  );
  if (underwriters.length > n) {
    const underwriter = underwriters[n];
    if (notEmptyPerson(underwriter)) {
      const { type, ...data } = underwriter;
      return data;
    }
  }
  return null;
};

export const extractPreferredUnderwriterFromProgram = (people) =>
  exrtractNthUnderwriterFromPeople(0)(people);
export const extractSecondaryUnderwriterFromProgram = (people) =>
  exrtractNthUnderwriterFromPeople(1)(people);
