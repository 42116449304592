import * as actionTypes from "../../../actions/input/exposure/exposureActionTypes";

export const INIT_STATE = {
  all: [],
  loadingAll: false,
  hadErrorLoadingAll: false,
  forIndustryClass: [],
  loadingForIndustryClass: false,
  hadErrorLoadingForIndustryClass: false,
  industryClassChoices: [],
  loadingIndustryClasses: false,
  industryClassesHasError: false,
};

const exposureMetricsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case actionTypes.UPDATING_EXPOSURE_MEASURES_LIST:
      return {
        ...state,
        loadingForIndustryClass: true,
      };
    case actionTypes.UPDATED_LOSS_CATEGORIES_LIST:
      return {
        ...state,
        lossCategories: action.payload,
      };
    case actionTypes.UPDATED_EXPOSURE_MEASURES_LIST:
      return {
        ...state,
        loadingForIndustryClass: false,
        forIndustryClass: action.payload.map((_) => _.key),
        hadErrorLoadingForIndustryClass: false,
      };
    case actionTypes.LOAD_ALL_EXPOSURE_METRICS_STARTED:
      return {
        ...state,
        loadingAll: true,
      };
    case actionTypes.LOAD_ALL_EXPOSURE_METRICS_RETURNED:
      return {
        ...state,
        all: action.payload,
        loadingAll: false,
        hadErrorLoadingAll: false,
      };
    case actionTypes.LOAD_ALL_EXPOSURE_METRICS_FAILED:
      return {
        ...state,
        all: [],
        loadingAll: false,
        hadErrorLoadingAll: true,
      };
    case actionTypes.LOAD_ALL_INDUSTRY_CLASSES_STARTED:
      return {
        ...state,
        loadingIndustryClasses: true,
      };
    case actionTypes.LOAD_ALL_INDUSTRY_CLASSES_RETURNED:
      return {
        ...state,
        industryClassChoices: action.payload,
        loadingIndustryClasses: false,
        industryClassesHasError: false,
      };
    case actionTypes.LOAD_ALL_INDUSTRY_CLASSES_FAILED:
      return {
        ...state,
        loadingIndustryClasses: false,
        industryClassesHasError: true,
        industryClassChoices: [],
      };
    default:
      return state;
  }
};

export default exposureMetricsReducer;
