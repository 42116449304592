import * as types from "./scenariosActionTypes";

export const setScenarioLevels = (scenarioLevels) => ({
  type: types.SET_SCENARIO_LEVELS,
  payload: scenarioLevels,
});

export const setScenarioLayerId = (layerId) => ({
  type: types.SET_SCENARIO_LAYER_ID,
  payload: layerId,
});

export const updateScenariosNote = (note) => ({
  type: types.UPDATE_SCENARIOS_NOTE,
  payload: note,
});
