import * as logger from "../../../../common/logger";
import * as config from "../../../../config";
import * as utils from "../../../../utils";
import * as errorActions from "../../error/errorActions";
import * as tempAttachActionTypes from "../../temp/attachments/attachmentsTempActionTypes";
import { requestAttachmentTree } from "../../temp/attachments/attachmentsTempActions";
import * as claimsActionTypes from "../claims/claimsActionTypes";
import * as claimsActions from "../claims/claimsActions";
import * as types from "./attachmentsActionTypes";
import {
  ADD_FILE_TO_DIRECTORY,
  CREATE_DIRECTORY,
  MOVE_DIRECTORY_TO_DIRECTORY,
  MOVE_FILE_TO_DIRECTORY,
  REMOVE_DIRECTORY,
  REMOVE_FILE_FROM_DIRECTORY,
  RENAME_DIRECTORY,
  SET_NAME_OF_FILE_IN_DIRECTORY,
  MOVE_FILE_TO_FILE,
} from "./attachmentsActionTypes";
import { createAction } from "@reduxjs/toolkit";

export const copyAttachmentToLossFile = (attachment) => ({
  type: types.COPYING_ATTACHMENT_TO_LOSS_FILE,
  payload: attachment,
});

export const fileUploaded = (fileId) => (dispatch) => {
  try {
    dispatch({
      type: types.ATTACHMENT_UPLOADED,
      payload: { fileId },
    });
    dispatch(requestAttachmentTree(fileId));
  } catch (e) {
    logger.error("ERROR fileUploaded", e);
  }
};

export const lossFileAttachmentFile = (fileId, filename) => (dispatch) => {
  dispatch(copyAttachmentToLossFile({ filename, fileId }));

  utils
    .authenticatedFetch(
      config.endpoints.claims(`attachment-file-to-loss-file/${fileId}`)
    )
    .then((response) => response.json())
    .then((json) => {
      dispatch(claimsActions.fileUploaded(json.filename, json.claimsKey));
    })
    .catch((error) => {
      logger.exception(error, { breadcrumb: fileId });
      dispatch({
        type: claimsActionTypes.CLAIMS_FILE_UPLOAD_FAILED,
      });
      dispatch(
        errorActions.handleError(error, {
          title: `Unable to use Attachment as Loss File: ${filename}.`,
          message:
            error.description || `Please contact ${config.SUPPORT_EMAIL}.`,
          type: "UNABLE_TO_USE_ATTACHMENT_AS_LOSS_FILE",
        })
      );
    });
};

export const openAttachmentSelectionDialog = () => ({
  type: types.OPEN_ATTACHMENT_DIALOG,
});

export const closeAttachmentSelectionDialog = () => ({
  type: types.CLOSE_ATTACHMENT_DIALOG,
});

export const newEmailAddress = {
  startSaga: createAction("attachments/newEmail/startSaga"),
  requested: createAction("attachments/newEmail/requested"),
  returned: createAction(
    "attachments/newEmail/returned",
    ({ address, target }) => ({
      payload: { address, target },
    })
  ),
  monitoring: createAction("attachments/newEmail/monitoring"),
};

export const monitorEmail = {
  startSaga: createAction("attachments/monitorEmail/startSaga", (target) => ({
    payload: target,
  })),
  emailFound: createAction(
    "attachments/monitorEmail/emailFound",
    (target, savedEmail) => ({
      payload: { target, savedEmail },
    })
  ),
  timedOut: createAction("attachments/monitorEmail/timedOut", (target) => ({
    payload: target,
  })),
};

export const attachFromEmail = {
  startSaga: createAction(
    "attachments/attachFromEmail/startSaga",
    (emailAttachment) => ({
      payload: emailAttachment,
    })
  ),
  started: createAction(
    "attachments/attachFromEmail/started",
    (emailAttachment) => ({
      payload: emailAttachment,
    })
  ),
  uploaded: createAction(
    "attachments/attachFromEmail/uploaded",
    (emailAttachment, attachment) => ({
      payload: { emailAttachment, attachment },
    })
  ),
  finished: createAction(
    "attachments/attachFromEmail/finished",
    (attachment) => ({
      payload: attachment,
    })
  ),
};

export const updateEmailTimer = (target, counter, totalCount) => ({
  type: types.UPDATE_EMAIL_TIMER,
  payload: {
    target,
    fraction: counter / totalCount,
    timeRemaining: counter,
  },
});

export const removeExpiredEmail = (target) => ({
  type: types.REMOVE_EXPIRED_EMAIL,
  payload: target,
});

export const metadataRefresh = (fileId) => ({
  type: tempAttachActionTypes.REQUEST_ATTACHMENT_TREE,
  payload: { fileId },
});

export const removeDirectory = (directoryId) => ({
  type: REMOVE_DIRECTORY,
  payload: { directoryId },
});

export const renameDirectory = (directoryId, name) => ({
  type: RENAME_DIRECTORY,
  payload: { directoryId, name },
});

export const createDirectory = (parentId, name) => ({
  type: CREATE_DIRECTORY,
  payload: { parentId, name: name ? name : "New Folder" },
});

export const removeFileFromDirectory = (fileId, directoryId) => ({
  type: REMOVE_FILE_FROM_DIRECTORY,
  payload: { fileId, directoryId },
});

export const moveDirectory = (dirId, toDirId) => ({
  type: MOVE_DIRECTORY_TO_DIRECTORY,
  payload: { dirId, toDirId },
});
export const moveFileToDirectory = (
  fileId,
  fromDirId,
  toDirId,
  embeddedFiles
) => (dispatch) => {
  dispatch({
    type: MOVE_FILE_TO_DIRECTORY,
    payload: { fileId, fromDirId, toDirId, embeddedFiles },
  });
  dispatch(metadataRefresh(fileId));
};

export const addFileToDirectory = (fileId, directoryId) => (dispatch) => {
  dispatch({
    type: ADD_FILE_TO_DIRECTORY,
    payload: { fileId, directoryId },
  });
  dispatch(metadataRefresh(fileId));
};

export const updateAttachmentName = (fileId, directoryId, name) => ({
  type: SET_NAME_OF_FILE_IN_DIRECTORY,
  payload: { fileId, directoryId, name },
});

export const moveFileToFile = (fileId, fromDirId, toDirId, embeddedFiles) => ({
  type: MOVE_FILE_TO_FILE,
  payload: { fileId, fromDirId, toDirId, embeddedFiles },
});
