import { useEffect, useState } from "react";

export const usePagination = (rows, { defaultPageSize } = {}) => {
  const rowCount = rows?.length ?? 0;

  const [page, setPage] = useState(1);
  const [pageSize, _setPageSize] = useState(defaultPageSize ?? 25);
  const pageCount = Math.ceil(rowCount / pageSize);

  const paginatedRows = (rows ?? []).slice(
    (page - 1) * pageSize,
    page * pageSize
  );

  const setPageSize = (pageSize) => {
    _setPageSize(pageSize);
    setPage(1);
  };

  // We might not have the default page size on initialization. For example, if we are
  // reading that from config. So reset the page size if it changes.
  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize]);

  return {
    paginatedRows,
    rowCount,
    page,
    setPage,
    pageSize,
    setPageSize,
    pageCount,
  };
};
