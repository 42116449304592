import { wrapTextInBreaks } from "../../../../common/markdown";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import * as logger from "common/logger";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const SUPPORTED_IMAGE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/bmp",
  "image/gif",
];

export const insertPhotoImage = (uploadImage, file, state, api) => {
  if (SUPPORTED_IMAGE_TYPES.includes(file?.type?.toLowerCase())) {
    const localId = uuidv4();
    api.replaceSelection(
      wrapTextInBreaks(
        state,
        `![${file.name}](https://marmalade/image/local/${localId})`
      )
    );
    uploadImage(file, localId);
  } else {
    logger.error(`Invalid file type, ${file.type}`, {
      breadcrumb: {
        action: "insertPhotoImage",
        file: {
          name: file?.name,
          type: file?.type,
          size: file?.size,
        },
      },
    });
  }
};

export const insertPhoto = (uploadImage) => ({
  name: "insertPhoto",
  keyCommand: "insertPhoto",
  buttonProps: {
    "aria-label": "Insert an image",
    title: "Insert an image",
  },
  icon: <AddPhotoAlternateIcon fontSize={"inherit"} />,
  execute: (state, api) => {
    const buildFileSelector = () => {
      const fileSelector = document.createElement("input");
      fileSelector.setAttribute("type", "file");
      fileSelector.setAttribute("accept", SUPPORTED_IMAGE_TYPES.join(","));
      fileSelector.onchange = () => {
        for (let i = 0; i < fileSelector.files.length; i++) {
          const file = fileSelector.files[i];
          insertPhotoImage(uploadImage, file, state, api);
        }
      };
      return fileSelector;
    };
    const fileSelector = buildFileSelector();
    fileSelector.click();
  },
});
