import { Edit as EditIcon } from "@mui/icons-material";
import { IconButton, Stack, TextField } from "@mui/material";
import { useState, useRef } from "react";

const EditableTitle = ({ onSubmit, value }) => {
  const [newValue, setNewValue] = useState(value);
  const [editing, setEditing] = useState(false);
  const textFieldRef = useRef(null);

  const handleSubmit = (givenValue) => {
    setEditing(false);
    let val = givenValue.trim();
    if (onSubmit != null && val.length > 0) {
      onSubmit(val);
    } else {
      setNewValue(value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(newValue);
    } else if (e.key === "Escape") {
      setEditing(false);
      setNewValue(value);
    }
  };

  return editing ? (
    <TextField
      inputRef={textFieldRef}
      onBlur={() => {
        handleSubmit(newValue);
      }}
      onFocus={(e) => {
        e.target.select();
      }}
      onChange={(e) => {
        setNewValue(e.target.value);
      }}
      onKeyDown={handleKeyDown}
      value={newValue}
      size={"small"}
      style={{ marginRight: "10px" }}
    />
  ) : (
    <Stack flexDirection={"row"} alignItems={"center"} gap={0.5}>
      <h2>{value}</h2>
      <IconButton
        onClick={() => {
          setEditing(true);
          setTimeout(() => {
            textFieldRef.current?.focus();
          }, 10);
        }}
      >
        <EditIcon color={"primary"} />
      </IconButton>
    </Stack>
  );
};

export default EditableTitle;
