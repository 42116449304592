import adminReducer from "./admin/adminReducer";
import tempAttachmentsReducer from "./attachments/tempAttachmentsReducer";
import tempClaimsReducer from "./claims/tempClaimsReducer";
import errorReducer from "./errors/errorsReducer";
import exposureReducer from "./exposure/exposureReducer";
import exposureMetricsReducer from "./exposureMetrics/exposureMetricsReducer";
import flagsReducer from "./flags/flagsReducer";
import imagesReducer from "./images/imagesReducer";
import navigationReducer from "./navigation/navigationReducer";
import peersPricingReducer from "./peersPricing/peersPricingReducer";
import priorStateReducer from "./priorState/priorStateReducer";
import staticDataReducer from "./staticData/staticDataReducer";
import tempTransferReducer from "./transfer/tempTransferReducer";
import { combineReducers } from "redux";

const tempReducer = combineReducers({
  claims: tempClaimsReducer,
  exposureMetrics: exposureMetricsReducer,
  peersPricing: peersPricingReducer,
  flags: flagsReducer,
  error: errorReducer,
  priorState: priorStateReducer,
  staticData: staticDataReducer,
  images: imagesReducer,
  attachments: tempAttachmentsReducer,
  navigation: navigationReducer,
  admin: adminReducer,
  exposure: exposureReducer,
  transfer: tempTransferReducer,
});

export default tempReducer;
