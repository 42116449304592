import * as utils from "./utils";
import { useRef } from "react";

export const tickFormatter = (n) => {
  if (n == null) {
    return n;
  }
  const targetDecimalPlaces = (n) => {
    const a = Math.abs(n);
    if (a < 1) {
      return 3;
    }
    if (a < 10) {
      return 2;
    }
    if (a < 100) {
      return 1;
    }
    return 0;
  };
  const formatNumber = (n) => {
    return n.toLocaleString("en-US", {
      maximumFractionDigits: targetDecimalPlaces(n),
    });
  };
  const a = Math.abs(n);
  if (a < 1e3) return formatNumber(n) + "";
  if (a >= 1e3 && a < 1e6) return formatNumber(n / 1e3) + "k";
  if (a >= 1e6 && a < 1e9) return formatNumber(n / 1e6) + "m";
  if (a >= 1e9 && a < 1e12) return formatNumber(n / 1e9) + "b";
  if (a >= 1e12) return formatNumber(a / 1e12) + "t";
};

export const formatToolTip = (value) => utils.sfString(value);

export const useTooltipPositionerFixingBottomRightToChartTopRight = () => {
  const chartRef = useRef();
  let tooltipPosition = {};
  Object.defineProperty(tooltipPosition, "x", {
    get: () => {
      const chartWidth = chartRef.current?.props?.width;
      const marginRight = chartRef.current?.props?.margin?.right;
      const tooltipEl = chartRef.current?.container?.parentElement?.querySelector(
        ".recharts-tooltip-wrapper"
      );
      const boundingRect = tooltipEl?.getBoundingClientRect();
      return chartWidth - boundingRect?.width - marginRight;
    },
  });
  Object.defineProperty(tooltipPosition, "y", {
    get: () => {
      const tooltipEl = chartRef.current?.container?.parentElement?.querySelector(
        ".recharts-tooltip-wrapper"
      );
      const boundingRect = tooltipEl?.getBoundingClientRect();
      return -boundingRect?.height;
    },
  });
  return { chartRef, tooltipPosition };
};
