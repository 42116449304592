import Component from "components/Component";
import FilesFileUpload from "components/FilesFileUpload";
import BigLoader from "components/common/BigLoader";
import * as config from "config";
import * as fileUtils from "fileUtils";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import * as sovService from "services/sovService";
import * as sovStore from "store/SOV";

const UploadSov = () => {
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [createSovFile, { isLoading }] = sovService.useCreateSovFileMutation();
  const onFileDrop = async (fileId) => {
    const { data: sovFileStatus } = await createSovFile({ fileId });
    if (sovFileStatus?.id) {
      dispatch(
        sovStore.updateSOV({
          sovFileId: sovFileStatus.id,
        })
      );
    } else {
      enqueueSnackbar(
        `Error parsing the SOV file. Please contact ${config.SUPPORT_EMAIL}`,
        {
          variant: "error",
          autoHideDuration: 10_000,
        }
      );
    }
  };
  return isLoading ? (
    <Component title={"Parsing SOV ..."}>
      <BigLoader />
    </Component>
  ) : (
    <FilesFileUpload
      onDrop={onFileDrop}
      asAtDateNotRequired
      size={{ xs: 12 }}
      dropMessage={"Drop an SOV template in .xlsx format"}
      mimeTypes={fileUtils.spreadsheetMimeTypes}
    />
  );
};

export default UploadSov;
