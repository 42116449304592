import UploadValidation from "components/common/files/UploadValidation";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as hazardActions from "store/actions/input/exposure/hazardActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

const SICValidation = () => {
  const activeFile = useSelector(exposureSelectors.getActiveFile);

  const dispatch = useDispatch();

  const transformHazardFile = useCallback(() => {
    dispatch(hazardActions.transformHazardFile.requested());
  }, [dispatch]);

  useEffect(() => {
    transformHazardFile();
  }, [transformHazardFile]);

  return (
    <UploadValidation
      transformStatus={activeFile?.transformStatus}
      transformError={activeFile?.transformError}
    />
  );
};

export default SICValidation;
