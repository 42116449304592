import FormattedDatePicker from "./FormattedDatePicker";
import Visible from "./Visible";
import { Stack, FormControlLabel, Checkbox } from "@mui/material";
import * as dates from "common/dates";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import { useDispatch } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as staticDataHooks from "store/hooks/staticDataHooks";

const Document = ({
  documentKey,
  label,
  layer,
  updateLayer,
  defaultVisible = true,
  legacyKey = null,
}) => {
  const document = layer?.[documentKey] ?? {};
  return (
    <Visible
      byTag={`<DocumentsIssued>.${documentKey}`}
      defaultVisible={defaultVisible}
    >
      <Stack
        direction={"column"}
        spacing={1}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Disabled ifReadOnly>
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                checked={document.isIssued ?? false}
                onChange={(e) => {
                  const isIssued = e.target.checked;
                  const now = dates.getDate();
                  updateLayer({
                    [documentKey]: {
                      issueDate: isIssued ? dates.toISODateString(now) : null,
                      recordedTimestamp: isIssued ? now.toISOString() : null,
                    },
                    ...(legacyKey != null ? { [legacyKey]: null } : {}),
                  });
                }}
              />
            }
            componentsProps={{
              typography: { variant: "caption" },
            }}
            sx={{ height: "100%" }}
          />
        </Disabled>
        {(document.isIssued || !!document.issueDate) && (
          <Disabled ifReadOnly>
            <FormattedDatePicker
              label={"Issue Date"}
              variant={"filled"}
              onChangeDate={(issueDate) => {
                updateLayer({
                  [documentKey]: {
                    ...document,
                    issueDate,
                  },
                });
              }}
              value={document.issueDate ?? ""}
              fullWidth
              clearable={true}
              sx={{ marginLeft: 5 }}
            />
          </Disabled>
        )}
      </Stack>
    </Visible>
  );
};

const DocumentsIssued = ({ layer }) => {
  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  const dispatch = useDispatch();

  const updateLayer = (values) => {
    if (!layer?.id) {
      return;
    }
    dispatch(
      pricingActions.updateLayer({
        layerId: layer.id,
        values,
      })
    );
  };

  return (
    <Component
      title={translateLabel("DOCUMENTS_ISSUED", {
        default: "Documents Issued",
      })}
      growVertically
    >
      <Stack direction={"column"} spacing={1}>
        <Document
          documentKey={"binder"}
          label={translateLabel("IS_BINDER_ISSUED", {
            default: "Binder Issued?",
          })}
          layer={layer}
          updateLayer={updateLayer}
          legacyKey={"isBinderIssued"}
        />
        <Document
          documentKey={"policy"}
          label={translateLabel("IS_POLICY_ISSUED", {
            default: "Policy Issued?",
          })}
          layer={layer}
          updateLayer={updateLayer}
          legacyKey={"isPolicyIssued"}
        />
        <Document
          documentKey={"puniBinder"}
          label={translateLabel("IS_PUNI_BINDER_ISSUED", {
            default: "Puni Binder Issued?",
          })}
          layer={layer}
          updateLayer={updateLayer}
          defaultVisible={false}
        />
        <Document
          documentKey={"puniPolicy"}
          label={translateLabel("IS_PUNI_POLICY_ISSUED", {
            default: "Puni Policy Issued?",
          })}
          layer={layer}
          updateLayer={updateLayer}
          defaultVisible={false}
        />
      </Stack>
    </Component>
  );
};

export default DocumentsIssued;
