import { Box, Typography } from "@mui/material";
import * as numbers from "common/numbers";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";

const commonStyles = {
  width: "6.5rem",
  height: "2.25rem",
  padding: "0.25rem 0 !important",
  margin: "0 0.25rem 0.25rem 0",
  borderRadius: "0.25rem",
  flexShrink: "0",
};

const clickableStyles = (active) => ({
  borderColor: active ? "primary.main" : "transparent",
  borderWidth: "2px",
  borderStyle: "solid",
  cursor: "pointer",
  "&:hover": {
    borderColor: "lightgrey",
  },
});

export const HeadingCell = ({ label, centered, id, active, onClick, sx }) => {
  const clickable = typeof onClick === "function";

  return (
    <Box
      sx={{
        ...commonStyles,
        ...sx,
        ...(clickable ? clickableStyles(active) : {}),
      }}
      onClick={onClick}
      key={id}
    >
      <Typography
        variant={"subtitle2"}
        align={centered ? "center" : "right"}
        paddingRight={1}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const ValueCell = ({ children, borderless, bg, id }) => {
  return (
    <Box
      sx={{
        ...commonStyles,
        textAlign: "center",
        background: bg,
        border: borderless ? null : "1px solid #ececec",
      }}
      key={id}
    >
      {children}
    </Box>
  );
};

export const ClickableCell = ({ children, id, active, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        ...commonStyles,
        textAlign: "center",
        ...clickableStyles(active),
      }}
      key={id}
    >
      {children}
    </Box>
  );
};

export const InputCell = ({
  value,
  active,
  onChangeNumber,
  abbreviated,
  percentage,
  padding,
}) => {
  const offFormatter = (() => {
    if (abbreviated) {
      return numbers.abbreviated;
    } else if (percentage) {
      return numbers.dpString(2);
    }
    return numbers.dpString(3);
  })();
  if (percentage) {
    value = value * 100;
    if (onChangeNumber) {
      const originalOnChangeNumber = onChangeNumber;
      onChangeNumber = (value) => {
        if (value == null) {
          return originalOnChangeNumber(value);
        }
        return originalOnChangeNumber(value / 100);
      };
    }
  }
  return (
    <PrettyNumberTextField
      value={value}
      variant={"outlined"}
      onChangeNumber={onChangeNumber}
      hiddenLabel={true}
      size={"small"}
      sx={{
        ...commonStyles,
        padding: padding ?? commonStyles.padding,
        marginRight: "0.25rem",
        input: {
          textAlign: "center",
          padding: "7px 14px",
          ...(active
            ? {
                color: "primary.main",
                fontWeight: "bold",
              }
            : {}),
        },
      }}
      suffix={percentage ? "%" : ""}
      offFormatter={offFormatter}
      allowClear={active}
    />
  );
};

export const PercentageInputCell = ({ value, onChangeNumber }) => (
  <PrettyNumberTextField
    value={100 * value}
    variant={"percentage outlined"}
    onChangeNumber={
      onChangeNumber
        ? (value) => onChangeNumber(value ? value / 100 : value)
        : null
    }
    hiddenLabel={true}
    size={"small"}
    sx={{
      ...commonStyles,
      marginRight: "0.25rem",
    }}
    InputProps={{
      inputProps: {
        style: {
          textAlign: "center",
        },
      },
    }}
    offFormatter={numbers.dpString(1)}
  />
);
