import PieChartRoundedIcon from "@mui/icons-material/PieChartRounded";
import TableChartRoundedIcon from "@mui/icons-material/TableChartRounded";
import {
  Table,
  Typography,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import * as numbers from "common/numbers";
import Component from "components/Component";
import { shades } from "components/inputs/lossDevelopment/LossDevelopment.support";
import React, { useState } from "react";
import { connect } from "react-redux";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import * as towerSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as utils from "utils";

const CustomLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  carrier,
  value,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline={"central"}
      fontSize={12}
    >
      <tspan x={x} dy={"-1.2em"}>
        {`${carrier}:`}
      </tspan>
      <tspan x={x} dy={"1.2em"}>
        {`$${numbers.abbreviated(value)}`}
      </tspan>
      <tspan x={x} dy={"1.2em"}>
        {`(${(percent * 100).toFixed(1)}%)`}
      </tspan>
    </text>
  );
};

const CarrierParticipation = ({ carriersData, companyName }) => {
  const [view, setView] = useState("Table");
  const [chartDataKey, setChartDataKey] = useState("totalLimit");

  if (!carriersData || carriersData.length === 0) {
    return (
      <Component title={"Carrier Participation"} lg_width={6} md_width={12}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          padding={2}
        >
          <Typography variant={"h6"} color={"textSecondary"}>
            {"No Tower Data"}
          </Typography>
        </Box>
      </Component>
    );
  }
  const updatedCarriersData = carriersData.map((carrier) => {
    if (carrier.carrier === "Us") {
      return { ...carrier, carrier: companyName || "Our Share" };
    }
    return carrier;
  });
  const numberOfCarriers = updatedCarriersData.length;
  const colors = shades("#ffe0b2", "#ff6f00", numberOfCarriers);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleChartDataKeyChange = (event, newDataKey) => {
    if (newDataKey !== null) {
      setChartDataKey(newDataKey);
    }
  };

  return (
    <Component
      title={"Carrier Participation"}
      lg_width={6}
      md_width={12}
      options={
        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            color={"primary"}
            size={"small"}
            aria-label={"view toggle"}
          >
            <Tooltip title={"Show Table"} arrow>
              <ToggleButton value={"Table"} aria-label={"Show Table"}>
                <TableChartRoundedIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                <span style={{ paddingRight: "3px" }}>{"Table"}</span>
              </ToggleButton>
            </Tooltip>
            <Tooltip title={"Show Chart"} arrow>
              <ToggleButton value={"Chart"} aria-label={"Show Chart"}>
                <PieChartRoundedIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                <span style={{ paddingRight: "2px" }}>{"Chart"}</span>
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </Stack>
      }
    >
      {view === "Chart" ? (
        <>
          <ResponsiveContainer width={"100%"} height={400}>
            <PieChart>
              <Pie
                data={updatedCarriersData}
                dataKey={chartDataKey}
                nameKey={"carrier"}
                cx={"50%"}
                cy={"50%"}
                outerRadius={150}
                labelLine={false}
                label={CustomLabel}
              >
                {updatedCarriersData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <Box display={"flex"} justifyContent={"center"}>
            <ToggleButtonGroup
              value={chartDataKey}
              exclusive
              onChange={handleChartDataKeyChange}
              color={"primary"}
              size={"small"}
              aria-label={"data key toggle"}
            >
              <ToggleButton value={"totalLimit"} aria-label={"Total Limit"}>
                <span style={{ paddingRight: "3px" }}>{"Total Limit"}</span>
              </ToggleButton>
              <ToggleButton value={"totalPremium"} aria-label={"Total Premium"}>
                <span style={{ paddingRight: "2px" }}>{"Total Premium"}</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label={"carrier participation table"}>
            <TableHead>
              <TableRow>
                <TableCell>{"Carrier"}</TableCell>
                <TableCell align={"right"}>{"Total Limit ($)"}</TableCell>
                <TableCell align={"right"}>{"Total Premium ($)"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedCarriersData.map((carrier) => (
                <TableRow key={carrier.carrier}>
                  <TableCell component={"th"} scope={"row"}>
                    {carrier.carrier}
                  </TableCell>
                  <TableCell align={"right"}>
                    {utils.sfString(carrier.totalLimit)}{" "}
                  </TableCell>
                  <TableCell align={"right"}>
                    {utils.sfString(carrier.totalPremium)}{" "}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Component>
  );
};

const mapStateToProps = (state) => {
  const towerLayers = towerSelectors.getTower(state);

  const carrierMap = {};

  towerLayers.forEach((layer) => {
    const shares = layer.shares || [];
    shares.forEach((share) => {
      if (share.carrier) {
        if (!carrierMap[share.carrier]) {
          carrierMap[share.carrier] = {
            carrier: share.carrier,
            totalLimit: 0,
            totalPremium: 0,
          };
        }
        carrierMap[share.carrier].totalLimit += share.shareOfLimit || 0;
        carrierMap[share.carrier].totalPremium +=
          share.shareOfGrossPremium || 0;
      }
    });
  });

  const carriersData = Object.values(carrierMap);

  return { carriersData };
};

export default connect(mapStateToProps)(CarrierParticipation);
