import Tooltip from "@mui/material/Tooltip";
import Logo from "components/common/Logo";
import { Link, useLocation } from "react-router-dom";

export const NavButton = ({ text, icon, active }) => (
  <span className={"nav-link " + (active ? "active-section" : "")}>
    {icon}
    {text}
  </span>
);

export const Navigation = ({
  menuItems = [],
  rightMenuItems = null,
  rightMenuComponent = null,
  isFullWidth = false,
  parentRoute = "",
}) => {
  let location = useLocation();

  return (
    <div className={"navbar-wrapper"}>
      <div className={isFullWidth ? "navbar" : "navbar container"}>
        <div className={"nav-left"}>
          {menuItems.map((menuItem, i) => {
            return (
              <Link
                to={menuItem.route}
                style={{ textDecoration: "none" }}
                key={i}
              >
                <NavButton
                  text={menuItem.text}
                  active={location.pathname.startsWith(
                    `${parentRoute}/${menuItem.route}`
                  )}
                  icon={menuItem.icon}
                />
              </Link>
            );
          })}
          {menuItems.length === 0 && (
            <Logo
              variant={"wordmark"}
              height={24}
              style={{ alignSelf: "center", margin: "4px 0" }}
            />
          )}
        </div>

        <div className={"nav-right"}>
          {(rightMenuItems ?? []).map((menuItem, i) => (
            <Link
              to={menuItem.route}
              style={{ textDecoration: "none" }}
              key={i}
            >
              <span className={"link"}>
                <Tooltip title={menuItem.text}>{menuItem.icon}</Tooltip>
              </span>
            </Link>
          ))}
          {rightMenuComponent || <></>}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
