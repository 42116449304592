import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as chartUtils from "chartUtils";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import NumberFormat from "react-number-format";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const stateNames = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const numberColumnSx = {
  minWidth: "100px",
  paddingLeft: "6px",
  paddingRight: "6px",
};

const summaryItems = (hazardSummary) => {
  const groups = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];
  const items = [];

  (hazardSummary?.states ?? []).forEach((x) => {
    const item = {
      key: x.state,
      label: `${stateNames[x.state]} (${x.state})`,
      total: x.profile.reduce((acc, cur) => acc + cur.premium, 0) || 0,
    };

    for (let i = 0; i < 9; i++) {
      const premium =
        x.profile.find((y) => {
          return y.hazardGroup === i + 1 || y.hazardGroup === groups[i];
        })?.premium || 0;
      item[i + 1] = premium;
    }

    items.push(item);
  });

  return items.sort((a, b) => a.key > b.key);
};

const summaryTotals = (items) => {
  const allStates = {};
  const california = {};
  const californiaItem = items.find((x) => x.key === "CA") || {};

  for (let i = 1; i <= 9; i++) {
    allStates[i] = items.reduce((acc, cur) => acc + cur[i], 0);
    california[i] = californiaItem[i];
  }

  return {
    allStates: allStates,
    california: california,
  };
};

const PercentCell = ({ value }) => (
  <TableCell align={"right"}>
    <NumberFormat
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={1}
      value={value}
      suffix={"%"}
    />
  </TableCell>
);

const NumberCell = ({ value }) => (
  <TableCell align={"right"} sx={numberColumnSx}>
    <NumberFormat
      displayType={"text"}
      thousandSeparator={true}
      decimalScale={0}
      value={value === 0 ? "-" : value}
    />
  </TableCell>
);

const TotalCell = ({ value }) => {
  if (!value || value === 0) {
    return <TableCell />;
  }
  return (
    <TableCell align={"right"} sx={numberColumnSx}>
      <NumberFormat
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
        value={value}
        style={{ fontWeight: "bold" }}
      />
    </TableCell>
  );
};

const SICSummaryHeader = ({ includesCA }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>{"State"}</TableCell>
        <TableCell align={"right"}>{"Share"}</TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"1"}
        </TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"2"}
        </TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"3"}
        </TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"4"}
        </TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"5"}
        </TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"6"}
        </TableCell>
        <TableCell align={"right"} sx={numberColumnSx}>
          {"7"}
        </TableCell>
        {includesCA && (
          <>
            <TableCell align={"right"} sx={numberColumnSx}>
              {"8"}
            </TableCell>
            <TableCell align={"right"} sx={numberColumnSx}>
              {"9"}
            </TableCell>
          </>
        )}
      </TableRow>
    </TableHead>
  );
};

const SICSummaryBody = ({ includesCA, items }) => {
  const grandTotal = items.reduce((acc, cur) => acc + cur.total, 0);

  return (
    <TableBody>
      {items.map((item) => {
        return (
          <TableRow key={item.key}>
            <TableCell>{item.label}</TableCell>
            <PercentCell value={(100 * item.total) / grandTotal} />
            <NumberCell value={item["1"]} />
            <NumberCell value={item["2"]} />
            <NumberCell value={item["3"]} />
            <NumberCell value={item["4"]} />
            <NumberCell value={item["5"]} />
            <NumberCell value={item["6"]} />
            <NumberCell value={item["7"]} />
            {includesCA && (
              <>
                <NumberCell value={item["8"]} />
                <NumberCell value={item["9"]} />
              </>
            )}
          </TableRow>
        );
      })}
      <SICSummaryFooter includesCA={includesCA} items={items} />
    </TableBody>
  );
};

const SICSummaryFooter = ({ includesCA, items }) => {
  const totals = summaryTotals(items);

  return (
    <>
      {includesCA && items.length > 1 && (
        <TableRow key={"TOTALS_EXCL_CA"}>
          <TableCell align={"right"} colSpan={2}>
            <b>{"Excl. California"}</b>
          </TableCell>
          <TotalCell value={totals.allStates["1"] - totals.california["1"]} />
          <TotalCell value={totals.allStates["2"] - totals.california["2"]} />
          <TotalCell value={totals.allStates["3"] - totals.california["3"]} />
          <TotalCell value={totals.allStates["4"] - totals.california["4"]} />
          <TotalCell value={totals.allStates["5"] - totals.california["5"]} />
          <TotalCell value={totals.allStates["6"] - totals.california["6"]} />
          <TotalCell value={totals.allStates["7"] - totals.california["7"]} />
          <TotalCell value={totals.allStates["8"] - totals.california["8"]} />
          <TotalCell value={totals.allStates["9"] - totals.california["9"]} />
        </TableRow>
      )}
      <TableRow key={"TOTALS"}>
        <TableCell align={"right"} colSpan={2}>
          <b>{"Total"}</b>
        </TableCell>
        <TotalCell value={totals.allStates["1"]} />
        <TotalCell value={totals.allStates["2"]} />
        <TotalCell value={totals.allStates["3"]} />
        <TotalCell value={totals.allStates["4"]} />
        <TotalCell value={totals.allStates["5"]} />
        <TotalCell value={totals.allStates["6"]} />
        <TotalCell value={totals.allStates["7"]} />
        {includesCA && (
          <>
            <TotalCell value={totals.allStates["8"]} />
            <TotalCell value={totals.allStates["9"]} />
          </>
        )}
      </TableRow>
    </>
  );
};

const SICSummaryChart = ({ items }) => {
  const stateTotals = summaryTotals(items).allStates;
  const data = Object.entries(stateTotals).map(([k, v]) => {
    return { name: k, value: v };
  });
  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  return (
    <ResponsiveContainer width={"100%"} height={200}>
      <ComposedChart data={data} chartRef={chartRef}>
        <CartesianGrid strokeDasharray={"3 3"} />
        <XAxis dataKey={"name"} />
        <YAxis tickFormatter={chartUtils.tickFormatter} />
        <Bar dataKey={"value"} fill={"#FFAB53"} maxBarSize={40} />
        <Tooltip
          position={tooltipPosition}
          isAnimationActive={false}
          formatter={chartUtils.formatToolTip}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const SICSummary = ({ hazardSummary }) => {
  const states = hazardSummary?.value?.states ?? [];

  const includesCA = states.some((x) => x.state === "CA");
  const items = summaryItems(hazardSummary?.value);

  return (
    <>
      <Component title={"Premium by Hazard Group"}>
        {hazardSummary?.state === "RUNNING" ? (
          <DefaultLoader
            color={"#dc7f4c"}
            size={60}
            style={{
              display: "block",

              textAlign: "center",
            }}
          />
        ) : hazardSummary?.state === "FAILED" ? (
          <Alert severity={"error"}>{"Failed to calculate."}</Alert>
        ) : (
          <TableContainer>
            <Table size={"small"}>
              <SICSummaryHeader includesCA={includesCA} />
              <SICSummaryBody includesCA={includesCA} items={items} />
            </Table>
          </TableContainer>
        )}
      </Component>

      <Component title={"Total Premiums"}>
        {hazardSummary?.state === "RUNNING" ? (
          <DefaultLoader
            color={"#dc7f4c"}
            size={60}
            style={{
              display: "block",

              textAlign: "center",
            }}
          />
        ) : hazardSummary?.state === "FAILED" ? (
          <Alert severity={"error"}>{"Failed to calculate."}</Alert>
        ) : (
          <SICSummaryChart items={items} />
        )}
      </Component>
    </>
  );
};

export default SICSummary;
