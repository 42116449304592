import ClaimsComparisonSettingsDialog from "./ClaimsComparisonSettingsDialog";
import { Settings as SettingsIcon } from "@mui/icons-material";
import {
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from "@mui/material";
import { currencyFormat } from "common/numbers";
import { truncateStringWithEllipsis } from "common/strings";
import Component from "components/Component";
import IconButton from "components/common/IconButton";
import * as config from "config";
import * as _ from "lodash";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

const HighlightedTableCell = (props) => {
  return (
    <TableCell style={props.cellStyle}>
      <div
        style={
          props.priorValue !== props.value && props.priorValue != null
            ? {
                textAlign: "left",
                border: "2px solid #fcae83",
                borderRadius: "4px",
                ...props.divStyle,
              }
            : { textAlign: "left", ...props.divStyle }
        }
      >
        {props.hasTooltip ? (
          <Tooltip title={props?.value ?? "-"}>
            <div>
              {props?.value == null
                ? "-"
                : truncateStringWithEllipsis(props.value, { maxLength: 50 })}
            </div>
          </Tooltip>
        ) : (
          props?.value ?? "-"
        )}
      </div>
    </TableCell>
  );
};

const NumericalAlignCell = (props) => (
  <TableCell style={props.cellStyle}>
    <div
      style={{
        textAlign: "right",
        color:
          props.change == null || props.change === 0
            ? "#000"
            : props.change > 0
            ? "#0a0"
            : "#d00",
      }}
    >
      {props.value}
    </div>
  </TableCell>
);

const TextAlignTableCell = (props) => (
  <TableCell style={props.cellStyle}>
    <div style={{ textAlign: "left", ...props.divStyle }}>
      {props.value ?? "-"}
    </div>
  </TableCell>
);

const ComparisonRow = ({
  source,
  status,
  values,
  priorValues,
  delta,
  date,
  noBottomBorder,
}) => {
  const cellStyle = noBottomBorder ? { borderBottom: "none" } : {};
  const statusStyle =
    {
      New: {
        background: "#cfc",
        border: "2px solid #0c0",
        borderRadius: "4px",
        color: "#0a0",
      },
      Missing: {
        background: "#fcdfcf",
        border: "2px solid #fcae83",
        borderRadius: "4px",
        color: "#fa7b35",
      },

      Different: {
        background: "#fcc",
        border: "2px solid #f00",
        borderRadius: "4px",
        color: "#f00",
      },
    }[status] ?? {};
  return (
    <TableRow>
      <TextAlignTableCell value={source} cellStyle={cellStyle} />
      <TextAlignTableCell
        value={date}
        divStyle={{ width: "90px", textAlign: "left" }}
        cellStyle={cellStyle}
      />
      {(values?.textDeltas ?? []).map((value, index) => {
        return (
          <HighlightedTableCell
            key={index}
            value={value}
            priorValue={priorValues?.[index]}
            cellStyle={cellStyle}
          />
        );
      })}
      {(values?.numericDeltas ?? []).map((value, index) => {
        return (
          <NumericalAlignCell
            key={index}
            value={currencyFormat(value)}
            change={0}
            divStyle={{ textAlign: "right" }}
            cellStyle={cellStyle}
          />
        );
      })}
      <NumericalAlignCell
        value={
          status === "Different" && priorValues == null
            ? "-"
            : currencyFormat(delta)
        }
        change={status === "Different" && priorValues == null ? 0 : delta}
        divStyle={{ textAlign: "right" }}
        cellStyle={cellStyle}
      />
      <TextAlignTableCell
        value={
          status === "Different" ? (source === "Prior" ? status : "-") : status
        }
        divStyle={
          status === "Different" && source === "Current"
            ? {}
            : { width: "62px", ...statusStyle, textAlign: "center" }
        }
        cellStyle={cellStyle}
      />
    </TableRow>
  );
};

const ClaimsComparisonTable = ({ query, setQuery }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const priorQuery = useDebouncedValue(
    useSelector((state) =>
      claimsSelectors.selectInputClaims(stateSelectors.selectPriorState(state))
    )
  );
  const currentQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const hasPriorState = !_.isEmpty(
    useSelector(stateSelectors.selectPriorState)
  );

  const { data, isFetching, isError } = claimsService.useClaimsComparisonQuery(
    {
      ...query,
      priorQuery,
      currentQuery,
    },
    {
      skip: !hasPriorState || !priorQuery || !currentQuery,
    }
  );

  if (!hasPriorState) {
    return <></>;
  }

  const headers = ["LOB", "Claimant", "Description", "O/C", "Reported"];

  return (
    <Component
      title={
        query?.limit
          ? `Claims Comparison (Top ${query.limit})`
          : "Claims Comparison"
      }
      isFetching={isFetching}
      options={
        <IconButton
          variant={"bright"}
          scale={"small"}
          icon={SettingsIcon}
          tooltip={"Settings"}
          onClick={() => setIsSettingsOpen(true)}
        />
      }
    >
      <ClaimsComparisonSettingsDialog
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        query={query}
        setQuery={setQuery}
      />
      {isError ? (
        <Alert severity={"error"}>
          {`Error running claims comparison. Please contact ${config.SUPPORT_EMAIL}.`}
        </Alert>
      ) : (
        <TableContainer
          style={{
            maxHeight: 800,
            pointerEvents: isFetching ? "none" : null,
            transition: "150ms",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TextAlignTableCell value={"Source"} />
                <TextAlignTableCell
                  value={"Date"}
                  divStyle={{ width: "90px", textAlign: "left" }}
                />
                {headers.map((header, index) => {
                  return (
                    <TextAlignTableCell
                      key={index}
                      value={header}
                      divStyle={{ textAlign: "left" }}
                    />
                  );
                })}
                <TextAlignTableCell
                  value={"Delta"}
                  divStyle={{ textAlign: "center" }}
                />
                <TextAlignTableCell
                  value={"Status"}
                  divStyle={{ width: "60px", textAlign: "left" }}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {(data?.comparisons ?? []).map((comparison, i) => {
                if (comparison.status === "new") {
                  return (
                    <ComparisonRow
                      key={i}
                      source={"Current"}
                      status={"New"}
                      values={comparison?.current}
                      delta={comparison?.delta}
                      date={comparison?.date}
                    />
                  );
                } else if (comparison.status === "missing") {
                  return (
                    <ComparisonRow
                      key={i}
                      source={"Prior"}
                      status={"Missing"}
                      values={comparison?.prior}
                      delta={comparison?.delta}
                      date={comparison?.date}
                    />
                  );
                } else {
                  return (
                    <Fragment key={i}>
                      <ComparisonRow
                        source={"Prior"}
                        status={"Different"}
                        values={comparison?.prior}
                        delta={comparison?.delta}
                        date={comparison?.date}
                        noBottomBorder
                      />
                      <ComparisonRow
                        source={"Current"}
                        status={"Different"}
                        priorValues={comparison?.prior}
                        values={comparison?.current}
                        delta={comparison?.delta}
                        date={comparison?.date}
                      />
                    </Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Component>
  );
};

export default ClaimsComparisonTable;
