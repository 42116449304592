import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.persistence(args.url),
      args.payload || {},
      args.impersonateCompanyId
    );
    return {
      data: await response.json(),
    };
  } catch (error) {
    return { error };
  }
};

export const submissionsApi = createApi({
  reducerPath: "submissionsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    retrieveSubmissionSaveInfo: builder.query({
      query: ({ submissionId }) => ({
        url: "retrieve-submission-save-info",
        payload: {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ submissionId }),
        },
      }),
    }),
    createSubmission: builder.mutation({
      query: ({ renewFromSubmission, impersonateCompanyId } = {}) => ({
        url: "create-submission",
        payload: {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ renewFromSubmission }),
        },
        impersonateCompanyId,
      }),
    }),
    retrieveSubmission: builder.query({
      query: ({ submissionId, impersonateCompanyId } = {}) => ({
        url:
          "get?" +
          new URLSearchParams({
            filename: `${submissionId}.json`,
          }),
        payload: {
          method: "get",
          headers: {
            "Content-Type": "application/json",
          },
        },
        impersonateCompanyId,
      }),
    }),
    listSubmissions: builder.query({
      queryFn: async ({ impersonateCompanyId } = {}) => {
        const response = await utils.authenticatedFetch(
          config.endpoints.persistence("list-submissions"),
          { method: "POST" },
          impersonateCompanyId
        );
        const responseJson = await response.json();
        if (responseJson?.presignedUrl) {
          const presignedUrl = responseJson.presignedUrl;
          const redirectResponse = await utils.plainFetch(presignedUrl);
          const submissions = await redirectResponse.json();
          return {
            data: submissions,
          };
        } else {
          return {
            data: responseJson,
          };
        }
      },
    }),
    querySubmissions: builder.query({
      query: ({ query, cacheKey, sort, limit, skip }) => ({
        url: "query-submissions",
        payload: {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query, cacheKey, sort, limit, skip }),
        },
      }),
    }),
    listVersions: builder.query({
      query: ({ submissionId }) => ({
        url: "list-versions",
        payload: {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ submissionId }),
        },
      }),
    }),
    retrieveVersion: builder.query({
      query: ({ submissionId, versionId }) => ({
        url: "retrieve-version",
        payload: {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ submissionId, versionId }),
        },
      }),
    }),
    listComments: builder.query({
      query: ({ submissionId }) => ({
        url: "list-comments",
        payload: {
          body: JSON.stringify({ submissionId }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    createComment: builder.mutation({
      query: ({ submissionId, content, path, submissionVersionId }) => ({
        url: "create-comment",
        payload: {
          body: JSON.stringify({
            submissionId,
            content,
            path,
            submissionVersionId,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    generateNextPolicyNumber: builder.mutation({
      query: ({ submission, priorSubmission, layerId, config }) => ({
        url: "generate-next-policy-number",
        payload: {
          body: JSON.stringify({
            submission,
            priorSubmission,
            layerId,
            config,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    sendFeedback: builder.mutation({
      query: ({ type, message, submission, activeUrl }) => ({
        url: "send-feedback",
        payload: {
          body: JSON.stringify({
            type,
            message,
            submission,
            activeUrl,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    listAllocations: builder.query({
      query: ({ submissionId }) => ({
        url: "list-allocations",
        payload: {
          body: JSON.stringify({ submissionId }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    patchSubmission: builder.mutation({
      query: ({ submissionId, path, type, value }) => ({
        url: "patch-submission",
        payload: {
          body: JSON.stringify({ submissionId, patch: { path, type, value } }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    createAllocation: builder.mutation({
      query: ({
        submissionId,
        submissionVersionId,
        recipientEmail,
        comments,
        insured,
        effectiveDate,
        reference,
      }) => ({
        url: "create-allocation",
        payload: {
          body: JSON.stringify({
            submissionId,
            submissionVersionId,
            recipientEmail,
            comments,
            insured,
            effectiveDate,
            reference,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
  }),
});

export const {
  useRetrieveSubmissionSaveInfoQuery,
  useCreateSubmissionMutation,
  useLazyRetrieveSubmissionQuery,
  useListSubmissionsQuery,
  useQuerySubmissionsQuery,
  useListVersionsQuery,
  useRetrieveVersionQuery,
  useListCommentsQuery,
  useCreateCommentMutation,
  useGenerateNextPolicyNumberMutation,
  useSendFeedbackMutation,
  useListAllocationsQuery,
  usePatchSubmissionMutation,
  useCreateAllocationMutation,
} = submissionsApi;
