import { Alert, Stack } from "@mui/material";
import BigLoader from "components/common/BigLoader";
import { useEffect } from "react";

const ExtractTriangle = ({ transformStatus, applyTransform }) => {
  const status = transformStatus?.status;

  useEffect(() => {
    if (status === "pending") {
      applyTransform({});
    }
  }, [status, applyTransform]);

  return (
    <Stack direction={"column"} alignContent={"center"}>
      {status === "failed" ? (
        <Alert severity={"error"}>{"Error extracting triangle"}</Alert>
      ) : (
        <BigLoader />
      )}
    </Stack>
  );
};

export default ExtractTriangle;
