export const resolveRateChange = (rateChangeInput) => {
  if (!rateChangeInput) {
    return rateChangeInput;
  }
  let out = rateChangeInput;

  if (rateChangeInput?.layerDefaults && rateChangeInput?.byLayer) {
    out = {
      ...out,
      byLayer: Object.fromEntries(
        Object.entries(rateChangeInput.byLayer).map(([layerId, layer]) => [
          layerId,
          {
            ...rateChangeInput.layerDefaults,
            ...layer,
          },
        ])
      ),
    };
  }

  return out;
};
