import * as fileUtils from "../../../fileUtils";
import FilesFileUpload from "../../FilesFileUpload";
import DownloadButton from "../../common/DownloadButton";
import ClaimsDialog from "../ingestion/ClaimsDialog";
import FileListTree from "./FileListTree";
import GetAppIcon from "@mui/icons-material/GetApp";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import EmailReceiver from "components/inputs/attachments/EmailReceiver";
import ClaimsFileUploadDialog from "components/inputs/claims/ClaimsFileUploadDialog";
import ClaimsBetaBadge from "components/inputs/ingestion/ClaimsBetaBadge";
import SICFileUploadDialog from "components/inputs/sic/SICFileUploadDialog";
import { useRef, useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/input/attachments/attachmentsActions";
import * as attachTempSelectors from "store/selectors/temp/attachments/tempAttachmentsSelectors";

export const Attachments = ({ fileUploaded, attachedFilesForDownload }) => {
  const onDrop = (fileId) => {
    fileUploaded(fileId);
  };
  const startFileProcessingRef = useRef(null);
  return (
    <>
      <ClaimsDialog
        startFileProcessingRef={startFileProcessingRef}
        noDropBox={true}
      />
      <ClaimsFileUploadDialog />
      <SICFileUploadDialog />
      <Disabled ifReadOnly>
        <FilesFileUpload
          onDrop={onDrop}
          oneAtATime={false}
          asAtDateNotRequired={true}
        />
      </Disabled>
      <Disabled ifReadOnly>
        <EmailReceiver />
      </Disabled>
      <Component title={"Attachments"} options={<ClaimsBetaBadge />}>
        <FileListTree startFileProcessingRef={startFileProcessingRef} />
        <DownloadAll attachedFilesForDownload={attachedFilesForDownload} />
      </Component>
    </>
  );
};

const DownloadAll = ({ attachedFilesForDownload }) => {
  const [isLoading, setIsLoading] = useState(0);
  class counter {
    constructor(num) {
      this.count = num;
      setIsLoading(num);
    }
    dec() {
      this.count -= 1;
      setIsLoading(this.count);
    }
  }
  const doDownload = async () => {
    const cnt = new counter(Object.keys(attachedFilesForDownload).length);

    attachedFilesForDownload.forEach((e) => {
      fileUtils
        .saveFile(e.id, e.name)
        .then(() => cnt.dec())
        .catch(() => cnt.dec());
    });
  };
  return (
    <div style={{ display: "flex", justifyContent: "end" }}>
      <DownloadButton
        sx={{ marginLeft: "auto" }}
        onClick={doDownload}
        isLoading={isLoading > 0}
      >
        <GetAppIcon /> {"Download All"}
      </DownloadButton>
    </div>
  );
};

const mapDispatchToProps = {
  fileUploaded: actions.fileUploaded,
};

const mapStateToProps = (state) => ({
  attachedFilesForDownload: attachTempSelectors.attachedFilesFlattened(state),
  downloadErrors: new Set(state.input.attachments?.downloadErrors || []),
  validClaimsFileFormats: state.temp?.staticData?.validClaimsFileFormats ?? [],
});

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);
