import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as time from "common/time";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.files(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

const awaitCsvStatus = async (fileId) => {
  let waitTime = 500;
  while (true) {
    const data = await baseQuery({
      url: "retrieve-csv-metadata-status",
      payload: {
        body: JSON.stringify({ id: fileId }),
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      },
    });
    if (
      data?.data?.csvStatus != null &&
      data?.data?.csvStatus !== "FILE_NOT_READY"
    )
      return data;
    await time.sleep(waitTime);
    if (waitTime < 3000) waitTime *= 1.1 + Math.random() * 0.5;
  }
};

export const filesApi = createApi({
  reducerPath: "filesApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getCsvMetadataStatus: builder.query({
      queryFn: async (fileId) => {
        return await awaitCsvStatus(fileId);
      },
    }),
    getExtractMetadata: builder.query({
      query: (fileId) => ({
        url: `get-extraction-metadata/${fileId}`,
      }),
    }),
    getFileMetadata: builder.query({
      query: (fileId) => ({
        url: `get-status/${fileId}`,
      }),
    }),
    getTableList: builder.query({
      query: (fileId) => ({
        url: `table-list/${fileId}`,
      }),
    }),
    getSubtableColumnList: builder.query({
      query: (extractionSpec) => ({
        url: "extract-subtable-columns",
        payload: {
          body: JSON.stringify({
            id: extractionSpec.fileId,
            sheet_name: extractionSpec.sheet.name,
            skip_rows: extractionSpec.skipRows,
            skip_columns: extractionSpec.skipColumns,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    cleanOfficeFile: builder.mutation({
      query: (fileId) => ({
        url: `clean-office-file/${fileId}`,
      }),
    }),
    extractTable: builder.mutation({
      query: (body) => ({
        url: "table-extract",
        payload: {
          body: JSON.stringify(body),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    fileUrl: builder.query({
      query: (fileId) => ({
        url: `get-download-url/${fileId}`,
      }),
    }),
    fileBlob: builder.query({
      queryFn: async (fileId) => {
        try {
          const urlResponse = await utils.authenticatedFetch(
            config.endpoints.files(`get-download-url/${fileId}`)
          );
          const urls = await urlResponse.json();

          const response = await utils.plainFetch(urls.url);
          return {
            data: await response.blob(),
          };
        } catch (e) {
          logger.exception(e);
          return {
            error: String(e),
          };
        }
      },
    }),
    htmlFileBlob: builder.query({
      queryFn: async (fileId) => {
        try {
          const urlResponse = await utils.authenticatedFetch(
            config.endpoints.files(`get-html/${fileId}`)
          );
          const urls = await urlResponse.json();

          const response = await utils.plainFetch(urls.primary);
          return {
            data: await response.blob(),
          };
        } catch (e) {
          logger.exception(e);
          return {
            error: String(e),
          };
        }
      },
    }),
    downloadExcelTable: builder.mutation({
      queryFn: async ({
        fileIds,
        sheetName,
        columnMap,
        fileName,
        pollingIntervalSeconds = 1,
      }) => {
        try {
          let response = await utils.authenticatedFetch(
            config.endpoints.files("create-excel-table"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ fileIds, sheetName, columnMap }),
            }
          );
          const creationResponse = await response.json();
          const id = creationResponse.id;

          const delay = (seconds) =>
            new Promise((res) => setTimeout(res, 1000 * seconds));

          let status = null;
          while (
            status == null ||
            ["pending", "running"].includes(status.state)
          ) {
            await delay(pollingIntervalSeconds);
            response = await utils.authenticatedFetch(
              config.endpoints.files("retrieve-excel-table"),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id }),
              }
            );
            status = await response.json();
          }
          if (status.state !== "succeeded") {
            throw new Error(status);
          }
          response = await fetch(status.downloadUrl);
          const blob = await response.blob();
          FileSaver.saveAs(blob, fileName);
          return { data: status };
        } catch (e) {
          logger.exception(e);
          return {
            error: String(e),
          };
        }
      },
    }),
  }),
});

export const {
  useGetCsvMetadataStatusQuery,
  useGetExtractMetadataQuery,
  useGetFileMetadataQuery,
  useGetTableListQuery,
  useExtractTableMutation,
  useGetSubtableColumnListQuery,
  useCleanOfficeFileMutation,
  useFileUrlQuery,
  useFileBlobQuery,
  useHtmlFileBlobQuery,
  useDownloadExcelTableMutation,
} = filesApi;
