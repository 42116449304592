import DefaultLoader from "./DefaultLoader";
import React from "react";

export const SmallLoader = ({ color }) => (
  <div style={{ width: "40px" }}>
    <DefaultLoader
      color={color ?? "#dc7f4c"}
      size={16}
      style={{
        display: "block",
        textAlign: "center",
      }}
    />
  </div>
);

export default SmallLoader;
