import * as peersActionTypes from "../../../actions/peers/peersActionTypes";
import * as persistenceActionTypes from "../../../actions/persistence/persistenceActionTypes";

export const INIT_STATE = {
  group: { loading: false },
  exposure: { loading: false },
};

const peerPricingReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case persistenceActionTypes.NEW_SUBMISSION:
    case persistenceActionTypes.RENEW_SUBMISSION:
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case peersActionTypes.PEER_PRICING_RECEIVED:
      return {
        ...state,
        group: {
          ...state.group,
          chart: action.payload.group,
          loading: false,
        },
        exposure: {
          ...state.exposure,
          chart: action.payload.exposure,
          loading: false,
        },
      };
    case peersActionTypes.PEER_PRICING_UPDATING:
      return {
        ...state,
        group: {
          ...state.group,
          chart: undefined,
          loading: true,
        },
        exposure: {
          ...state.exposure,
          chart: undefined,
          loading: true,
        },
      };
    case peersActionTypes.LOAD_PEER:
      return {
        ...state,
        peerData: {
          ...(state.peerData || {}),
          [action.payload.filename]: action.payload.state,
        },
      };
    case peersActionTypes.ADD_PEER:
      return {
        ...state,
        peerData: {
          ...(state.peerData || {}),
          [action.payload.filename]: undefined,
        },
      };
    case peersActionTypes.DELETE_PEER:
      return {
        ...state,
        peerData: {
          ...(state.peerData || {}),
          [action.payload]: undefined,
        },
      };
    default:
      return state;
  }
};

export default peerPricingReducer;
