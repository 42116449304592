import { Stack } from "@mui/material";
import {
  EX_ANTE_AVG,
  EX_ANTE_AVG_LAST_3_YEARS,
  EX_ANTE_AVG_LAST_5_YEARS,
  AVG,
  AVG_LAST_3_YEARS,
  AVG_LAST_5_YEARS,
} from "common/averages";
import { abbreviated, percentage } from "common/numbers";
import Component from "components/Component";
import {
  HeadingCell,
  ValueCell,
  InputCell,
  ClickableCell,
} from "components/inputs/lossDevelopment/Cell";
import Ultimate from "components/inputs/lossDevelopment/Ultimate";
import { connect } from "react-redux";
import * as lossDevelopmentStore from "store/lossDevelopment";
import { MODELS } from "store/lossDevelopment";

const Ultimates = ({
  triangles,
  ultimate,
  selection,
  updateUltimateSelection,
  updateUltimateOverrideSelection,
  updateUltimateManualOverrideSelection,
  updateLossRatioManualOverride,
  updateLossRatioSelection,
  policyYears,
}) => {
  return (
    <Component title={"Ultimate"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"row"}>
          <Stack direction={"column"}>
            <HeadingCell label={""} />
            <HeadingCell label={"Year"} />
            {policyYears?.map((policyYear) => (
              <HeadingCell label={policyYear} key={policyYear} />
            ))}
          </Stack>
          {Object.keys(triangles).map((id) => {
            return (
              <Stack direction={"column"}>
                <HeadingCell
                  label={triangles[id].name}
                  sx={{ width: "13.25rem" }}
                  centered
                />
                <Stack direction={"row"}>
                  {MODELS.map(({ name: label, method }) => {
                    return (
                      <>
                        <Ultimate
                          id={id}
                          key={id}
                          label={label}
                          method={method}
                          headingStyles={{ width: "100%" }}
                          centered
                          onHeaderClick={({ method }) => {
                            updateUltimateSelection({ id, method });
                          }}
                          isHeaderActive={({ method }) => {
                            return (
                              ultimate.selected &&
                              ultimate.selected.id === id &&
                              ultimate.selected.method === method
                            );
                          }}
                          onCellClick={({ method, year }) => {
                            updateUltimateOverrideSelection({
                              id,
                              year,
                              method,
                            });
                          }}
                          isCellActive={({ method, year }) => {
                            const override = ultimate.selectedOverrides?.[year];
                            return (
                              override != null &&
                              override.method === method &&
                              override.id === id
                            );
                          }}
                          policyYears={policyYears}
                        />
                      </>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
        <Stack direction={"column"}>
          <HeadingCell label={""} />
          <Stack direction={"row"}>
            <Stack alignItems={"center"}>
              <Stack flexDirection={"row"}>
                <HeadingCell centered={true} label={"Ultimate"} />
              </Stack>
              {policyYears.map((year, index) => (
                <Stack flexDirection={"row"} key={index}>
                  <InputCell
                    borderless={true}
                    abbreviated
                    value={selection?.ultimate?.[year] ?? 0}
                    active={ultimate.manualOverrides?.[year] != null}
                    onChangeNumber={(value) => {
                      updateUltimateManualOverrideSelection({ year, value });
                    }}
                    padding={"0"}
                  />
                </Stack>
              ))}
            </Stack>
            <Stack alignItems={"center"}>
              <HeadingCell centered={true} label={"On-Level Premium"} />
              {policyYears.map((year, index) => (
                <ValueCell borderless={true} key={index}>
                  {abbreviated(selection?.premium?.[year]) ?? 0}
                </ValueCell>
              ))}
              <ValueCell borderless={true} />
              <ValueCell borderless={true}>{"Last 3"}</ValueCell>
              <ValueCell borderless={true}>{"Last 5"}</ValueCell>
              <ValueCell borderless={true}>{"All Years"}</ValueCell>
              <ValueCell borderless={true}>{"Ex-Ante 3"}</ValueCell>
              <ValueCell borderless={true}>{"Ex-Ante 5"}</ValueCell>
              <ValueCell borderless={true}>{"Ex-Ante"}</ValueCell>
              <ValueCell borderless={true}>{"Selected"}</ValueCell>
            </Stack>
            <Stack alignItems={"center"}>
              <HeadingCell centered={true} label={"Loss Ratio"} />
              {policyYears.map((year, index) => (
                <ValueCell key={index} borderless={true}>
                  {percentage(2)(selection?.lossRatio?.byYear?.[year])}
                </ValueCell>
              ))}
              <ValueCell borderless={true} />
              {[
                AVG_LAST_3_YEARS,
                AVG_LAST_5_YEARS,
                AVG,
                EX_ANTE_AVG_LAST_3_YEARS,
                EX_ANTE_AVG_LAST_5_YEARS,
                EX_ANTE_AVG,
              ].map((avgType, index) => (
                <ClickableCell
                  borderless={true}
                  key={index}
                  active={selection?.lossRatio?.selection?.selected === avgType}
                  onClick={() =>
                    updateLossRatioSelection({ selected: avgType })
                  }
                >
                  {percentage(2)(selection?.lossRatio?.summary?.[avgType])}
                </ClickableCell>
              ))}
              <InputCell
                borderless={true}
                value={selection?.lossRatio?.selection?.value}
                active={selection?.lossRatio?.selection?.isManualOverride}
                onChangeNumber={(manualOverride) =>
                  updateLossRatioManualOverride({ manualOverride })
                }
                percentage
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Component>
  );
};

const mapDispatchToProps = {
  updateUltimateSelection: lossDevelopmentStore.updateUltimateSelection,
  updateUltimateOverrideSelection:
    lossDevelopmentStore.updateUltimateOverrideSelection,
  updateUltimateManualOverrideSelection:
    lossDevelopmentStore.updateUltimateManualOverrideSelection,
  updateLossRatioSelection: lossDevelopmentStore.updateLossRatioSelection,
  updateLossRatioManualOverride:
    lossDevelopmentStore.updateLossRatioManualOverride,
};

const mapStateToProps = (state) => ({
  triangles: lossDevelopmentStore.selectLossDevelopment(state).triangles ?? [],
  ultimate: lossDevelopmentStore.selectLossDevelopment(state).ultimate ?? {},
  selection: lossDevelopmentStore.selectUltimateSummary(state),
  policyYears: lossDevelopmentStore.selectPolicyYears(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ultimates);
