import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { normaliseRatio, normaliseQuotient } from "common/numbers";
import { toSentenceCase } from "common/strings";
import SeverityBadge from "components/common/SeverityBadge";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import { Link } from "react-router-dom";

const sortByScenarioAndLevel = (scenarios, scenarioLabels) => {
  const copy = JSON.parse(JSON.stringify(scenarios));

  return copy.sort((a, b) => {
    const scenarioA =
      scenarioLabels[a.scenario_key] || toSentenceCase(a.scenario_key);
    const scenarioB =
      scenarioLabels[b.scenario_key] || toSentenceCase(b.scenario_key);
    return (
      scenarioA.localeCompare(scenarioB) ||
      b.scenario_level - a.scenario_level ||
      b.insured.localeCompare(a.insured)
    );
  });
};

const DetailsByScenario = ({ scenarios, scenarioLabels, aggregateMode }) => {
  const data = sortByScenarioAndLevel(scenarios, scenarioLabels);
  let prevScenario = null;

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            {!aggregateMode && <TableCell>{"Scenario"}</TableCell>}
            <TableCell align={"center"}>{"Risk"}</TableCell>
            <TableCell>{"Insured"}</TableCell>
            <TableCell>{"Industry"}</TableCell>
            <TableCell>{"Inception"}</TableCell>
            <TableCell>{"Expiry"}</TableCell>
            <TableCell align={"right"}>{"Attachement"}</TableCell>
            <TableCell align={"right"}>{"Limit"}</TableCell>
            <TableCell align={"right"}>{"Line Size"}</TableCell>
            {aggregateMode && (
              <>
                <TableCell align={"right"}>{"Share of GWP"}</TableCell>
                <TableCell align={"right"}>{"Premium : Limit"}</TableCell>
                <TableCell align={"right"}>{"Payback years"}</TableCell>
              </>
            )}
            <TableCell>{"Status"}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            const matchesNext =
              index + 1 < data.length &&
              row.scenario_key === data[index + 1].scenario_key;
            const matchesPrev = row.scenario_key === prevScenario;
            prevScenario = row.scenario_key;

            return (
              <TableRow
                key={`${row.scenario_key}:${row.submission_id}:${row.layer_id}`}
                sx={{ verticalAlign: "top" }}
              >
                {!aggregateMode && (
                  <TableCell sx={{ borderBottom: matchesNext ? "none" : null }}>
                    {!matchesPrev && (
                      <b>
                        {scenarioLabels[row.scenario_key] ||
                          toSentenceCase(row.scenario_key)}
                      </b>
                    )}
                  </TableCell>
                )}
                <TableCell
                  align={"center"}
                  sx={{
                    borderBottom: matchesNext && !aggregateMode ? "none" : null,
                  }}
                >
                  <SeverityBadge level={row.scenario_level} />
                </TableCell>
                <TableCell>{row.insured ?? "???"}</TableCell>
                <TableCell>
                  {toSentenceCase(row.industry_class ?? "-")}
                </TableCell>
                <TableCell>{row.inception}</TableCell>
                <TableCell>{row.expiration}</TableCell>
                <TableCell align={"right"}>
                  {(row.attachment ?? 0).toLocaleString()}
                </TableCell>
                <TableCell align={"right"}>
                  {(row.limit ?? 0).toLocaleString()}
                </TableCell>
                <TableCell align={"right"}>
                  {(row.line_size ?? 0).toLocaleString()}
                </TableCell>
                {aggregateMode && (
                  <>
                    <TableCell align={"right"}>
                      {(row.share_of_premium ?? 0).toLocaleString()}
                    </TableCell>
                    <TableCell align={"right"}>
                      {normaliseRatio(row.share_of_premium, row.line_size, "-")}
                    </TableCell>
                    <TableCell align={"right"}>
                      {normaliseQuotient(
                        row.share_of_premium,
                        row.line_size,
                        "-"
                      )}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <StatusBadgeV2 status={row.status} />
                </TableCell>
                <TableCell>
                  <Link
                    to={`/submissions/${row.submission_id}/inputs/scenarios`}
                    target={"_blank"}
                  >
                    <OpenInNewIcon />
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailsByScenario;
