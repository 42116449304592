import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as numbers from "common/numbers";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import Visible from "components/common/Visible";
import { Fragment } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as utils from "utils";

const percentFormatted = numbers.dpString(0, { suffix: "%" });

const getLayerMeta = (layer, numberLayers, index) => {
  if (layer.attachment === 0) {
    const enDashChar = "\u2013";

    return {
      title: "Retentions",
      description: enDashChar,
      size: numbers.abbreviated(layer.limit),
      isRetention: true,
      gwp: enDashChar,
      rpm: enDashChar,
      rate: enDashChar,
    };
  }

  return {
    title: `Layer ${numberLayers - index}`,
    description: `${layer.shares.map((x) => x.carrier).join(", ")}`,
    size: utils.shortLayerName(layer),
    gwp: numbers.abbreviated(layer.grossPremium),
    rpm: numbers.abbreviated(layer.grossRPM),
    rate: isNaN(layer?.towerPricing?.rateRelativeToLayerBelow ?? NaN)
      ? ""
      : percentFormatted(layer.towerPricing.rateRelativeToLayerBelow * 100),
  };
};

const getLayerClass = (attachment, isParticipant) => {
  if (attachment === 0) {
    return "layer dim";
  }
  if (isParticipant) {
    return "layer highlight";
  }
  return "layer";
};

const LabelledData = ({ label, data, align, minWidth }) => {
  const style = {
    textAlign: align,
    minWidth: minWidth,
  };

  return !!data ? (
    <Stack style={style}>
      <Typography variant={"overline"}>{label}</Typography>
      <Typography variant={"subtitle2"}>{data}</Typography>
    </Stack>
  ) : (
    <Box style={style} />
  );
};

const TowerDiagramLayer = (layer, numberLayers, index, isParticipant) => {
  const meta = getLayerMeta(layer, numberLayers, index);
  return (
    <Fragment key={`TOWER_${index}`}>
      <div className={getLayerClass(layer.attachment, isParticipant)}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={"0.5rem"}>
            <LabelledData
              label={meta.title}
              data={meta.size}
              minWidth={"6rem"}
            />
            <LabelledData label={"Participants"} data={meta.description} />
          </Stack>

          <Stack direction={"row"} flexShrink={"0"} gap={"0.5rem"}>
            <LabelledData
              label={"GWP"}
              data={meta.gwp}
              align={"center"}
              minWidth={"4rem"}
            />
            <LabelledData
              label={"RPM"}
              data={meta.rpm}
              align={"center"}
              minWidth={"4rem"}
            />
            <LabelledData
              label={"Relative Rate"}
              data={meta.rate}
              align={"center"}
              minWidth={"6rem"}
            />
          </Stack>
        </Stack>
      </div>
    </Fragment>
  );
};

const TowerDiagram = ({ tower, pricingLayers }) => {
  return (
    <div className={"tower-diagram"}>
      {tower.map((layer, index) => {
        const numberLayers = tower.length - 1;

        const isParticipant = pricingLayers.some((x) => {
          return numbers.rangesOverlap(
            x.attachment,
            x.attachment + x.limit,
            layer.attachment,
            layer.attachment + layer.limit
          );
        });

        return TowerDiagramLayer(layer, numberLayers, index, isParticipant);
      })}
    </div>
  );
};

const TowerParticipationRow = ({ layer }) => {
  return (
    <TableRow>
      <Visible byTag={"summary.print.layers.status"} defaultVisible={true}>
        <TableCell align={"center"}>
          <StatusBadgeV2 status={layer.status} variant={"chip"} />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.ourLimit"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={layer.lineSize}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.partOf"} defaultVisible={true}>
        <TableCell align={"center"}>
          {numbers.abbreviated(layer.limit)}
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.excess"} defaultVisible={true}>
        <TableCell align={"center"}>
          {numbers.abbreviated(layer.attachment)}
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.premium"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={layer.grossPremium}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.rpm"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={layer.grossPPM}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.ourGross"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={layer.shareOfPremium}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.ourNet"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={((100 - layer.tac) / 100) * layer.shareOfPremium}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.tac"} defaultVisible={true}>
        <TableCell align={"center"}>
          {layer.tac}
          {"%"}
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.tp"} defaultVisible={true}>
        <TableCell align={"center"}>
          <NumberFormat
            displayType={"text"}
            decimalScale={0}
            suffix={"%"}
            value={layer.uwSelectedMeasure?.targetPrice}
            className={
              (layer.uwSelectedMeasure?.targetPrice ?? 100) - 100 < 0
                ? "table-cell-bad-deal"
                : "table-cell-good-deal"
            }
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.gnulr"} defaultVisible={true}>
        <TableCell align={"center"}>
          <NumberFormat
            displayType={"text"}
            decimalScale={2}
            suffix={"%"}
            value={layer.uwSelectedMeasure?.technicalRatio}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.brokerage"} defaultVisible={false}>
        <TableCell align={"center"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={2}
            suffix={"%"}
            value={layer.brokerage}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.slipFee"} defaultVisible={false}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            suffix={"%"}
            value={layer.slipFee}
          />
        </TableCell>
      </Visible>
    </TableRow>
  );
};

const TowerParticipationSummaryRow = ({ pricingLayers }) => {
  const limitReducer = (acc, current) =>
    acc + (current.bound ? current.lineSize : 0);
  const grossReducer = (acc, current) =>
    acc + (current.bound ? current.shareOfPremium : 0);
  const netReducer = (acc, current) =>
    acc +
    (current.bound ? (1 - current.tac / 100) * current.shareOfPremium : 0);
  const costReducer = (acc, current) =>
    acc + (current.bound ? (current.tac / 100) * current.shareOfPremium : 0);
  const totalSlipFee = pricingLayers.reduce(
    (acc, layer) =>
      acc + (layer.bound ? (layer.slipFee / 100) * layer.shareOfPremium : 0),
    0
  );
  const averageBrokerage = pricingLayers.reduce(
    (acc, layer) =>
      acc + (layer.bound ? (layer.brokerage / 100) * layer.shareOfPremium : 0),
    0
  );
  return (
    <TableRow>
      <Visible byTag={"summary.print.layers.status"} defaultVisible={true}>
        <TableCell align={"center"}>
          <b>{"Total Bound"}</b>
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.ourLimit"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={pricingLayers.reduce(limitReducer, 0)}
            style={{ fontWeight: 600 }}
          />
        </TableCell>
      </Visible>

      {["partOf", "excess", "premium", "rpm"].map((tag) => (
        <Visible
          key={tag}
          byTag={`summary.print.layers.${tag}`}
          defaultVisible={true}
        >
          <TableCell></TableCell>
        </Visible>
      ))}

      <Visible byTag={"summary.print.layers.ourGross"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={pricingLayers.reduce(grossReducer, 0)}
            style={{ fontWeight: 600 }}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.ourNet"} defaultVisible={true}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={pricingLayers.reduce(netReducer, 0)}
            style={{ fontWeight: 600 }}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.tac"} defaultVisible={true}>
        <TableCell align={"center"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={0}
            prefix={"$"}
            value={pricingLayers.reduce(costReducer, 0)}
            style={{ fontWeight: 600 }}
          />
        </TableCell>
      </Visible>

      {["tp", "gnulr"].map((tag) => (
        <Visible
          key={tag}
          byTag={`summary.print.layers.${tag}`}
          defaultVisible={true}
        >
          <TableCell></TableCell>
        </Visible>
      ))}

      <Visible byTag={"summary.print.layers.brokerage"} defaultVisible={false}>
        <TableCell align={"center"}>
          <NumberFormat
            displayType={"text"}
            decimalScale={2}
            thousandSeparator
            prefix={"$"}
            value={averageBrokerage}
            style={{ fontWeight: 600 }}
          />
        </TableCell>
      </Visible>

      <Visible byTag={"summary.print.layers.slipFee"} defaultVisible={false}>
        <TableCell align={"right"}>
          <NumberFormat
            displayType={"text"}
            thousandSeparator
            decimalScale={2}
            prefix={"$"}
            value={totalSlipFee}
            style={{ fontWeight: 600 }}
          />
        </TableCell>
      </Visible>
    </TableRow>
  );
};

const TowerParticipation = ({ pricingLayers }) => {
  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <Visible
              byTag={"summary.print.layers.status"}
              defaultVisible={true}
            >
              <TableCell align={"center"}>{"Status"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.ourLimit"}
              defaultVisible={true}
            >
              <TableCell align={"right"}>{"Our Limit"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.partOf"}
              defaultVisible={true}
            >
              <TableCell align={"center"}>{"Part Of"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.excess"}
              defaultVisible={true}
            >
              <TableCell align={"center"}>{"Excess"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.premium"}
              defaultVisible={true}
            >
              <TableCell align={"right"}>{"Premium"}</TableCell>
            </Visible>

            <Visible byTag={"summary.print.layers.rpm"} defaultVisible={true}>
              <TableCell align={"right"}>{"RPM"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.ourGross"}
              defaultVisible={true}
            >
              <TableCell align={"right"}>{"Our Gross"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.ourNet"}
              defaultVisible={true}
            >
              <TableCell align={"right"}>{"Our Net"}</TableCell>
            </Visible>

            <Visible byTag={"summary.print.layers.tac"} defaultVisible={true}>
              <TableCell align={"center"}>{"TAC"}</TableCell>
            </Visible>

            <Visible byTag={"summary.print.layers.tp"} defaultVisible={true}>
              <TableCell align={"center"}>{"TP"}</TableCell>
            </Visible>

            <Visible byTag={"summary.print.layers.gnulr"} defaultVisible={true}>
              <TableCell align={"center"}>{"GNULR"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.brokerage"}
              defaultVisible={false}
            >
              <TableCell align={"center"}>{"Brokerage"}</TableCell>
            </Visible>

            <Visible
              byTag={"summary.print.layers.slipFee"}
              defaultVisible={false}
            >
              <TableCell align={"center"}>{"Slip Fee"}</TableCell>
            </Visible>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...pricingLayers]
            .sort((a, b) => b.attachment - a.attachment)
            .map((layer, index) => (
              <TowerParticipationRow
                layer={layer}
                key={`TOWER_TABLE_${index}`}
              />
            ))}
          <TowerParticipationSummaryRow pricingLayers={pricingLayers} />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const countLayers = (pricingLayers) => {
  const numberLayers = (pricingLayers ?? []).length;
  if (numberLayers === 1 && pricingLayers[0].lineSize == null) {
    return 0;
  }
  return numberLayers;
};

const Tower = ({ tower, pricingLayers }) => {
  const hasTower = (tower ?? []).length > 0;
  const hasLayers = countLayers(pricingLayers) > 0;

  return (
    <>
      {hasTower && (
        <Visible byTag={"<Print>.tower.tower"} defaultVisible>
          <Stack className={"print-section"}>
            <span className={"print-label"} style={{ marginBottom: "8px" }}>
              {"Tower"}
            </span>
            <TowerDiagram tower={tower} pricingLayers={pricingLayers} />
          </Stack>
        </Visible>
      )}

      {hasLayers && (
        <Visible byTag={"<Print>.tower.pricing"} defaultVisible>
          <Stack className={"print-section"}>
            <span className={"print-label"}>{"Pricing Results"}</span>
            <TowerParticipation pricingLayers={pricingLayers} />
          </Stack>
        </Visible>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    tower: towerPricingSelectors.getInvertedTower(state),
    pricingLayers: pricingSelectors.getTower(state),
  };
};

export default connect(mapStateToProps)(Tower);
