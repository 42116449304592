import { Checkbox } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import React from "react";

const CheckboxCell = (props) => {
  return (
    <TableCell
      align={"center"}
      onMouseEnter={() => props.setHighlight(props.i)}
      onMouseLeave={() => props.setHighlight(-1)}
      className={props.highlighted ? "highlighted" : ""}
      width={props.width}
    >
      <Checkbox
        color={props.isSuggestion ? "default" : undefined}
        checked={props.checked}
        onClick={() =>
          props.mappingUpdated(
            props.source,
            props.target,
            props.isSuggestion ? false : !props.checked
          )
        }
      />
    </TableCell>
  );
};

export default React.memo(CheckboxCell);
