import * as support from "./WarnOnPageExit.support";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "components/common/Button";
import {
  NOT_SAVED_APP_EXIT_WARNING,
  NOT_SAVED_INTER_PAGE_WARNING,
} from "flags";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as navigationSelectors from "store/selectors/temp/staticData/navigation/navigationSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const LeavePageDialog = ({ open, doContinue, doAbort }) => (
  <Dialog open={open} maxWidth={"md"} fullWidth>
    <DialogTitle>{"Unsaved Data"}</DialogTitle>
    <DialogContent>
      <div>{"The information entered has not been saved!"}</div>
      <div>{"Do you wish to leave this page?"}</div>
    </DialogContent>
    <DialogActions>
      <Button onClick={doAbort} color={"primary"}>
        {"Return to previous page"}
      </Button>
      <Button onClick={doContinue} color={"secondary"}>
        {"Continue and leave page"}
      </Button>
    </DialogActions>
  </Dialog>
);

const useWarnOnPageChange = ({ enabled }) => {
  const loaded = useSelector(persistenceSelectors.loaded);
  const currentState = useSelector(
    navigationSelectors.selectSavedPersistentState
  );
  const persistedState = useSelector(persistenceSelectors.selectPersistedState);
  const navigate = useNavigate();
  const [isLeavePageDialogOpen, setIsLeavePageDialogOpen] = useState(false);
  const [previousLocation, setPreviousLocation] = useState(null);
  const location = useLocation();

  const onLeavePageAbort = () => {
    if (enabled) navigate(-1);
    setIsLeavePageDialogOpen(false);
  };
  const onLeavePageContinue = () => {
    setPreviousLocation(location);
    setIsLeavePageDialogOpen(false);
  };

  useEffect(() => {
    if (
      enabled &&
      loaded &&
      support.isLeavingSubmission({ previousLocation, location })
    ) {
      if (support.isSaveExpected(persistedState, currentState))
        setIsLeavePageDialogOpen(true);
      else {
        setIsLeavePageDialogOpen(false);
        setPreviousLocation(location);
      }
    } else {
      setPreviousLocation(location);
    }
  }, [
    location,
    previousLocation,
    setPreviousLocation,
    enabled,
    loaded,
    currentState,
    persistedState,
  ]);

  return { isLeavePageDialogOpen, onLeavePageAbort, onLeavePageContinue };
};

const useWarnOnPageExit = ({ enabled }) => {
  const location = useLocation();

  const currentState = useSelector(
    navigationSelectors.selectSavedPersistentState
  );
  const persistedState = useSelector(persistenceSelectors.selectPersistedState);

  useEffect(() => {
    const isLeavingSubmission = support.isLeavingSubmission({
      previousLocation: location,
      location: null,
    });
    if (enabled && isLeavingSubmission) {
      const handleBeforeUnload = (event) => {
        if (support.isSaveExpected(persistedState, currentState)) {
          event.preventDefault();
          event.returnValue = "";
        } else return null;
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [enabled, currentState, persistedState, location]);
};

const WarnOnPageExit = ({ children }) => {
  const userAttributes = useSelector(userSelectors.selectUserAttributes);

  const {
    isLeavePageDialogOpen,
    onLeavePageAbort,
    onLeavePageContinue,
  } = useWarnOnPageChange({
    enabled: !!userAttributes?.config?.componentVisibility?.[
      NOT_SAVED_INTER_PAGE_WARNING
    ],
  });

  useWarnOnPageExit({
    enabled: !!userAttributes?.config?.componentVisibility?.[
      NOT_SAVED_APP_EXIT_WARNING
    ],
  });

  return (
    <>
      <LeavePageDialog
        open={isLeavePageDialogOpen}
        doAbort={onLeavePageAbort}
        doContinue={onLeavePageContinue}
      />
      {children}
    </>
  );
};

export default WarnOnPageExit;
