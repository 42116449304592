import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Markdown from "components/common/Markdown";
import { ExposureFlagCells } from "components/inputs/flags/ExposureFlags";

const ExposureFlags = (props) => {
  const hasComments = props.exposures.some(({ comments }) => !!comments);

  const includeComments = hasComments;

  return (
    <Grid item xs={6}>
      <span className={"print-label"}>{"Exposure Flags"}</span>

      <TableContainer>
        <Table size={"small"} className={"flags"}>
          <TableHead>
            <TableRow>
              <TableCell>{"Type"}</TableCell>
              <TableCell>{"Category"}</TableCell>
              {includeComments && <TableCell>{"Comments"}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.exposures.map((flag, i) => (
              <TableRow key={i}>
                <ExposureFlagCells flag={flag} />
                {includeComments && (
                  <TableCell
                    sx={{ verticalAlign: "top" }}
                    className={"cell-condensed-padding-md"}
                  >
                    <Markdown value={flag.comments} />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default ExposureFlags;
