import { Box, Stack, Typography } from "@mui/material";

const Badge = ({ colour, size = 10 }) => {
  const sx = {
    display: "inline-block",
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size / 2}px`,
    background: colour,
    flexShrink: 0,
  };

  return <Box sx={sx} marginRight={0.75}></Box>;
};

const LabelledBadge = ({ colour, label }) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Badge colour={colour} />
      <Typography variant={"subtitle2"} flexShrink={0}>
        {label}
      </Typography>
    </Stack>
  );
};

const ReviewStatusBadge = ({ status, label, variant }) => {
  const isListItem = (variant ?? "").trim().toLowerCase() === "listitem";

  const colour = !status
    ? "gainsboro"
    : status === "Reviewed"
    ? "green"
    : status.startsWith("Pending")
    ? "gold"
    : "grey";

  label = status ?? label ?? "?";

  if (isListItem) {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography>{label}</Typography>
        <Badge colour={colour} size={14} />
      </Stack>
    );
  }

  return <LabelledBadge label={label} colour={colour} />;
};

export default ReviewStatusBadge;
