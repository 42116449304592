import { getInputComponent } from "./transformInputs/factory";
import {
  CheckCircleOutlined as SuccessIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import BigLoader from "components/common/BigLoader";
import { useCallback, useEffect, useState } from "react";
import * as ingestService from "services/ingestService";

const useTriangleIngestionController = ({ onSetTriangle }) => {
  const [state, setState] = useState("loading");

  const [ingestionStatus, setIngestionStatus] = useState(null);

  const [
    createIngestion,
    { data: creationIngestionStatus },
  ] = ingestService.useCreateIngestionMutation();

  useEffect(() => {
    createIngestion();
  }, [createIngestion]);

  useEffect(() => {
    setIngestionStatus(creationIngestionStatus);
  }, [setIngestionStatus, creationIngestionStatus]);

  const { data: latestIngestionStatus } = ingestService.useStatusQuery(
    ingestionStatus?.ingestionId,
    {
      skip:
        !ingestionStatus?.ingestionId ||
        ingestionStatus?.currentTransform?.status !== "running",
      pollingInterval: 200,
    }
  );

  useEffect(() => {
    setIngestionStatus(latestIngestionStatus);
  }, [setIngestionStatus, latestIngestionStatus]);

  const [_applyTransform] = ingestService.useApplyTransformMutation();

  const ingestionId = ingestionStatus?.ingestionId;

  const currentTransformStatus = ingestionStatus?.currentTransform;
  const currentTransformId = currentTransformStatus?.transformId;

  const applyTransform = useCallback(
    async (args) => {
      const transformResponse = await _applyTransform({
        ingestionId: ingestionId,
        transformId: currentTransformId,
        args,
      });
      if (!transformResponse?.data) {
        throw new Error(transformResponse?.error ?? "Unknown failure");
      }
      const newIngestionStatus = transformResponse.data;
      setIngestionStatus(newIngestionStatus);
      return newIngestionStatus;
    },
    [ingestionId, currentTransformId, _applyTransform, setIngestionStatus]
  );

  const progress = {
    steps: [
      ...(ingestionStatus?.transforms?.map((t) => ({ name: t.name })) ?? []),
      { name: "Validate" },
    ],
    currentStep: currentTransformStatus?.transformId
      ? ingestionStatus?.transforms.findIndex(
          (t) => t.transformId === currentTransformStatus?.transformId
        )
      : ingestionStatus?.transforms.every((t) => t.status === "succeeded")
      ? ingestionStatus?.transforms?.length ?? 0
      : 0,
  };

  const [completeIngestion] = ingestService.useCompleteIngestionMutation();

  useEffect(() => {
    if (state === "loading") {
      if (!!ingestionStatus) {
        setState("transforming");
      }
    } else if (state === "transforming") {
      if (
        ingestionStatus?.transforms?.length &&
        ingestionStatus.transforms.every((t) => t.status === "succeeded")
      ) {
        setState("completing");
        completeIngestion({ ingestionId: ingestionStatus.ingestionId }).then(
          (result) => {
            if (result.data) {
              setState("succeeded");
              onSetTriangle(result.data);
            } else {
              setState("failed");
            }
          }
        );
      }
    }
  }, [state, setState, ingestionStatus, completeIngestion, onSetTriangle]);

  return { progress, currentTransformStatus, state, applyTransform };
};

const Progress = ({ progress }) => {
  return (
    <Stepper activeStep={progress?.currentStep} sx={{ width: "100%" }}>
      {progress?.steps?.map((step, index) => (
        <Step key={index}>
          <StepLabel>{step.name}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const TransformInput = ({ transformStatus, applyTransform }) => {
  if (!transformStatus) {
    return <></>;
  }
  const Input = getInputComponent(transformStatus?.type);
  return (
    <Input transformStatus={transformStatus} applyTransform={applyTransform} />
  );
};

const Content = ({ state, progress, transformStatus, applyTransform }) => {
  switch (state) {
    case "loading":
      return <BigLoader />;
    case "transforming":
      return (
        <>
          <Progress progress={progress} />
          <TransformInput
            transformStatus={transformStatus}
            applyTransform={applyTransform}
          />
        </>
      );
    case "completing":
      return (
        <>
          <Progress progress={progress} />
          <BigLoader />
        </>
      );
    case "succeeded":
      return (
        <SuccessIcon
          color={"success"}
          sx={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      );
    case "failed":
    default:
      return (
        <Alert severity={"error"} sx={{ width: "100%" }}>
          {"Failed to load triangle"}
        </Alert>
      );
  }
};

const TriangleIngestion = ({ onClose, onSetTriangle }) => {
  const {
    progress,
    currentTransformStatus,
    state,
    applyTransform,
  } = useTriangleIngestionController({ onSetTriangle });

  return (
    <Dialog open={true} maxWidth={"xl"} fullWidth>
      <DialogTitle>{"Load a Triangle"}</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack
          direction={"column"}
          alignItems={"strecth"}
          justifyContent={"space-around"}
          spacing={2}
          sx={{ width: "100%" }}
        >
          <Content
            state={state}
            progress={progress}
            transformStatus={currentTransformStatus}
            applyTransform={applyTransform}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TriangleIngestion;
