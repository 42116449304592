import claimsSaga from "./claims/claimsSaga";
import hazardProfileSaga from "./exposures/hazardProfileSaga";
import programSaga from "./program/programSaga";
import { fork } from "redux-saga/effects";

export default function* inputSaga() {
  yield fork(claimsSaga);
  yield fork(hazardProfileSaga);
  yield fork(programSaga);
}
