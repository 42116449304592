import PrintRoundedIcon from "@mui/icons-material/PrintRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Tooltip,
} from "@mui/material";
import * as chartUtils from "chartUtils";
import * as numbers from "common/numbers";
import DefaultLoader from "components/common/DefaultLoader";
import GoodBadTableCell from "components/common/GoodBadTableCell";
import IconButton from "components/common/IconButton";
import Logo from "components/common/Logo";
import SmallLoader from "components/common/SmallLoader";
import Visible from "components/common/Visible";
import ClaimsChart from "components/inputs/claims/ClaimsChart";
import LossFrequencyChart from "components/pricing/lossFrequency/LossFrequencyChart";
import ExposureFlags from "components/summary/program/ExposureFlags";
import HistoricalExposuresTable from "components/summary/program/HistoricalExposuresTable";
import Judgement from "components/summary/program/Judgement";
import Notes from "components/summary/program/Notes";
import PrintNote from "components/summary/program/PrintNote";
import PrintSettingsDialog from "components/summary/program/PrintSettingsDialog";
import TopClaims from "components/summary/program/TopClaims";
import Tower from "components/summary/program/Tower";
import * as config from "config";
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import * as bffService from "services/bffService";
import * as claimsService from "services/claimsService";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as flagSelectors from "store/selectors/input/flags/flagsSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as peersSelectors from "store/selectors/peers/peersSelectors";
import * as peopleSelectors from "store/selectors/people/peopleSelectors";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";
import "styles/global/print.css";

const DollarFormatted = (props) => <GoodBadTableCell prefix={"$"} {...props} />;

const PercentFormatted = (props) => (
  <GoodBadTableCell
    suffix={"%"}
    decimalScale={2}
    fixedDecimalScale
    {...props}
  />
);

const layerlessRow = (submission) => {
  return (
    <TableRow key={`PEERS_ROW_${submission.filename}_LAYERLESS`}>
      <TableCell />
      <TableCell>{submission.insured}</TableCell>
      <TableCell className={"tablecell-inception"}>
        {submission.inception}
      </TableCell>
      <TableCell colSpan={10}>{"No Layers with positive line size."}</TableCell>
    </TableRow>
  );
};

const loadingRow = (submission) => {
  return (
    <TableRow key={`PEERS_ROW_${submission.filename}_LOADING`}>
      <TableCell />
      <TableCell>{submission.insured}</TableCell>
      <TableCell className={"tablecell-inception"}>
        {submission.inception}
      </TableCell>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
        <TableCell
          align={"right"}
          key={`PEERS_ROW_${submission.filename}_LOADING_${i}`}
        >
          <DefaultLoader
            color={"#dc7f4c"}
            style={{
              display: "inline-block",
              position: "relative",
              top: "2px",
            }}
            size={16}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

const layerRow = (submission, layer, layer_index, isLast, peer_index) => {
  return (
    <TableRow
      key={`PEERS_ROW_${submission.filename}_${layer_index}`}
      style={peer_index === 0 ? { background: "#dbdce5" } : {}}
    >
      <TableCell style={isLast ? {} : { borderBottom: "none" }}>
        {layer_index > 0 ? "" : submission.insured}
      </TableCell>
      <TableCell
        className={"tablecell-inception"}
        style={isLast ? {} : { borderBottom: "none" }}
      >
        {layer_index > 0 ? "" : submission.inception}
      </TableCell>
      <GoodBadTableCell
        cellStyle={isLast ? {} : { borderBottom: "none" }}
        value={layer_index > 0 ? "" : submission.fixedPeerMetricValue}
      />
      <GoodBadTableCell
        cellStyle={isLast ? {} : { borderBottom: "none" }}
        value={layer_index > 0 ? "" : submission.exposure}
      />
      <GoodBadTableCell
        cellStyle={isLast ? {} : { borderBottom: "none" }}
        value={layer_index > 0 ? "" : submission.exposure2}
      />
      <GoodBadTableCell
        cellStyle={isLast ? {} : { borderBottom: "none" }}
        value={layer.lineSize / 1e6}
      />
      <GoodBadTableCell
        cellStyle={isLast ? {} : { borderBottom: "none" }}
        value={layer.limit / 1e6}
      />
      <GoodBadTableCell
        cellStyle={isLast ? {} : { borderBottom: "none" }}
        value={layer.attachment / 1e6}
      />
      <DollarFormatted value={layer.grossPPM} />
      <PercentFormatted value={layer.tp} good={(layer.tp ?? 100) - 100} />
      <PercentFormatted value={layer.totalAcquisition} />
    </TableRow>
  );
};

const ClaimsCharts = () => {
  const pricingClaimsQuery = useDebouncedValue(
    useSelector(pricingSelectors.selectPricingClaims)
  );
  const { currentData, isFetching } = claimsService.useSummaryChartQuery(
    pricingClaimsQuery
  );
  return (
    <div className={"print-claims-charts"}>
      <ClaimsChart
        title={"Loss Frequency"}
        chart={currentData?.frequency}
        loading={isFetching}
        dataName={"Frequency"}
        styles={{
          position: "relative",
          width: "100%",
          height: "180px",
        }}
        referenceStyles={{ fontSize: "12px" }}
        titleStyles={{
          fontWeight: "bold",
          display: "inline-block",
          fontSize: "14px",
          marginBottom: "4px",
          color: "#000",
          textAlign: "center",
        }}
      />
      <ClaimsChart
        title={"Loss Severity"}
        chart={currentData?.severity}
        loading={isFetching}
        dataName={"Severity"}
        ultimateName={"Development & Inflation"}
        styles={{
          position: "relative",
          width: "100%",
          height: "180px",
        }}
        referenceStyles={{ fontSize: "12px" }}
        averageLabel={(mean) => {
          return `Dev. Avg. to ${mean.year}: ${chartUtils.tickFormatter(
            mean.ultimate
          )}`;
        }}
        titleStyles={{
          fontWeight: "bold",
          display: "inline-block",
          fontSize: "14px",
          marginBottom: "4px",
          color: "#000",
          textAlign: "center",
        }}
      />
      <ClaimsChart
        title={"Loss Aggregate"}
        chart={currentData?.aggregate}
        loading={isFetching}
        dataName={"Aggregate"}
        ultimateName={"Development & Inflation"}
        averageLabel={(mean) => {
          return `Dev. Avg. to ${mean.year}: ${chartUtils.tickFormatter(
            mean.ultimate
          )}`;
        }}
        referenceStyles={{ fontSize: "12px" }}
        styles={{
          position: "relative",
          width: "100%",
          height: "180px",
        }}
        titleStyles={{
          fontWeight: "bold",
          display: "inline-block",
          fontSize: "14px",
          marginBottom: "4px",
          color: "#000",
          textAlign: "center",
        }}
      />
    </div>
  );
};

const MetaDetail = ({ label, value, isLoading, tooltip }) => {
  const out = (
    <div className={"print-meta-detail"}>
      <span className={"print-label"}>{label}</span>
      <span className={"print-value"}>
        {isLoading ? <SmallLoader /> : value}
      </span>
    </div>
  );
  return tooltip ? <Tooltip title={tooltip}>{out}</Tooltip> : out;
};

const PriorGWPMetaDetail = ({ submissionId }) => {
  const { data, isLoading, isError } = bffService.useCreateInsuredSummaryQuery(
    { submissionId },
    { skip: !submissionId }
  );
  if (isError) {
    return (
      <MetaDetail
        label={"ITD GWP"}
        value={"?"}
        isLoading={isLoading}
        tooltip={"Error loading data"}
      />
    );
  }

  return (
    <div className={"print-header-section"}>
      <MetaDetail
        label={"ITD GWP"}
        value={numbers.abbreviated(data?.totalPriorBoundPremium) ?? "-"}
        isLoading={isLoading}
        tooltip={numbers.dpString(0)(data?.totalPriorBoundPremium)}
      />
      {(data?.priorBoundPremiumsByYear || []).map(
        ({ inceptionYear, premium }) => (
          <MetaDetail
            key={inceptionYear}
            label={inceptionYear}
            value={numbers.abbreviated(premium)}
            isLoading={false}
            tooltip={numbers.dpString(0)(premium)}
          />
        )
      )}
    </div>
  );
};

const brokerLabel = (broker) => {
  const brokerName = broker?.name;
  const house = broker?.company;
  if (!brokerName && !house) {
    return "-";
  } else if (!house) {
    return brokerName;
  } else if (!brokerName) {
    return house;
  }
  return `${brokerName} (${house})`;
};

const Print = (props) => {
  const peersHeaders = [
    { title: "Insured", tooltip: "", align: "left" },
    { title: "Inception", tooltip: "", align: "left" },
    { title: props.fixedPeerMetricDefn.name, tooltip: "", align: "right" },
    { title: props.exposureMetric?.name, tooltip: "", align: "right" },
    { title: props.exposureMetric2?.name, tooltip: "", align: "right" },
    { title: "Our Limit (m)", tooltip: "", align: "right" },
    { title: "Part Of (m)", tooltip: "", align: "right" },
    { title: "Attach. (m)", tooltip: "", align: "right" },
    { title: "Gross PPM", tooltip: "", align: "right" },
    {
      title: "Target Price (TP)",
      tooltip: config.DEFINITION_TP,
      align: "right",
    },
    {
      title: "TAC",
      tooltip: config.DEFINITION_TAC,
      align: "right",
    },
  ];

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const hasLossFrequencyChart =
    props.userConfig.componentVisibility?.["<Print>.lossFrequencyChart"] ??
    false;

  return (
    <>
      <PrintSettingsDialog
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
      />
      <div className={"print"}>
        <div className={"page page-break-after"}>
          <div className={"print-insured-header"}>
            <Grid container justifyContent={"space-between"}>
              <h1>{props.insured}</h1>
              <Stack
                direction={"row"}
                alignItems={"center"}
                displayPrint={"none"}
              >
                <IconButton
                  variant={"bright"}
                  scale={"small"}
                  icon={SettingsIcon}
                  tooltip={"Settings"}
                  onClick={() => setIsSettingsOpen(true)}
                />
                <IconButton
                  variant={"bright"}
                  scale={"small"}
                  icon={PrintRoundedIcon}
                  tooltip={"Print"}
                  onClick={() => window.print()}
                />
              </Stack>
            </Grid>
            <span className={"print-risk-description"}>
              {props.riskDescription}
            </span>
          </div>

          <div className={"print-header-section"}>
            <MetaDetail label={"Ref"} value={props.reference || "-"} />
            <MetaDetail label={"Inception"} value={props.inception} />
            <MetaDetail
              label={"Renewal"}
              value={props.isRenewal ? "YES" : "NO"}
            />
            <MetaDetail label={"Industry Class"} value={props.industryClass} />
            <MetaDetail
              label={"UW"}
              value={props.preferredUnderwriter?.name || "-"}
            />
            <MetaDetail
              label={"Brokerage"}
              value={props.brokerage != null ? `${props.brokerage}%` : null}
            />
            <MetaDetail label={"Broker"} value={brokerLabel(props.broker)} />
          </div>
          <Divider sx={{ margin: "26px 0 22px 0" }} />
          {props.isRenewal && (
            <Visible byTag={"<Print>.summary.priorGwp"} defaultVisible={true}>
              <PriorGWPMetaDetail submissionId={props.submissionId} />
              <Divider sx={{ margin: "26px 0 22px 0" }} />
            </Visible>
          )}

          <PrintNote title={"Risk Summary"} markdown={props.riskSummary} />

          <Divider sx={{ margin: "26px 0 22px 0" }} />

          {props.hasClaims && (
            <>
              <Visible byTag={"<Print>.claimsCharts"} defaultVisible>
                <ClaimsCharts />
              </Visible>

              <Visible byTag={"<Print>.topClaims"} defaultVisible>
                <div
                  className={
                    hasLossFrequencyChart
                      ? "print-claims-table-and-freq-chart"
                      : ""
                  }
                >
                  <TopClaims fullWidth={!hasLossFrequencyChart} />

                  <Visible
                    byTag={"<Print>.lossFrequencyChart"}
                    defaultVisible={false}
                  >
                    <div>
                      <LossFrequencyChart
                        credibleTitle={true}
                        hideLegend={true}
                        chartMargins={{
                          top: 0,
                          right: 30,
                          left: 0,
                          bottom: 0,
                        }}
                        styles={{
                          position: "relative",
                          width: "100%",
                          paddingBottom: "220px",
                          textAlign: "center",
                          left: "-40px",
                        }}
                        titleStyles={{
                          fontWeight: "bold",
                          display: "inline-block",
                          fontSize: "14px",
                          marginBottom: "4px",
                          color: "#000",
                          textAlign: "center",
                        }}
                      />
                      <ul
                        style={{
                          listStyleType: "square",
                          fontWeight: "bold",
                          fontSize: "11px",
                        }}
                      >
                        <li style={{ color: "#FFAB53" }}>{"Reported"}</li>
                        <li style={{ color: "#FF634E" }}>
                          {"Expected Development"}
                        </li>
                        <li style={{ color: "#62BEE5" }}>{"Model"}</li>
                        <li style={{ color: "#A0A6AD" }}>
                          {"Insured Selected # of Losses"}
                        </li>
                        <li style={{ color: "#CC5ED6" }}>
                          {"Model/Claims Blend"}
                        </li>
                        <li style={{ color: "#3A4148" }}>
                          {"Losses for Pricing"}
                        </li>
                      </ul>
                    </div>
                  </Visible>
                </div>
              </Visible>
            </>
          )}

          <Visible byTag={"<Print>.tower"} defaultVisible={false}>
            <Tower />
          </Visible>
        </div>

        <div className={"page"}>
          <Grid
            container
            spacing={config.GRID_SPACING}
            className={"print-section"}
          >
            <HistoricalExposuresTable updating={props.rowsUpdating} />
          </Grid>

          <Visible byTag={"<Print>.peerPricing"} defaultVisible={false}>
            <Grid
              container
              spacing={config.GRID_SPACING}
              className={"print-section"}
            >
              <Grid item xs={12}>
                <span className={"print-label"}>
                  {"Peer Comparison / Pricing"}
                </span>
                <TableContainer>
                  <Table className={"print-peer-comparison-table"}>
                    <TableHead>
                      <TableRow>
                        {peersHeaders.map((header, i) => (
                          <TableCell
                            key={`REVIEW_TOOLTIP_TABLE_CELL_${i}`}
                            align={header.align}
                          >
                            {header.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.peerData.map((submission, peer_index) => {
                        if (!submission.loaded) {
                          return loadingRow(submission, peer_index);
                        }
                        if (submission.layers.length === 0) {
                          return layerlessRow(submission);
                        }
                        return submission.layers.map((layer, layer_index) => {
                          return layerRow(
                            submission,
                            layer,
                            layer_index,
                            layer_index + 1 === submission.layers.length,
                            peer_index
                          );
                        });
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Visible>

          <Grid
            container
            direction={"column"}
            spacing={config.GRID_SPACING}
            className={"print-section"}
          >
            <Visible byTag={"<Print>.uwJudgments"} defaultVisible={true}>
              {props.judgments.length > 1 && (
                <Judgement
                  judgements={props.judgments}
                  judgementFactorPretty={props.judgmentFactorPretty}
                />
              )}
            </Visible>
            <Visible byTag={"<Print>.exposureFlags"} defaultVisible={true}>
              {props.exposures.length > 0 && (
                <ExposureFlags exposures={props.exposures} />
              )}
            </Visible>
          </Grid>
        </div>

        <div className={"print-section"}>
          <Notes
            summaryNotes={props.summaryNotes}
            otherNotes={props.otherNotes}
            peerReview={props.peerReview}
            spacing={config.GRID_SPACING}
          />
        </div>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Logo variant={"poweredBy"} />
          <Typography variant={"caption"} color={"lightslategray"}>
            {"marmalademodel.com"}
          </Typography>
        </Stack>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    inception: state.input.program.inception,
    insured: state.input.program.insured,
    riskDescription: state.input.program.riskDescription,
    riskSummary: state.input.program.riskSummary,
    reference: state.input.program.reference,
    brokerage: state.input.program.brokerage,
    preferredUnderwriter: peopleSelectors.selectPreferredUnderwriter(state),
    broker: peopleSelectors.selectBroker(state),
    industryClass: programSelectors.getPrettyIndustryClass(state),
    summaryNotes: state.summary.notes,
    otherNotes: {
      program: state.input.program.notes,
      claims: state.input.claims.note,
      exposure: state.input.exposure.note,
      scenarios: state.input.scenarios.note,
    },
    peerReview: state.summary.peerReview ?? {},
    rows: exposureSelectors.getExposureList(state),
    tower: pricingSelectors.getTower(state),
    judgments: flagSelectors.getJudgments(state),
    judgmentFactorPretty: flagSelectors.getJudgmentFactorPretty(state),
    exposures: flagSelectors.getExposures(state),
    expectedLosses: pricingSelectors.getNumberOfLossesForPricingPretty(state),
    insuredWeight: state.pricing.insuredWeight,
    hasClaims: !claimsSelectors.isClaimsQueryEmpty(state),
    peerData: peersSelectors.getPeers(state),
    exposureMetric: state.peers.exposureMetric,
    exposureMetric2: state.peers.exposureMetric2,
    fixedPeerMetricDefn: peersSelectors.selectFixedPeerMetricDefn(state),
    isRenewal: !!state.input.program.priorSubmission,
    userConfig: userSelectors.selectUserConfig(state),
    submissionId: persistenceSelectors.selectSubmissionId(state),
  };
};

export default connect(mapStateToProps)(Print);
