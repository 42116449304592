import {
  getBreaksNeededForEmptyLineBefore,
  getBreaksNeededForEmptyLineAfter,
} from "@uiw/react-md-editor";

export const tableText = (rows, columns) => {
  const head = "|" + Array(columns).fill(" Head |").join("");
  const data = "|" + Array(columns).fill(" Data |").join("");
  const line = "|" + Array(columns).fill(" --- |").join("");
  return `${head}\n${line}\n${Array(rows - 1)
    .fill(data)
    .join("\n")}`;
};

export const wrapTextInBreaks = (state, insertText) => {
  const breaksBeforeCount = getBreaksNeededForEmptyLineBefore(
    state.text,
    state.selection.start
  );
  const breaksBefore = Array(breaksBeforeCount + 1).join("\n");

  const breaksAfterCount = getBreaksNeededForEmptyLineAfter(
    state.text,
    state.selection.end
  );
  const breaksAfter = Array(breaksAfterCount + 1).join("\n");

  return `${breaksBefore}${insertText}${breaksAfter}`;
};
