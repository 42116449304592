import Grid from "@mui/material/Grid";
import * as numbers from "common/numbers";
import Component from "components/Component";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";

const InflationSelector = ({
  activeLayerRateChange,
  updateLayerRateChange,
}) => {
  return (
    <Component title={"Inflation"}>
      <Grid item xs={12}>
        <PrettyNumberTextField
          type={"text"}
          className={"rate-change-summary"}
          InputLabelProps={{ shrink: true }}
          InputProps={{ spellCheck: "false" }}
          label={"Inflation"}
          autoComplete={"off"}
          variant={"filled"}
          offFormatter={numbers.dpString(2, {
            suffix: "%",
          })}
          onChangeNumber={(inflation) => updateLayerRateChange(inflation)}
          value={activeLayerRateChange?.inflation}
          fullWidth
        />
      </Grid>
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    activeLayerRateChange: rateChangeSlice.selectActiveLayerRateChange(state),
  };
};

const mapDispatchToProps = {
  updateLayerRateChange: rateChangeSlice.updateLayerPropertiesItem("inflation"),
};

export default connect(mapStateToProps, mapDispatchToProps)(InflationSelector);
