import { createSlice } from "@reduxjs/toolkit";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

const initialState = {
  currencies: {},
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    addCurrency: (state, action) => {
      return {
        ...state,
        currencies: {
          ...state?.currencies,
          [action.payload.currency]: action.payload.fxRate,
        },
      };
    },
    updateCurrency: (state, action) => {
      return {
        ...state,
        currencies: {
          ...state?.currencies,
          [action.payload.currency]: action.payload.fxRate,
        },
      };
    },
    removeCurrency: (state, action) => {
      return {
        ...state,
        currencies: Object.fromEntries(
          Object.entries(state?.currencies ?? {}).filter(
            ([currency, fxRate]) => {
              if (currency === action.payload.currency) {
                return false;
              }
              return true;
            }
          )
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => action?.payload?.data?.state?.currency ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => initialState)
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, () => initialState);
  },
});

export const selectInputCurrency = (state) => state?.currency;

export const selectResolvedCurrency = (state) => selectInputCurrency(state);

export const {
  addCurrency,
  updateCurrency,
  removeCurrency,
} = currencySlice.actions;

export default currencySlice;
