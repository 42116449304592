import { hasItems } from "./arrays";
import { hasValue } from "./strings";
import * as logger from "common/logger";

export const METHOD = {
  MIN: "MIN",
  MAX: "MAX",
  SUM: "SUM",
  AVG: "AVG",
  MEDIAN: "MEDIAN",
  COUNT: "COUNT",
  COUNT_UNIQUE: "COUNT_UNIQUE",
  COUNT_CHILDREN: "COUNT_CHILDREN",
};

export const isMethod = (method) => Object.values(METHOD).includes(method);

const logError = (text, context) => {
  if (!hasValue(context)) {
    context = "unspecified calling context";
  }
  logger.error(`${text} applying aggregate for ${context}`);
};

export const aggregatedByKey = (method, key, objArray, errorContext) => {
  const indeterminateValue = 0;

  if (!hasValue(method)) {
    logError("Missing aggregate mode", errorContext);
    return indeterminateValue;
  }

  if (!hasValue(key)) {
    logError("Missing key", errorContext);
    return indeterminateValue;
  }

  if (!hasItems(objArray)) {
    return indeterminateValue;
  }

  if (!objArray.some((x) => x[key] != null)) {
    logError(`Specified key '${key}' not present in array data`, errorContext);
    return indeterminateValue;
  }

  if (method === METHOD.MIN) {
    return objArray.reduce(
      (acc, cur) =>
        (acc = acc == null ? cur[key] : Math.min(acc, cur[key] ?? acc)),
      null
    );
  }

  if (method === METHOD.MAX) {
    return objArray.reduce(
      (acc, cur) =>
        (acc = acc == null ? cur[key] : Math.max(acc, cur[key] ?? acc)),
      null
    );
  }

  if (method === METHOD.SUM) {
    return objArray.reduce((acc, cur) => (acc += cur[key] ?? 0), 0);
  }

  if (method === METHOD.AVG) {
    const sum = aggregatedByKey(METHOD.SUM, key, objArray, errorContext);
    const count = aggregatedByKey(METHOD.COUNT, key, objArray, errorContext);
    return sum / count;
  }

  if (method === METHOD.MEDIAN) {
    const sorted = objArray
      .filter((x) => x[key] != null)
      .map((x) => x[key])
      .sort((a, b) => a - b);

    const count = sorted.length;

    if (count === 0) {
      return indeterminateValue;
    } else if (count === 1) {
      return sorted[0];
    } else if (count % 2 === 1) {
      return sorted[Math.floor(count / 2)];
    } else {
      const a = sorted[count / 2 - 1];
      const b = sorted[count / 2];
      return (a + b) / 2;
    }
  }

  if (method === METHOD.COUNT) {
    return objArray.filter((x) => x.hasOwnProperty(key)).length;
  }

  if (method === METHOD.COUNT_UNIQUE) {
    const unique = new Set();
    objArray.forEach((x) => {
      if (x.hasOwnProperty(key)) {
        unique.add(x[key]);
      }
    });
    return unique.size;
  }

  if (method === METHOD.COUNT_CHILDREN) {
    return objArray.reduce((acc, cur) => {
      const children = cur[key] ?? [];
      return (acc += Array.isArray(children) ? children.length : 0);
    }, 0);
  }

  logError(`Unrecognised method '${method}'`, errorContext);
  return indeterminateValue;
};
