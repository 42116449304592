import {
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as numbers from "common/numbers";
import Component from "components/Component";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as scenariosActions from "store/actions/input/scenarios/scenariosActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

const Group = ({ config }) => {
  const dispatch = useDispatch();

  const setScenarioLevels = (levels) =>
    dispatch(scenariosActions.setScenarioLevels(levels));
  const setScenarioLayerId = (layerId) =>
    dispatch(scenariosActions.setScenarioLayerId(layerId));

  const scenarioLevels = useSelector(
    (state) => state.input.scenarios?.scenarios ?? {}
  );
  const scenarioLayerId = useSelector(
    (state) => state.input.scenarios?.scenarioLayerId ?? null
  );

  const layers = useSelector(pricingSelectors.selectResolvedLayers);

  const scenarioLayer =
    layers?.filter((l) => l?.id === scenarioLayerId)?.[0] ?? null;

  return (
    <Stack direction={"column"} spacing={1}>
      <Typography
        variant={"subtitle2"}
        sx={{ margin: "10px 0 6px 0 !important" }}
      >
        {config?.name}
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={250} align={"left"}>
                {"Location"}
              </TableCell>
              <TableCell width={200} align={"right"}>
                {"$"}
              </TableCell>
              <TableCell width={150} align={"right"}>
                {"% of Line"}
              </TableCell>
              <TableCell width={200} align={"left"}>
                {"Layer"}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {config?.items?.map((itemConfig, index) => (
              <TableRow key={itemConfig.id}>
                <TableCell align={"left"}>
                  <Typography variant={"body2"}>{itemConfig.label}</Typography>
                </TableCell>
                <TableCell>
                  <PrettyNumberTextField
                    fullWidth
                    type={"text"}
                    hiddenLabel
                    size={"small"}
                    variant={"filled"}
                    allowClear
                    inputProps={{
                      tabIndex: index + 1,
                      style: { textAlign: "right" },
                    }}
                    onChangeNumber={(x) =>
                      setScenarioLevels({
                        ...scenarioLevels,
                        [itemConfig.id]: x,
                      })
                    }
                    value={scenarioLevels?.[itemConfig.id] ?? ""}
                  />
                </TableCell>
                <TableCell align={"right"}>
                  {scenarioLevels?.[itemConfig.id] && scenarioLayer?.limit && (
                    <Typography variant={"body2"}>
                      {numbers.dpString(2)(
                        (100 * scenarioLevels[itemConfig.id]) /
                          scenarioLayer.limit
                      )}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align={"left"}>
                  {index === 0 ? (
                    <Select
                      sx={{
                        minWidth: 200,
                      }}
                      variant={"standard"}
                      value={scenarioLayerId ?? "__UNSET__"}
                      onChange={(e) => {
                        const layerId = e?.target?.value;
                        if (layerId === "__UNSET__") {
                          setScenarioLayerId(null);
                        } else if (!!layerId) {
                          setScenarioLayerId(layerId);
                        }
                      }}
                    >
                      <MenuItem value={"__UNSET__"}>{""}</MenuItem>
                      {layers?.map((layer) => (
                        <MenuItem key={layer.id} value={layer.id}>
                          {utils.shortLayerName(layer)}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Typography variant={"body2"}>
                      {scenarioLayer ? utils.shortLayerName(scenarioLayer) : ""}
                    </Typography>
                  )}
                </TableCell>
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

const LevelledScenarios = () => {
  const { data: scenarioConfig } = staticDataService.useConfigQuery(
    "scenarios"
  );

  return (
    <Component
      title={"Scenarios"}
      subtitle={"The disaster scenarios to which this insured is exposed."}
    >
      <Stack direction={"column"} spacing={2}>
        {scenarioConfig?.scenarios?.map((groupConfig) => (
          <Group key={groupConfig.name} config={groupConfig} />
        ))}
      </Stack>
    </Component>
  );
};

export default LevelledScenarios;
