import { Autocomplete, TextField } from "@mui/material";

import * as staticDataService from "services/staticDataService";
import { useEffect, useState } from "react";

const applyFilter = (options, value, filter, filteredOptions) => {
  if (value) {
    if (value !== filter) {
      try {
        const regex = new RegExp(value.split("").join(".*"), "i");
        return options.filter((option) => {
          return regex.test(option?.name);
        });
      } catch (e) {
        return [];
      }
    }
    return filteredOptions;
  }
  return options;
};

const AutoCompleteTextField = ({
  category,
  value,
  onChange,
  onChangeValue,
  ...props
}) => {
  const autoCompletions = staticDataService.useConfigQuery("auto_completions");
  const [options, setOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (
      autoCompletions.isLoading === false &&
      autoCompletions.data?.[category]
    ) {
      setOptions(autoCompletions.data?.[category]);
    }
  }, [autoCompletions, category]);

  useEffect(() => {
    if (value !== filter) {
      setFilteredOptions(applyFilter(options, value, filter, filteredOptions));
      setFilter(value);
    }
  }, [options, value, filter, filteredOptions]);

  return (
    <Autocomplete
      options={options}
      filterOptions={(options, state) =>
        applyFilter(options, state.inputValue, filter, filteredOptions)
      }
      getOptionLabel={(option) => option?.name || value}
      renderOption={(props, option) => {
        try {
          const regex = new RegExp(
            "^(.*)(" + value.split("").join(")(.*)(") + ")(.*)$",
            "i"
          );
          return (
            regex.test(option?.name) && (
              <li {...props}>
                <div>
                  {(option?.name.match(regex) || [])
                    .slice(1)
                    .flatMap((str, index) =>
                      str.split("").map((char, charIndex) => (
                        <span
                          key={`${index}_${charIndex}`}
                          style={{
                            fontWeight: index % 2 === 1 ? "bold" : "normal",
                          }}
                        >
                          {char}
                        </span>
                      ))
                    )}
                </div>
              </li>
            )
          );
        } catch (e) {
          return null;
        }
      }}
      value={value}
      autoHighlight
      includeInputInList
      freeSolo
      renderInput={(params) => (
        <TextField {...params} value={value} onChange={onChange} {...props} />
      )}
      onChange={(e, value) => onChangeValue(value?.name || "")}
    />
  );
};

export default AutoCompleteTextField;
