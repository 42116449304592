import CatExposuresGrid from "./CatExposuresGrid";
import * as ids from "common/ids";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import { usePricing } from "components/customPricing/dnfModel1/pricingHooks";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as analyticsStore from "store/analytics";
import * as catExposuresStore from "store/catExposures";

const ManualCatExposures = () => {
  const [nextId, setNextId] = useState(ids.getUniqueKey());

  const dispatch = useDispatch();

  const catExposures = useSelector(catExposuresStore.select);
  const manualExposures = catExposures?.manualExposures ?? [];

  const deleteExposure = (id) => {
    dispatch(
      catExposuresStore.update({
        manualExposures: manualExposures.filter((e) => e.id !== id),
      })
    );
  };

  const updateExposure = (id) => ({ field, value }) => {
    if (!manualExposures.some((e) => e.id === id)) {
      dispatch(
        catExposuresStore.update({
          manualExposures: [
            ...manualExposures,
            {
              id: nextId,
              [field]: value,
            },
          ],
        })
      );
      setNextId(ids.getUniqueKey());
    } else {
      setTimeout(
        () =>
          dispatch(
            catExposuresStore.update({
              manualExposures: manualExposures.map((e) =>
                e.id === id
                  ? {
                      ...e,
                      [field]: value != null ? value : undefined,
                    }
                  : e
              ),
            })
          ),
        0
      );
    }
  };

  const analyticsData = useSelector(analyticsStore.select);
  const pricingById = analyticsData?.programPricing?.values?.manualCat ?? {};

  const exposures = manualExposures.map((exposure) => {
    const pricing = pricingById?.[exposure.id] ?? {};
    return {
      ...exposure,
      ...pricing,
    };
  });

  const { isFetching } = usePricing();

  return (
    <Component title={"Manual Cat Exposures"} isFetching={isFetching}>
      <Disabled ifReadOnly>
        <CatExposuresGrid
          exposures={[...exposures, { id: nextId }]}
          updateExposure={updateExposure}
          deleteExposure={deleteExposure}
          loading={isFetching}
        />
      </Disabled>
    </Component>
  );
};

export default ManualCatExposures;
