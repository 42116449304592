import LayerInputs from "./LayerInputs";
import ProgramInputs from "./ProgramInputs";
import { Grid } from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import FilteredClaims from "components/inputs/claims/FilteredClaims";
import * as config from "config";
import * as claims from "domain/claims";
import { useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";

export const BurningCost = () => {
  const claimsQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const claimsAbove = useSelector(claimsSelectors.selectClaimsThreshold);
  const { currentData: allClaimsChart } = claimsService.useAllClaimsChartQuery({
    claimsQuery,
    claimsAbove,
  });

  const isClaimsQueryEmpty = claims.isClaimsQueryEmpty(claimsQuery);

  return (
    <Grid container item xs={12} spacing={config.GRID_SPACING}>
      <Grid item xs={12}>
        {!isClaimsQueryEmpty && (
          <Disabled ifReadOnly>
            <FilteredClaims
              data={allClaimsChart}
              zoomIdentifier={"burningcost"}
              isViewOnly
            />
          </Disabled>
        )}
      </Grid>
      <Component title={"Configuration"}>
        <ProgramInputs />
      </Component>
      <Component
        title={"Burning Costs"}
        subtitle={"The burning costs of each layer."}
      >
        <LayerInputs />
      </Component>
    </Grid>
  );
};

export default BurningCost;
