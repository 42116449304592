import { Stack } from "@mui/material";
import IndustryClassSelector from "components/common/IndustryClassSelector";

const IndustryClassInput = ({ defn, options, setOptions }) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <IndustryClassSelector
        value={options[defn.name] ?? []}
        onChange={(x) =>
          setOptions({
            ...options,
            [defn.name]: (x ?? []).length > 0 ? x : null,
          })
        }
        sx={{ minWidth: 300 }}
      />
    </Stack>
  );
};

export default IndustryClassInput;
