import NumberTextField from "../../../components/common/NumberTextField";
import * as flagActions from "../../../store/actions/input/flags/flagsActions";
import * as flagSelectors from "../../../store/selectors/input/flags/flagsSelectors";
import * as utils from "../../../utils";
import Component from "../../Component";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import React from "react";
import { connect } from "react-redux";

export const getGoodBad = (valueStr) => {
  if (valueStr == null) {
    return "";
  }
  const value = parseFloat(valueStr);
  if (isNaN(value)) {
    return "";
  }
  if (value === 0) {
    return "";
  }
  return value < 0 ? "good" : "bad";
};

export const UWJudgmentComponent = (props) => {
  return (
    <Component
      title={"UW Judgment"}
      subtitle={
        "Risk assessments and their estimated impact on the deal. These numbers are reflected in the UW Adjusted number on the Layer Pricing screen."
      }
    >
      <TableContainer>
        <Table size={"small"} className={"flags"}>
          <TableHead>
            <TableRow>
              <TableCell width={"5%"} sx={{ minWidth: "30px" }} />
              <TableCell width={"25%"}>{"Label"}</TableCell>
              <Tooltip
                title={
                  "Positive numbers are bad (increase the required premium). Negative numbers are good (decrease the required premium)."
                }
              >
                <TableCell
                  width={"10%"}
                  sx={{ minWidth: "100px" }}
                  align={"right"}
                >
                  {"% Impact"}
                </TableCell>
              </Tooltip>
              <TableCell width={"70%"}>{"Comments"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.judgments.map((row, i) => (
              <TableRow key={`JUDGMENTS_ROW_${i}`}>
                <TableCell>
                  {row.canDelete && (
                    <IconButton
                      icon={DeleteIcon}
                      onClick={() => props.deleteJudgment(i)}
                    />
                  )}
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center" }}
                  className={"cell-condensed-padding-md"}
                >
                  <TextField
                    value={row.name}
                    onChange={(e) =>
                      props.updateJudgment("name", i, e.target.value)
                    }
                    size={"small"}
                    variant={"filled"}
                    autoComplete={"off"}
                    hiddenLabel
                    fullWidth
                    disabled={!(row.canEditName ?? true)}
                  />
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "center" }}
                  className={"cell-condensed-padding-md"}
                >
                  <NumberTextField
                    value={row.value}
                    onChange={(value) => {
                      props.updateJudgment("value", i, value);
                    }}
                    size={"small"}
                    variant={"filled"}
                    autoComplete={"off"}
                    hiddenLabel
                    fullWidth
                    className={getGoodBad(row.value)}
                    inputProps={{ sx: { textAlign: "right" } }}
                  />
                </TableCell>
                <TableCell
                  style={{ verticalAlign: "top" }}
                  className={"cell-condensed-padding-md"}
                >
                  <Notes
                    key={new Date().getTime()}
                    title={null}
                    hasEditButton={false}
                    update={(comment) =>
                      props.updateJudgment("comments", i, comment)
                    }
                    value={row.comments}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell
                style={{ verticalAlign: "center" }}
                className={"cell-condensed-padding-md"}
                align={"right"}
              >
                <b>{"Overall"}</b>
              </TableCell>
              <TableCell
                style={{ verticalAlign: "center", pointerEvents: "none" }}
                className={"cell-condensed-padding-md"}
              >
                <TextField
                  value={utils.sfString(props.judgmentFactorPretty)}
                  size={"small"}
                  variant={"filled"}
                  autoComplete={"off"}
                  hiddenLabel
                  fullWidth
                  disabled
                  className={getGoodBad(props.judgmentFactorPretty)}
                  inputProps={{
                    sx: { textAlign: "right", fontWeight: "bold" },
                  }}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

const mapDispatchToProps = {
  updateJudgment: flagActions.updateJudgment,
  deleteJudgment: flagActions.deleteJudgment,
};

const mapStateToProps = (state) => {
  return {
    judgments: flagSelectors.getJudgments(state),
    judgmentFactorPretty: flagSelectors.getJudgmentFactorPretty(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UWJudgmentComponent);
