import * as types from "store/actions/input/exposure/premDeductActionTypes";

export const INIT_STATE = {};

const premDeductProfileReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case types.UPDATE_PREM_DEDUCT_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case types.HIDE_PREM_DEDUCT_FILE:
      if (
        !state.files ||
        !state.files.filter((e) => e === action.payload).length
      )
        return state;
      return {
        ...state,
        files: (state.files ?? []).filter((e) => e !== action.payload),
      };
    case types.TRANSFORM_PREM_DEDUCT_FILE_SUCCESS:
      if (
        state.files &&
        !!state.files.filter((e) => e === action.payload).length
      )
        return state;
      return {
        ...state,
        files: [...(state.files ?? []), action.payload],
      };
    default:
      return state;
  }
};

export default premDeductProfileReducer;
