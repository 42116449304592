import MemoizedShareTable from "./MemoizedShareTable";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import * as config from "config";
import * as _ from "lodash";
import { memo, useState } from "react";
import { connect } from "react-redux";
import * as towerPricingActions from "store/actions/pricing/tower/towerPricingActions";

const TowerTableRow = ({
  columns,
  layer,
  layerIndex,
  isNewRow,
  isSelectedRow,
  priorLayers,
  dispatch,
  updateLimit,
  updateAttachment,
  updateMarketGrossWrittenPremium,
  updateSelected,
  addLayer,
  deleteLayer,
  updateLayer,
  updateShare,
  deleteShare,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      <TableRow
        style={
          isSelectedRow
            ? { background: config.SELECTED_ROW_BACKGROUND_COLOR }
            : {}
        }
      >
        {columns.map(({ key, Cell }) => (
          <Cell
            key={key}
            {...{
              layer,
              layerIndex,
              isNewRow,
              isSelectedRow,
              isExpanded,
              priorLayers,
              dispatch: {
                updateLimit,
                updateAttachment,
                updateMarketGrossWrittenPremium,
                updateSelected,
                addLayer,
                deleteLayer,
                updateLayer,
                updateShare,
                deleteShare,
                setIsExpanded,
                ...dispatch,
              },
            }}
          />
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={1000}>
          <Collapse in={isExpanded} timeout={"auto"} unmountOnExit>
            <Box sx={{ margin: 1, marginLeft: 10 }}>
              <MemoizedShareTable
                layer={layer}
                layerIndex={layerIndex}
                updateShare={updateShare}
                deleteShare={deleteShare}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapDispatchToProps = {
  updateLimit: towerPricingActions.updateLimit,
  updateAttachment: towerPricingActions.updateAttachment,
  updateMarketGrossWrittenPremium:
    towerPricingActions.updateMarketGrossWrittenPremium,
  updateSelected: towerPricingActions.updateSelected,
  addLayer: towerPricingActions.addLayer,
  updateLayer: towerPricingActions.updateLayer,
  deleteLayer: towerPricingActions.deleteLayer,
  updateShare: towerPricingActions.updateShare,
  deleteShare: towerPricingActions.deleteShare,
};

export default memo(
  connect(null, mapDispatchToProps)(TowerTableRow),
  _.isEqual
);
