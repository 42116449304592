import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import GoodBadTableCell from "components/common/GoodBadTableCell";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";

const PricingTableRow = (props) => {
  return (
    <TableRow>
      {props.meta.description ? (
        <Tooltip title={props.meta.description}>
          <TableCell component={"th"} scope={"row"}>
            {props.meta.name}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell component={"th"} scope={"row"}>
          {props.meta.name}
        </TableCell>
      )}
      {props.columns.map((column) => {
        const cellKey = `PRICING_TABLE_${props.meta.key}_CELL_${column.key}`;
        const stateValue = (props.values || {})[column.key];
        const value = column.f ? column.f(stateValue) : stateValue;
        const isOverridable = props.onChangeNumber && column.isOverrideColumn;
        if (isOverridable) {
          return (
            <TableCell key={cellKey} scope={"row"}>
              <PrettyNumberTextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { textAlign: "right" } }}
                hiddenLabel
                size={"small"}
                variant={"filled"}
                onChangeNumber={(x) =>
                  props.onChangeNumber(column.inverseF ? column.inverseF(x) : x)
                }
                value={value}
                fullWidth
                prefix={column.prefix}
                suffix={column.suffix}
                offFormatter={(num) =>
                  num.toLocaleString("en-US", {
                    maximumFractionDigits: column.decimalScale,
                  })
                }
                helperText={props.helperText}
              />
            </TableCell>
          );
        } else {
          return (
            <GoodBadTableCell
              key={cellKey}
              isLoading={props.isLoading}
              value={value}
              prefix={column.prefix}
              suffix={column.suffix}
              decimalScale={column.decimalScale}
              fixedDecimalScale
              good={column.goodScaler ? column.goodScaler(value) : null}
            />
          );
        }
      })}
    </TableRow>
  );
};

export default PricingTableRow;
