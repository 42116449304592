import { Delete as DeleteIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Component from "components/Component";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import StatusSelect from "components/common/StatusSelectWithChecks";
import PolicyNumberField from "components/layers/PolicyNumberField";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as programPricingStore from "store/programPricing";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const PerilsCoverage = ({ layerId }) => {
  const dispatch = useDispatch();

  const perilsCoverage = useSelector(programPricingStore.select)?.layers?.[
    layerId
  ]?.perilsCoverage;

  const setPerilsCoverage = (perilsCoverage) => {
    dispatch(
      programPricingStore.updatePathValue({
        path: ["layers", layerId, "perilsCoverage"],
        value: perilsCoverage,
      })
    );
  };

  const { data: catExposuresConfig } = staticDataService.useConfigQuery(
    "cat_exposures"
  );

  const perilsCoverageOptions =
    catExposuresConfig?.enums?.perilsCoverage?.choices ?? [];

  return (
    <Disabled ifReadOnly>
      <Autocomplete
        value={
          perilsCoverageOptions.filter(
            (coverage) => coverage.key === perilsCoverage
          )?.[0] ?? (perilsCoverage == null ? null : { name: "<Unknown>" })
        }
        onChange={(_, value) => setPerilsCoverage(value?.key ?? null)}
        options={perilsCoverageOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
            size={"small"}
            hiddenLabel
          />
        )}
        autoHighlight
      />
    </Disabled>
  );
};

export const PolicyForm = ({ layer }) => {
  const { data: policyForms } = staticDataService.useConfigQuery(
    "policy_forms"
  );

  const dispatch = useDispatch();

  const policyFormOptions = policyForms?.policy_forms ?? [];

  const policyForm = layer?.policyForm ?? null;

  return (
    <Disabled ifReadOnly>
      <Autocomplete
        value={
          policyFormOptions.filter(
            (option) => option.code === policyForm
          )?.[0] ?? (policyForm == null ? null : { name: "<Unknown>" })
        }
        onChange={(_, value) =>
          dispatch(
            pricingActions.updateLayer({
              layerId: layer?.id,
              values: { policyForm: value?.code ?? null },
            })
          )
        }
        options={policyFormOptions}
        getOptionKey={(option) => option.code}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
            size={"small"}
            hiddenLabel
          />
        )}
        autoHighlight
      />
    </Disabled>
  );
};

const LayerTable = () => {
  const layers = useSelector(pricingSelectors.selectResolvedLayers);

  const dispatch = useDispatch();

  return (
    <TableContainer>
      <Table size={"small"} className={"condensed-table"}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 60 }}>{/* Delete Layer */}</TableCell>
            <TableCell sx={{ width: 180 }}>{/* Status */}</TableCell>
            <TableCell sx={{ width: 200 }}>{"Limit"}</TableCell>
            <TableCell sx={{ width: 200 }}>{"Attachment"}</TableCell>
            <TableCell sx={{ width: 150 }}>{"Target Premium"}</TableCell>
            <TableCell sx={{ width: 250 }}>{"Perils"}</TableCell>
            <TableCell sx={{ width: 250 }}>{"Scheme"}</TableCell>
            <TableCell sx={{ width: 300 }}>{"Reference"}</TableCell>
            <TableCell>{/* Fill rest of space */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(layers ?? []).map((layer, layerIndex) => (
            <TableRow key={layer?.id ?? layerIndex}>
              <TableCell align={"left"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <IconButton
                    icon={DeleteIcon}
                    onClick={() => {
                      dispatch(pricingActions.deleteLayer(layerIndex));
                    }}
                  />
                </Disabled>
              </TableCell>
              <TableCell
                align={"right"}
                sx={{ verticalAlign: "top", paddingTop: "10px" }}
              >
                <Box sx={{ marginTop: "0.5em" }}>
                  <Disabled ifReadOnly>
                    <StatusSelect
                      layer={layer}
                      variant={"compact"}
                      showQuickSwitch
                      status={layer?.status ?? null}
                      onChange={(status) => {
                        dispatch(
                          pricingActions.updateLayer({
                            layerId: layer?.id,
                            values: { status },
                          })
                        );
                      }}
                    />
                  </Disabled>
                </Box>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <PrettyNumberTextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(limit) => {
                      dispatch(
                        pricingActions.updateLayer({
                          layerId: layer?.id,
                          values: { limit },
                        })
                      );
                    }}
                    value={layer?.limit ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <PrettyNumberTextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(attachment) => {
                      dispatch(
                        pricingActions.updateLayer({
                          layerId: layer?.id,
                          values: { attachment },
                        })
                      );
                    }}
                    value={layer?.attachment ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <PrettyNumberTextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(brokerTargetGrossPremium) => {
                      dispatch(
                        pricingActions.updateLayer({
                          layerId: layer?.id,
                          values: { brokerTargetGrossPremium },
                        })
                      );
                    }}
                    value={layer?.brokerTargetGrossPremium ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <PerilsCoverage layerId={layer?.id} />
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <PolicyForm layer={layer} />
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <PolicyNumberField
                  value={layer?.reference ?? ""}
                  onChange={(reference) => {
                    dispatch(
                      pricingActions.updateLayer({
                        layerId: layer?.id,
                        values: { reference },
                      })
                    );
                  }}
                  layerId={layer?.id}
                  size={"small"}
                  hiddenLabel
                  label={null}
                />
              </TableCell>
              <TableCell>{/* Fill rest of space */}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Layers = () => {
  const dispatch = useDispatch();

  return (
    <Component title={"Layers"}>
      <Stack direction={"column"} spacing={2}>
        <LayerTable />
        <Stack direction={"row"}>
          <Disabled ifReadOnly>
            <Button
              onClick={() => {
                dispatch(pricingActions.addLayer());
              }}
            >
              {"Add Layer"}
            </Button>
          </Disabled>
        </Stack>
      </Stack>
    </Component>
  );
};

export default Layers;
