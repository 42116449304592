import SmallLoader from "../../common/SmallLoader";
import { Button } from "@mui/material";
import Disabled from "components/common/Disabled";
import React from "react";
import { useGetCsvMetadataStatusQuery } from "services/filesService";

const ClaimsV2LossRunButton = ({ support }) => {
  const {
    data: csvMetadataStatus,
    status: csvMetadataStatusStatus,
  } = useGetCsvMetadataStatusQuery(support.getFileId(), {
    skip: !support.canProcessClaimsV2LossFile(),
  });

  if (csvMetadataStatusStatus === "fulfilled") {
    if (csvMetadataStatus?.csvStatus === "UP_TO_DATE") {
      return (
        <Disabled ifReadOnly>
          <Button
            variant={"contained"}
            size={"small"}
            disableElevation
            onClick={(e) => {
              support.processClaimsV2LossFile();
              e.stopPropagation();
            }}
          >
            {"Loss Run"}
          </Button>
        </Disabled>
      );
    } else {
      return <></>;
    }
  } else {
    return <SmallLoader />;
  }
};

export default ClaimsV2LossRunButton;
