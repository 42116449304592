import IconButton from "./IconButton";
import ReviewIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import ScenariosIcon from "@mui/icons-material/Category";
import CloseIcon from "@mui/icons-material/CloseRounded";
import DealsIcon from "@mui/icons-material/HowToVote";
import ReportsIcon from "@mui/icons-material/ImportContacts";
import SidebarIcon from "@mui/icons-material/MenuRounded";
import AdminIcon from "@mui/icons-material/VpnKey";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { toSentenceCase } from "common/strings";
import Logo from "components/common/Logo";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import * as userSelectors from "store/selectors/user/userSelectors";

const AppMenu = ({
  isUserReadOnly,
  isSuperUser,
  componentVisibility,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const contexts = [
    {
      text: "Submissions",
      path: "/submissions",
      icon: <DealsIcon />,
      visible: true,
    },
    {
      text: "Reports",
      path: "/reports",
      icon: <ReportsIcon />,
      visible: isUserReadOnly ? false : null,
    },
    {
      text: "Review",
      path: "/review",
      icon: <ReviewIcon />,
      visible: true,
    },
    {
      text: "Scenarios",
      path: "/scenarios",
      icon: <ScenariosIcon />,
      visible: isUserReadOnly ? false : null,
    },
    {
      text: "Admin",
      path: "/admin",
      icon: <AdminIcon />,
      visible: isSuperUser,
    },
  ].filter(
    (x) => x.visible ?? componentVisibility[`<AppMenu>${x.path}`] ?? false
  );

  const location = useLocation();

  useEffect(() => {
    if (typeof onChange !== "function") return;
    const basePath = (location.pathname.substring(1) || "submissions").split(
      "/"
    )[0];
    onChange(toSentenceCase(basePath));
  }, [location, onChange]);

  return (
    <>
      <IconButton
        icon={SidebarIcon}
        className={"link"}
        onClick={toggleMenu}
        data-testid={"side-bar-button"}
      />
      <Drawer
        variant={"temporary"}
        anchor={"left"}
        open={isOpen}
        onClose={toggleMenu}
      >
        <Stack minWidth={240}>
          <List>
            <ListItem key={"APP_MENU_HEADER"}>
              <Stack
                direction={"row"}
                alignItems={"baseline"}
                justifyContent={"space-between"}
                style={{ width: "100%" }}
              >
                <Logo height={20} variant={"wordmark"} />
                <IconButton
                  icon={CloseIcon}
                  aria-label={"Close App Menu"}
                  edge={"end"}
                  onClick={toggleMenu}
                />
              </Stack>
            </ListItem>
            {contexts.map((context) => {
              return (
                <ListItemButton
                  component={Link}
                  to={context.path}
                  key={context.path}
                  onClick={() => setIsOpen(false)}
                  data-testid={`side-bar-content-${context.text}`}
                >
                  <ListItemIcon>{context.icon}</ListItemIcon>
                  <ListItemText primary={context.text} />
                </ListItemButton>
              );
            })}
          </List>
        </Stack>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  isUserReadOnly: userSelectors.isUserReadOnly(state),
  isSuperUser: userSelectors.isSuperUser(state),
  componentVisibility:
    userSelectors.selectUserConfig(state).componentVisibility ?? {},
});

export default connect(mapStateToProps)(AppMenu);
