import { FileListTreeSupport } from "./FileListTreeSupport";

export class DirListTreeSupport {
  constructor(
    attachments,
    parentDirSupport,
    parentFileSupport,
    fileTrees,
    parentIds
  ) {
    this.isDir = true;
    this.name = attachments.name || "";
    this.id = attachments.id || null;
    this.parentIds = [...(parentIds || []), this.id];
    this.parentDirSupport = parentDirSupport;
    this.attachments = attachments || {};
    const attFiles = attachments.attachedFiles || [];
    const hidden = attachments.attachedFilesHidden || {};
    const overrides = attachments.attachedFileNameOverrides || {};
    this.fileTrees = fileTrees;
    this.files = attFiles
      .map((fileId) => {
        const tree = fileTrees[fileId];
        if (!tree) return null;
        return new FileListTreeSupport(
          fileId,
          this.id,
          parentFileSupport,
          hidden,
          overrides,
          tree
        );
      })
      .filter((d) => d);
    this.directories = (attachments.subDirectories || []).map(
      (dir) =>
        new DirListTreeSupport(
          dir,
          parentDirSupport,
          parentFileSupport,
          fileTrees,
          this.parentIds
        )
    );
  }
  getName() {
    return this.name;
  }
  getId() {
    return this.id;
  }
  getFiles() {
    return this.files;
  }
  getDirectories() {
    return this.directories;
  }
  isRoot() {
    return this.name === "" && this.id === null;
  }
  areFilesLoading() {
    return this.files.filter((f) => f.isFileLoading()).length > 0;
  }
  allFilesLoadedFailed() {
    return this.files.filter((f) => !f.isFileLoadingFailed()).length === 0;
  }
  deleteDirectory() {
    this.parentDirSupport.deleteDir(this.id);
  }
  hasCreateDirButtons() {
    return !!this.parentDirSupport.createDirButtons;
  }
  createDirButtons() {
    if (this.hasCreateDirButtons())
      return this.parentDirSupport.createDirButtons(this);
    else return [];
  }
  getCreateFileButtons() {
    return this.parentDirSupport.createFileButtons;
  }
  setDirectoryName(name) {
    this.parentDirSupport.setDirName(this.id, name);
  }
  canAddDirectory() {
    return !!this.parentDirSupport.addDir;
  }
  addDirectory() {
    if (this.canAddDirectory()) this.parentDirSupport.addDir(this.id);
  }
  moveDir(toDirId) {
    if (this.parentDirSupport.moveDirectory)
      this.parentDirSupport.moveDirectory(this.id, toDirId);
  }
  getUniqueId() {
    return `DIR-${this.id}`;
  }
  noFiles() {
    return this.files.length === 0;
  }
  canDropOnMe(dirId) {
    if (!dirId) return true;
    if (
      (this.attachments.subDirectories || []).filter((att) => att.id === dirId)
        .length
    )
      return false;
    return !this.parentIds.filter((pid) => pid === dirId).length > 0;
  }

  canDelete() {
    if (this.isRoot()) return false;
    const hasFiles = (att) => {
      if ((att.attachedFiles || []).length) return true;
      const dirs = att.subDirectories || [];
      for (const i in dirs) if (hasFiles(dirs[i])) return true;
      return false;
    };
    return !hasFiles(this.attachments);
  }

  getExpanded() {
    return this.parentDirSupport.getExpanded();
  }

  expand() {
    this.parentDirSupport.expand(this.getUniqueId());
  }
}
