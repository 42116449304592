import * as time from "../common/time";
import * as config from "../config";
import * as utils from "../utils";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

const baseFilesQuery = async (args, api, extraOptions) =>
  baseQuery(config.endpoints.files, args, api, extraOptions);
const baseTablesQuery = async (args, api, extraOptions) =>
  baseQuery(config.endpoints.tables, args, api, extraOptions);
const baseQuery = async (urlGen, args, api, extraOptions) => {
  const payload = { ...args.payload };
  if (args.payload?.body) {
    if (!payload.method) payload.method = "POST";
    if (!payload.headers)
      payload.headers = {
        "Content-Type": "application/json",
      };
    payload.body = JSON.stringify(payload.body);
  }
  try {
    const response = await utils.authenticatedFetch(urlGen(args.url), payload);
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

const newWaitTime = (current) => {
  if (current < 500) current = 1000;
  if (current > 3000) current = 4000;
  return current * (1.1 + Math.random() * 0.5);
};

const awaitCompletion = async (
  initialResponseData,
  progressNotification = null
) => {
  const initialResponse = initialResponseData.data;
  let resp = initialResponse;
  const retryPayload = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id: initialResponse.id }),
  };
  let waitTime = 0;
  while (true) {
    switch (resp.responseType) {
      case "INLINE":
        return resp;
      // eslint-disable-next-line no-fallthrough
      case "SLOW_PROCESS_S3_STORED":
      case "LARGE_FILE_S3_STORED":
        switch (resp.status) {
          case "READY":
            if (typeof progressNotification === "function") {
              progressNotification({
                ...resp.timimng,
                percentageComplete: 100,
                remainingSeconds: 0,
              });
            }
            const fromS3 = await utils.plainFetch(resp.url);
            return await fromS3.json();
          case "NOT_READY":
            if (typeof progressNotification === "function") {
              progressNotification(resp["timing"]);
            }
            await time.sleep(waitTime);
            waitTime = newWaitTime(waitTime);
            const baseResp = await utils.authenticatedFetch(
              config.endpoints.tables("retrieve-s3-stored-slow-response-data"),
              retryPayload
            );
            resp = await baseResp.json();
            break;
          default:
            throw new Error("Unknown status: " + resp);
        }
        break;
      default:
        throw new Error("Unknown responseType: " + resp);
    }
  }
};

const pageLoaded = {};
const getMetadatAlreadyReady = (fileId, tableName) => {
  const key = fileId + ":" + tableName;
  return pageLoaded[key];
};

const awaitPageReady = async (fileId, tableName) => {
  const key = fileId + ":" + tableName;
  let waitTime = 1000;
  while (true) {
    if (pageLoaded[key]) {
      return pageLoaded[key];
    }
    const list = await baseFilesQuery({
      url: `table-metadata/${fileId}`,
      method: "GET",
    });
    const table = list?.data?.tables?.[tableName];
    const status = table?.metadata?.status;
    if (["FAILED", "LOADED"].includes(status)) {
      pageLoaded[key] = table.metadata;
      return table.metadata;
    }
    await time.sleep(waitTime);
    waitTime = newWaitTime(waitTime);
  }
};

export const tablesApi = createApi({
  reducerPath: "tablesApi",
  baseQuery: baseTablesQuery,
  endpoints: (builder) => ({
    getBasicTableList: builder.query({
      query: (fileId) => ({
        url: "retrieve-table-list",
        payload: {
          body: { id: fileId },
        },
      }),
    }),
    getTransformMetadata: builder.query({
      query: (fileId) => ({
        url: "retrieve-table-metadata",
        payload: { body: { id: fileId } },
      }),
    }),
    getTableList: builder.query({
      queryFn: async (fileId) => {
        let waitTime = 500;
        while (true) {
          const list = await baseFilesQuery({
            url: `table-metadata/${fileId}`,
            method: "GET",
          });
          const tables = list?.data?.tables;
          if (tables) {
            const res = {};
            for (const name in tables) {
              res[name] = {
                sheetIndex: tables[name]?.metadata.sheetIndex,
              };
            }
            return { data: { tables: res } };
          }
          await time.sleep(waitTime);
          waitTime = newWaitTime(waitTime);
        }
      },
    }),
    getTableMetadata: builder.query({
      queryFn: async ({ fileId, tableName }) => {
        const meta = getMetadatAlreadyReady(fileId, tableName);
        const ret = {
          data: meta ? meta : await awaitPageReady(fileId, tableName),
        };
        return ret;
      },
    }),
    getHeaderRow: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
      }) => {
        if (!getMetadatAlreadyReady(fileId, tableName)) {
          await awaitPageReady(fileId, tableName);
        }
        return await baseTablesQuery({
          url: "retrieve-header-row",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
            },
          },
        });
      },
    }),
    getColumnSummary: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        column,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "retrieve-column-summary",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              column,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        if (resp.status === "ERROR") throw Error(resp.message);
        return { data: { ...resp } };
      },
    }),
    getColumnTypeSummary: builder.query({
      query: ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
      }) => ({
        url: "retrieve-column-type-summary",
        payload: {
          body: {
            id: fileId,
            tableName,
            skipColumns,
            skipRows,
            maxColumns,
            maxRows,
            headerRows,
          },
        },
      }),
    }),
    getTablePage: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        pageSize,
        pageIndex,
      }) => {
        if (!getMetadatAlreadyReady(fileId, tableName)) {
          await awaitPageReady(fileId, tableName);
        }

        return await baseTablesQuery({
          url: "retrieve-table-page",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              pageSize,
              pageIndex,
            },
          },
        });
      },
    }),
    getTableObjectsPage: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        pageSize,
        pageIndex,
      }) => {
        if (!getMetadatAlreadyReady(fileId, tableName)) {
          await awaitPageReady(fileId, tableName);
        }

        return await baseTablesQuery({
          url: "retrieve-table-objects-page",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              pageSize,
              pageIndex,
            },
          },
        });
      },
    }),
    getConversionErrors: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        skipBlankRows,
        mapping,
        progressNotification,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "retrieve-conversion-errors",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              skipBlankRows,
              mapping,
            },
          },
        });
        const resp = await awaitCompletion(initialResp, progressNotification);
        if (resp.status === "ERROR") throw Error(resp.message);
        return {
          data: {
            fileId: fileId,
            tableName: tableName,
            errors: resp.errors,
            warnings: resp.warnings,
          },
        };
      },
    }),
    doConversion: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        skipBlankRows,
        mapping,
        progressNotification,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "create-subtable",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              skipBlankRows,
              mapping,
            },
          },
        });
        const resp = await awaitCompletion(initialResp, progressNotification);
        if (resp.status === "ERROR") throw Error(resp.message);
        return {
          data: {
            fileId: fileId,
            createdFile: resp.errors?.length ? null : resp.targetFileId,
            tableName: tableName,
            errors: resp.errors,
            warnings: resp.warnings,
          },
        };
      },
    }),
    getClaimsMapping: builder.query({
      queryFn: async ({
        columnMappings,
        substitutions,
        rowDeletions,
        deletionMappings,
        openClosedMapping,
        openClosedDeleteUnmappedRows,
        openClosedDefault,
        coverageMapping,
        coverageDeleteUnmappedRows,
        coverageDefault,
        excess,
        threshold,
        defaultDate,
        dateConversionTypes,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "create-table-mapping",
          payload: {
            body: {
              mappingName: "claims",
              columnMappings,
              substitutions,
              rowDeletions,
              deletionMappings,
              openClosedMapping,
              openClosedDeleteUnmappedRows,
              openClosedDefault,
              coverageMapping,
              coverageDeleteUnmappedRows,
              coverageDefault,
              excess,
              threshold,
              defaultDate,
              dateConversionTypes,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        if (resp.status === "ERROR") throw Error(resp.message);
        return {
          data: resp.mapping,
        };
      },
    }),
    getTableSummary: builder.query({
      queryFn: async ({
        fileId,
        tableName,
        skipColumns,
        skipRows,
        maxColumns,
        maxRows,
        headerRows,
        maxOutputSize,
      }) => {
        const initialResp = await baseTablesQuery({
          url: "retrieve-table-summary",
          payload: {
            body: {
              id: fileId,
              tableName,
              skipColumns,
              skipRows,
              maxColumns,
              maxRows,
              headerRows,
              maxOutputSize,
            },
          },
        });
        const resp = await awaitCompletion(initialResp);
        if (resp.status === "ERROR") throw Error(resp.message);
        return {
          data: resp.summary,
        };
      },
    }),
    getClaimsColumns: builder.query({
      query: () => ({
        url: "retrieve-output-columns",
        payload: { body: { mappingName: "claims" } },
      }),
    }),
  }),
});

export const {
  useGetTableListQuery,
  useGetTableMetadataQuery,
  /** useGetHeaderRowQuery, returns
   * {
   *   "header": [
   *     "first-column-name",
   *     "second-col-name"
   *   ]
   * }
   */
  useGetHeaderRowQuery,
  useGetColumnSummaryQuery,
  /** useGetColumnTypesSummaryQuery
   * returns {
   *   totalRows: 50,
   *   types: [
   *     {
   *       "name": "column-name2, "types": {
   *       "date":{"count": 10, "fraction": 0.2},
   *       "str":{"count": 2, "fraction": 0.04},
   *       ...
   *     },
   *     ...
   *   ]
   * }
   */
  useGetColumnTypeSummaryQuery,
  /** useGetTablePageQuery returns
   * {
   *    "page": [a list of rows each row being a list of cell values as strings],
   *    "pageIndex": 0,
   *    "pageSize": 50,
   *    pagesAvailable: 2,
   *    totalRows: 80
   * }
   */
  useGetTablePageQuery,
  /** useGetTableObjectsPageQuery returns
   * {
   *    "page": [a list of rows each row being a list of cells as objects with known attributes],
   *    "pageIndex": 0,
   *    "pageSize": 50,
   *    pagesAvailable: 2,
   *    totalRows: 80
   * }
   */
  useGetTableObjectsPageQuery,
  useGetConversionErrorsQuery,
  useDoConversionQuery,
  useGetClaimsMappingQuery,
  useGetClaimsColumnsQuery,
  useGetTransformMetadataQuery,
  useGetTableSummaryQuery,
} = tablesApi;
