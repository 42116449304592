import { REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS } from "../../flags";
import * as staticDataService from "../../services/staticDataService";
import SmallLoader from "../common/SmallLoader";
import { ReviewDownloadButtons } from "./ReviewDownloadButtons";
import { getColumnValues, LocalLoader } from "./ReviewSupport";
import SortDescendingIcon from "@mui/icons-material/KeyboardArrowDown";
import SortAscendingIcon from "@mui/icons-material/KeyboardArrowUp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import UnsortedIcon from "@mui/icons-material/UnfoldMore";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Component from "components/Component";
import FilterBar from "components/common/FilterBar";
import FilterSelect from "components/common/FilterSelect";
import ReviewStatusBadge from "components/common/ReviewStatusBadge";
import * as support from "components/review/PeerReviewSupport";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as reportService from "services/reportService";

const COLUMNS = [
  {
    key: "inception",
    title: "Inception",
    Cell: ({ submission }) => (
      <TableCell className={"tablecell-inception"}>
        {submission.inception}
      </TableCell>
    ),
    compare: support.compareStringFields((s) => s.inception),
    isSearchable: true,
  },
  {
    key: "expiration",
    title: "Expiration",
    Cell: ({ submission }) => (
      <TableCell className={"tablecell-inception"}>
        {submission.expiration}
      </TableCell>
    ),
    compare: support.compareStringFields((s) => s.expiration),
    isSearchable: true,
  },
  {
    key: "insured",
    title: "Insured",
    Cell: ({ submission }) => <TableCell>{submission.insured}</TableCell>,
    compare: support.compareStringFields((s) => s.insured),
    isSearchable: true,
  },
  {
    key: "reference",
    title: "Reference",
    Cell: ({ submission }) => <TableCell>{submission.reference}</TableCell>,
    compare: support.compareStringFields((s) => s.reference),
    isSearchable: true,
  },
  {
    key: "preferredUnderwriter",
    title: "Underwriter",
    Cell: ({ submission }) => (
      <TableCell>{submission.preferredUnderwriter}</TableCell>
    ),
    compare: support.compareStringFields((s) => s.preferredUnderwriter),
    isSearchable: true,
  },
  {
    key: "reviewer",
    title: "Reviewer",
    Cell: ({ submission }) => <TableCell>{submission.reviewer}</TableCell>,
    compare: support.compareStringFields((s) => s.reviewer),
    isSearchable: true,
  },
  {
    key: "reviewDate",
    title: "Review Date",
    Cell: ({ submission }) => <TableCell>{submission.reviewDate}</TableCell>,
    compare: support.compareStringFields((s) => s.reviewDate),
    isSearchable: true,
  },
  {
    key: "status",
    title: "Status",
    Cell: ({ submission }) => (
      <TableCell>
        <ReviewStatusBadge status={submission.status} />
      </TableCell>
    ),
    compare: support.compareStringFields((s) => s.status),
    isSearchable: true,
  },
];

const SubmissionTableRow = ({ submission }) => {
  return (
    <TableRow>
      {COLUMNS.map(({ Cell, key }) => (
        <Cell key={key} submission={submission} />
      ))}
      <TableCell>
        <Link
          to={`/submissions/${submission.submissionId}/summary/notes`}
          target={"_blank"}
        >
          <OpenInNewIcon />
        </Link>
      </TableCell>
    </TableRow>
  );
};

const SubmissionsTable = ({ columnSort, setColumnSort, ...props }) => {
  const SortArrow = ({ name, columnKey }) => (
    <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
      <span>{name}</span>
      <IconButton
        size={"small"}
        onClick={() => {
          if (columnSort == null || columnSort.columnKey !== columnKey) {
            setColumnSort({
              columnKey,
              ascending: true,
            });
          } else if (columnSort.ascending) {
            setColumnSort({
              ...columnSort,
              ascending: false,
            });
          } else {
            setColumnSort(null);
          }
        }}
      >
        {columnSort == null || columnSort.columnKey !== columnKey ? (
          <UnsortedIcon />
        ) : columnSort.ascending ? (
          <SortAscendingIcon />
        ) : (
          <SortDescendingIcon />
        )}
      </IconButton>
    </Stack>
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {COLUMNS.map((column) => (
              <TableCell key={column.key}>
                <SortArrow name={column.title} columnKey={column.key} />
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {props.submissions.map((sub, subIndex) => (
            <SubmissionTableRow
              key={`${sub.submissionId}-${subIndex}`}
              submission={sub}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PeerReview = () => {
  const {
    data: flags,
    isLoading: isFeatureFlagsLoading,
  } = staticDataService.useFeatureFlagsQuery();
  const showAllValuesInFilters =
    flags?.[REVIEW_REPORTS_SHOW_ALL_VALUES_IN_FILTERS] ?? false;

  const searchableColumns = COLUMNS.filter((c) => c.isSearchable).map(
    (c) => c.key
  );

  const [columnSort, setColumnSort] = useState({
    columnKey: "inception",
    ascending: false,
  });
  const [searchInput, setSearchInputValues] = useState(null);

  const sortedColumn =
    COLUMNS.filter((c) => c.key === columnSort?.columnKey)?.[0] ?? COLUMNS[0];

  const [filters, setFilterValues] = useState([
    { context: "Review Status", name: "status", value: [], default: [] },
    { context: "Insured", name: "insured", value: [], default: [] },
    {
      context: "Underwriter",
      name: "preferredUnderwriter",
      value: [],
      default: [],
    },
    { context: "Reviewer", name: "reviewer", value: [], default: [] },
  ]);

  const createQuery = (filterList, searchString) => {
    let args = {};
    const filtsList = filterList.reduce((res, filter) => {
      if (filter.value?.length) {
        res.push({ key: filter.name, values: filter.value });
      }
      return res;
    }, []);

    if (filtsList?.length) {
      if (showAllValuesInFilters) args["orIns"] = filtsList;
      else args["andIns"] = filtsList;
    }
    if (searchString?.trim()) {
      args["searchString"] = searchString.trim();
      args["searchColumns"] = searchableColumns;
    }
    return {
      type: "peer_review_list",
      args,
      format: "json",
      filename: "peer_review_list",
    };
  };

  const [query, setQuery] = useState(createQuery(filters, searchInput));

  const {
    data: submissions,
    isLoading: isReportLoading,
    isFetching,
  } = reportService.useReportQuery(query);

  const {
    data: unfilteredSubmissions,
    isLoading: unfilteredLoading,
  } = reportService.useReportQuery(
    {
      type: "peer_review_list",
      args: { requiredColumns: filters.map((f) => f.name) },
      format: "json",
      filename: "peer_review_list",
    },
    {
      skip: isFeatureFlagsLoading || !showAllValuesInFilters,
    }
  );

  const isLoading = isReportLoading || isFeatureFlagsLoading;

  const setFilters = (filterList) => {
    setFilterValues(filterList);
    setQuery(createQuery(filterList, searchInput));
  };

  const setSearchInput = (val) => {
    setSearchInputValues(val);
    setQuery(createQuery(filters, val));
  };

  const updateFilter = (context, value) => {
    const updated = filters.map((item) => {
      return {
        ...item,
        value: item.context === context ? value : item.value,
      };
    });
    setFilters(updated);
  };

  const filterDialogDefault = {
    open: false,
    context: null,
    items: [],
    sortItems: true,
    initialSelected: [],
  };

  const [filterDialog, setFilterDialog] = useState(filterDialogDefault);

  const getDisplayableSubmissions = () => {
    const filteredSubList = (submissions || []).map((s) => s);
    filteredSubList.sort(
      support.invertComparatorIf(!columnSort?.ascending)(sortedColumn.compare)
    );
    return filteredSubList;
  };

  const displayableSubmissions = getDisplayableSubmissions();

  const openFilterDialog = (context) => {
    const filter = filters.filter((x) => x.context === context)[0];
    setFilterDialog({
      ...filterDialog,
      open: true,
      context: context,
      items: getColumnValues(
        filter.name,
        showAllValuesInFilters ? unfilteredSubmissions : submissions
      ),
      sortItems: true,
      selected: filter.value,
    });
  };

  const closeFilterDialog = () => {
    setFilterDialog(filterDialogDefault);
  };

  return (
    <>
      <Component>
        {isLoading ? (
          <LocalLoader />
        ) : (
          <>
            {unfilteredLoading ? (
              <SmallLoader />
            ) : (
              <>
                <FilterBar
                  filters={filters}
                  onClickFilter={(context) => openFilterDialog(context)}
                  onChangeFilters={(filters) => setFilters(filters)}
                  onChangeSearchInput={(searchText) =>
                    setSearchInput(searchText)
                  }
                  options={
                    <ReviewDownloadButtons
                      name={"PeerReview"}
                      type={"peer_review_list"}
                      filterArgs={query}
                    />
                  }
                />
                <FilterSelect
                  context={filterDialog.context}
                  items={filterDialog.items}
                  sortItems={filterDialog.sortItems}
                  initialSelected={filterDialog.selected}
                  open={filterDialog.open}
                  onClose={closeFilterDialog}
                  onChange={updateFilter}
                />
              </>
            )}
            {isFetching ? (
              <LocalLoader />
            ) : (
              <SubmissionsTable
                columnSort={columnSort}
                setColumnSort={setColumnSort}
                submissions={displayableSubmissions}
              />
            )}
          </>
        )}
      </Component>
    </>
  );
};

export default PeerReview;
