import * as config from "../../../../config";
import * as utils from "../../../../utils";
import * as actionTypes from "./exposureActionTypes";
import * as actions from "./exposureActions";
import * as logger from "common/logger";

export const updateValue = (year, key, value) => ({
  type: actionTypes.UPDATE_EXPOSURE_VALUE,
  payload: { year, key, value },
});

export const updateExposureMeasures = (industryClasses) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATING_EXPOSURE_MEASURES_LIST,
    payload: industryClasses,
  });
  utils
    .authenticatedFetch(
      config.endpoints.claims(
        "exposure/types?" +
          new URLSearchParams({ industryClass: industryClasses.join("|") })
      )
    )
    .then((response) => {
      response.json().then((data) => {
        dispatch({
          type: actionTypes.UPDATED_EXPOSURE_MEASURES_LIST,
          payload: data,
        });
      });
    })
    .catch((error) => {
      logger.exception(error);
    });
};

export const loadAllLossCategories = () => (dispatch) => {
  utils
    .authenticatedFetch(config.endpoints.claims("exposure/loss-categories"))
    .then((response) => {
      response.json().then((data) => {
        dispatch({
          type: actionTypes.UPDATED_LOSS_CATEGORIES_LIST,
          payload: data,
        });
      });
    })
    .catch((error) => {
      logger.exception(error);
    });
};

export const updateExposureNote = (note) => ({
  type: actionTypes.UPDATE_EXPOSURE_NOTE,
  payload: note,
});

export const addExtraExposureMetric = (exposureMetric) => ({
  type: actionTypes.ADD_EXTRA_EXPOSURE_METRIC,
  payload: exposureMetric,
});

export const deleteExtraExposureMetric = (exposureMetric) => ({
  type: actionTypes.DELETE_EXTRA_EXPOSURE_METRIC,
  payload: exposureMetric,
});

const toggleExposureMetricType = ({ selected, fromTemplate }) => {
  if (fromTemplate) {
    return selected
      ? actionTypes.HIDE_TEMPLATE_EXPOSURE_METRIC
      : actionTypes.UNHIDE_TEMPLATE_EXPOSURE_METRIC;
  } else {
    return selected
      ? actionTypes.DELETE_EXTRA_EXPOSURE_METRIC
      : actionTypes.ADD_EXTRA_EXPOSURE_METRIC;
  }
};

export const toggleExposureMetric = ({ key, selected, fromTemplate }) => ({
  type: toggleExposureMetricType({ selected, fromTemplate }),
  payload: key,
});

export const updateCustomMeasureName = (key, name) => ({
  type: actionTypes.UPDATE_CUSTOM_MEASURE_NAME,
  payload: { key, name },
});

export const updateCustomMeasureUnit = (key, units) => ({
  type: actionTypes.UPDATE_CUSTOM_MEASURE_UNIT,
  payload: { key, units },
});

export const loadAllExposureMetrics = () => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD_ALL_EXPOSURE_METRICS_STARTED,
  });
  utils
    .authenticatedFetch(config.endpoints.claims("exposure/types"))
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: actionTypes.LOAD_ALL_EXPOSURE_METRICS_RETURNED,
        payload: data,
      });
    })
    .catch((e) => dispatch(actions.loadAllExposureMetricsError(e)));
};

export const loadAllIndustryClasses = () => (dispatch) => {
  dispatch({
    type: actionTypes.LOAD_ALL_INDUSTRY_CLASSES_STARTED,
  });
  utils
    .authenticatedFetch(config.endpoints.claims("exposure/industry-classes"))
    .then((response) => response.json())
    .then((data) => {
      dispatch({
        type: actionTypes.LOAD_ALL_INDUSTRY_CLASSES_RETURNED,
        payload: data,
      });
    })
    .catch((e) => dispatch(actions.loadAllIndustryClassesError(e)));
};

export const loadAllExposureMetricsError = (e) => {
  logger.exception(e);
  return {
    type: actionTypes.LOAD_ALL_EXPOSURE_METRICS_FAILED,
    payload: e,
  };
};

export const loadAllIndustryClassesError = (e) => {
  logger.exception(e);
  return {
    type: actionTypes.LOAD_ALL_INDUSTRY_CLASSES_FAILED,
    payload: e,
  };
};

export const deleteCustomMeasure = (measureKey) => {
  return { type: actionTypes.DELETE_CUSTOM_MEASURE, payload: measureKey };
};

export const updateExposureComment = ({ key, value }) => {
  return {
    type: actionTypes.UPDATE_EXPOSURE_COMMENT,
    payload: {
      key,
      value,
    },
  };
};

export const showAllExposureComments = () => {
  return {
    type: actionTypes.SHOW_ALL_EXPOSURE_COMMENTS,
  };
};

export const showExposureComment = ({ key }) => {
  return {
    type: actionTypes.SHOW_EXPOSURE_COMMENT,
    payload: {
      key,
    },
  };
};

export const hideAllExposureComments = () => {
  return {
    type: actionTypes.HIDE_ALL_EXPOSURE_COMMENTS,
  };
};

export const hideExposureComment = ({ key }) => {
  return {
    type: actionTypes.HIDE_EXPOSURE_COMMENT,
    payload: {
      key,
    },
  };
};

export const addExposureYear = (year) => ({
  type: actionTypes.ADD_EXPOSURE_YEAR,
  payload: year,
});
