import { Box, Stack, Typography } from "@mui/material";
import InlineSearch from "components/common/InlineSearch";
import Options from "components/common/Options";
import { useState } from "react";

const filterItems = (categorisedItems, filterText) => {
  if (filterText === "") {
    return categorisedItems;
  }

  const filterQuery = filterText.toLowerCase();
  const filtered = [];

  categorisedItems.forEach((category) => {
    if (category.name.toLowerCase().includes(filterQuery)) {
      filtered.push(category);
    } else {
      const matches = category.items.filter((x) =>
        x.label.toLowerCase().includes(filterQuery)
      );
      if (matches.length > 0) {
        filtered.push({ name: category.name, items: matches });
      }
    }
  });

  return filtered;
};

const CategorisedOptions = ({
  categorisedItems,
  onClick,
  levels,
  variant,
  actions = null,
}) => {
  const [filterText, setFilterText] = useState("");
  const filteredCategories = filterItems(categorisedItems, filterText);

  if (!categorisedItems || categorisedItems.length === 0) {
    return (
      <Typography variant={"body2"}>
        {"Sorry, we don't have anything to show you"}
      </Typography>
    );
  }

  return (
    <div>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ marginBottom: "1rem", padding: 0 }}
      >
        <InlineSearch
          onChange={(searchText) => {
            setFilterText(searchText);
          }}
        />
        <Box>{actions}</Box>
      </Stack>
      {filteredCategories.length === 0 && (
        <p>{`Sorry, no matches for '${filterText}'`}</p>
      )}
      {filteredCategories.map((category) => {
        return (
          <div key={category.name}>
            <Typography
              variant={"subtitle2"}
              sx={{ margin: "10px 0 6px 0 !important" }}
            >
              {category.name}
            </Typography>
            <Options
              items={category.items}
              levels={levels}
              onClick={onClick}
              variant={variant}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CategorisedOptions;
