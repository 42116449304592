import * as config from "config";

export const selectCreateCompanyState = (state) =>
  state.temp.admin.createCompanyState;

export const userLogMessage = (state) => state.temp.admin.userLogMessage;

export const hasUserLogMessage = (state) =>
  config.ENVIRONMENT === "dev" ||
  (state.temp.admin.userLogMessage &&
    state.temp.admin.userLogMessage.length > 4);
