import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

const validPattern = /^-?[0-9, ]*\.?[0-9, ]*(e[0-9]+)?[kKmMbBtT]?$/;

export const getMultiplicand = (lastChar) => {
  switch (lastChar) {
    case "k":
      return 1e3;
    case "m":
      return 1e6;
    case "b":
      return 1e9;
    case "t":
      return 1e12;
    default:
      return 1;
  }
};

export const parseNumberString = (str) => {
  if (str === "") {
    return null;
  }
  if (!validPattern.exec(str)) {
    return NaN;
  }
  str = str.replace(/[ ,]/g, "");
  const parsedFloat = parseFloat(str);
  const multiplicand = getMultiplicand(str.slice(-1).toLowerCase());

  if (!isNaN(parsedFloat)) {
    return parsedFloat * multiplicand;
  }
  return NaN;
};

export const getStringValue = (value) => {
  if (value === undefined || value === null) {
    return "";
  }
  if (typeof value === "string") {
    return value;
  }
  if (isNaN(value)) {
    return "";
  }
  return parseFloat(value.toPrecision(14)).toString();
};

const PrettyNumberTextField = (props) => {
  const {
    error,
    onChangeNumber,
    ref,
    prefix,
    suffix,
    stringValue,
    variant,
    offFormatter,
    allowClear,
    selectOnFocus = true,
    onValidation = null,
    ...other
  } = props;
  const [localStringValue, setLocalStringValue] = useState(stringValue || "");
  const [hasError, _setHasError] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  const setHasError = (newHasError) => {
    if (onValidation) {
      onValidation(!newHasError);
    }
    _setHasError(newHasError);
  };

  const onFocus = (e) => {
    setHasFocus(true);
    if (
      !localStringValue ||
      parseNumberString(localStringValue) !== props.value
    ) {
      setLocalStringValue(getStringValue(props.value));
    }
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const onMouseUp = (e) => {
    if (selectOnFocus) {
      if (e.target.select) e.target.select();
    }
  };
  const onKeyUp = (e) => {
    var code = e.keyCode ? e.keyCode : e.which;
    if (code === 9 && e.target.select) {
      e.target.select();
    }
    if (props.onKeyUp) {
      props.onKeyUp(e);
    }
  };

  const onBlur = (e) => {
    setHasFocus(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const changeStringValue = (e) => {
    const str = e.target.value;
    setLocalStringValue(str);
    const parsedNumber = parseNumberString(str);
    if (!isNaN(parsedNumber)) {
      setHasError(false);
      onChangeNumber && onChangeNumber(parsedNumber);
    } else {
      setHasError(true);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  const formatString = (num) => {
    if (num === undefined || num === null || isNaN(num)) {
      return "";
    }
    if (offFormatter) {
      return offFormatter(num);
    }
    return num.toLocaleString();
  };

  const ClearAdornment = (props) => (
    <InputAdornment position={"end"}>
      <IconButton
        tabIndex={-1}
        onClick={() => {
          setLocalStringValue("");
          setHasError(false);
          onChangeNumber && onChangeNumber(null);
        }}
        edge={"end"}
        disableRipple
        sx={{
          paddingLeft: 0,
          position: "relative",
          left: 2,
        }}
      >
        <ClearIcon
          sx={{
            fontSize: "12px",
          }}
        />
      </IconButton>
    </InputAdornment>
  );

  const EndAndornment = () => {
    if (variant === "percentage" || variant === "percentage outlined") {
      return <InputAdornment position={"start"}>{" %"}</InputAdornment>;
    } else if (
      props.allowClear &&
      !hasFocus &&
      props.value !== null &&
      props.value !== ""
    ) {
      return <ClearAdornment />;
    }
    return null;
  };

  const variantTranslator = () => {
    if (variant == null) {
      return "standard";
    } else if (variant === "percentage") {
      return "filled";
    } else if (variant === "percentage outlined") {
      return "outlined";
    } else {
      return variant;
    }
  };

  return (
    <TextField
      {...other}
      error={props.error || hasError}
      onChange={changeStringValue}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyUp={onKeyUp}
      onMouseUp={onMouseUp}
      autoComplete={"off"}
      value={
        hasFocus || props.error || hasError
          ? localStringValue
          : `${prefix || ""}${formatString(props.value)}${suffix || ""}`
      }
      variant={variantTranslator()}
      InputProps={{
        ...other?.InputProps,
        endAdornment: EndAndornment(),
      }}
    />
  );
};

export default PrettyNumberTextField;
