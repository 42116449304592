import {
  CloseRounded as CloseIcon,
  OfflineBoltRounded as BetaIcon,
  ChevronRightRounded as ExpandIcon,
  ExpandMoreRounded as CollapseIcon,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Chip,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import * as featureFlagHooks from "store/hooks/featureFlagHooks";

const HelpContent = () => (
  <Stack
    gap={1.5}
    sx={{
      marginTop: "0.5rem",
      padding: "1rem 1.25rem",
      borderRadius: "0.5rem",
      background: "#edf0f3",
    }}
  >
    <Typography variant={"subtitle1"}>{"What is a beta?"}</Typography>
    <Typography>
      {
        "Beta features are improvements to Marmalade that we share before they're completely finished."
      }
    </Typography>
    <Typography>
      {
        "This means you get access to new features earlier and your feedback can help shape the product."
      }
    </Typography>
    <Typography>
      {
        "Once work is complete the feature graduates from beta to general availability."
      }
    </Typography>

    <Typography variant={"subtitle1"}>{"How do I take part?"}</Typography>
    <Typography>
      {
        "New beta features are on by default so you don't have to do anything to take part."
      }
    </Typography>
    <Typography>
      {
        "You can recognise beta features by the blue thunderbolt that appears alongside them in Marmalade."
      }
    </Typography>

    <Typography variant={"subtitle1"}>{"Can I opt out?"}</Typography>
    <Typography>
      {
        "Sure, click any thunderbolt then switch the feature on or off, please note that switching off a feature only hides it for you."
      }
    </Typography>
  </Stack>
);

const Content = ({ feature, onChange }) => {
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: "0",
          paddingBottom: "0.25rem",
          background: "white",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant={"subtitle1"}>{feature.name}</Typography>
          <Switch
            color={"info"}
            checked={feature.enabled}
            onChange={onChange}
          />
        </Stack>
        {!feature.enabled && feature.isSet && (
          <Box className={"fade-in"} marginBottom={0.5}>
            <Alert icon={false} severity={"info"}>
              {
                "Sorry this feature isn't working out for you, we'd love to hear your feedback on how we can make it better"
              }
            </Alert>
          </Box>
        )}
      </Box>
      <Typography sx={{ whiteSpace: "pre-line" }}>
        {feature.description}
      </Typography>
    </>
  );
};

const BetaDialog = ({ feature, open, onChange, onClose }) => {
  const [helpExpanded, setHelpExpanded] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "0.75rem" } }}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle sx={{ background: "dodgerblue", color: "white" }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {"Beta Feature"}
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent sx={{ background: "white", padding: "0 !important" }}>
        <Stack direction={"row"}>
          <Box style={{ padding: "1rem 1.25rem" }}>
            <Content feature={feature} onChange={onChange} />

            <Stack
              direction={"row"}
              alignItems={"center"}
              onClick={() => setHelpExpanded(!helpExpanded)}
              sx={{ cursor: "pointer" }}
              marginTop={3}
            >
              <Typography>{"Learn more"}</Typography>
              {helpExpanded ? (
                <CollapseIcon fontSize={"small"} />
              ) : (
                <ExpandIcon fontSize={"small"} />
              )}
            </Stack>

            <Collapse in={helpExpanded}>
              <HelpContent />
            </Collapse>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const BetaBadge = ({ flag, name, description, variant }) => {
  const {
    isLoading,
    isBetaFlaggingEnabled,
    isFeatureFlagOn,
    isBetaFlagSet,
    isBetaFlagOn,
    setBetaFlag,
  } = featureFlagHooks.useFeatureFlag(flag);

  const [dialogOpen, setDialogOpen] = useState(false);

  const compact = (variant ?? "").trim().toLowerCase() === "compact";
  const enabled = isBetaFlagOn;
  const tooltip = enabled
    ? `You're using the beta version of ${name}, click to learn more`
    : `Click to try out the new beta version of ${name}`;

  const updateFeatureStatus = () => {
    setBetaFlag(!isBetaFlagOn);
  };

  if (isLoading || !isFeatureFlagOn || !isBetaFlaggingEnabled) {
    return <></>;
  }

  return (
    <>
      <Tooltip title={tooltip} arrow>
        <Chip
          size={"small"}
          label={compact ? "" : "BETA"}
          icon={
            <BetaIcon
              sx={{ transform: compact && enabled ? "translateX(8px)" : null }}
            />
          }
          color={enabled ? "info" : "default"}
          onClick={() => setDialogOpen(true)}
          clickable
        />
      </Tooltip>

      <BetaDialog
        feature={{ name, description, enabled, isSet: isBetaFlagSet }}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onChange={() => updateFeatureStatus()}
      />
    </>
  );
};

export default BetaBadge;
