import { numberToAlphabetical } from "../../../common/numbers";

export const genErrorListByInputColumn = (
  conversionErrors,
  headings,
  origin
) => {
  if (!conversionErrors?.errors) return null;
  const warningMap = conversionErrors?.warnings
    ? conversionErrors.warnings.reduce((acc, entry) => {
        const curCol = acc[entry.column] || [];
        return {
          ...acc,
          [entry.column]: [...curCol, entry],
        };
      }, {})
    : {};

  const errorMap = conversionErrors.errors.reduce((acc, entry) => {
    const curCol = acc[entry.column] || [];
    return {
      ...acc,
      [entry.column]: [...curCol, entry],
    };
  }, {});
  return Object.entries(errorMap)
    .map(([key, e]) => {
      const col = Number(key);
      return {
        entries: e,
        column: col,
        warnings: warningMap[col],
        inputColumnName: (headings?.header || [])[col] || col,
        colChar: numberToAlphabetical(col + origin.column),
      };
    })
    .sort((a, b) => a.column - b.column);
};

export const mergeCells = (errorList, origin) => {
  if (!errorList) return null;

  const append = (a, b) => {
    if (a.includes(b)) return a;
    return [...a, b];
  };
  const merge = (entries) => {
    const mapByRow = entries.reduce((acc, e) => {
      const cur = acc[e.row];
      if (!cur)
        return {
          ...acc,
          [e.row]: {
            ...e,
            rowNumber: e.row + 1 + origin.row,
            outputColumn: [e.outputColumn],
            errorCode: [e.errorCode],
            error: [e.error],
          },
        };
      const updated = {
        ...cur,
        outputColumn: append(cur.outputColumn, e.outputColumn),
        errorCode: append(cur.errorCode, e.errorCode),
        error: append(cur.error, e.error),
      };
      return {
        ...acc,
        [e.row]: updated,
      };
    }, {});
    return Object.entries(mapByRow)
      .map(([row, e]) => e)
      .sort((a, b) => a.row - b.row);
  };
  return errorList.map((e) => {
    return {
      ...e,
      entries: merge(e.entries),
    };
  });
};

export const getConversionTypeForColumn = (mapping, output, type) => {
  return (
    mapping
      .find((column) => column.outputName === output)
      ?.conversions?.find((conv) => conv.conversionType === type) || null
  );
};

export const deletionMappingsReducer = (exclColNumber, deletions) => (dm) => {
  const exclEntry = dm[exclColNumber];
  const exclList = exclEntry?.values;
  let add = (exclList?.length || 0) !== (deletions?.length || 0);
  if (!add && deletions?.length) {
    for (const i in deletions) {
      if (deletions[i] !== exclList[i]) {
        add = true;
        break;
      }
    }
  }
  if (add) {
    return {
      ...dm,
      [exclColNumber]: { column: exclColNumber, values: deletions },
    };
  }
  return dm;
};
