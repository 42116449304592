import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import BigLoader from "components/common/BigLoader";
import Mapper from "components/common/Mapper";
import SheetSelector from "components/common/files/SheetSelector";
import UploadValidation from "components/common/files/UploadValidation";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  useExtractTableMutation,
  useGetTableListQuery,
  useGetSubtableColumnListQuery,
} from "services/filesService";
import * as actions from "store/actions/input/exposure/premDeductActions";

const constructExtractorDef = (fileId, sheetName, topLeft, mapping) => {
  const column = (source, target) => {
    return {
      source,
      target,
      conversions: [
        { conversion_type: "TO_FLOAT" },
        { conversion_type: "NULL_LIKE_DELETE_ROW" },
      ],
    };
  };
  const extDef = {
    id: fileId,
    skipRows: topLeft.row,
    skipColumns: topLeft.column,
    sheetName,
    mapping: [],
  };
  for (const [target, source] of Object.entries(mapping)) {
    extDef.mapping.push(column(source, target));
  }
  return extDef;
};

const ColumnMapper = ({ mappingUpdated, mapping, columns }) => {
  return (
    <form className={"form"} noValidate>
      <FormControl
        margin={"normal"}
        className={"sheet-select-form"}
        variant={"standard"}
        fullWidth
      >
        <Mapper
          {...{
            mappingUpdated,
            sources: columns,
            mapping,
            suggested: null,
            isTransposed: false,
            targets: [
              { key: "_DEDUCTION", display: "Deduction" },
              { key: "_PREMIUM", display: "Premium" },
            ],
          }}
        />
      </FormControl>
    </form>
  );
};

const Content = ({
  state,
  setState,
  sheetSummaries,
  mappingUpdated,
  transformationStatus,
  transformationError,
}) => {
  const {
    status: columnReadState,
    data: columnData,
  } = useGetSubtableColumnListQuery(
    {
      fileId: state.fileId,
      sheet: sheetSummaries?.[state.activeSheet],
      skipRows: state.topLeft.row,
      skipColumns: state.topLeft.column,
    },
    { skip: state.step !== 1 }
  );

  switch (state.step) {
    case 1:
      if (columnReadState === "rejected")
        return <Alert severity={"error"}>{"Not a valid file"}</Alert>;
      if (columnReadState !== "fulfilled") return <BigLoader />;
      return (
        <ColumnMapper
          {...{
            mappingUpdated,
            mapping: state.columnMapping,
            columns: columnData,
          }}
        />
      );
    case 2:
    case 3:
      return (
        <UploadValidation
          transformStatus={transformationStatus}
          transformError={transformationError}
        />
      );
    default:
      return (
        <SheetSelector
          {...{
            name: "Premium Profile",
            sheetSummaries,
            activeSheet: state.activeSheet,
            topLeft: state.topLeft,
            sheetTopLeftSelected: (tl) => setState({ ...state, topLeft: tl }),
            setActiveSheet: (acs) => setState({ ...state, activeSheet: acs }),
          }}
        />
      );
  }
};

const PremDeductFileUploadDialog = (props) => {
  const nullState = {
    fileId: 0,
    step: 0,
    activeSheet: 0,
    topLeft: { row: 0, column: 0 },
    columnMapping: {},
  };
  const [state, setState] = useState(nullState);

  if (props.fileId !== state.fileId)
    setState({ ...nullState, fileId: props.fileId });

  const {
    data: sheetSummaries,
    status: sheetSummariesStatus,
  } = useGetTableListQuery(state.fileId, { skip: !state.fileId });

  const [
    startTransformation,
    { status: transformationStatus },
  ] = useExtractTableMutation();

  const anyStatus = props.error
    ? "rejected"
    : state.step === 0
    ? null
    : state.step === 1
    ? sheetSummariesStatus
    : transformationStatus;
  const anyError =
    anyStatus !== "rejected"
      ? null
      : props.error
      ? props.error
      : state.step === 1
      ? sheetSummariesStatus === "rejected"
        ? "Cannot read the file"
        : null
      : state.step >= 2
      ? transformationStatus === "rejected"
        ? "Cannot convert the file as specified"
        : null
      : null;
  const steps = [
    {
      name: "Select sheet",
      nextDisable: () => {
        return sheetSummariesStatus !== "fulfilled" || anyError;
      },
      prevDisable: () => true,
      prev: 0,
      next: 1,
    },
    {
      name: "Map Columns",
      nextDisable: () =>
        !state.columnMapping["_DEDUCTION"] ||
        !state.columnMapping["_PREMIUM"] ||
        anyError,
      prevDisable: () => !!anyError,
      prev: 0,
      next: 2,
    },
    {
      name: "Validate",
      nextDisable: () => true,
      prevDisable: () => true,
      prev: 1,
      next: 3,
    },
    {
      name: "Completed",
      nextDisable: () => false,
      prevDisable: () => true,
      prev: 2,
      next: 3,
    },
  ];

  const curStep = () => steps[state.step];
  const prevStep = () => setState({ ...state, step: curStep().prev });

  const mappingUpdated = (source, target, selected) => {
    const columnMapping = {
      ...state.columnMapping,
    };

    if (selected) columnMapping[target] = source;
    else columnMapping[target] = null;
    setState({ ...state, columnMapping });
  };

  const end = () => {
    setState({ ...state, step: 3 });
    setTimeout(props.uploadComplete, 2000);
  };
  const nextStep = () => {
    const next = curStep().next;
    setState({ ...state, step: next });
    if (next === 2) {
      startTransformation(
        constructExtractorDef(
          state.fileId,
          sheetSummaries?.[state.activeSheet]?.name,
          state.topLeft,
          state.columnMapping
        )
      ).then((dataWrapper) => {
        if (dataWrapper.data) {
          props.transformComplete(dataWrapper.data.id);
          end();
        }
      });
    }
  };

  return (
    <Dialog
      open={props.open}
      aria-labelledby={"max-width-dialog-title"}
      maxWidth={"lg"}
      fullWidth
      className={"claims-file-upload-dialog"}
      onClose={(e, b) => {
        if (state.step === 3 || b !== "backdropClick") {
          props.uploadComplete();
        }
      }}
    >
      <DialogTitle id={"max-width-dialog-title"}>
        {"Premium Deductions"}
      </DialogTitle>
      <DialogContent>
        {anyError ? (
          <Alert severity={"error"}>{anyError}</Alert>
        ) : sheetSummariesStatus === "rejected" ? (
          <Alert severity={"error"}>
            {"Cannot extract data from this file."}
          </Alert>
        ) : sheetSummariesStatus !== "fulfilled" || !props.fileId ? (
          <BigLoader />
        ) : (
          <>
            <Stepper activeStep={state.step}>
              {steps.map((stepEntry) => {
                return (
                  <Step key={stepEntry.name}>
                    <StepLabel>{stepEntry.name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Content
              {...{
                state,
                setState,
                sheetSummaries,
                mappingUpdated,
                transformationStatus: anyStatus,
                transformationError: anyError,
              }}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.uploadComplete}
          disableElevation
          variant={"contained"}
          className={"button-muted"}
          color={"secondary"}
        >
          {"Cancel"}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={prevStep}
          disableElevation
          variant={"contained"}
          color={"primary"}
          disabled={curStep().prevDisable()}
        >
          {"Prev"}
        </Button>
        <Button
          onClick={state.step === 3 ? props.uploadComplete : nextStep}
          disableElevation
          variant={"contained"}
          color={"primary"}
          disabled={curStep().nextDisable()}
        >
          {state.step === 3 ? "CLOSE" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapDispatchToProps = {
  transformComplete: actions.transformCompleted,
};
export default connect(null, mapDispatchToProps)(PremDeductFileUploadDialog);
