import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import Component from "components/Component";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useDispatch, useSelector } from "react-redux";
import * as towerPricingActions from "store/actions/pricing/tower/towerPricingActions";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";

const TowerTable = ({ layers }) => {
  const dispatch = useDispatch();

  return (
    <TableContainer>
      <Table size={"small"} className={"condensed-table"}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 60 }}>{/* Delete Layer */}</TableCell>
            <TableCell sx={{ width: 200 }}>{"Limit"}</TableCell>
            <TableCell sx={{ width: 200 }}>{"Attachment"}</TableCell>
            <TableCell sx={{ width: 250 }}>{"TargetPremium"}</TableCell>
            <TableCell sx={{ width: 250 }}>{"Notes"}</TableCell>
            <TableCell>{/* Fill rest of space */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(layers ?? []).map((layer, layerIndex) => (
            <TableRow key={layerIndex}>
              <TableCell align={"left"} sx={{ verticalAlign: "top" }}>
                <Stack
                  direction={"row"}
                  justifyContent={"left"}
                  alignItems={"center"}
                  spacing={1}
                >
                  <Disabled ifReadOnly>
                    <IconButton
                      icon={DeleteIcon}
                      onClick={() => {
                        dispatch(towerPricingActions.deleteLayer(layerIndex));
                      }}
                    />
                  </Disabled>
                  <Disabled ifReadOnly>
                    <IconButton
                      icon={AddIcon}
                      onClick={() => {
                        dispatch(towerPricingActions.addLayer(layerIndex));
                      }}
                    />
                  </Disabled>
                </Stack>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <PrettyNumberTextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(limit) => {
                      dispatch(
                        towerPricingActions.updateLayer(layerIndex, { limit })
                      );
                    }}
                    value={layer?.limit ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <PrettyNumberTextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(attachment) => {
                      dispatch(
                        towerPricingActions.updateLayer(layerIndex, {
                          attachment,
                        })
                      );
                    }}
                    value={layer?.attachment ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <PrettyNumberTextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(grossPremium) => {
                      dispatch(
                        towerPricingActions.updateLayer(layerIndex, {
                          grossPremium,
                        })
                      );
                    }}
                    value={layer?.grossPremium ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell align={"right"} sx={{ verticalAlign: "top" }}>
                <Disabled ifReadOnly>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant={"filled"}
                    size={"small"}
                    hiddenLabel
                    onChangeNumber={(comments) => {
                      dispatch(
                        towerPricingActions.updateLayer(layerIndex, {
                          comments,
                        })
                      );
                    }}
                    value={layer?.comments ?? null}
                    fullWidth
                  />
                </Disabled>
              </TableCell>
              <TableCell>{/* Fill rest of space */}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Tower = () => {
  const tower = useSelector(towerPricingSelectors.selectResolvedTower);
  const layers = tower?.layers ?? [];

  const dispatch = useDispatch();

  return (
    <Component title={"Tower"}>
      <Stack direction={"column"} spacing={2}>
        <TowerTable layers={layers} />
        <Stack direction={"row"}>
          <Disabled ifReadOnly>
            <Button
              onClick={() => {
                dispatch(towerPricingActions.addLayer(layers?.length ?? 0));
              }}
            >
              {"Add Layer"}
            </Button>
          </Disabled>
        </Stack>
      </Stack>
    </Component>
  );
};

export default Tower;
