export const UPDATE_TOWER_CARRIER = "UPDATE_TOWER_CARRIER";
export const UPDATE_TOWER_LIMIT = "UPDATE_TOWER_LIMIT";
export const UPDATE_TOWER_ATTACHMENT = "UPDATE_TOWER_ATTACHMENT";
export const UPDATE_TOWER_SELECTED = "UPDATE_TOWER_SELECTED";
export const UPDATE_TOWER_MARKET_GROSS_WRITTEN_PREMIUM =
  "UPDATE_TOWER_MARKET_GROSS_WRITTEN_PREMIUM";
export const ADD_TOWER_LAYER = "ADD_TOWER_LAYER";
export const DELETE_TOWER_LAYER = "DELETE_TOWER_LAYER";
export const UPDATE_TOWER_LAYER = "UPDATE_TOWER_LAYER";
export const UPDATE_TOWER_PRICING_STARTED = "UPDATE_TOWER_PRICING_STARTED";
export const UPDATE_TOWER_PRICING_COMPLETED = "UPDATE_TOWER_PRICING_COMPLETED";
export const UPDATE_TOWER_PRICING_FAILED = "UPDATE_TOWER_PRICING_FAILED";
export const UPDATE_TOWER_PRICING_NOTE = "UPDATE_TOWER_PRICING_NOTE";
export const UPDATE_TOWER_SHARE = "UPDATE_TOWER_SHARE";
export const DELETE_TOWER_SHARE = "DELETE_TOWER_SHARE";
export const UPDATE_TOWER_PRIMARIES_LIMIT = "UPDATE_TOWER_PRIMARIES_LIMIT";
export const UPDATE_TOWER_PRIMARIES_ATTACHMENT =
  "UPDATE_TOWER_PRIMARIES_ATTACHMENT";
export const UPDATE_TOWER_PRIMARIES_COMMENT = "UPDATE_TOWER_PRIMARIES_COMMENT";
export const ADD_TOWER_PRIMARIES_LAYER = "ADD_TOWER_PRIMARIES_LAYER";
export const DELETE_TOWER_PRIMARIES_LAYER = "DELETE_TOWER_PRIMARIES_LAYER";
export const COPY_FROM_EXPIRING = "COPY_FROM_EXPIRING";
