import {
  AutoFixHigh as GenerateIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import DefaultLoader from "components/common/DefaultLoader";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import * as config from "config";
import { useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as submissionsService from "services/submissionsService";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";
import * as submissionSelector from "store/selectors/submission/submissionSelector";

const useGeneratePolicyNumber = ({
  policyNumberConfig,
  onChange,
  layerId = undefined,
}) => {
  const [
    trigger,
    { isLoading, isError, error },
  ] = submissionsService.useGenerateNextPolicyNumberMutation();

  const submission = useSelector(
    submissionSelector.selectResolvedSubmissionWithoutInput
  );
  const priorSubmission = useSelector((state) =>
    submissionSelector.selectResolvedSubmissionWithoutInput(
      stateSelectors.selectPriorState(state)
    )
  );
  const activeLayerId = useSelector(pricingSelectors.selectResolvedActiveLayer)
    ?.id;

  const generatePolicyNumber = async () => {
    const { data } = await trigger({
      submission,
      priorSubmission,
      layerId: layerId ?? activeLayerId,
      config: policyNumberConfig,
    });
    if (data?.policyNumber) {
      onChange(data.policyNumber);
    }
  };

  return {
    generatePolicyNumber,
    isGenerating: isLoading,
    error: isError
      ? error?.name === "POLICY_NUMBER_ERROR" && error?.description
        ? error.description
        : `Unknown error. Please contact ${config.SUPPORT_EMAIL}.`
      : null,
  };
};

const PolicyNumberField = ({
  value,
  disabled,
  generateLocked,
  onChange,
  label,
  type,
  layerId = undefined,
  ...props
}) => {
  const {
    data: rawPolicyNumberConfig,
    isLoading: isConfigLoading,
  } = staticDataService.useConfigQuery("policy_numbers");

  const policyNumberConfig = {
    ...rawPolicyNumberConfig,
    generatorName: type
      ? rawPolicyNumberConfig?.generatorNameByType?.[type]
      : rawPolicyNumberConfig?.generatorName,
  };

  const {
    generatePolicyNumber,
    isGenerating,
    error,
  } = useGeneratePolicyNumber({ policyNumberConfig, onChange, layerId });

  const deletePolicyNumber = () => {
    onChange(null);
  };

  return (
    <Disabled ifReadOnly>
      <TextField
        {...props}
        type={"text"}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: !!value ? (
            <InputAdornment position={"end"}>
              <Disabled ifReadOnly>
                <IconButton
                  icon={ClearIcon}
                  edge={"end"}
                  onClick={deletePolicyNumber}
                  disabled={disabled}
                />
              </Disabled>
            </InputAdornment>
          ) : isConfigLoading ? (
            <></>
          ) : isGenerating ? (
            <DefaultLoader color={"#000000"} />
          ) : (
            <InputAdornment position={"end"}>
              <Disabled ifReadOnly>
                <IconButton
                  icon={GenerateIcon}
                  edge={"end"}
                  onClick={generatePolicyNumber}
                  disabled={disabled || generateLocked}
                />
              </Disabled>
            </InputAdornment>
          ),
        }}
        label={label ?? (props.hiddenLabel ? null : "Reference")}
        variant={"filled"}
        onChange={(e) => {
          let value = e.target.value;
          if (!value || value === "") {
            value = null;
          }
          onChange(value);
        }}
        value={value ?? ""}
        disabled={disabled}
        error={!!error}
        helperText={error}
        fullWidth
      />
    </Disabled>
  );
};

export default PolicyNumberField;
