import { downloadState } from "../../common/errors";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as config from "config";
import React from "react";
import { connect } from "react-redux";

const GlobalError = ({ state }) => {
  return (
    <Dialog
      open={true}
      aria-labelledby={"error-dialog-title"}
      maxWidth={"lg"}
      fullWidth
      scroll={"paper"}
      className={"error-dialog"}
    >
      <DialogTitle id={"error-dialog-title"}>
        {`Please contact ${config.SUPPORT_EMAIL}.`}
      </DialogTitle>
      <DialogContent dividers={true}>
        {
          "Unfortunately an unexpected error has occurred, if you press the 'Download State' button below and email the file it creates to "
        }
        <a href={`mailto:${config.SUPPORT_EMAIL}`}>{config.SUPPORT_EMAIL}</a>
        {", they will be able to ensure you do not lose any unsaved work."}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <span className={"spacer"} style={{ flex: "1 1 auto" }}></span>
        <Button
          onClick={() => downloadState(state)}
          disableElevation
          variant={"contained"}
          color={"secondary"}
        >
          {"Download State"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(GlobalError);
