import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Disabled from "components/common/Disabled";
import Visible from "components/common/Visible";
import React from "react";
import { connect } from "react-redux";
import { setInflationModel } from "store/actions/input/claims/claimsActions";

export const InflationModelSelectorComponent = ({
  selectedInflationModel,
  setInflationModel,
  defaultInflationModel,
  inflationModelList,
}) => {
  const value =
    inflationModelList.length === 0
      ? ""
      : selectedInflationModel ?? defaultInflationModel ?? "";
  return (
    <Visible byTag={"input.claims.inflationModelSelector"}>
      <Disabled ifReadOnly>
        <FormControl variant={"filled"} style={{ minWidth: "200px" }}>
          <InputLabel>{"Inflation Model"}</InputLabel>
          <Select
            value={value}
            onChange={(e) => setInflationModel(e.target.value)}
          >
            {inflationModelList.map((model) => (
              <MenuItem key={model.key} value={model.key}>
                {model.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Disabled>
    </Visible>
  );
};

export const mapStateToProps = (state) => {
  return {
    selectedInflationModel: state.input.claims.inflationModel,
    defaultInflationModel:
      state.temp.staticData.inflationModelConfig?.default ?? "",
    inflationModelList:
      state.temp.staticData.inflationModelConfig?.orderedModelList ?? [],
  };
};

const mapDispatchToProps = {
  setInflationModel: setInflationModel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InflationModelSelectorComponent);
