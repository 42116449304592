import { Error as ErrorIcon } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import * as numbers from "common/numbers";
import AutoCompleteTextField from "components/common/AutoCompleteTextField";
import ConditionalTooltip from "components/common/ConditionalTooltip";
import FeatureFlagged from "components/common/FeatureFlagged";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import Visible from "components/common/Visible";
import { DYNAMIC_AUTO_COMPLETE } from "flags";
import * as _ from "lodash";
import { memo } from "react";

const ShareTableRow = ({
  share,
  layerIndex,
  shareIndex,
  errors,
  updateShare,
  deleteShare,
  autoFocus,
}) => {
  const carrierChanged = (value) =>
    updateShare({
      layerIndex,
      shareIndex,
      values: {
        carrier: value,
      },
    });
  return (
    <TableRow>
      <TableCell>
        {!!deleteShare && (
          <IconButton
            icon={DeleteIcon}
            onClick={() => deleteShare({ layerIndex, shareIndex })}
          />
        )}
      </TableCell>
      <TableCell>
        {" "}
        <FeatureFlagged
          on={AutoCompleteTextField}
          off={TextField}
          flag={DYNAMIC_AUTO_COMPLETE}
          category={"carriers"}
          value={share.carrier ?? ""}
          onChange={(e) => carrierChanged(e.target.value)}
          onChangeValue={carrierChanged}
          variant={"filled"}
          autoComplete={"off"}
          size={"small"}
          fullWidth
          hiddenLabel
          autoFocus={autoFocus}
        />
      </TableCell>
      <Visible
        byTag={"pricing.towerPricing.share.shareOfLimit"}
        defaultVisible={true}
      >
        <TableCell>
          <PrettyNumberTextField
            value={share.shareOfLimit ?? ""}
            stringValue={String(share.shareOfLimit ?? "")}
            onChangeNumber={(value) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: { shareOfLimit: value },
              })
            }
            inputProps={{ style: { textAlign: "right" } }}
            variant={"filled"}
            size={"small"}
            hiddenLabel
            fullWidth
          />
        </TableCell>
      </Visible>
      <Visible byTag={"pricing.towerPricing.share.share"} defaultVisible={true}>
        <TableCell>
          <ConditionalTooltip conditional={errors?.share} title={errors?.share}>
            <PrettyNumberTextField
              value={share.share ?? ""}
              stringValue={String(share.share ?? "")}
              onChangeNumber={(value) =>
                updateShare({
                  layerIndex,
                  shareIndex,
                  values: { share: value },
                })
              }
              inputProps={{ style: { textAlign: "right" } }}
              variant={"filled"}
              size={"small"}
              error={errors?.share}
              hiddenLabel
              fullWidth
            />
          </ConditionalTooltip>
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.shareOfGwp"}
        defaultVisible={true}
      >
        <TableCell>
          <PrettyNumberTextField
            value={share.shareOfGrossPremium}
            stringValue={String(share.shareOfGrossPremium ?? "")}
            onChangeNumber={(value) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: { shareOfGrossPremium: value },
              })
            }
            inputProps={{ style: { textAlign: "right" } }}
            variant={"filled"}
            size={"small"}
            hiddenLabel
            fullWidth
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.brokerage"}
        defaultVisible={true}
      >
        <TableCell>
          <PrettyNumberTextField
            value={share.brokerage}
            stringValue={String(share.brokerage ?? "")}
            onChangeNumber={(value) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: { brokerage: value },
              })
            }
            inputProps={{ style: { textAlign: "right" } }}
            variant={"filled"}
            size={"small"}
            hiddenLabel
            fullWidth
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.shareOfNwp"}
        defaultVisible={true}
      >
        <TableCell>
          <PrettyNumberTextField
            value={share.shareOfNetPremium}
            inputProps={{ style: { textAlign: "right" } }}
            variant={"filled"}
            size={"small"}
            hiddenLabel
            fullWidth
            disabled
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.comments"}
        defaultVisible={true}
      >
        <TableCell>
          <TextField
            value={share.comments ?? ""}
            onChange={(e) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: {
                  comments: e.target.value,
                },
              })
            }
            variant={"filled"}
            autoComplete={"off"}
            size={"small"}
            fullWidth
            hiddenLabel
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.reference"}
        defaultVisible={true}
      >
        <TableCell>
          <TextField
            value={share.reference ?? ""}
            onChange={(e) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: {
                  reference: e.target.value,
                },
              })
            }
            variant={"filled"}
            autoComplete={"off"}
            size={"small"}
            fullWidth
            hiddenLabel
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.coverage"}
        defaultVisible={false}
      >
        <TableCell>
          <TextField
            value={share.coverage ?? ""}
            onChange={(e) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: {
                  coverage: e.target.value,
                },
              })
            }
            variant={"filled"}
            autoComplete={"off"}
            size={"small"}
            fullWidth
            hiddenLabel
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.policyForm"}
        defaultVisible={false}
      >
        <TableCell>
          <TextField
            value={share.policyForm ?? ""}
            onChange={(e) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: {
                  policyForm: e.target.value,
                },
              })
            }
            variant={"filled"}
            autoComplete={"off"}
            size={"small"}
            fullWidth
            hiddenLabel
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.aggregateLimit"}
        defaultVisible={false}
      >
        <TableCell>
          <PrettyNumberTextField
            value={share.shareOfAggregateLimit}
            onChangeNumber={(shareOfAggregateLimit) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: { shareOfAggregateLimit },
              })
            }
            inputProps={{ style: { textAlign: "right" } }}
            variant={"filled"}
            size={"small"}
            hiddenLabel
            fullWidth
          />
        </TableCell>
      </Visible>
      <Visible
        byTag={"pricing.towerPricing.share.plAggregateLimit"}
        defaultVisible={false}
      >
        <TableCell>
          <PrettyNumberTextField
            value={share.shareOfPLAggregateLimit}
            onChangeNumber={(shareOfPLAggregateLimit) =>
              updateShare({
                layerIndex,
                shareIndex,
                values: { shareOfPLAggregateLimit },
              })
            }
            inputProps={{ style: { textAlign: "right" } }}
            variant={"filled"}
            size={"small"}
            hiddenLabel
            fullWidth
          />
        </TableCell>
      </Visible>
    </TableRow>
  );
};

const MemoizedShareTableRow = memo(ShareTableRow, _.isEqual);

const ShareTable = ({ layer, layerIndex, updateShare, deleteShare }) => {
  const shares = layer?.shares ?? [];
  const errors = {
    shareOfLimit:
      !layer?.limit ||
      numbers.approxEqual(
        (shares ?? [])
          .map((s) => s.shareOfLimit || 0)
          .reduce((a, b) => a + b, 0),
        layer?.limit,
        { epsilon: 5_000 }
      )
        ? null
        : "Shares of limit do not sum to the total",
  };
  return (
    <Table size={"small"}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: 60 }}></TableCell>
          <TableCell sx={{ width: 350 }} align={"left"}>
            {"Carrier"}
          </TableCell>
          <Visible
            byTag={"pricing.towerPricing.share.shareOfLimit"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 300 }} align={"right"}>
              {"Share of Limit"}
              {errors.shareOfLimit && (
                <Tooltip title={errors.shareOfLimit}>
                  <ErrorIcon
                    color={"error"}
                    sx={{ fontSize: "1rem", marginLeft: 0.5 }}
                  />
                </Tooltip>
              )}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.share"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 100 }} align={"right"}>
              {"% Share"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.shareOfGwp"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 300 }} align={"right"}>
              {"Share of GWP"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.brokerage"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 100 }} align={"right"}>
              {"% Brokerage"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.shareOfNwp"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 300 }} align={"right"}>
              {"Share of NWP"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.comments"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 300 }} align={"left"}>
              {"Comments"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.reference"}
            defaultVisible={true}
          >
            <TableCell sx={{ width: 200 }} align={"left"}>
              {"Reference"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.coverage"}
            defaultVisible={false}
          >
            <TableCell sx={{ width: 200 }} align={"left"}>
              {"Coverage"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.policyForm"}
            defaultVisible={false}
          >
            <TableCell sx={{ width: 200 }} align={"left"}>
              {"Policy Form"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.aggregateLimit"}
            defaultVisible={false}
          >
            <TableCell sx={{ width: 300 }} align={"left"}>
              {"Agg Limit"}
            </TableCell>
          </Visible>
          <Visible
            byTag={"pricing.towerPricing.share.plAggregateLimit"}
            defaultVisible={false}
          >
            <TableCell sx={{ width: 300 }} align={"left"}>
              {"PL Agg Limit"}
            </TableCell>
          </Visible>
        </TableRow>
      </TableHead>
      <TableBody>
        {(shares ?? []).concat([{}]).map((share, shareIndex) => (
          <MemoizedShareTableRow
            key={shareIndex}
            share={share}
            layerIndex={layerIndex}
            shareIndex={shareIndex}
            errors={errors}
            updateShare={updateShare}
            deleteShare={
              shareIndex === (shares ?? []).length ? null : deleteShare
            }
            autoFocus={shareIndex === (shares ?? []).length - 1}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(ShareTable, _.isEqual);
