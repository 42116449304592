import AttachFileIcon from "@mui/icons-material/AttachFile";
import Grid from "@mui/material/Grid";
import Component from "components/Component";
import FilesFileUpload from "components/FilesFileUpload";
import IconButton from "components/common/IconButton";
import FileListTree from "components/inputs/attachments/FileListTree";
import { PreviewButton as FileListTreePreviewButton } from "components/inputs/attachments/FileListTreeItem";
import * as config from "config";
import { connect } from "react-redux";
import * as attachmentActions from "store/actions/input/attachments/attachmentsActions";

export const insertAttachment = (selectAttachment) => ({
  name: "insertAttachment",
  keyCommand: "insertAttachment",
  buttonProps: {
    "aria-label": "Insert an attachment",
    title: "Insert an attachment",
  },
  icon: <AttachFileIcon fontSize={"inherit"} />,
  execute: (state, api) => {
    selectAttachment();
  },
});

const SelectFileButton = ({ onSelected, fileMeta }) => (
  <IconButton
    icon={AttachFileIcon}
    onClick={() => {
      onSelected(fileMeta);
    }}
  />
);

const NotesFileSelector = ({ fileUploaded, onSelected }) => {
  const onDrop = (fileId) => {
    fileUploaded(fileId);
  };

  return (
    <Grid
      container
      item
      justifyContent={"space-between"}
      spacing={config.GRID_SPACING}
    >
      <Grid item xs={12} lg={3} minWidth={200}>
        <FilesFileUpload onDrop={onDrop} asAtDateNotRequired={true} />
      </Grid>
      <Grid item xs={12} lg={9}>
        <Component title={"File Selector"}>
          <FileListTree
            createFileButtons={({ support }) => [
              <SelectFileButton
                onSelected={onSelected}
                fileMeta={support.fileMeta}
                key={"select"}
              />,
              <FileListTreePreviewButton support={support} key={"preview"} />,
            ]}
            createDirButtons={() => []}
          />
        </Component>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  fileUploaded: attachmentActions.fileUploaded,
};

export default connect(null, mapDispatchToProps)(NotesFileSelector);
