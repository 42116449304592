import { createSelector } from "reselect";

const getErrorMessages = (state) => {
  return state?.temp?.error?.messages || [];
};

const getErrorMessagesAreHidden = (state) => {
  return state?.temp?.error?.hidden ?? true;
};

export const errorDialogIsOpen = createSelector(
  [getErrorMessages, getErrorMessagesAreHidden],
  (messages, hidden) => {
    return messages.length > 0 && !hidden;
  }
);
