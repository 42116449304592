import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as rateChangeResolvers from "domain/rateChangeResolvers";
import * as _ from "lodash";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const initialState = { byLayer: {}, activeLayerId: null };

const defaultLayerState = {
  inflation: 5,
};
const dontCopyLayerStates = [
  "note",
  "selectedPriorLayerId",
  "queryData",
  "chartData",
  "pureRateChangeOverride",
];

const rateChangeSlice = createSlice({
  name: "rateChange",
  initialState,
  reducers: {
    updateRateChangeState: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      if (
        values.activeLayerId &&
        values.activeLayerId !== state.activeLayerId &&
        !state.byLayer[values.activeLayerId]
      ) {
        state.byLayer[values.activeLayerId] = {
          ...defaultLayerState,
          ..._.pickBy(
            state.byLayer?.[state.activeLayerId] ?? {},
            (_, key) => !dontCopyLayerStates.includes(key)
          ),
        };
      }
      for (const key in values) {
        if (!["byLayer"].includes(key)) {
          state[key] = values[key];
        }
      }
    },
    updateLayerProperties: (state, action) => {
      const { layerId, field, value, ...values } = {
        layerId: state?.activeLayerId ?? null,
        ...action?.payload,
      };

      if (typeof layerId !== "string") {
        return;
      }

      if (field) {
        if (state.byLayer[layerId]) {
          state.byLayer[layerId][field] = value;
        }
      } else if (typeof values === "object" && Object.keys(values).length) {
        state.byLayer[layerId] = {
          ...state.byLayer[layerId],
          ...values,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => action?.payload?.data?.state?.rateChange ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, (_, action) => {
        return {
          ...initialState,
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.rateChange,
        };
      })
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (_, action) => {
        return {
          ...initialState,
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.rateChange,
        };
      });
  },
});

export const selectRateChangeInput = (state) => state?.rateChange ?? null;

export const selectRateChange = createSelector(
  [selectRateChangeInput],
  (rateChangeInput) => rateChangeResolvers.resolveRateChange(rateChangeInput)
);

export const selectActiveLayerRateChange = createSelector(
  [selectRateChange],
  (rateChange) => ({
    layerId: rateChange?.activeLayerId,
    ...rateChange?.byLayer?.[rateChange?.activeLayerId],
  })
);

export const {
  updateRateChangeState,
  updateLayerProperties,
} = rateChangeSlice.actions;

export const selectLayerItem = (state, layerId, item) =>
  state.rateChange.byLayer[layerId]?.[item];

export const updateRateChangeItem = (item) => (value) =>
  updateRateChangeState({ [item]: value });

export const updateLayerPropertiesItem = (item) => (value) =>
  updateLayerProperties({ [item]: value });

export default rateChangeSlice;
