/* eslint-disable */
function Step(context) {
  this._context = context;
}

Step.prototype = {
  areaStart: function () {
    this._line = 0;
  },
  areaEnd: function () {
    this._line = NaN;
  },
  lineStart: function () {
    this._x = this._y = NaN;
    this._x2 = this._y2 = NaN;
    this._point = 0;
  },
  lineEnd: function () {
    const x = (this._x - this._x2) / 2 + this._x;
    this._context.moveTo(this._x, this._y);
    this._context.lineTo(x, this._y);
  },
  point: function (x, y) {
    (x = +x), (y = +y);
    switch (this._point) {
      case 0:
        this._point = 1;
        this._line ? this._context.lineTo(x, y) : this._context.moveTo(x, y);
        break;
      case 1:
        const start = (this._x - x) / 2 + this._x;
        this._context.moveTo(start, this._y);
        this._context.lineTo(this._x, this._y);
        this._point = 2; // falls through
      default: {
        var xM2 = isNaN(this._x2) ? NaN : (this._x2 + this._x) * 0.5;
        var xM = (this._x + x) * 0.5;
        var x1 = x;
        this._context.moveTo(this._x, this._y);
        this._context.lineTo(x1, this._y);
        this._context.moveTo(xM, y);
        this._context.lineTo(x, y);
        if (!isNaN(xM2)) {
          this._context.moveTo(xM2, this._y2);
          this._context.lineTo(xM, this._y2);
        }
        break;
      }
    }
    (this._x2 = this._x), (this._y2 = this._y);
    (this._x = x), (this._y = y);
  },
};

export default function (context) {
  return new Step(context);
}
