import { calculateTickIndex, useContainerDimensions } from "../../../utils";
import PrettyNumberTextField from "../../common/PrettyNumberTextField";
import { getMarks } from "./ClaimsSliderSupport";
import { abbreviated } from "../../../common/numbers";
import { Slider } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";

const DEFAULT_MAX = 1000000000;

const ClaimsSlider = ({ value, onChangeNumber, min, max, title, disabled }) => {
  const sliderRef = useRef();
  const { width } = useContainerDimensions(sliderRef);
  const [isDragging, setIsDragging] = useState(false);
  const [tempValue, setTempValue] = useState(null);
  const [cachedMarksData, setCachedMarksData] = useState(null);

  useEffect(() => {
    if (max !== DEFAULT_MAX) {
      const newMarksData = getMarks(width, max, min);
      setCachedMarksData(newMarksData);
    }
  }, [width, max, min]);

  const { ticks, marks } = useMemo(() => {
    if (cachedMarksData && max === DEFAULT_MAX) {
      return cachedMarksData;
    }
    return getMarks(width, max, min);
  }, [width, max, min, cachedMarksData]);

  const handleDragEnd = useCallback(() => {
    if (isDragging && tempValue !== null && tempValue !== value) {
      onChangeNumber(tempValue);
    }
    setIsDragging(false);
    setTempValue(null);
  }, [isDragging, tempValue, value, onChangeNumber]);

  useEffect(() => {
    if (!isDragging) return;

    const handleMouseUp = () => {
      handleDragEnd();
    };

    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleDragEnd]);

  const handleChange = (e) => {
    const newValue = Math.max(0, min, ticks[e.target.value]);
    if (isDragging) {
      setTempValue(newValue);
    } else {
      if (newValue !== value) {
        onChangeNumber(newValue);
      }
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
    setTempValue(value);
  };

  const handleTextChange = (newValue) => {
    const boundedValue = Math.max(newValue, min);
    if (boundedValue !== value) {
      onChangeNumber(boundedValue);
    }

    setIsDragging(false);
    setTempValue(null);
  };

  const currentTickIndex = useMemo(() => {
    const currentValue = isDragging ? tempValue : value;
    return calculateTickIndex(ticks, currentValue ?? 0);
  }, [ticks, tempValue, value, isDragging]);

  const valueLabelFormat = (index) => {
    const val = ticks[index];
    return abbreviated(val);
  };

  return (
    <Grid container spacing={0} columnSpacing={3}>
      {title && (
        <Grid item xs={12}>
          <div className={"component-header"}>
            <h2 className={"component-title"}>{title}</h2>
          </div>
        </Grid>
      )}
      <Grid item xs={12} sm={8} md={10}>
        <Box ml={2} mr={2}>
          <Slider
            ref={sliderRef}
            marks={marks}
            min={0}
            max={marks.length - 1}
            onChange={handleChange}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onTouchStart={handleDragStart}
            onTouchEnd={handleDragEnd}
            value={currentTickIndex}
            disabled={disabled}
            step={1}
            disableSwap
            valueLabelDisplay={"auto"}
            valueLabelFormat={valueLabelFormat}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={4} md={2}>
        <PrettyNumberTextField
          fullWidth
          onChangeNumber={handleTextChange}
          inputProps={{ style: { textAlign: "right" } }}
          value={isDragging ? tempValue : value}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ClaimsSlider, (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.min === nextProps.min &&
    prevProps.max === nextProps.max &&
    prevProps.disabled === nextProps.disabled
  );
});
