import * as config from "../config";
import IconButton from "./common/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { fetchAuthSession } from "aws-amplify/auth";
import * as logger from "common/logger";
import Component from "components/Component";
import Dashboard from "components/layout/Dashboard";
import DashboardContent from "components/layout/DashboardContent";
import { useState, useEffect } from "react";

const forceTokenRefresh = async () => {
  try {
    const session = await fetchAuthSession({ forceRefresh: true });
    return session.tokens?.accessToken?.toString();
  } catch (error) {
    throw new Error("Could not refresh token:\n" + error);
  }
};

const TokenApp = (props) => {
  const [token, setToken] = useState("Empty");
  const [isTokenLoading, setTokenLoading] = useState(false);
  const [isTokenHidden, setTokenHidden] = useState(true);
  const copyToken = () => {
    navigator.clipboard.writeText(token);
  };
  const refreshToken = () => {
    setTokenLoading(true);
    forceTokenRefresh()
      .then((token) => {
        setToken(token);
        setTokenLoading(false);
      })
      .catch(logger.exception);
  };
  const getRedactedToken = (token) => {
    const charsVisible = 4;
    const prefix = "░".repeat(token.length - charsVisible);
    const suffix = token.substring(token.length - charsVisible, token.length);
    return prefix + suffix;
  };
  useEffect(() => {
    refreshToken();
  }, []);
  const displayedToken = isTokenLoading
    ? "Loading..."
    : isTokenHidden
    ? getRedactedToken(token)
    : token;
  return (
    <div className={"app"}>
      <Dashboard>
        <DashboardContent>
          <Component title={"Access Token"}>
            <Grid
              item
              container
              spacing={config.GRID_SPACING}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontFamily: "monospace",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {displayedToken}
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <IconButton
                  scale={"small"}
                  icon={VisibilityIcon}
                  edge={"start"}
                  onClick={() => {
                    setTokenHidden(!isTokenHidden);
                  }}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <IconButton
                  scale={"small"}
                  icon={RefreshIcon}
                  edge={"start"}
                  onClick={() => {
                    refreshToken();
                  }}
                />
              </Grid>
              <Grid item xs={"auto"}>
                <IconButton
                  scale={"small"}
                  icon={ContentCopyIcon}
                  edge={"start"}
                  onClick={() => {
                    copyToken();
                  }}
                />
              </Grid>
            </Grid>
          </Component>
        </DashboardContent>
      </Dashboard>
    </div>
  );
};

export default TokenApp;
