import Loader from "react-loader-spinner";

const DefaultLoader = ({
  type,
  color,
  style,
  height,
  width,
  size,
  ...props
}) => (
  <div data-testid={`default-loader-${size}`}>
    <Loader
      type={type || "ThreeDots"}
      color={color || "#ffffff"}
      style={{
        display: "inline-block",
        position: "relative",
        top: "2px",
        ...style,
      }}
      height={height || size || 16}
      width={width || size || 16}
      {...props}
    />
  </div>
);

export default DefaultLoader;
