import { useListTemplatesQuery } from "../../services/adminService";
import SmallLoader from "../common/SmallLoader";
import { IconClose } from "@aws-amplify/ui-react/internal";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import RawTextField from "@mui/material/TextField";
import Component from "components/Component";
import AdminDashboard from "components/admin/AdminDashboard";
import Button from "components/common/Button";
import TextField from "components/common/TextField";
import DashboardContent from "components/layout/DashboardContent";
import * as config from "config";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { connect } from "react-redux";
import * as adminActions from "store/actions/temp/admin/adminActions";
import * as adminSelectors from "store/selectors/temp/admin/adminSelectors";
import * as yup from "yup";

const NO_SELECTION_KEY = "__NO_SELECTION__";

const NewCompany = ({
  createCompany,
  createCompanyState,
  saveUserLogMessage,
  userLogMessage,
  hasUserLogMessage,
}) => {
  const running = createCompanyState.status === "running";
  const failed = createCompanyState.status === "failed";
  const errorMessage = failed ? createCompanyState.errorMessage : "";
  const { data: templates, status: templatesStatus } = useListTemplatesQuery();
  const templatesLoading = templatesStatus !== "fulfilled";

  const {
    enqueueSnackbar: queueMessage,
    closeSnackbar: closeMessage,
  } = useSnackbar();
  const addMessage = (variant, message) => {
    queueMessage(message, {
      variant,
      autoHideDuration: 30_000,
      preventDuplicate: true,
      action: (msgId) => (
        <IconButton onClick={() => closeMessage(msgId)}>
          <IconClose />
        </IconButton>
      ),
    });
  };
  const [submittedValues, setSubmittedValues] = useState({});
  const [lastStatus, setLastStatus] = useState(null);
  if (lastStatus !== createCompanyState.status) {
    setLastStatus(createCompanyState.status);

    if (createCompanyState.status === "succeeded") {
      addMessage(
        "success",
        `Company ${createCompanyState.payload?.id} ${submittedValues.users} created;`
      );
    } else if (createCompanyState.status === "failed") {
      addMessage("error", `Company creation failed: ${errorMessage}`);
    }
  }
  return (
    <AdminDashboard>
      <DashboardContent isFullWidth={false}>
        <Formik
          initialValues={{
            name: "",
            filesTemplateKey: "__NO_SELECTION__",
            users: "",
          }}
          validationSchema={yup.object({
            name: yup
              .string("The name slug of the new company.")
              .matches(
                /^[a-zA-Z][a-zA-Z0-9-]*$/,
                "Must contain only letters, numbers and hyphens but must start with a letter."
              )
              .required("Name is required"),
            filesTemplateKey: yup
              .string("The selected template.")
              .notOneOf([NO_SELECTION_KEY], "Template is required."),
            users: yup
              .array()
              .transform(function (value, originalValue) {
                if (this.isType(value) && value !== null) {
                  return value;
                }
                return originalValue ? originalValue.split(/[\s,;]+/) : [];
              })
              .of(
                yup
                  .string()
                  .email(({ value }) => `${value} is not a valid email`)
              )
              .required("At least one user is required."),
          })}
          onSubmit={(values) => {
            setSubmittedValues(values);
            createCompany({
              companyDefn: {
                ...values,
                users: values.users
                  .split(/[\s,;]+/)
                  .filter((s) => s.length > 0),
                userLogMessage,
              },
            });
          }}
        >
          {(formik) => (
            <Component title={"Create a New Company"}>
              <Grid container spacing={config.GRID_SPACING}>
                <Grid item xs={12} md={6}>
                  <TextField
                    name={"name"}
                    label={"Company Slug"}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={0} md={6} />
                <Grid item xs={12} md={6}>
                  <FormControl variant={"filled"} fullWidth>
                    <InputLabel className={"input-label"}>
                      {templatesLoading ? "" : "Select Template"}
                    </InputLabel>
                    {templatesLoading ? (
                      <SmallLoader />
                    ) : (
                      <Select
                        name={"filesTemplateKey"}
                        value={formik.values.filesTemplateKey}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.filesTemplateKey &&
                          Boolean(formik.errors.filesTemplateKey)
                        }
                      >
                        {templates.map((template) => (
                          <MenuItem value={template.key} key={template.key}>
                            {template.name}
                          </MenuItem>
                        ))}
                        <MenuItem value={NO_SELECTION_KEY} />
                      </Select>
                    )}
                    <FormHelperText
                      error={
                        formik.touched.filesTemplateKey &&
                        Boolean(formik.errors.filesTemplateKey)
                      }
                    >
                      {formik.touched.filesTemplateKey &&
                        formik.errors.filesTemplateKey}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={0} md={6} />
                <Grid item xs={12} md={6}>
                  <RawTextField
                    type={"text"}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ spellCheck: "false" }}
                    autoComplete={"off"}
                    variant={"filled"}
                    name={"users"}
                    label={"User Emails"}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.users}
                    error={formik.touched.users && Boolean(formik.errors.users)}
                    helperText={formik.touched.users && formik.errors.users}
                    multiline
                    fullWidth
                    maxRows={Infinity}
                  />
                </Grid>
                <Grid container item xs={10}>
                  <TextField
                    variant={"filled"}
                    label={"Reason for company creation"}
                    fullWidth
                    onChange={(e) => saveUserLogMessage(e.target.value)}
                    value={userLogMessage}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={2}
                  justifyContent={"right"}
                  alignItems={"center"}
                >
                  <Grid item xs={"auto"}>
                    <Button
                      onClick={formik.handleSubmit}
                      isDisabled={running || !hasUserLogMessage}
                    >
                      {running ? <SmallLoader /> : "Create"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Component>
          )}
        </Formik>
      </DashboardContent>
    </AdminDashboard>
  );
};

const mapStateToProps = (state) => {
  return {
    createCompanyState: adminSelectors.selectCreateCompanyState(state),
    userLogMessage: adminSelectors.userLogMessage(state),
    hasUserLogMessage: adminSelectors.hasUserLogMessage(state),
  };
};

const mapDispatchToProps = {
  createCompany: adminActions.createCompany.startSaga,
  saveUserLogMessage: adminActions.userLogMessage.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCompany);
