import * as stateSelectors from "../../stateSelectors";
import { createSelector } from "reselect";

const getJudgmentsRaw = (state) => state.input?.flags?.judgments || [];
const getExposuresRaw = (state) => state.input?.flags?.exposures || [];

export const getJudgments = createSelector([getJudgmentsRaw], (judgments) => {
  return [
    ...judgments.map((judgment) => ({
      name: "",
      value: "",
      comments: "",
      canDelete: true,
      ...judgment,
    })),
    {
      name: "",
      value: "",
      comments: "",
      canDelete: false,
    },
  ];
});

export const getExposures = createSelector([getExposuresRaw], (exposures) => {
  return [
    ...exposures.map((exposure) => ({
      type: "",
      category: "",
      comments: "",
      canDelete: true,
      ...exposure,
    })),
  ];
});

export const getExposuresForDisplay = createSelector(
  [getExposures],
  (exposures) => {
    return [
      ...exposures,
      {
        type: "",
        category: "",
        comments: "",
        canDelete: false,
      },
    ];
  }
);

const _filterNonEmptyJudgments = (judgments) =>
  judgments
    .filter(({ value }) => value != null && value !== "" && value !== "-")
    .map(({ name, value, comments }) => ({
      name,
      value,
      comments,
    }));

export const getNonEmptyJudgments = createSelector(
  [getJudgmentsRaw],
  _filterNonEmptyJudgments
);

export const getJudgmentFactors = createSelector(
  [getNonEmptyJudgments],
  (judgments) => judgments.map((_) => _.value)
);

export const getJudgmentFactor = createSelector(
  [getJudgmentFactors],
  (judgments) =>
    judgments.map((_) => (100 + parseFloat(_)) / 100).reduce((a, b) => a * b, 1)
);

export const getJudgmentFactorPretty = createSelector(
  [getJudgmentFactor],
  (judgmentFactor) => (judgmentFactor || 0) * 100 - 100
);

export const selectUWJudgments = stateSelectors.createSelector(
  [(state) => state],
  (state) => _filterNonEmptyJudgments(state.input?.flags?.judgments ?? [])
);

export const selectStateId = (state) => state.input?.flags?.stateId ?? null;

export const selectResolvedFlags = (state) => state.input?.flags ?? null;
