export const getAttachments = (state) => state.input?.attachments || {};

export const getAllAttachedFiles = (state) => {
  const loop = (entry, set) => {
    if (set === null || set === undefined) set = new Set();
    if (entry.subDirectories)
      entry.subDirectories.forEach((sub) => loop(sub, set));
    if (entry.attachedFiles) entry.attachedFiles.forEach((id) => set.add(id));
    return set;
  };
  return Array.from(loop(getAttachments(state)).values());
};

const findDir = (entry, directoryId) => {
  if (!entry.id && !directoryId) return entry;
  if (entry.id === directoryId) return entry;
  if (entry.subDirectories) {
    for (const i in entry.subDirectories) {
      const resp = findDir(entry.subDirectories[i], directoryId);
      if (resp) return resp;
    }
  }
  return null;
};

export const getAttachedFilesInDir = (state, directoryId) => {
  const dir = findDir(getAttachments(state), directoryId);
  if (dir) return dir.attachedFiles || [];
  return [];
};

export const getFlattenedFileNameOverrides = (state) => {
  const loop = (entry) => {
    const attachedFiles = entry.attachedFiles || {};
    const hidden = { ...(entry.attachedFilesHidden || {}) };
    attachedFiles.forEach((id) => (hidden[id] = false));
    const res = Object.entries(entry.attachedFileNameOverrides || {}).filter(
      (e) => !hidden[e[0]] || attachedFiles.includes[e[0]]
    );
    const dirs = entry.subDirectories || [];
    for (const i in dirs) {
      Object.entries(loop(dirs[i])).forEach(([i, e]) => res.push(e));
    }
    return res;
  };
  const res = loop(getAttachments(state));
  return Object.fromEntries(res);
};

export const getFlattenedHiddenFiles = (state) => {
  const loop = (entry) => {
    const files = entry.attachedFiles || [];
    const deleted = entry.deletedFiles || [];
    const hidden = { ...(entry.attachedFilesHidden || {}) };
    files.forEach((id) => (hidden[id] = false));
    deleted.forEach((id) => (hidden[id] = true));
    const fileStats = {};
    files.forEach((id) => (fileStats[id] = false));
    for (const id in hidden) fileStats[id] = !!hidden[id];
    const dirs = entry.subDirectories || [];
    for (const i in dirs) {
      Object.entries(loop(dirs[i])).forEach((e) => {
        if (!(e[0] in fileStats)) fileStats[e[0]] = !!e[1];
        else fileStats[e[0]] = !!(fileStats[e[0]] && e[1]);
      });
    }
    return fileStats;
  };
  return loop(getAttachments(state));
};

export const getFileNameOverridesInDir = (state, directoryId) => {
  const dir = findDir(getAttachments(state), directoryId);
  if (!dir) return {};
  return dir.attachedFileNameOverrides || {};
};

export const getFileNameOverrideInDir = (state, fileId, directoryId) => {
  return getFileNameOverridesInDir(state, directoryId)[fileId];
};

export const getHiddenFilesInDir = (state, directoryId) => {
  const dir = findDir(getAttachments(state), directoryId);
  if (!dir) return {};
  const files = dir.attachedFiles || [];
  const deleted = dir.deletedFiles || [];
  const hidden = { ...(dir.attachedFilesHidden || {}) };
  files.forEach((id) => (hidden[id] = false));
  deleted.forEach((id) => (hidden[id] = true));
  return Object.fromEntries(Object.entries(hidden).filter((e) => e[1]));
};

export const isFileHidden = (state, fileId, directoryId) =>
  getHiddenFilesInDir(state, directoryId)[fileId] || false;

export const getDirectoryTree = (state) => getAttachments(state);
