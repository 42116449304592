import * as userSelectors from "../../store/selectors/user/userSelectors";
import SignOutButton from "../layout/SignOutButton";
import { connect } from "react-redux";

const MetaData = ({ label, value, fallback = "-" }) => (
  <span className={"meta-data"}>
    <span className={"label"}>{`${label}: `}</span>
    {value || fallback}
  </span>
);

const Footer = ({ user, extraFields = null }) => {
  const fields = [
    { label: "User", value: user?.attributes?.email, fallback: "Unknown" },
  ].concat(extraFields ?? []);

  return (
    <div className={"footer-wrapper"}>
      <div className={"container"}>
        <div className={"meta-data-wrapper"}>
          {fields.map((field, i) => (
            <MetaData {...field} key={i} />
          ))}
        </div>
        <div className={"sign-out"}>
          <SignOutButton />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: userSelectors.selectUser(state),
  };
};

export default connect(mapStateToProps)(Footer);
