import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.ingest(args.url),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: args.body,
      }
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const ingestApi = createApi({
  reducerPath: "ingestApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    status: builder.query({
      query: (ingestionId) => ({
        url: "retrieve-status",
        body: JSON.stringify({ ingestionId }),
      }),
    }),
    retrieveTransformHints: builder.query({
      query: ({ ingestionId, transformId }) => ({
        url: "retrieve-transform-hints",
        body: JSON.stringify({ ingestionId, transformId }),
      }),
    }),
    createIngestion: builder.mutation({
      query: (ingestionType) => ({
        url: "create-ingestion",
        body: JSON.stringify({ ingestionType }),
      }),
    }),
    applyTransform: builder.mutation({
      query: ({ ingestionId, transformId, args }) => ({
        url: "apply-transform",
        body: JSON.stringify({ ingestionId, transformId, args }),
      }),
    }),
    completeIngestion: builder.mutation({
      query: ({ ingestionId }) => ({
        url: "complete-ingestion",
        body: JSON.stringify({ ingestionId }),
      }),
    }),
    retrieveArtifact: builder.query({
      query: ({ artifactId }) => ({
        url: "retrieve-artifact",
        body: JSON.stringify({ artifactId }),
      }),
    }),
  }),
});

export const {
  useStatusQuery,
  useRetrieveTransformHintsQuery,
  useCreateIngestionMutation,
  useApplyTransformMutation,
  useCompleteIngestionMutation,
  useRetrieveArtifactQuery,
} = ingestApi;
