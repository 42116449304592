import { Box, Stack, Typography } from "@mui/material";
import { clamp } from "common/numbers";

const Bar = ({ data, max, color, secondaryColor }) => {
  const sx = {
    position: "absolute",
    width: (data.value / max) * 100 + "%",
    height: "100%",
    borderRadius: "3px",
    background: data.color || color || "#ffab53",
  };

  return (
    <Box sx={{ position: "relative", display: "flex" }}>
      {data.secondaryValue != null && data.secondaryValue > data.value && (
        <Box
          sx={{
            ...sx,
            width: clamp((data.secondaryValue / max) * 100, 0, 100) + "%",
            background: secondaryColor || "seashell",
          }}
        />
      )}
      <Box sx={sx} />
      {data.secondaryValue != null && data.secondaryValue < data.value && (
        <Box
          sx={{
            ...sx,
            width: clamp((data.secondaryValue / max) * 100, 0, 100) + "%",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
            borderRight: "3px dotted " + (secondaryColor || "seashell"),
          }}
        />
      )}
      <Typography
        variant={"caption"}
        sx={{ position: "relative", padding: "0.25rem 0.5rem" }}
      >
        {data.label}
      </Typography>
    </Box>
  );
};

const RelativeBars = ({ data, blankSlate, color, secondaryColor }) => {
  const sorted = [...(data ?? [])].sort((a, b) => b.value - a.value);
  const max = sorted[0]?.value ?? 0;

  return (
    <Stack spacing={1}>
      {(sorted.length === 0 || max === 0) && (
        <Typography color={"dimgray !important"} align={"center"} padding={3}>
          {blankSlate}
        </Typography>
      )}
      {sorted.map((x, index) => (
        <Bar
          key={index}
          data={x}
          max={max}
          color={color}
          secondaryColor={secondaryColor}
        />
      ))}
    </Stack>
  );
};

export default RelativeBars;
