import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.transfer(args.url),
      {
        header: {
          "Content-Type": "application/json",
        },
        method: args.method ?? "post",
        body: args.body,
      },
      args.impersonateCompanyId
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

export const transferApi = createApi({
  reducerPath: "transferApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    retrieveMergeDefinition: builder.query({
      query: ({ transferId, impersonateCompanyId }) => ({
        url: "retrieve-merge-definition",
        body: JSON.stringify({ transferId }),
        impersonateCompanyId,
      }),
    }),
    updateMergeDefinition: builder.mutation({
      query: ({ mergeDefinition, impersonateCompanyId }) => ({
        url: "update-merge-definition",
        body: JSON.stringify(mergeDefinition),
        impersonateCompanyId,
      }),
    }),
  }),
});

export const {
  useRetrieveMergeDefinitionQuery,
  useUpdateMergeDefinitionMutation,
} = transferApi;
