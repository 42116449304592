import * as peopleActions from "../../../store/actions/people/peopleActions";
import * as peopleSelectors from "../../../store/selectors/people/peopleSelectors";
import Component from "../../Component";
import BrokerCreation from "./BrokerCreation";
import PersonSelect from "./PersonSelect";
import Disabled from "components/common/Disabled";
import React from "react";
import { connect } from "react-redux";
import * as staticDataService from "services/staticDataService";

const Broker = ({ broker, updateBroker }) => {
  const {
    data: brokers,
    refetch: refreshBrokers,
  } = staticDataService.useListBrokersQuery();

  const sortedBrokers = [...(brokers ?? [])].sort(
    (a, b) =>
      (a.company || "z").localeCompare(b.company || "z") ||
      (a.name || "z").localeCompare(b.name || "z")
  );

  return (
    <Component
      title={"Broker"}
      subtitle={"The broker associated with this submission."}
    >
      <Disabled ifReadOnly>
        <PersonSelect
          person={broker}
          personList={sortedBrokers}
          onUpdatePerson={updateBroker}
          onUpdatePersonList={refreshBrokers}
          PersonCreationComponent={BrokerCreation}
        />
      </Disabled>
    </Component>
  );
};

const mapStateToProps = (state) => ({
  broker: peopleSelectors.selectBroker(state),
});

const mapDispatchToProps = {
  updateBroker: peopleActions.updateBroker,
};

export default connect(mapStateToProps, mapDispatchToProps)(Broker);
