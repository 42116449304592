import * as exposures from "domain/exposures";
import { createSelector } from "reselect";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

export const getPeersRaw = (state) => {
  return state.peers;
};

export const selectResolvedPeers = getPeersRaw;

export const rawPeerData = (state) => state.temp?.peersPricing?.peerData || {};

const rawPeerExposure = createSelector([getPeersRaw], (peers) => {
  return peers.peerExposure;
});

export const selectFixedPeerMetricDefn = createSelector(
  [userSelectors.selectUserConfig],
  (userConfig) => {
    return (
      userConfig.fixedPeerMetricDefn ?? { key: "REVENUE", name: "Revenue" }
    );
  }
);

export const peerExposures = createSelector(
  [getPeersRaw, selectFixedPeerMetricDefn],
  (peers, fixedPeerMetricDefn) => {
    return [
      fixedPeerMetricDefn,
      peers.exposureMetric,
      peers.exposureMetric2,
    ].filter((x) => x !== null);
  }
);
export const peerExposure = createSelector(
  [rawPeerExposure, peerExposures],
  (peerExposure, candidates) => {
    if (
      peerExposure != null &&
      candidates.some((x) => x?.key === peerExposure.key)
    ) {
      return peerExposure;
    }
    return { key: "REVENUE", name: "Revenue" };
  }
);

const requiredState = (state) => ({
  input: {
    program: state.input.program,
    exposure: state.input.exposure,
  },
  pricing: {
    layers: state.pricing.layers,
  },
  persistence: {
    filename: state?.persistence?.filename,
  },
});

const extractPeerData = (
  peer,
  data,
  exposureKey1,
  exposureKey2,
  fixedPeerMetricKey
) => {
  const program = data?.input?.program;
  const insured = program?.insured || peer.insured;
  const inception = program?.inception || peer.inception;
  const industry = program?.industryClass?.name;
  const fixedPeerMetricValue = exposures.latestExposure(
    data?.input?.exposure?.data,
    fixedPeerMetricKey
  );
  const exposure = exposures.latestExposure(
    data?.input?.exposure?.data,
    exposureKey1
  );
  const exposure2 = exposures.latestExposure(
    data?.input?.exposure?.data,
    exposureKey2
  );
  return {
    filename: peer.filename || data?.persistence?.filename,
    insured,
    inception,
    industry,
    fixedPeerMetricValue,
    exposure,
    exposure2,
    loaded: true,
    layers:
      data == null
        ? []
        : pricingSelectors
            .getTower(data)
            .map((layer, layer_index) => {
              return {
                unfiltered_index: layer_index,
                limit: layer.limit,
                attachment: layer.attachment,
                grossPPM: layer.grossPPM,
                gnulr: layer.uwSelectedMeasure?.technicalRatio,
                tp: layer.uwSelectedMeasure?.targetPrice,
                totalAcquisition: layer.tac,
                lineSize: layer.lineSize,
                weighting: peer.pricing?.[layer_index]?.weighting ?? 0,
              };
            })
            .filter((x) => x.lineSize > 0),
  };
};

export const getPeers = createSelector(
  [getPeersRaw, rawPeerData, requiredState, selectFixedPeerMetricDefn],
  (peers, peerData, state, fixedPeerMetricDefn) => {
    return [
      extractPeerData(
        {},
        state,
        peers.exposureMetric?.key,
        peers.exposureMetric2?.key,
        fixedPeerMetricDefn.key
      ),
      ...peers.submissions.map((peer) => {
        if (peerData[peer.filename]) {
          return extractPeerData(
            peer,
            peerData[peer.filename],
            peers.exposureMetric?.key,
            peers.exposureMetric2?.key,
            fixedPeerMetricDefn.key
          );
        } else {
          return {
            filename: peer.filename,
            insured: peer.insured,
            inception: peer.inception,
            loaded: false,
          };
        }
      }),
    ];
  }
);

const peersRequestData = (peer, data, exposureKey) => {
  const peerAttributes = {
    naics: data?.input?.program?.naics?.code,
    inUs: data?.input?.program?.inUS,
    turnover: exposures.latestExposure(data?.input?.exposure?.data, "REVENUE"),
    exposure: exposures.latestExposure(
      data?.input?.exposure?.data,
      exposureKey
    ),
    insured: data?.input?.program?.insured,
  };
  return data == null
    ? []
    : pricingSelectors
        .getTower(data)
        .map((layer) => {
          return {
            ...peerAttributes,
            terms: {
              limit: layer.limit,
              attachment: layer.attachment,
            },
            premium: layer.grossPremium,
          };
        })
        .filter((_, idx) => (peer.pricing?.[idx]?.weighting ?? 0) > 0);
};

export const getPeerPricingRequest = createSelector(
  [
    getPeersRaw,
    rawPeerData,
    requiredState,
    pricingSelectors.getTower,
    peerExposure,
  ],
  (peers, peerData, state, tower, peerExposure) => {
    return {
      terms: tower.map((layer) => ({
        terms: { limit: layer.limit, attachment: layer.attachment },
        insured: state.input.program.insured,
        premium: layer.grossPremium,
      })),
      exposure: exposures.latestExposure(
        state?.input?.exposure?.data,
        peerExposure.key
      ),
      peers: peers.submissions.flatMap((peer) => {
        if (peerData[peer.filename]) {
          return peersRequestData(
            peer,
            peerData[peer.filename],
            peerExposure.key
          );
        }
        return [];
      }),
    };
  }
);

export const getPeerIndustryClass = (state) => {
  const programIndustryClass = state?.input?.program?.industryClass;
  const peerIndustryClass = state?.peers?.industryClass;
  return peerIndustryClass == null ? programIndustryClass : peerIndustryClass;
};

const getPeerNaicsRaw = (state) => {
  return state.peers?.naics;
};

export const getPeerNaics = createSelector(
  [getPeerNaicsRaw, programSelectors.getCompanyNaics],
  (peer, company) => (peer == null ? company : peer)
);
