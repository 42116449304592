import * as actionTypes from "store/actions/temp/maintenance/maintenanceStatusActionTypes";
import * as utils from "utils";

export const INITIAL_STATE = {
  message: "",
  disableNow: false,
  showWarningNow: false,
  warningClosed: false,
  loadFailed: 0,
  versionNumber: "",
  versionChanged: false,
  changeCompleted: false,
  forceReload: false,
  dialogTitle: null,
  dialogMessage: null,
};

const updateState = ({ priorState, maintenanceStatus }) => {
  const isPageExempt = (window?.location?.pathname ?? "").startsWith("/admin/");
  if (isPageExempt) {
    return INITIAL_STATE;
  }

  const wasWarnClosed = priorState.warningClosed;
  const oldMessage = priorState.message;
  const newMessage = maintenanceStatus.message;
  const warningDisplay = maintenanceStatus.showWarningNow;
  const warningClosed =
    wasWarnClosed && oldMessage === newMessage && warningDisplay;
  const oldDisabled = priorState.disableNow;
  const oldVersion = priorState.versionNumber;
  const newState = {
    ...priorState,
    ...maintenanceStatus,
    loadFailed: 0,
    warningClosed,
  };
  if (oldVersion && oldVersion !== newState.versionNumber) {
    newState.versionChanged = true;
  }
  if ((oldDisabled || newState.versionChanged) && !newState.disableNow) {
    newState.changeCompleted = true;
  }

  utils.setOutgoingCallsBlocked(newState.disableNow);

  return newState;
};

const maintenanceStateReducer = (state = INITIAL_STATE, action) => {
  if (state === undefined) {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case actionTypes.CLOSE_MAINTENANCE_WARNING:
      return {
        ...state,
        warningClosed: true,
      };
    case actionTypes.LOAD_MAINTENANCE_STATUS_START:
      return state;
    case actionTypes.LOAD_MAINTENANCE_STATUS_FAIL:
      return {
        ...state,
        loadFailed: (state.loadFailed || 0) + 1,
      };
    case actionTypes.LOAD_MAINTENANCE_STATUS_OK:
      return updateState({
        priorState: state ?? {},
        maintenanceStatus: action?.payload ?? {},
      });
    case actionTypes.UPDATE_MAINTENANCE_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default maintenanceStateReducer;
