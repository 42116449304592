import Grid from "@mui/material/Grid";
import Component from "components/Component";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import * as config from "config";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";

const SummaryTable = ({ activeLayerRateChange, updateLayerRateChange }) => {
  return (
    <Component title={"Summary"}>
      <Grid container spacing={config.GRID_SPACING}>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Pure Rate Change (%)"}
            value={activeLayerRateChange?.pureRateChangeOverride}
            onChangeNumber={(value) => updateLayerRateChange(value)}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            autoComplete={"off"}
            variant={"filled"}
          />
        </Grid>
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {
  updateLayerRateChange: rateChangeSlice.updateLayerPropertiesItem(
    "pureRateChangeOverride"
  ),
};

const mapStateToProps = (state) => {
  return {
    activeLayerRateChange: rateChangeSlice.selectActiveLayerRateChange(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTable);
