import DocViewer, {
  BMPRenderer,
  CSVRenderer,
  HTMLRenderer,
  JPGRenderer,
  PDFRenderer,
  PNGRenderer,
  TIFFRenderer,
  TXTRenderer,
} from "@cyntler/react-doc-viewer";
import { Alert } from "@mui/material";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import * as fileUtils from "fileUtils";
import * as filesService from "services/filesService";

const activePlugins = [
  PDFRenderer,
  CSVRenderer,
  TXTRenderer,
  TIFFRenderer,
  PNGRenderer,
  JPGRenderer,
  HTMLRenderer,
  BMPRenderer,
];

const useFileMetadata = () => {
  const searchParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const isOldUrl = !!searchParams?.attachmentId;

  const fileIdFromFilename = () => {
    if (searchParams.filename) {
      const lastDot = searchParams.filename.lastIndexOf(".");
      if (
        lastDot === 36 &&
        /^[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12}/.test(searchParams.filename)
      ) {
        return searchParams.filename.substring(0, lastDot);
      } else {
        return searchParams?.attachmentId;
      }
    }
  };
  const fileId = isOldUrl ? fileIdFromFilename() : searchParams.fileId;

  const allMetaDataInSearchParams =
    searchParams.filename && searchParams.mime && searchParams.type;

  const {
    data: meta,
    isLoading,
    isError,
  } = filesService.useGetFileMetadataQuery(fileId, {
    skip: !searchParams || !fileId || allMetaDataInSearchParams,
  });

  const filename =
    (isOldUrl ? searchParams.viewName : searchParams?.filename) ??
    meta?.originalFilename;
  const mime = searchParams?.mime ?? meta?.mimeType;
  const type = searchParams?.type ?? fileUtils.getViewTypeFromMime(mime);

  return {
    fileMetadata: {
      fileId,
      filename,
      type,
      mime,
    },
    isLoading: !allMetaDataInSearchParams && isLoading,
    isError: !allMetaDataInSearchParams && isError,
  };
};

const HtmlDisplay = ({ body }) => {
  const url = URL.createObjectURL(new Blob([body], { type: "text/html" }));
  return (
    <iframe
      style={{
        marginTop: "0.375rem",
        padding: "0.25rem 0.5rem",
        border: "2px solid gainsboro",
        borderRadius: "0.5rem",
        width: "100%",
        height: "calc(100vh - 200px)",
      }}
      sandbox={"allow-same-origin"}
      src={url}
      title={"html-display"}
    />
  );
};

const RawHtmlFileViewer = ({ fileId }) => {
  const { data, isLoading, isError } = filesService.useFileBlobQuery(fileId);

  if (isLoading) {
    return <BigLoader />;
  }
  if (isError) {
    return <Alert severity={"error"}>{"Could not load file"}</Alert>;
  }

  return <HtmlDisplay body={data} />;
};

const HtmlFileViewer = ({ fileId }) => {
  const { data, isLoading, isError } = filesService.useHtmlFileBlobQuery(
    fileId
  );

  if (isLoading) {
    return <BigLoader />;
  }
  if (isError) {
    return <Alert severity={"error"}>{"Could not load file"}</Alert>;
  }

  return <HtmlDisplay body={data} />;
};

const RawFileViewer = ({ fileId, filename, mime }) => {
  const { data, isLoading, isError } = filesService.useFileUrlQuery(fileId);

  if (isLoading) {
    return <BigLoader />;
  }
  if (isError) {
    return <Alert severity={"error"}>{"Could not load file"}</Alert>;
  }

  return (
    <DocViewer
      documents={[
        {
          uri: data.url,
          filename: filename,
          fileType: mime,
        },
      ]}
      pluginRenderers={activePlugins}
      prefetchMethod={"GET"}
      config={{
        header: { disableFileName: true },
        pdfVerticalScrollByDefault: true,
      }}
    />
  );
};

const AttachmentFileViewer = () => {
  const { fileMetadata, isLoading, isError } = useFileMetadata();

  const Content = ({ fileId, filename, type, mime }) => {
    if (type === "html") {
      return <HtmlFileViewer fileId={fileId} />;
    } else if (type === "raw") {
      if (mime === "text/html") {
        return <RawHtmlFileViewer fileId={fileId} />;
      } else {
        return (
          <RawFileViewer fileId={fileId} filename={filename} mime={mime} />
        );
      }
    } else {
      return (
        <Alert severity={"warning"}>
          {"Cannot view this type of file. Please download it instead."}
        </Alert>
      );
    }
  };

  return (
    <Component title={fileMetadata.filename ?? "View File"} loading={isLoading}>
      {isError ? (
        <Alert severity={"error"}>{"Could not locate file."}</Alert>
      ) : (
        <Content {...fileMetadata} />
      )}
    </Component>
  );
};

export default AttachmentFileViewer;
