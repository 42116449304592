import SignOutButton from "../layout/SignOutButton";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

const AccessDeniedDialog = ({ message }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={true} onClose={() => {}} fullWidth={true}>
      <DialogTitle>{"Access Denied"}</DialogTitle>
      <DialogContent>
        <Alert severity={"error"} variant={"outlined"}>
          {message}
        </Alert>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "0 24px 18px 24px",
        }}
      >
        <SignOutButton
          disableElevation
          variant={"contained"}
          color={"secondary"}
          className={""}
        />
        <Button
          disableElevation
          variant={"contained"}
          onClick={() => navigate("/")}
        >
          {"Home"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AccessDeniedDialog;
