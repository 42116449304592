import * as config from "config";
import * as actionTypes from "store/actions/peers/peersActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as searchActionTypes from "store/actions/search/searchActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as utils from "utils";

export const INIT_STATE = {
  submissions: [],
  exposureMetric: { key: "ASSETS", name: "Assets", units: "Count" },
  exposureMetric2: { key: "EMPLOYEES", name: "Employees", units: "Count" },
  note: null,
};

const peersReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.peers,
      };
    case actionTypes.ADD_PEER:
    case actionTypes.LOAD_PEER: {
      const currentSubmission = (state.submissions || []).find(
        (submission) => submission.filename === action.payload.filename
      );
      const targetState = {
        filename: action.payload.filename,
        insured: action.payload.insured,
        inception: utils.dateOnly(action.payload.inception),
      };
      if (
        currentSubmission?.filename === targetState.filename &&
        currentSubmission?.insured === targetState.insured &&
        currentSubmission?.inception === targetState.inception
      ) {
        return state;
      }
      return {
        ...state,
        submissions:
          currentSubmission === undefined
            ? [...(state.submissions || []), targetState]
            : state.submissions.map((submission) => {
                if (submission.filename === action.payload.filename) {
                  return targetState;
                }
                return submission;
              }),
      };
    }
    case actionTypes.UPDATE_SELECTED_PEERS:
      return {
        ...state,
        submissions: (state.submissions || []).map((submission) => {
          if (submission.filename !== action.payload.filename) {
            return submission;
          }
          return {
            ...submission,
            pricing: Array(
              Math.max(
                action.payload.layerIndex + 1,
                submission.pricing?.length || 0
              )
            )
              .fill(0)
              .map((_, layerIndex) => {
                const layer = submission.pricing?.[layerIndex] || {};
                if (action.payload.layerIndex !== layerIndex) {
                  return layer;
                }
                return {
                  ...layer,
                  weighting: action.payload.weighting,
                };
              }),
          };
        }),
      };
    case actionTypes.DELETE_PEER:
      return {
        ...state,
        submissions: (state.submissions || []).filter(
          (x) => x.filename !== action.payload
        ),
      };
    case actionTypes.UPDATE_CHOSEN_PEER_EXPOSURE_METRIC:
      return {
        ...state,
        exposureMetric: action.payload,
      };
    case actionTypes.UPDATE_CHOSEN_PEER_EXPOSURE_METRIC_2:
      return {
        ...state,
        exposureMetric2: action.payload,
      };
    case actionTypes.UPDATE_PEER_CHOSEN_EXPOSURE_METRIC:
      return {
        ...state,
        peerExposure: action.payload,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
        note:
          staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.peers?.note ?? null,
      };
    case actionTypes.UPDATE_PEERS_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case actionTypes.UPDATE_PEERS_INDUSTRY_CLASS:
      return {
        ...state,
        industryClass: action.payload,
      };
    case searchActionTypes.SELECT_SEARCH_VALUE:
      switch (action.payload?.searchType) {
        case config.PEER_NAICS_SEEKER.key:
          return {
            ...state,
            naics: {
              code: action.payload.selectedValue._CODE,
              title: action.payload.selectedValue._TITLE,
            },
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default peersReducer;
