import { Box } from "@mui/material";
import { readableDate } from "common/dates";
import { abbreviated } from "common/numbers";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import RelativeBars from "components/common/RelativeBars";
import { useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";

const ClaimsLargest = () => {
  const claimsQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );

  const { data: claims, isLoading, isFetching } = claimsService.useClaimsQuery({
    claimsQuery,
    limit: 10,
    includeExcluded: false,
  });

  const toLabel = (claim, secondaryValue) => {
    const label = [];
    label.push(claim["_OPEN_CLOSED"] === "C" ? "Closed" : "Open");
    label.push(
      abbreviated(claim["_TOTAL_LOSS"]) +
        (secondaryValue == null
          ? ""
          : " (" + abbreviated(secondaryValue) + " override)")
    );
    label.push(claim["_COVERAGE"]);
    label.push(readableDate(claim["_DATE_OF_LOSS"]));
    return label.join(" • ");
  };

  const displayData = (claims ?? []).map((claim) => {
    const reported = claim["_TOTAL_LOSS"];
    const override =
      Math.abs(
        (claim["_ULTIMATE_INCURRED"] ?? 0) - (claim["_SCALED_INCURRED"] ?? 0)
      ) > 0
        ? claim["_ULTIMATE_INCURRED"]
        : null;

    return {
      label: toLabel(claim, override),
      value: override ?? reported,
      secondaryValue: override == null ? null : reported,
      color: claim["_OPEN_CLOSED"] === "C" ? "lightsteelblue" : null,
    };
  });

  return (
    <Component
      title={"Top 10 Claims"}
      isFetching={isFetching && !isLoading}
      subtitle={"The ten largest reported claims across all years"}
      lg_width={6}
      md_width={12}
    >
      {isLoading ? (
        <BigLoader />
      ) : (
        <Box sx={{ opacity: isFetching ? 0.5 : 1, transition: "150ms" }}>
          <RelativeBars
            data={displayData}
            blankSlate={"No claims loaded at present"}
          />
        </Box>
      )}
    </Component>
  );
};

export default ClaimsLargest;
