import { PRICING_METHODS } from "./AppliedExcessRecorder";
import CentredLoader from "./CentredLoader";
import {
  deletionMappingsReducer,
  getConversionTypeForColumn,
} from "./ClaimsTransformerSupport";
import DataValidation from "./DataValidation";
import * as config from "config";
import { CLAIMS_MAPPING_IGNORE_VALUE, EXCLUDE_KEY } from "config";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useDoConversionQuery,
  useGetClaimsMappingQuery,
  useGetConversionErrorsQuery,
  useGetHeaderRowQuery,
} from "services/tablesService";
import { setV2ClaimsKey } from "store/actions/input/claims/claimsActions";

export const ClaimsTransformer = ({
  fileId,
  origin,
  tableName,
  headerRows,
  mapping,
  appliedExcess,
  substitutions,
  rowDeletions,
  filename,
  tablesList,
  onConversionComplete,
  setDateConversionType,
  dateConversionTypeList,
  onSubstitutionsChanged,
  onRowDeletionsChanged,
}) => {
  const [columnMappings, setColumnMappings] = useState({});
  const [progressMessage, setProgressMessage] = useState("");
  const [deletionMappings, setDeletionMappings] = useState({});
  const [openClosedMapping, setOpenClosedMapping] = useState(null);
  const [coverageMapping, setCoverageMapping] = useState(null);
  const [coverageDefault, setCoverageDefault] = useState(null);
  const [requestDateConversionTypes, setRequestDateConversionTypes] = useState(
    []
  );
  const [requestSubstitutions, setRequestSubstitutions] = useState([]);
  const [requestDeletions, setRequestDeletions] = useState([]);

  useEffect(() => {
    const colMap = {};
    if (mapping) {
      for (const index in mapping) {
        const entry = mapping[index];
        colMap[entry.outputName] = entry.inputColumn;
      }
      setColumnMappings(colMap);
    }
  }, [mapping, setColumnMappings]);

  const { data: headings } = useGetHeaderRowQuery(
    {
      fileId,
      tableName,
      skipRows: origin.row,
      skipColumns: origin.column,
      headerRows,
    },
    { skip: !fileId || !tableName }
  );

  const addDeletionMapping = useCallback(
    (key, deletions) => {
      const excludeColumn = columnMappings[key];
      if (!excludeColumn && excludeColumn !== 0) return;
      setDeletionMappings(deletionMappingsReducer(excludeColumn, deletions));
    },
    [columnMappings]
  );

  useEffect(() => {
    if (Object.keys(columnMappings ?? {}).length > 0) {
      if (EXCLUDE_KEY in columnMappings) {
        const excMapping = getConversionTypeForColumn(
          mapping,
          EXCLUDE_KEY,
          "IN_DELETE_ROW"
        );
        if (!excMapping) return;
        const excVals = Object.entries(excMapping?.conversions)
          .filter(([_, func]) => func === CLAIMS_MAPPING_IGNORE_VALUE)
          .map(([val, _]) => val);
        if (excVals.length > 0) {
          addDeletionMapping(EXCLUDE_KEY, excVals);
        }
      }
    }
  }, [
    columnMappings,
    setDeletionMappings,
    deletionMappings,
    mapping,
    addDeletionMapping,
  ]);

  useEffect(() => {
    const generateMappingDataForEnumCol = (
      colName,
      mappingSetter,
      existing
    ) => {
      const enMapping = getConversionTypeForColumn(mapping, colName, "CONVERT")
        ?.conversions;
      if (!enMapping) return;
      const disFilter = Object.entries(enMapping)
        .filter(([_, mapped]) => mapped === config.CLAIMS_MAPPING_IGNORE_VALUE)
        .map(([val, _]) => val);
      addDeletionMapping(colName, disFilter);
      const enFilter = Object.fromEntries(
        Object.entries(enMapping).filter(
          ([_, mapped]) => mapped !== config.CLAIMS_MAPPING_IGNORE_VALUE
        )
      );
      if (JSON.stringify(enFilter) !== JSON.stringify(existing ?? {})) {
        mappingSetter(enFilter);
      }
    };

    generateMappingDataForEnumCol(
      "_OPEN_CLOSED",
      setOpenClosedMapping,
      openClosedMapping
    );

    const coverageConversion = getConversionTypeForColumn(
      mapping,
      "_COVERAGE",
      "CONSTANT"
    );

    if (coverageConversion) {
      setCoverageDefault(coverageConversion.value);
    } else {
      generateMappingDataForEnumCol(
        "_COVERAGE",
        setCoverageMapping,
        coverageMapping
      );
    }
  }, [
    mapping,
    setOpenClosedMapping,
    setCoverageMapping,
    coverageMapping,
    openClosedMapping,
    addDeletionMapping,
  ]);

  const hasCoverage =
    Object.keys(coverageMapping ?? {}).length > 0 || coverageDefault != null;

  const getExcess = () => {
    switch (appliedExcess?.type) {
      case PRICING_METHODS.FGU:
        return 0;
      case PRICING_METHODS.EXCESS:
        return appliedExcess?.excessRemoved ? appliedExcess?.excess : 0;
      default:
        return null;
    }
  };

  const getThreshold = () => {
    switch (appliedExcess?.type) {
      case PRICING_METHODS.FGU:
        return 0;
      case PRICING_METHODS.EXCESS:
        return appliedExcess?.excess;
      default:
        return null;
    }
  };

  const {
    data: mappingTable,
    status: mappingTableStatus,
  } = useGetClaimsMappingQuery(
    {
      fileId,
      columnMappings,
      substitutions: requestSubstitutions,
      rowDeletions: requestDeletions,
      deletionMappings: deletionMappings ? Object.values(deletionMappings) : [],
      openClosedMapping,
      openClosedDeleteUnmappedRows: false,
      coverageMapping: coverageMapping ?? [],
      coverageDeleteUnmappedRows: false,
      coverageDefault: coverageDefault ?? "GL",
      excess: getExcess(),
      threshold: getThreshold(),
      defaultDate: "1970-01-01",
      dateConversionTypes: requestDateConversionTypes,
    },
    {
      skip: !hasCoverage || !fileId,
    }
  );

  const {
    data: conversionErrors,
    status: conversionErrorsStatus,
  } = useGetConversionErrorsQuery(
    {
      fileId,
      tableName,
      skipColumns: origin.column,
      skipRows: origin.row,
      headerRows,
      skipBlankRows: true,
      mapping: mappingTable,
    },
    { skip: mappingTableStatus !== "fulfilled" || !fileId || !tableName }
  );

  const hasConversionErrors =
    conversionErrorsStatus === "fulfilled" && conversionErrors?.errors?.length;

  const {
    data: processingResponse,
    status: processingResponseStatus,
  } = useDoConversionQuery(
    {
      fileId,
      tableName,
      skipColumns: origin.column,
      skipRows: origin.row,
      headerRows,
      skipBlankRows: true,
      mapping: mappingTable,
      progressNotification: (timing) => {
        if (timing?.rowsProcessed) {
          if (timing.percentageComplete === 100) {
            setProgressMessage("Process complete, Saving...");
          } else {
            setProgressMessage(
              `${timing.rowsProcessed} rows processed ` +
                `${timing.percentageComplete}% estimated ` +
                `${timing.remainingSeconds} seconds left`
            );
          }
        } else {
          setProgressMessage("initialising...");
        }
      },
    },
    {
      skip:
        mappingTableStatus !== "fulfilled" ||
        hasConversionErrors ||
        !fileId ||
        !tableName,
    }
  );

  const dispatch = useDispatch();
  const [createdFile, setCreatedFile] = useState(null);
  useEffect(() => {
    if (
      processingResponseStatus === "fulfilled" &&
      processingResponse.createdFile &&
      createdFile !== processingResponse.createdFile
    ) {
      setCreatedFile(processingResponse.createdFile);
      dispatch(
        setV2ClaimsKey(
          processingResponse.createdFile,
          fileId,
          tableName,
          filename,
          tablesList
        )
      );
      onConversionComplete();
    }
  }, [
    createdFile,
    setCreatedFile,
    processingResponse,
    processingResponseStatus,
    dispatch,
    fileId,
    tableName,
    filename,
    tablesList,
    onConversionComplete,
  ]);

  const setRequestValues = () => {
    const activeSubstitutions = substitutions
      .filter((x) => !rowDeletions.includes(x.row))
      .reduce((acc, { column, row, value }) => {
        if (!acc[column]) {
          acc[column] = { column, replacements: [] };
        }
        acc[column].replacements.push({ row, value });
        return acc;
      }, {});

    const dateConversions = Object.entries(dateConversionTypeList).map(
      ([column, type]) => ({
        column,
        type,
      })
    );

    setRequestDateConversionTypes(dateConversions);
    setRequestSubstitutions(Object.values(activeSubstitutions));
    setRequestDeletions(rowDeletions);
  };

  if (
    processingResponseStatus === "rejected" ||
    conversionErrorsStatus === "rejected"
  ) {
    return <>{"Error - failed to load file"}</>;
  } else if (conversionErrorsStatus !== "fulfilled") {
    return <CentredLoader label={"Validating data " + progressMessage} />;
  } else if (hasConversionErrors) {
    return (
      <DataValidation
        conversionErrors={conversionErrors}
        headings={headings}
        origin={origin}
        substitutions={substitutions}
        rowDeletions={rowDeletions}
        setDateConversionType={setDateConversionType}
        dateConversionTypeList={dateConversionTypeList}
        onReProcess={setRequestValues}
        onSubstitutionsChanged={onSubstitutionsChanged}
        onRowDeletionsChanged={onRowDeletionsChanged}
      />
    );
  } else {
    return <CentredLoader label={"Validating data " + progressMessage} />;
  }
};

export default ClaimsTransformer;
