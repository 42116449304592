import * as staticDataService from "services/staticDataService";
import * as userService from "services/userService";

export const useIsVisible = (visibilityTag) => {
  const { data, isLoading } = userService.useAttributesQuery();

  return {
    isVisible: data?.config?.componentVisibility?.[visibilityTag],
    isLoading,
  };
};

export const useTranslator = (domain) => {
  const { data, isLoading } = staticDataService.useConfigQuery("translations");

  const translate = (key, options = { default: "" }) => {
    if (isLoading) {
      return "...";
    }
    return data?.[domain]?.[key] ?? options?.default;
  };

  return translate;
};
