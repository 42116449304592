/**
 * Copied from: https://dev.to/0916dhkim/simple-typescript-mutex-implementation-5544
 */
export class Mutex {
  constructor() {
    this._isLocked = false;
    this._queue = [];
  }

  acquire() {
    return new Promise((resolve) => {
      this._queue.push({ resolve });
      this._dispatch();
    });
  }

  async runExclusive(func) {
    const release = await this.acquire();
    try {
      return await func();
    } finally {
      release();
    }
  }

  _dispatch() {
    if (this._isLocked) {
      return;
    }
    const nextEntry = this._queue.shift();
    if (!nextEntry) {
      return;
    }
    this._isLocked = true;
    nextEntry.resolve(() => {
      this._isLocked = false;
      this._dispatch();
    });
  }
}
