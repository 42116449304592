import { fetchAuthSession } from "aws-amplify/auth";
import * as errors from "common/errors";
import { HandledError } from "common/errors";
import * as locks from "common/locks";
import * as logger from "common/logger";
import * as network from "common/network";
import { getTokenPreExpiryRefreshSeconds } from "config";
import _ from "lodash";
import { useEffect, useState } from "react";
import * as utils from "utils";

export const getCellName = (topLeft) => {
  if (topLeft == null) {
    return "";
  }
  let { row, column } = topLeft;
  let columnStr = "";
  while (column > 25) {
    const remainder = column % 26;
    column = Math.floor(column / 26);
    columnStr = String.fromCharCode(65 + remainder) + columnStr;
  }
  columnStr =
    String.fromCharCode((columnStr.length > 0 ? 64 : 65) + column) + columnStr;
  return `${columnStr}${row + 1}`;
};

export { formatDate } from "./common/dates";
export { roundUpNice, sfString } from "./common/numbers";
export { getUniqueKey } from "./common/ids";
export {
  latestExposure,
  latestTurnover,
  exposureSeries,
} from "./domain/exposures";
export { shortLayerName } from "domain/layerSupport";

export const dateOnly = (date) => {
  if (date) {
    const asDate = new Date(date);
    return asDate.toISOString().slice(0, 10);
  }
  return undefined;
};

export const usDateFormat = (date) => {
  if (!date) return null;
  var y = "";
  var m = 0;
  var d = 0;
  if (date instanceof Date) {
    y = date.getUTCFullYear();
    m = date.getUTCMonth();
    d = date.getUTCDate();
  } else {
    y = date.substring(0, 4);
    m = Number(date.substring(5, 7)) - 1;
    d = Number(date.substring(8, 10));
  }

  var mmm = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ][m];
  return mmm + " " + d + ", " + y;
};

export const zip = (a, b) => a.map((k, i) => [k, b[i]]);

export const plainFetch = async (url, allowCodes, { method = "get" } = {}) => {
  const response = await network.fetchWithRetry({ retryOnStatusCodes: [503] })(
    url,
    {
      method,
      cache: "no-cache",
    }
  );
  if (
    !(response.ok || (allowCodes && new Set(allowCodes).has(response.status)))
  ) {
    logger.breadcrumb(
      {
        url,
        status: response?.status,
        statusText: response?.statusText,
      },
      {
        category: "fetch",
        level: logger.LEVEL.ERROR,
      }
    );
    throw await errors.buildErrorFromResponse(response);
  }
  return response;
};

const nearingExpiry = (session) => {
  const sessionExpiry = session.tokens?.accessToken?.payload?.exp;
  if (sessionExpiry == null) {
    return false;
  }
  const preExpTime = getTokenPreExpiryRefreshSeconds();
  const now = Math.round(Date.now() / 1000);
  return preExpTime + now > sessionExpiry;
};

const _getSession = async () => {
  let session = await fetchAuthSession();
  if (nearingExpiry(session)) {
    session = await fetchAuthSession({ forceRefresh: true });
  }
  return session;
};

const sessionMutex = new locks.Mutex();
export const getSession = async () => {
  return await sessionMutex.runExclusive(_getSession);
};

let outgoingCallsBlocked = false;
export const setOutgoingCallsBlocked = (blocked) => {
  outgoingCallsBlocked = blocked;
};

export const authenticatedFetch = async (
  url,
  payload = {},
  impersonateCompanyId = undefined
) => {
  if (outgoingCallsBlocked) {
    throw new HandledError({
      title: "Network execution blocked",
      message: "maintenance blocking in operation",
    });
  }
  const session = await utils.getSession();
  const token = session.tokens?.accessToken?.toString();
  if (token == null || token === "") {
    throw new HandledError({
      title: "Network execution blocked",
      message: "unable to retrieve auth token",
    });
  }
  const response = await network.fetchWithRetry({ retryOnStatusCodes: [503] })(
    url,
    {
      method: "get",
      cache: "no-cache",
      ...payload,
      headers: {
        ...(payload.headers || {}),
        Authorization: token,
        ...(impersonateCompanyId && {
          "x-mmm-impersonate-company-id": impersonateCompanyId,
        }),
      },
    }
  );
  if (!response.ok) {
    logger.breadcrumb(
      {
        url,
        status: response?.status,
        statusText: response?.statusText,
      },
      {
        category: "fetch",
        level: logger.LEVEL.ERROR,
      }
    );
    throw await errors.buildErrorFromResponse(response);
  }
  return response;
};

export const getTicks = (
  stop,
  start = 10000,
  base10Ticks = [1, 1.25, 1.5, 1.75, 2, 2.5, 3, 3.5, 4, 5, 6, 8],
  hasZero = true
) => {
  const fromBase10 = Math.floor(Math.log10(start));
  const toBase10 = Math.ceil(Math.log10(stop));
  const range = Math.max(1, toBase10 - fromBase10);

  function* getSliderTicks() {
    if (hasZero) {
      yield 0;
    }
    for (let log = 0; log <= range; log++) {
      const multiplier = Math.pow(10, log + fromBase10);
      for (let i = 0; i < base10Ticks.length; i++) {
        const tick = base10Ticks[i] * multiplier;
        if (tick >= start) yield tick;
        if (tick >= stop) {
          return;
        }
      }
    }
  }

  return Array.from(getSliderTicks());
};

export const calculateTickIndex = (ticks, value) => {
  if (ticks.length === 0) {
    return undefined;
  }
  if (ticks.length === 1) {
    return 0;
  }
  if (value <= ticks[0]) {
    return 0;
  }
  if (value >= ticks[ticks.length - 1]) {
    return ticks.length - 1;
  }
  const index = _.sortedIndex(ticks, value);
  if (ticks[index - 1] === value) {
    return index;
  }
  const below = ticks[index - 1];
  const above = ticks[index];
  return (value - below) / (above - below) + index - 1;
};

export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
      });
    };

    if (myRef.current) {
      setDimensions({
        width: myRef.current.offsetWidth,
        height: myRef.current.offsetHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

export const isEmptyObject = (obj) => {
  if (obj == null) {
    return true;
  } else if (typeof obj !== "object" || Array.isArray(obj)) {
    return false;
  }
  for (const _ in obj) {
    return false;
  }
  return true;
};
