import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { toSentenceCase } from "common/strings";
import SeverityBadge from "components/common/SeverityBadge";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import { Link } from "react-router-dom";

const sortByIdAndLevel = (scenarios) => {
  const copy = JSON.parse(JSON.stringify(scenarios));

  return copy.sort(
    (a, b) =>
      b.submission_id.localeCompare(a.submission_id) ||
      b.layer_id.localeCompare(a.layer_id) ||
      b.scenario_level - a.scenario_level
  );
};

const groupedLayer = (layer, scenarios) => {
  const maxRiskFactor =
    scenarios.reduce((acc, cur) => Math.max(acc, cur.level), 0) / 4;

  const grouped = {
    ...layer,
    maxRisk: layer.limit * maxRiskFactor,
    maxRiskFactor: maxRiskFactor,
    industry_class: layer.industry_class,
    scenarios: [...scenarios].sort(
      (a, b) => b.level - a.level || b.key.localeCompare(a.key)
    ),
    rank: scenarios.reduce((acc, cur) => acc + Math.pow(10, cur.level - 1), 0),
  };

  delete grouped["scenario_key"];
  delete grouped["scenario_level"];
  return grouped;
};

const groupAndScore = (scenarios) => {
  if (!scenarios || scenarios.length === 0) {
    return [];
  }

  const data = [];
  let prevScenario = null;
  let groupedScenarios = [];

  sortByIdAndLevel(scenarios).forEach((scenario) => {
    if (
      prevScenario === null ||
      (prevScenario.submission_id === scenario.submission_id &&
        prevScenario.layer_id === scenario.layer_id)
    ) {
      groupedScenarios.push({
        key: scenario.scenario_key,
        level: scenario.scenario_level,
      });
    } else {
      data.push(groupedLayer(prevScenario, groupedScenarios));
      groupedScenarios = [
        { key: scenario.scenario_key, level: scenario.scenario_level },
      ];
    }
    prevScenario = scenario;
  });

  data.push(groupedLayer(prevScenario, groupedScenarios));

  return data;
};

const sort = (data, sortByInsured) => {
  const stringCompare = (a, b) => (a ?? "").localeCompare(b ?? "");
  return [...data].sort((a, b) => {
    if (sortByInsured) {
      return (
        stringCompare(a.insured, b.insured) ||
        stringCompare(b.submission_id, a.submission_id) ||
        stringCompare(b.layer_id, a.layer_id) ||
        b.rank - a.rank
      );
    } else {
      return (
        b.rank - a.rank ||
        stringCompare(b.insured, a.insured) ||
        stringCompare(b.submission_id, a.submission_id) ||
        stringCompare(b.layer_id, a.layer_id)
      );
    }
  });
};

const DetailsByInsured = ({ scenarios, scenarioLabels, sortByInsured }) => {
  const data = groupAndScore(scenarios);
  const dataSorted = sort(data, sortByInsured);

  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>{"Insured"}</TableCell>
            <TableCell>{"Status"}</TableCell>
            <TableCell>{"Industry"}</TableCell>
            <TableCell>{"Inception"}</TableCell>
            <TableCell>{"Expiry"}</TableCell>
            <TableCell align={"right"}>{"Attachement"}</TableCell>
            <TableCell align={"right"}>{"Limit"}</TableCell>
            <TableCell align={"right"}>{"Line Size"}</TableCell>
            <TableCell>{"Scenario"}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSorted.map((row) => {
            return (
              <TableRow
                key={`${row.submission_id}:${row.layer_id}`}
                sx={{ verticalAlign: "top" }}
              >
                <TableCell>
                  <b>{row.insured ?? "???"}</b>
                </TableCell>
                <TableCell>
                  <StatusBadgeV2 status={row.status} />
                </TableCell>
                <TableCell>
                  {toSentenceCase(row.industry_class ?? "-")}
                </TableCell>
                <TableCell>{row.inception}</TableCell>
                <TableCell>{row.expiration}</TableCell>
                <TableCell align={"right"}>
                  {(row.attachment ?? 0).toLocaleString()}
                </TableCell>
                <TableCell align={"right"}>
                  {(row.limit ?? 0).toLocaleString()}
                </TableCell>
                <TableCell align={"right"}>
                  {(row.line_size ?? 0).toLocaleString()}
                </TableCell>
                <TableCell>
                  {row.scenarios.map((scenario) => {
                    return (
                      <Stack
                        direction={"row"}
                        alignItems={"first baseline"}
                        key={scenario.key}
                      >
                        <SeverityBadge level={scenario.level} />
                        {scenarioLabels[scenario.key] ||
                          toSentenceCase(scenario.key)}
                      </Stack>
                    );
                  })}
                </TableCell>
                <TableCell>
                  <Link
                    to={`/submissions/${row.submission_id}/inputs/scenarios`}
                    target={"_blank"}
                  >
                    <OpenInNewIcon />
                  </Link>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailsByInsured;
