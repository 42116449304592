import { OpenInNew as OpenTransferIcon } from "@mui/icons-material";
import { InputAdornment, Stack, TextField } from "@mui/material";
import Component from "components/Component";
import IconButton from "components/common/IconButton";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const SelectTransfer = () => {
  const transferIdRef = useRef("");
  const navigate = useNavigate();

  const openTransfer = () => {
    const transferId = transferIdRef.current.value;
    navigate(`${transferId}/`);
  };

  return (
    <Component title={"Select Transfer"}>
      <Stack
        direction={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
        spacing={2}
      >
        <TextField
          variant={"filled"}
          label={"Transfer ID"}
          inputRef={transferIdRef}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton
                  icon={OpenTransferIcon}
                  scale={"small"}
                  onClick={openTransfer}
                />
              </InputAdornment>
            ),
          }}
          fullWidth
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              openTransfer();
            }
          }}
        />
      </Stack>
    </Component>
  );
};

export default SelectTransfer;
