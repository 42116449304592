import { TableCell } from "@mui/material";
import { numberToAlphabetical } from "common/numbers";
import Mapper from "components/common/Mapper";
import { connect } from "react-redux";
import * as actions from "store/actions/input/claims/claimsActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";

const mapDispatchToProps = {
  mappingUpdated: actions.mapping.updated,
};

const mapStateToProps = (state) => {
  const topLeft = claimsSelectors.selectActiveFileTopLeft(state);
  return {
    targets: claimsSelectors.selectTargetClaimsColumns(state),
    sources: state?.input?.claims?.activeFile?.columns,
    mapping: state?.input?.claims?.activeFile?.columnMapping,
    suggested: state?.input?.claims?.activeFile?.columnMappingSuggestions,
    firstHeaderCell: <TableCell></TableCell>,
    firstBodyCell: (source, index) => (
      <TableCell width={1}>{`${numberToAlphabetical(index + topLeft.column)}${
        topLeft.row + 1
      }`}</TableCell>
    ),
    isTransposed: false,
    maxHeight: "70vh",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapper);
