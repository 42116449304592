import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Component from "components/Component";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as programTowerActions from "store/actions/input/program/programTowerActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const NumberCell = (props) => {
  const { cellStyle, good, ...numberFormatProps } = props;
  if (props.value == null || isNaN(props.value)) {
    return <TableCell align={"right"} />;
  } else {
    return (
      <TableCell align={"right"} style={cellStyle}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          defaultValue={""}
          decimalScale={0}
          className={"monospace"}
          {...numberFormatProps}
        />
      </TableCell>
    );
  }
};

const HeaderRow = () => {
  return (
    <TableRow>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 20 }} />
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 150 }}>
        {"Limit"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 150 }}>
        {"Attachment"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 150 }}>
        {"Gross Premium"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 100 }}>
        {"RPM"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 100 }}>
        {"RPM Relative to Layer Below"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 80 }}>
        {"Share (%)"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 150 }}>
        {"Share of Limit"}
      </TableCell>
      <TableCell align={"right"} valign={"bottom"} style={{ minWidth: 150 }}>
        {"Share of Premium"}
      </TableCell>
      <TableCell align={"left"} valign={"bottom"} style={{ minWidth: 400 }}>
        {"Comment"}
      </TableCell>
    </TableRow>
  );
};

const BodyRow = ({
  layer,
  pricing,
  index,
  isNewRow,
  updateProgramTowerValue,
  deleteProgramTowerLayer,
}) => {
  const updateValue = ({ key, value }) =>
    updateProgramTowerValue({ layerIndex: index, key, value });

  return (
    <TableRow>
      <TableCell align={"left"}>
        {isNewRow ? (
          <></>
        ) : (
          <IconButton
            icon={DeleteIcon}
            onClick={() => deleteProgramTowerLayer({ layerIndex: index })}
          />
        )}
      </TableCell>
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer.limit || ""}
          fullWidth
          onChangeNumber={(value) => updateValue({ key: "limit", value })}
          name={"limit"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer.attachment || ""}
          fullWidth
          onChangeNumber={(value) => updateValue({ key: "attachment", value })}
          name={"attachment"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer.grossPremium || ""}
          fullWidth
          onChangeNumber={(value) =>
            updateValue({ key: "grossPremium", value })
          }
          name={"gross-premium"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <NumberCell value={layer.grossPPM} />
      <NumberCell
        suffix={"%"}
        decimalScale={2}
        fixedDecimalScale
        value={
          pricing?.rateRelativeToLayerBelow != null
            ? pricing.rateRelativeToLayerBelow * 100
            : ""
        }
      />
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer.shareOfLine || ""}
          fullWidth
          onChangeNumber={(value) => updateValue({ key: "shareOfLine", value })}
          name={"share"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <NumberCell value={layer.lineSize} />
      <NumberCell value={layer.shareOfPremium} />
      <TableCell align={"left"}>
        <TextField
          type={"text"}
          InputProps={{
            spellCheck: "false",
            margin: "dense",
          }}
          autoComplete={"off"}
          onChange={(e) =>
            updateValue({ key: "comment", value: e.target.value })
          }
          value={layer.comment || ""}
          fullWidth
          multiline
          name={"comment"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
    </TableRow>
  );
};

export const Tower = ({
  layers,
  updateProgramTowerValue,
  deleteProgramTowerLayer,
}) => {
  const pricings = (layers ?? []).map((layer, index) => ({
    rateRelativeToLayerBelow:
      index > 0 ? layer.grossPPM / layers[index - 1].grossPPM : null,
  }));
  return (
    <Component
      title={"Tower"}
      subtitle={"The layers that are being placed in the market."}
      error={false}
    >
      <TableContainer>
        <Table size={"small"} className={"condensed-table"}>
          <TableHead>
            <HeaderRow />
          </TableHead>
          <TableBody>
            {[...(layers ?? []), {}].map((layer, index) => (
              <BodyRow
                key={index}
                layer={layer}
                pricing={pricings[index]}
                index={index}
                isNewRow={index === (layers ?? []).length}
                updateProgramTowerValue={updateProgramTowerValue}
                deleteProgramTowerLayer={deleteProgramTowerLayer}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    layers: pricingSelectors.selectResolvedLayers(state),
  };
};

const mapDispatchToProps = {
  updateProgramTowerValue: programTowerActions.updateValue,
  deleteProgramTowerLayer: programTowerActions.deleteLayer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tower);
