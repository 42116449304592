import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.notes(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (error) {
    return { error };
  }
};

export const notesApi = createApi({
  reducerPath: "notesApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createNote: builder.mutation({
      query: ({ submissionId, title, content }) => ({
        url: "create-note",
        payload: {
          body: JSON.stringify({ submissionId, title, content }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    retrieveNote: builder.query({
      query: ({ submissionId, noteId }) => ({
        url: "retrieve-note",
        payload: {
          body: JSON.stringify({ submissionId, noteId }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    listNotes: builder.query({
      query: ({ submissionId }) => ({
        url: "list-notes",
        payload: {
          body: JSON.stringify({ submissionId }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    updateNote: builder.mutation({
      query: ({ submissionId, noteId, title, content }) => ({
        url: "update-note",
        payload: {
          body: JSON.stringify({ submissionId, noteId, title, content }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
    deleteNote: builder.mutation({
      query: ({ submissionId, noteId }) => ({
        url: "delete-note",
        payload: {
          body: JSON.stringify({ submissionId, noteId }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useRetrieveNoteQuery,
  useListNotesQuery,
  useUpdateNoteMutation,
  useDeleteNoteMutation,
} = notesApi;
