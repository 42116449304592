import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as numbers from "common/numbers";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import NumberFormat from "react-number-format";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const SnapshotPricingRow = ({ layer }) => {
  return (
    <TableRow>
      <TableCell align={"center"}>
        <StatusBadgeV2 status={layer.status} variant={"chip"} />
      </TableCell>

      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          prefix={"$"}
          value={layer.lineSize}
        />
      </TableCell>

      <TableCell align={"center"}>{numbers.abbreviated(layer.limit)}</TableCell>

      <TableCell align={"center"}>
        {numbers.abbreviated(layer.attachment)}
      </TableCell>

      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          prefix={"$"}
          value={layer.grossPremium}
        />
      </TableCell>

      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          prefix={"$"}
          value={layer.grossPPM}
        />
      </TableCell>

      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          prefix={"$"}
          value={layer.shareOfPremium}
        />
      </TableCell>

      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          decimalScale={0}
          prefix={"$"}
          value={((100 - layer.tac) / 100) * layer.shareOfPremium}
        />
      </TableCell>

      <TableCell align={"center"}>
        {layer.tac}
        {"%"}
      </TableCell>

      <TableCell align={"center"}>
        <NumberFormat
          displayType={"text"}
          decimalScale={0}
          suffix={"%"}
          value={layer.uwSelectedMeasure?.targetPrice}
          className={
            (layer.uwSelectedMeasure?.targetPrice ?? 100) - 100 < 0
              ? "table-cell-bad-deal"
              : "table-cell-good-deal"
          }
        />
      </TableCell>

      <TableCell align={"center"}>
        <NumberFormat
          displayType={"text"}
          decimalScale={2}
          suffix={"%"}
          value={layer.uwSelectedMeasure?.technicalRatio}
        />
      </TableCell>
    </TableRow>
  );
};

const SnapshotPricingTable = ({ pricingLayers }) => {
  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell align={"center"}>{"Status"}</TableCell>
            <TableCell align={"right"}>{"Our Limit"}</TableCell>
            <TableCell align={"center"}>{"Part Of"}</TableCell>
            <TableCell align={"center"}>{"Excess"}</TableCell>
            <TableCell align={"right"}>{"Premium"}</TableCell>
            <TableCell align={"right"}>{"RPM"}</TableCell>
            <TableCell align={"right"}>{"Our Gross"}</TableCell>
            <TableCell align={"right"}>{"Our Net"}</TableCell>
            <TableCell align={"center"}>{"TAC"}</TableCell>
            <TableCell align={"center"}>{"TP"}</TableCell>
            <TableCell align={"center"}>{"GNULR"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...pricingLayers]
            .sort((a, b) => b.attachment - a.attachment)
            .map((layer, index) => (
              <SnapshotPricingRow
                layer={layer}
                key={`SNAPSHOT_PRICING_${index}`}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const SnapshotPricing = ({ state }) => {
  const pricingLayers = pricingSelectors.getTower(state);

  return state == null ? (
    <Typography padding={1.75}>
      {
        "No version information available, if this is a new submission you'll need to save it first"
      }
    </Typography>
  ) : (
    <SnapshotPricingTable pricingLayers={pricingLayers} />
  );
};

export default SnapshotPricing;
