import * as dates from "common/dates";
import moment from "moment";

export const compareStringItems = (item) => (a, b) =>
  String(a?.[item] ?? "").localeCompare(String(b?.[item] ?? ""));

export const makeQueryMatcher = (searchQuery) => {
  if (!searchQuery) {
    return (_) => true;
  }

  const searchTokens = searchQuery.trim().toLowerCase().split(/\W+/);

  const matchableFields = [
    "insured",
    "riskDescription",
    "inception",
    "reference",
  ];

  return (submission) => {
    const values = matchableFields.map((field) =>
      String(submission?.[field] ?? "").toLowerCase()
    );
    return searchTokens.every((token) =>
      values.some((value) => value.includes(token))
    );
  };
};

export const searchSubmissions = (
  submissions,
  { searchQuery, justMe, userEmail, limit = 50 }
) => {
  if (!submissions) {
    return {
      recentlyEditedSubmissions: [],
      renewingSubmissions: [],
    };
  }

  if (justMe) {
    submissions = submissions.filter((s) => s.savedBy === userEmail);
  }
  if (searchQuery) {
    const matchesQuery = makeQueryMatcher(searchQuery);
    submissions = submissions.filter(matchesQuery);
  }

  const today = moment.parseZone(dates.getDate()).format("YYYY-MM-DD");
  const threeMonths = moment
    .parseZone(today)
    .add(3, "months")
    .format("YYYY-MM-DD");
  return {
    recentlyEditedSubmissions: [...submissions]
      .sort((a, b) => -compareStringItems("savedAt")(a, b))
      .slice(0, limit),
    renewingSubmissions: [...submissions]
      .filter((s) => s.expiration > today && s.expiration <= threeMonths)
      .sort(
        (a, b) =>
          compareStringItems("expiration")(a, b) ||
          compareStringItems("submissionId")(a, b)
      )
      .slice(0, limit),
  };
};
