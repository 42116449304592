import CheckCircle from "@mui/icons-material/CheckCircle";
import Help from "@mui/icons-material/Help";

const IsMappedIcon = ({ isMapped }) => (
  <>
    <div className={"icon"}>
      <span className={`icon ${isMapped ? "good" : "faded"}`}>
        {isMapped ? <CheckCircle data-testid={"mapped"} /> : <Help />}
      </span>
    </div>{" "}
  </>
);

export default IsMappedIcon;
