import * as actionTypes from "store/actions/input/scenarios/scenariosActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const INIT_STATE = {
  scenarios: {},
  relativeToLayerId: null,
};

const excludeZeroLevels = (scenarios) => {
  if (scenarios == null) {
    return {};
  } else {
    return Object.fromEntries(
      Object.entries(scenarios).filter(([_, v]) => v !== 0)
    );
  }
};

const scenariosReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.scenarios,
      };
    case actionTypes.SET_SCENARIO_LEVELS:
      return {
        ...state,
        scenarios: excludeZeroLevels(action.payload),
      };
    case actionTypes.SET_SCENARIO_LAYER_ID:
      return {
        ...state,
        scenarioLayerId: action.payload ?? null,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
        note: staticDataSelectors.selectConfig("submission_defaults")(
          action?.payload?.priorState
        )?.input?.scenarios?.note,
      };
    case actionTypes.UPDATE_SCENARIOS_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    default:
      return state;
  }
};

export default scenariosReducer;
