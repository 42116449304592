import { Grid, Box, Button } from "@mui/material";
import * as charts from "common/charts";
import Component from "components/Component";
import ClaimsSlider from "components/inputs/claims/ClaimsSlider";
import FilteredClaimsChart from "components/inputs/claims/FilteredClaimsChart";
import { useDispatch, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

const useAllClaims = () => {
  const claimsThreshold = useSelector(claimsSelectors.selectClaimsThreshold);

  const claimsQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const { currentData: allClaimsChart } = claimsService.useAllClaimsChartQuery({
    claimsQuery,
    claimsAbove: claimsThreshold,
  });

  const priorClaimsQuery = useDebouncedValue(
    useSelector((state) =>
      claimsSelectors.selectInputClaims(stateSelectors.selectPriorState(state))
    )
  );
  const {
    currentData: priorAllClaimsChart,
  } = claimsService.useAllClaimsChartQuery({
    claimsQuery: priorClaimsQuery,
    claimsAbove: claimsThreshold,
  });

  return { claimsThreshold, allClaimsChart, priorAllClaimsChart };
};

const SideBySideClaimsChart = () => {
  const dispatch = useDispatch();

  const {
    allClaimsChart,
    priorAllClaimsChart,
    claimsThreshold,
  } = useAllClaims();

  const numberTicks = charts.makeTicksForNumericDomain(
    charts.getNumberDomain(priorAllClaimsChart, allClaimsChart)
  );
  const numberDomain = [numberTicks[0], numberTicks.at(-1)];
  const dateDomain = charts.getDateDomain(priorAllClaimsChart, allClaimsChart);
  return (
    <Component
      title={`All Claims ${
        claimsThreshold > 0
          ? `(> $${claimsThreshold.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })})`
          : ""
      }`}
      subtitle={"In excess of threshold"}
      xs_width={12}
    >
      <Grid container spacing={3} alignItems={"stretch"}>
        <Grid item xs={12}>
          <Box mr={6}>
            <h2 className={"component-title"}>{"Current"}</h2>
            <FilteredClaimsChart
              data={allClaimsChart}
              dateDomain={dateDomain}
              numberTicks={numberTicks}
              numberDomain={numberDomain}
              filter={{ attachment: claimsThreshold }}
              zoomIdentifier={"sidebyside"}
              disabled
            />
            <h2 className={"component-title"}>{"Prior"}</h2>
            <FilteredClaimsChart
              data={priorAllClaimsChart}
              dateDomain={dateDomain}
              numberTicks={numberTicks}
              numberDomain={numberDomain}
              filter={{ attachment: claimsThreshold }}
              zoomIdentifier={"sidebyside"}
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ClaimsSlider
            value={claimsThreshold}
            onChangeNumber={(threshold) =>
              dispatch(claimsActions.updateClaimThreshold(threshold))
            }
            min={0}
            max={1e9}
          />
        </Grid>
        <Grid container item xs={12} justifyContent={"flex-end"} spacing={3}>
          <Button
            disableElevation
            variant={"contained"}
            color={"secondary"}
            onClick={() => dispatch(claimsActions.clearChartZoom("sidebyside"))}
          >
            {"Clear Zoom"}
          </Button>
        </Grid>
      </Grid>
    </Component>
  );
};

export default SideBySideClaimsChart;
