import { ClickableCell, HeadingCell, InputCell, ValueCell } from "./Cell";
import { Stack } from "@mui/material";
import * as numbers from "common/numbers";
import Visible from "components/common/Visible";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lossDevelopmentStore from "store/lossDevelopment";

const format = numbers.dpString(3);

const Cumulative = ({ id }) => {
  const data = useSelector(lossDevelopmentStore.selectLossDevelopment)
    .triangles?.[id];

  const dispatch = useDispatch();
  const updateLossDevelopmentTriangle = useCallback(
    (payload) => {
      dispatch(
        lossDevelopmentStore.updateLossDevelopmentTriangle({
          id,
          triangle: payload,
        })
      );
    },
    [dispatch, id]
  );

  const toggleLdfModel = (model) => {
    if (data?.selectedLdfModel === model) {
      updateLossDevelopmentTriangle({ selectedLdfModel: null });
    } else {
      updateLossDevelopmentTriangle({ selectedLdfModel: model });
    }
  };

  const getLdfOverrideModel = (developmentYear) => {
    return data?.selectedLdfOverrides?.[developmentYear]?.model;
  };

  const hasLdfOverrideValue = (developmentYear) => {
    return data?.selectedLdfOverrides?.[developmentYear]?.value != null;
  };

  const toggleLdfOverrideModel = (developmentYear, { model }) => {
    const current = getLdfOverrideModel(developmentYear);
    const override = model !== current ? { model } : null;
    updateLossDevelopmentTriangle({
      selectedLdfOverrides: {
        ...data?.selectedLdfOverrides,
        [developmentYear]: override,
      },
    });
  };

  const setLdfOverrideValue = (developmentYear, { value }) => {
    const override = value !== null ? { value } : null;
    updateLossDevelopmentTriangle({
      selectedLdfOverrides: {
        ...data?.selectedLdfOverrides,
        [developmentYear]: override,
      },
    });
  };

  const developmentYears = data?.developmentYears ?? [];

  return (
    <Stack direction={"row"} marginTop={2}>
      <Stack>
        <HeadingCell
          label={"Last 3"}
          active={data?.selectedLdfModel === "avgLast3yrs"}
          onClick={() => toggleLdfModel("avgLast3yrs")}
        />
        <HeadingCell
          label={"Last 5"}
          active={data?.selectedLdfModel === "avgLast5yrs"}
          onClick={() => toggleLdfModel("avgLast5yrs")}
        />
        <HeadingCell
          label={"All Years"}
          active={data?.selectedLdfModel === "avg"}
          onClick={() => toggleLdfModel("avg")}
        />
        <Visible byTag={"inputs.lossDevelopment.exAnte"} defaultVisible={true}>
          <HeadingCell
            label={"Ex-Ante 3"}
            active={data?.selectedLdfModel === "exAnteAvgLast3yrs"}
            onClick={() => toggleLdfModel("exAnteAvgLast3yrs")}
          />
          <HeadingCell
            label={"Ex-Ante 5"}
            active={data?.selectedLdfModel === "exAnteAvgLast5yrs"}
            onClick={() => toggleLdfModel("exAnteAvgLast5yrs")}
          />
          <HeadingCell
            label={"Ex-Ante"}
            active={data?.selectedLdfModel === "exAnteAvg"}
            onClick={() => toggleLdfModel("exAnteAvg")}
          />
        </Visible>
        <HeadingCell label={"Selected"} />
        <HeadingCell label={"Cumulative"} />
      </Stack>
      {developmentYears.map((developmentYear, index) =>
        index < developmentYears.length - 1 ? (
          <Stack key={index}>
            <ClickableCell
              active={getLdfOverrideModel(developmentYear) === "avgLast3yrs"}
              onClick={() => {
                toggleLdfOverrideModel(developmentYear, {
                  model: "avgLast3yrs",
                });
              }}
            >
              {format(data?.ldfsByModel?.avgLast3yrs?.[index]?.value)}
            </ClickableCell>
            <ClickableCell
              active={getLdfOverrideModel(developmentYear) === "avgLast5yrs"}
              onClick={() => {
                toggleLdfOverrideModel(developmentYear, {
                  model: "avgLast5yrs",
                });
              }}
            >
              {format(data?.ldfsByModel?.avgLast5yrs?.[index]?.value)}
            </ClickableCell>
            <ClickableCell
              active={getLdfOverrideModel(developmentYear) === "avg"}
              onClick={() => {
                toggleLdfOverrideModel(developmentYear, { model: "avg" });
              }}
            >
              {format(data?.ldfsByModel?.avg?.[index]?.value)}
            </ClickableCell>
            <ClickableCell
              active={
                getLdfOverrideModel(developmentYear) === "exAnteAvgLast3yrs"
              }
              onClick={() => {
                toggleLdfOverrideModel(developmentYear, {
                  model: "exAnteAvgLast3yrs",
                });
              }}
            >
              {format(data?.ldfsByModel?.exAnteAvgLast3yrs?.[index]?.value)}
            </ClickableCell>
            <ClickableCell
              active={
                getLdfOverrideModel(developmentYear) === "exAnteAvgLast5yrs"
              }
              onClick={() => {
                toggleLdfOverrideModel(developmentYear, {
                  model: "exAnteAvgLast5yrs",
                });
              }}
            >
              {format(data?.ldfsByModel?.exAnteAvgLast5yrs?.[index]?.value)}
            </ClickableCell>
            <ClickableCell
              active={getLdfOverrideModel(developmentYear) === "exAnteAvg"}
              onClick={() => {
                toggleLdfOverrideModel(developmentYear, { model: "exAnteAvg" });
              }}
            >
              {format(data?.ldfsByModel?.exAnteAvg?.[index]?.value)}
            </ClickableCell>
            <InputCell
              active={hasLdfOverrideValue(developmentYear)}
              value={data?.ldfs?.[index]?.value}
              onChangeNumber={(value) => {
                setLdfOverrideValue(developmentYear, { value });
              }}
            />
            <ValueCell borderless={true}>
              {format(data?.cumulativeLdfs?.[index]?.value)}
            </ValueCell>
          </Stack>
        ) : (
          <Stack key={index}>
            <Visible
              byTag={"inputs.lossDevelopment.exAnte"}
              defaultVisible={true}
            >
              <ValueCell borderless={true} />
              <ValueCell borderless={true} />
              <ValueCell borderless={true} />
            </Visible>
            <ValueCell borderless={true} />
            <ValueCell borderless={true} />
            <ValueCell borderless={true} />
            <InputCell
              active={hasLdfOverrideValue(developmentYear)}
              value={data?.ldfs?.[index]?.value}
              onChangeNumber={(value) => {
                setLdfOverrideValue(developmentYear, { value });
              }}
            />
            <ValueCell borderless={true}>
              {format(
                data?.cumulativeLdfs?.[data?.cumulativeLdfs?.length - 1]?.value
              )}
            </ValueCell>
          </Stack>
        )
      )}
    </Stack>
  );
};

export default Cumulative;
