export const convertToRPM = ({ price, layer }) => {
  if (
    price == null ||
    layer == null ||
    layer.limit == null ||
    layer.limit === 0
  ) {
    return null;
  }
  return (1_000_000 * price) / layer.limit;
};

export const extractLayerPrice = ({ layer, measure, model = null }) => {
  model = model ?? layer?.pricing?.selectedModel;
  return layer?.pricing?.prices?.[model]?.[measure] ?? null;
};

export const extractLayerSelectedPrice = ({ layer, model = null }) => {
  model = model ?? layer?.pricing?.selectedModel;
  return (
    layer?.pricing?.selectedPrices?.[model] ??
    extractLayerPrice({
      layer,
      model,
      measure: layer?.pricing?.selectedMeasures?.[model],
    })
  );
};

export const calculatePricePerspectives = ({ price, layer }) => {
  const purePremium = price;
  if (purePremium == null) {
    return {};
  }

  const technicalFactor = (100 - layer.tac) / 100;
  const profitFactor = (100 - layer.profit - layer.expenses) / 100;
  const technicalRatio = purePremium / (layer.grossPremium * technicalFactor);
  const netPremium = (layer.grossPremium * (100 - layer.tac)) / 100;

  const removeNans = (obj) =>
    Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => !isNaN(value))
    );

  return removeNans({
    purePremium,
    requiredPremium: purePremium / technicalFactor / profitFactor,
    shareOfNetPremium: (netPremium * layer.shareOfLine) / 100,
    netPremium,
    lossRatio: (purePremium / layer.grossPremium) * 100,
    technicalRatio: technicalRatio * 100,
    targetPrice: (profitFactor / technicalRatio) * 100,
  });
};
