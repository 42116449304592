export const parseFloatTable = (input) => {
  const rows = input.trim().split(/\r?\n/);
  return rows.map((row) =>
    row.split("\t").map((cell) => {
      const trimmed = cell.trim();
      if (!trimmed) return null;
      try {
        const value = parseFloat(trimmed.replace(/,/g, ""));
        return isFinite(value) ? value : null;
      } catch {
        return null;
      }
    })
  );
};
