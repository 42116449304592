import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { dpString } from "common/numbers";
import Component from "components/Component";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useState } from "react";
import { connect } from "react-redux";
import * as currencySlice from "store/currencySlice";

const Currencies = ({
  currencies,
  addCurrency,
  updateCurrency,
  removeCurrency,
}) => {
  const [currency, setCurrency] = useState({ currency: "", fxRate: null });
  return (
    <Component
      title={"Exchange Rates"}
      subtitle={
        "Currency pairs will eventually be used to convert non-dollar losses into USD equivalents when loading a claims file"
      }
    >
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack direction={"column"} alignItems={"flex-end"}>
          <div>
            <TextField
              label={"Currency Code"}
              variant={"filled"}
              sx={{ paddingRight: 5 }}
              InputLabelProps={{ shrink: true }}
              value={currency.currency}
              onChange={(e) => {
                setCurrency({
                  ...currency,
                  currency: e.target.value.toUpperCase().trim(),
                });
              }}
            />
            <PrettyNumberTextField
              label={"FX Rate"}
              variant={"filled"}
              InputLabelProps={{ shrink: true }}
              value={currency.fxRate}
              onChangeNumber={(value) => {
                setCurrency({ ...currency, fxRate: value });
              }}
              offFormatter={dpString(4)}
            />
          </div>
          <Button
            onClick={() => {
              addCurrency(currency);
              setCurrency({ currency: "", fxRate: null });
            }}
            hasError={currency.currency === "USD"}
            errorTooltip={"Cannot enter USD"}
            isDisabled={
              currency.currency === "" ||
              currency.fxRate == null ||
              currency.currency === "USD"
            }
            sx={{ marginTop: 2 }}
          >
            {"Add"}
          </Button>
        </Stack>
        <TableContainer sx={{ width: 600 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{"Currency Code"}</TableCell>
                <TableCell>{"FX Rate → USD"}</TableCell>
                <TableCell>{"USD → FX Rate"}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(currencies).map(([currency, fxRate]) => {
                return (
                  <TableRow key={currency}>
                    <TableCell>{currency}</TableCell>
                    <TableCell>
                      <PrettyNumberTextField
                        hiddenLabel
                        size={"small"}
                        variant={"filled"}
                        value={fxRate}
                        offFormatter={dpString(4)}
                        onChangeNumber={(value) => {
                          updateCurrency({ currency, fxRate: value });
                        }}
                      />
                    </TableCell>
                    <TableCell>{dpString(4)(1.0 / fxRate)}</TableCell>
                    <TableCell>
                      <IconButton
                        icon={DeleteIcon}
                        onClick={() => {
                          removeCurrency({ currency });
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Component>
  );
};

const mapDispatchToProps = {
  addCurrency: currencySlice.addCurrency,
  updateCurrency: currencySlice.updateCurrency,
  removeCurrency: currencySlice.removeCurrency,
};

const mapStateToProps = (state) => {
  return {
    currencies: state?.currency?.currencies ?? {},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Currencies);
