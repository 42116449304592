export const DEFAULT_MANUAL_CLAIMS = {
  lossYear: "",
  coverage: "",
  lossAmount: null,
  description: "",
  claimant: "",
  oc: null,
  dateClosed: "",
};

export const isClaimsQueryEmpty = (claimsQuery) =>
  !claimsQuery?.keys?.length && !claimsQuery?.manualClaims?.length;
