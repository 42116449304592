import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "components/common/TextField";
import React from "react";
import { connect } from "react-redux";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const MULTIPLE_VALUES_KEY = "__MULTIPLE__";
const NONE_KEY = "__NONE__";

const ReinsuranceAggregator = ({
  label,
  layers,
  onChange,
  disabled,
  reinsurersConfig,
}) => {
  if (!reinsurersConfig?.reinsurers?.length) {
    return <></>;
  }

  if (!layers?.length) {
    return (
      <Grid item xs={12}>
        <TextField label={label} value={"No layers."} disabled />
      </Grid>
    );
  }

  const distinctLayerValues = new Set(
    (layers ?? []).map((layer) => layer.paperProvider ?? null)
  );
  const allLayersHaveSameValue = distinctLayerValues.size === 1;

  return (
    <Grid item xs={12}>
      <FormControl variant={"filled"} fullWidth>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          value={
            allLayersHaveSameValue
              ? layers?.[0]?.paperProvider ?? NONE_KEY
              : MULTIPLE_VALUES_KEY
          }
          onChange={(e) => {
            const key = e?.target?.value;
            if (!!key && key !== MULTIPLE_VALUES_KEY) {
              const value = key === NONE_KEY ? null : key;
              onChange(value);
            }
          }}
          disabled={disabled}
          fullWidth
        >
          {!allLayersHaveSameValue && (
            <MenuItem
              key={MULTIPLE_VALUES_KEY}
              value={MULTIPLE_VALUES_KEY}
              disabled
            >
              {"Layers have different reinsurance"}
            </MenuItem>
          )}
          <MenuItem value={NONE_KEY}>{"None"}</MenuItem>
          {(reinsurersConfig?.reinsurers ?? []).map((reinsurer) => (
            <MenuItem key={reinsurer.key} value={reinsurer.key}>
              {reinsurer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    reinsurersConfig: staticDataSelectors.selectConfig("reinsurers")(state),
  };
};

export default connect(mapStateToProps)(ReinsuranceAggregator);
