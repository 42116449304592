import GlobalError from "./error/GlobalError";
import * as Sentry from "@sentry/react";
import React from "react";
import { connect } from "react-redux";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.addBreadcrumb({
      message: `Master error boundary for user: ${
        this?.props?.attributes?.email || "UNKNOWN"
      }`,
    });
    Sentry.captureException(error);
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return <GlobalError />;
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    attributes: state.user?.attributes || {},
  };
};

export default connect(mapStateToProps, null)(ErrorBoundary);
