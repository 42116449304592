import * as actionTypes from "./towerPricingActionTypes";
import * as actions from "./towerPricingActions";
import { createAction } from "@reduxjs/toolkit";

export const updateLimit = (index, limit) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_LIMIT,
    payload: { index, limit },
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const updateCarrier = (index, carrier) => ({
  type: actionTypes.UPDATE_TOWER_CARRIER,
  payload: { index, carrier },
});

export const updateAttachment = (index, attachment) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_ATTACHMENT,
    payload: { index, attachment },
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const updateSelected = (index) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_SELECTED,
    payload: index,
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const updateMarketGrossWrittenPremium = (index, grossPremium) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_MARKET_GROSS_WRITTEN_PREMIUM,
    payload: { index, grossPremium },
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const addLayer = (index) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_TOWER_LAYER,
    payload: index,
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const deleteLayer = (index) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_TOWER_LAYER,
    payload: index,
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const updateLayer = (index, values) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_LAYER,
    payload: { index, values },
  });
  dispatch(actions.calculateTowerPricing.requested());
};

export const updateTowerPricingNote = (note) => ({
  type: actionTypes.UPDATE_TOWER_PRICING_NOTE,
  payload: note,
});

export const calculateTowerPricing = {
  requested: createAction("pricing/calculateTowerPricing"),
  started: createAction("pricing/calculateTowerPricing/started"),
  succeeded: createAction("pricing/calculateTowerPricing/succeeded", (data) => {
    return {
      payload: data,
    };
  }),
  failed: createAction("pricing/calculateTowerPricing/failed"),
};

export const updateShare = ({ layerIndex, shareIndex, values }) => ({
  type: actionTypes.UPDATE_TOWER_SHARE,
  payload: { layerIndex, shareIndex, values },
});

export const deleteShare = ({ layerIndex, shareIndex }) => ({
  type: actionTypes.DELETE_TOWER_SHARE,
  payload: { layerIndex, shareIndex },
});

export const updatePrimariesLimit = (index, limit) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_PRIMARIES_LIMIT,
    payload: { index, limit },
  });
};

export const updatePrimariesComment = (index, comment) => ({
  type: actionTypes.UPDATE_TOWER_PRIMARIES_COMMENT,
  payload: { index, comment },
});

export const updatePrimariesAttachment = (index, attachment) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_TOWER_PRIMARIES_ATTACHMENT,
    payload: { index, attachment },
  });
};

export const addPrimariesLayer = (index) => (dispatch) => {
  dispatch({
    type: actionTypes.ADD_TOWER_PRIMARIES_LAYER,
    payload: index,
  });
};

export const deletePrimariesLayer = (index) => (dispatch) => {
  dispatch({
    type: actionTypes.DELETE_TOWER_PRIMARIES_LAYER,
    payload: index,
  });
};

export const copyFromExpiring = (expiringLayers) => ({
  type: actionTypes.COPY_FROM_EXPIRING,
  payload: expiringLayers,
});
