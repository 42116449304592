import * as submissions from "domain/submissions";
import { createSelector, createStructuredSelector } from "reselect";
import * as metaSelectors from "store/selectors/meta/metaSelectors";

export const selectFilename = (state) => state.persistence?.filename;
export const selectSavedToken = (state) => state.persistence?.savedToken;
export const loaded = (state) => state.persistence?.state === "SUCCEEDED";

export const selectSubmissionId = createSelector(
  [selectFilename],
  (filename) => {
    try {
      return submissions.extractSubmissionIdFromFilename(filename);
    } catch {
      return null;
    }
  }
);

export const selectIsNew = (state) => !state.persistence?.lastSaved;

export const selectSubmissionStatus = createSelector(
  [
    metaSelectors.selectOverlay,
    metaSelectors.selectPending,
    metaSelectors.selectIsDeleted,
    selectIsNew,
  ],
  (overlay, pending, isDeleted, isNew) => {
    if (!!overlay) {
      return "overlay";
    } else if (!!pending) {
      return "pending";
    } else if (!!isDeleted) {
      return "deleted";
    } else if (!!isNew) {
      return "new";
    } else {
      return "default";
    }
  }
);

export const selectPersistedState = createStructuredSelector({
  input: (state) => state.input,
  pricing: (state) => state.pricing,
  rateChange: (state) => state.rateChange,
  peers: (state) => state.peers,
  summary: (state) => state.summary,
  print: (state) => state.print,
  schemaVersion: (state) => state.persistence?.schemaVersion,
  meta: (state) => state.meta,
  layerDefaults: (state) => state.layerDefaults,
  lossDevelopment: (state) => state.lossDevelopment,
  baseLayerBurn: (state) => state.baseLayerBurn,
  endorsements: (state) => state.endorsements,
  SOV: (state) => state.SOV,
  programPricing: (state) => state.programPricing,
  modelData: (state) => state.modelData,
  currency: (state) => state.currency,
  catExposures: (state) => state.catExposures,
  notes: (state) => state.notes,
  analytics: (state) => state.analytics,
  people: (state) => state.people,
});

export const selectFullPersistenceState = createSelector(
  [selectPersistedState, selectFilename, selectSavedToken, selectSubmissionId],
  (state, filename, savedToken, id) => {
    return {
      state,
      filename,
      savedToken,
      id,
    };
  }
);
