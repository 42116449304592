import * as actionTypes from "./premDeductActionTypes";
import * as logger from "common/logger";

export const transformCompleted = (fileId) => (dispatch) => {
  logger.breadcrumb({ msg: "transformCompletedSuccess", fileId });
  dispatch({
    type: actionTypes.TRANSFORM_PREM_DEDUCT_FILE_SUCCESS,
    payload: fileId,
  });
};

export const updatePremDeductNote = (note) => (dispatch) => {
  logger.breadcrumb({ msg: "updatePremDeductNote" });
  dispatch({
    type: actionTypes.UPDATE_PREM_DEDUCT_NOTE,
    payload: note,
  });
};

export const hidePremDeductFile = (fileId) => (dispatch) => {
  logger.breadcrumb({ msg: "hidePremDeductFile", fileId });
  dispatch({
    type: actionTypes.HIDE_PREM_DEDUCT_FILE,
    payload: fileId,
  });
};
