import { useSendFeedbackMutation } from "../../services/submissionsService";
import * as submissionSelector from "../../store/selectors/submission/submissionSelector";
import Button from "../common/Button";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import OfflineBanner from "components/common/OfflineBanner";
import * as config from "config";
import { useState } from "react";
import { useSelector } from "react-redux";

const NEW_FEATURE = "NEW_FEATURE_REQUEST";
const ENHANCEMENT = "ENHANCEMENT_REQUEST";
const BUG = "BUG_REPORT";

const placeHolderTexts = {
  [NEW_FEATURE]:
    "Please describe the new feature you wish to add, " +
    "both describing what you are trying to achieve as well as how you " +
    "would like to see it in Marmalade.\n\n" +
    "Understanding how the change effects the way you work " +
    "helps us to find the best solution.",
  [ENHANCEMENT]:
    "Please describe which part of Marmalade you wish " +
    "to change and how this will improve the way you use Marmalade.\n\n" +
    "Understanding how the change effects the way you work " +
    "helps us to find the best solution.",
  [BUG]:
    "Please describe what you were doing in Marmalade when you\n" +
    "found the problem together with " +
    "what actually happened and what you expected to happen.\n\n" +
    "The more information you provide the quicker we will be " +
    "able to find and fix the problem.",
};

export const FeedbackDialog = ({ open, close }) => {
  const [type, setType] = useState(NEW_FEATURE);
  const placeHolderText = placeHolderTexts[type];
  const [message, setMessage] = useState("");
  const [sendFeedback, { isError, isLoading }] = useSendFeedbackMutation();
  const submission = useSelector(
    submissionSelector.selectResolvedSubmissionWithoutInput
  );
  const saveAndClose = async () => {
    const response = await sendFeedback({
      message,
      type,
      submission,
      activeUrl: window.location.href,
    });
    if (!response?.error) {
      setMessage("");
      close();
    }
  };

  return (
    <Dialog open={open} maxWidth={"md"}>
      <DialogTitle>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {"Feedback"}
          <IconButton onClick={close}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container direction={"column"}>
          <Grid item>
            <RadioGroup
              row
              id={"feedback-type"}
              name={"feedback-type"}
              value={type}
              onChange={(event) => {
                setType(event.target.value);
              }}
            >
              <FormControlLabel
                value={NEW_FEATURE}
                control={<Radio color={"primary"} />}
                label={"Suggest a new feature"}
              />
              <FormControlLabel
                value={ENHANCEMENT}
                control={<Radio color={"primary"} />}
                label={"Request an enhancement"}
              />
              <FormControlLabel
                value={BUG}
                control={<Radio color={"primary"} />}
                label={"Report a fault"}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            <TextField
              placeholder={placeHolderText}
              minRows={7}
              multiline
              variant={"filled"}
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: "0 25px 15px 25px" }}>
        <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignContent={"center"}
          >
            <Button
              onClick={saveAndClose}
              color={"primary"}
              isDisabled={message.length < 5}
              isLoading={isLoading}
            >
              {"Save"}
            </Button>
          </Stack>
          {isError && (
            <Alert
              severity={"error"}
              sx={{ width: "100%" }}
            >{`Could not send the feedback. Please try again or email ${config.SUPPORT_EMAIL}`}</Alert>
          )}
        </Stack>
      </DialogActions>
      <OfflineBanner />
    </Dialog>
  );
};

export default FeedbackDialog;
