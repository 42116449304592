import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/input/program/programActions";

const NO_SELECTION_KEY = "__NO_SELECTION__";

export const ModelSelectorComponent = (props) => {
  const models = [
    { key: "WORKERS_COMP", name: "Workers' Comp" },
    { key: "NON_WORKERS_COMP", name: "General Casualty" },
    { key: "CASUALTY_CLASH", name: "Casualty Clash" },
    { key: "UMBRELLA", name: "Umbrella" },
    { key: "AUTO", name: "Auto" },
    { key: "MEDICAL_MALPRACTICE", name: "Medical Malpractice" },
  ];
  return (
    <Component
      title={"Model"}
      subtitle={"The Model that is to be used for pricing this risk."}
      {...props.componentProps}
    >
      {props.loading ? (
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            width: "100%",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          size={60}
        />
      ) : (
        <FormControl variant={"filled"} fullWidth>
          <InputLabel className={"input-label"}>{"Model"}</InputLabel>
          <Select
            id={"model-selector"}
            value={props.model ?? NO_SELECTION_KEY}
            onChange={(event) => {
              const value =
                event.target.value === NO_SELECTION_KEY
                  ? null
                  : event.target.value;
              props.updateModel(value);
            }}
            inputProps={{
              name: "program-inputs-model",
              id: "program-inputs-model",
            }}
            name={"model"}
            className={"program-inputs-model"}
          >
            {models.map((model) => {
              return (
                <MenuItem key={`Model_${model.key}`} value={model.key}>
                  {model.name}
                </MenuItem>
              );
            })}
            <MenuItem key={"Model_NULL"} value={NO_SELECTION_KEY} />
          </Select>
        </FormControl>
      )}
    </Component>
  );
};

const mapDispatchToProps = {
  updateModel: actions.updateModel,
};

const mapStateToProps = (state) => ({
  model: state?.input?.program?.model ?? null,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModelSelectorComponent);
