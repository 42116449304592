import { createSlice, createSelector } from "@reduxjs/toolkit";
import * as arrays from "common/arrays";
import * as programPricingResolver from "domain/programPricingResolver";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const programPricingSlice = createSlice({
  name: "programPricing",
  initialState: {},
  reducers: {
    set: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...values,
      };
    },
    update: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
    updatePathValue: (state, action) => {
      const { path, value } = action?.payload;

      const replacePathValue = (obj, { path, value }) =>
        arrays.hasItems(path)
          ? {
              ...obj,
              [path[0]]: replacePathValue(obj?.[path[0]] ?? {}, {
                path: path.slice(1),
                value,
              }),
            }
          : value;

      return replacePathValue(state, { path, value });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => ({
          ...action?.payload?.data?.state?.programPricing,
        })
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, (_, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.programPricing,
        };
      })
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (state, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.programPricing,
          ...state,
        };
      });
  },
});

export const selectInput = createSelector(
  [(state) => state?.programPricing ?? null],
  (programPricing) => programPricing
);

export const select = createSelector(
  [selectInput, pricingSelectors.selectResolvedLayers],
  (input, layers) => programPricingResolver.resolve(input, { layers })
);

export const { set, update, updatePathValue } = programPricingSlice.actions;

export default programPricingSlice;
