import BlankSlate from "./BlankSlate";
import Cumulative from "./Cumulative";
import SumTrianglesDialog from "./SumTrianglesDialog";
import Triangle from "./Triangle";
import Ultimate from "./Ultimate";
import TriangleIngestion from "./triangleIngestion/TriangleIngestion";
import { Stack, Typography } from "@mui/material";
import * as time from "common/time";
import Component from "components/Component";
import Button from "components/common/Button";
import EditableTitle from "components/common/EditableTitle";
import { InputCell } from "components/inputs/lossDevelopment/Cell";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as lossDevelopmentStore from "store/lossDevelopment";
import { MODELS } from "store/lossDevelopment";

const LossDevelopment = () => {
  const [showIngestionDialog, setShowIngestionDialog] = useState(false);
  const [showSumTrianglesDialog, setShowSumTrianglesDialog] = useState(false);

  const lossDevelopment = useSelector(
    lossDevelopmentStore.selectLossDevelopment
  );

  const numberTriangles = Object.keys(lossDevelopment?.triangles ?? {}).length;
  const hasData = !!numberTriangles;
  const hasMultipleTriangles = numberTriangles > 1;

  const dispatch = useDispatch();

  const onSetTriangle = useCallback(
    async (artifact) => {
      dispatch(
        lossDevelopmentStore.addLossDevelopmentTriangle({
          name: "Loss Development",
          lossesArtifactId: artifact?.artifactId,
          losses: artifact?.output?.losses,
        })
      );
      await time.sleep(1500);
      setShowIngestionDialog(false);
    },
    [dispatch, setShowIngestionDialog]
  );

  const onSumTriangles = useCallback(
    async (ids) => {
      dispatch(
        lossDevelopmentStore.sumLossDevelopmentTriangles({
          ids,
        })
      );
      setShowSumTrianglesDialog(false);
    },
    [dispatch]
  );

  const updateLossDevelopmentTriangle = useCallback(
    ({ id, payload }) => {
      dispatch(
        lossDevelopmentStore.updateLossDevelopmentTriangle({
          id,
          triangle: payload,
        })
      );
    },
    [dispatch]
  );

  const updateInitialExpectedLossRatio = useCallback(
    (value) => {
      dispatch(lossDevelopmentStore.updateInitialExpectedLossRatio(value));
    },
    [dispatch]
  );

  return (
    <>
      <Component
        title={"Triangles"}
        options={
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Typography>{"Initial Expected Loss Ratio"}</Typography>
            <InputCell
              value={lossDevelopment?.initialExpectedLossRatio}
              onChangeNumber={updateInitialExpectedLossRatio}
              percentage
              padding={"0"}
            />
            <Button
              size={"small"}
              onClick={() => setShowSumTrianglesDialog(true)}
              isDisabled={!hasMultipleTriangles}
            >
              {"Sum Triangles"}
            </Button>
            <Button size={"small"} onClick={() => setShowIngestionDialog(true)}>
              {"Load File…"}
            </Button>
          </Stack>
        }
      >
        {!hasData && <BlankSlate />}
        {showIngestionDialog && (
          <TriangleIngestion
            onClose={() => setShowIngestionDialog(false)}
            onSetTriangle={onSetTriangle}
          />
        )}
        {showSumTrianglesDialog && (
          <SumTrianglesDialog
            onClose={() => setShowSumTrianglesDialog(false)}
            onSumTriangles={onSumTriangles}
            triangles={lossDevelopment?.triangles}
          />
        )}
      </Component>
      {Object.keys(lossDevelopment?.triangles ?? {}).map((id) => (
        <Component
          titleComponent={
            <EditableTitle
              id={id}
              value={lossDevelopment?.triangles?.[id]?.name}
              onSubmit={(value) => {
                updateLossDevelopmentTriangle({ id, payload: { name: value } });
              }}
            />
          }
          key={id}
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack sx={{ overflowX: "auto" }}>
              <Triangle id={id} />
              <Cumulative id={id} />
            </Stack>
            <Stack direction={"row"}>
              {MODELS.map(({ name: label, method }) => (
                <Ultimate id={id} label={`${label} Ultimate`} method={method} />
              ))}
            </Stack>
          </Stack>
        </Component>
      ))}
    </>
  );
};

export default LossDevelopment;
