import DefaultLoader from "./DefaultLoader";
import React from "react";

const BigLoader = () => (
  <DefaultLoader
    color={"#dc7f4c"}
    style={{
      width: "200px",
      height: "200px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "auto",
      marginRight: "auto",
    }}
    size={50}
  />
);

export default BigLoader;
