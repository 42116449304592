import Mapper from "components/common/Mapper";
import { connect } from "react-redux";
import * as actions from "store/actions/input/exposure/hazardActions";
import * as selectors from "store/selectors/input/exposure/exposureSelectors";

const mapDispatchToProps = {
  mappingUpdated: actions.mapping.updated,
};

const mapStateToProps = (state) => ({
  targets: [
    { key: "_SIC", display: "SIC" },
    { key: "_PREMIUM", display: "Premium" },
    { key: "_STATE", display: "State" },
  ],
  sources: selectors.getColumns(state),
  mapping: selectors.getColumnMapping(state),
  suggested: selectors.getColumnMappingSuggestions(state),
  isTransposed: false,
});

export default connect(mapStateToProps, mapDispatchToProps)(Mapper);
