import { tickFormatter } from "../../../chartUtils";
import { getTicks } from "../../../utils";

export const getMarks = (width, max, min) => {
  if (!min) min = max / 1000;
  const ticks = getTicks(max, min);
  const markRatio = width / 40 / ticks.length;
  const cnt = markRatio === 0 ? ticks.length : ~~(1 / markRatio) + 1;
  const markOrBlank = (value, index) => {
    return index === 0 || (index - 1) % cnt === 0 ? value : "";
  };
  const marks = ticks.map((value, index) => ({
    value: index,
    label: markOrBlank(tickFormatter(value), index),
  }));
  return { ticks, marks };
};
