import * as utils from "../../../utils";
import * as types from "./searchActionTypes";
import * as actions from "./searchActions";
import * as Sentry from "@sentry/react";

export const updateSearch = (meta, searchTerm) => (dispatch, getState) => {
  dispatch(actions.searchDispatched(meta, searchTerm));
  utils
    .authenticatedFetch(
      meta.endpointStem +
        meta.endpoint +
        "?" +
        new URLSearchParams({ q: searchTerm })
    )
    .then((result) => result.json())
    .then((data) => {
      const responseAction = actions.receivedSearchResponse(
        meta.key,
        searchTerm,
        data
      );
      dispatch(responseAction);
    })
    .catch((error) => {
      Sentry.captureException(error);
      dispatch(actions.searchFailed(meta.key, searchTerm));
    });
};

export const searchDispatched = (meta, searchTerm) => ({
  type: types.SEARCH_DISPATCHED,
  payload: {
    searchType: meta.key,
    searchTerm: searchTerm,
  },
});

export const receivedSearchResponse = (searchType, searchTerm, results) => ({
  type: types.SEARCH_RECEIVED,
  payload: {
    searchTerm: searchTerm,
    searchType: searchType,
    results: results,
  },
});

export const searchFailed = (searchType, searchTerm) => ({
  type: types.SEARCH_FAILED,
  payload: {
    searchTerm: searchTerm,
    searchType: searchType,
  },
});

export const selectSearchValue = (searchType, selectedValue) => ({
  type: types.SELECT_SEARCH_VALUE,
  payload: {
    selectedValue: selectedValue,
    searchType: searchType,
  },
});

export const searchCancelled = (searchType) => ({
  type: types.SEARCH_CANCELLED,
  payload: {
    searchType: searchType,
  },
});
