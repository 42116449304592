import TransferCard from "./TransferCard";
import { Grid, Typography } from "@mui/material";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import * as config from "config";
import { useSelector } from "react-redux";
import * as exchangeService from "services/exchangeService";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const Share = () => {
  const {
    data: exchangeConfig,
    isLoading: isExchangeConfigLoading,
  } = exchangeService.useClientConfigQuery();

  const submissionId = useSelector(persistenceSelectors.selectSubmissionId);

  return (
    <>
      <Component
        title={"Share"}
        subtitle={"Send this submission to others in the marketplace."}
      >
        {isExchangeConfigLoading ? (
          <BigLoader />
        ) : !exchangeConfig?.recipients?.length ? (
          <Typography>
            {`Sharing is disabled. Please contact ${config.SUPPORT_EMAIL} to enable it.`}
          </Typography>
        ) : !submissionId ? (
          <Typography>
            {"Cannot share an unsaved submission. Press Save first."}
          </Typography>
        ) : (
          <Grid container spacing={config.GRID_SPACING}>
            {exchangeConfig.recipients.map((recipient) => (
              <Grid item xs={12} key={recipient.name}>
                <TransferCard
                  senderId={exchangeConfig.participantId}
                  recipient={recipient}
                  submissionId={submissionId}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Component>
    </>
  );
};

export default Share;
