import * as errors from "../../../common/errors";
import * as types from "./errorActionTypes";
import * as Sentry from "@sentry/react";

export const dismissError = (index) => ({
  type: types.DISMISS_ERROR,
  payload: index,
});
export const dismissAllErrors = () => ({
  type: types.DISMISS_ALL_ERRORS,
});
export const hideErrors = () => ({
  type: types.HIDE_ERRORS,
});
export const showErrors = () => ({
  type: types.SHOW_ERRORS,
});

export const fileOverwritten = (email, written_date) => {
  return {
    type: types.NEW_ERROR,
    payload: {
      type: "FILE_OVERWRITTEN",
      title: "Submission Overwritten",
      message: `The data just saved overwrites a previous
                save by ${email} on ${written_date}`,
      overwrite_email: email,
      overwrite_date: written_date,
      warning: true,
      date: new Date().toISOString(),
    },
  };
};

export const handleError = (error, meta) => {
  const handledError = errors.createError(error, { ...meta });
  Sentry.captureException(handledError, {
    tags: handledError.tags,
  });
  return {
    type: types.NEW_ERROR,
    payload: handledError,
  };
};
