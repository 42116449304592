import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMenuIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import FiltersIcon from "@mui/icons-material/FilterListRounded";
import {
  Box,
  Button,
  Collapse,
  Fade,
  IconButton as MuiIconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "components/common/IconButton";
import InlineSearch from "components/common/InlineSearch";
import ReviewStatusBadge from "components/common/ReviewStatusBadge";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import { useState } from "react";

const statusConfig = (value) => {
  if (value.length === 1) {
    return {
      status: value[0],
      meta: null,
    };
  } else if (value.length > 1) {
    return {
      status: "MULTIPLE",
      meta: { icon: "WILDCARD", displayName: "Multiple" },
    };
  } else {
    return {
      status: "ANY_STATUS",
      meta: { icon: "WILDCARD", displayName: "Any Status" },
    };
  }
};

const StatusButton = ({ value, onClick }) => {
  const config = statusConfig(value);
  return (
    <Stack className={"select-button"}>
      <Typography variant={"caption"} style={{ marginBottom: "2px" }}>
        {"Status"}
      </Typography>
      <Button variant={"outlined"} color={"secondary"} onClick={onClick}>
        <StatusBadgeV2 status={config.status} meta={config.meta} />
        <ExpandMenuIcon className={"expand-icon"} />
      </Button>
    </Stack>
  );
};

const severityConfig = [
  { value: 4, label: "Max", colour: "red" },
  { value: 3, label: "High", colour: "orange" },
  { value: 2, label: "Med", colour: "gold" },
  { value: 1, label: "Low", colour: "deepskyblue" },
];

const SeverityBadge = ({ value }) => {
  const config =
    value.length === 1
      ? severityConfig.filter((x) => x.value === value[0])[0]
      : value.length > 1
      ? { label: "Multiple", colour: "gainsboro" }
      : { label: "Any Level", colour: "gainsboro" };

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
      <CircleIcon fontSize={"small"} sx={{ color: config.colour }} />
      <Typography
        variant={"caption"}
        sx={{ fontWeight: "600", whiteSpace: "nowrap" }}
      >
        {config.label}
      </Typography>
    </Stack>
  );
};

const SeverityButton = ({ value, onClick }) => (
  <Stack className={"select-button"}>
    <Typography variant={"caption"} style={{ marginBottom: "2px" }}>
      {"Risk Level"}
    </Typography>
    <Button variant={"outlined"} color={"secondary"} onClick={onClick}>
      <SeverityBadge value={value} />
      <ExpandMenuIcon className={"expand-icon"} />
    </Button>
  </Stack>
);

const ReviewStatusButton = ({ value, onClick }) => {
  const status = value.length === 1 ? value[0] : null;
  const label =
    value.length > 1 ? "Multiple" : value.length === 0 ? "Any Status" : null;

  return (
    <Stack className={"select-button"}>
      <Typography variant={"caption"} style={{ marginBottom: "2px" }}>
        {"Status"}
      </Typography>
      <Button variant={"outlined"} color={"secondary"} onClick={onClick}>
        <ReviewStatusBadge status={status} label={label} />
        <ExpandMenuIcon className={"expand-icon"} />
      </Button>
    </Stack>
  );
};

const SelectButton = ({ item, onClick }) => {
  const label =
    item.value.length === 0
      ? `Any ${item.context}`
      : item.value
          .map((x) =>
            typeof item.formatter === "function"
              ? item.formatter(x)
              : x === ""
              ? "[Blank]"
              : x
          )
          .join(", ");

  return (
    <Stack className={"select-button"}>
      <Typography variant={"caption"} style={{ marginBottom: "2px" }}>
        {item.context}
      </Typography>
      <Button
        variant={"outlined"}
        color={"secondary"}
        onClick={onClick}
        sx={{ maxWidth: "18rem" }}
      >
        <Typography
          variant={"caption"}
          sx={{
            fontWeight: "600",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {label}
        </Typography>
        <ExpandMenuIcon className={"expand-icon"} />
      </Button>
    </Stack>
  );
};

const Filters = ({ values, onClick, onChangeValue }) => {
  const hasOneOrMoreValues = values.some(
    (item) => (item.value ?? []).join() !== (item.default ?? []).join()
  );

  const clear = () => {
    const result = values.map((item) => {
      return { ...item, value: item.default };
    });
    onChangeValue(result);
  };

  return (
    <Box
      sx={{ background: "#edf0f3", padding: "1rem", borderRadius: "0.625rem" }}
    >
      <Stack direction={"row"} spacing={1}>
        {values.map((item) => {
          if (item.context === "Status") {
            return (
              <StatusButton
                key={item.context}
                value={item.value}
                onClick={() => onClick(item.context)}
              />
            );
          } else if (item.context === "Severity") {
            return (
              <SeverityButton
                key={item.context}
                value={item.value}
                onClick={() => onClick(item.context)}
              />
            );
          } else if (item.context === "Review Status") {
            return (
              <ReviewStatusButton
                key={item.context}
                value={item.value}
                onClick={() => onClick(item.context)}
              />
            );
          } else {
            return (
              <SelectButton
                key={item.context}
                item={item}
                onClick={() => onClick(item.context)}
              />
            );
          }
        })}
        <Fade in={hasOneOrMoreValues} style={{ alignSelf: "flex-end" }}>
          <span>
            <IconButton
              variant={"bright"}
              icon={DeleteIcon}
              tooltip={"Clear all filters"}
              onClick={() => clear()}
            />
          </span>
        </Fade>
      </Stack>
    </Box>
  );
};

const FilterBar = ({
  filters,
  onChangeSearchInput,
  onChangeFilters,
  onClickFilter,
  options,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const hasFilters = filters.some(
    (item) => (item.value ?? []).join() !== (item.default ?? []).join()
  );

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        marginBottom={0.75}
      >
        <Stack direction={"row"} alignItems={"baseline"} spacing={1}>
          <InlineSearch
            placeholder={"Quick Search"}
            onChange={(searchText) => onChangeSearchInput(searchText)}
            disabled={true}
          />
          <Tooltip title={"Additional Filters"}>
            <MuiIconButton
              onClick={(_) => setFiltersOpen(!filtersOpen)}
              sx={{ position: "relative", padding: "5px" }}
            >
              <FiltersIcon />
              <Fade in={hasFilters}>
                <Box
                  sx={{
                    position: "absolute",
                    right: "4px",
                    top: "7px",
                    width: "8px",
                    height: "8px",
                    borderRadius: "4px",
                    border: "1px solid white",
                    background: "#fa7b35",
                  }}
                />
              </Fade>
            </MuiIconButton>
          </Tooltip>
        </Stack>
        {options ? options : <></>}
      </Stack>

      <Collapse in={filtersOpen}>
        <Filters
          values={filters}
          onChangeValue={onChangeFilters}
          onClick={onClickFilter}
        />
      </Collapse>
    </>
  );
};

export default FilterBar;
