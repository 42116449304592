import { useEffect, useState } from "react";

const useDebouncedValue = (value, { delayMs = 250 } = {}) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => setDebouncedValue(value), delayMs);
    return () => clearTimeout(timeoutId);
  }, [value, delayMs]);

  return debouncedValue;
};

export default useDebouncedValue;
