import BetaBadge from "components/common/BetaBadge";

const ClaimsBetaBadge = ({ variant }) => {
  const description =
    "We've overhauled Marmalade's claims import feature so it can handle larger files, automatically fix more errors, and is easier to use.";

  return (
    <BetaBadge
      flag={"ui/useClaimsV2Frontend"}
      name={"Claims Import"}
      description={description}
      variant={variant}
    />
  );
};

export default ClaimsBetaBadge;
