import SmallLoader from "./SmallLoader";
import { ExpandMoreRounded as DropdownIcon } from "@mui/icons-material";
import { Chip, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import { useState } from "react";

const OPEN_CLOSE_STATES = {
  NOT_SET: "",
  OPEN: "O",
  CLOSED: "C",
};

const StatusMenu = ({
  anchorEl,
  closeMenu,
  status,
  hasOverride,
  onChange,
  onRemoveOverride,
}) => {
  const menuItems = [
    { key: OPEN_CLOSE_STATES.NOT_SET, label: <>&nbsp;</> },
    { key: OPEN_CLOSE_STATES.OPEN, label: "Open" },
    { key: OPEN_CLOSE_STATES.CLOSED, label: "Closed" },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl != null}
      onClose={(_, reason) => {
        if (reason === "backdropClick") closeMenu();
      }}
    >
      {hasOverride && (
        <MenuItem
          onClick={() => {
            onRemoveOverride?.();
            closeMenu();
          }}
        >
          {"[Remove Override]"}
        </MenuItem>
      )}
      {menuItems.map((item) => {
        return (
          <MenuItem
            key={item.key}
            selected={item.key === status}
            onClick={() => {
              if (item.key !== status) onChange(item.key);
              closeMenu();
            }}
          >
            {item.label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

/**
 *
 * @param {string} status - The original claim status mapped at file ingestion
 * @param {string} override - Manual status, note empty string is used to indicate "Not Set"
 * @param {function} onChange - Component includes dropdown when function present, called with new status on change
 * @param {function} onRemoveOverride - Called when the user triggers for the status to be cleared (ie any overrides removed)
 *
 */
const OpenClosedBadge = ({
  status,
  reportedStatus,
  overriddenStatus,
  onChange,
  onRemoveOverride,
}) => {
  const activeStatus = status ?? OPEN_CLOSE_STATES.NOT_SET;
  const open = activeStatus === OPEN_CLOSE_STATES.OPEN;
  const notSet = activeStatus === OPEN_CLOSE_STATES.NOT_SET;
  const editable = typeof onChange === "function";

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const label = (openClosed) =>
    openClosed === OPEN_CLOSE_STATES.OPEN
      ? "Open"
      : openClosed === OPEN_CLOSE_STATES.CLOSED
      ? "Closed"
      : "Not Set";

  const hasOverride = overriddenStatus !== undefined;
  const isWaitingOnRefresh =
    (hasOverride && status !== overriddenStatus) ||
    (!hasOverride && reportedStatus !== undefined && reportedStatus !== status);

  return (
    <Tooltip
      placement={"right"}
      title={
        hasOverride && anchorEl == null
          ? `This status has been overridden, original value '${label(
              reportedStatus
            )}'`
          : null
      }
    >
      {editable ? (
        <span>
          <Chip
            label={
              <Stack direction={"row"} alignItems={"center"}>
                {isWaitingOnRefresh ? (
                  <SmallLoader color={open ? "white" : null} />
                ) : (
                  <>
                    {label(activeStatus)}
                    {hasOverride ? "*" : ""}
                  </>
                )}
                <DropdownIcon fontSize={"small"} />
              </Stack>
            }
            variant={notSet ? "outlined" : "filled"}
            color={open ? "primary" : "default"}
            sx={{
              color: open ? "white" : null,
              ".MuiChip-label": {
                paddingRight: "3px",
              },
            }}
            size={"small"}
            onClick={openMenu}
            clickable
          />
          <StatusMenu
            anchorEl={anchorEl}
            closeMenu={closeMenu}
            status={activeStatus}
            hasOverride={hasOverride}
            onChange={onChange}
            onRemoveOverride={onRemoveOverride}
          />
        </span>
      ) : (
        <Chip
          label={`${label(activeStatus)}${hasOverride ? "*" : ""}`}
          variant={notSet ? "outlined" : "filled"}
          color={open ? "primary" : "default"}
          sx={{ color: open ? "white" : null }}
          size={"small"}
        />
      )}
    </Tooltip>
  );
};

export default OpenClosedBadge;
