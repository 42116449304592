export const getExposureTrendData = (exposures, data) => {
  const selectedExposures = exposures.map((x) => x.key);
  const exposureNames = Object.fromEntries(
    exposures.map((x) => [x.key, x.name])
  );
  const exposureData = {};
  const years = Object.keys(data).sort((a, b) => a - b);

  years.forEach((year) => {
    const yearData = data[year];
    Object.entries(yearData).forEach(([key, val]) => {
      if (selectedExposures.includes(key) && val != null) {
        if (exposureData[key] == null) {
          const name = exposureNames[key];
          exposureData[key] = { key: key, name: name, values: [] };
        }
        exposureData[key].values.push(val);
      }
    });
  });

  return Object.values(exposureData).map((x) => x);
};

export const getExposureChartData = (data) =>
  Object.entries(data).map(([k, v]) => {
    return { year: k, ...v };
  });

const cleanNumber = (str) => {
  return str.replace(/[$,\s]/g, "").trim();
};

export const tryParse1dArray = (text) => {
  if (!text || typeof text !== "string") {
    return null;
  }

  text = text.trim();

  const hasTab = text.includes("\t");
  const hasNewline = text.includes("\n");
  if (hasTab && hasNewline) {
    return null;
  }

  if (hasTab) {
    const items = text
      .split("\t")
      .map((item) => cleanNumber(item))
      .filter((item) => item !== "");
    return items.length > 0 ? items : null;
  }

  if (hasNewline) {
    const items = text
      .split(/\r?\n/)
      .map((item) => cleanNumber(item))
      .filter((item) => item !== "");
    return items.length > 0 ? items : null;
  }

  text = cleanNumber(text);
  return text !== "" ? [text] : null;
};
