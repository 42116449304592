import * as support from "./MetricsComparison.support";
import CloseIcon from "@mui/icons-material/Close";
import ResizeIcon from "@mui/icons-material/LaunchRounded";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import * as chartUtils from "chartUtils";
import * as numbers from "common/numbers";
import IconButton from "components/common/IconButton";
import TrendCard from "components/common/TrendCard";
import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const chartHeightPixels = 520;

const MainChartContainer = ({
  children,
  minimised,
  onResize,
  isRebased,
  setIsRebased,
}) => {
  if (minimised) {
    const sx = {
      position: "absolute",
      display: "flex",
      bottom: "1rem",
      left: "1rem",
      borderRadius: "4px",
      padding: "6px 8px",
      cursor: "pointer",
      zIndex: 99,
      background: "white",
      boxShadow: "0 1px 6px rgba(10,10,10,0.2)",
      "&:hover": {
        background: "#fafafa",
      },
    };

    return (
      <Box
        title={"Show Chart"}
        sx={sx}
        onClick={() => onResize && onResize(false)}
      >
        <ResizeIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
      </Box>
    );
  } else {
    const sx = {
      position: "relative",
      display: "flex",
      margin: "8px 2px 4px 2px",
      padding: "6px 12px 8px 0px",
      background: "white",
      borderRadius: "8px",
      textAlign: "center",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid gainsboro",
    };

    return (
      <Box height={chartHeightPixels} sx={sx}>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          spacing={2}
          sx={{
            position: "absolute",
            top: "8px",
            right: "8px",
            zIndex: 99,
            color: "primary.main",
          }}
        >
          {setIsRebased && (
            <FormControl>
              <RadioGroup
                row
                value={isRebased ? "REBASED" : "ORIGINAL"}
                onChange={(e) => setIsRebased(e.target.value === "REBASED")}
              >
                <FormControlLabel
                  value={"ORIGINAL"}
                  control={<Radio />}
                  label={"Original"}
                  size={"small"}
                />
                <FormControlLabel
                  value={"REBASED"}
                  control={<Radio />}
                  label={"Rebased"}
                  size={"small"}
                />
              </RadioGroup>
            </FormControl>
          )}
          <Button
            size={"small"}
            sx={{
              color: "primary.main",
            }}
            endIcon={
              <ResizeIcon
                sx={{ transform: "rotate(180deg)" }}
                fontSize={"small"}
              />
            }
            onClick={() => onResize && onResize(true)}
          >
            {"Hide Chart"}
          </Button>
        </Stack>
        {children}
      </Box>
    );
  }
};

const formatPercentage = (x) =>
  x == null ? "" : `${numbers.dpString(1)(100 * x)}%`;

const MainChart = ({
  data,
  selectedMetrics,
  minimised,
  onMinimise,
  onMaximise,
}) => {
  const [isRebased, setIsRebased] = useState(true);

  const scaledData = isRebased
    ? support.rebase(data, { selectedMetrics })
    : data;

  // When multiple metrics are selected, default to rebased.
  useEffect(() => {
    if (selectedMetrics?.length > 1) {
      setIsRebased(true);
    } else {
      setIsRebased(false);
    }
  }, [selectedMetrics, setIsRebased]);

  if (selectedMetrics.length === 0) {
    return (
      <MainChartContainer
        minimised={minimised}
        onResize={(shouldMinimise) => {
          shouldMinimise ? onMinimise() : onMaximise();
        }}
      >
        <Typography>{"Choose up to three metrics to compare"}</Typography>
      </MainChartContainer>
    );
  } else {
    return (
      <MainChartContainer
        minimised={minimised}
        onResize={(shouldMinimise) => {
          shouldMinimise ? onMinimise() : onMaximise();
        }}
        isRebased={isRebased}
        setIsRebased={setIsRebased}
      >
        <ResponsiveContainer>
          <LineChart data={scaledData} height={chartHeightPixels}>
            {selectedMetrics.map((metric) => (
              <Line
                key={metric.key}
                dataKey={metric.key}
                name={metric.name}
                type={"monotone"}
                stroke={metric.colour}
                strokeWidth={3}
                legendType={"circle"}
                isAnimationActive={true}
                animationDuration={350}
              ></Line>
            ))}
            <Tooltip
              formatter={
                isRebased ? formatPercentage : (value) => value.toLocaleString()
              }
              isAnimationActive={false}
            />
            <Legend
              align={"left"}
              verticalAlign={"top"}
              wrapperStyle={{ marginLeft: "60px", paddingBottom: "12px" }}
            />
            <CartesianGrid strokeDasharray={"3 3"} />
            <XAxis dataKey={"year"} />
            <YAxis
              tickFormatter={
                isRebased ? formatPercentage : chartUtils.tickFormatter
              }
            />
            {isRebased && <ReferenceLine y={1} />}
          </LineChart>
        </ResponsiveContainer>
      </MainChartContainer>
    );
  }
};

const Charts = ({ trendData, chartData }) => {
  const [mainChartMinimised, setMainChartMinimised] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [palette, setPalette] = useState([
    "darkorange",
    "orangered",
    "#a45a31",
  ]);

  const toggleSelection = (key, name) => {
    const selected = [...selectedMetrics];
    const colours = [...palette];
    const idx = selected.findIndex((x) => x.key === key);

    if (idx === -1) {
      if (selected.length === 3) {
        colours.push(selected[2].colour);
        selected.pop();
      }
      selected.push({ key: key, name: name, colour: colours.shift() });
    } else {
      colours.push(selected[idx].colour);
      selected.splice(idx, 1);
    }

    setSelectedMetrics(selected);
    setPalette(colours);
  };

  return (
    <>
      <Stack
        direction={"row"}
        flexWrap={mainChartMinimised ? "wrap" : null}
        gap={mainChartMinimised ? 1 : null}
        spacing={mainChartMinimised ? null : 1}
        sx={{ padding: "2px", overflowY: mainChartMinimised ? null : "scroll" }}
      >
        {trendData.map((data) => {
          const idx = selectedMetrics.findIndex((x) => x.key === data.key);
          const borderColour = idx === -1 ? null : selectedMetrics[idx].colour;
          return (
            <TrendCard
              key={data.key}
              data={data}
              borderColour={borderColour}
              onClick={(key, name) => toggleSelection(key, name)}
            />
          );
        })}
      </Stack>

      <MainChart
        data={chartData}
        selectedMetrics={selectedMetrics}
        minimised={mainChartMinimised}
        onMinimise={() => setMainChartMinimised(true)}
        onMaximise={() => setMainChartMinimised(false)}
      />
    </>
  );
};

const BlankSlate = () => (
  <MainChartContainer>
    <Typography>
      {"Sorry, there don't appear to be any metrics to compare"}
    </Typography>
  </MainChartContainer>
);

const CloseButton = ({ onClose }) => {
  const sx = {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    zIndex: 99,
    background: "white",
    boxShadow: "0 1px 6px rgba(10,10,10,0.2)",
    "&:hover": { background: "#fafafa" },
  };

  return <IconButton icon={CloseIcon} onClick={onClose} sx={sx} />;
};

const MetricsComparison = ({ open, trendData, chartData, onClose }) => {
  const hasData = trendData.length > 0;

  return (
    <Dialog open={open} fullWidth maxWidth={"xl"}>
      <DialogContent sx={{ padding: "18px" }}>
        <CloseButton onClose={onClose} />
        {hasData ? (
          <Charts trendData={trendData} chartData={chartData} />
        ) : (
          <BlankSlate />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MetricsComparison;
