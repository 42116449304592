import BigLoader from "../BigLoader";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import React from "react";

export const UploadValidation = ({ transformStatus, transformError }) => {
  switch (transformStatus) {
    case "fulfilled":
    case "SUCCEEDED":
      return (
        <CheckCircleOutlineIcon
          color={"success"}
          sx={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      );
    case "rejected":
    case "FAILED":
      return (
        <Grid container>
          <Grid item sm={12}>
            <Alert severity={"error"}>
              {"Unable to load the file."}
              <br />
              <br />
              <div style={{ whiteSpace: "pre" }}>{transformError}</div>
            </Alert>
          </Grid>
        </Grid>
      );
    default:
      return <BigLoader />;
  }
};

export default UploadValidation;
