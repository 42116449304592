import * as attachmentsSelectors from "store/selectors/input/attachments/attachmentsSelectors";

export const treeIsLoaded = (state, fileId) => {
  const trees = state.temp?.attachments?.attachmentFileTrees || {};
  const tree = trees[fileId];
  if (!tree || tree.status === "LOADING") return false;
  // The tree is a tree of nodes where a node is
  // {
  // meta: FileMetaData,
  // child_metas: List[node]
  // }
  const ends = [
    "LOADED",
    "ERRORED",
    "NOT_VALID",
    "NOT_AVAILABLE",
    "TEXT_FILE",
    "CONVERSION_FAILED",
    "NOT_READABLE",
  ];
  const isMetaFinished = (meta) => {
    return (
      meta &&
      ends.includes(meta.status) &&
      ends.includes(meta.expansionStatus) &&
      ends.includes(meta.htmlStatus)
    );
  };
  const isComplete = (tree_entry) => {
    if (!tree_entry.meta) return false;
    if (!isMetaFinished(tree_entry.meta)) return false;
    for (const ind in tree_entry.childMetas) {
      if (!isComplete(tree_entry.childMetas[ind])) return false;
    }
    return true;
  };
  return isComplete(tree);
};

export const attachedFileTrees = (state) => {
  return state.temp?.attachments?.attachmentFileTrees || {};
};

export const embeddedFiles = (state, fileId) => {
  const findFile = (entry) => {
    if (entry.meta?.id === fileId) return entry;
    const children = entry.childMetas || [];
    for (const ind in children) {
      const resp = findFile(children[ind]);
      if (resp) return resp;
    }
    return null;
  };
  const findRoot = () => {
    const fileTrees = state?.temp?.attachments?.attachmentFileTrees || {};
    for (const id in fileTrees) {
      const posRoot = findFile(fileTrees[id]);
      if (posRoot) return posRoot;
    }
    return null;
  };
  const root = findRoot(state) || {};
  const getEmbedded = (entry) => {
    if (!entry?.meta) return [];
    const res = [entry.meta.id];
    const children = entry.childMetas || [];
    for (const x in children)
      getEmbedded(children[x]).forEach((id) => res.push(id));
    return res;
  };
  const res = getEmbedded(root);
  const x = res && res.length > 0 ? res.slice(1) : [];
  return x;
};
/* return a flattened list of {id: fileId, name: filename}
 * Hidden files are not included, files with orverridden
 * names have those names with the original extension if
 * no extension is present if a file is present with two names both entries are included*/
export const attachedFilesFlattened = (state) => {
  const names = attachmentsSelectors.getFlattenedFileNameOverrides(state);
  const hidden = attachmentsSelectors.getFlattenedHiddenFiles(state);
  const res = [];
  const add = (entry) => {
    if (entry?.meta) {
      const id = entry.meta.id;
      if (!hidden[id]) {
        const getName = () => {
          const name = names[id];
          if (!name) return entry.meta.originalFilename;
          const ind = name.lastIndexOf(".");
          if (ind > 0 && ind > name.length - 10 && ind < name.length - 1) {
            return name;
          }
          return name + "." + entry.meta.originalExtension;
        };
        res.push({ id, name: getName() });
        if (entry.childMetas) {
          entry.childMetas.forEach((c) => add(c));
        }
      }
    }
  };
  Object.values(
    state.temp?.attachments?.attachmentFileTrees || {}
  ).forEach((e) => add(e));
  return res;
};

export const isSelectingAttachments = (state) =>
  state.temp.attachments?.selectingAttachment ?? false;
