import { createSelector } from "reselect";
import { selectUnderwriterList } from "store/selectors/temp/staticData/staticDataSelectors";

export const selectRawSubmissions = (state) =>
  state.temp?.review?.submissions || [];

export const selectSubmissions = createSelector(
  [selectRawSubmissions],
  (submissions) =>
    [...submissions].sort((a, b) => {
      if (a.inception < b.inception) {
        return 1;
      } else if (a.inception > b.inception) {
        return -1;
      } else if (a.insured < b.insured) {
        return -1;
      } else if (a.insured > b.insured) {
        return 1;
      } else {
        return 0;
      }
    })
);

export const getPeerReviewer = (state) => {
  const peerReviewer = state.summary?.peerReview?.peerReviewer;
  if (!peerReviewer) return {};
  if (typeof peerReviewer === "string") {
    const underwriterList = selectUnderwriterList(state);
    if (!underwriterList) return { name: peerReviewer };
    for (const ind in underwriterList) {
      const potReviewer = underwriterList[ind];
      if (potReviewer.name === peerReviewer) return potReviewer;
    }
    return { name: peerReviewer };
  }
  return peerReviewer;
};
