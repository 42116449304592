import { Grid, TextField, Typography } from "@mui/material";
import * as numbers from "common/numbers";
import Component from "components/Component";
import * as config from "config";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";

const DisabledTextField = ({ label, value, formatter }) => (
  <TextField
    type={"text"}
    className={"rate-change-summary"}
    InputLabelProps={{ shrink: true }}
    InputProps={{ spellCheck: "false" }}
    label={label}
    autoComplete={"off"}
    variant={"filled"}
    value={formatter(value)}
    disabled
    fullWidth
  />
);

const summaryFormatter = (expiringPremium) => (value) => {
  if (value == null) {
    return "N/A";
  }
  const formatter = numbers.dpString(0);
  const percentFormatter = numbers.dpString(1, {
    suffix: "%",
  });
  if (expiringPremium == null) {
    return formatter(value);
  }
  return `${formatter(value)} (${percentFormatter(
    (value * 100) / expiringPremium
  )})`;
};

const OldSummaryTable = ({ activeLayerRateChange, isLoading }) => {
  const calculated = activeLayerRateChange.chartData;

  const extractComponent = (key) =>
    (calculated?.decomposition ?? []).filter((x) => x.key === key)?.[0] ?? null;

  const startPremium = extractComponent("start")?.value;
  const endPremium = extractComponent("end")?.value;
  const pureRateChange = extractComponent("pure")?.delta;
  const cashRateChange =
    startPremium != null && endPremium != null
      ? endPremium - startPremium
      : null;
  const otherRateChange = cashRateChange - pureRateChange;

  return (
    <Component title={"Summary"} loading={isLoading}>
      <Grid container spacing={config.GRID_SPACING} columns={14}>
        <Grid item xs={13} md={4}>
          <DisabledTextField
            label={"Total"}
            formatter={summaryFormatter(startPremium)}
            value={pureRateChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align={"center"}>{"="}</Typography>
        </Grid>
        <Grid item xs={13} md={4}>
          <DisabledTextField
            label={"Base Rate/ Base Premium"}
            formatter={summaryFormatter(startPremium)}
            value={cashRateChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography align={"center"}>{"+"}</Typography>
        </Grid>
        <Grid item xs={13} md={4}>
          <DisabledTextField
            formatter={summaryFormatter(startPremium)}
            label={"All Other-Exp/Terms/Conds"}
            value={0 - otherRateChange}
          />
        </Grid>
      </Grid>
    </Component>
  );
};

const mapStateToProps = (state) => ({
  activeLayerRateChange: rateChangeSlice.selectActiveLayerRateChange(state),
});

export default connect(mapStateToProps)(OldSummaryTable);
