import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  const url = !!args?.directToExchange
    ? config.endpoints.exchange(args.url)
    : config.endpoints.exchangeProxy(args.url);

  try {
    const response = await utils.authenticatedFetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      ...args.payload,
    });
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

export const exchangeApi = createApi({
  reducerPath: "exchangeApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    clientConfig: builder.query({
      query: () => ({
        url: "retrieve-client-config",
        directToExchange: true,
      }),
    }),
    transfers: builder.query({
      query: ({ senderId, recipientId, senderSubmissionId }) => ({
        url: "list-transfers",
        directToExchange: true,
        payload: {
          body: JSON.stringify({ senderId, recipientId, senderSubmissionId }),
        },
      }),
    }),
    transferSubmission: builder.mutation({
      query: ({
        senderId,
        recipientId,
        senderSubmissionId,
        originalSubmission,
        view,
      }) => ({
        url: "transfer-submission-v2",
        directToExchange: true,
        payload: {
          body: JSON.stringify({
            senderId,
            recipientId,
            senderSubmissionId,
            originalSubmission,
            view,
          }),
        },
      }),
    }),
  }),
});

export const {
  useClientConfigQuery,
  useTransfersQuery,
  useTransferSubmissionMutation,
} = exchangeApi;
