import {
  CheckRounded as ConfirmIcon,
  CloseRounded as CancelIcon,
} from "@mui/icons-material";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import SmallLoader from "components/common/SmallLoader";
import { useState } from "react";

const InlineEditor = ({ comment }) => {
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [text, setText] = useState(comment.text);
  const hasText = (text || "").trim() !== "";
  const saveDisabled = loading || !hasText || text.trim() === comment.text;

  const updateComment = () => {
    // Call backend via RTK, probably obviates need for loading/failed state
    setFailed(false);
    setLoading(false);
  };

  return (
    <Stack sx={{ margin: "0.25rem 0 0.5rem 2.5rem" }}>
      <Stack flexDirection={"row"} gap={1}>
        <TextField
          value={text}
          fullWidth
          multiline
          size={"small"}
          sx={{ background: "white" }}
          disabled={loading}
          InputProps={{
            endAdornment: loading ? <SmallLoader /> : <></>,
          }}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (hasText && (e.key === "Enter") & !e.shiftKey) {
              e.preventDefault();
              updateComment();
            }
          }}
        />
        <IconButton sx={{ alignSelf: "flex-end" }} disabled={saveDisabled}>
          <ConfirmIcon />
        </IconButton>
        <IconButton sx={{ alignSelf: "flex-end" }} disabled={loading}>
          <CancelIcon />
        </IconButton>
      </Stack>
      {!loading && failed && (
        <Typography variant={"caption"} color={"error"} marginTop={0.25}>
          {
            "There was a problem saving changes to Marmalade, please try again in a moment"
          }
        </Typography>
      )}
    </Stack>
  );
};

export default InlineEditor;
