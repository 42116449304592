import { Alert } from "@mui/material";
import * as chartUtils from "chartUtils";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import React from "react";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const formatChart = (chart) => {
  if (chart == null) {
    return chart;
  }
  return {
    data: [
      ...chart.data.map(({ year, value, ultimate }) => ({
        name: year,
        value,
        ultimate: ultimate == null ? ultimate : ultimate - value,
      })),
    ],
    mean: chart.mean,
  };
};

const getYAxisDomain = (data, ultimateName) => {
  if (!data || !data.data || data.data.length === 0) return [0, "auto"];

  const maxValue = Math.max(
    ...data.data.map((item) => {
      const total = ultimateName
        ? (item.value || 0) + (item.ultimate || 0)
        : item.value || 0;
      return total;
    })
  );

  return [0, Math.ceil(maxValue * 1.2)];
};

const Chart = ({
  data,
  dataName,
  ultimateName,
  averageLabel,
  referenceStyles,
}) => {
  const chart = formatChart(data);
  const yAxisDomain = getYAxisDomain(chart, ultimateName);

  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  const customLegendText = (value) => {
    return <span style={{ color: "#45494e", fontSize: "10px" }}>{value}</span>;
  };

  const defaultAverageLabel = (mean) => {
    return `${ultimateName ? "Dev. " : ""}Avg: ${chartUtils.tickFormatter(
      ultimateName ? mean.ultimate : mean.value
    )}`;
  };

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
      }}
    >
      <ResponsiveContainer width={"100%"} height={"100%"}>
        <ComposedChart
          data={chart.data}
          margin={{
            top: 5,
            right: 45,
            left: 0,
            bottom: 5,
          }}
          ref={chartRef}
        >
          <CartesianGrid strokeDasharray={"3 3"} />
          <Tooltip
            position={tooltipPosition}
            isAnimationActive={false}
            formatter={chartUtils.formatToolTip}
            contentStyle={{
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              padding: "8px",
              fontSize: "11px",
              borderRadius: "4px",
              boxShadow: "2px 2px 6px rgba(0,0,0,0.1)",
            }}
          />
          <Legend
            formatter={customLegendText}
            wrapperStyle={{
              fontSize: "10px",
              paddingTop: "2px",
              paddingBottom: "2px",
              lineHeight: "14px",
            }}
            iconSize={8}
            align={"center"}
            verticalAlign={"bottom"}
          />
          <XAxis
            dataKey={"name"}
            tick={{ fontSize: 10 }}
            tickMargin={5}
            height={20}
          />
          <YAxis
            tickFormatter={chartUtils.tickFormatter}
            tick={{ fontSize: 10 }}
            width={45}
            tickMargin={2}
            domain={yAxisDomain}
            allowDataOverflow={false}
          />
          <Bar
            dataKey={"value"}
            name={dataName}
            stackId={"a"}
            fill={"#FFAB53"}
            barSize={16}
          />
          {ultimateName == null ? (
            <></>
          ) : (
            <Bar
              dataKey={"ultimate"}
              name={ultimateName}
              stackId={"a"}
              fill={"#FF634E"}
              barSize={16}
            />
          )}
          {chart.mean && (
            <ReferenceLine
              y={ultimateName ? chart.mean.ultimate : chart.mean.value}
              stroke={"#62BEE5"}
              label={{
                value: chart.mean,
                position: "insideBottomRight",
                formatter: averageLabel || defaultAverageLabel,
                style: {
                  ...referenceStyles,
                  fontSize: "12px",
                },
                offset: 2,
              }}
              strokeWidth={1.5}
              dot={false}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const ClaimsChart = ({
  title,
  subtitle,
  loading,
  error,
  chart,
  dataName,
  ultimateName,
  averageLabel,
  xs_width,
  styles,
  titleStyles,
  referenceStyles,
}) => {
  const defaultStyles = styles || {
    position: "relative",
    width: "100%",
    height: "300px",
    marginBottom: "8px",
  };

  return (
    <Component
      title={title}
      subtitle={subtitle}
      xs_width={xs_width || 12}
      sm_width={12}
      md_width={12}
      lg_width={4}
      titleStyles={titleStyles}
    >
      <div style={defaultStyles}>
        {loading ? (
          <BigLoader />
        ) : !!error ? (
          <Alert severity={"error"}>{error}</Alert>
        ) : !chart ? (
          <></>
        ) : (
          <Chart
            data={chart}
            dataName={dataName}
            ultimateName={ultimateName}
            averageLabel={averageLabel}
            referenceStyles={referenceStyles}
          />
        )}
      </div>
    </Component>
  );
};

export default ClaimsChart;
