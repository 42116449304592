import Notes from "components/common/Notes";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";

const mapDispatchToProps = (dispatch) => ({
  update: (note) =>
    dispatch(
      rateChangeSlice.updateLayerProperties({
        note: note,
      })
    ),
});

const mapStateToProps = (state) => {
  return {
    value: rateChangeSlice.selectActiveLayerRateChange(state)?.note,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
