import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#FA7B35",
      hover: "#A45A31",
      dark: "#A45A31",
      light: "#FCAE83",
      lighter: "#FDCEB4",
      lightest: "#FEEFE6",
    },
    secondary: {
      main: "#485159",
      hover: "#696D72",
      lightest: "#EDF0F3",
    },
  },
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontWeight: "bold",
        },
      },
    },
  },
});

export default theme;
