import { PricingAssumptionsTableSupport } from "./PricingAssumptionsTableSupport";
import LockOutlined from "@mui/icons-material/LockOutlined";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  TableFooter,
} from "@mui/material";
import Button from "components/common/Button";
import React, { useState } from "react";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const PricingAssumptionsTable = ({
  configs,
  defaults,
  values,
  setValue,
  editability,
}) => {
  const [newVals, setNewVals] = useState({});
  const support = new PricingAssumptionsTableSupport(
    configs,
    defaults,
    values,
    setValue,
    newVals,
    setNewVals
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{"Assumption"}</TableCell>
          <TableCell>{"Default value"}</TableCell>
          <TableCell>{"Current value"}</TableCell>
          <TableCell>{"New value"}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {configs.map((assumption) => (
          <TableRow key={assumption.code}>
            <TableCell>{assumption.name}</TableCell>
            <TableCell>{support.formatDefault(assumption)}</TableCell>
            <TableCell>{support.formatCurrent(assumption)}</TableCell>
            <TableCell>
              {editability[assumption.code] ? (
                <TextField
                  hiddenLabel
                  value={newVals[assumption.code] || ""}
                  variant={"filled"}
                  inputProps={{
                    placeholder: support.formatForInput(assumption, values),
                  }}
                  onChange={(e) => support.onChange(e, assumption)}
                />
              ) : (
                <LockOutlined />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow key={"buttons"} className={"pricing-assumptions-footer"}>
          <TableCell></TableCell>
          <TableCell>
            <Button onClick={() => support.setDefault()}>
              {"Set Default"}
            </Button>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Button onClick={() => support.store()}>{"Set"}</Button>
            <Button sx={{ marginLeft: "10px" }} onClick={() => support.clear()}>
              {"Clear"}
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

const mapDispatchToProps = {
  setValue: pricingActions.setPricingAssumption,
};

export const mapStateToProps = (state) => {
  return {
    configs: staticDataSelectors.pricingAssumptionsConfig(state),
    editability: staticDataSelectors.pricingAssumptionsEditability(state),
    defaults: staticDataSelectors.pricingAssumptionsDefVals(state),
    values: pricingSelectors.getAssumptions(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingAssumptionsTable);
