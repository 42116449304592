import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Markdown from "components/common/Markdown";
import NumberFormat from "react-number-format";

export const getGoodBadClassName = (valueStr) => {
  if (valueStr == null) {
    return "";
  }
  const value = parseFloat(valueStr);
  if (isNaN(value)) {
    return "";
  }
  if (value === 0) {
    return "";
  }
  return value < 0 ? "good" : "bad";
};

const JudgementValue = ({ value }) => (
  <NumberFormat
    displayType={"text"}
    decimalScale={2}
    fixedDecimalScale
    suffix={"%"}
    value={value}
    className={`value ${getGoodBadClassName(value)}`}
  />
);

const Judgement = (props) => (
  <Grid item xs={6}>
    <span className={"print-label"}>{"UW Judgment"}</span>

    <TableContainer>
      <Table size={"small"} className={"flags judgement"}>
        <TableHead>
          <TableRow>
            <TableCell width={"14%"}>{"Label"}</TableCell>
            <Tooltip
              title={
                "Positive numbers are bad (increase the required premium). Negative numbers are good (decrease the required premium)."
              }
            >
              <TableCell width={"10%"} align={"center"}>
                {"Impact"}
              </TableCell>
            </Tooltip>
            <TableCell width={"76%"}>{"Comments"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.judgements.slice(0, -1).map((row, i) => (
            <TableRow key={`JUDGMENTS_ROW_${i}`}>
              <TableCell
                style={{ verticalAlign: "top" }}
                className={"cell-condensed-padding-md"}
              >
                {row.name}
              </TableCell>
              <TableCell
                style={{ verticalAlign: "top" }}
                className={"cell-condensed-padding-md"}
                align={"center"}
              >
                <JudgementValue value={row.value} />
              </TableCell>
              <TableCell
                style={{ verticalAlign: "top" }}
                className={"cell-condensed-padding-md"}
              >
                <Markdown value={row.comments} editMode={false} />
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell
              style={{ verticalAlign: "top" }}
              className={"cell-condensed-padding-md"}
            >
              <b>{"Overall"}</b>
            </TableCell>
            <TableCell
              style={{ verticalAlign: "top" }}
              className={"cell-condensed-padding-md"}
              align={"center"}
            >
              <JudgementValue value={props.judgementFactorPretty} />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Grid>
);

export default Judgement;
