import * as objects from "common/objects";

export const compareStringFields = (readValue) => (a, b) => {
  const formalize = (x) =>
    (readValue(x)?.trim()?.toLowerCase() || "zzzzzzzzzzzzzzz") +
    ":" +
    x.submissionId;
  return formalize(a).localeCompare(formalize(b));
};

export const invertComparatorIf = (isInverted) =>
  isInverted ? (f) => (a, b) => -1 * f(a, b) : (f) => f;

const applyAttributeFilters = (submissions, filters) => {
  const searchTerms = filters.filter((item) => item.value.length !== 0);

  if (searchTerms.length === 0) {
    return submissions;
  } else {
    return submissions.filter((x) => {
      let matchCount = 0;

      searchTerms.forEach((search) => {
        if (search.context === "Review Status") {
          matchCount += search.value.includes(x.status) ? 1 : 0;
        } else if (search.context === "Insured") {
          matchCount += search.value.includes(x.insured ?? "") ? 1 : 0;
        } else if (search.context === "Underwriter") {
          matchCount += search.value.includes(x.preferredUnderwriter ?? "")
            ? 1
            : 0;
        } else if (search.context === "Reviewer") {
          matchCount += search.value.includes(x.reviewer ?? "") ? 1 : 0;
        }
      });

      return matchCount === searchTerms.length;
    });
  }
};

export const doSearch = (searchInput, searchableColumns) => {
  searchInput = (searchInput ?? "").trim().toLowerCase();
  if (!searchInput) {
    return (s) => s;
  }

  const searchStrings = searchInput.split(/\s+/);

  const makeSearchableObject = (submission) =>
    Object.fromEntries(
      Object.entries(submission)
        .filter(
          ([k, v]) => searchableColumns.includes(k) && typeof v === "string"
        )
        .map(([k, v]) => [k, v.trim().toLowerCase()])
    );

  return (submission) => {
    const searchable = makeSearchableObject(submission);
    const score = objects.calculateSearchScore(searchable, { searchStrings });
    return score > 0;
  };
};

export const filterSubmissions = (
  submissions,
  searchInput,
  searchableColumns,
  filters
) => {
  submissions = submissions ?? [];
  const matchedByFilter = applyAttributeFilters(submissions, filters);
  return matchedByFilter.filter(doSearch(searchInput, searchableColumns));
};

export const getFilterItems = (
  context,
  submissions,
  searchInput,
  searchableColumns,
  filters
) => {
  const filtered = filterSubmissions(
    submissions,
    searchInput,
    searchableColumns,
    filters.filter((item) => item.context !== context)
  );

  if (context === "Review Status") {
    const items = [...new Set(filtered.map((x) => x.status))];
    return items.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  } else if (context === "Insured") {
    const items = [...new Set(filtered.map((x) => x.insured ?? ""))];
    return items.map((item) => {
      return {
        value: item,
        label: item === "" ? "[Blank]" : item,
      };
    });
  } else if (context === "Underwriter") {
    const items = [
      ...new Set(filtered.map((x) => x.preferredUnderwriter ?? "")),
    ];
    return items.map((item) => {
      return {
        value: item,
        label: item === "" ? "[Blank]" : item,
      };
    });
  } else if (context === "Reviewer") {
    const items = [...new Set(filtered.map((x) => x.reviewer ?? ""))];
    return items.map((item) => {
      return {
        value: item,
        label: item === "" ? "[Blank]" : item,
      };
    });
  }
};
