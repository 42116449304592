import {
  Delete as DeleteIcon,
  WarningRounded as IncompleteManualClaimIcon,
  EventBusyRounded as InvalidDateIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { connect } from "react-redux";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

export const ManualClaims = (props) => {
  return (
    <>
      <Component
        title={"Manual Claims"}
        options={
          <Disabled ifReadOnly>
            <Button
              onClick={props.toggleMergingClaims}
              variant={props.mergingClaims ? "contained" : "outlined"}
              sx={
                props.mergingClaims ? { color: "white" } : { color: "orange" }
              }
              disableElevation
            >
              {props.mergingClaims ? "Cancel Merge Claims" : "Merge Claims"}
            </Button>
          </Disabled>
        }
      >
        <TableContainer>
          <Table className={"manual-claims-table"}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell
                  align={"right"}
                  size={"small"}
                  sx={{ minWidth: "100px" }}
                >
                  {"Year*"}
                </TableCell>
                <TableCell>{"Line*"}</TableCell>
                <TableCell align={"right"}>{"Loss Amount*"}</TableCell>
                <TableCell>{"Claimant"}</TableCell>
                <TableCell>{"Description"}</TableCell>
                <TableCell>{"Status"}</TableCell>
                <TableCell align={"right"} width={"12%"}>
                  {"Date Closed"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.manualClaims.map((claim, i) => (
                <TableRow
                  key={i}
                  className={!claim.isActive ? "table-row-inactive" : ""}
                >
                  <TableCell sx={{ minWidth: "0!important" }}>
                    {!claim.isDefault && !claim.isActive && (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{ height: "100%" }}
                      >
                        <Tooltip
                          title={
                            "Required fields missing, please ensure you provide a Year, Coverage and Loss Amount."
                          }
                        >
                          <IncompleteManualClaimIcon
                            fontSize={"small"}
                            color={"error"}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: "0!important" }}>
                    {i !== props.manualClaims.length - 1 && (
                      <IconButton
                        icon={DeleteIcon}
                        onClick={() => props.deleteManualClaims(i)}
                        data-testid={`delete-manual-claim-${i}`}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ width: 30 }}>
                    {claim.mergedFrom ? "M" : ""}
                  </TableCell>
                  <TableCell scope={"row"} sx={{ width: "120px" }}>
                    <TextField
                      onChange={(e) =>
                        props.handleChangeLossYear(e.target.value, i)
                      }
                      fullWidth
                      inputProps={{ style: { textAlign: "right" } }}
                      InputProps={{
                        ...(!claim.isDefault && !claim.hasValidLossYear
                          ? {
                              startAdornment: (
                                <InputAdornment position={"start"}>
                                  <Tooltip
                                    title={
                                      "Unrecognised date, please enter a year or a date in the format YYYY-MM-DD."
                                    }
                                  >
                                    <InvalidDateIcon
                                      fontSize={"small"}
                                      color={"error"}
                                    />
                                  </Tooltip>
                                </InputAdornment>
                              ),
                            }
                          : {}),
                      }}
                      value={claim.lossYear ?? ""}
                      variant={"standard"}
                      placeholder={"YYYY-MM-DD"}
                      error={!claim.hasValidLossYear}
                      data-testid={"manual-claims-year"}
                    />
                  </TableCell>
                  <TableCell align={"left"} sx={{ whiteSpace: "nowrap" }}>
                    <Disabled ifReadOnly>
                      <FormControl error={!claim.hasValidCoverage}>
                        <Select
                          sx={{
                            width: "60px",
                          }}
                          value={claim.coverage || ""}
                          onChange={(e) =>
                            props.handleChangeCoverage(e.target.value, i)
                          }
                          fullWidth
                          variant={"standard"}
                          data-testid={"manual-claims-coverage"}
                        >
                          <MenuItem value={""}>&nbsp;</MenuItem>
                          {props.claimTypes.map((claimType) => {
                            return (
                              <MenuItem
                                key={claimType.key}
                                value={claimType.key}
                              >
                                {claimType.display}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Disabled>
                    {props.createCoverageList &&
                      claim.coverageList &&
                      claim.coverageList.length > 1 &&
                      "(" + claim.coverageList.join(", ") + ")"}
                  </TableCell>
                  <TableCell>
                    <PrettyNumberTextField
                      fullWidth
                      type={"text"}
                      hiddenLabel
                      size={"small"}
                      variant={"filled"}
                      allowClear
                      inputProps={{ style: { textAlign: "right" } }}
                      onChangeNumber={(x) => props.handleChangeLoss(x, i)}
                      value={claim.lossAmount || ""}
                      error={!claim?.isDefault && !claim.hasValidLossAmount}
                      data-testid={"manual-claims-loss"}
                    />
                  </TableCell>
                  <TableCell scope={"row"}>
                    <TextField
                      onChange={(e) =>
                        props.handleChangeClaimant(e.target.value, i)
                      }
                      fullWidth
                      value={claim.claimant || ""}
                      variant={"standard"}
                    />
                  </TableCell>
                  <TableCell scope={"row"}>
                    <TextField
                      onChange={(e) =>
                        props.handleChangeDescription(e.target.value, i)
                      }
                      value={claim.description || ""}
                      fullWidth
                      variant={"standard"}
                    />
                  </TableCell>
                  <TableCell>
                    <Disabled ifReadOnly>
                      <Select
                        value={claim.oc ?? ""}
                        fullWidth
                        onChange={(e) =>
                          props.handleChangeOC(e.target.value || null, i)
                        }
                        variant={"standard"}
                      >
                        <MenuItem value={""}>&nbsp;</MenuItem>
                        <MenuItem value={"O"}>{"Open"}</MenuItem>
                        <MenuItem value={"C"}>{"Closed"}</MenuItem>
                      </Select>
                    </Disabled>
                  </TableCell>
                  <TableCell scope={"row"} sx={{ width: "120px" }}>
                    <TextField
                      onChange={(e) =>
                        props.handleChangeDateClosed(e.target.value, i)
                      }
                      fullWidth
                      inputProps={{ style: { textAlign: "right" } }}
                      value={claim.dateClosed ?? ""}
                      variant={"standard"}
                      placeholder={"YYYY-MM-DD"}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Component>
    </>
  );
};

const mapDispatchToProps = {
  handleChangeDateClosed: claimsActions.handleChangeDateClosed,
  handleChangeLossYear: claimsActions.handleChangeLossYear,
  handleChangeCoverage: claimsActions.handleChangeCoverage,
  handleChangeLoss: claimsActions.handleChangeLoss,
  handleChangeDescription: claimsActions.handleChangeDescription,
  handleChangeClaimant: claimsActions.handleChangeClaimant,
  handleChangeOC: claimsActions.handleChangeOC,
  deleteManualClaims: claimsActions.deleteManualClaims,
};

const mapStateToProps = (state) => {
  return {
    manualClaims: claimsSelectors.getManualClaims(state),
    claimTypes: exposureSelectors.selectLossCategories(state) || [
      { key: "AL", display: "Auto" },
      { key: "EL", display: "Employee" },
      { key: "PL", display: "Product" },
      { key: "GL", display: "General" },
    ],
    claimTypesLoading: state.temp?.claimTypes?.loadingClaimTypes || false,
    mergedClaims: claimsSelectors.mergedClaims(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualClaims);
