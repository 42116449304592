import moment from "moment";

export class ErrorDialogSupport {
  constructor(messages) {
    const baseMsg = "The data just saved overwrites a previous save by";
    this.formattedMsgs = messages.map((m) => {
      if (m.type === "FILE_OVERWRITTEN") {
        const fmtdDate = this.formatDate(m.overwrite_date);
        return {
          type: m.type,
          title: m.title,
          message: `${baseMsg} ${m.overwrite_email} on ${fmtdDate}`,
          level: "info",
          date: m.date,
        };
      } else {
        return m;
      }
    });
    const types = messages.reduce(
      (acc, m) => {
        acc[m.warning ? "W" : "E"] += 1;
        return acc;
      },
      { E: 0, W: 0 }
    );
    const plural = messages.length > 1;
    const errM = types["E"] === 0 ? "" : plural ? "Errors" : "Error";
    const warnM = types["W"] === 0 ? "" : plural ? "Warnings" : "Warning";
    this.title = errM + (errM && warnM ? "/" : "") + warnM;
  }
  getTitle() {
    return this.title;
  }

  formatDate(dateStr) {
    if (!dateStr) return "";
    try {
      return moment(dateStr).format("MMM Do YYYY, h:mm:ss a");
    } catch (e) {
      return "";
    }
  }
}
