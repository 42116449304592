import * as saga from "./claimsSaga";
import * as logger from "common/logger";
import * as config from "config";
import * as fileUtils from "fileUtils";
import {
  all,
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import * as types from "store/actions/input/claims/claimsActionTypes";
import * as actions from "store/actions/input/claims/claimsActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as utils from "utils";

const REQUIRED_MODEL_CONFIDENCE = 0.25;

export const mergeSuggestions = ({
  defaultSuggestions,
  modelSuggestions,
  targetColumns,
}) => {
  return Object.fromEntries(
    (targetColumns ?? [])
      .map((targetColumn) => {
        const defaultSuggestion = defaultSuggestions?.[targetColumn];
        const modelSuggestionEntry =
          modelSuggestions?.suggestions?.[targetColumn]?.[0];
        const modelSuggestion =
          modelSuggestionEntry?.confidence > 0.25
            ? modelSuggestionEntry?.column
            : null;
        return [targetColumn, modelSuggestion ?? defaultSuggestion ?? null];
      })
      .filter(([, suggestion]) => !!suggestion)
  );
};

export const mergeInverseSuggestions = ({
  defaultSuggestions,
  modelSuggestions,
  targetColumns,
}) => {
  const resolvedModelSuggestions = Object.fromEntries(
    Object.entries(modelSuggestions?.suggestions ?? {})
      .map(([key, entries]) => {
        const entry = entries?.[0];
        return [
          key,
          entry?.confidence > REQUIRED_MODEL_CONFIDENCE ? entry?.column : null,
        ];
      })
      .filter(([, value]) => !!value)
  );

  const sanitize = (suggestions) =>
    Object.fromEntries(
      Object.entries(suggestions ?? {}).filter(([, value]) =>
        targetColumns?.includes?.(value)
      )
    );

  return {
    ...sanitize(defaultSuggestions),
    ...sanitize(resolvedModelSuggestions),
  };
};

export function* updateMapping(hintType, keys, meta, inverse = false) {
  try {
    const [defaultResponse, modelResponse] = yield all([
      call(
        utils.authenticatedFetch,
        config.endpoints.claims("excel/mapping-hints"),
        {
          body: JSON.stringify({
            keys,
            hintType,
            inverse,
          }),
          method: "post",
        }
      ),
      call(
        utils.authenticatedFetch,
        config.endpoints.mappingSuggestions("suggest-mappings"),
        {
          body: JSON.stringify({
            keys,
            meta,
            hintType,
            inverse,
          }),
          method: "post",
        }
      ),
    ]);
    const defaultSuggestions = yield call([
      defaultResponse,
      defaultResponse.json,
    ]);
    const modelSuggestions = yield call([modelResponse, modelResponse.json]);

    function* retrieveTargetColumns(hintType) {
      if (hintType === "columnMapping") {
        const columns = yield select(
          claimsSelectors.selectTargetClaimsColumns
        ) ?? [];
        // We are seeing a number of errors with suggestions being applied where they
        // should not have been. Prefer to err on the side of mapping only those columns
        // we know are being suggested well. The suggestions seem often to be accepted
        // without much attention paid. So incorrectly suggestions can be quite
        // dangerous.
        const allowed_columns = [
          "_DATE_OF_LOSS",
          "_COVERAGE",
          "_REPORTED_TOTAL_LOSS",
          "_CLAIMANT_NAME",
          "_DESCRIPTION",
          "_OPEN_CLOSED",
        ];
        return columns
          .map(({ key }) => key)
          .filter((key) => allowed_columns.includes(key));
      } else if (hintType === "openClosedMapping") {
        return ["O", "C"];
      } else if (hintType === "lossTypeMapping") {
        const columns = yield select(exposureSelectors.selectLossCategories) ??
          [];
        return columns.map(({ key }) => key);
      } else {
        return [];
      }
    }
    const targetColumns = yield* retrieveTargetColumns(hintType);

    const suggestions = inverse
      ? mergeInverseSuggestions({
          defaultSuggestions,
          modelSuggestions,
          targetColumns,
        })
      : mergeSuggestions({
          defaultSuggestions,
          modelSuggestions,
          targetColumns,
        });

    yield put({
      type: types.MAPPING_SUGGESTIONS_RETURNED,
      payload: {
        hintType,
        suggestions,
      },
    });
  } catch (error) {
    logger.exception(error);
  }
}

export function* updateDownstreamSuggestedMapping() {
  try {
    yield put(
      actions.mapping.columns[config.LOSS_TYPE_KEY].candidatesInvalidated()
    );
    yield put(
      actions.mapping.columns[config.OPEN_CLOSED].candidatesInvalidated()
    );
    yield put(
      actions.mapping.columns[config.EXCLUDE_KEY].candidatesInvalidated()
    );
    const columnMappings = yield select(claimsSelectors.getColumnMappings);
    if (columnMappings == null) {
      return;
    }
    if (columnMappings[config.OPEN_CLOSED] != null) {
      yield put(
        actions.mapping.columns[config.OPEN_CLOSED].candidatesRequested(
          columnMappings[config.OPEN_CLOSED],
          true
        )
      );
    }
    if (columnMappings[config.LOSS_TYPE_KEY] != null) {
      yield put(
        actions.mapping.columns[config.LOSS_TYPE_KEY].candidatesRequested(
          columnMappings[config.LOSS_TYPE_KEY],
          true
        )
      );
    }
    if (columnMappings[config.EXCLUDE_KEY] != null) {
      yield put(
        actions.mapping.columns[config.EXCLUDE_KEY].candidatesRequested(
          columnMappings[config.EXCLUDE_KEY],
          true
        )
      );
    }
  } catch (error) {
    logger.exception(error);
  }
}

export function* updateColumns() {
  try {
    yield put({
      type: types.COLUMN_NAMES_REQUESTED,
    });
    const active = yield select(claimsSelectors.getActiveFile);
    function* getCols() {
      if (active?.isFilesFile) {
        const sheets = yield select(claimsSelectors.sheetSummaries);

        return yield call(fileUtils.getSubtableColumnList, {
          fileId: active.fileId,
          sheet: {
            name: sheets[active.activeSheet].name,
          },
          skipRows: active.topLeft.row,
          skipColumns: active.topLeft.column,
        });
      } else {
        const query = yield select(claimsSelectors.getColumnsQuery);
        const response = yield call(utils.authenticatedFetch, query);
        return yield call([response, response.json]);
      }
    }
    function* getColsMeta() {
      try {
        // Columns metadata is only supported for Files-based claims.
        if (!active?.isFilesFile) {
          return null;
        }

        const useColumnMetaSuggestions = yield call(async () => {
          const url = config.endpoints.staticData("feature-flags");
          const resp = await utils.authenticatedFetch(url, {
            method: "get",
          });
          return await resp.json();
        })["claims/useColumnMetaSuggestions"] ?? true;
        if (!useColumnMetaSuggestions) {
          return null;
        }

        const sheets = yield select(claimsSelectors.sheetSummaries);
        return yield call(fileUtils.getSubtableColumnMeta, {
          fileId: active.fileId,
          sheet: {
            name: sheets[active.activeSheet].name,
          },
          skipRows: active.topLeft.row,
          skipColumns: active.topLeft.column,
        });
      } catch (error) {
        logger.exception(error, {
          breadcrumb: { call: "getSubtableColumnMeta" },
        });
      }
      return null;
    }

    const columns = yield getCols();
    yield put({
      type: types.COLUMN_NAMES_RETURNED,
      payload: columns,
    });
    const columnsMeta = yield getColsMeta();
    yield* updateMapping("columnMapping", columns, columnsMeta);
    yield call(saga.updateDownstreamSuggestedMapping);
  } catch (error) {
    logger.exception(error);
    yield put({
      type: types.COLUMN_NAMES_FAILED,
    });
  }
}

export function* activeSheetTopLeftChanged(action) {
  try {
    yield put({
      type: types.SHEET_TOP_LEFT_SELECTED,
      payload: action.payload,
    });
    if (action.payload == null) {
      return;
    }
    yield put({ type: types.UPDATE_COLUMNS });
  } catch (error) {
    logger.exception(error);
  }
}

export function* activeSheetChanged(action) {
  try {
    yield put({
      type: types.SHEET_SELECTED,
      payload: action.payload?.sheet,
    });
    if (action.payload?.sheet == null) {
      return;
    }
    yield put({ type: types.UPDATE_COLUMNS });
  } catch (error) {
    logger.exception(error);
  }
}

export function* activeFilesSheetChanged(action) {
  try {
    yield put({
      type: types.SHEET_SELECTED,
      payload: action.payload?.sheet,
    });
    if (action.payload?.sheet == null) {
      return;
    }
    yield put({ type: types.UPDATE_COLUMNS });
  } catch (error) {
    logger.exception(error);
  }
}

export function* getColumnValueCandidates(column, ignore_blanks) {
  const claimsSheet = yield select(claimsSelectors.getClaimsSheet);
  if (claimsSheet.isFilesFile) {
    return yield call(fileUtils.getUniqueColumnValues, {
      fileId: claimsSheet.fileId,
      sheetName: claimsSheet.sheet,
      skipRows: claimsSheet.skipRows,
      skipColumns: claimsSheet.skipColumns,
      columnName: column,
      ignoreBlanks: ignore_blanks,
    });
  } else {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims(
        "excel/distinct?" +
          new URLSearchParams({
            filename: claimsSheet.filename,
            sheet: claimsSheet.sheet,
            skipRows: claimsSheet.skipRows,
            skipColumns: claimsSheet.skipColumns,
            columnName: column,
            dropBlanks: ignore_blanks,
          })
      )
    );
    return yield call([response, response.json]);
  }
}

export function* getCandidatesAndHints(action) {
  try {
    if (!action.payload.selected) {
      yield put(
        actions.mapping.columns[action.payload.target].candidatesInvalidated()
      );
      return;
    }
    yield put(
      actions.mapping.columns[action.payload.target].candidatesWaiting()
    );
    const candidates = yield getColumnValueCandidates(
      action.payload.column,
      false
    );
    yield put(
      actions.mapping.columns[action.payload.target].candidatesReturned(
        candidates.values,
        candidates.totalCount
      )
    );
    yield* saga.updateMapping(
      {
        _COVERAGE: "lossTypeMapping",
        _OPEN_CLOSED: "openClosedMapping",
        _EXCLUDE: "excludedMapping",
      }[action.payload.target],
      candidates.values.map((candidate) => candidate.name),
      {},
      true
    );
  } catch (error) {
    logger.exception(error);
  }
}

export function* columnMappingUpdated(action) {
  try {
    yield put({
      type: types.COLUMN_MAPPING_UPDATED,
      payload: action.payload,
    });
    if (action.payload.target in actions.mapping.columns) {
      yield put(
        actions.mapping.columns[action.payload.target].candidatesRequested(
          action.payload.column,
          action.payload.selected
        )
      );
    }
  } catch (error) {
    logger.exception(error);
  }
}

function* _callTransformClaimsSync(claimsTransformRequest) {
  const response = yield call(
    utils.authenticatedFetch,
    config.endpoints.claims("transform"),
    {
      method: "post",
      body: JSON.stringify(claimsTransformRequest),
    }
  );
  return yield call([response, response.json]);
}

function* _callTransformClaimsAsync(request) {
  let response = yield call(
    utils.authenticatedFetch,
    config.endpoints.claims("create-transform"),
    {
      method: "post",
      body: JSON.stringify(request),
    }
  );
  let status = yield call([response, response.json]);
  while (status?.state === "pending") {
    yield delay(1000);
    response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims("retrieve-transform"),
      {
        method: "post",
        body: JSON.stringify({ transformId: status.transformId }),
      }
    );
    status = yield call([response, response.json]);
  }
  if (status?.error) {
    throw status.error;
  }
  if (!status?.output) {
    throw new Error("No output from transform");
  }
  return status.output;
}

export function* transformClaimsFile(action) {
  yield delay(100);
  yield put(actions.transformClaimsFile.started());
  try {
    const claimsTransformationRequest = yield select(
      claimsSelectors.getClaimsTransformationRequest
    );
    const data = action?.payload?.useAsync
      ? yield* _callTransformClaimsAsync(claimsTransformationRequest)
      : yield* _callTransformClaimsSync(claimsTransformationRequest);

    yield put(
      actions.transformClaimsFile.succeeded({
        claimsKey: data.claimsKey,
        lastMappedLossDate: data.lastMappedLossDate,
        lastLossDate: data.lastLossDate,
        firstMappedLossDate: data.firstMappedLossDate,
        firstLossDate: data.firstLossDate,
      })
    );
    yield delay(500);
    yield put(actions.claimsDialogClosed());
  } catch (error) {
    logger.exception(error);
    yield put(
      actions.transformClaimsFile.failed({
        errorMessage:
          error?.description ??
          error?.message ??
          `Cannot process file. Please contact ${config.SUPPORT_EMAIL}.`,
      })
    );
  }
}

export function* handleClaimsDialogClosed() {}

export function* retrieveClaimsColumnMapping() {
  const mappingData = yield select(
    claimsSelectors.selectClaimsFileColumnMappingDisplay
  );
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims(`excel/column-mappings/${mappingData.claimsKey}`)
    );
    const meta_data = yield call([response, response.json]);
    yield put({
      type: types.CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOADED,
      payload: meta_data,
    });
  } catch (e) {
    yield put({
      type: types.CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD_FAILED,
      payload: e,
    });
  }
}

export default function* claimsSaga() {
  yield takeLatest(types.ACTIVE_SHEET_CHANGED, activeSheetChanged);
  yield takeLatest(types.FILE_ACTIVE_SHEET_CHANGED, activeFilesSheetChanged);
  yield takeEvery(actions.mapping.updated, columnMappingUpdated);
  yield takeLatest(
    actions.mapping.columns[config.LOSS_TYPE_KEY].candidatesRequested,
    getCandidatesAndHints
  );
  yield takeLatest(
    types.ACTIVE_SHEET_TOP_LEFT_CHANGED,
    activeSheetTopLeftChanged
  );
  yield takeLatest(types.UPDATE_COLUMNS, updateColumns);
  yield takeLatest(
    actions.mapping.columns[config.OPEN_CLOSED].candidatesRequested,
    getCandidatesAndHints
  );
  yield takeLatest(
    actions.mapping.columns[config.EXCLUDE_KEY].candidatesRequested,
    getCandidatesAndHints
  );
  yield takeLatest(actions.transformClaimsFile.requested, transformClaimsFile);
  yield takeLatest(
    types.START_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD,
    retrieveClaimsColumnMapping
  );
}
