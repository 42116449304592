const ALLOWED_S3_UPLOAD_FIELDS = [
  "key",
  "policy",
  "AWSAccessKeyId",
  "x-amz-security-token",
  "signature",
  "x-amz-checksum-algorithm",
  "x-amz-checksum-sha256",
];

export const uploadFile = async (file, { url, fields }) => {
  const formData = new FormData();
  Object.entries(fields)
    .filter(([key, _]) => ALLOWED_S3_UPLOAD_FIELDS.includes(key))
    .forEach(([key, value]) => formData.append(key, value));
  formData.append("file", file);

  const response = await fetch(url, {
    method: "post",
    cache: "no-cache",
    body: formData,
  });
  if (!response.ok) {
    const errorMessage = await response.text().catch(() => "[Unknown error]");
    throw new Error(`${response.status}: ${errorMessage}`);
  }
};
