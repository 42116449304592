import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataActions from "store/actions/temp/staticData/staticDataActions";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import useDeepCompareEffect from "use-deep-compare-effect";

export const useConfig = (keys) => {
  const dispatch = useDispatch();

  const retrieveConfig = useCallback(
    (keys) => {
      dispatch(staticDataActions.retrieveConfig.requested(keys));
    },
    [dispatch]
  );

  const config = useSelector(staticDataSelectors.selectAllConfig);

  useDeepCompareEffect(() => {
    retrieveConfig(keys);
  }, [keys, retrieveConfig]);

  return config;
};
