import CoveragesGrid from "./CoveragesGrid";
import { SettingsBackupRestore as ResetIcon } from "@mui/icons-material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as programPricingStore from "store/programPricing";

const CatCoverages = () => {
  const dispatch = useDispatch();
  const [queryConfig] = staticDataService.useLazyConfigQuery();

  const programPricing = useSelector(programPricingStore.select);

  const resetCoverageConfig = async () => {
    const { data: submissionDefaults } = await queryConfig(
      "submission_defaults"
    );
    // TODO(mhiggins): Remove this after all submissions have been updated. This was
    // added just as a shortcut to avoid writing a migration.
    const removeLegacy =
      programPricing?.catCoverages != null ||
      programPricing?.nonCatCoverages != null
        ? {
            catCoverages: null,
            nonCatCoverages: null,
          }
        : {};

    dispatch(
      programPricingStore.update({
        coverageConfig:
          submissionDefaults?.programPricing?.coverageConfig ?? {},
        ...removeLegacy,
      })
    );
  };

  return (
    <Component
      title={"Cat Coverages"}
      options={
        <Disabled ifReadOnly>
          <IconButton
            variant={"bright"}
            icon={ResetIcon}
            onClick={resetCoverageConfig}
            tooltip={"Reset to the latest list of coverages."}
          />
        </Disabled>
      }
    >
      <Disabled ifReadOnly>
        <CoveragesGrid
          perils={programPricing?.coverageConfig?.catCoverageGrid}
        />
      </Disabled>
    </Component>
  );
};

export default CatCoverages;
