import * as persistenceActionTypes from "../../actions/persistence/persistenceActionTypes";
import * as actionTypes from "../../actions/print/printActionTypes";

export const INIT_STATE = {
  elementVisibility: {},
};

const printReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.print,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...state,
      };
    case actionTypes.TOGGLE_ELEMENT_VISIBILITY:
      return {
        ...state,
        elementVisibility: {
          ...state.elementVisibility,
          [action.payload]: !state.elementVisibility[action.payload],
        },
      };

    default:
      return state;
  }
};

export default printReducer;
