import * as actions from "store/actions/temp/admin/adminActions";

export const INIT_STATE = {
  companies: null,
  templates: null,
  userLogMessage: "",
  companiesState: {
    status: "idle",
  },
  templatesState: {
    status: "idle",
  },
  createCompanyState: {
    status: "idle",
  },
};

const adminReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case actions.createCompany.started.toString():
      return {
        ...state,
        createCompanyState: {
          status: "running",
        },
      };
    case actions.createCompany.succeeded.toString():
      return {
        ...state,
        createCompanyState: {
          status: "succeeded",
          payload: action?.payload,
        },
      };
    case actions.createCompany.failed.toString():
      return {
        ...state,
        createCompanyState: {
          status: "failed",
          errorMessage: action.payload,
        },
      };
    case actions.userLogMessage.update.toString():
      return {
        ...state,
        userLogMessage: action.payload,
      };
    default:
      return state;
  }
};

export default adminReducer;
