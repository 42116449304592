import InlineDelete from "./InlineDelete";
import InlineEditor from "./InlineEditor";
import {
  EditRounded as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { onlyContainsEmoji, stringToColor } from "common/strings";

const Comment = ({ comment }) => {
  const canEdit = false;
  const accentColor = stringToColor(comment.userId);
  const avatarSx = {
    height: "2rem",
    width: "2rem",
    bgcolor: accentColor,
    fontSize: "1rem",
  };
  // Length gives the codepoint length not grapheme count, there are ways to do this but prob less compute to just bound at some fixed length
  // For actual grapheme length see: https://stackoverflow.com/questions/38345372/why-does-a-string-containing-a-single-emoji-like-have-a-length-of-2
  const emojiText =
    comment.content?.length < 20 && onlyContainsEmoji(comment.content);
  const contentSx = emojiText
    ? { fontSize: "32px" }
    : { marginBottom: "12px", whiteSpace: "pre-line" };

  const dateLabel = comment.dateLabel ? (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        paddingBottom: "0.5rem",
        background: "white",
        zIndex: 100,
      }}
    >
      <Divider>
        <Chip label={comment.dateLabel} />
      </Divider>
    </Box>
  ) : (
    <></>
  );

  let content = (
    <Box
      sx={{ position: "relative" }}
      className={canEdit ? "has-actions" : null}
    >
      <Typography marginLeft={5} sx={contentSx}>
        {comment.content}
      </Typography>
      {canEdit && (
        <Box
          sx={{
            position: "absolute",
            bottom: "0.5rem",
            right: "0.5rem",
            padding: "0.25rem",
            background: "white",
            borderRadius: "0.5rem",
            border: "1px solid #dce2eb",
          }}
          className={"actions"}
        >
          <IconButton sx={{ borderRadius: "0.375rem" }} onClick={() => {}}>
            <EditIcon />
          </IconButton>
          <IconButton sx={{ borderRadius: "0.375rem" }} onClick={() => {}}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );

  if (comment.editing) {
    content = <InlineEditor comment={comment} />;
  } else if (comment.deleting) {
    content = <InlineDelete comment={comment} />;
  }

  if (!comment.time)
    return (
      <Stack>
        {dateLabel}
        {content}
      </Stack>
    );

  return (
    <>
      {dateLabel}
      <Stack>
        <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
          <Avatar sx={avatarSx}>{comment.initials}</Avatar>
          <Stack>
            <Typography variant={"subtitle2"} color={accentColor}>
              {comment.label}
            </Typography>
            <Typography
              variant={"subtitle2"}
              sx={{ marginTop: "-3px !important", fontSize: "0.75rem" }}
            >
              {comment.time}
            </Typography>
          </Stack>
        </Stack>
        {content}
      </Stack>
    </>
  );
};

export default Comment;
