import { createAction } from "@reduxjs/toolkit";

export const listCompanies = {
  startSaga: createAction("admin/listCompanies"),
  started: createAction("admin/listCompanies/started"),
  succeeded: createAction("admin/listCompanies/succeeded"),
  failed: createAction("admin/listCompanies/failed"),
};

export const listTemplates = {
  startSaga: createAction("admin/listTemplates"),
  started: createAction("admin/listTemplates/started"),
  succeeded: createAction("admin/listTemplates/succeeded"),
  failed: createAction("admin/listTemplates/failed"),
};

export const createCompany = {
  startSaga: createAction("admin/createCompany/startSaga"),
  started: createAction("admin/createCompany/started"),
  succeeded: createAction("admin/createCompany/succeeded"),
  failed: createAction("admin/createCompany/failed"),
};

export const userLogMessage = {
  update: createAction("admin/userLogMessage/update"),
};
