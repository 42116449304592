import * as actionTypes from "../../../actions/input/images/imagesActionTypes";
import * as persistenceActionTypes from "../../../actions/persistence/persistenceActionTypes";

export const INIT_STATE = {
  remote: {},
};

const imagesReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.images,
      };
    case actionTypes.UPLOADED_IMAGE:
      return {
        ...state,
        remote: {
          ...(state.remote || {}),
          [action.payload.localId]: {
            submissionId: action.payload.submissionId,
            imageId: action.payload.imageId,
            imageType: action.payload.imageType,
          },
        },
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
};

export default imagesReducer;
