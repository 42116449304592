import { Children, cloneElement } from "react";
import { connect } from "react-redux";
import * as metaSelectors from "store/selectors/meta/metaSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const Disabled = ({
  children,
  ifReadOnly = false,
  byTag = null,
  defaultDisabledByTag = false,
  force = false,
  isUserReadOnly,
  overlay,
  componentDisabled,
}) => {
  const isReadOnly = isUserReadOnly || overlay != null;

  const isDisabledByReadOnly = ifReadOnly && isReadOnly;
  const isDisabledByTag =
    byTag != null && (componentDisabled[byTag] ?? defaultDisabledByTag);
  const isDisabled = force || isDisabledByReadOnly || isDisabledByTag;

  if (isDisabled) {
    return Children.map(children, (child) =>
      cloneElement(child, { disabled: true })
    );
  } else {
    return children;
  }
};

const mapStateToProps = (state) => ({
  isUserReadOnly: userSelectors.isUserReadOnly(state),
  overlay: metaSelectors.selectOverlay(state),
  componentDisabled:
    userSelectors.selectUserConfig(state)?.componentDisabled ?? {},
});

export default connect(mapStateToProps)(Disabled);
