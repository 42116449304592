import MaintenanceDialog from "./MaintenanceDialog";
import MaintenanceWarning from "./MaintenanceWarning";
import { connect } from "react-redux";
import * as dataHooks from "store/hooks/dataHooks";
import * as maintenanceStatusSelectors from "store/selectors/temp/maintenanceStatus/maintenanceStatusSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const MaintenanceBlocker = ({ children, disabled, user }) => {
  const config = dataHooks.useConfig(["maintenance"]);

  const userId = user?.attributes?.id ?? null;
  const exemptUsers = config?.maintenance?.exemptUsers ?? [];
  const isUserExempt = !!userId && exemptUsers.includes(userId);

  return disabled && !isUserExempt ? (
    <MaintenanceDialog />
  ) : (
    <>
      <MaintenanceWarning />
      {children}
    </>
  );
};

const mapStateToProps = (state) => ({
  disabled: maintenanceStatusSelectors.selectIsDisabled(state),
  user: userSelectors.selectUser(state),
});

export default connect(mapStateToProps)(MaintenanceBlocker);
