import React from "react";
import { NavLink } from "react-router-dom";
import * as staticDataHooks from "store/hooks/staticDataHooks";

const TopNav = ({
  tabs,
  style,
  parentPath,
  navigationState,
  setNavigationState,
  isFullWidth,
  borderless,
}) => {
  const translateTab = staticDataHooks.useTranslator("tabs");

  const tabList = tabs.map((tab) => (
    <NavLink
      key={tab.title}
      to={tab.path}
      className={({ isActive }) =>
        "top-nav-tab " + (isActive ? "active-tab" : "")
      }
      onClick={() => {
        if (setNavigationState) {
          setNavigationState({
            ...navigationState,
            [parentPath]: tab.path,
          });
        }
      }}
      data-testid={`child-tab-${tab.path}`}
    >
      {translateTab(`${parentPath}/${tab.path}`, { default: tab.title })}
    </NavLink>
  ));
  return (
    <div
      className={borderless ? "top-nav borderless" : "top-nav"}
      style={style}
    >
      <div
        className={"container"}
        style={{ maxWidth: isFullWidth ? "unset" : null }}
      >
        {tabList}
      </div>
    </div>
  );
};

export default TopNav;
