import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { connect } from "react-redux";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const NONE_KEY = "__NONE__";

const ReinsuranceSelector = ({
  label,
  value,
  onChange,
  disabled,
  reinsurersConfig,
}) => {
  if (!reinsurersConfig?.reinsurers?.length) {
    return <></>;
  }
  return (
    <Grid item xs={12}>
      <FormControl variant={"filled"} fullWidth>
        <InputLabel shrink>{label}</InputLabel>
        <Select
          value={value ?? NONE_KEY}
          onChange={(e) => {
            const key = e?.target?.value;
            if (!!key) {
              const value = key === NONE_KEY ? null : key;
              onChange(value);
            }
          }}
          disabled={disabled}
          fullWidth
        >
          <MenuItem value={NONE_KEY}>{"None"}</MenuItem>
          {(reinsurersConfig?.reinsurers ?? []).map((reinsurer) => (
            <MenuItem key={reinsurer.key} value={reinsurer.key}>
              {reinsurer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    reinsurersConfig: staticDataSelectors.selectConfig("reinsurers")(state),
  };
};

export default connect(mapStateToProps)(ReinsuranceSelector);
