import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import React from "react";
import { connect } from "react-redux";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import { selectSelfInsuredRetentions } from "store/selectors/input/claims/claimsSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const SelfInsuredRetentions = (props) => {
  const appendBlankAndAddDeleteFlag = (retentions) => {
    return retentions
      .map((retention) => ({ canDelete: true, ...retention }))
      .concat({
        lossType: null,
        value: null,
        notes: null,
        canDelete: false,
      });
  };
  const CoverageSelector = (props) => (
    <Disabled ifReadOnly>
      <Select
        variant={"standard"}
        onChange={props.onChange}
        value={props.value}
        fullWidth
      >
        <MenuItem hidden disabled sx={{ display: "none" }} value={null}>
          {"Not Selected"}
        </MenuItem>
        <MenuItem value={"ALL"}>{"All"}</MenuItem>
        {(props.coverages ?? {}).map((coverage) => (
          <MenuItem key={coverage.key} value={coverage.key}>
            {coverage.display}
          </MenuItem>
        ))}
        {props.includeOther && <MenuItem value={"OTHER"}>{"Other"}</MenuItem>}
      </Select>
    </Disabled>
  );

  return (
    <Component title={"Self-Insured Retentions"} {...props.componentProps}>
      <Table>
        <colgroup>
          <col style={{ width: "0px" }} />
          <col style={{ width: "150px" }} />
          <col style={{ width: "250px" }} />
          <col style={{}} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{"Coverage"}</TableCell>
            <TableCell align={"right"}>{"Amount"}</TableCell>
            <TableCell>{"Notes"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {appendBlankAndAddDeleteFlag(props.selfInsuredRetentions).map(
            (retention, index) => (
              <TableRow key={index}>
                <TableCell>
                  {retention.canDelete ? (
                    <IconButton
                      icon={DeleteIcon}
                      onClick={() => props.deleteSelfInsuredRetention(index)}
                    />
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell>
                  <CoverageSelector
                    coverages={props.lossCategories}
                    value={retention.lossType || ""}
                    onChange={(e) =>
                      props.updateSelfInsuredRetention(
                        {
                          lossType: e.target.value,
                        },
                        index
                      )
                    }
                    includeOther={props.includeOther}
                  />
                </TableCell>
                <TableCell>
                  <PrettyNumberTextField
                    type={"text"}
                    inputProps={{ style: { textAlign: "right" } }}
                    hiddenLabel
                    size={"small"}
                    variant={"filled"}
                    onChangeNumber={(x) =>
                      props.updateSelfInsuredRetention({ value: x }, index)
                    }
                    value={retention.value}
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <Notes
                    title={null}
                    update={(comment) =>
                      props.updateSelfInsuredRetention(
                        { notes: comment },
                        index
                      )
                    }
                    value={retention.notes}
                    hasEditButton={false}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </Component>
  );
};

const mapDispatchToProps = {
  updateSelfInsuredRetention: claimsActions.updateSelfInsuredRetention,
  deleteSelfInsuredRetention: claimsActions.deleteSelfInsuredRetention,
};

const mapStateToProps = (state) => {
  return {
    lossCategories: state.temp.exposureMetrics.lossCategories || [],
    selfInsuredRetentions: selectSelfInsuredRetentions(state),
    includeOther:
      userSelectors.selectUserConfig(state)?.componentVisibility?.[
        "input.claims.selfInsuredRetentions.other"
      ] ?? true,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelfInsuredRetentions);
