import MockMode from "./MockMode";
import Submission from "./Submission";
import FeatureFlagged from "./common/FeatureFlagged";
import SearchDialogViaFrontend from "./search/SearchDialog";
import SearchDialog20240618 from "./search/SearchDialog20240618";
import SearchDialogViaBackend from "./search/SearchDialogViaBackend";
import DefaultLoader from "components/common/DefaultLoader";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import * as staticDataService from "services/staticDataService";
import * as submissionsService from "services/submissionsService";
import * as companyActions from "store/actions/company/companyActions";
import * as exposureActions from "store/actions/input/exposure/exposureActions";
import * as staticDataActions from "store/actions/temp/staticData/staticDataActions";

const SearchDialog = () => (
  <FeatureFlagged
    flag={"ui/submissionSearchViaBackend"}
    on={SearchDialogViaBackend}
    off={SearchDialogViaFrontend}
  />
);

const useTempStoreConfig = (keys) => {
  // TODO: Remove this once we no longer acccess config via the temp store.
  //
  // This is retrieving a list config and saving it to the the temp store. For new
  // use-cases, using the staticDataService directly is the preferred approach. But
  // there are some legacy use-cases (notably new & renewed submissions) that rely
  // on it being in the temp store. This hook populates that.

  const dispatch = useDispatch();

  const { data, isLoading } = staticDataService.useMultiConfigQuery(keys);

  useEffect(() => {
    dispatch(staticDataActions.retrieveConfig.succeeded(data));
  }, [dispatch, data]);

  return { isLoading };
};

const useWarmup = () => {
  submissionsService.useListSubmissionsQuery();
};

const SubmissionApp = ({
  source = "submissions",
  isMockMode,
  isSubmissionLoading,
  loadAllExposureMetrics,
  loadAllIndustryClasses,
  loadAllLossCategories,
  checkCategoryCodes,
  retrieveValidClaimsFileFormats,
  retrieveInflationModelConfig,
  retrieveAnalyticsConfig,
  retrieveSovTortCaps,
  retrieveLayerStatusLabels,
}) => {
  const { isLoading: isConfigLoading } = useTempStoreConfig([
    "claims_files",
    "flags",
    "pricing_assumptions",
    "reinsurers",
    "scenarios",
    "submission_defaults",
    "translations",
    "underwriters",
    "general_configuration",
    "renewal",
    "feature_flags",
  ]);

  useWarmup();

  useEffect(() => {
    loadAllExposureMetrics();
    loadAllIndustryClasses();
    loadAllLossCategories();
    checkCategoryCodes();
    retrieveValidClaimsFileFormats();
    retrieveInflationModelConfig();
    retrieveAnalyticsConfig();
    retrieveSovTortCaps();
    retrieveLayerStatusLabels();
  }, [
    loadAllExposureMetrics,
    loadAllIndustryClasses,
    loadAllLossCategories,
    checkCategoryCodes,
    retrieveValidClaimsFileFormats,
    retrieveInflationModelConfig,
    retrieveAnalyticsConfig,
    retrieveSovTortCaps,
    retrieveLayerStatusLabels,
  ]);

  return (
    <>
      {isMockMode && <MockMode />}
      {isSubmissionLoading || isConfigLoading ? (
        <DefaultLoader
          color={"#dc7f4c"}
          size={100}
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <Routes>
          <Route
            path={":submissionId/version/:versionId/*"}
            element={<Submission source={source} />}
          />
          <Route
            path={":submissionId/*"}
            element={<Submission source={source} />}
          />
          {source === "submissions" && (
            <Route
              path={"/"}
              element={
                <FeatureFlagged
                  flag={"ui/legacy.search"}
                  on={SearchDialog20240618}
                  off={SearchDialog}
                />
              }
              replace
            />
          )}
        </Routes>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isMockMode: state.isMockStore,
    isSubmissionLoading: state.persistence?.isLoading ?? false,
  };
};

const mapDispatchToProps = {
  loadAllExposureMetrics: exposureActions.loadAllExposureMetrics,
  loadAllIndustryClasses: exposureActions.loadAllIndustryClasses,
  loadAllLossCategories: exposureActions.loadAllLossCategories,
  checkCategoryCodes: companyActions.checkCategoryCodes,
  retrieveValidClaimsFileFormats:
    staticDataActions.retrieveValidClaimsFileFormats.requested,
  retrieveInflationModelConfig:
    staticDataActions.retrieveInflationModelConfig.requested,
  retrieveAnalyticsConfig: staticDataActions.retrieveAnalyticsConfig.requested,
  retrieveSovTortCaps: staticDataActions.retrieveSovTortCaps.requested,
  retrieveLayerStatusLabels:
    staticDataActions.retrieveLayerStatusLabels.requested,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionApp);
