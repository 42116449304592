import * as logger from "common/logger";
import * as config from "config";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as staticDataActions from "store/actions/temp/staticData/staticDataActions";
import * as utils from "utils";

export function* retrieveValidClaimsFileFormats() {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.root("claims/valid-claims-file-formats")
    );
    const validClaimsFileFormats = yield call([response, response.json]);
    yield put(
      staticDataActions.retrieveValidClaimsFileFormats.succeeded(
        validClaimsFileFormats
      )
    );
  } catch (e) {
    logger.exception(e);
  }
}

export function* retrieveInflationModelConfig() {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims("inflation-model-config")
    );
    const inflationModelConfig = yield call([response, response.json]);
    yield put(
      staticDataActions.retrieveInflationModelConfig.succeeded(
        inflationModelConfig
      )
    );
  } catch (e) {
    logger.exception(e);
  }
}

export function* retrieveAnalyticsConfig() {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.staticData("retrieve-config"),
      {
        method: "post",
        body: JSON.stringify({ keys: ["analytics"] }),
      }
    );
    const responseJson = yield call([response, response.json]);
    yield put(
      staticDataActions.retrieveAnalyticsConfig.succeeded(
        responseJson?.analytics ?? {}
      )
    );
  } catch (e) {
    logger.exception(e);
  }
}

export function* retrieveSovTortCaps() {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.root("claims/exposure/sov-tort-caps")
    );
    const sovTortCaps = yield call([response, response.json]);
    yield put(staticDataActions.retrieveSovTortCaps.succeeded(sovTortCaps));
  } catch (e) {
    logger.exception(e);
  }
}

export function* retrieveConfig(action) {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.staticData("retrieve-config"),
      {
        method: "post",
        body: JSON.stringify({ keys: action.payload }),
      }
    );
    const cfg = yield call([response, response.json]);
    yield put(staticDataActions.retrieveConfig.succeeded(cfg));
  } catch (e) {
    logger.exception(e, { breadcrumb: { action } });
  }
}

export function* retrieveLayerStatusLabels() {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.root("claims/params/layer-status-labels")
    );
    const layerStatusLabels = yield call([response, response.json]);
    yield put(
      staticDataActions.retrieveLayerStatusLabels.succeeded(layerStatusLabels)
    );
  } catch (e) {
    logger.exception(e);
  }
}

export default function* staticDataSaga() {
  yield takeLatest(
    staticDataActions.retrieveValidClaimsFileFormats.requested,
    retrieveValidClaimsFileFormats
  );
  yield takeLatest(
    staticDataActions.retrieveInflationModelConfig.requested,
    retrieveInflationModelConfig
  );
  yield takeLatest(
    staticDataActions.retrieveAnalyticsConfig.requested,
    retrieveAnalyticsConfig
  );
  yield takeLatest(
    staticDataActions.retrieveSovTortCaps.requested,
    retrieveSovTortCaps
  );
  yield takeLatest(
    staticDataActions.retrieveLayerStatusLabels.requested,
    retrieveLayerStatusLabels
  );
  yield takeEvery(staticDataActions.retrieveConfig.requested, retrieveConfig);
}
