import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as catExposuresResolvers from "domain/catExposuresResolvers";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

const initialState = {};

const catExposuresSlice = createSlice({
  name: "catExposures",
  initialState,
  reducers: {
    setCatExposures: (state, action) => {
      const values = action?.payload ?? {};
      if (values?.constructor?.name !== "Object") {
        return;
      }
      return {
        ...initialState,
        ...values,
      };
    },
    updateCatExposures: (state, action) => {
      const values = action?.payload;
      if (values?.constructor?.name !== "Object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) =>
          action?.payload?.data?.state?.catExposures ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => initialState)
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, () => initialState);
  },
});

export const selectCatExposuresInput = (state) => state?.catExposures ?? null;

export const select = createSelector([selectCatExposuresInput], (input) =>
  catExposuresResolvers.resolveCatExposures(input)
);

export const {
  setCatExposures: set,
  updateCatExposures: update,
} = catExposuresSlice.actions;

export default catExposuresSlice;
