import MemoizedTowerTableRow from "./MemoizedTowerTableRow";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ConditionalTooltip from "components/common/ConditionalTooltip";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as towerPricingActions from "store/actions/pricing/tower/towerPricingActions";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const dealQuality = (good) => {
  if (good == null) {
    return "";
  }
  if (good < 0) {
    return "table-cell-bad-deal";
  }
  if (good > 0) {
    return "table-cell-good-deal";
  }
  return "";
};

const NumberCell = ({ value, cellStyle, good, ...numberFormatProps }) => {
  if (value == null || isNaN(value)) {
    return <TableCell align={"right"} />;
  } else {
    return (
      <TableCell align={"right"} style={cellStyle}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          defaultValue={""}
          decimalScale={0}
          className={`monospace ${dealQuality(good)}`}
          {...numberFormatProps}
          value={value}
        />
      </TableCell>
    );
  }
};

const COLUMNS = [
  {
    key: "addLayer",
    title: "",
    width: 0,
    align: "right",
    description: "",
    visibilityTag: "pricing.primaries.addLayer",
    Cell: ({ layerIndex, isNewRow, dispatch }) => (
      <TableCell align={"left"}>
        {isNewRow ? (
          <></>
        ) : (
          <IconButton
            icon={AddIcon}
            onClick={() => {
              dispatch?.setIsExpanded?.(false);
              dispatch?.addLayer?.(layerIndex);
            }}
          />
        )}
      </TableCell>
    ),
  },
  {
    key: "deleteLayer",
    title: "",
    width: 0,
    align: "right",
    description: "",
    visibilityTag: "pricing.primaries.deleteLayer",
    Cell: ({ layerIndex, isNewRow, dispatch }) => (
      <TableCell align={"left"}>
        {isNewRow ? (
          <></>
        ) : (
          <IconButton
            icon={DeleteIcon}
            onClick={() => {
              dispatch?.setIsExpanded?.(false);
              dispatch?.deleteLayer?.(layerIndex);
            }}
          />
        )}
      </TableCell>
    ),
  },
  {
    key: "numLayer",
    title: "",
    width: 0,
    align: "left",
    description: "",
    visibilityTag: "pricing.primaries.index",
    defaultVisibility: true,
    Cell: ({ layerIndex }) => <NumberCell value={layerIndex + 1} />,
  },
  {
    key: "limit",
    title: "Limit",
    width: 150,
    align: "right",
    description: "The layer limit.",
    visibilityTag: "pricing.primaries.limit",
    Cell: ({ layer, layerIndex, dispatch }) => (
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer?.limit || ""}
          fullWidth
          onChangeNumber={(limit) => dispatch?.updateLimit?.(layerIndex, limit)}
          name={"limit"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
    ),
  },
  {
    key: "attachment",
    title: "Attachment",
    width: 150,
    align: "right",
    description: "The layer attachment.",
    visibilityTag: "pricing.primaries.attachment",
    Cell: ({ layer, layerIndex, dispatch }) => (
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer?.attachment || ""}
          fullWidth
          onChangeNumber={(attachment) =>
            dispatch?.updateAttachment?.(layerIndex, attachment)
          }
          name={"attachment"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
    ),
  },
  {
    key: "comment",
    title: "Comment",
    width: 150,
    align: "right",
    description: "Description of Primary.",
    visibilityTag: "pricing.primaries.comment",
    Cell: ({ layer, layerIndex, dispatch }) => (
      <TableCell align={"right"}>
        <Tooltip title={layer?.comment ?? ""}>
          <TextField
            inputProps={{ style: { textAlign: "right" } }}
            value={layer?.comment || ""}
            fullWidth
            onChange={(e) =>
              dispatch?.updateComment?.(layerIndex, e.target.value)
            }
            name={"comment"}
            variant={"filled"}
            size={"small"}
            hiddenLabel
          />
        </Tooltip>
      </TableCell>
    ),
  },
];

export const TowerPrimariesTable = ({
  isNet,
  layers,
  componentVisibility,
  updateLimit,
  updateAttachment,
  updateComment,
  addLayer,
  deleteLayer,
}) => {
  const visibleColumns = COLUMNS.filter(
    (c) =>
      (isNet ? !c.hideNet : !c.hideGross) &&
      (c.visibilityTag === undefined ||
        (componentVisibility?.[c.visibilityTag] ?? c.defaultVisibility ?? true))
  );

  return (
    <>
      <Typography>{"Primaries"}</Typography>
      <TableContainer>
        <Table size={"small"} className={"condensed-table"}>
          <TableHead>
            <TableRow>
              {visibleColumns.map(
                ({ key, title, description, align, width, cellStyle }, i) => {
                  return (
                    <ConditionalTooltip
                      key={key}
                      conditional={description}
                      title={description}
                    >
                      <TableCell
                        align={align}
                        valign={"bottom"}
                        style={{
                          minWidth: width,
                          verticalAlign: "bottom",
                          ...cellStyle,
                        }}
                        key={i}
                      >
                        {title}
                      </TableCell>
                    </ConditionalTooltip>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...(layers ?? []), {}].map((layer, layerIndex) => (
              <MemoizedTowerTableRow
                dispatch={{
                  updateLimit,
                  updateAttachment,
                  updateComment,
                  addLayer,
                  deleteLayer,
                }}
                columns={visibleColumns}
                key={layerIndex}
                layer={layer}
                layerIndex={layerIndex}
                isNewRow={layerIndex === layers?.length}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export const mapDispatchToProps = {
  updateLimit: towerPricingActions.updatePrimariesLimit,
  updateAttachment: towerPricingActions.updatePrimariesAttachment,
  updateComment: towerPricingActions.updatePrimariesComment,
  addLayer: towerPricingActions.addPrimariesLayer,
  deleteLayer: towerPricingActions.deletePrimariesLayer,
};

export const mapStateToProps = (state) => {
  return {
    layers: towerPricingSelectors.getPrimariesLayers(state),
    componentVisibility:
      userSelectors.selectUserConfig(state).componentVisibility ?? {},
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TowerPrimariesTable);
