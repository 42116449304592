import * as Sentry from "@sentry/react";
import { isConvertableExtension, getExtension } from "common/files";
import * as config from "config";
import * as fileUtils from "fileUtils";
import { uploadEmailFromFiles } from "fileUtils";
import _ from "lodash";
import {
  call,
  put,
  select,
  takeLeading,
  takeEvery,
  delay,
} from "redux-saga/effects";
import * as errorActions from "store/actions/error/errorActions";
import * as types from "store/actions/input/attachments/attachmentsActionTypes";
import * as attachmentsActions from "store/actions/input/attachments/attachmentsActions";
import * as tempAttachActionTypes from "store/actions/temp/attachments/attachmentsTempActionTypes";
import * as tempAttachActions from "store/actions/temp/attachments/attachmentsTempActions";
import * as tempAttachSelect from "store/selectors/temp/attachments/tempAttachmentsSelectors";
import * as utils from "utils";

const getMimeType = (filename) => {
  const extension = getExtension(filename);
  switch (extension) {
    case "html":
      return "text/html";
    default:
      return "application/octet-stream";
  }
};

const STEP_LENGTH = 60;

export function* cacheAttachment(action) {
  try {
    let { download } = action.payload;
    const originalDownload = download;
    const cachedURL = yield select(
      (state) =>
        (state?.temp?.attachments?.cache || []).filter((a) =>
          _.isEqual(a.download, download)
        )[0]?.url
    );
    if (cachedURL != null) {
      return;
    }
    yield put({
      type: types.CACHING_ATTACHMENT,
      payload: { download },
    });
    if (isConvertableExtension(getExtension(download.filename))) {
      const htmlResult = yield call(
        utils.authenticatedFetch,
        config.endpoints.attachment("html"),
        {
          body: JSON.stringify(download),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      download = yield call([htmlResult, "json"]);
    }
    const locationResult = yield call(
      utils.authenticatedFetch,
      config.endpoints.attachment("download?" + new URLSearchParams(download))
    );
    const json = yield call([locationResult, "json"]);
    const awsUrl = json.aws;
    const dataResult = yield fetch(awsUrl);
    const blob = yield call([dataResult, "blob"]);
    const knownMimeType = getMimeType(download.filename);
    const url = URL.createObjectURL(blob.slice(0, blob.size, knownMimeType));
    yield put({
      type: types.CACHED_ATTACHMENT,
      payload: { download: originalDownload, url },
    });
  } catch (e) {
    Sentry.captureException(e);
  }
}

export function* newEmailAddress(action) {
  yield put(attachmentsActions.newEmailAddress.requested());
  const response = yield call(
    utils.authenticatedFetch,
    config.endpoints.email("get-email-address")
  );
  const json = yield call([response, "json"]);
  yield put(attachmentsActions.newEmailAddress.returned(json));
  yield put(attachmentsActions.monitorEmail.startSaga(json.target));
}

function* waitForEmail(target) {
  const delaySeconds = [1, 2, 3, 5, 10];
  const totalTime = delaySeconds.reduce((a, b) => a + b, 0) * STEP_LENGTH;
  let counter = totalTime;
  for (let delayIndex = 0; delayIndex < delaySeconds.length; delayIndex++) {
    for (let i = 0; i < STEP_LENGTH; i++) {
      const response = yield call(
        utils.authenticatedFetch,
        config.endpoints.email(
          "check-for-email?" + new URLSearchParams({ target })
        )
      );
      const json = yield call([response, response.json]);
      if (json.status !== "WAITING") {
        return json;
      }
      yield delay(delaySeconds[delayIndex] * 1000);
      counter = Math.max(counter - delaySeconds[delayIndex], 0);
      yield put(
        attachmentsActions.updateEmailTimer(target, counter, totalTime)
      );
    }
  }
  return null;
}

export function* monitorEmail(action) {
  try {
    const savedEmail = yield* waitForEmail(action.payload);
    if (savedEmail !== null) {
      yield put(
        attachmentsActions.monitorEmail.emailFound(action.payload, savedEmail)
      );
    } else {
      yield put(attachmentsActions.removeExpiredEmail(action.payload));
    }
  } catch (e) {
    yield put(attachmentsActions.monitorEmail.failed(action.payload));
    yield put(
      errorActions.handleError(e, {
        title: "Failed to monitor email.",
        message: `If you sent an email and it wasn't processed please contact ${config.SUPPORT_EMAIL}.`,
      })
    );
  }
}

export function* attachFromEmail(action) {
  try {
    yield put(attachmentsActions.attachFromEmail.started(action.payload));
    const meta = yield call(
      uploadEmailFromFiles,
      action.payload.target,
      action.payload.filename,
      action.payload.original
    );
    yield put(attachmentsActions.fileUploaded(meta.id));
    yield put(attachmentsActions.attachFromEmail.finished(action.payload));
  } catch (e) {
    yield put(
      errorActions.handleError(e, {
        title: "Failed to move email attachments.",
        message: `If the attachment opens fine from outlook please contact ${config.SUPPORT_EMAIL}.`,
      })
    );
  }
}

export function* loadAttachmentTree(action) {
  const fileId = action.payload.fileId;
  yield put(tempAttachActions.attachmentTreeRequested(fileId));
  while (
    yield select((state) => !tempAttachSelect.treeIsLoaded(state, fileId))
  ) {
    yield delay(500);
    const fileTree = yield call(fileUtils.fileStatusTree, fileId);
    yield put(tempAttachActions.receivedAttachmentTree(fileId, fileTree));
  }
}

export default function* attachmentsSaga() {
  yield takeLeading(types.CACHE_ATTACHMENT, cacheAttachment);
  yield takeEvery(
    attachmentsActions.newEmailAddress.startSaga.toString(),
    newEmailAddress
  );
  yield takeEvery(
    attachmentsActions.monitorEmail.startSaga.toString(),
    monitorEmail
  );
  yield takeEvery(
    attachmentsActions.attachFromEmail.startSaga.toString(),
    attachFromEmail
  );
  yield takeEvery(
    tempAttachActionTypes.REQUEST_ATTACHMENT_TREE,
    loadAttachmentTree
  );
}
