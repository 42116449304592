import { IconButton as MuiIconButton, Tooltip } from "@mui/material";
import React from "react";

const HoverIconButton = ({
  icon: Icon,
  error,
  className,
  isDisabled,
  scale,
  ...props
}) => {
  const icon = (
    <MuiIconButton
      {...props}
      disabled={isDisabled || props.disabled}
      className={
        (className ?? "hover-icon") + (error ? " button-has-error" : "")
      }
    >
      <Icon {...(scale && { fontSize: scale })} />
    </MuiIconButton>
  );
  return props?.tooltip ? (
    <Tooltip title={props.tooltip}>
      <span>{icon}</span>
    </Tooltip>
  ) : (
    icon
  );
};

const OrangeIconButton = ({
  icon: Icon,
  error,
  className,
  isDisabled,
  scale,
  ...props
}) => {
  const icon = (
    <MuiIconButton
      {...props}
      disabled={isDisabled || props.disabled}
      sx={{ color: "primary.main", ...props?.sx }}
    >
      <Icon {...(scale && { fontSize: scale })} />
    </MuiIconButton>
  );
  return props?.tooltip ? (
    <Tooltip title={props.tooltip}>
      <span>{icon}</span>
    </Tooltip>
  ) : (
    icon
  );
};

export const IconButton = ({ variant, icon, scale, ...props }) => {
  switch (variant) {
    case "bright":
      return <OrangeIconButton {...props} icon={icon} scale={scale} />;

    default:
      return <HoverIconButton {...props} icon={icon} scale={scale} />;
  }
};

export default IconButton;
