import * as userSelectors from "../../store/selectors/user/userSelectors";
import { useSelector } from "react-redux";

const Toggle = ({ trueChild, falseChild, byTag, defaultToggle = false }) => {
  const userConfig = useSelector(userSelectors.selectUserConfig);
  const stateIndependentVisibility =
    userConfig.componentVisibility?.[byTag] ?? defaultToggle;
  const stateVisibility =
    useSelector(userSelectors.getComponentOnStateVisibility)?.[byTag] ??
    (() => stateIndependentVisibility);
  const isVisible = useSelector(stateVisibility) ?? stateIndependentVisibility;

  if (isVisible) {
    return trueChild;
  } else {
    return falseChild;
  }
};

export default Toggle;
