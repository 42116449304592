import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";

const MockMode = () => (
  <AppBar position={"sticky"}>
    <Toolbar variant={"dense"}>
      <Typography variant={"h6"} color={"inherit"}>
        {"None of the buttons will work, you are in Mock Mode."}
      </Typography>
    </Toolbar>
  </AppBar>
);

export default MockMode;
