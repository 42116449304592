import Disabled from "./Disabled";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const stateNames = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District Of Columbia",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

const options = Object.entries(stateNames).map(([k, v]) => {
  return { label: `${v} (${k})`, id: k, name: v };
});

const UsStatesSelector = ({ stateId, onChange }) => {
  return (
    <Disabled ifReadOnly>
      <Autocomplete
        value={options.filter((option) => option.id === stateId)?.[0] ?? null}
        onChange={(_, value) => onChange(value?.id ?? null)}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"State"}
            variant={"filled"}
            InputLabelProps={{ shrink: true }}
          />
        )}
        autoHighlight
      />
    </Disabled>
  );
};

export default UsStatesSelector;
