import Component from "components/Component";
import Options from "components/common/Options";
import { useDispatch, useSelector } from "react-redux";
import * as rateChangeSlice from "store/rateChange";

const componentDefns = [
  {
    key: "structure",
    name: "Structure",
  },
  {
    key: "commission",
    name: "Commission",
  },
  {
    key: "exposure",
    name: "Exposure",
  },
  {
    key: "uwJudgment",
    name: "UW Judgment",
  },
  {
    key: "inflation",
    name: "Inflation",
  },
];

const RebaseSelector = () => {
  const dispatch = useDispatch();
  const activeLayerRateChange = useSelector(
    rateChangeSlice.selectActiveLayerRateChange
  );
  const options = componentDefns
    .filter(({ key }) => !activeLayerRateChange?.componentDisabled?.[key])
    .map((defn) => {
      return {
        id: defn.key,
        label: defn.name,
        selected: activeLayerRateChange?.rebaseAfter?.[defn.key],
      };
    });

  return (
    <Component
      title={"Rebase After"}
      subtitle={
        "Select after which commponents to rebase in the Rate Change calc."
      }
    >
      <Options
        items={options}
        onClick={({ id, selected }) =>
          dispatch(
            rateChangeSlice.updateLayerProperties({
              rebaseAfter: {
                ...activeLayerRateChange?.rebaseAfter,
                [id]: !selected,
              },
            })
          )
        }
      />
    </Component>
  );
};

export default RebaseSelector;
