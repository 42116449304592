import LoadingButton from "@mui/lab/LoadingButton";
import ConditionalTooltip from "components/common/ConditionalTooltip";
import DefaultLoader from "components/common/DefaultLoader";

const Button = ({
  children,
  onClick,
  sx,
  isLoading = false,
  isDisabled = false,
  hasError = false,
  errorTooltip = "",
  color = "primary",
  startIcon = null,
  endIcon = null,
  className = null,
  tooltip = null,
  tooltipRequired = null,
  ...props
}) => {
  const dispTooltip = hasError || tooltipRequired;
  const tooltipMessage = hasError ? errorTooltip : tooltip;
  const cls =
    (hasError ? "button-has-error " : "") + (className ? className + " " : "");
  return (
    <ConditionalTooltip title={tooltipMessage} conditional={dispTooltip}>
      <span data-testid={props["data-testid"]}>
        <LoadingButton
          onClick={onClick}
          variant={"contained"}
          disableElevation
          color={color}
          disabled={isDisabled || props.disabled}
          loading={isLoading}
          loadingIndicator={<DefaultLoader />}
          className={cls}
          startIcon={startIcon}
          endIcon={endIcon}
          sx={sx}
        >
          {children}
        </LoadingButton>
      </span>
    </ConditionalTooltip>
  );
};

export default Button;
