import * as config from "config";

export class ValueMapperController {
  constructor({ key, label, options }) {
    this.key = key;
    this.label = label;
    this.options = options;
  }

  valid(val) {
    if (val === config.CLAIMS_MAPPING_IGNORE_VALUE) return true;
    return this.options.filter((op) => op.key === val).length > 0;
  }
}
