import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as baseLayerBurnResolvers from "domain/baseLayerBurnResolvers";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const initialState = {};

const baseLayerBurnSlice = createSlice({
  name: "baseLayerBurn",
  initialState,
  reducers: {
    setBaseLayerBurn: (state, action) => {
      const values = action?.payload ?? {};
      if (values?.constructor?.name !== "Object") {
        return;
      }
      return {
        ...initialState,
        ...values,
      };
    },
    updateBaseLayerBurn: (state, action) => {
      const values = action?.payload;
      if (values?.constructor?.name !== "Object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) =>
          action?.payload?.data?.state?.baseLayerBurn ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, (_, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.baseLayerBurn,
        };
      })
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (state, action) => {
        const out = {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.baseLayerBurn,
        };
        const renewedKeys = ["limit", "attachment"];
        renewedKeys.forEach((key) => {
          if (state[key] != null) {
            out[key] = state[key];
          }
        });
        return out;
      });
  },
});

export const selectBaseLayerBurnInput = (state) => state?.baseLayerBurn ?? null;

export const selectBaseLayerBurn = createSelector(
  [selectBaseLayerBurnInput],
  (baseLayerBurnInput) =>
    baseLayerBurnResolvers.resolveBaseLayerBurn(baseLayerBurnInput)
);

export const {
  setBaseLayerBurn: set,
  updateBaseLayerBurn: update,
} = baseLayerBurnSlice.actions;

export default baseLayerBurnSlice;
