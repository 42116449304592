import { Box } from "@mui/material";
import * as aggregates from "common/aggregates";
import { hasItems } from "common/arrays";
import * as formulae from "common/formulae";
import Metrics from "components/common/Metrics";
import * as staticDataService from "services/staticDataService";

const context = "review metrics";

const extractBaseMetrics = (submissions) => {
  const metrics = {
    submissionCount: aggregates.aggregatedByKey(
      aggregates.METHOD.COUNT_UNIQUE,
      "submissionId",
      submissions,
      context
    ),
    layerCount: aggregates.aggregatedByKey(
      aggregates.METHOD.COUNT,
      "submissionId",
      submissions,
      context
    ),
    layerStats: {},
  };

  if (!hasItems(submissions)) {
    return metrics;
  }

  submissions.forEach((submission) => {
    if (metrics.layerStats[submission.status] == null) {
      metrics.layerStats[submission.status] = 0;
    }
    metrics.layerStats[submission.status]++;
  });

  return metrics;
};

const extractCustomMetrics = (submissions, config) => {
  if (!hasItems(config)) {
    return [];
  }

  const metrics = config.map((x) => {
    const value =
      x.formula != null
        ? formulae.apply(x.formula, submissions, context)
        : aggregates.aggregatedByKey(x.method, x.key, submissions, context);

    return {
      ...x,
      value: value,
    };
  });

  return metrics;
};

const statusMeta = (status, labelsConfig) => {
  const colours = {
    CLOSED: "#ededec",
    OPPORTUNITY: "gold",
    IN_PROGRESS: "dodgerblue",
    NOT_TAKEN_UP: "mediumslateblue",
    BOUND: "mediumseagreen",
    UNKNOWN: "#ededec",
  };

  const config = labelsConfig.filter((x) => x.statusKey === status)[0];

  return {
    label: config?.displayName ?? "Unknown",
    colour: colours[config?.icon] ?? colours["UNKNOWN"],
  };
};

const chartData = (layerStats, labelsConfig) => {
  if (layerStats == null || labelsConfig == null) {
    return null;
  }

  const data = Object.entries(layerStats).map(([k, v]) => {
    return { value: v, ...statusMeta(k, labelsConfig) };
  });

  return data;
};

const metricsToDisplay = (baseMetrics, customMetrics, labelsConfig) => {
  const toDisplay = [
    {
      label: "Submissions",
      value: baseMetrics.submissionCount,
    },
    {
      label: "Layers",
      value: baseMetrics.layerCount,
    },
    ...customMetrics,
    {
      label: "Status",
      data: chartData(baseMetrics.layerStats, labelsConfig),
    },
  ];

  return toDisplay;
};

const DEFAULT_CONFIG = [
  {
    method: aggregates.METHOD.MAX,
    key: "limit",
    label: "Largest Single Limit",
    prefix: "$",
  },
  {
    method: aggregates.METHOD.SUM,
    key: "limit",
    label: "Total Limit",
    prefix: "$",
  },
  {
    method: aggregates.METHOD.SUM,
    key: "premium",
    label: "Total GWP",
    prefix: "$",
  },
  {
    method: aggregates.METHOD.SUM,
    key: "brokeragePremium",
    label: "Total Brokerage",
    prefix: "$",
  },
];

const ReviewMetrics = ({ submissions }) => {
  const {
    data: metricsConfig,
    isLoading: metricsConfigLoading,
  } = staticDataService.useConfigQuery("submission_review_config");

  const { data: labelsConfig } = staticDataService.useConfigQuery(
    "layer_status_labels_v2"
  );

  if (metricsConfigLoading) {
    return <></>;
  }

  const toDisplay = metricsToDisplay(
    extractBaseMetrics(submissions),
    extractCustomMetrics(
      submissions,
      metricsConfig?.reviewMetrics ?? DEFAULT_CONFIG
    ),
    labelsConfig?.labels
  );

  return (
    <Box
      sx={{
        margin: "0.5rem 0",
        padding: "0.875rem 0.375rem",
        background: "#edf0f3",
        borderRadius: "0.625rem",
      }}
    >
      <Metrics metrics={toDisplay} />
    </Box>
  );
};

export default ReviewMetrics;
