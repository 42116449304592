import { Box, Typography } from "@mui/material";
import * as chartUtils from "chartUtils";
import * as numbers from "common/numbers";
import Component from "components/Component";
import React from "react";
import { connect } from "react-redux";
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import * as towerSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

export const parseData = (currentLayers, priorLayers) => {
  const currentData = currentLayers
    ? currentLayers.map((layer) => {
        const attachment = numbers.parseFloatOrNull(layer.attachment) || 0;
        const limit = numbers.parseFloatOrNull(layer.limit) || 0;
        const midpoint = attachment + limit / 2;
        return {
          midpoint,
          currentRate: numbers.parseFloatOrNull(layer.grossRPM),
        };
      })
    : [];

  const priorData = priorLayers
    ? priorLayers.map((layer) => {
        const attachment = numbers.parseFloatOrNull(layer.attachment) || 0;
        const limit = numbers.parseFloatOrNull(layer.limit) || 0;
        const midpoint = attachment + limit / 2;
        return {
          midpoint,
          priorRate: numbers.parseFloatOrNull(layer.grossRPM),
        };
      })
    : [];

  const priorDataMap = priorData.reduce((acc, item) => {
    acc[item.midpoint] = item.priorRate;
    return acc;
  }, {});

  const mergedMidpoints = new Set([
    ...currentData.map((item) => item.midpoint),
    ...priorData.map((item) => item.midpoint),
  ]);

  const mergedData = Array.from(mergedMidpoints).map((midpoint) => ({
    midpoint,
    currentRate:
      currentData.find((item) => item.midpoint === midpoint)?.currentRate ||
      null,
    priorRate: priorDataMap[midpoint] || null,
  }));

  return { data: mergedData };
};

const TowerRateChartComponent = (props) => {
  const title = "Rate Per Million vs Layer Midpoint";
  const { currentLayers, priorLayers } = props;

  const hasCurrentData = currentLayers && currentLayers.length > 0;
  const hasPriorData = priorLayers && priorLayers.length > 0;

  const chart = parseData(currentLayers, priorLayers);

  const hasValidData =
    chart.data &&
    chart.data.length > 0 &&
    chart.data.some(
      (item) => item.currentRate != null || item.priorRate != null
    );

  if ((!hasCurrentData && !hasPriorData) || !hasValidData) {
    return (
      <Component title={title} lg_width={6} md_width={12}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"100%"}
          padding={2}
        >
          <Typography variant={"h6"} color={"textSecondary"}>
            {"No Tower Data"}
          </Typography>
        </Box>
      </Component>
    );
  }

  const {
    chartRef,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  const chartMargins = props.chartMargins || {
    top: 20,
    right: 30,
    left: 20,
    bottom: 5,
  };

  const defaultStyles = props.styles || {
    position: "relative",
    width: "100%",
    paddingBottom: "500px",
  };

  return (
    <Component
      title={title}
      lg_width={6}
      md_width={12}
      titleStyles={props.titleStyles}
    >
      <div style={defaultStyles}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <ComposedChart
              data={chart.data}
              margin={chartMargins}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray={"3 3"} />

              <XAxis
                type={"number"}
                dataKey={"midpoint"}
                name={"Layer Midpoint"}
                domain={[0, "dataMax"]}
                tickFormatter={(value) => numbers.abbreviated(value)}
                label={{
                  value: "Layer Midpoint",
                  position: "insideBottom",
                  offset: -5,
                }}
              />

              <YAxis
                domain={[0, (dataMax) => numbers.roundUpNice(dataMax)]}
                tickFormatter={(value) => numbers.abbreviated(value)}
                label={{
                  value: "Rate Per Million",
                  angle: -90,
                  position: "insideLeft",
                }}
              />

              <Tooltip
                isAnimationActive={false}
                formatter={(value) => numbers.abbreviated(value)}
                labelFormatter={(label) =>
                  `Midpoint: ${numbers.abbreviated(label)}`
                }
              />

              <Legend verticalAlign={"top"} height={36} />

              {hasPriorData && hasValidData && (
                <Line
                  type={"monotone"}
                  dataKey={"priorRate"}
                  stroke={"#FFAB53"}
                  name={"Prior Tower Line"}
                  strokeWidth={3}
                  dot={{ r: 4 }}
                  strokeDasharray={"5 5"}
                />
              )}

              {hasCurrentData && hasValidData && (
                <Line
                  type={"monotone"}
                  dataKey={"currentRate"}
                  stroke={"#FF634E"}
                  name={"Current Tower Line"}
                  strokeWidth={3}
                  dot={{ r: 4 }}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Component>
  );
};

const mapStateToProps = (state) => {
  const currentLayers = towerSelectors.getTower(state);
  const priorState = stateSelectors.selectPriorState(state);
  const priorLayers = towerSelectors.getTower(priorState);

  return {
    currentLayers,
    priorLayers,
  };
};

export default connect(mapStateToProps)(TowerRateChartComponent);
