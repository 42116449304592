import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { memo } from "react";
import NumberFormat from "react-number-format";

const ChangeArrowIcon = ({ priorValue, currentValue, invertColours }) => {
  if (priorValue > currentValue) {
    return (
      <span className={invertColours ? "up" : "down"}>
        <TrendingDownIcon className={"arrow"} />
      </span>
    );
  } else if (priorValue < currentValue) {
    return (
      <span className={invertColours ? "down" : "up"}>
        <TrendingUpIcon className={"arrow"} />
      </span>
    );
  } else {
    return (
      <span className={"flat"}>
        <TrendingFlatIcon className={"arrow"} />
      </span>
    );
  }
};

const ChangeArrowValue = ({ priorValue, currentValue }) => {
  if (priorValue === 0) {
    return currentValue === 0 ? "" : "\u221e";
  }
  return (
    <NumberFormat
      value={(currentValue / priorValue - 1) * 100}
      suffix={"%"}
      decimalScale={0}
      displayType={"text"}
    />
  );
};

const ChangeArrow = ({ priorValue, currentValue, invertColours = false }) => {
  if (currentValue == null || priorValue == null) {
    return <></>;
  }
  return (
    <table className={"trending"}>
      <tbody>
        <tr className={"arrowIcon"}>
          <td>
            <ChangeArrowIcon
              priorValue={priorValue}
              currentValue={currentValue}
              invertColours={invertColours}
            />
          </td>
        </tr>
        <tr className={"arrowValue"}>
          <td>
            <ChangeArrowValue
              priorValue={priorValue}
              currentValue={currentValue}
              invertColors={invertColours}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default memo(ChangeArrow);
