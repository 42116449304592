import PricingTableRow from "./PricingTableRow";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { toDecimalFromPercent, toPercentFromDecimal } from "common/numbers";
import DefaultLoader from "components/common/DefaultLoader";
import Disabled from "components/common/Disabled";
import * as config from "config";
import React from "react";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const columns = [
  {
    name: "Expected Loss Cost",
    description: "The expected amount of annual loss.",
    key: "purePremium",
    prefix: "$",
    decimalScale: 0,
    isOverrideColumn: true,
    defaultVisible: true,
    visibilityKey: "purePremium",
  },
  {
    name: "Pure Premium",
    description:
      "The expected amount of premium required to cover average losses.",
    key: "purePremium",
    suffix: "%",
    decimalScale: 3,
    isOverrideColumn: true,
    defaultVisible: false,
    f: toPercentFromDecimal,
    inverseF: toDecimalFromPercent,
    visibilityKey: "purePremiumPercent",
  },
  {
    name: "Required 100% Premium",
    description:
      "The amount of premium needed to be charged to cover the Expected Loss Cost after Brokerage, Other Acquisition Costs, Expenses and Profit.",
    key: "requiredPremium",
    prefix: "$",
    decimalScale: 0,
    defaultVisible: true,
    visibilityKey: "requiredPremium",
  },
  {
    name: "Required 100% Premium",
    description:
      "The amount of premium needed to be charged to cover the average losses after Brokerage, Other Acquisition Costs, Expenses and Profit.",
    key: "requiredPremium",
    suffix: "%",
    decimalScale: 3,
    defaultVisible: false,
    f: toPercentFromDecimal,
    visibilityKey: "requiredPremiumPercent",
  },
  {
    name: "Target Price (TP)",
    description: config.DEFINITION_TP,
    key: "targetPrice",
    suffix: "%",
    decimalScale: 2,
    goodScaler: (value) => (value ?? 100) - 100,
    defaultVisible: true,
    visibilityKey: "targetPrice",
  },
  {
    name: "Loss Ratio",
    description: "Expected Loss Cost / 100% Premium",
    key: "lossRatio",
    suffix: "%",
    decimalScale: 2,
    defaultVisible: true,
    visibilityKey: "lossRatio",
  },
  {
    name: "GNULR",
    description: config.DEFINITION_GNULR,
    key: "technicalRatio",
    suffix: "%",
    decimalScale: 2,
    defaultVisible: true,
    visibilityKey: "technicalRatio",
  },
];

export const PricingTableComponent = ({
  layer,
  analyticsConfig,
  modelConfig,
  prices,
  selectedPrice,
  setSelectedModel,
  setSelectedPrice,
  refreshPricing,
  columns,
}) => {
  const models = analyticsConfig?.layerPricing?.models ?? [];
  return (
    <Grid container>
      {models.length > 1 && (
        <Grid item container xs={12} justifyContent={"right"}>
          <FormControl variant={"filled"}>
            <InputLabel>{"Pricing Model"}</InputLabel>
            <Select
              onChange={(e) => setSelectedModel({ model: e.target.value })}
              value={layer.pricing?.selectedModel ?? ""}
            >
              {models.map((model) => (
                <MenuItem key={model.key} value={model.key}>
                  {model.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell />
                {columns.map((column, i) => {
                  return (
                    <Tooltip key={i} title={column.description}>
                      <TableCell align={"right"}>{column.name}</TableCell>
                    </Tooltip>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(modelConfig?.measures ?? [])
                .filter(
                  (measureConfig) =>
                    !measureConfig.excludeIfEmpty ||
                    Object.keys(prices?.[measureConfig.key] ?? {}).length > 0
                )
                .map((measureConfig) => {
                  return (
                    <PricingTableRow
                      key={measureConfig.key}
                      meta={measureConfig}
                      values={prices[measureConfig.key]}
                      columns={columns}
                    />
                  );
                })}
              {modelConfig?.selectedPrice?.isVisible && (
                <PricingTableRow
                  meta={modelConfig.selectedPrice}
                  values={selectedPrice}
                  columns={columns}
                  onChangeNumber={
                    modelConfig.selectedPrice.isEditable
                      ? (value) =>
                          setSelectedPrice({
                            model: modelConfig.key,
                            value,
                          })
                      : undefined
                  }
                />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item xs={12} justifyContent={"flex-end"}>
        <Disabled ifReadOnly>
          <div data-testid={"calculate-pricing"}>
            <Button
              onClick={refreshPricing}
              variant={"contained"}
              disableElevation
              color={"primary"}
              className={"button button-fixed-width"}
              sx={{ marginTop: config.GRID_SPACING }}
            >
              {layer.loadingPurePremium ? (
                <DefaultLoader
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "3px",
                  }}
                  size={16}
                />
              ) : (
                "Calculate"
              )}
            </Button>
          </div>
        </Disabled>
      </Grid>
    </Grid>
  );
};

export const mapStateToProps = (state) => {
  const userConfig = userSelectors.selectUserConfig(state);
  const isColumnVisible = (column) => {
    return (
      userConfig.componentVisibility?.[
        "pricing.layerPricing.pricingTable." + column.visibilityKey
      ] ?? column.defaultVisible
    );
  };
  return {
    columns: columns.filter(isColumnVisible),
    layer: pricingSelectors.getActiveLayer(state) ?? {},
    analyticsConfig: staticDataSelectors.selectAnalyticsConfig(state),
    modelConfig: pricingSelectors.selectActiveLayerPricingSelectedModelConfig(
      state
    ),
    prices: pricingSelectors.selectActiveLayerPrices(state),
    selectedPrice: pricingSelectors.selectActiveLayerSelectedPrice(state),
  };
};

const mapDispatchToProps = {
  setSelectedModel: pricingActions.setActiveLayerPricingSelectedModel,
  setSelectedPrice: pricingActions.setActiveLayerPricingSelectedPrice,
  refreshPricing: pricingActions.refreshActiveLayerPricing,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricingTableComponent);
