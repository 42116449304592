import {
  Collapse,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import moment from "moment";
import { useEffect, useState } from "react";

export const buildDateRange = (intervalType, { fromDate = null } = {}) => {
  fromDate = moment.parseZone(fromDate ?? new Date());

  if (intervalType === "All Dates") {
    return [null, null];
  } else if (intervalType === "Last Month") {
    const end = fromDate.endOf("month").add(1, "days").add(-1, "month");
    const start = moment(end).add(-1, "month");
    return [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
  } else if (intervalType === "Last Quarter") {
    const end = fromDate.endOf("quarter").add(1, "days").add(-1, "quarter");
    const start = moment(end).add(-1, "quarter");
    return [start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD")];
  } else if (intervalType === "Custom") {
    return [fromDate.format("YYYY-MM-DD"), fromDate.format("YYYY-MM-DD")];
  } else if (intervalType === "After") {
    return [fromDate.format("YYYY-MM-DD"), null];
  } else if (intervalType === "Before") {
    return [null, fromDate.format("YYYY-MM-DD")];
  } else {
    return null;
  }
};

const normalizeDate = (v) => (v == null || v === "" ? null : v);

const Transition = ({ children }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) setOpen(true);
  }, [open, setOpen]);

  return (
    <Collapse in={open} orientation={"horizontal"}>
      {children}
    </Collapse>
  );
};

const IntervalInput = ({ intervalType, interval, onSetInterval }) => {
  const [start, end] = interval;

  if (intervalType === "Custom") {
    return (
      <Transition key={"custom"}>
        <Stack direction={"row"}>
          <FormattedDatePicker
            label={"Start"}
            onChangeDate={(date) => {
              const newStart = normalizeDate(date);
              onSetInterval([newStart, newStart > end ? newStart : end]);
            }}
            value={start ?? ""}
            sx={{ marginLeft: 1, marginRight: 1 }}
          />
          <FormattedDatePicker
            label={"End"}
            onChangeDate={(date) => {
              const newEnd = normalizeDate(date);
              onSetInterval([newEnd < start ? newEnd : start, newEnd]);
            }}
            value={end ?? ""}
            sx={{ marginLeft: 1, marginRight: 1 }}
          />
        </Stack>
      </Transition>
    );
  } else if (intervalType === "After") {
    return (
      <Transition key={"after"}>
        <FormattedDatePicker
          label={"After"}
          onChangeDate={(date) => {
            onSetInterval([normalizeDate(date), null]);
          }}
          value={start ?? ""}
          sx={{ marginLeft: 1, marginRight: 1 }}
        />
      </Transition>
    );
  } else if (intervalType === "Before") {
    return (
      <Transition key={"before"}>
        <FormattedDatePicker
          label={"Before"}
          onChangeDate={(date) => {
            onSetInterval([null, normalizeDate(date)]);
          }}
          value={end ?? ""}
          sx={{ marginLeft: 1, marginRight: 1 }}
        />
      </Transition>
    );
  } else if (start && end) {
    return (
      <Typography variant={"subtitle2"} sx={{ paddingLeft: 2 }}>
        {`${start} to ${end}`}
      </Typography>
    );
  } else {
    return null;
  }
};

const DateRangeInput = ({ defn, options, setOptions }) => {
  const [intervalType, setIntervalType] = useState("All Dates");

  const setInterval = ([start, end]) => {
    setOptions({
      ...options,
      [defn.nameGte]: start,
      [defn.nameLt]: end,
    });
  };

  const updateIntervalType = (newRangeType) => {
    try {
      setInterval(buildDateRange(newRangeType));
      setIntervalType(newRangeType);
    } catch {}
  };

  const interval = [options[defn.nameGte], options[defn.nameLt]];

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <FormControl variant={"filled"}>
        <InputLabel>{defn.label}</InputLabel>
        <Select
          value={intervalType}
          onChange={(event) => updateIntervalType(event.target.value)}
          sx={{ minWidth: 180 }}
        >
          <MenuItem value={"All Dates"}>{"All Dates"}</MenuItem>
          <MenuItem value={"Last Month"}>{"Last Month"}</MenuItem>
          <MenuItem value={"Last Quarter"}>{"Last Quarter"}</MenuItem>
          <MenuItem value={"Custom"}>{"Custom Range"}</MenuItem>
          <MenuItem value={"After"}>{"After"}</MenuItem>
          <MenuItem value={"Before"}>{"Before"}</MenuItem>
        </Select>
      </FormControl>
      <IntervalInput
        intervalType={intervalType}
        interval={interval}
        onSetInterval={setInterval}
      />
    </Stack>
  );
};

export default DateRangeInput;
