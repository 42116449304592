import {
  Alert,
  Avatar,
  Box,
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
} from "@mui/material";
import { readableDate } from "common/dates";
import { extractNameFromEmail, stringToColor } from "common/strings";
import Component from "components/Component";
import AdminDashboard from "components/admin/AdminDashboard";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import InlineSearch from "components/common/InlineSearch";
import DashboardContent from "components/layout/DashboardContent";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useListAuditLogsQuery } from "services/adminService";

const EmptyRow = ({ children }) => (
  <TableRow>
    <TableCell colSpan={4}>{children}</TableCell>
  </TableRow>
);

const TableContent = ({ status, auditLogs }) => {
  if (status === "failed") {
    return (
      <EmptyRow>
        <Alert severity={"error"}>{"Error reading logs"}</Alert>
      </EmptyRow>
    );
  }

  if (status === "fulfilled") {
    if (!auditLogs.logs || auditLogs.logs.length === 0) {
      return (
        <EmptyRow>
          <Typography textAlign={"center"} padding={6}>
            {
              "No logs match the selected filters, or a company needs to be selected"
            }
          </Typography>
        </EmptyRow>
      );
    }

    const compareDates = (a, b) => {
      const d1 = new Date(a.date);
      const d2 = new Date(b.date);
      return d2.getTime() - d1.getTime();
    };

    const reverseSortedLogs = [...(auditLogs?.logs ?? [])].sort(compareDates);

    return reverseSortedLogs.map((log, index) => {
      const emailMeta = extractNameFromEmail(log.email);
      const avatarSx = {
        width: "1.625rem",
        height: "1.625rem",
        fontSize: "0.75rem",
        fontWeight: 600,
        bgcolor: stringToColor(log.email),
      };
      return (
        <TableRow key={index}>
          <TableCell>{readableDate(log.date, { includeTime: true })}</TableCell>
          <TableCell>
            <Tooltip title={log.email}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Avatar sx={avatarSx}>{emailMeta.initials}</Avatar>
                <span>{emailMeta.name}</span>
              </Stack>
            </Tooltip>
          </TableCell>
          <TableCell>{log.userMessage}</TableCell>
          <TableCell>{log.description}</TableCell>
        </TableRow>
      );
    });
  }

  return (
    <EmptyRow>
      <BigLoader />
    </EmptyRow>
  );
};

const AuditLogs = () => {
  const { companyId } = useParams();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [email, setEmail] = useState(null);
  const validDate = (d) => {
    if (!d || d.length < 10) return null;
    if (d.length > 10) d = d.substring(0, 10);
    if (/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(d)) return d;
    return null;
  };
  const validEmail = (e) => {
    if (!e) return null;
    return e;
  };
  const {
    data: auditLogs,
    status: auditLogsStatus,
    refetch: refetchAuditLogs,
  } = useListAuditLogsQuery({
    companyId: companyId || "ROOT",
    startDate: validDate(startDate),
    endDate: validDate(endDate),
    email: validEmail(email),
  });

  return (
    <AdminDashboard>
      <DashboardContent isFullWidth={true}>
        <Component>
          <Stack flexDirection={"row"} gap={1.5} alignItems={"center"}>
            <InlineSearch
              placeholder={"Search by email"}
              onChange={(searchText) => setEmail(searchText)}
            />
            <Box flexGrow={1} />
            <FormattedDatePicker
              label={"Start Date"}
              compact
              value={startDate}
              onChangeDate={setStartDate}
            />
            <FormattedDatePicker
              label={"End Date"}
              compact
              value={endDate}
              onChangeDate={setEndDate}
            />
            <Button onClick={refetchAuditLogs}>{"Refresh"}</Button>
          </Stack>
        </Component>

        <Component>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{"Date"}</TableCell>
                  <TableCell>{"User"}</TableCell>
                  <TableCell>{"Reason"}</TableCell>
                  <TableCell>{"Action"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableContent status={auditLogsStatus} auditLogs={auditLogs} />
              </TableBody>
            </Table>
          </TableContainer>
        </Component>
      </DashboardContent>
    </AdminDashboard>
  );
};

export default AuditLogs;
