import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import * as numbers from "common/numbers";

const ChartLegend = ({ data }) => (
  <Box>
    {data.map((datum, index) => (
      <Stack
        key={index}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography
          variant={"subtitle2"}
          sx={{ color: datum.labelColour || datum.colour }}
        >
          {datum.label}
        </Typography>
        <Typography variant={"subtitle2"} pl={2}>
          {datum.value}
        </Typography>
      </Stack>
    ))}
  </Box>
);

const MiniBarChart = ({
  data,
  chartHeight,
  barWidth = 10,
  gap = 3,
  minBarHeight = 1,
}) => {
  if (!data || data.length === 0) {
    return (
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          height: chartHeight + "px",
          width: "100%",
          borderRadius: "3px",
          border: "2px solid #edf0f3",
        }}
      >
        <Typography variant={"caption"} color={"dimgray"}>
          {"No Data"}
        </Typography>
      </Stack>
    );
  }

  const barHeight = chartHeight - 6;
  const max = Math.max(
    data.reduce((acc, cur) => Math.max(acc, cur.value), 0),
    1
  );

  const displayData = data.map((datum) => {
    return {
      ...datum,
      height: Math.max(
        Math.round((barHeight * datum.value) / max),
        minBarHeight
      ),
    };
  });

  return (
    <Tooltip title={<ChartLegend data={data} />}>
      <Stack
        direction={"row"}
        alignItems={"flex-end"}
        gap={gap + "px"}
        sx={{
          height: chartHeight + "px",
          borderBottom: "1px solid gainsboro",
          paddingBottom: "1px",
        }}
      >
        {displayData.map((datum, index) => {
          const barStyle = {
            width: barWidth + "px",
            height: datum.height + "px",
            borderTopLeftRadius: Math.max(Math.round(barWidth / 5), 2) + "px",
            borderTopRightRadius: Math.max(Math.round(barWidth / 5), 2) + "px",
            background: datum.colour || "grey",
          };
          return <Box sx={barStyle} key={index}></Box>;
        })}
      </Stack>
    </Tooltip>
  );
};

const DisplayText = ({ metric, size }) => {
  const large = size === "large";

  const valueVariant = large ? "h4" : "h5";
  const valueHeight = large ? "42px" : "28px";
  const suffixVariant = large ? "subtitle2" : "caption";
  const suffixPadding = large ? 1 : 0.5;

  const valueLabel =
    typeof metric.value === "number"
      ? `${metric.prefix || ""}${
          metric.dp != null
            ? numbers.dpString(metric.dp)(metric.value)
            : numbers.abbreviated(metric.value)
        }`
      : metric.value;

  return (
    <Typography variant={valueVariant} height={valueHeight}>
      {valueLabel}
      {metric.suffix && (
        <Typography
          variant={suffixVariant}
          display={"inline-block"}
          pl={suffixPadding}
        >
          {metric.suffix}
        </Typography>
      )}
    </Typography>
  );
};

const Metrics = ({ metrics, size, inline }) => {
  if (!metrics || metrics.length === 0) {
    return <></>;
  }

  size = (size || "").trim().toLowerCase() === "large" ? "large" : null;

  const margin = size === "large" ? 2 : 0.5;
  const chartHeight = size === "large" ? 42 : 28;
  const labelVariant = size === "large" ? null : "body2";
  const tipFontSize = size === "large" ? "1.25rem" : "0.9375rem";

  return (
    <Box
      sx={{
        display: inline ? "inline-flex" : "flex",
        justifyContent: inline ? "flex-start" : "space-evenly",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: inline ? "2rem" : null,
      }}
    >
      {metrics.map((metric, metricIndex) => (
        <Stack alignItems={"center"} m={margin} key={metricIndex}>
          {metric.data ? (
            <MiniBarChart data={metric.data} chartHeight={chartHeight} />
          ) : (
            <DisplayText metric={metric} size={size} />
          )}
          <Stack direction={"row"} alignItems={"center"} mt={0.5}>
            <Typography variant={labelVariant}>{metric.label}</Typography>
            {metric.tip && (
              <Tooltip title={metric.tip}>
                <InfoIcon
                  sx={{
                    fontSize: tipFontSize,
                    marginLeft: "0.25rem",
                    fill: "#a0a8ad",
                  }}
                />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      ))}
    </Box>
  );
};

export default Metrics;
