import Item from "./Item";
import { Link, Stack } from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import { useDispatch, useSelector } from "react-redux";
import * as endorsementsStore from "store/endorsements";

export const Section = ({ section, config }) => {
  const dispatch = useDispatch();

  const endorsements = useSelector(endorsementsStore.selectInput);

  const setSelected = (selected) => {
    const newValues = { ...endorsements?.values };
    section?.items?.forEach((itemKey) => {
      newValues[itemKey] = {
        ...newValues[itemKey],
        selected,
      };
    });
    dispatch(
      endorsementsStore.update({
        values: newValues,
      })
    );
  };

  return (
    <Component
      title={section?.label}
      options={
        section?.showSelectAll ? (
          <Stack direction={"row"} spacing={1}>
            <Disabled ifReadOnly>
              <Link
                component={"button"}
                variant={"body2"}
                color={"secondary"}
                onClick={() => setSelected(true)}
                sx={{ display: "block", marginBottom: "8px" }}
              >
                {"Select All"}
              </Link>
            </Disabled>
            <Disabled ifReadOnly>
              <Link
                component={"button"}
                variant={"body2"}
                color={"secondary"}
                onClick={() => setSelected(false)}
                sx={{ display: "block", marginBottom: "8px" }}
              >
                {"Deselect All"}
              </Link>
            </Disabled>
          </Stack>
        ) : null
      }
    >
      <Stack direction={"column"} spacing={1}>
        {section?.items.map((itemKey) => (
          <Item key={itemKey} itemKey={itemKey} config={config} />
        ))}
      </Stack>
    </Component>
  );
};

export default Section;
