import * as numbers from "common/numbers";

export const resolvePricingInputs = (pricing) => {
  const out = {
    ...pricing?.pricingInputs,
    baseLayer: _resolvePricingInputsBaseLayer(pricing),
    towerPricingScalingLayer: pricing?.towerPricingScalingLayer,
  };
  if ("burningCost" in out) {
    out.burningCost = _resolveBurningCostInputs(out.burningCost);
  }
  return out;
};

const _resolveBurningCostInputs = (inputs) => {
  return {
    ...inputs,
    layers: Object.fromEntries(
      Object.entries(inputs?.layers ?? {}).map(([key, values]) => [
        key,
        _resolveBurningCostLayerInputs(values),
      ])
    ),
  };
};

const _resolveBurningCostLayerInputs = (inputs) => {
  return {
    ...inputs,
    selectedBurningCost: inputs?.selectedBurningCostSource?.key
      ? inputs?.pureBurningCostPricing?.[inputs.selectedBurningCostSource.key]
          ?.value?.price ?? null
      : inputs?.selectedBurningCostSource?.override ?? null,
  };
};

const _resolvePricingInputsBaseLayer = (pricing) => {
  const baseLayer = pricing?.baseLayer ?? {};

  const underwriterSelectedExperienceClaimsCount = numbers.parseFloatOrNull(
    pricing?.frequency?.underwriterSelectedNumberOfClaims
  );
  const recommendedExperienceClaimsCount = numbers.parseFloatOrNull(
    pricing?.frequency?.averageNumberOfClaims?.[0]?.claims
  );
  const modelClaimsCount = numbers.parseFloatOrNull(
    pricing?.frequency?.improvex?.claims
  );
  const modelWeight = numbers.parseFloatOrNull(pricing?.improvexWeight);
  const underwriterSelectedClaimsCount = numbers.parseFloatOrNull(
    baseLayer.expectedLosses
  );

  const experienceClaimsCount =
    underwriterSelectedExperienceClaimsCount ??
    recommendedExperienceClaimsCount;

  const pricingClaimsCount = _resolvePricingClaimsCount({
    underwriterSelectedClaimsCount,
    experienceClaimsCount,
    modelClaimsCount,
    modelWeight,
  });

  return {
    limit: baseLayer.limit ?? null,
    attachment: baseLayer.attachment ?? null,
    start: baseLayer.from ?? null,
    end: baseLayer.to ?? null,
    claimsFrequency: {
      underwriterSelectedExperienceClaimsCount,
      recommendedExperienceClaimsCount,
      modelClaimsCount,
      modelWeight,
      underwriterSelectedClaimsCount,
      pricingClaimsCount,
    },
  };
};

const _resolvePricingClaimsCount = ({
  underwriterSelectedClaimsCount,
  experienceClaimsCount,
  modelClaimsCount,
  modelWeight,
}) => {
  if (underwriterSelectedClaimsCount) {
    return underwriterSelectedClaimsCount;
  }
  if (
    experienceClaimsCount != null &&
    modelClaimsCount != null &&
    modelWeight != null
  ) {
    return (
      experienceClaimsCount * (1 - 0.01 * modelWeight) +
      modelClaimsCount * 0.01 * modelWeight
    );
  }
  if (experienceClaimsCount != null && modelWeight === 0) {
    return experienceClaimsCount;
  }
  if (modelClaimsCount != null && modelWeight === 100) {
    return modelClaimsCount;
  }
  return null;
};
