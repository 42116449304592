import * as _ from "lodash";

export const resolveEndorsements = (endorsements) => {
  const resolved = _.cloneDeep(endorsements);

  resolved?.config?.sections?.forEach((section) => {
    let index = 1;
    section?.items?.forEach((key) => {
      if (resolved?.values?.[key]?.selected) {
        resolved.values[key] = {
          ...resolved.values[key],
          index,
        };
        index += 1;
      }
    });
  });

  return resolved;
};

export default resolveEndorsements;
