import ExposureFlags from "./ExposureFlags";
import StateLaw from "components/inputs/flags/StateLaw";
import UWJudgment from "components/inputs/flags/UWJudgment";
import React from "react";

export const Flags = () => {
  return (
    <>
      <UWJudgment />
      <ExposureFlags />
      <StateLaw />
    </>
  );
};

export default Flags;
