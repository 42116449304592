const IS_TEST = process.env.NODE_ENV === "test";

export const resolveLossDevelopment = (input, { exposureData }) => {
  if (input == null) {
    return null;
  }
  const triangles = Object.fromEntries(
    Object.entries(input?.triangles ?? {}).map(([id, triangle]) => [
      id,
      resolveLossDevelopmentTriangle(triangle, {
        exposureData,
        initialExpectedLossRatio: input?.initialExpectedLossRatio,
      }),
    ])
  );
  return {
    ...input,
    triangles,
  };
};

const resolveLossRatios = ({
  ultimateLossesByPolicyYear,
  policyYears,
  premiumByPolicyYear,
}) => {
  const lossRatiosByPolicyYear = policyYears.map((policyYear) => {
    const ultimate =
      ultimateLossesByPolicyYear?.filter((l) => l.inception === policyYear)?.[0]
        ?.value ?? null;
    const premium =
      premiumByPolicyYear?.filter((p) => p.inception === policyYear)?.[0]
        ?.value ?? null;
    return {
      inception: policyYear,
      value: ultimate != null && !!premium ? ultimate / premium : null,
    };
  });

  const lossRatiosByModel = calculateLossRatiosByModel({
    lossRatiosByPolicyYear,
  });

  return {
    byPolicyYear: lossRatiosByPolicyYear,
    summary: lossRatiosByModel,
  };
};

export const resolveSelectedLossRatio = ({ input, lossRatios }) => {
  if (input.lossRatioOverride != null) {
    return input.lossRatioOverride;
  }
  const { model, summaryModel } = input.selectedLossRatioModel ?? {};
  if (model == null || summaryModel == null) {
    return null;
  }
  return lossRatios?.[model]?.summary?.[summaryModel];
};

export const resolveLossDevelopmentTriangle = (
  input,
  { exposureData, initialExpectedLossRatio }
) => {
  if (input == null) {
    return null;
  }
  const losses = input?.losses ?? [];
  if (!losses?.length) {
    return input;
  }

  const policyYears = extractPolicyYears(losses);
  const developmentYears = extractDevelopmentYears(losses);

  const ldfsByModel = calculateLdfByModel(losses, {
    policyYears,
    developmentYears,
  });
  const ldfs = resolveLdfs({
    developmentYears,
    ldfsByModel,
    selectedLdfModel: input?.selectedLdfModel,
    selectedLdfOverrides: input?.selectedLdfOverrides,
  });
  const cumulativeLdfs = calculateCumulativeLdf(ldfs);

  const premiumByPolicyYear = policyYears.map((policyYear) => {
    return {
      inception: policyYear,
      value: exposureData?.[String(policyYear)]?.FGU_PREMIUM ?? null,
    };
  });

  const ultimateLossesByPolicyYear = {
    ldf: calculateUltimateLossesByPolicyYear({
      losses,
      cumulativeLdfs,
      policyYears,
    }),
    bf: calculateBFUltimateLossesByPolicyYear({
      losses,
      cumulativeLdfs,
      policyYears,
      initialExpectedLossRatio,
      premiumByPolicyYear,
    }),
  };

  const lossRatios = {
    ldf: resolveLossRatios({
      ultimateLossesByPolicyYear: ultimateLossesByPolicyYear.ldf,
      policyYears,
      premiumByPolicyYear,
    }),
    bf: resolveLossRatios({
      ultimateLossesByPolicyYear: ultimateLossesByPolicyYear.bf,
      policyYears,
      premiumByPolicyYear,
    }),
  };

  return {
    ...input,
    losses,
    policyYears,
    developmentYears,
    ldfsByModel,
    ldfs,
    cumulativeLdfs,
    ultimateLossesByPolicyYear,
    premiumByPolicyYear,
    lossRatios,
    lossRatio: resolveSelectedLossRatio({ input, lossRatios }),
  };
};

const average = (items) => {
  const nonNullItems = items?.filter((i) => i != null);
  return nonNullItems?.length
    ? nonNullItems.reduce((acc, cur) => acc + cur, 0) / nonNullItems.length
    : 1;
};

const extractPolicyYears = (losses) => {
  const policyYears = Array.from(new Set(losses.map((x) => x.inception)));
  return policyYears.sort((a, b) => a - b);
};

const extractDevelopmentYears = (losses) => {
  const developmentYears = Array.from(new Set(losses.map((x) => x.ageMonths)));
  return developmentYears.sort((a, b) => a - b);
};

const calculateLdfByModel = (losses, { policyYears, developmentYears }) => {
  if (!losses?.length) {
    return {};
  }

  const lossesMap = {};
  for (const loss of losses) {
    if (lossesMap[loss.inception] == null) {
      lossesMap[loss.inception] = {};
    }
    lossesMap[loss.inception][loss.ageMonths] = loss.value;
  }

  const temp = developmentYears
    .slice(0, developmentYears.length - 1)
    .map((developmentYear, developmentYearIndex) => {
      const nextDevelopmentYear = developmentYears[developmentYearIndex + 1];
      const deltas = policyYears
        .slice(0, policyYears.length - developmentYearIndex - 1)
        .map(
          (policyYear) =>
            lossesMap[policyYear][nextDevelopmentYear] /
            lossesMap[policyYear][developmentYear]
        )
        .reverse();

      return {
        ageMonths: developmentYear,
        avg: average(deltas),
        avgLast3yrs: average(deltas.slice(0, 3)),
        avgLast5yrs: average(deltas.slice(0, 5)),
        exAnteAvg: average(deltas.slice(1)),
        exAnteAvgLast3yrs: average(deltas.slice(1, 4)),
        exAnteAvgLast5yrs: average(deltas.slice(1, 6)),
      };
    });

  return Object.fromEntries(
    [
      "avgLast3yrs",
      "avgLast5yrs",
      "avg",
      "exAnteAvg",
      "exAnteAvgLast3yrs",
      "exAnteAvgLast5yrs",
    ].map((model) => [
      model,
      temp.map((t) => ({ ageMonths: t.ageMonths, value: t[model] })),
    ])
  );
};

const resolveLdfs = ({
  developmentYears,
  ldfsByModel,
  selectedLdfModel,
  selectedLdfOverrides,
}) => {
  if (developmentYears == null) {
    return null;
  }

  return developmentYears.map((developmentYear, developmentYearIndex) => {
    const value = selectedLdfOverrides?.[developmentYear]?.value;
    const model =
      selectedLdfOverrides?.[developmentYear]?.model ?? selectedLdfModel;

    if (developmentYearIndex === developmentYears.length - 1) {
      return {
        ageMonths: developmentYear,
        value: value ?? 1.1,
      };
    } else {
      return {
        ageMonths: developmentYear,
        value:
          value ?? ldfsByModel?.[model]?.[developmentYearIndex]?.value ?? null,
      };
    }
  });
};

const calculateCumulativeLdf = (ldfs) => {
  if (!ldfs?.length) {
    return [];
  }
  const out = [];
  let cumulation = 1;
  [...ldfs].reverse().forEach((ldf) => {
    cumulation *= ldf.value;
    out.push({
      ageMonths: ldf.ageMonths,
      value: cumulation,
    });
  });
  return out.reverse();
};

const calculateUltimateLossesByPolicyYear = ({
  losses,
  cumulativeLdfs,
  policyYears,
}) => {
  return policyYears.map((policyYear) => {
    const latestLoss =
      [...(losses ?? [])]
        .filter((loss) => loss.inception === policyYear)
        .sort((a, b) => b?.ageMonths - a?.ageMonths)?.[0] ?? null;
    const cumulativeLdf = latestLoss
      ? cumulativeLdfs?.filter(
          (ldf) => ldf?.ageMonths === latestLoss?.ageMonths
        )?.[0]?.value
      : null;
    return {
      inception: policyYear,
      value:
        latestLoss?.value != null && cumulativeLdf != null
          ? latestLoss.value * cumulativeLdf
          : null,
    };
  });
};

const calculateBFUltimateLossesByPolicyYear = ({
  losses,
  cumulativeLdfs,
  policyYears,
  initialExpectedLossRatio,
  premiumByPolicyYear,
}) => {
  return policyYears.map((policyYear) => {
    const latestLoss =
      [...(losses ?? [])]
        .filter((loss) => loss.inception === policyYear)
        .sort((a, b) => b?.ageMonths - a?.ageMonths)?.[0] ?? null;
    const premium =
      premiumByPolicyYear?.filter((p) => p.inception === policyYear)?.[0]
        ?.value ?? null;
    const cumulativeLdf = latestLoss
      ? cumulativeLdfs?.filter(
          (ldf) => ldf?.ageMonths === latestLoss?.ageMonths
        )?.[0]?.value
      : null;
    if (
      initialExpectedLossRatio == null ||
      premium == null ||
      cumulativeLdf == null ||
      cumulativeLdf === 0 ||
      latestLoss?.value == null
    ) {
      return {
        inception: policyYear,
        value: null,
      };
    } else {
      return {
        inception: policyYear,
        value:
          initialExpectedLossRatio * premium * (1 - 1 / cumulativeLdf) +
          latestLoss?.value,
      };
    }
  });
};

const calculateLossRatiosByModel = ({ lossRatiosByPolicyYear }) => {
  if (!lossRatiosByPolicyYear?.length) {
    return {};
  }
  const lossRatios = [...lossRatiosByPolicyYear].map((x) => x.value).reverse();

  return {
    avgLast3yrs: average(lossRatios.slice(0, 3)),
    avgLast5yrs: average(lossRatios.slice(0, 5)),
    avg: average(lossRatios),
  };
};

export const _calculateLdfByModel = IS_TEST ? calculateLdfByModel : null;
export const _calculateCumulativeLdf = IS_TEST ? calculateCumulativeLdf : null;
