import ClaimsComparisonSettingsDialog from "./ClaimsComparisonSettingsDialog";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { Stack, Alert } from "@mui/material";
import * as chartUtils from "chartUtils";
import * as charts from "common/charts";
import { getDateTicks } from "common/charts";
import { makeTicksForNumericDomain } from "common/charts";
import * as dates from "common/dates";
import { currencyFormat } from "common/numbers";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import * as config from "config";
import * as _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  Cell,
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import * as claimsService from "services/claimsService";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

const defaultStyles = {
  position: "relative",
  width: "100%",
  paddingBottom: "500px",
};

const chartMargins = {
  top: 20,
  right: 30,
  left: 20,
  bottom: 5,
};

const tooltipLabelFormatter = (value, props) => {
  return dates.formatDate(props?.[0]?.payload?.date);
};

const makeTooltipPayload = (originalPayload) => {
  const diff = originalPayload?.[0]?.payload;

  if (diff == null) {
    return [];
  }

  const { delta, status, date, prior, current } = diff;

  const payload = [
    {
      name: "Date",
      value: <span>{date}</span>,
    },
    {
      name: "Status",
      value: (
        <span
          style={{
            ...{
              new: {
                background: "#cfc",
                border: "2px solid #0c0",
                borderRadius: "4px",
                color: "#0a0",
              },
              missing: {
                background: "#fcdfcf",
                border: "2px solid #fcae83",
                borderRadius: "4px",
                color: "#fa7b35",
              },
              different: {
                background: "#fcc",
                border: "2px solid #f00",
                borderRadius: "4px",
                color: "#f00",
              },
            }[status],
          }}
        >
          {status?.toUpperCase()}
        </span>
      ),
    },
  ];

  if (status === "different") {
    payload.push({
      name: "Delta",
      value: (
        <span style={{ color: delta < 0 ? "#d00" : "#0a0" }}>
          {currencyFormat(delta)}
        </span>
      ),
    });

    const oldValue = prior?.numericDeltas?.[0];
    payload.push({
      name: "Old Value",
      value: <span>{oldValue != null ? currencyFormat(oldValue) : ""}</span>,
    });

    const newValue = current?.numericDeltas?.[0];
    payload.push({
      name: "New Value",
      value: <span>{newValue != null ? currencyFormat(newValue) : ""}</span>,
    });
  } else {
    payload.push({
      name: "Value",
      value: (
        <span style={{ color: delta < 0 ? "#d00" : "#0a0" }}>
          {currencyFormat(delta)}
        </span>
      ),
    });
  }

  return payload;
};

const CustomTooltipContent = (props) => {
  return (
    <DefaultTooltipContent
      {...props}
      payload={makeTooltipPayload(props?.payload)}
    />
  );
};

const tooltipFormatter = (value) => {
  if (typeof value === "number") {
    return currencyFormat(value);
  }
  return value;
};

const ClaimsDeltaChart = ({ query, setQuery }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const priorQuery = useDebouncedValue(
    useSelector((state) =>
      claimsSelectors.selectInputClaims(stateSelectors.selectPriorState(state))
    )
  );
  const currentQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const hasPriorState = !_.isEmpty(
    useSelector(stateSelectors.selectPriorState)
  );

  const { data, isFetching, isError } = claimsService.useClaimsComparisonQuery(
    {
      ...query,
      priorQuery,
      currentQuery,
    },
    {
      skip: !hasPriorState || !priorQuery || !currentQuery,
    }
  );

  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  if (!hasPriorState) {
    return <></>;
  }

  const deltasAboveZero = (data?.comparisons ?? []).filter(
    (comparison) => comparison.delta !== 0
  );
  const numberDomain = [
    Math.min(0, ...deltasAboveZero.map((comparison) => comparison?.delta || 0)),
    Math.max(0, ...deltasAboveZero.map((comparison) => comparison?.delta || 0)),
  ];
  const numberTicks = makeTicksForNumericDomain(numberDomain);
  return (
    <Component
      title={
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div
            style={{
              marginTop: "13px",
              whiteSpace: "nowrap",
              alignItems: "center",
            }}
          >
            {"Claims Delta"}
          </div>
          {isFetching && (
            <DefaultLoader
              color={"#dc7f4c"}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "13px",
                marginLeft: "8px",
                marginRight: "auto",
              }}
              size={14}
            />
          )}
        </Stack>
      }
      options={
        <IconButton
          variant={"bright"}
          scale={"small"}
          icon={SettingsIcon}
          tooltip={"Settings"}
          onClick={() => setIsSettingsOpen(true)}
        />
      }
    >
      <ClaimsComparisonSettingsDialog
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        query={query}
        setQuery={setQuery}
      />
      {isError ? (
        <Alert severity={"error"}>
          {`Error running claims comparison. Please contact ${config.SUPPORT_EMAIL}.`}
        </Alert>
      ) : data?.domain != null && data?.comparisons?.length !== 0 ? (
        <div style={defaultStyles}>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              top: 0,
            }}
          >
            <ResponsiveContainer width={"100%"} height={"100%"}>
              <BarChart
                ref={chartRef}
                width={500}
                height={500}
                margin={chartMargins}
                data={deltasAboveZero}
              >
                <CartesianGrid strokeDasharray={"3 3"} />
                <XAxis
                  dataKey={"months"}
                  type={"number"}
                  tickFormatter={charts.dateTickFormatter}
                  ticks={getDateTicks(data.domain)}
                  domain={[(dataMin) => dataMin, (dataMax) => dataMax]}
                />
                <YAxis
                  domain={numberDomain}
                  ticks={numberTicks}
                  tickFormatter={chartUtils.tickFormatter}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  content={<CustomTooltipContent />}
                  position={tooltipPosition}
                  isAnimationActive={false}
                />
                <Bar dataKey={"delta"} barSize={5}>
                  {deltasAboveZero.map((comparison, index) => (
                    <Cell
                      key={index}
                      fill={comparison.delta < 0 ? "#F5BEB7" : "#B7F4BA"}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          {isFetching ? "" : "No comparison data"}
        </div>
      )}
    </Component>
  );
};

export default ClaimsDeltaChart;
