import * as config from "config";
import * as types from "store/actions/persistence/persistenceActionTypes";
import * as actions from "store/actions/persistence/persistenceActions";
import * as programSelectors from "store/selectors/input/program/programSelectors";

export const INITIAL_STATE = {
  state: "IDLE",
  error: null,
  renewError: null,
  saving: false,
  filename: null,
  lastSaved: null,
  savedBy: null,
  schemaVersion: config.CURRENT_FILE_SCHEMA_VERSION,
};

const persistenceReducer = (state, action) => {
  if (state === undefined) {
    state = INITIAL_STATE;
  }

  switch (action.type) {
    case types.LOAD_SUBMISSION_STARTED:
      return {
        ...state,
        state: "LOADING",
        error: null,
        saving: false,
      };
    case types.LOAD_SUBMISSION_FAILED:
      return {
        ...state,
        state: "FAILED",
        error: action?.payload?.error || "Unknown error",
        saving: false,
      };
    case types.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...state,
        state: "SUCCEEDED",
        error: null,
        saving: false,
        filename: action?.payload?.data?.filename ?? null,
        lastSaved: action?.payload?.data?.savedAt ?? null,
        savedBy: action?.payload?.data?.savedBy ?? null,
        savedToken: action?.payload?.data?.savedToken ?? null,
        schemaVersion:
          action?.payload?.data?.state?.schemaVersion ??
          config.CURRENT_FILE_SCHEMA_VERSION,
      };
    case types.NEW_SUBMISSION:
      return {
        ...state,
        state: "SUCCEEDED",
        error: null,
        renewError: null,
        saving: false,
        filename: action.payload?.filename ?? null,
        lastSaved: null,
        savedBy: null,
      };
    case types.RENEW_SUBMISSION:
      return {
        ...state,
        state: "SUCCEEDED",
        error: null,
        renewError: null,
        saving: false,
        filename: action.payload?.filename ?? null,
        lastSaved: null,
        savedBy: null,
      };
    case types.DUPLICATE_SUBMISSION:
      return {
        ...state,
        state: "SUCCEEDED",
        error: null,
        renewError: null,
        saving: false,
        filename: action.payload?.filename ?? null,
        lastSaved: null,
        savedBy: null,
      };
    case actions.saveState.started.toString():
      return {
        ...state,
        saving: true,
      };
    case actions.saveState.succeeded.toString():
      if (!state.saving) {
        return state;
      }
      return {
        ...state,
        saving: false,
        lastSaved: Date.parse(action.payload.savedAt),
        savedBy: action.payload.savedBy,
        filename: action.payload.filename,
        overwritten: action.payload.overwritten,
        savedToken: action.payload.savedToken,
      };
    case actions.saveState.failed.toString():
      return {
        ...state,
        saving: false,
      };
    case types.CANCEL_RENEW_SUBMISSION:
      return {
        ...state,
        renewError: null,
      };
    case types.RENEW_SUBMISSION_DIALOG:
      const inceptionDate = programSelectors.getInception(
        action.payload.priorState
      );
      return {
        ...state,
        renewError: `You are attempting to renew a deal that has not yet incepted. It is due to incept on ${inceptionDate}.`,
      };
    default:
      return state;
  }
};

export default persistenceReducer;
