import { selectPersistedState } from "../../../persistence/persistenceSelectors";
import { createSelector } from "reselect";

export const selectSavedPersistentState = (state) => {
  return state?.temp?.navigation?.savedPersistentState;
};

export const isSaveRequired = createSelector(
  [selectPersistedState, selectSavedPersistentState],
  (current, saved) => {
    return current !== saved;
  }
);
