import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Component from "components/Component";
import Markdown from "components/common/Markdown";
import RagBadge from "components/common/RagBadge";
import UsStatesSelector from "components/common/UsStatesSelector";
import { connect } from "react-redux";
import * as flagActions from "store/actions/input/flags/flagsActions";
import * as flagSelectors from "store/selectors/input/flags/flagsSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const StateLaw = ({ sovTortCaps, stateId, setStateId }) => {
  const active = sovTortCaps?.[stateId] ?? null;

  return (
    <Component
      title={"US State Law"}
      subtitle={
        "Important legal details that factor into risk assessment. These inputs do not affect pricing calculations."
      }
    >
      <UsStatesSelector
        stateId={stateId}
        onChange={(stateId) => setStateId(stateId)}
      />
      {active && (
        <Stack spacing={1} mt={2}>
          <RagBadge
            rag={active.rag}
            title={"Sovereign Immunity & Tort Liability Caps"}
            variant={"filled"}
          />
          <Box sx={{ paddingLeft: "2rem" }}>
            <Markdown value={active.content} />
          </Box>
        </Stack>
      )}
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    stateId: flagSelectors.selectStateId(state),
    sovTortCaps: staticDataSelectors.selectSovTortCaps(state),
  };
};

const mapDispatchToProps = {
  setStateId: flagActions.updateStateId,
};

export default connect(mapStateToProps, mapDispatchToProps)(StateLaw);
