import {
  KeyboardArrowLeftRounded as PreviousPageIcon,
  KeyboardArrowRightRounded as NextPageIcon,
  SkipPreviousRounded as FirstPageIcon,
  SkipNextRounded as LastPageIcon,
} from "@mui/icons-material";
import { MenuItem, Select, Stack, Typography } from "@mui/material";

const PaginationControls = ({
  rowCount,
  page,
  setPage,
  pageSize,
  setPageSize,
  pageSizeOptions,
  pageCount,
  sx,
}) => {
  const activeColour = "#808080";
  const inactiveColour = "#DEDEDE";

  const makeIconStyle = (disabled) =>
    disabled
      ? { color: inactiveColour }
      : {
          color: activeColour,
          ":hover": {
            color: "black",
            cursor: "pointer",
          },
        };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      spacing={2}
      sx={sx}
    >
      <Typography color={activeColour}>{`Total rows: ${rowCount}`}</Typography>
      <Stack direction={"row"} alignItems={"center"}>
        <Typography color={activeColour}>{"Rows per page: "}</Typography>
        <Select
          size={"small"}
          variant={"standard"}
          sx={{ padding: 0.5, paddingTop: 1, marginLeft: 1 }}
          value={pageSize}
          onChange={(e) => setPageSize(Number(e.target.value))}
        >
          {pageSizeOptions.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      <Stack direction={"row"} alignItems={"center"}>
        <FirstPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page === 1)}
          onClick={() => setPage(1)}
        />
        <PreviousPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page <= 1)}
          onClick={() => setPage(Math.max(0, page - 1))}
        />
        <Typography color={activeColour}>{`${page} / ${pageCount}`}</Typography>
        <NextPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page >= pageCount)}
          onClick={() => setPage(Math.min(pageCount, page + 1))}
        />
        <LastPageIcon
          fontSize={"small"}
          sx={makeIconStyle(page === pageCount)}
          onClick={() => setPage(pageCount)}
        />
      </Stack>
    </Stack>
  );
};

export default PaginationControls;
