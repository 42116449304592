import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.email(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const emailServiceApi = createApi({
  reducerPath: "emailServiceApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    sendPeerReviewEmail: builder.mutation({
      /** Expects am email_payload of the form
       *        {
       *         sender_name: ,
       *         email_to: ,
       *         submission_url: ,
       *         submission_name: ,
       *       }
       */
      query: (email_payload) => ({
        url: "send-peer-review-email",
        payload: {
          body: JSON.stringify(email_payload),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
  }),
});

export const { useSendPeerReviewEmailMutation } = emailServiceApi;
