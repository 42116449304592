import * as config from "../../../config";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { getExtension } from "common/files";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import Disabled from "components/common/Disabled";
import { useState } from "react";
import {
  BsFileEarmarkExcelFill as ExcelIcon,
  BsFillFileEarmarkFill as GenericFileIcon,
  BsFillFileEarmarkMedicalFill as TarIcon,
  BsFillFileEarmarkPdfFill as PdfIcon,
  BsFillFileEarmarkPptFill as PowerPointIcon,
  BsFillFileEarmarkWordFill as WordIcon,
  BsFillFileEarmarkZipFill as ZipIcon,
  BsFillFileImageFill as ImageIcon,
} from "react-icons/bs";
import { connect } from "react-redux";
import * as actions from "store/actions/input/attachments/attachmentsActions";

export const SmallLoader = () => (
  <DefaultLoader
    color={"#dc7f4c"}
    size={16}
    style={{
      display: "block",
      textAlign: "center",
    }}
  />
);

export const FileIcon = ({ extension, large, color }) => {
  const style = {
    fontSize: large ? "1.5625rem" : null,
    color: color,
  };

  if (extension == null) {
    return <GenericFileIcon style={style} />;
  }
  switch (extension.toLowerCase()) {
    case "xls":
    case "xlsx":
    case "xlsm":
      return <ExcelIcon style={style} />;
    case "ppt":
    case "pptx":
    case "pptm":
      return <PowerPointIcon style={style} />;
    case "doc":
    case "docx":
    case "docm":
      return <WordIcon style={style} />;
    case "zip":
      return <ZipIcon style={style} />;
    case "pdf":
      return <PdfIcon style={style} />;
    case "tar":
      return <TarIcon style={style} />;
    case "jpeg":
    case "jpg":
    case "gif":
    case "png":
    case "bmp":
    case "svg":
    case "webp":
      return <ImageIcon style={style} />;
    default:
      return <GenericFileIcon style={style} />;
  }
};

const CircularProgressBackground = ({ progress, size = 24, thickness = 3 }) => {
  return (
    <div style={{ position: "relative" }}>
      <CircularProgress
        variant={"determinate"}
        style={{
          color: "gray",
          opacity: "0.25",
        }}
        size={24}
        thickness={size - 2}
        value={progress}
      />
      <CircularProgress
        variant={"determinate"}
        style={{
          color: "primary",
          position: "absolute",
          left: 0,
        }}
        size={24}
        thickness={thickness}
        value={progress}
      />
    </div>
  );
};

export const EmailReceiver = (props) => {
  const [copyConfirmationVisible, setCopyConfirmationVisible] = useState(false);
  const [selectedTree, setSelectedTree] = useState(null);

  const emailTip = () => {
    if (props.monitoringEmails.length === 0) {
      return "Add a temporary inbox to send attachments directly to this submission";
    }
    const prefix =
      props.monitoringEmails.length > 1
        ? "Send email to either of the following addresses"
        : "Send an email to the following address";
    return `${prefix} to upload any files attached to the mail:`;
  };

  const handleTreeSelect = (emailId) => {
    setSelectedTree(emailId);
  };

  return (
    <Component
      title={"Email Upload"}
      options={
        <Disabled ifReadOnly>
          <LoadingButton
            variant={"contained"}
            color={"primary"}
            disableElevation
            onClick={() => props.newEmailAddress()}
            loading={props.requestingEmail}
            loadingIndicator={<DefaultLoader />}
          >
            {"Add Inbox"}
          </LoadingButton>
        </Disabled>
      }
    >
      <Snackbar
        open={copyConfirmationVisible}
        message={"Copied to clipboard"}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2500}
        ContentProps={{ sx: { display: "block", textAlign: "center" } }}
        onClose={() => {
          setCopyConfirmationVisible(false);
        }}
      />
      <Grid container spacing={config.GRID_SPACING}>
        <Grid item>
          <Typography>{emailTip()}</Typography>
        </Grid>
        {props.monitoringEmails.map((email, index) => {
          return (
            <Grid
              item
              container
              spacing={config.GRID_SPACING}
              alignItems={"center"}
              key={index}
            >
              <Grid item xs={"auto"}>
                <Paper variant={"outlined"} sx={{ padding: "8px 12px" }}>
                  <Typography fontFamily={"monospace"} fontSize={"14px"}>
                    {email.address}
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  disableElevation
                  startIcon={<CopyIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(email.address);
                    setCopyConfirmationVisible(true);
                  }}
                >
                  {"Copy"}
                </Button>
              </Grid>
              <Grid item xs={"auto"}>
                <Typography fontSize={"small"}>
                  {"Expires in "}
                  {Math.round(email.fraction * 10)} {"mins"}
                </Typography>
              </Grid>
              <Grid item xs={"auto"}>
                <CircularProgressBackground progress={email.fraction * 100} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {props.receivedEmails.map((email) => {
        const isExpanded = selectedTree === email.mailId;
        return (
          <TreeView
            key={email.mailId}
            aria-label={"file system navigator"}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ flexGrow: 1, overflowY: "auto" }}
          >
            <TreeItem
              label={
                <Box
                  sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}
                >
                  <Typography
                    variant={"body2"}
                    sx={{ fontWeight: "inherit", flexGrow: 1 }}
                  >
                    {email.subject}
                  </Typography>
                </Box>
              }
              nodeId={`${email.mailId}`}
              onIconClick={() => handleTreeSelect(email.mailId)}
              selected={isExpanded}
            >
              {email.attachments.map((attachment, index) => {
                return (
                  <TreeItem
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          p: 0.5,
                          pr: 0,
                        }}
                      >
                        <Box color={"#45494e"} sx={{ mr: 1 }}>
                          <FileIcon
                            extension={getExtension(attachment.original)}
                          />
                        </Box>
                        <Typography
                          variant={"body2"}
                          sx={{ fontWeight: "inherit", flexGrow: 1 }}
                        >
                          {attachment.original}
                        </Typography>
                        <IconButton
                          disabled={
                            !!attachment.attached || !!attachment.processing
                          }
                          onClick={() => props.attachFromEmail(attachment)}
                        >
                          {attachment.attached ? (
                            <DoneIcon />
                          ) : attachment.processing ? (
                            <SmallLoader />
                          ) : (
                            <AttachFileIcon />
                          )}
                        </IconButton>
                      </Box>
                    }
                    nodeId={`${email.mailId}_${index}`}
                    key={`${email.mailId}_${index}`}
                  />
                );
              })}
            </TreeItem>
          </TreeView>
        );
      })}
    </Component>
  );
};

const mapDispatchToProps = {
  newEmailAddress: actions.newEmailAddress.startSaga,
  attachFromEmail: actions.attachFromEmail.startSaga,
};

const mapStateToProps = (state) => {
  return {
    monitoringEmails: state?.temp?.attachments?.monitoringEmails || [],
    receivedEmails: state?.temp?.attachments?.receivedEmails || [],
    requestingEmail: state?.temp?.attachments?.requestingEmail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailReceiver);
