import { HeadingCell, ValueCell } from "./Cell";
import * as lossDevelopmentSupport from "./LossDevelopment.support";
import { Stack, Tooltip } from "@mui/material";
import { abbreviated, dpString } from "common/numbers";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import * as lossDevelopmentStore from "store/lossDevelopment";

const Triangle = ({ id }) => {
  const data = useSelector(lossDevelopmentStore.selectLossDevelopment)
    .triangles?.[id];
  const losses = data?.losses;

  if (!losses?.length) return <></>;

  const getHeatmapColour = lossDevelopmentSupport.makeLossDevelopmentHeatmapColourizer(
    data
  );

  return (
    <Stack>
      <Stack direction={"row"}>
        <HeadingCell />
        {data?.developmentYears?.map((developmentYear) => (
          <ValueCell borderless={true} key={developmentYear}>
            <b>{developmentYear}</b>
          </ValueCell>
        ))}
      </Stack>
      {data?.policyYears?.map((policyYear, policyYearIndex) => {
        const premium = data?.premiumByPolicyYear?.[policyYearIndex]?.value;
        return (
          <Stack direction={"row"} key={policyYear}>
            <HeadingCell label={policyYear} />
            {data?.developmentYears?.map(
              (developmentYear, developmentYearIndex) => {
                if (
                  developmentYearIndex + policyYearIndex >=
                  data.developmentYears.length
                ) {
                  return <Fragment key={developmentYear} />;
                }
                const loss = losses?.filter(
                  (loss) =>
                    loss?.ageMonths === developmentYear &&
                    loss?.inception === policyYear
                )?.[0]?.value;
                const lossRatio = loss / premium;
                return (
                  <Tooltip
                    title={`Loss Ratio: ${dpString(1)(100 * lossRatio)}%`}
                    placement={"left"}
                    arrow
                    key={developmentYearIndex}
                  >
                    <div>
                      <ValueCell bg={getHeatmapColour(lossRatio)}>
                        {abbreviated(loss)}
                      </ValueCell>
                    </div>
                  </Tooltip>
                );
              }
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default Triangle;
