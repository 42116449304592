import * as chartUtils from "chartUtils";
import Component from "components/Component";
import React from "react";
import { connect } from "react-redux";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Line,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

export const parseData = (
  claims,
  improvex,
  expected,
  blendedExpected,
  ultimateSelected
) => {
  if (
    claims === undefined ||
    claims === null ||
    Object.keys(claims).length === 0
  ) {
    return { data: [] };
  }
  const fixedValues = {
    improvex,
    expected,
    blendedExpected,
    ultimateSelected,
  };
  const data = Object.keys(claims)
    .sort()
    .map((k) => ({
      label: parseInt(k),
      actual: claims[k].actual,
      ultimate: claims[k].ultimate - claims[k].actual,
      ...fixedValues,
    }));
  const domain = [
    Math.min(...Object.keys(claims)),
    Math.max(...Object.keys(claims)),
  ];
  return {
    data: [
      { label: domain[0] - 1, ...fixedValues },
      ...data,
      { label: domain[1] + 1, ...fixedValues },
    ],
  };
};

export const LossFrequencyChartComponent = (props) => {
  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  const chart = parseData(
    props.claims,
    props.improvexClaims,
    props.underwriterSelectedNumberOfClaims ||
      props.averageNumberOfClaims?.[0]?.claims,
    props.blendedClaims,
    props.pricingSelection
  );
  const chartMargins = props.chartMargins || {
    top: 20,
    right: 30,
    left: 20,
    bottom: 5,
  };
  const defaultStyles = props.styles || {
    position: "relative",
    width: "100%",
    paddingBottom: "500px",
  };
  const requestAttachment = props.request?.baseLayer?.attachment;
  const title =
    requestAttachment == null
      ? "Losses"
      : `Losses xs ${
          props.credibleTitle ? "Credible" : "Base"
        } Layer ($${utils.sfString(requestAttachment / 1e6, 2)}m)`;
  return (
    <Component title={title} titleStyles={props.titleStyles}>
      <div style={defaultStyles}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <ComposedChart
              width={500}
              height={500}
              data={chart.data}
              margin={chartMargins}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray={"3 3"} />
              <XAxis
                dataKey={"label"}
                type={"number"}
                domain={[(dataMin) => dataMin, (dataMax) => dataMax]}
              />
              <YAxis />
              <Tooltip
                position={tooltipPosition}
                isAnimationActive={false}
                formatter={chartUtils.formatToolTip}
              />
              {!props.hideLegend && <Legend iconType={"square"} />}
              <Bar
                dataKey={"actual"}
                stackId={"a"}
                fill={"#FFAB53"}
                name={"Reported"}
              />
              <Bar
                dataKey={"ultimate"}
                stackId={"a"}
                fill={"#FF634E"}
                name={"Expected Development"}
              />
              <Line
                dataKey={"improvex"}
                stroke={"#62BEE5"}
                name={"Model"}
                strokeWidth={2}
                dot={false}
              />
              <Line
                dataKey={"expected"}
                stroke={"#A0A6AD"}
                name={"Insured Selected # of Losses"}
                strokeWidth={2}
                dot={false}
              />
              <Line
                dataKey={"blendedExpected"}
                stroke={"#CC5ED6"}
                name={"Model/Claims Blend"}
                strokeWidth={2}
                dot={false}
              />
              <Line
                dataKey={"ultimateSelected"}
                name={"Losses for Pricing"}
                stroke={"#3A4148"}
                strokeWidth={3}
                dot={false}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Component>
  );
};

const mapDispatchToProps = {};

export const mapStateToProps = (state) => {
  return {
    request: state.pricing.frequency.request,
    claims: state.pricing.frequency.claims,
    improvexClaims: state.pricing.frequency.improvex?.claims,
    blendedClaims: pricingSelectors.getBlendedExpected(state),
    pricingSelection: pricingSelectors.getNumberOfLossesForPricingNumber(state),
    attachment: state.pricing.baseLayer?.attachment,
    averageNumberOfClaims: state.pricing.frequency.averageNumberOfClaims,
    underwriterSelectedNumberOfClaims:
      state.pricing.frequency.underwriterSelectedNumberOfClaims,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LossFrequencyChartComponent);
