import {
  Clear as ClearIcon,
  Edit as EditIcon,
  OpenInNew as OpenSubmissionIcon,
} from "@mui/icons-material";
import {
  Card,
  ClickAwayListener,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import IconButton from "components/common/IconButton";
import SubmissionSelector from "components/common/SubmissionSearch";
import SubmissionSummary from "components/submission/SubmissionSummary";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "store/actions/input/program/programActions";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const PriorSubmissionCard = (props) => {
  const priorSubmissionFilename = useSelector(
    programSelectors.getPriorSubmission
  );

  const hasPriorSubmission = !!priorSubmissionFilename;

  const priorProgram = useSelector(
    (state) => state.temp?.priorState?.state?.input?.program
  );

  const dispatch = useDispatch();
  const updatePriorSubmission = useCallback(
    (priorSubmissionFilename) => {
      dispatch(actions.updatePriorSubmission(priorSubmissionFilename));
    },
    [dispatch]
  );

  return (
    <Card>
      <Grid container>
        <Grid item container xs={9}>
          {hasPriorSubmission ? (
            <SubmissionSummary program={priorProgram} />
          ) : (
            <Grid item container alignItems={"center"} sx={{ padding: "10px" }}>
              <Typography>{"No prior submission"}</Typography>
            </Grid>
          )}
        </Grid>
        <Grid item container xs={1} className={"centered"}>
          {hasPriorSubmission && (
            <Disabled ifReadOnly>
              <IconButton
                variant={"bright"}
                icon={ClearIcon}
                onClick={() => updatePriorSubmission(null)}
              />
            </Disabled>
          )}
        </Grid>
        <Grid item container xs={1} className={"centered"}>
          {hasPriorSubmission && (
            <Disabled ifReadOnly>
              <Link
                to={`/submissions/${priorSubmissionFilename?.substring(
                  0,
                  priorSubmissionFilename.length - 5
                )}`}
                target={"_blank"}
              >
                <IconButton variant={"bright"} icon={OpenSubmissionIcon} />
              </Link>
            </Disabled>
          )}
        </Grid>
        <Grid item container xs={1} className={"centered"}>
          <Disabled ifReadOnly>
            <IconButton
              variant={"bright"}
              icon={EditIcon}
              onClick={props.onEdit}
            />
          </Disabled>
        </Grid>
      </Grid>
    </Card>
  );
};

const PriorSubmissionSearch = ({ label, textFieldInputProps, onSelect }) => {
  const filename = useSelector(persistenceSelectors.selectFilename);

  const dispatch = useDispatch();

  return (
    <SubmissionSelector
      label={label}
      textFieldInputProps={textFieldInputProps}
      excludeFilenames={[filename]}
      onSelect={(submission) => {
        dispatch(actions.updatePriorSubmission(submission.filename));
        onSelect();
      }}
    />
  );
};

const RenewalComponent = () => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  return (
    <Component title={"Renewed From"}>
      {isSearchVisible ? (
        <ClickAwayListener
          onClickAway={() => {
            setIsSearchVisible(false);
          }}
        >
          <div>
            <PriorSubmissionSearch
              label={"Select prior Submission"}
              onSelect={() => setIsSearchVisible(false)}
              textFieldInputProps={{
                autoFocus: true,
                endAdornment: (
                  <InputAdornment position={"end"}>
                    <IconButton
                      icon={ClearIcon}
                      onClick={() => setIsSearchVisible(false)}
                    />
                  </InputAdornment>
                ),
              }}
              clearOnEscape
            />
          </div>
        </ClickAwayListener>
      ) : (
        <PriorSubmissionCard onEdit={() => setIsSearchVisible(true)} />
      )}
    </Component>
  );
};

export default RenewalComponent;
