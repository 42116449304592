import * as Sentry from "@sentry/react";
import * as logger from "common/logger";
import * as config from "config";
import * as fileUtils from "fileUtils";
import {
  call,
  put,
  select,
  takeLatest,
  takeEvery,
  delay,
} from "redux-saga/effects";
import * as types from "store/actions/input/exposure/hazardActionTypes";
import * as actions from "store/actions/input/exposure/hazardActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as utils from "utils";

export function* activeSheetChanged(action) {
  try {
    yield put({
      type: types.HAZARD_SHEET_SELECTED,
      payload: action.payload?.sheet,
    });
    if (action.payload?.sheet == null) {
      return;
    }
    yield put({ type: types.HAZARD_UPDATE_COLUMNS });
  } catch (e) {
    logger.exception(e);
  }
}

export function* updateHazardProfileSummary() {
  try {
    yield put(actions.updateHazardProfileSummaryStarted());
    const hazardProfileInput = yield select(
      exposureSelectors.selectHazardProfileInput
    );
    const result = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims("summarize-hazard-profile"),
      {
        body: JSON.stringify(hazardProfileInput),
        method: "post",
      }
    );
    const hazardProfileSummary = yield call([result, result.json]);
    yield put(
      actions.updateHazardProfileSummarySucceeded(hazardProfileSummary)
    );
  } catch (e) {
    logger.exception(e);
    yield put(actions.updateHazardProfileSummaryFailed());
  }
}

export function* transformHazardFile() {
  yield put(actions.transformHazardFile.started());
  try {
    const extractRequest = yield select(
      exposureSelectors.getHazardTransformationRequest
    );
    const data = yield call(fileUtils.extractTable, extractRequest);
    yield put(
      actions.transformHazardFile.succeeded({
        fileId: data.id,
      })
    );
    yield put(actions.nextStep());
    yield put(actions.updateHazardProfileSummary());
    yield delay(2000);
    yield put(actions.hazardDialogClosed());
  } catch (e) {
    logger.exception(e);
    yield put(
      actions.transformHazardFile.failed({
        errorMessage:
          e?.description ??
          e?.message ??
          `Cannot process file. Please contact ${config.SUPPORT_EMAIL}.`,
      })
    );
  }
}

export function* activeSheetTopLeftChanged(action) {
  try {
    yield put({
      type: types.HAZARD_SHEET_TOP_LEFT_SELECTED,
      payload: action.payload,
    });
    if (action.payload == null) {
      return;
    }
    yield put({ type: types.HAZARD_UPDATE_COLUMNS });
  } catch (e) {
    logger.exception(e);
  }
}

export function* updateColumns() {
  try {
    yield put({
      type: types.HAZARD_COLUMN_NAMES_REQUESTED,
    });
    const hazardSheet = yield select(exposureSelectors.getHazardSheet);
    const columns = yield call(fileUtils.getSubtableColumnList, hazardSheet);
    yield put({
      type: types.HAZARD_COLUMN_NAMES_RETURNED,
      payload: columns,
    });
    yield* updateMapping("columnMapping", columns);
  } catch (e) {
    logger.exception(e);
    yield put({
      type: types.HAZARD_COLUMN_NAMES_FAILED,
    });
    yield put(
      actions.mapHazardFileFailed(
        `Failed to extract columns from input file. Please either convert the file to a .csv, try another file, or contact ${config.SUPPORT_EMAIL}.`
      )
    );
  }
}

export function* updateMapping(hintType, keys, inverse = false) {
  try {
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims("excel/mapping-hints"),
      {
        body: JSON.stringify({
          keys,
          hintType,
          inverse,
        }),
        method: "post",
      }
    );
    const mapping = yield call([response, response.json]);
    yield put({
      type: types.HAZARD_MAPPING_SUGGESTIONS_RETURNED,
      payload: {
        hintType,
        suggestions: mapping,
      },
    });
  } catch (e) {
    logger.exception(e);
  }
}

export function* columnMappingUpdated(action) {
  try {
    yield put({
      type: types.HAZARD_COLUMN_MAPPING_UPDATED,
      payload: action.payload,
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}

export default function* hazardProfileSaga() {
  yield takeLatest(types.HAZARD_ACTIVE_SHEET_CHANGED, activeSheetChanged);
  yield takeLatest(types.HAZARD_UPDATE_COLUMNS, updateColumns);
  yield takeLatest(
    types.ACTIVE_HAZARD_SHEET_TOP_LEFT_CHANGED,
    activeSheetTopLeftChanged
  );
  yield takeEvery(actions.mapping.updated, columnMappingUpdated);
  yield takeLatest(actions.transformHazardFile.requested, transformHazardFile);
  yield takeLatest(
    types.UPDATE_HAZARD_PROFILE_SUMMARY,
    updateHazardProfileSummary
  );
}
