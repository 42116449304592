import { Box, Stack, Typography } from "@mui/material";

const BlankSlate = () => {
  const sx = {
    width: "3.5rem",
    height: "1.25rem",
    background: "#f3f4f7",
    border: "1px solid gainsboro",
    borderRadius: "0.25rem",
  };

  return (
    <Stack alignItems={"center"} spacing={3} marginTop={20} marginBottom={20}>
      <Stack spacing={0.75}>
        <Stack direction={"row"} spacing={0.75}>
          <Box sx={sx} />
          <Box sx={sx} />
          <Box sx={sx} />
        </Stack>
        <Stack direction={"row"} spacing={0.75}>
          <Box sx={sx} />
          <Box sx={sx} />
        </Stack>
        <Stack direction={"row"} spacing={0.75}>
          <Box sx={sx} />
        </Stack>
      </Stack>

      <Typography
        variant={"body"}
        color={"silver"}
        textAlign={"center"}
        sx={{ maxWidth: "30ch" }}
      >
        {
          "Load one or more triangle files to perform a loss development analysis"
        }
      </Typography>
    </Stack>
  );
};

export default BlankSlate;
