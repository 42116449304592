import ExtraInput from "./ExtraInput";
import { Checkbox, Collapse, Stack, Typography } from "@mui/material";
import Disabled from "components/common/Disabled";
import { useDispatch, useSelector } from "react-redux";
import * as endorsementsStore from "store/endorsements";
import * as userSelectors from "store/selectors/user/userSelectors";

const Toggle = ({ itemKey, config }) => {
  const dispatch = useDispatch();

  const endorsements = useSelector(endorsementsStore.selectInput);

  const isUserReadOnly = useSelector(userSelectors.isUserReadOnly);

  const item = config?.items?.[itemKey] ?? {};

  const value = endorsements?.values?.[itemKey] ?? {};

  const selected = value.selected ?? false;

  const updateValue = (newValue) => {
    if (isUserReadOnly) {
      return;
    }
    dispatch(
      endorsementsStore.update({
        values: {
          ...endorsements?.values,
          [itemKey]: {
            ...value,
            ...newValue,
          },
        },
      })
    );
  };

  const toggleSelected = () => updateValue({ selected: !selected });

  return (
    <Stack direction={"column"} spacing={1}>
      <Stack
        direction={"row"}
        onClick={toggleSelected}
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={1}
        sx={{
          cursor: "pointer",
          ":hover": {
            backgroundColor: "#FAFAFA",
          },
        }}
      >
        <Disabled ifReadOnly>
          <Checkbox checked={selected} />
        </Disabled>
        <Typography variant={"body"}>{item.label}</Typography>
      </Stack>
      {item?.extraInputs?.length && (
        <Collapse in={selected}>
          <Stack direction={"column"} sx={{ marginLeft: 10 }}>
            {item?.extraInputs?.map((extraInput, index) => (
              <ExtraInput
                key={extraInput.key}
                definition={extraInput}
                value={value?.[extraInput.key]}
                setValue={(newInputValue) =>
                  updateValue({ [extraInput.key]: newInputValue })
                }
              />
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};

export default Toggle;
