import * as programActionTypes from "../../../actions/input/program/programActionTypes";
import * as persistenceActions from "../../../actions/persistence/persistenceActions";
import * as priorStateActions from "../../../actions/temp/priorState/priorStateActions";
import { put, takeLatest } from "redux-saga/effects";

export function* updatePriorSubmission(action) {
  const priorSubmissionFilename = action.payload;
  if (priorSubmissionFilename) {
    yield put(persistenceActions.loadPriorSubmission(priorSubmissionFilename));
  } else {
    yield put(priorStateActions.clearPriorState());
  }
}

export default function* programSaga() {
  yield takeLatest(
    programActionTypes.UPDATE_PRIOR_SUBMISSION,
    updatePriorSubmission
  );
}
