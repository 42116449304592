import * as config from "config";
import { put, select, call, takeLatest } from "redux-saga/effects";
import * as errorActions from "store/actions/error/errorActions";
import * as types from "store/actions/pricing/pricingActionTypes";
import * as actionTypes from "store/actions/pricing/pricingActionTypes";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as utils from "utils";

export function* recalculateInsuredExpectedEvents() {
  try {
    yield put({ type: actionTypes.RECALCULATING_INSURED_EXPECTED_EVENTS });
    const request = yield select(
      pricingActions.makeRecalculateInsuredExpectedEventsBody
    );
    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims("pricing/frequency-data"),
      {
        method: "post",
        body: JSON.stringify(request),
      }
    );
    const data = yield call([response, "json"]);

    if (data.claims) {
      // Check no claims
      const allClaims = Object.values(data.claims);

      if (
        allClaims.every((claim) => claim.actual < 0.5 && claim.ultimate < 0.5)
      ) {
        yield put(
          errorActions.handleError(null, {
            title: "No claims hit the base layer.",
            message: "Is this intended? If not, check the base layer.",
            type: "NO_CLAIMS_HIT_BASE_LAYER",
          })
        );
      }
    }

    yield put({
      type: actionTypes.RECALCULATED_INSURED_EXPECTED_EVENTS,
      payload: { data, request },
    });
  } catch (e) {
    yield put({
      type: actionTypes.RECALCULATED_INSURED_EXPECTED_EVENTS_FAILED,
    });
    yield put(
      errorActions.handleError(null, {
        title: "Failed to calculate loss frequency.",
        message: e.description || "Failed to calculate loss frequency.",
        type: "CALCULATE_LOSS_FREQUENCY_FAILED",
      })
    );
  }
}

export default function* pricingSaga() {
  yield takeLatest(
    types.RECALCULATE_INSURED_EXPECTED_EVENTS,
    recalculateInsuredExpectedEvents
  );
}
