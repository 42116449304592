import { clamp, distribute, quantiles } from "common/numbers";

const toRgb = (hex) => {
  const dec = (hex) => parseInt(hex, 16);
  const r = hex.substring(1, 3);
  const g = hex.substring(3, 5);
  const b = hex.substring(5, 7);
  return { r: dec(r), g: dec(g), b: dec(b) };
};

const toHex = (r, g, b) => {
  const hex = (value) => {
    value = clamp(value, 0, 255).toString(16);
    return "0".repeat(2 - value.length) + value;
  };
  return `#${hex(r)}${hex(g)}${hex(b)}`;
};

export const shades = (startColor, endColor, steps) => {
  if (steps <= 0) {
    return [];
  }

  if (steps === 1) {
    return [startColor];
  }

  const shadesArray = [startColor];
  const start = toRgb(startColor);
  const end = toRgb(endColor);

  const rDeltas = distribute(end.r - start.r, steps - 1);
  const gDeltas = distribute(end.g - start.g, steps - 1);
  const bDeltas = distribute(end.b - start.b, steps - 1);

  let r = start.r;
  let g = start.g;
  let b = start.b;

  for (let i = 0; i < steps - 1; i++) {
    r += rDeltas[i];
    g += gDeltas[i];
    b += bDeltas[i];
    shadesArray.push(toHex(r, g, b));
  }

  return shadesArray;
};

export const makeLossDevelopmentHeatmapColourizer = (lossDevelopment) => {
  const losses = lossDevelopment?.losses;
  if (!losses || losses.length < 4) {
    return () => null;
  }

  const premiumByPolicyYear = Object.fromEntries(
    lossDevelopment?.premiumByPolicyYear?.map(({ value, inception }) => [
      inception,
      value,
    ]) ?? []
  );

  const lossRatios = losses
    .map(({ value, inception }) => value / premiumByPolicyYear[inception])
    .filter(Number.isFinite);

  return makeLossRatioHeatmapColourizer(lossRatios);
};

export const makeLossRatioHeatmapColourizer = (lossRatios) => {
  if (!lossRatios || lossRatios?.length < 4) {
    return () => null;
  }

  const boundaryValues = quantiles(lossRatios, lossRatios.length);
  const colors = shades("#ffffff", "#ff5c00", lossRatios.length);

  return (value) => {
    if (!boundaryValues || value == null) {
      return null;
    }

    for (let i = 0; i < boundaryValues.length; i++) {
      if (value <= boundaryValues[i]) {
        return colors[i];
      }
    }

    return colors[colors.length - 1];
  };
};
