import * as logger from "common/logger";
import * as config from "config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as userSelectors from "store/selectors/user/userSelectors";

export const useHeap = () => {
  const [isHeapEnabled, setIsHeapEnabled] = useState(false);
  const [isHeapLoaded, setIsHeapLoaded] = useState(false);

  const userAttributes = useSelector(userSelectors.selectUserAttributes);
  const isMock = useSelector((state) => state.isMockStore);

  useEffect(() => {
    setIsHeapEnabled(!!config.HEAP_APP_ID && !isMock);
  }, [isMock, setIsHeapEnabled]);

  useEffect(() => {
    if (!isHeapEnabled || isHeapLoaded) {
      return;
    }
    try {
      window.heap.load(config.HEAP_APP_ID);
      setIsHeapLoaded(true);
    } catch (error) {
      logger.exception(error, {
        breadcrumb: { message: "Unable to load Heap" },
      });
    }
  }, [isHeapEnabled, isHeapLoaded, setIsHeapLoaded]);

  useEffect(() => {
    if (!isHeapEnabled || !isHeapLoaded || !userAttributes?.email) {
      return;
    }
    try {
      window.heap.identify(userAttributes.email);
      window.heap.addUserProperties({
        "Company ID": userAttributes?.["custom:company_id"] ?? "unknown",
      });
    } catch (error) {
      logger.exception(error, {
        breadcrumb: { message: "Unable to identify to Heap" },
      });
    }
  }, [isHeapEnabled, isHeapLoaded, userAttributes]);
};
