import Grid from "@mui/material/Grid";
import Markdown from "components/common/Markdown";

const PrintNote = ({ title, markdown }) => {
  if (!markdown) {
    return <></>;
  }

  return (
    <Grid item>
      <div className={"print-note"}>
        <span className={"print-label"}>{title}</span>
        <div className={"print-textarea"}>
          <Markdown value={markdown} />
        </div>
      </div>
    </Grid>
  );
};

export default PrintNote;
