import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import * as arrays from "common/arrays";
import IconButton from "components/common/IconButton";
import TextField from "components/common/TextField";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import * as endorsementsStore from "store/endorsements";

const DND_TYPE_CLAUSES = "CLAUSES";

const Entry = ({ index, value, onUpdate, onDelete, onDrop }) => {
  const [, dragRef, dragPreviewRef] = useDrag(() => ({
    type: DND_TYPE_CLAUSES,
    item: { index },
  }));

  const [, dropRef] = useDrop(
    () => ({
      accept: DND_TYPE_CLAUSES,
      drop: (item) => onDrop(item.index),
    }),
    [onDrop]
  );

  return (
    <Stack
      direction={"row"}
      spacing={2}
      justifyContent={"flex-start"}
      alignItems={"top"}
      ref={(el) => {
        dragRef(el);
        dropRef(el);
        dragPreviewRef(el);
      }}
    >
      <Typography
        width={20}
        align={"right"}
        fontWeight={"bold"}
        sx={{ padding: 2 }}
      >
        {index + 1}
      </Typography>
      <TextField
        hiddenLabel
        multiline
        value={value}
        onChange={(e) => onUpdate(e.target.value)}
      />
      <Stack
        direction={"row"}
        spacing={0}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <IconButton icon={DeleteIcon} onClick={onDelete} />
        <DragIndicatorIcon />
      </Stack>
    </Stack>
  );
};

export const FreeformList = ({ itemKey, config }) => {
  const dispatch = useDispatch();

  const endorsements = useSelector(endorsementsStore.selectInput);
  const current = endorsements?.values?.[itemKey]?.entries ?? [];

  const _updateEntries = (newEntries) =>
    dispatch(
      endorsementsStore.update({
        values: {
          ...endorsements?.values,
          [itemKey]: {
            entries: newEntries,
          },
        },
      })
    );

  const addEntry = (value) => _updateEntries([...current, value]);

  const updateEntry = (index) => (value) =>
    _updateEntries([
      ...current.slice(0, index),
      value,
      ...current.slice(index + 1),
    ]);

  const deleteEntry = (index) => () =>
    _updateEntries([...current.slice(0, index), ...current.slice(index + 1)]);

  const moveEntry = (toIndex) => (fromIndex) =>
    _updateEntries(arrays.move(current, { fromIndex, toIndex }));

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack direction={"column"} spacing={2}>
        {current.map((clause, index) => (
          <Entry
            key={index}
            index={index}
            value={clause}
            onUpdate={updateEntry(index)}
            onDelete={deleteEntry(index)}
            onDrop={moveEntry(index)}
          />
        ))}
        <Stack direction={"row"}>
          <IconButton icon={AddIcon} onClick={() => addEntry("")} />
        </Stack>
      </Stack>
    </DndProvider>
  );
};

export default FreeformList;
