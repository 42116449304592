import Metrics from "components/common/Metrics";

const sortByIdAndLevel = (scenarios) => {
  const copy = JSON.parse(JSON.stringify(scenarios));

  return copy.sort(
    (a, b) =>
      b.submission_id.localeCompare(a.submission_id) ||
      b.layer_id.localeCompare(a.layer_id) ||
      b.scenario_level - a.scenario_level
  );
};

const extractMetrics = (scenarios, scenarioCategories) => {
  const metrics = {
    insuredCount: 0,
    layerCount: 0,
    distinctScenarios: [],
    maxLimit: 0,
    totalRisk: 0,
    totalLimit: 0,
    totalGWP: 0,
    categoryStats: {},
  };

  if (!scenarios || scenarios.length === 0 || !scenarioCategories) {
    return metrics;
  }

  const sortedCopy = sortByIdAndLevel(scenarios);
  const insureds = new Set();
  const seenCategories = new Set();
  let prevKey = null;
  let prevSubmissionId = null;
  let riskLimit = 0;
  let atRisk = 0;

  sortedCopy.forEach((scenario) => {
    const key = `${scenario.submission_id}:${scenario.layer_id}`;
    const category =
      scenarioCategories[scenario.scenario_key] || "Uncategorised";
    const keyWithCategory = `${key}:${category}`;

    if (!metrics.categoryStats[category]) {
      metrics.categoryStats[category] = {
        name: category,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        totalLimit: 0,
      };
    }

    metrics.categoryStats[category][scenario.scenario_level]++;

    if (!seenCategories.has(keyWithCategory)) {
      metrics.categoryStats[category].totalLimit += scenario.line_size;
      metrics.categoryStats[category].totalGWP += scenario.share_of_premium;
      seenCategories.add(keyWithCategory);
    }

    const riskFactor = scenario.scenario_level / 4;
    riskLimit = scenario.line_size * riskFactor;
    atRisk = Math.max(riskLimit, atRisk);

    if (key !== prevKey) {
      metrics.totalLimit += scenario.line_size;
      metrics.totalGWP += scenario.share_of_premium;
      metrics.maxLimit = Math.max(riskLimit, metrics.maxLimit);
      metrics.totalRisk += riskLimit;
      riskLimit = 0;
      atRisk = 0;
      metrics.layerCount++;
      prevKey = key;
    }

    if (!metrics.distinctScenarios.includes(scenario.scenario_key)) {
      metrics.distinctScenarios.push(scenario.scenario_key);
    }

    if (scenario.submission_id !== prevSubmissionId) {
      insureds.add(scenario.insured.trim().toLowerCase());
    }

    prevSubmissionId = scenario.submission_id;
  });

  metrics.insuredCount = insureds.size;
  metrics.maxLimit = Math.max(riskLimit, metrics.maxLimit);

  return metrics;
};

const chartData = (categoryStats) => [
  {
    value: Object.entries(categoryStats).reduce(
      (acc, cur) => acc + cur[1][1],
      0
    ),
    colour: "deepskyblue",
    label: "Low",
  },
  {
    value: Object.entries(categoryStats).reduce(
      (acc, cur) => acc + cur[1][2],
      0
    ),
    colour: "gold",
    label: "Medium",
  },
  {
    value: Object.entries(categoryStats).reduce(
      (acc, cur) => acc + cur[1][3],
      0
    ),
    colour: "darkorange",
    label: "High",
  },
  {
    value: Object.entries(categoryStats).reduce(
      (acc, cur) => acc + cur[1][4],
      0
    ),
    colour: "red",
    label: "Max",
    labelColour: "orangered",
  },
];

const metricsToDisplay = (metrics, aggregateMode) => {
  if (!metrics) {
    return null;
  }

  const toDisplay = [
    { value: metrics.insuredCount, label: "Insureds" },
    { value: metrics.layerCount, label: "Layers" },
    { prefix: "$", value: metrics.maxLimit, label: "Largest Single Risk" },
    {
      prefix: "$",
      value: metrics.totalRisk,
      label: "Aggregate Risk",
      tip:
        "Sum of all limits, multiplied by the worst case scenario risk factor, for each layer",
    },
    { prefix: "$", value: metrics.totalLimit, label: "Total Limit" },
    { data: chartData(metrics.categoryStats), label: "Risk Levels" },
  ];

  if (aggregateMode) {
    toDisplay.splice(5, 0, {
      prefix: "$",
      value: metrics.totalGWP,
      label: "Total GWP",
    });
  } else {
    toDisplay.unshift({
      value: metrics.distinctScenarios.length,
      label: "Scenarios",
    });
  }

  return toDisplay;
};

const ScenarioMetrics = ({ scenarios, categories, aggregateMode }) => {
  const aggData = extractMetrics(scenarios, categories);
  const metrics = metricsToDisplay(aggData, aggregateMode);

  return <Metrics metrics={metrics} />;
};

export default ScenarioMetrics;
