import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SmallLoader from "components/common/SmallLoader";
import { useNavigate } from "react-router-dom";
import { useListCompaniesQuery } from "services/adminService";

const LOADING_KEY = "__LOADING__";

const CompanySelector = ({ companyId }) => {
  const navigate = useNavigate();

  const { data, isLoading, isError } = useListCompaniesQuery();

  const companyList = data?.map((c) => c.id)?.sort() ?? [];

  return (
    <FormControl sx={{ minWidth: 300 }} size={"small"} error={isError}>
      <InputLabel shrink>{"Select Company"}</InputLabel>
      <Select
        label={"Select Company"}
        variant={"outlined"}
        value={isLoading ? LOADING_KEY : companyId}
        onChange={(e) => {
          const newCompanyId = e.target.value;
          navigate(`/admin/${newCompanyId}`);
        }}
        disabled={isLoading}
      >
        {isLoading && (
          <MenuItem value={LOADING_KEY}>
            <SmallLoader />
          </MenuItem>
        )}
        <MenuItem value={"global"}>{"[global]"}</MenuItem>
        {companyList.map((companyId) => (
          <MenuItem key={companyId} value={companyId}>
            {companyId}
          </MenuItem>
        ))}
      </Select>
      {isError && (
        <FormHelperText>
          {"Failed to retrieve the companies list"}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CompanySelector;
