import DashboardContent from "../layout/DashboardContent";
import DashboardHeader from "../layout/DashboardHeader";
import Allocations from "./allocations/Allocations";
import SummaryNotes from "./notes/SummaryNotes";
import Print from "./program/Print";
import Snapshots from "./snapshots/Snapshots";
import Templates from "./templates/Templates";
import Box from "@mui/material/Box";
import * as navigationSupport from "components/common/Navigation.support";
import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import * as userSelectors from "store/selectors/user/userSelectors";

const Summary = (props) => {
  const printSummaryActive = useLocation().pathname.endsWith("program");

  const {
    orderSubTabs,
    isSubTabOrderLoading,
  } = navigationSupport.useOrderSubTabs({ tabName: "Summary" });

  if (isSubTabOrderLoading) {
    return <></>;
  }

  const tabs = [
    {
      title: "Program",
      path: "program",
      element: <Print />,
      visibilityTag: "summary.program",
      defaultVisibility: true,
    },
    {
      title: "Notes",
      path: "notes",
      element: <SummaryNotes />,
      visibilityTag: "summary.notes",
      defaultVisibility: true,
    },
    {
      title: "Snapshots",
      path: "snapshots",
      element: <Snapshots />,
      hidden: props.isUserReadOnly === true,
      visibilityTag: "summary.snapshots",
      defaultVisibility: false,
    },
    {
      title: "Templates",
      path: "templates",
      element: <Templates />,
      visibilityTag: "summary.templates",
      defaultVisibility: false,
    },
    {
      title: "Allocations",
      path: "allocations",
      element: <Allocations />,
      visibilityTag: "summary.allocations",
      defaultVisibility: false,
    },
  ]
    .filter((x) => !x.hidden)
    .filter(
      (x) => props.componentVisibility?.[x.visibilityTag] ?? x.defaultVisibility
    );

  const orderedTabs = orderSubTabs(tabs);

  return (
    <>
      <DashboardHeader
        tabs={orderedTabs}
        parentPath={"summary"}
        navigationState={props.navigationState}
        setNavigationState={props.setNavigationState}
        isFullWidth={props.isFullWidth}
        hideActiveSubmission={printSummaryActive}
      />
      {printSummaryActive && (
        <Box className={"no-print"} sx={{ height: "24px" }} />
      )}
      <DashboardContent isFullWidth={props.isFullWidth}>
        <Routes>
          {orderedTabs.map(({ element, path }) => (
            <Route key={path} path={`${path}/*`} element={element} />
          ))}
          <Route
            path={"*"}
            element={
              <Navigate
                to={
                  props.navigationState?.summary != null &&
                  orderedTabs.some(
                    (tab) => tab.path === props.navigationState.summary
                  )
                    ? props.navigationState.summary
                    : orderedTabs[0].path
                }
                replace={true}
              />
            }
          />
        </Routes>
      </DashboardContent>
    </>
  );
};

export const mapStateToProps = (state) => ({
  componentVisibility:
    userSelectors.selectUserConfig(state).componentVisibility || {},
  isUserReadOnly: userSelectors.isUserReadOnly(state),
});

export default connect(mapStateToProps)(Summary);
