import * as uuid from "uuid";

export const INIT_STATE = {
  attachedFiles: [],
  attachedFileNameOverrides: {},
  attachedFilesHidden: {},
  subDirectories: [],
  deletedFiles: [],
};

/** The subdirectory path is a list of directories starting
 * at the target directory and ending at the root
 */
export const getDirPath = (state, id) => {
  if (!id) return [state];
  const get_subdir_recurse = (entry) => {
    if (entry.id === id) return [entry];
    if (entry.subDirectories) {
      for (var i in entry.subDirectories) {
        const ret = get_subdir_recurse(entry.subDirectories[i]);
        if (ret) {
          ret.push(entry);
          return ret;
        }
      }
    }
    return null;
  };
  return get_subdir_recurse(state);
};

export const copy = (value, path) => {
  const copyInt = (value, path) => {
    if (path.length === 0) return value;
    return copyInt(
      {
        ...path[0],
        subDirectories: [
          ...path[0].subDirectories.map((sd) =>
            sd.id === value.id ? value : sd
          ),
        ],
      },
      path.slice(1)
    );
  };
  if (!path || path.length === 0) return value;
  return copyInt({ ...path[0], ...(value || {}) }, path.slice(1));
};

const sortSubdirectories = (subDirectories) => {
  return [...subDirectories].sort((a, b) => {
    if (!a.name) return -1;
    if (!b.name) return 1;
    const aup = a.name.toUpperCase();
    const bup = b.name.toUpperCase();
    if (aup < bup) return -1;
    if (aup > bup) return 1;
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
};

export const addDirectory = (state, parentId, name) => {
  const places = getDirPath(state, parentId);
  if (!places) return state;
  return copy(
    {
      subDirectories: sortSubdirectories([
        ...places[0].subDirectories,
        {
          ...INIT_STATE,
          name,
          id: uuid.v4(),
        },
      ]),
    },
    places
  );
};

export const removeDirectory = (state, id) => {
  if (!id) return state;
  const places = getDirPath(state, id);
  if (!places || places.length < 2) return state;
  const parent = places[1];
  return copy(
    { subDirectories: parent.subDirectories.filter((dir) => dir.id !== id) },
    places.slice(1)
  );
};

export const renameDirectory = (state, id, name) => {
  if (!id || !name) return state;
  const places = getDirPath(state, id);
  if (!places || places.length < 1) return state;
  const place = places[0];
  if (place.name === name) return state;
  return copy({ name }, places);
};

export const removeFileFromDirectory = (state, fileId, directoryId) => {
  const update = {};
  const path = getDirPath(state, directoryId);
  if (!path || path.length < 1) return state;
  const dir = path[0];
  const attachedFiles = dir.attachedFiles || [];
  const isFile = attachedFiles.includes(fileId);
  if (isFile) {
    const deletedFiles = dir.deletedFiles || [];
    if (!deletedFiles.includes(fileId))
      update.deletedFiles = [...deletedFiles, fileId];
    update.attachedFiles = attachedFiles.filter((fid) => fid !== fileId);
  } else {
    const hidden = dir.attachedFilesHidden || {};
    update.attachedFilesHidden = { ...hidden, [fileId]: true };
  }
  const overriden = dir.attachedFileNameOverrides || {};
  if (overriden[fileId]) {
    update.attachedFileNameOverrides = Object.fromEntries(
      Object.entries(overriden).filter((e) => e[0] !== fileId)
    );
  }
  return copy(update, path);
};

export const moveDirectory = (state, dirId, toDirId) => {
  const srcPath = getDirPath(state, dirId);
  if (!srcPath || srcPath.length < 2) return state;
  const srcDir = srcPath[0];
  const srcParent = srcPath[1];
  const updateSrc = {
    subDirectories: (srcParent.subDirectories || []).filter(
      (d) => d.id !== dirId
    ),
  };
  const newState = copy(updateSrc, srcPath.slice(1));

  const tgtPath = getDirPath(newState, toDirId);
  if (!tgtPath || tgtPath.length < 1) return state;
  if (tgtPath.map((e) => e.id).includes(dirId)) {
    return state;
  }
  const updateTgt = {
    subDirectories: [...(tgtPath[0].subDirectories || []), srcDir],
  };
  return copy(updateTgt, tgtPath);
};

export const moveFileToDirectory = (
  state,
  fileId,
  fromDirId,
  toDirId,
  embeddedFiles
) => {
  const srcPath = getDirPath(state, fromDirId);
  if (!srcPath || srcPath.length === 0) return state;
  const tgtPath = getDirPath(state, toDirId);
  if (!tgtPath || tgtPath.length === 0) return state;

  // s could be source of confusion
  // We cannot move directly between a directory and itself so that is disabled
  // but we can move from a file embedded in a file in a directory
  // to the same directory as this will become a non embedded file
  if (
    (fromDirId === toDirId || (!fromDirId && !toDirId)) &&
    (srcPath[0].attachedFiles || []).includes(fileId)
  )
    return state;
  const srcDir = srcPath[0];

  const srcHidden = {};
  if (embeddedFiles && srcDir.attachedFilesHidden) {
    embeddedFiles
      .filter((id) => srcDir.attachedFilesHidden[id])
      .forEach((id) => (srcHidden[id] = true));
  }
  const srcOverrides = srcDir.attachedFileNameOverrides || {};
  const srcOverride = srcOverrides[fileId];
  // we must remove before adding otherwise there is a situation where
  // we will add and then remove what has just been adadded.
  const remState = removeFileFromDirectory(state, fileId, fromDirId);
  const addedState = addFileToDirectory(remState, fileId, toDirId, srcHidden);
  return srcOverride
    ? setFileNameInDirectory(addedState, fileId, toDirId, srcOverride)
    : addedState;
};

export const moveFileToFile = (
  state,
  fileId,
  fromDirId,
  toDirId,
  embeddedFiles
) => {
  const path = getDirPath(state, fromDirId);
  const override = (path[0].attachedFileNameOverrides || {})[fileId];
  const remState = removeFileFromDirectory(state, fileId, fromDirId);

  const srcHidden = {};
  if (embeddedFiles && path[0].attachedFilesHidden) {
    embeddedFiles
      .filter((id) => path[0].attachedFilesHidden[id])
      .forEach((id) => (srcHidden[id] = true));
  }
  const tgtPath = getDirPath(remState, toDirId);
  if (!tgtPath || tgtPath.length === 0) return state;
  const tgtDir = tgtPath[0];
  const hidden = tgtDir.attachedFilesHidden || {};
  if (!hidden[fileId]) return state;
  const update = {
    attachedFilesHidden: { ...hidden, [fileId]: false, ...srcHidden },
  };
  if (override) {
    const overrides = tgtDir.attachedFileNameOverrides || {};
    update.attachedFileNameOverrides = { ...overrides, [fileId]: override };
  }
  return copy(update, tgtPath);
};

export const addFileToDirectory = (
  state,
  fileId,
  directoryId,
  toBeHidden = {}
) => {
  const tgtPath = getDirPath(state, directoryId);
  if (!tgtPath || tgtPath.length === 0) return state;
  const tgtDir = tgtPath[0];

  const tgtUpdate = {};
  let changed = false;
  const attachedFiles = tgtDir.attachedFiles || [];
  const hasEntry = attachedFiles.includes(fileId);
  const deletedFiles = tgtDir.deletedFiles || [];
  const isDeleted = deletedFiles.includes(fileId);
  if (isDeleted) {
    tgtUpdate.deletedFiles = deletedFiles.filter((id) => id !== fileId);
    changed = true;
  }
  if (!hasEntry) {
    tgtUpdate.attachedFiles = [...attachedFiles, fileId];
    if (toBeHidden) {
      tgtUpdate.attachedFilesHidden = { ...tgtDir.attachedFilesHidden };
      for (const id in toBeHidden) tgtUpdate.attachedFilesHidden[id] = true;
    }
    changed = true;
  }
  if (!changed) return state;
  const copied = copy(tgtUpdate, tgtPath);
  return copied;
};

export const setFileNameInDirectory = (state, fileId, directoryId, name) => {
  const path = getDirPath(state, directoryId);
  if (!path || path.length < 1) return state;
  let nameList = path[0].attachedFileNameOverrides || {};
  if (name) {
    if (name !== nameList[fileId]) {
      const names = { ...nameList, [fileId]: name };
      return copy({ attachedFileNameOverrides: names }, path);
    }
  } else {
    if (nameList[fileId]) {
    }
    const names = Object.fromEntries(
      Object.entries(nameList).filter((e) => e[0] !== fileId)
    );
    return copy({ attachedFileNameOverrides: names }, path);
  }
  return state;
};

export const attachedFileUploaded = (state, fileId) => {
  const files = state.attachedFiles || [];
  if (files.filter((fid) => fid === fileId).length > 0) return state;
  return {
    ...state,
    attachedFiles: [...state.attachedFiles, fileId],
  };
};
