import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const layerDefaultsSlice = createSlice({
  name: "layerDefaults",
  initialState: {},
  reducers: {
    set: (state, action) => {
      const values = action?.payload ?? {};
      if (values?.constructor?.name !== "Object") {
        return;
      }
      return {
        ...values,
      };
    },
    update: (state, action) => {
      const values = action?.payload;
      if (values?.constructor?.name !== "Object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => ({
          ...action?.payload?.data?.state?.layerDefaults,
        })
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, (_, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.layerDefaults,
        };
      })
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (state, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.layerDefaults,
          ...state,
        };
      });
  },
});

export const selectInput = (state) => state?.layerDefaults ?? null;

export const select = createSelector([selectInput], (input) => input);

export const { set, update } = layerDefaultsSlice.actions;

export default layerDefaultsSlice;
