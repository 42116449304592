import { createSlice } from "@reduxjs/toolkit";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

const initialState = {
  texts: {},
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    updateNoteText: (state, action) => {
      return {
        ...state,
        texts: {
          ...state?.texts,
          [action.payload.key]: action.payload.text,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => action?.payload?.data?.state?.notes ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => initialState)
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, () => initialState);
  },
});

export const selectNotes = (state) => state?.notes ?? {};

export const selectNoteText = (key) => (state) =>
  selectNotes(state).texts?.[key];

export const { updateNoteText } = notesSlice.actions;

export default notesSlice;
