import Notes from "./Notes";
import { useDispatch, useSelector } from "react-redux";
import * as notesStore from "store/notes";

const TextNote = ({ title, noteKey }) => {
  const dispatch = useDispatch();

  const value = useSelector(notesStore.selectNoteText(noteKey));

  const update = (text) =>
    dispatch(notesStore.updateNoteText({ key: noteKey, text }));

  return <Notes title={title} value={value} update={update} xs_width={12} />;
};

export default TextNote;
