import ClaimsSlider from "./ClaimsSlider";
import FilteredClaimsChart from "./FilteredClaimsChart";
import { Warning as WarningIcon } from "@mui/icons-material";
import { Alert, Box, Button, Grid, Stack } from "@mui/material";
import * as charts from "common/charts";
import Component from "components/Component";
import ConditionalTooltip from "components/common/ConditionalTooltip.js";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import * as config from "config.js";
import { connect } from "react-redux";
import * as actions from "store/actions/input/claims/claimsActions";
import * as selectors from "store/selectors/input/claims/claimsSelectors";

const FilteredClaims = (props) => {
  const hasFreeLimit =
    props.data?.biggestClaim < props.filter.limit + props.filter.attachment;
  const numberTicks = charts.makeTicksForNumericDomain(
    charts.getNumberDomain(props.data)
  );
  const numberDomain = [numberTicks[0], numberTicks.at(-1)];
  const dateDomain = charts.getDateDomain(props.data);

  return (
    <Component
      title={`All Claims ${
        props.claimsThreshold > 0
          ? `(> $${props.claimsThreshold.toLocaleString("en-US", {
              maximumFractionDigits: 0,
            })})`
          : ""
      }`}
      subtitle={"In excess of threshold"}
      xs_width={12}
      loading={props.data === null}
    >
      <Grid container spacing={config.GRID_SPACING} alignItems={"stretch"}>
        <Grid item xs={12}>
          <Box mr={6}>
            {props.error ? (
              <Alert severity={"error"}>{props.error}</Alert>
            ) : (
              <FilteredClaimsChart
                disabled={props.disabled}
                data={props.data}
                filter={props.filter}
                dateDomain={dateDomain}
                numberTicks={numberTicks}
                numberDomain={numberDomain}
                zoomIdentifier={props?.zoomIdentifier}
                onZoom={props?.onZoom}
              />
            )}
          </Box>
        </Grid>
        {!props.disabled && (
          <>
            <Grid item xs={12}>
              <ClaimsSlider
                title={"Limit"}
                value={props.filter.limit}
                onChangeNumber={(number) =>
                  props.updateClaimsFilter("limit", number)
                }
                min={0}
                max={props.data?.biggestClaim || 1e9}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ClaimsSlider
            title={"Attachment"}
            value={props.claimsThreshold}
            onChangeNumber={props.updateClaimThreshold}
            min={0}
            max={props.data?.biggestClaim || 1e9}
          />
        </Grid>
        {!props.disabled &&
          (props.isViewOnly ? (
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-end"}
              spacing={config.GRID_SPACING}
              sx={{ width: "100%" }}
            >
              <Button
                disableElevation
                variant={"contained"}
                color={"secondary"}
                onClick={props.clearClaimsFilter}
              >
                {"Clear"}
              </Button>
              <Button
                disableElevation
                variant={"contained"}
                color={"secondary"}
                onClick={(e) => {
                  props.clearChartZoom(props?.zoomIdentifier);
                }}
              >
                {props.clearZoomLabel ?? "Clear Zoom"}
              </Button>
            </Stack>
          ) : (
            <>
              <Grid item lg={3} xs={6}>
                <FormattedDatePicker
                  label={"From"}
                  onChangeDate={(date) =>
                    props.updateClaimsFilter("from", date)
                  }
                  value={props.filter.from || ""}
                  fullWidth
                />
              </Grid>
              <Grid item lg={3} xs={6}>
                <FormattedDatePicker
                  label={"To"}
                  onChangeDate={(date) => props.updateClaimsFilter("to", date)}
                  value={props.filter.to || ""}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                container
                lg={6}
                spacing={config.GRID_SPACING}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Grid item>
                  <ConditionalTooltip
                    title={"Are you sure you want to give away free limit?"}
                    conditional={hasFreeLimit}
                  >
                    <Button
                      disableElevation
                      variant={"contained"}
                      onClick={props.useFilterAsBaseLayer}
                      className={hasFreeLimit ? "button-has-error" : ""}
                    >
                      {hasFreeLimit ? <WarningIcon /> : <></>}
                      {"Use as Base Layer"}
                    </Button>
                  </ConditionalTooltip>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation
                    variant={"contained"}
                    color={"secondary"}
                    onClick={props.clearClaimsFilter}
                  >
                    {"Clear"}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disableElevation
                    variant={"contained"}
                    color={"secondary"}
                    onClick={(e) => {
                      props.onZoom
                        ? props.onZoom(null, null)
                        : props.clearChartZoom(props?.zoomIdentifier);
                    }}
                  >
                    {props.clearZoomLabel ?? "Clear Zoom"}
                  </Button>
                </Grid>
              </Grid>
            </>
          ))}
        {props.disabled && (
          <Grid container item xs={12} justifyContent={"flex-end"}>
            <Button
              disableElevation
              variant={"contained"}
              color={"secondary"}
              onClick={(e) => {
                props.onZoom
                  ? props.onZoom(null, null)
                  : props.clearChartZoom(props?.zoomIdentifier);
              }}
            >
              {props.clearZoomLabel ?? "Clear Zoom"}
            </Button>
          </Grid>
        )}
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {
  updateClaimThreshold: actions.updateClaimThreshold,
  updateClaimsFilter: actions.updateClaimsFilter,
  clearClaimsFilter: actions.clearClaimsFilter,
  useFilterAsBaseLayer: actions.useFilterAsBaseLayer,
  clearChartZoom: actions.clearChartZoom,
};

const mapStateToProps = (state) => ({
  claimsThreshold: selectors.selectClaimsThreshold(state),
  filter: selectors.selectClaimsFilter(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FilteredClaims);
