import ExtractTriangle from "./ExtractTriangle";
import SelectFile from "./SelectFile";
import SelectRange from "./SelectRange";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import { Stack, Typography } from "@mui/material";
import * as config from "config";

const transformTypeToInputComponent = {
  select_file: SelectFile,
  select_range: SelectRange,
  extract_triangle: ExtractTriangle,
};

const DefaultComponent = () => (
  <Stack direction={"column"} alignItems={"center"} sx={{ paddingTop: "5vh" }}>
    <ErrorIcon sx={{ color: "red", fontSize: 100, marginBottom: "2vh" }} />
    <Typography
      variant={"h6"}
    >{`Oops. Something unexpected happened. Please contact ${config.SUPPORT_EMAIL}`}</Typography>
  </Stack>
);

export const getInputComponent = (type) =>
  transformTypeToInputComponent[type] ?? DefaultComponent;
