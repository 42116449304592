import { SOV_DOWNLOAD_BUTTON_ENABLED } from "../../../flags";
import { useDownloadSovMutation } from "../../../services/sovService";
import Visible from "../../common/Visible";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import { useSelector } from "react-redux";
import * as SOVStore from "store/SOV";
import * as program from "store/selectors/input/program/programSelectors";

const DownloadSOVButton = () => {
  const sovInput = useSelector(SOVStore.selectSOV);
  const insured = useSelector(program.getInsured);
  const hasSOVTable = !!sovInput?.sovFileId;
  const filename = `SOV-${insured || ""}-${new Date().toISOString()}.xlsx`;
  const [
    triggerDowloadSOV,
    { isLoading: isDownloading },
  ] = useDownloadSovMutation();
  const downloadSOV = () => {
    triggerDowloadSOV({
      sovInput,
      outputType: "EXCEL",
      filename,
    });
  };

  return (
    <Visible byTag={SOV_DOWNLOAD_BUTTON_ENABLED} defaultVisible={true}>
      <Disabled ifReadOnly>
        <Button
          size={"small"}
          isLoading={isDownloading}
          isDisabled={!hasSOVTable}
          onClick={downloadSOV}
        >
          {"Download"}
        </Button>
      </Disabled>
    </Visible>
  );
};

export default DownloadSOVButton;
