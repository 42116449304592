/* eslint no-console: "off" */
import * as logger from "./logger";
import * as Sentry from "@sentry/react";

export const LEVEL = {
  DEBUG: "debug",
  INFO: "info",
  LOG: "log",
  WARNING: "warning",
  ERROR: "error",
  CRITICAL: "critical",
  FATAL: "fatal",
};

const shouldLogToConsole = () => process.env.NODE_ENV === "development";

export const breadcrumb = (
  breadcrumb,
  { level = LEVEL.INFO, category = undefined } = {}
) => {
  try {
    if (shouldLogToConsole()) {
      console.log(breadcrumb);
    } else {
      Sentry.addBreadcrumb({
        level,
        category,
        message: JSON.stringify(breadcrumb),
      });
    }
  } catch {}
};

export const info = (...args) => {
  if (shouldLogToConsole()) {
    console.log(...args);
  }
};

export const error = (error, { breadcrumb } = {}) => {
  logger.exception(new Error(error), { breadcrumb });
};

export const exception = (error, { breadcrumb } = {}) => {
  try {
    if (breadcrumb) {
      logger.breadcrumb(breadcrumb, { level: LEVEL.ERROR });
    }
    if (shouldLogToConsole()) {
      console.error(error);
    } else {
      Sentry.captureException(error);
    }
  } catch {}
};
