import * as layerSupport from "./layerSupport";
import * as paymentResolvers from "./paymentResolvers";
import * as dates from "common/dates";
import * as numbers from "common/numbers";

export const resolveProgram = (program, { layerInputs } = {}) => {
  let inception = program.inception;
  if (isNaN(new Date(program.inception).getTime())) {
    inception = dates.getFirstOfNextMonthStrNoTimeZone();
  }
  const expiration =
    program.expiration || dates.getMoveForwardOneYear(inception);
  const proRataAdjustment =
    program.proRataAdjustment != null && program.proRataAdjustment !== ""
      ? numbers.parseFloatOrNull(program.proRataAdjustment)
      : dates.yearsBetween(expiration, inception) * 100;

  const effectiveDate = program.effectiveDate ?? inception;

  const paymentTerms = paymentResolvers.resolvePaymentTerms(
    program.paymentTerms,
    { fromDateString: effectiveDate }
  );

  const out = {
    ...program,
    inception,
    expiration,
    proRataAdjustment,
    paymentTerms,
    status: program.status ?? layerSupport.extractProgramStatus(layerInputs),
    transaction:
      program.transaction ?? (program.priorSubmission ? "RENEWAL" : "NEW"),
  };

  if (program.targetTotalExpenses != null && program.expenses == null) {
    const otherExpenses = program.brokerage ?? 0.0;
    out.expenses = program.targetTotalExpenses - otherExpenses;
  }

  return out;
};
