import * as colors from "@mui/material/colors";

export const getExtension = (filename) => {
  if (filename == null) {
    return filename;
  }
  return filename.toLowerCase().split(".").slice(-1)[0];
};

export const isConvertableExtension = (extension) => {
  return ["docx", "xlsx", "pptx"].includes(extension);
};

export const getExtensionMeta = (extension) => {
  switch ((extension || "").toLowerCase()) {
    case "xls":
    case "xlsx":
    case "xlsm":
      return {
        primaryColor: colors.green[600],
        secondaryColor: colors.green[50],
        supportedExtension: true,
      };
    case "doc":
    case "docx":
    case "docm":
      return {
        primaryColor: colors.blue[600],
        secondaryColor: colors.blue[50],
        supportedExtension: true,
      };
    case "ppt":
    case "pptx":
    case "pptm":
      return {
        primaryColor: colors.deepOrange[500],
        secondaryColor: colors.deepOrange[50],
        supportedExtension: true,
      };
    case "pdf":
      return {
        primaryColor: colors.red[500],
        secondaryColor: colors.red[50],
        supportedExtension: true,
      };
    case "jpeg":
    case "jpg":
    case "gif":
    case "png":
    case "bmp":
    case "svg":
    case "webp":
      return {
        primaryColor: colors.amber[600],
        secondaryColor: colors.amber[50],
        supportedExtension: true,
      };
    case "zip":
    case "tar":
      return {
        primaryColor: colors.blueGrey[400],
        secondaryColor: colors.blueGrey[50],
        supportedExtension: true,
      };
    default:
      return {
        primaryColor: colors.blueGrey[400],
        secondaryColor: colors.blueGrey[50],
        supportedExtension: false,
      };
  }
};
