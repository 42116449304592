import DashboardContent from "../layout/DashboardContent";
import DashboardHeader from "../layout/DashboardHeader";
import Exposure from "./exposure/Exposure";
import Group from "./group/Group";
import Review from "./review/Review";
import * as navigationSupport from "components/common/Navigation.support";
import React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import * as userSelectors from "store/selectors/user/userSelectors";

const TABS = [
  {
    title: "Review",
    path: "review",
    element: <Review />,
    visibilityTag: "comparison.review",
    defaultVisibility: true,
  },
  {
    title: "Group",
    path: "group",
    element: <Group />,
    visibilityTag: "comparison.group",
    defaultVisibility: true,
  },
  {
    title: "Exposure",
    path: "exposure",
    element: <Exposure />,
    visibilityTag: "comparison.exposure",
    defaultVisibility: true,
  },
];

const Peers = (props) => {
  const {
    orderSubTabs,
    isSubTabOrderLoading,
  } = navigationSupport.useOrderSubTabs({ tabName: "Comparison" });

  if (isSubTabOrderLoading) {
    return <></>;
  }

  const orderedTabs = orderSubTabs(props.tabs);

  return (
    <>
      <DashboardHeader
        tabs={orderedTabs}
        parentPath={"peers"}
        navigationState={props.navigationState}
        setNavigationState={props.setNavigationState}
        isFullWidth={props.isFullWidth}
      />
      <DashboardContent isFullWidth={props.isFullWidth}>
        <Routes>
          {orderedTabs.map(({ element, path }) => (
            <Route key={path} path={`${path}/*`} element={element} />
          ))}
          <Route
            path={"*"}
            element={
              <Navigate
                to={
                  props.navigationState?.peers != null &&
                  orderedTabs.some(
                    (tab) => tab.path === props.navigationState.peers
                  )
                    ? props.navigationState.peers
                    : orderedTabs[0].path
                }
                replace={true}
              />
            }
          />
        </Routes>
      </DashboardContent>
    </>
  );
};

export const mapStateToProps = (state) => {
  const userConfig = userSelectors.selectUserConfig(state);
  const stateVisibilitySelectors = userSelectors.getComponentOnStateVisibility(
    state
  );
  return {
    tabs: TABS.filter((tab) => {
      const tag = tab.visibilityTag;
      const stateIndependentVisibility =
        userConfig.componentVisibility?.[tag] ?? tab.defaultVisibility;
      const stateVisibilty =
        stateVisibilitySelectors?.[tag] ?? (() => stateIndependentVisibility);
      return stateVisibilty(state) ?? stateIndependentVisibility;
    }),
  };
};

export default connect(mapStateToProps)(Peers);
