import ClearIcon from "@mui/icons-material/Clear";
import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "components/common/IconButton";
import React, { useEffect, useState } from "react";

const EditableFilename = ({
  extension,
  onSubmit,
  id,
  style,
  uploaded,
  value,
  noEditing,
  onClick,
  onEdit,
  onClose,
}) => {
  const [newValue, setNewValue] = useState("");
  const [editing, setEditing] = useState(false);

  const handleSubmit = (givenValue) => {
    let val = givenValue.trim();
    if (val.length === 0) {
      if (givenValue.length !== 0) setNewValue("");
    } else {
      const dotx = extension.length ? "." + extension : "";
      val = val + (val.endsWith(dotx) ? "" : dotx);
      setNewValue(val);
    }
    if (onSubmit != null) {
      onSubmit(val);
    }
    setTimeout(() => setEditing(false), 20);
    if (onClose) onClose();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(newValue);
    } else if (e.key === "Escape") {
      setEditing(false);
      if (onClose) onClose();
      setNewValue("");
    }
  };

  useEffect(() => {
    if (editing) {
      document.getElementById(id).focus();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  });

  return editing ? (
    <>
      <TextField
        onBlur={(e) => {
          handleSubmit(newValue);
        }}
        onChange={(e) => {
          setNewValue(e.target.value);
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (onClick) onClick(e);
        }}
        value={newValue}
        id={id}
        size={"small"}
        style={{ ...style, marginRight: "10px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position={"end"}>
              <IconButton
                icon={ClearIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSubmit("");
                }}
              />
            </InputAdornment>
          ),
        }}
        data-testid={`editable-dir-name-${value}`}
      />
      <Typography style={{ marginRight: "10px" }}>{`.${extension}`}</Typography>
    </>
  ) : (
    <Typography
      onClick={(e) => {
        if (onClick) onClick(e);
        if (e.detail > 1 && uploaded) {
          if (onEdit) onEdit();
          setEditing(!noEditing);
          const nv = value.substring(0, value.length - extension.length);
          setNewValue(nv.endsWith(".") ? nv.substring(0, nv.length - 1) : nv);
        }
      }}
      style={style}
      data-testid={`editable-dir-name-${value}`}
    >
      {value}
    </Typography>
  );
};

export default EditableFilename;
