import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as averages from "common/averages";
import * as lossDevelopmentResolvers from "domain/lossDevelopmentResolvers";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as uuid from "uuid";

export const MODELS = [
  { name: "LDF", method: "ldf" },
  { name: "BF", method: "bf" },
];

const initialState = {
  triangles: [],
};

export const selectLossDevelopmentInput = (state) =>
  state?.lossDevelopment ?? null;

export const selectPolicyYears = createSelector(
  [selectLossDevelopmentInput],
  (lossDevelopmentInput) => {
    const triangles = lossDevelopmentInput?.triangles ?? {};
    const years = Object.values(triangles).reduce((years, triangle) => {
      return years.concat((triangle?.losses ?? []).map((x) => x.inception));
    }, []);
    const startYear = Math.min(...years);
    const endYear = Math.max(...years);
    const policyYears = [];
    for (let i = startYear; i <= endYear; i++) {
      policyYears.push(i.toString());
    }
    return policyYears;
  }
);

export const sumTriangleLossesUtil = (triangleLosses) =>
  Object.entries(
    triangleLosses.reduce((sumTable, losses) => {
      for (const loss of losses) {
        sumTable[loss.inception] = {
          ...sumTable[loss.inception],
          [loss.ageMonths]:
            loss.value + (sumTable[loss.inception]?.[loss.ageMonths] ?? 0),
        };
      }
      return sumTable;
    }, {})
  ).reduce((arr, [inception, monthValues]) => {
    for (const [ageMonths, value] of Object.entries(monthValues)) {
      arr.push({
        inception,
        ageMonths,
        value,
      });
    }
    return arr;
  }, []);

const selectUltimateFromTriangleByYear = ({ triangle, method }) => {
  const resolvedUltimate = {};
  if (
    triangle?.policyYears != null ||
    triangle?.ultimateLossesByPolicyYear != null
  ) {
    triangle.policyYears.forEach((year, index) => {
      resolvedUltimate[year] =
        triangle.ultimateLossesByPolicyYear[method][index].value;
    });
  }
  return resolvedUltimate;
};

export const selectUltimateSelection = (state) => {
  const lossDevelopment = selectLossDevelopment(state);
  const ultimate = lossDevelopment?.ultimate;
  const triangles = lossDevelopment?.triangles;

  if (!ultimate || Object.keys(ultimate || {}).length < 1) {
    return null;
  }
  const selectedOverrides = ultimate?.selectedOverrides || {};
  const resolvedUltimate = selectUltimateFromTriangleByYear({
    triangle: triangles[ultimate?.selected.id],
    method: ultimate?.selected.method,
  });
  const ultimates = Object.fromEntries(
    Object.keys(triangles).map((id) => [
      id,
      Object.fromEntries(
        MODELS.map(({ method }) => [
          method,
          selectUltimateFromTriangleByYear({ triangle: triangles[id], method }),
        ])
      ),
    ])
  );
  const selectedUltimates = Object.fromEntries(
    Object.entries(selectedOverrides).map(([year, { id, method }]) => [
      year,
      ultimates[id][method][year],
    ])
  );
  return {
    ...resolvedUltimate,
    ...selectedUltimates,
    ...(ultimate?.manualOverrides || {}),
  };
};

export const selectPremium = createSelector(
  [exposureSelectors.getData, selectPolicyYears],
  (exposureData, policyYears) =>
    Object.fromEntries(
      policyYears.map((policyYear) => [
        policyYear,
        exposureData?.[String(policyYear)]?.FGU_PREMIUM ?? null,
      ])
    )
);

export const selectLossRatio = createSelector(
  [selectUltimateSelection, selectPremium, selectPolicyYears],
  (ultimate, premium, policyYears) =>
    Object.fromEntries(
      policyYears.map((policyYear) => {
        const ult = ultimate?.[policyYear];
        const prem = premium?.[policyYear];
        return [policyYear, ult != null && !!prem ? ult / prem : null];
      })
    )
);

export const selectLossRatioSummary = createSelector(
  [selectPolicyYears, selectLossRatio],
  (policyYears, lossRatio) => {
    return Object.fromEntries(
      averages.supportedAverages.map((average) => [
        average,
        averages.yearBasedAverage(lossRatio, policyYears, average),
      ])
    );
  }
);

const selectLossRatioSelection = createSelector(
  [(state) => state?.lossDevelopment?.lossRatio, selectLossRatioSummary],
  (selection, summary) => {
    return {
      ...selection,
      value:
        selection?.manualOverride ?? summary?.[selection?.selected] ?? null,
      isManualOverride: !!selection?.manualOverride,
    };
  }
);

export const selectUltimateSummary = createSelector(
  [
    selectUltimateSelection,
    selectPremium,
    selectLossRatio,
    selectLossRatioSummary,
    selectLossRatioSelection,
  ],
  (ultimate, premium, lossRatio, lossRatioSummary, lossRatioSelection) => ({
    ultimate,
    premium,
    lossRatio: {
      byYear: lossRatio,
      summary: lossRatioSummary,
      selection: lossRatioSelection,
    },
  })
);

const lossDevelopmentSlice = createSlice({
  name: "lossDevelopment",
  initialState,
  reducers: {
    setLossDevelopment: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...initialState,
        ...values,
      };
    },
    updateLossDevelopment: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
    updateInitialExpectedLossRatio: (state, action) => {
      return {
        ...state,
        initialExpectedLossRatio: action.payload,
      };
    },
    updateUltimateSelection: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      const currentSelection = state?.ultimate?.selected;
      const newSelection = { id: values.id, method: values.method };
      const switchOff =
        currentSelection != null &&
        currentSelection === newSelection.id &&
        currentSelection === newSelection.method;
      return {
        ...state,
        ultimate: {
          ...state?.ultimate,
          selected: switchOff ? null : newSelection,
        },
      };
    },
    updateUltimateOverrideSelection: (state, action) => {
      const { id, year, method } = action?.payload ?? {};
      const ultimate = state?.ultimate;
      if (!id || !year || !method || !ultimate) {
        return;
      }
      let cleanManualOverrides = { ...ultimate.manualOverrides };
      delete cleanManualOverrides[year];
      let cleanSelectedOverrides = { ...ultimate.selectedOverrides };
      delete cleanSelectedOverrides[year];
      const currentSelectedOverride = ultimate.selectedOverrides?.[year];
      const deleteOnly =
        currentSelectedOverride &&
        currentSelectedOverride.id === id &&
        currentSelectedOverride.method === method;

      return {
        ...state,
        ultimate: {
          ...state?.ultimate,
          selectedOverrides: deleteOnly
            ? cleanSelectedOverrides
            : {
                ...cleanSelectedOverrides,
                [year]: { id, method },
              },
          manualOverrides: cleanManualOverrides,
        },
      };
    },
    updateUltimateManualOverrideSelection: (state, action) => {
      const { year, value } = action?.payload ?? {};
      const ultimate = state?.ultimate;
      if (!year || !ultimate) {
        return;
      }
      let cleanManualOverrides = { ...ultimate.manualOverrides };
      delete cleanManualOverrides[year];
      let cleanSelectedOverrides = { ...ultimate.selectedOverrides };
      delete cleanSelectedOverrides[year];
      return {
        ...state,
        ultimate: {
          ...state?.ultimate,
          selectedOverrides: cleanSelectedOverrides,
          manualOverrides:
            value == null
              ? cleanManualOverrides
              : {
                  ...state?.ultimate?.manualOverrides,
                  [year]: value,
                },
        },
      };
    },
    updateUltimateSelectedAverage: (state, action) => {
      return {
        ...state,
        ultimate: {
          ...state?.ultimate,
          selectedAverage: action?.payload?.selected,
        },
      };
    },
    updateLossRatioSelection: (state, action) => {
      let cleanLossRatio = { ...state?.lossRatio };
      delete cleanLossRatio.manualOverride;
      return {
        ...state,
        lossRatio: {
          ...cleanLossRatio,
          selected: action?.payload?.selected,
        },
      };
    },
    updateLossRatioManualOverride: (state, action) => {
      let cleanLossRatio = { ...state?.lossRatio };
      delete cleanLossRatio.selected;
      return {
        ...state,
        lossRatio: {
          ...cleanLossRatio,
          manualOverride: action?.payload?.manualOverride,
        },
      };
    },
    addLossDevelopmentTriangle: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      const id = uuid.v4();
      return {
        ...state,
        triangles: {
          ...state.triangles,
          [id]: {
            ...values,
            id,
          },
        },
      };
    },
    updateLossDevelopmentTriangle: (state, action) => {
      const { id, triangle: values } = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...state,
        triangles: {
          ...state.triangles,
          [id]: {
            ...state.triangles[id],
            ...values,
          },
        },
      };
    },
    sumLossDevelopmentTriangles: (state, action) => {
      const { ids } = action?.payload ?? {};
      if ((ids ?? [])?.length === 0) {
        return;
      }
      const triangleSum = {
        id: uuid.v4(),
        name: ids.map((id) => state.triangles[id].name).join(" + "),
        losses: sumTriangleLossesUtil(
          ids.map((id) => state.triangles[id].losses)
        ),
        sources: ids,
      };
      return {
        ...state,
        triangles: {
          ...state.triangles,
          [triangleSum.id]: {
            ...triangleSum,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) =>
          action?.payload?.data?.state?.lossDevelopment ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => initialState)
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, () => initialState);
  },
});

export const selectLossDevelopment = createSelector(
  [selectLossDevelopmentInput, exposureSelectors.getData],
  (lossDevelopmentInput, exposureData) =>
    lossDevelopmentResolvers.resolveLossDevelopment(lossDevelopmentInput, {
      exposureData,
    })
);

export const {
  setLossDevelopment,
  updateLossDevelopment,
  updateUltimateSelection,
  updateUltimateOverrideSelection,
  updateUltimateManualOverrideSelection,
  updateUltimateSelectedAverage,
  addLossDevelopmentTriangle,
  updateInitialExpectedLossRatio,
  updateLossDevelopmentTriangle,
  sumLossDevelopmentTriangles,
  updateLossRatioManualOverride,
  updateLossRatioSelection,
} = lossDevelopmentSlice.actions;

export default lossDevelopmentSlice;
