export const ADD_PEER = "ADD_PEER";
export const DELETE_PEER = "DELETE_PEER";
export const LOAD_PEER = "LOAD_PEER";
export const LOAD_PEER_FAILED = "LOAD_PEER_FAILED";
export const UPDATE_CHOSEN_PEER_EXPOSURE_METRIC =
  "UPDATE_CHOSEN_PEER_EXPOSURE_METRIC";
export const UPDATE_CHOSEN_PEER_EXPOSURE_METRIC_2 =
  "UPDATE_CHOSEN_PEER_EXPOSURE_METRIC_2";
export const UPDATE_PEERS_NOTE = "UPDATE_PEERS_NOTE";
export const UPDATE_SELECTED_PEERS = "UPDATE_SELECTED_PEERS";
export const RECALCULATE_PEER_PRICING = "RECALCULATE_PEER_PRICING";
export const RECEIVED_SEARCH_RESPONSE = "RECEIVED_SEARCH_RESPONSE";
export const PEER_PRICING_RECEIVED = "PEER_PRICING_RECEIVED";
export const PEER_PRICING_FAILED = "PEER_PRICING_FAILED";
export const PEER_PRICING_UPDATING = "PEER_PRICING_UPDATING";
export const UPDATE_PEER_CHOSEN_EXPOSURE_METRIC =
  "UPDATE_PEER_CHOSEN_EXPOSURE_METRIC";
export const UPDATE_PEERS_INDUSTRY_CLASS = "UPDATE_PEERS_INDUSTRY_CLASS";
