import {
  Chip,
  Card,
  CardContent,
  Link,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { hasItems } from "common/arrays";
import { hasValue } from "common/strings";
import StatusBadge from "components/common/StatusBadgeV2";

const LayerChip = ({ layer, pill, onClick }) => {
  if (layer == null || layer.limit == null || layer.attachment == null) {
    return <></>;
  }

  const tooltipText = (layer) => {
    const items = [
      layer.reference,
      layer.businessUnitName,
      layer.paperProviderName,
    ].filter((item) => item != null);

    if (items.length === 0) {
      return null;
    } else if (items.length === 1) {
      return items[0];
    } else {
      return items.join(" • ");
    }
  };

  const tooltip = tooltipText(layer);
  const avatar = pill.statusBadge ? (
    <StatusBadge status={layer.status} variant={"icon"} />
  ) : null;

  const chip = (
    <Chip
      avatar={avatar}
      label={layer?.[pill.path]}
      size={"small"}
      variant={pill?.variant ?? "outlined"}
      color={pill?.color}
      onClick={(e) => {
        e.preventDefault();
        onClick(`${pill.prefix ?? pill.path}:"${layer?.[pill.path]}"`);
      }}
    />
  );

  return tooltip == null ? chip : <Tooltip title={tooltip}>{chip}</Tooltip>;
};

const FilterChip = ({ label, pill, onClick }) => {
  const TooltipWraper = ({ children }) =>
    pill?.tooltip ? (
      <Tooltip title={pill?.tooltip}>{children}</Tooltip>
    ) : (
      <>{children}</>
    );
  return !hasValue(label) || label === "-" ? (
    <></>
  ) : (
    <TooltipWraper>
      <Chip
        label={label}
        size={"small"}
        variant={pill?.variant ?? "outlined"}
        color={pill?.color}
        clickable
        onClick={(e) => {
          e.preventDefault();
          onClick(`${pill.prefix ?? pill.path}:"${label}"`);
        }}
      />
    </TooltipWraper>
  );
};

const PlaceholderChip = () => (
  <Stack mt={2}>
    <Chip
      size={"small"}
      className={"pulse"}
      sx={{ width: "6rem", background: "rgba(0, 0, 0, 0.11)" }}
    />
  </Stack>
);

const Meta = ({ submission, searchConfig, isLoading, filterClicked }) => {
  if (!hasItems(submission.layers) && !hasItems(submission.people)) {
    return <></>;
  } else if (isLoading) {
    return <PlaceholderChip />;
  }

  const numberLayers = submission.layers.filter(
    (layer) => layer.limit != null && layer.attachment != null
  ).length;
  const numberPeople = submission.people?.people?.length ?? 0;

  // We want to display people even if no layers have been set but
  // we don't want to show the current year tag for new submissions
  // which have yet to be filled in (avoids needless clutter)
  if (numberLayers === 0 && numberPeople === 0) {
    return <></>;
  }

  return (
    <Stack
      className={"fade-in"}
      direction={"row"}
      flexWrap={"wrap"}
      gap={0.75}
      mt={2}
    >
      {(searchConfig?.pills || []).map((pill) => {
        if (pill.byLayer) {
          return submission.layers.map((layer) => (
            <LayerChip
              key={layer.id}
              layer={layer}
              pill={pill}
              onClick={filterClicked}
            />
          ));
        } else {
          return (
            <FilterChip
              key={pill.path}
              onClick={filterClicked}
              label={submission?.[pill.path]?.toString()}
              pill={pill}
            />
          );
        }
      })}
    </Stack>
  );
};

const SearchCard = ({
  submission,
  searchConfig,
  configLoading,
  filterClicked,
  ...props
}) => {
  return (
    <Link
      href={"/submissions/" + submission.submissionId}
      target={"_self"}
      underline={"none"}
      {...props}
    >
      <Card
        elevation={0}
        sx={{
          marginBottom: "8px",
          cursor: "pointer",
          "&:hover": {
            boxShadow: "0px 4px 16px rgba(36, 41, 48, 0.1)",
          },
        }}
      >
        <CardContent sx={{ padding: "8px 14px 14px 14px !important" }}>
          <Stack
            direction={"row"}
            alignItems={"baseline"}
            gap={0.5}
            sx={{ width: "100%" }}
          >
            <Stack sx={{ width: "100%" }}>
              <Stack
                direction={"row"}
                alignItems={"baseline"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
              >
                <Stack>
                  <Typography variant={"subtitle1"}>
                    {submission.insured}
                  </Typography>
                  {!submission.industryName ? (
                    <Skeleton sx={{ fontSize: "14.5px" }} />
                  ) : (
                    <Typography
                      className={"fade-in"}
                      variant={"subtitle2"}
                      mt={-0.5}
                      color={"grey"}
                    >
                      {submission.industryName}
                    </Typography>
                  )}
                </Stack>
                <Typography variant={"caption"} flexShrink={0} ml={1}>
                  {(submission.reference || "") === "" ? (
                    ""
                  ) : (
                    <span className={"hidden-mobile"}>
                      {submission.reference + " • "}
                    </span>
                  )}
                  {submission.inceptionName}
                </Typography>
              </Stack>
              {submission.riskDescription && (
                <Typography
                  variant={"body2"}
                  marginTop={0.5}
                  sx={{
                    display: "-webkit-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {submission.riskDescription}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Meta
            submission={submission}
            isLoading={configLoading}
            searchConfig={searchConfig}
            filterClicked={filterClicked}
          />
        </CardContent>
      </Card>
    </Link>
  );
};

export default SearchCard;
