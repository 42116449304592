import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const SignOutButton = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      className={"sign-out"}
      {...props}
      onClick={(e) => {
        navigate("/logout");
        e.preventDefault();
      }}
    >
      {"Sign Out"}
    </Button>
  );
};

export default SignOutButton;
