import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Stack, Typography } from "@mui/material";
import { makeNewId } from "common/ids";
import Component from "components/Component";
import IconButton from "components/common/IconButton";
import { useState } from "react";

const GUIDGenerator = () => {
  const [guid, setGuid] = useState(makeNewId());

  const copyGuid = () => {
    navigator.clipboard.writeText(guid);
  };
  const refreshGuid = () => {
    setGuid(makeNewId());
    copyGuid();
  };

  return (
    <Component
      title={"GUID Generator"}
      options={
        <Stack flexDirection={"row"} gap={2} alignItems={"center"}>
          <Box style={{ flexGrow: "1" }} />
          <Typography
            sx={{ fontFamily: "monospace", color: "lightslategray !important" }}
            textAlign={"center"}
          >
            {guid}
          </Typography>
          <IconButton
            scale={"small"}
            icon={RefreshIcon}
            edge={"start"}
            onClick={refreshGuid}
          />
          <IconButton
            scale={"small"}
            icon={ContentCopyIcon}
            edge={"start"}
            onClick={copyGuid}
          />
        </Stack>
      }
    />
  );
};

export default GUIDGenerator;
