import { FILTER_MODE, DATE_MODE, SORT_ORDER } from "./constants";
import {
  FilterListRounded as FiltersIcon,
  Check as SelectedIcon,
  SettingsRounded as SettingsIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Fade,
  IconButton as MuiIconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "components/common/IconButton";
import InlineSearch from "components/common/InlineSearch";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

const FiltersMenu = ({
  anchorEl,
  closeMenu,
  activeFilter,
  changeFilter,
  activeDateMode,
  changeDateMode,
}) => {
  const filters = [
    { key: FILTER_MODE.EVERYTHING, label: "Everything" },
    { key: FILTER_MODE.SAVED_BY_ME, label: "Saved by Me" },
    { key: FILTER_MODE.RENEWING_SOON, label: "Renewing Soon" },
  ];

  const dateModes = [
    { key: DATE_MODE.EVERYTHING, label: "All Submissions" },
    { key: DATE_MODE.LATEST, label: "Latest by Insured" },
    { key: DATE_MODE.THIS_YEAR, label: "The Current Year" },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={(_, reason) => {
        if (reason === "backdropClick") closeMenu();
      }}
    >
      <MenuItem disabled>
        <Typography variant={"caption"}>{"Show"}</Typography>
      </MenuItem>

      {filters.map((filter) => (
        <MenuItem onClick={() => changeFilter(filter.key)} key={filter.key}>
          {filter.key === activeFilter && (
            <ListItemIcon>
              <SelectedIcon fontSize={"small"} />
            </ListItemIcon>
          )}
          <ListItemText inset={filter.key !== activeFilter}>
            {filter.label}
          </ListItemText>
        </MenuItem>
      ))}

      <Divider />

      <MenuItem disabled>
        <Typography variant={"caption"}>{"Across"}</Typography>
      </MenuItem>

      {dateModes.map((dateMode) => (
        <MenuItem
          onClick={() => changeDateMode(dateMode.key)}
          key={dateMode.key}
        >
          {dateMode.key === activeDateMode && (
            <ListItemIcon>
              <SelectedIcon fontSize={"small"} />
            </ListItemIcon>
          )}
          <ListItemText inset={dateMode.key !== activeDateMode}>
            {dateMode.label}
          </ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
};

const SettingsMenu = ({
  anchorEl,
  closeMenu,
  activeFilter,
  activeSort,
  changeSort,
}) => {
  const sortings = [
    {
      key: SORT_ORDER.DATE,
      label:
        activeFilter === FILTER_MODE.RENEWING_SOON
          ? "Expiration Date"
          : "Last Edited",
    },
    { key: SORT_ORDER.INSURED_NAME, label: "Insured Name" },
    { key: SORT_ORDER.EFFECTIVE_DATE, label: "Effective Date" },
  ];

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={(_, reason) => {
        if (reason === "backdropClick") closeMenu();
      }}
    >
      <MenuItem disabled>
        <Typography variant={"caption"}>{"Sort By"}</Typography>
      </MenuItem>

      {sortings.map((sorting) => (
        <MenuItem onClick={() => changeSort(sorting.key)} key={sorting.key}>
          {sorting.key === activeSort && (
            <ListItemIcon>
              <SelectedIcon fontSize={"small"} />
            </ListItemIcon>
          )}
          <ListItemText inset={sorting.key !== activeSort}>
            {sorting.label}
          </ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
};

const SearchBar = forwardRef((props, ref) => {
  const {
    activeFilter,
    activeDateMode,
    activeSort,
    isFetching,
    onChangeSearch,
    onChangeFilter,
    onChangeDateMode,
    onChangeSort,
  } = props;

  const searchRef = useRef();

  const [filtersAnchorEl, setFiltersAnchorEl] = useState(null);
  const openFiltersMenu = (e) => setFiltersAnchorEl(e.currentTarget);
  const closeFiltersMenu = () => setFiltersAnchorEl(null);
  const hasFilters =
    activeFilter !== FILTER_MODE.EVERYTHING ||
    activeDateMode !== DATE_MODE.EVERYTHING;

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const openSettingsMenu = (e) => setSettingsAnchorEl(e.currentTarget);
  const closeSettingsMenu = () => setSettingsAnchorEl(null);

  useImperativeHandle(ref, () => ({
    focus: () => searchRef.current.focus(),
    updateSearchText: (text) => searchRef.current.updateText(text),
  }));

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{
        position: "sticky",
        top: "0",
        marginTop: "0.5rem",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        background: "white",
        boxShadow: "0px 4px 16px rgba(36, 41, 48, 0.1)",
        zIndex: 99,
      }}
    >
      <InlineSearch
        ref={searchRef}
        placeholder={"Search by insured, person, status, date, reference etc."}
        onChange={onChangeSearch}
        sx={{ marginRight: "4px" }}
        isFetching={isFetching}
        fullWidth
      />

      <Tooltip title={"Additional Filters"}>
        <MuiIconButton
          onClick={openFiltersMenu}
          sx={{ position: "relative", padding: "5px" }}
        >
          <FiltersIcon />
          <Fade in={hasFilters}>
            <Box
              sx={{
                position: "absolute",
                right: "4px",
                top: "7px",
                width: "8px",
                height: "8px",
                borderRadius: "4px",
                border: "1px solid white",
                background: "#fa7b35",
              }}
            />
          </Fade>
        </MuiIconButton>
      </Tooltip>

      <FiltersMenu
        anchorEl={filtersAnchorEl}
        closeMenu={closeFiltersMenu}
        activeFilter={activeFilter}
        changeFilter={onChangeFilter}
        activeDateMode={activeDateMode}
        changeDateMode={onChangeDateMode}
      />

      <IconButton
        variant={"bright"}
        icon={SettingsIcon}
        tooltip={"Settings"}
        onClick={openSettingsMenu}
      />

      <SettingsMenu
        anchorEl={settingsAnchorEl}
        closeMenu={closeSettingsMenu}
        activeFilter={activeFilter}
        activeSort={activeSort}
        changeSort={onChangeSort}
      />
    </Stack>
  );
});

export default SearchBar;
