import ParticipationTable from "./ParticipationTable";
import TowerPrimariesTable from "./TowerPrimariesTable";
import TowerRateChart from "./TowerRateChart";
import TowerTable from "./TowerTable";
import {
  Alert,
  FormGroup,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import * as hashes from "common/hashes";
import * as logger from "common/logger";
import Component from "components/Component";
import Button from "components/common/Button";
import HiddenIfReadOnly from "components/common/HiddenIfReadOnly";
import Notes from "components/common/Notes";
import Visible from "components/common/Visible";
import SelfInsuredRetentions from "components/inputs/claims/SelfInsuredRetentions";
import * as config from "config";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as analyticsService from "services/analyticsService";
import * as towerPricingActions from "store/actions/pricing/tower/towerPricingActions";
import * as analyticsStore from "store/analytics";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as expiringProgramSelectors from "store/selectors/input/expiringProgram/expiringProgramSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as submissionSelector from "store/selectors/submission/submissionSelector";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const formatNumber = (number) => {
  if (number == null) {
    return "";
  }
  return number.toLocaleString(undefined, { maximumFractionDigits: 0 });
};

const useTriggerTowerPricingOnChange = ({
  calculateTowerPricing,
  priorHash,
  submission,
  updatingTowerPricing,
  isUsingAnalyticsBackend,
}) => {
  const selectedLayerIndex = submission?.tower?.selectedLayer ?? null;
  const towerInput = (submission?.tower?.layers ?? []).map(
    ({ towerPricing, targetPrice, ...rest }) => rest
  );

  useEffect(() => {
    if (!isUsingAnalyticsBackend || updatingTowerPricing) {
      return;
    }
    (async () => {
      const currentHash = await hashes.hashDict({
        ...towerInput,
        selectedLayerIndex,
      });
      if (currentHash !== priorHash) {
        setTimeout(() => calculateTowerPricing(currentHash), 0);
      }
    })();
  }, [
    isUsingAnalyticsBackend,
    calculateTowerPricing,
    priorHash,
    updatingTowerPricing,
    selectedLayerIndex,
    towerInput,
  ]);
};

export const TowerPricing = (props) => {
  const [isNet, setIsNet] = useState(false);

  const dispatch = useDispatch();

  const [triggerPricing] = analyticsService.useLazySubmissionAnalyticsQuery();

  const submission = useDebouncedValue(
    useSelector(submissionSelector.selectResolvedSubmissionWithoutInput)
  );

  const isUsingAnalyticsBackend =
    props.analyticsConfig?.towerPricing?.backend === "analytics";
  const engine = props.analyticsConfig?.towerPricing?.engine ?? null;

  const _calculateTowerPricing = async (currentHash) => {
    dispatch(towerPricingActions.calculateTowerPricing.started());
    try {
      const response = await triggerPricing({
        engine: engine,
        metric: "tower_pricing",
        submission,
      });
      if (response?.data) {
        dispatch(
          towerPricingActions.calculateTowerPricing.succeeded(
            response.data?.values ?? {}
          )
        );
        dispatch(
          analyticsStore.update({ towerPricing: { priorHash: currentHash } })
        );
      } else {
        logger.exception(response?.error ?? "Unknown tower pricing error");
        dispatch(towerPricingActions.calculateTowerPricing.failed());
      }
    } catch (e) {
      logger.exception(e);
      dispatch(towerPricingActions.calculateTowerPricing.failed());
    }
  };

  const calculateTowerPricing = isUsingAnalyticsBackend
    ? _calculateTowerPricing
    : props._calculateTowerPricing_old;

  const showCopyFromExpiring =
    props.layers.length === 0 &&
    props.priorSubmissionId == null &&
    props.expiringLayers.length > 0 &&
    props.expiringLayers.some((layer) => layer.hasOwnProperty("limit"));

  useTriggerTowerPricingOnChange({
    calculateTowerPricing,
    priorHash: props.priorHash,
    submission,
    updatingTowerPricing: props.updatingTowerPricing,
    isUsingAnalyticsBackend,
  });

  return (
    <>
      <Visible byTag={"pricing.towerPricing.SIR"}>
        <SelfInsuredRetentions />
      </Visible>
      <Component
        title={"Tower of Layers"}
        subtitle={"The layers that are being placed in the market."}
        options={
          <Stack direction={"row"} alignItems={"center"} gap={3}>
            {showCopyFromExpiring && (
              <Visible
                byTag={"pricing.copyFromExpiring"}
                defaultVisible={"true"}
              >
                <Button
                  onClick={() => props.copyFromExpiring(props.expiringLayers)}
                >
                  {"Copy from Expiring Program"}
                </Button>
              </Visible>
            )}
            <Visible
              byTag={"pricing.towerPricing.toggleNet"}
              defaultVisible={true}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isNet}
                      onChange={(event) => setIsNet(event.target.checked)}
                      size={"small"}
                    />
                  }
                  label={"Net"}
                  size={"small"}
                />
              </FormGroup>
            </Visible>
          </Stack>
        }
      >
        <Grid item>
          <Stack direction={"column"} spacing={config.GRID_SPACING}>
            <TowerTable isNet={isNet} />
            <Visible byTag={"pricing.primaries.primariesTower"}>
              <TowerPrimariesTable isNet={isNet} />
            </Visible>
            <Stack direction={"row"} spacing={config.GRID_SPACING}>
              <Visible byTag={"pricing.towerPricing.calculate"} defaultVisible>
                <Button
                  onClick={calculateTowerPricing}
                  isLoading={props.updatingTowerPricing}
                  isDisabled={props.isUserReadOnly === true}
                  data-testid={"calculate-tower"}
                >
                  {"Calculate"}
                </Button>
              </Visible>
              {props.towerPricingError && (
                <Visible
                  byTag={"pricing.towerPricing.calculateError"}
                  defaultVisible
                >
                  <HiddenIfReadOnly>
                    <Alert
                      severity={"error"}
                      sx={{ whiteSpace: "pre-line", width: "100%" }}
                    >
                      {props.towerPricingError}
                    </Alert>
                  </HiddenIfReadOnly>
                </Visible>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Component>
      <Notes update={props.updateNote} value={props.note} />
      <Visible byTag={"comparison.tower"} defaultVisible>
        <TowerRateChart />
      </Visible>
      <Visible byTag={"pricing.towerPricing.participation"} defaultVisible>
        <ParticipationTable />
      </Visible>
      <Visible byTag={"pricing.towerPricing.asIfLayer"} defaultVisible={true}>
        <Component
          title={"As If Layer"}
          subtitle={
            "The selected layer from Layer Pricing from which to calculate an As If RPM."
          }
          lg_width={6}
          md_width={12}
        >
          <Grid container spacing={config.GRID_SPACING}>
            <Grid item xs={4}>
              <TextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Limit"}
                variant={"filled"}
                autoComplete={"off"}
                className={"monospace"}
                value={formatNumber(props.scalingLayer.limit)}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Attachment"}
                variant={"filled"}
                autoComplete={"off"}
                className={"monospace"}
                value={formatNumber(props.scalingLayer.attachment)}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type={"text"}
                InputLabelProps={{ shrink: true }}
                label={"Required 100% Premium"}
                variant={"filled"}
                autoComplete={"off"}
                className={"monospace"}
                value={formatNumber(
                  props.scalingLayer.uwSelectedMeasure?.requiredPremium
                )}
                disabled={true}
                fullWidth
              />
            </Grid>
          </Grid>
        </Component>
      </Visible>
    </>
  );
};

export const mapDispatchToProps = {
  _calculateTowerPricing_old:
    towerPricingActions.calculateTowerPricing.requested,
  updateNote: towerPricingActions.updateTowerPricingNote,
  copyFromExpiring: towerPricingActions.copyFromExpiring,
};

export const mapStateToProps = (state) => {
  return {
    scalingLayer: pricingSelectors.getTowerPricingScalingLayer(state) ?? {},
    updatingTowerPricing: state?.pricing?.tower?.updatingTowerPricing ?? false,
    towerPricingError: state?.pricing?.tower?.error ?? null,
    note: state?.pricing?.tower?.note ?? "",
    isUserReadOnly: userSelectors.isUserReadOnly(state),
    analyticsConfig: staticDataSelectors.selectAnalyticsConfig(state),
    layers: towerPricingSelectors.getTower(state),
    expiringLayers: expiringProgramSelectors.getExpiringLayers(state),
    priorSubmissionId: programSelectors.getPriorSubmissionId(state),
    priorHash: state?.analytics?.towerPricing?.priorHash,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TowerPricing);
