import { getFlattenedFileNameOverrides } from "../../store/selectors/input/attachments/attachmentsSelectors";
import Disabled from "./Disabled";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { commands } from "@uiw/react-md-editor/nohighlight";
import * as logger from "common/logger";
import Component from "components/Component";
import IconButton from "components/common/IconButton";
import Markdown from "components/common/Markdown";
import * as commandUtils from "components/common/notes/commandUtils";
import NotesFileSelector from "components/common/notes/commands/Attachment";
import { insertAttachment } from "components/common/notes/commands/Attachment";
import { insertPhoto } from "components/common/notes/commands/Image";
import { link } from "components/common/notes/commands/Link";
import { insert2x2Table as insertTable } from "components/common/notes/commands/Table";
import * as config from "config";
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { connect, useSelector } from "react-redux";
import * as attachmentActions from "store/actions/input/attachments/attachmentsActions";
import * as actions from "store/actions/input/images/imagesActions";
import { isSelectingAttachments } from "store/selectors/temp/attachments/tempAttachmentsSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const Notes = ({
  value,
  update,
  rows,
  disabled,
  uploadImage,
  openAttachmentSelectionDialog,
  closeAttachmentSelectionDialog,
  isSelectingAttachment,
  children,
  placeholder = "Double-click to edit ...",
  title = "Notes",
  isTitleEditable = false,
  hasEditButton = true,
  growVertically,
  allowAttachments = true,
  allowImages = true,
  attachmentNames,
  onDelete,
  onEnterEditMode,
  onExitEditMode,
  ...componentProps
}) => {
  const toolbarCommands = [
    commandUtils.withTrimmedSelection(commands.bold),
    commandUtils.withTrimmedSelection(commands.italic),
    commandUtils.withTrimmedSelection(commands.strikethrough),
    commands.title,
    commands.divider,
    link,
    commands.quote,
    commands.divider,
    commands.unorderedListCommand,
    commands.orderedListCommand,
    commands.checkedListCommand,
    commands.divider,
    insertTable,
    commands.divider,
    ...(allowAttachments
      ? [insertAttachment(openAttachmentSelectionDialog)]
      : []),
    ...(allowImages ? [insertPhoto(uploadImage)] : []),
  ];

  const [internalTitle, setInternalTitle] = React.useState(title ?? "");
  const [internalValue, setInternalValue] = React.useState(value ?? "");
  const [editMode, setEditMode] = React.useState(false);

  const isUserReadOnly = useSelector(userSelectors.isUserReadOnly);

  const enterEditMode = () => {
    if (editMode) {
      return;
    }
    if (isUserReadOnly) {
      return;
    }
    setInternalTitle(title);
    setInternalValue(value);
    if (!disabled) {
      setEditMode(true);
      onEnterEditMode && onEnterEditMode();
    }
  };

  const exitEditMode = async () => {
    if (isSelectingAttachment) {
      closeAttachmentSelectionDialog();
    } else {
      if (update) {
        try {
          await update(internalValue, internalTitle);
        } catch (e) {
          logger.exception(e);
          return;
        }
      }
      setEditMode(false);
      onExitEditMode && onExitEditMode();
    }
  };

  const attachmentSelected = (meta) => {
    let viewName = meta.originalFilename;
    const params = {
      fileId: meta.id,
      mime: meta.mimeType,
    };
    const override = attachmentNames[meta.id];
    if (override) {
      params["filename"] = override;
      viewName = override;
    }

    const url = "/attachments/fileView?" + new URLSearchParams(params);
    setInternalValue(internalValue + "\n[" + viewName + "](" + url + ")\n");
    closeAttachmentSelectionDialog();
  };

  return (
    <Component
      title={editMode && isTitleEditable ? null : title}
      {...componentProps}
      options={
        <Stack direction={"row"} spacing={1}>
          {!disabled &&
            hasEditButton &&
            (editMode ? (
              <Button
                disableElevation
                variant={"contained"}
                key={"disabled"}
                sx={{ height: "36px", margin: "2px 0" }}
              >
                {isSelectingAttachment ? "Cancel" : "Done"}
              </Button>
            ) : (
              <Disabled ifReadOnly>
                <IconButton
                  variant={"bright"}
                  icon={EditIcon}
                  onClick={enterEditMode}
                />
              </Disabled>
            ))}
          {!disabled && onDelete && !editMode && (
            <IconButton
              variant={"bright"}
              icon={DeleteIcon}
              onClick={() => onDelete()}
            />
          )}
        </Stack>
      }
      onDoubleClick={enterEditMode}
      compact
      growVertically
    >
      {(editMode || value || !!children || placeholder) && (
        <Grid container spacing={config.GRID_SPACING} direction={"row"}>
          {editMode ? (
            <Grid item xs={12} data-testid={"notes-editor"}>
              <OutsideClickHandler
                onOutsideClick={exitEditMode}
                display={"contents"}
              >
                <Stack direction={"column"} spacing={1} sx={{ width: "100%" }}>
                  {isTitleEditable && (
                    <TextField
                      variant={"outlined"}
                      fullWidth
                      value={internalTitle}
                      onChange={(e) => setInternalTitle(e.target.value ?? "")}
                    />
                  )}
                  {isSelectingAttachment ? (
                    <NotesFileSelector onSelected={attachmentSelected} />
                  ) : (
                    <Markdown
                      editMode={true}
                      value={internalValue}
                      onChange={setInternalValue}
                      commands={toolbarCommands}
                    />
                  )}
                </Stack>
              </OutsideClickHandler>
            </Grid>
          ) : value ? (
            <Grid item xs={12} data-testid={"notes-view"}>
              <Markdown
                editMode={false}
                value={value}
                commands={toolbarCommands}
              />
            </Grid>
          ) : placeholder ? (
            <Grid
              item
              xs={12}
              sx={{
                color: "black",
                fontSize: "small",
                fontWeight: "bold",
                opacity: 0.2,
              }}
              data-testid={"empty-note"}
            >
              {placeholder}
            </Grid>
          ) : (
            <></>
          )}

          {!!children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      )}
    </Component>
  );
};

const mapDispatchToProps = {
  uploadImage: actions.uploadImage,
  openAttachmentSelectionDialog:
    attachmentActions.openAttachmentSelectionDialog,
  closeAttachmentSelectionDialog:
    attachmentActions.closeAttachmentSelectionDialog,
};

const mapStateToProps = (state) => ({
  isSelectingAttachment: isSelectingAttachments(state),
  attachmentNames: getFlattenedFileNameOverrides(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
