import * as actionTypes from "./claimsActionTypes";
import * as claimsActions from "./claimsActions";
import * as actions from "./claimsActions";
import { createAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import * as aws from "common/aws";
import * as hashes from "common/hashes";
import * as logger from "common/logger";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as fileUtils from "fileUtils";
import * as errorActions from "store/actions/error/errorActions";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as utils from "utils";

export const useFilterAsBaseLayer = () => (dispatch, getState) => {
  const filter = claimsSelectors.selectClaimsFilter(getState());
  dispatch({
    type: actionTypes.USE_FILTER_AS_BASE_LAYER,
    payload: filter,
  });
  dispatch(pricingActions.recalculateInsuredExpectedEvents());
};

export const setV2ClaimsKey = (
  claimsKey,
  fileId,
  tableName,
  uploadedFilename,
  tablesList
) => ({
  type: actionTypes.SET_V2_CLAIMS_KEY,
  payload: { claimsKey, fileId, tableName, uploadedFilename, tablesList },
});

export const updateSelfInsuredRetention = (retention, index) => ({
  type: actionTypes.UPDATE_SELF_INSURED_RETENTION,
  payload: {
    retention,
    index,
  },
});

export const deleteSelfInsuredRetention = (index) => ({
  type: actionTypes.DELETE_SELF_INSURED_RETENTION,
  payload: { index },
});

export const updateClaimsFilter = (filter, value) => ({
  type: actionTypes.UPDATE_CLAIMS_FILTER,
  payload: { filter, value },
});

export const clearClaimsFilter = () => ({
  type: actionTypes.CLEAR_CLAIMS_FILTER,
});

export const updateClaimThreshold = (threshold) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_CLAIM_THRESHOLD,
    payload: threshold,
  });
};

export const fileUploading = (filename) => ({
  type: actionTypes.CLAIMS_FILE_DROPPED,
  payload: filename,
});

export const sheetsReturned = (filename, sheets) => ({
  type: actionTypes.SHEET_NAMES_RETURNED,
  payload: { filename, sheets },
});

export const fileSheetsReturned = (id, sheets) => ({
  type: actionTypes.FILE_SHEET_NAMES_RETURNED,
  payload: { id, sheets },
});

export const nextStep = () => ({
  type: actionTypes.CLAIMS_DIALOG_NEXT_STEP,
});

export const prevStep = () => ({
  type: actionTypes.CLAIMS_DIALOG_PREV_STEP,
});

export const sheetTopLeftSelected = ({ row, column }) => ({
  type: actionTypes.ACTIVE_SHEET_TOP_LEFT_CHANGED,
  payload: {
    row,
    column,
  },
});

export const filesSheetSelected = (fileId, sheet) => ({
  type: actionTypes.FILE_ACTIVE_SHEET_CHANGED,
  payload: {
    fileId,
    sheet,
  },
});

export const sheetSelected = (filename, sheet) => ({
  type: actionTypes.ACTIVE_SHEET_CHANGED,
  payload: {
    filename,
    sheet,
  },
});

const updateSheets = (filename, dispatch) => {
  utils
    .authenticatedFetch(sheetsUrl(filename))
    .then((result) => result.json())
    .then((data) => {
      dispatch(actions.sheetsReturned(filename, data.sheets));
      if (data && data.sheets && data.sheets.length > 0) {
        dispatch(sheetSelected(filename, 0));
      }
    })
    .catch((error) => {
      logger.exception(error);
      dispatch(
        claimsActions.mapClaimsFileFailed(
          "Failed to extract columns from input file. Please either convert the " +
            `file to a .csv, try another file, or contact ${config.SUPPORT_EMAIL}.`
        )
      );
    });
};

const updateFileSheets = async (id, filename, dispatch) => {
  try {
    const tableList = await fileUtils.getTableList(id);
    dispatch(fileSheetsReturned(id, tableList));
    dispatch(filesSheetSelected(id, 0));
  } catch (error) {
    logger.exception(error);
    dispatch(
      claimsActions.mapClaimsFileFailed(
        `Failed to extract columns from file (${filename}) sheet. Please either convert` +
          ` the file to a .csv, try another file, or contact ${config.SUPPORT_EMAIL}.`
      )
    );
  }
};

export const mapClaimsFileFailed = (error) => ({
  type: actionTypes.CLAIMS_FILE_MAPPING_FAILED,
  payload: {
    error,
  },
});

export const editClaimsFile = (filename) => (dispatch) => {
  dispatch({
    type: actionTypes.EDIT_CLAIMS_FILE,
    payload: filename,
  });
  updateSheets(filename, dispatch);
};

export const columnsReturned = (data) => ({
  type: actionTypes.COLUMN_NAMES_RETURNED,
  payload: data,
});

export const claimsDialogClosed = () => ({
  type: actionTypes.CLAIMS_DIALOG_CLOSED,
});

export const transformClaimsFile = {
  requested: createAction("activeClaimsFile/transform"),
  started: createAction("activeClaimsFile/transform/started"),
  succeeded: createAction(
    "activeClaimsFile/transform/succeeded",
    ({
      claimsKey,
      lastMappedLossDate,
      lastLossDate,
      firstMappedLossDate,
      firstLossDate,
    }) => {
      return {
        payload: {
          claimsKey,
          lastMappedLossDate,
          lastLossDate,
          firstMappedLossDate,
          firstLossDate,
        },
      };
    }
  ),
  failed: createAction(
    "activeClaimsFile/transform/failed",
    ({ errorMessage }) => {
      return {
        payload: {
          errorMessage,
        },
      };
    }
  ),
};

export const updateTotalLossNumberFormat = createAction(
  "activeClaimsFile/updateTotalLossNumberFormat",
  (numberFormat) => {
    return {
      payload: numberFormat,
    };
  }
);

const columnMapperActions = (target) => ({
  candidatesRequested: createAction(
    `claims/mapping/columns/${target}/candidatesRequested`,
    (column, selected) => ({
      payload: {
        target,
        column,
        selected,
      },
    })
  ),
  candidatesInvalidated: createAction(
    `claims/mapping/columns/${target}/candidatesInvalidated`
  ),
  candidatesReturned: createAction(
    `claims/mapping/columns/${target}/candidatesReturned`,
    (candidates, totalCount) => ({
      payload: {
        target,
        candidates,
        totalCount,
      },
    })
  ),
  candidatesWaiting: createAction(
    `claims/mapping/columns/${target}/candidatesWaiting`
  ),
});

export const mapping = {
  updated: createAction(
    "claims/mapping/updated",
    (column, target, selected) => ({
      payload: {
        target,
        column,
        selected,
      },
    })
  ),
  columns: {
    [config.LOSS_TYPE_KEY]: columnMapperActions(config.LOSS_TYPE_KEY),
    [config.OPEN_CLOSED]: columnMapperActions(config.OPEN_CLOSED),
    [config.EXCLUDE_KEY]: columnMapperActions(config.EXCLUDE_KEY),
  },
};

export const sheetsUrl = (filename) => {
  return config.endpoints.claims(
    "excel/sheets?" + new URLSearchParams({ filename })
  );
};

export const fileUploaded = (filename, claimsKey) => (dispatch) => {
  dispatch({
    type: actionTypes.CLAIMS_FILE_UPLOADED,
    payload: {
      filename: filename,
      claimsKey: claimsKey,
    },
  });
  updateSheets(filename, dispatch);
};

export const filesFileUploaded = (id, filename) => (dispatch) => {
  dispatch({
    type: actionTypes.FILE_CLAIMS_FILE_UPLOADED,
    payload: {
      filename,
      id,
    },
  });
  updateFileSheets(id, filename, dispatch);
};

export const fileUploadFailed = (filename, error) => (dispatch) => {
  Sentry.addBreadcrumb({
    message: `Uploading file failed: ${filename}`,
  });
  dispatch(
    errorActions.handleError(error, {
      title: "File Upload Failed",
      message:
        error?.description ||
        `File upload failed please contact ${config.SUPPORT_EMAIL}.`,
      type: actionTypes.CLAIMS_FILE_UPLOAD_FAILED,
    })
  );
  dispatch({
    type: actionTypes.CLAIMS_FILE_UPLOAD_FAILED,
  });
};

export const uploadUrl = ({ extension, base64Sha256 }) => {
  const params = { extension };
  if (!!base64Sha256) {
    params["base64Sha256"] = base64Sha256;
  }
  return config.endpoints.claims("upload?" + new URLSearchParams(params));
};

export const uploadFile = (file) => async (dispatch) => {
  dispatch(actions.fileUploading(file.name));

  let base64Sha256 = null;
  let uploadLocationUrl = null;
  try {
    base64Sha256 = await hashes.hashFile(file, {
      algorithm: "SHA-256",
      format: "base64",
    });

    const extension = /(?:\.([^.]+))?$/.exec(file.name)[1];
    uploadLocationUrl = uploadUrl({
      extension,
      base64Sha256,
    });
    const uploadLocationResponse = await utils.authenticatedFetch(
      uploadLocationUrl
    );
    const uploadLocationData = await uploadLocationResponse.json();

    await aws.uploadFile(file, uploadLocationData.aws);
    dispatch(
      actions.fileUploaded(
        uploadLocationData.filename,
        uploadLocationData.claimsKey
      )
    );
  } catch (error) {
    logger.exception(error, {
      breadcrumb: { file, base64Sha256, uploadLocationUrl },
    });
    dispatch(actions.fileUploadFailed(file.name, error));
  }
};

export const excludedMappingUpdated = (source, target, selected) => ({
  type: actionTypes.EXCLUDED_MAPPING_UPDATED,
  payload: { source: source, target: target, selected: selected },
});

export const lossTypeMappingUpdated = (source, target, selected) => ({
  type: actionTypes.LOSS_TYPE_MAPPING_UPDATED,
  payload: { source: source, target: target, selected: selected },
});

export const openClosedMappingUpdated = (source, target, selected) => ({
  type: actionTypes.OPEN_CLOSED_MAPPING_UPDATED,
  payload: { source: source, target: target, selected: selected },
});

export const setClaimsMapping = (claimsMapping) => ({
  type: actionTypes.SET_CLAIMS_MAPPING,
  payload: claimsMapping,
});

export const updateClaimsAsAtDate = (asAtDate) => ({
  type: actionTypes.UPDATE_CLAIMS_AS_AT_DATE,
  payload: asAtDate,
});

export const saveFileAs = (blob, name) => {
  return FileSaver.saveAs(blob, name);
};

export const downloadOriginalFromFiles = (id, name, key) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_STARTED,
    payload: key,
  });
  fileUtils
    .saveFile(id, name)
    .then(() => {
      dispatch({
        type: actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_RETURNED,
        payload: key,
      });
    })
    .catch((error) => {
      dispatch(actions.downloadOriginalError(error, key));
    });
};

export const downloadOriginal = (uploadedFilename, file, key) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_STARTED,
    payload: key,
  });
  utils
    .authenticatedFetch(
      config.endpoints.claims(
        "download/original?" + new URLSearchParams({ uploadedFilename })
      )
    )
    .then((response) => response.json())
    .then((json) => fetch(json.aws))
    .then((response) => response.blob())
    .then((blob) => actions.saveFileAs(blob, file))
    .then(() => {
      dispatch({
        type: actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_RETURNED,
        payload: key,
      });
    })
    .catch((e) => dispatch(actions.downloadOriginalError(e, key)));
};

export const downloadOriginalError = (e, key) => (dispatch) => {
  dispatch({
    type: actionTypes.DOWNLOAD_ORIGINAL_CLAIMS_DATA_FAILED,
    payload: key,
  });
  logger.exception(e);
};

export const claimsFileChecked = (key, checked) => ({
  type: actionTypes.CLAIMS_FILE_CHECKED,
  payload: {
    key,
    checked,
  },
});

export const updateClaimsNote = (note) => ({
  type: actionTypes.UPDATE_CLAIMS_NOTE,
  payload: note,
});

export const createManualClaim = (claim) => ({
  type: actionTypes.MANUAL_CREATE_MERGE_ENTRY,
  payload: claim,
});

export const handleChangeLossYear = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_LOSS_YEAR,
  payload: {
    lossYear: value,
    index,
  },
});

export const deleteManualClaims = (index) => ({
  type: actionTypes.DELETE_MANUAL_CLAIMS,
  payload: {
    index,
  },
});

export const handleChangeCoverage = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_COVERAGE,
  payload: {
    coverage: value,
    index,
  },
});

export const handleChangeLoss = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_LOSS,
  payload: {
    lossAmount: value,
    index,
  },
});

export const handleChangeDescription = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_DESCRIPTION,
  payload: {
    description: value,
    index,
  },
});
export const handleChangeClaimant = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_CLAIMANT,
  payload: {
    claimant: value,
    index,
  },
});
export const handleChangeOC = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_OPEN_CLOSED,
  payload: {
    oc: value,
    index,
  },
});

export const handleChangeDateClosed = (value, index) => ({
  type: actionTypes.MANUAL_UPDATE_DATE_CLOSED,
  payload: {
    dateClosed: value,
    index,
  },
});

export const toggleClaimExclude = (key, index, excluded) => ({
  type: actionTypes.TOGGLE_CLAIM_EXCLUSION,
  payload: {
    key,
    index,
    excluded,
  },
});

export const updateClaimOverride = (key, index, value) => ({
  type: actionTypes.OVERIDE_CLAIM_VALUE,
  payload: {
    key,
    index,
    value,
  },
});

export const setClaimOpenClosedOverride = (key, index, value) => ({
  type: actionTypes.SET_CLAIM_OPEN_CLOSED_OVERRIDE,
  payload: {
    key,
    index,
    value,
  },
});

export const removeClaimOpenClosedOverride = (key, index) => ({
  type: actionTypes.REMOVE_CLAIM_OPEN_CLOSED_OVERRIDE,
  payload: {
    key,
    index,
  },
});

export const addLossYear = (year) => (dispatch) => {
  dispatch({
    type: actionTypes.CLAIMS_TABLE_ADD_LOSS_YEAR,
    payload: year,
  });
};

export const removeLossYear = (year) => (dispatch) => {
  dispatch({
    type: actionTypes.CLAIMS_TABLE_REMOVE_LOSS_YEAR,
    payload: year,
  });
};

export const hideClaimsFile = (key) => ({
  type: actionTypes.HIDE_CLAIMS_FILE,
  payload: key,
});

export const updateChartZoom = (name, zoom) => ({
  type: actionTypes.UPDATE_CLAIMS_ZOOM,
  payload: {
    name,
    zoom,
  },
});

export const clearChartZoom = (name) => ({
  type: actionTypes.CLEAR_CHART_ZOOM,
  payload: name,
});

export const setInflationModel = (inflationModel) => ({
  type: actionTypes.SET_INFLATION_MODEL,
  payload: inflationModel,
});

export const showClaimsFileColumnMappingDisplay = (claimsKey) => (dispatch) => {
  const claimsMapping = claimsSelectors.selectClaimsFileColumnMappingDisplay();
  if (claimsMapping.claimsKey !== claimsKey || claimsMapping.state === "IDLE") {
    dispatch({
      type: actionTypes.SHOW_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY,
      payload: claimsKey,
    });
    dispatch({
      type: actionTypes.START_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD,
    });
  }
};

export const hideClaimsFileColumnMappingDisplay = () => (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY,
    payload: null,
  });
};

export const recievedClaimsFileColumnMappingDisplayData = (meta_data) => (
  dispatch
) => {
  const claimsMapping = claimsSelectors.selectClaimsFileColumnMappingDisplay();
  if (claimsMapping.claimsKey === meta_data["claims-key"]) {
    dispatch({
      type: actionTypes.CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOADED,
      payload: meta_data,
    });
  }
};
