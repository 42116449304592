import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import DefaultLoader from "components/common/DefaultLoader";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const LOADING_KEY = "__LOADING__";
const NONE_KEY = "__NONE__";

const PolicyTypeSelector = ({
  choices,
  value,
  onChange,
  isLoading,
  disabled,
}) => {
  return (
    <FormControl variant={"filled"} fullWidth>
      <InputLabel className={"input-label"}>{"Policy Type"}</InputLabel>
      <Select
        value={isLoading ? LOADING_KEY : !!value ? value : NONE_KEY}
        onChange={(event) => {
          const value = event?.target?.value ?? NONE_KEY;
          if (!value || value === NONE_KEY) {
            onChange(null);
          } else if (value !== LOADING_KEY) {
            onChange(value);
          }
        }}
        disabled={isLoading || disabled}
        fullWidth
      >
        {isLoading && (
          <MenuItem value={LOADING_KEY}>
            <DefaultLoader
              color={"#dc7f4c"}
              size={16}
              style={{
                display: "block",
                textAlign: "center",
              }}
            />
          </MenuItem>
        )}
        <MenuItem value={NONE_KEY}>{"None"}</MenuItem>
        {(choices ?? []).map(({ key, name }) => (
          <MenuItem key={key} value={key}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const PolicyType = ({ disabled }) => {
  const dispatch = useDispatch();

  const {
    data: policyTypeConfig,
    isLoading,
  } = staticDataService.useConfigQuery("policy_types");

  const layer = useSelector(pricingSelectors.selectResolvedActiveLayer);

  const selectedPolicyTypeChoice =
    policyTypeConfig?.choices?.filter(
      (choice) => choice.key === layer?.policyType
    )?.[0] ?? null;

  return (
    <>
      <Grid item xs={12}>
        <PolicyTypeSelector
          choices={policyTypeConfig?.choices}
          value={layer?.policyType}
          onChange={(value) => {
            if (value !== layer?.policyType) {
              dispatch(
                pricingActions.updateLayer({
                  layerId: layer.id,
                  values: {
                    policyType: value,
                    retroactiveDate: null,
                  },
                })
              );
            }
          }}
          isLoading={isLoading}
          disabled={disabled || !policyTypeConfig?.choices?.length}
        />
      </Grid>
      {selectedPolicyTypeChoice?.hasRetroactiveDate && (
        <Grid item xs={12} key={layer?.policyType}>
          <FormattedDatePicker
            label={"Retroactive Date"}
            value={layer?.retroactiveDate ?? ""}
            onChangeDate={(date) =>
              dispatch(
                pricingActions.updateLayer({
                  layerId: layer.id,
                  values: { retroactiveDate: date },
                })
              )
            }
            disabled={disabled}
          />
        </Grid>
      )}
    </>
  );
};

export default PolicyType;
