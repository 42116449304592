import { Alert } from "@mui/material";
import Mapper from "components/common/Mapper";
import { connect } from "react-redux";
import { excludedMappingUpdated } from "store/actions/input/claims/claimsActions";

const ExcludeMapping = (props) => {
  const excludedItems = Object.entries(props.mapping ?? [])
    .filter(([_, v]) => v === "_EXCLUDE")
    .map(([k, _]) => k);
  const areAllRowsExcluded =
    props.sources?.every((item) => excludedItems.includes(item)) ?? false;
  return (
    <>
      <Mapper {...props} />
      {areAllRowsExcluded && (
        <Alert severity={"error"} sx={{ marginTop: 2 }}>
          {
            "You have selected everything. This will exclude all rows and so fail to load. Make sure to select only the values corresponding to the rows that you want to exclude."
          }
        </Alert>
      )}
    </>
  );
};

const mapDispatchToProps = {
  mappingUpdated: excludedMappingUpdated,
};

const mapStateToProps = (state) => ({
  targets: [{ display: "Exclude", key: "_EXCLUDE" }],
  sources: state.input.claims.activeFile?.excluded,
  sourcesMeta: state.input.claims.activeFile?.excludedMeta,
  mapping: state.input.claims.activeFile?.excludedMapping,
  isTransposed: true,
  maxHeight: "50vh",
});

export default connect(mapStateToProps, mapDispatchToProps)(ExcludeMapping);
