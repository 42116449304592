import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as staticDataService from "services/staticDataService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as metaSelectors from "store/selectors/meta/metaSelectors";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

export const useIsSnapshot = () => {
  const location = useLocation();
  return location?.pathname?.includes("/version/");
};

export const useLoadSubmission = (id, { isLoadable, source, versionId }) => {
  const dispatch = useDispatch();

  const submissionState = useSelector((state) => state?.persistence?.state);
  const submissionError = useSelector((state) => state?.persistence?.error);

  const [isLoading, setIsLoading] = useState(false);

  const stateSubmissionId = useSelector(
    persistenceSelectors.selectSubmissionId
  );

  const stateSubmissionTransferId = useSelector(
    (state) => metaSelectors.selectOverlay(state)?.submissionTransferId ?? null
  );

  const {
    data: featureFlags,
    isLoading: isFeatureFlagsLoading,
  } = staticDataService.useFeatureFlagsQuery();

  const stateId =
    source === "transfers" ? stateSubmissionTransferId : stateSubmissionId;

  useEffect(() => {
    const hasSubmissionChanged = id !== stateId;
    if (isLoadable && hasSubmissionChanged) {
      if (source === "transfers") {
        setIsLoading(true);
        if (!isFeatureFlagsLoading) {
          dispatch(
            persistenceActions.loadTransferredSubmission(id, {
              asTransferred: true,
              useTransferService:
                featureFlags?.["ui/useTransferService"] ?? true,
            })
          );
          setIsLoading(false);
        }
      } else if (!!versionId) {
        dispatch(
          persistenceActions.loadExistingSubmissionVersion(id, versionId)
        );
      } else {
        dispatch(persistenceActions.loadExistingSubmission(id));
      }
    }
  }, [
    dispatch,
    source,
    isLoadable,
    id,
    versionId,
    stateId,
    featureFlags,
    isFeatureFlagsLoading,
  ]);

  return {
    isLoading: isLoading || submissionState === "LOADING",
    error: submissionError,
  };
};

export const useRedirectToSavedSubmission = (submissionId, { rootPath }) => {
  const stateSubmissionId = useSelector(
    persistenceSelectors.selectSubmissionId
  );

  const navigate = useNavigate();

  const isNewSubmission = useSelector(persistenceSelectors.selectIsNew);

  useEffect(() => {
    if (!isNewSubmission && stateSubmissionId && submissionId === "new") {
      navigate(`${rootPath}/${stateSubmissionId}`);
    }
  }, [submissionId, stateSubmissionId, navigate, rootPath, isNewSubmission]);
};
