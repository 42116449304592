import Grid from "@mui/material/Grid";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import Dashboard from "components/layout/Dashboard";
import DashboardContent from "components/layout/DashboardContent";
import ReportDownload from "components/reports/ReportDownload";
import * as staticDataService from "services/staticDataService";

const ReportApp = () => {
  const {
    data: config,
    isLoading: isConfigLoading,
  } = staticDataService.useConfigQuery("reports");

  return (
    <div className={"app"}>
      <Dashboard>
        <DashboardContent isFullWidth={false}>
          <Grid item container xs={12}>
            <Component title={"Reports"}>
              {isConfigLoading ? (
                <DefaultLoader
                  color={"#dc7f4c"}
                  style={{
                    width: "200px",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  size={60}
                />
              ) : (
                (config?.reportDefinitions ?? []).map((reportDefinition) => (
                  <Grid
                    key={reportDefinition.name}
                    item
                    xs={12}
                    sx={{ margin: 3 }}
                  >
                    <ReportDownload reportDefinition={reportDefinition} />
                  </Grid>
                ))
              )}
            </Component>
          </Grid>
        </DashboardContent>
      </Dashboard>
    </div>
  );
};

export default ReportApp;
