import { Button, Stack, Typography } from "@mui/material";
import { onlyContainsEmoji } from "common/strings";
import SmallLoader from "components/common/SmallLoader";
import { useState } from "react";

const InlineDelete = ({ comment }) => {
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const emojiText = comment.text.length < 20 && onlyContainsEmoji(comment.text);
  const contentSx = emojiText
    ? { fontSize: "32px" }
    : { marginBottom: failed ? "0" : "12px", whiteSpace: "pre-line" };
  const buttonSx = { flexShrink: 0, alignSelf: "flex-start" };

  const deleteComment = () => {
    // Call backend via RTK, probably obviates need for loading/failed state
    setFailed(false);
    setLoading(false);
  };

  return (
    <Stack>
      <Stack flexDirection={"row"} gap={1}>
        <Typography
          marginLeft={5}
          sx={{ ...contentSx, opacity: loading ? 0.25 : null }}
          flexGrow={1}
        >
          {comment.text}
        </Typography>
        <Button
          size={"small"}
          color={"error"}
          variant={"contained"}
          sx={{ ...buttonSx, minWidth: "8.5rem" }}
          disableElevation
          disabled={loading}
          onClick={(_) => deleteComment()}
        >
          {loading ? <SmallLoader /> : <span>{"Delete Forever"}</span>}
        </Button>
        <Button
          size={"small"}
          color={"secondary"}
          sx={{ ...buttonSx, color: "grey" }}
          variant={"outlined"}
          disableElevation
          disabled={loading}
        >
          {"Cancel"}
        </Button>
      </Stack>
      {!loading && failed && (
        <Typography
          variant={"caption"}
          color={"error"}
          marginLeft={5}
          marginBottom={1}
        >
          {
            "There was a problem saving changes to Marmalade, please try again in a moment"
          }
        </Typography>
      )}
    </Stack>
  );
};

export default InlineDelete;
