import { useAdminUpdateNavigationConfigMutation } from "../../../services/adminService";
import * as adminActions from "../../../store/actions/temp/admin/adminActions";
import TextField from "../../common/TextField";
import { IconClose } from "@aws-amplify/ui-react/internal";
import DragIcon from "@mui/icons-material/DragIndicator";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Grid, Button, Paper, Stack, Typography } from "@mui/material";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useAdminRetrieveNavigationConfigQuery } from "services/staticDataService";
import * as adminSelectors from "store/selectors/temp/admin/adminSelectors";

const DragDropList = ({ items, onChange }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const dragging = activeIndex != null;

  const clone = (obj) => JSON.parse(JSON.stringify(obj));

  const grab = (index) => {
    if (dragging) return;
    setActiveIndex(index);
  };

  const displace = (index) => {
    if (!dragging || index === activeIndex) return;
    const activeItem = items[activeIndex];
    const updatedItems = clone(items);
    updatedItems.splice(activeIndex, 1);
    updatedItems.splice(index, 0, activeItem);
    setActiveIndex(index);
    if (typeof onChange === "function") {
      onChange(updatedItems);
    }
  };

  const drop = () => {
    setActiveIndex(null);
  };

  const itemStyle = (isActive) => {
    return {
      padding: 2,
      userSelect: "none",
      cursor: isActive ? "grabbing" : "grab",
      transform: isActive ? "scale(0.98)" : null,
      boxShadow: isActive
        ? "0 0 0 2px inset darkorange, 0px 4px 12px rgba(0, 0, 0, 0.1)"
        : null,
      background: isActive ? "#fff1e9" : null,
      transition: "150ms",
      "&:hover": {
        boxShadow: "0 0 0 2px inset darkorange",
      },
    };
  };

  return (
    <>
      <Stack spacing={0.5} onMouseUp={() => drop()} onMouseLeave={() => drop()}>
        {items.map((item, index) => {
          return (
            <Paper
              key={"DRAG_DROP_LIST_" + index}
              elevation={index === activeIndex ? 2 : 0}
              sx={itemStyle(index === activeIndex)}
              onMouseDown={(e) => grab(index)}
              onMouseEnter={(e) => displace(index)}
            >
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"subtitle2"}>{item}</Typography>
                <DragIcon
                  sx={{
                    color: index === activeIndex ? "darkorange" : "silver",
                  }}
                />
              </Stack>
            </Paper>
          );
        })}
      </Stack>
    </>
  );
};

const NavigationConfigOverlay = ({
  userLogMessage,
  saveUserLogMessage,
  hasUserLogMessage,
}) => {
  const { companyId } = useParams();

  const {
    enqueueSnackbar: queueMessage,
    closeSnackbar: closeMessage,
  } = useSnackbar();
  const addMessage = (variant, message) => {
    queueMessage(message, {
      variant,
      autoHideDuration: 30_000,
      action: (msgId) => (
        <IconButton onClick={() => closeMessage(msgId)} icon={IconClose} />
      ),
    });
  };
  const { data, isFetching } = useAdminRetrieveNavigationConfigQuery(
    { companyId },
    { skip: companyId == null }
  );
  const [newValue, setNewValue] = useState();
  const [saving, setSaving] = useState(false);
  const [save] = useAdminUpdateNavigationConfigMutation();
  useEffect(() => {
    setNewValue(data);
  }, [data]);
  const moveRight = (tabIndex) => {
    let newTabOrder = [...newValue.tabOrder];
    const temp = newTabOrder[(tabIndex + 1) % newTabOrder.length];
    newTabOrder[(tabIndex + 1) % newTabOrder.length] = newTabOrder[tabIndex];
    newTabOrder[tabIndex] = temp;
    setNewValue({
      ...newValue,
      tabOrder: newTabOrder,
    });
  };
  const moveLeft = (tabIndex) => {
    let newTabOrder = [...newValue.tabOrder];
    const temp =
      newTabOrder[tabIndex - 1 < 0 ? newTabOrder.length - 1 : tabIndex - 1];
    newTabOrder[tabIndex - 1 < 0 ? newTabOrder.length - 1 : tabIndex - 1] =
      newTabOrder[tabIndex];
    newTabOrder[tabIndex] = temp;
    setNewValue({
      ...newValue,
      tabOrder: newTabOrder,
    });
  };
  return (
    <Component title={"Navigation Tab Order"} loading={isFetching}>
      {companyId == null ? (
        <></>
      ) : (
        <Grid container direction={"column"}>
          <Grid container item direction={"row"}>
            {(newValue?.tabOrder ?? []).map(([tab, subtabs], tabIndex) => {
              return (
                <Grid
                  key={tab}
                  item
                  justifyContent={"space-between"}
                  style={{
                    margin: "10px",
                    padding: "15px",
                    borderRadius: "8px",
                    borderColor: "#ccc",
                    borderStyle: "solid",
                    borderWidth: "2px",
                  }}
                  xs
                >
                  <Grid
                    item
                    container
                    direction={"row"}
                    justifyContent={"space-between"}
                    style={{
                      borderBottom: "solid",
                      borderBottomColor: "#ccc",
                      borderBottomWidth: "2px",
                      marginBottom: "15px",
                    }}
                  >
                    <Grid item>
                      <Typography style={{ marginTop: "8px" }}>
                        {tab}
                      </Typography>
                    </Grid>
                    <Grid item style={{ alignContent: "center" }}>
                      <IconButton
                        icon={NavigateBeforeIcon}
                        onClick={() => moveLeft(tabIndex)}
                      />
                      <IconButton
                        icon={NavigateNextIcon}
                        onClick={() => moveRight(tabIndex)}
                      />
                    </Grid>
                  </Grid>
                  <DragDropList
                    items={subtabs}
                    onChange={(subtabs) => {
                      setNewValue({
                        ...newValue,
                        tabOrder: [
                          ...newValue.tabOrder.slice(0, tabIndex),
                          [tab, subtabs],
                          ...newValue.tabOrder.slice(
                            tabIndex + 1,
                            newValue.tabOrder.length
                          ),
                        ],
                      });
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid
            container
            item
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
          >
            <Grid item>
              <Button
                onClick={() => {
                  setNewValue(data);
                }}
                variant={"contained"}
                disableElevation
                color={"primary"}
                style={{ marginRight: "15px" }}
              >
                {"Reset"}
              </Button>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant={"filled"}
                label={"Reason for Tab orer modification"}
                fullWidth
                onChange={(e) => saveUserLogMessage(e.target.value)}
                value={userLogMessage}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                onClick={async () => {
                  setSaving(true);
                  const { error } = await save({
                    companyId,
                    config: newValue,
                    userLogMessage,
                  });
                  if (error) addMessage("error", error + ": " + userLogMessage);
                  else
                    addMessage("success", "Tab order saved: " + userLogMessage);
                  setSaving(false);
                }}
                variant={"contained"}
                disableElevation
                color={"primary"}
                disabled={!!saving || !hasUserLogMessage}
                loading={!!saving}
                loadingIndicator={<DefaultLoader />}
              >
                {"Save"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogMessage: adminSelectors.userLogMessage(state),
    hasUserLogMessage: adminSelectors.hasUserLogMessage(state),
  };
};

const mapDispatchToProps = {
  saveUserLogMessage: adminActions.userLogMessage.update,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationConfigOverlay);
