import * as adminActions from "../../../store/actions/temp/admin/adminActions";
import TextField from "../../common/TextField";
import EditIcon from "@mui/icons-material/EditRounded";
import DiffIcon from "@mui/icons-material/ExposureRounded";
import {
  Alert,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import Component from "components/Component";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import GUIDGenerator from "components/common/GUIDGenerator";
import { detailedDiff } from "deep-object-diff";
import { useState, useEffect } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useListStaticDataQuery,
  useLazyGetStaticDataQuery,
  useUpdateStaticDataMutation,
} from "services/adminService";
import { useListConfigQuery } from "services/staticDataService";
import * as adminSelectors from "store/selectors/temp/admin/adminSelectors";

const diff = (oldJson, candidateJsonText) => {
  try {
    return {
      hasError: false,
      diff: detailedDiff(oldJson, JSON.parse(candidateJsonText)),
    };
  } catch (e) {
    return {
      hasError: true,
      message: `${e.name} ${e.message}`,
    };
  }
};

const StaticDiff = ({
  data,
  newData,
  companyId,
  staticDataKey,
  postSaveCallback,
  saveUserLogMessage,
  userLogMessage,
  hasUserLogMessage,
}) => {
  const { hasError, diff: diffData, message: errorMessage } = diff(
    data,
    newData
  );
  const noChanges =
    Object.keys({
      ...diffData?.added,
      ...diffData?.deleted,
      ...diffData?.updated,
    }).length === 0;
  const [
    updateStaticData,
    { isLoading: submitLoading, isError: submitError },
  ] = useUpdateStaticDataMutation();
  return (
    <Grid container spacing={2}>
      {hasError ? (
        <Grid item xs={12}>
          <Alert severity={"error"}>{errorMessage ?? "Unknown Error"}</Alert>
        </Grid>
      ) : noChanges ? (
        <Grid item xs={12}>
          <Alert severity={"warning"}>{"No changes detected"}</Alert>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <ReactDiffViewer
              oldValue={JSON.stringify(data, null, 2)}
              newValue={newData}
              splitView={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity={"success"}>{"This is valid JSON."}</Alert>
          </Grid>
          <Grid container item xs={12} alignItems={"center"}>
            <Grid item xs={10}>
              <TextField
                variant={"filled"}
                label={"Reason for change"}
                fullWidth
                onChange={(e) => saveUserLogMessage(e.target.value)}
                value={userLogMessage}
              />
            </Grid>
            <Grid item container xs={2} justifyContent={"right"}>
              <Grid item>
                <Button
                  variant={"contained"}
                  disableElevation
                  color={"primary"}
                  hasError={hasError || submitError}
                  isDisabled={hasError || submitLoading || !hasUserLogMessage}
                  isLoading={submitLoading}
                  onClick={() => {
                    updateStaticData({
                      companyId,
                      key: staticDataKey,
                      data: JSON.parse(newData),
                      userLogMessage,
                    }).then(() => {
                      if (postSaveCallback) {
                        postSaveCallback();
                      }
                    });
                  }}
                >
                  {"Save Changes"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const titleCase = (string) => {
  return string
    .split("_")
    .map((x) => x.substring(0, 1).toUpperCase() + x.substring(1).toLowerCase())
    .join(" ");
};

const StaticDataEditor = ({
  saveUserLogMessage,
  userLogMessage,
  hasUserLogMessage,
}) => {
  const { companyId } = useParams();

  const allStaticDataKeys = useListConfigQuery();
  const existingStaticDataKeys = useListStaticDataQuery(
    { companyId },
    { skip: companyId == null }
  );
  const [staticDataKey, setStaticDataKey] = useState(null);
  const [newStaticData, setNewStaticData] = useState(null);
  const [differenceToggle, setDifferenceToggle] = useState(false);
  const [isNewStaticData, setIsNewStaticData] = useState(false);
  const [staticDataTrigger, staticData] = useLazyGetStaticDataQuery();
  const loadedData = isNewStaticData ? {} : staticData?.data;

  useEffect(() => {
    if (companyId) {
      setStaticDataKey(null);
      setNewStaticData(null);
    }
  }, [companyId]);

  return (
    <>
      <GUIDGenerator />
      <Component
        title={"Configuration Files"}
        loading={
          allStaticDataKeys.isFetching || existingStaticDataKeys.isFetching
        }
      >
        {companyId && (
          <Grid container spacing={2}>
            {allStaticDataKeys.data && (
              <Grid item xs={12}>
                <Select
                  value={staticDataKey ?? "Select a File:"}
                  onChange={(e) => {
                    const key = e.target.value;
                    setStaticDataKey(key);
                    if (existingStaticDataKeys.data?.includes(key)) {
                      staticDataTrigger({ companyId, key });
                      setIsNewStaticData(false);
                    } else {
                      setIsNewStaticData(true);
                    }
                    setNewStaticData(null);
                  }}
                  fullWidth
                >
                  <MenuItem
                    style={{ pointerEvents: "none" }}
                    value={"Select a File:"}
                    key={"unselected"}
                  >
                    {"Select a File:"}
                  </MenuItem>
                  {allStaticDataKeys.data?.map(({ key, displayName }) => (
                    <MenuItem value={key} key={key}>
                      {displayName ?? titleCase(key)}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            )}
            {staticData.isFetching ? (
              <Grid item xs={12}>
                <DefaultLoader
                  color={"#dc7f4c"}
                  size={60}
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                />
              </Grid>
            ) : (
              (isNewStaticData || staticData.data) &&
              staticDataKey && (
                <>
                  <Grid item xs={12}>
                    <ToggleButtonGroup
                      value={differenceToggle ? "Diff" : "Edit"}
                      onChange={() => setDifferenceToggle(!differenceToggle)}
                      color={"primary"}
                      size={"small"}
                      exclusive
                    >
                      <ToggleButton value={"Edit"}>
                        <EditIcon
                          fontSize={"small"}
                          sx={{ marginRight: "3px" }}
                        />
                        <span style={{ paddingRight: "3px" }}>{"Edit"}</span>
                      </ToggleButton>
                      <ToggleButton value={"Diff"}>
                        <DiffIcon
                          fontSize={"small"}
                          sx={{ marginRight: "3px" }}
                        />
                        <span style={{ paddingRight: "2px" }}>{"Diff"}</span>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                    {differenceToggle ? (
                      <StaticDiff
                        data={loadedData}
                        newData={
                          newStaticData ??
                          (isNewStaticData
                            ? ""
                            : JSON.stringify(staticData?.data, null, 2))
                        }
                        companyId={companyId}
                        staticDataKey={staticDataKey}
                        postSaveCallback={() => {
                          setNewStaticData(null);
                          setIsNewStaticData(false);
                          staticDataTrigger({ companyId, key: staticDataKey });
                          existingStaticDataKeys?.refetch();
                          setDifferenceToggle(false);
                        }}
                        saveUserLogMessage={saveUserLogMessage}
                        userLogMessage={userLogMessage}
                        hasUserLogMessage={hasUserLogMessage}
                      />
                    ) : (
                      <CodeEditor
                        value={
                          newStaticData ??
                          (isNewStaticData
                            ? ""
                            : JSON.stringify(staticData?.data, null, 2))
                        }
                        language={"json"}
                        onChange={(e) => setNewStaticData(e.target.value)}
                        padding={15}
                        style={{
                          backgroundColor: "#f5f5f5",
                          fontFamily:
                            "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                        }}
                      />
                    )}
                  </Grid>
                </>
              )
            )}
          </Grid>
        )}
      </Component>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogMessage: adminSelectors.userLogMessage(state),
    hasUserLogMessage: adminSelectors.hasUserLogMessage(state),
  };
};

const mapDispatchToProps = {
  saveUserLogMessage: adminActions.userLogMessage.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaticDataEditor);
