import * as actions from "../../../store/actions/people/peopleActions";
import * as personSelectors from "../../../store/selectors/people/peopleSelectors";
import { Grid, TextField } from "@mui/material";
import Component from "components/Component";
import * as config from "config";
import { connect } from "react-redux";

export const OldBrokerComponent = (props) => {
  return (
    <Component
      title={"Broker"}
      subtitle={"The broker associated with this submission."}
    >
      <Grid container spacing={config.GRID_SPACING}>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Name"}
            autoComplete={"off"}
            id={"program-broker"}
            variant={"filled"}
            onChange={(e) => props.updateBrokerName(e.target.value)}
            value={props.broker ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Contact Email"}
            autoComplete={"off"}
            id={"program-broker-contact"}
            variant={"filled"}
            onChange={(e) => props.updateBrokerContact(e.target.value)}
            value={props.brokerContact ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Broking House"}
            autoComplete={"off"}
            id={"program-broker-house"}
            variant={"filled"}
            onChange={(e) => props.updateBrokerHouse(e.target.value)}
            value={props.brokerHouse ?? ""}
            fullWidth
          />
        </Grid>
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {
  updateBrokerName: actions.updateBrokerName,
  updateBrokerHouse: actions.updateBrokerHouse,
  updateBrokerContact: actions.updateBrokerContact,
};

const mapStateToProps = (state) => {
  const broker = personSelectors.selectBroker(state);
  return {
    broker: broker?.name ?? "",
    brokerContact: broker?.email ?? null,
    brokerHouse: broker?.company ?? "",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OldBrokerComponent);
