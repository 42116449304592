import * as logger from "common/logger";
import * as config from "config";
import { call, put, takeEvery } from "redux-saga/effects";
import * as adminActions from "store/actions/temp/admin/adminActions";
import * as utils from "utils";

export function* createCompany(action) {
  try {
    yield put(adminActions.createCompany.started());

    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.admin("companies"),
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(action.payload.companyDefn),
      }
    );
    const value = yield call([response, response.json]);
    yield put(adminActions.createCompany.succeeded(value));
  } catch (e) {
    logger.exception(e);
    yield put(adminActions.createCompany.failed(e.description));
  }
}

export default function* adminSaga() {
  yield takeEvery(adminActions.createCompany.startSaga, createCompany);
}
