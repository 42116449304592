import hazardProfileReducer from "./hazardProfileReducer";
import premDeductReducer from "./premDeductReducer";
import * as _ from "lodash";
import * as actionTypes from "store/actions/input/exposure/exposureActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const exposureReducer = (state, action) => {
  if (state === undefined) {
    state = {};
  }
  {
    const hazardProfile = hazardProfileReducer(state.hazardProfile, action);
    const premDeduct = premDeductReducer(state.premDeduct, action);
    if (
      hazardProfile !== state.hazardProfile ||
      premDeduct !== state.premDeduct
    ) {
      state = {
        ...state,
        hazardProfile,
        premDeduct,
      };
    }
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {};
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...action?.payload?.data?.state?.input?.exposure,
      };
    case actionTypes.UPDATE_EXPOSURE_VALUE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.year]: {
            ...(state.data?.[action.payload.year] || {}),
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...staticDataSelectors.selectConfig("submission_defaults")(
          action?.payload?.priorState
        )?.input?.exposure,
      };
    case actionTypes.UPDATE_EXPOSURE_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case actionTypes.ADD_EXTRA_EXPOSURE_METRIC:
      return {
        ...state,
        extraKeys: [...(state.extraKeys ?? []), action.payload],
      };
    case actionTypes.DELETE_EXTRA_EXPOSURE_METRIC:
      return {
        ...state,
        extraKeys: state.extraKeys?.filter((_) => _ !== action.payload),
      };
    case actionTypes.HIDE_TEMPLATE_EXPOSURE_METRIC:
      return {
        ...state,
        removedKeys: [...(state.removedKeys ?? []), action.payload],
      };
    case actionTypes.UNHIDE_TEMPLATE_EXPOSURE_METRIC:
      return {
        ...state,
        removedKeys: state.removedKeys?.filter((_) => _ !== action.payload),
      };
    case actionTypes.UPDATE_CUSTOM_MEASURE_NAME:
    case actionTypes.UPDATE_CUSTOM_MEASURE_UNIT:
      if (
        state.customKeys?.some(
          (customKey) => customKey.key === action.payload.key
        )
      ) {
        return {
          ...state,
          customKeys: state.customKeys
            ?.map((customKey) =>
              action.payload.key === customKey.key
                ? {
                    ...customKey,
                    ...action.payload,
                  }
                : customKey
            )
            ?.filter((customKey) => !!customKey.name || !!customKey.units),
        };
      } else {
        return {
          ...state,
          customKeys: [
            ...(state.customKeys ?? []),
            { ...action.payload },
          ].filter((customKey) => !!customKey.name || !!customKey.units),
        };
      }
    case actionTypes.DELETE_CUSTOM_MEASURE:
      return {
        ...state,
        customKeys: state.customKeys?.map((item) => {
          if (item.key === action.payload) {
            return { ...item, deleted: true };
          }
          return item;
        }),
      };

    case actionTypes.UPDATE_EXPOSURE_COMMENT:
      return {
        ...state,
        comments: {
          ...state.comments,
          [action.payload.key]: action.payload.value,
        },
      };

    case actionTypes.SHOW_ALL_EXPOSURE_COMMENTS:
      return {
        ...state,
        commentVisibility: {
          defaultVisible: true,
          exceptKeys: [],
        },
      };

    case actionTypes.HIDE_ALL_EXPOSURE_COMMENTS:
      return {
        ...state,
        commentVisibility: {
          defaultVisible: false,
          exceptKeys: [],
        },
      };

    case actionTypes.SHOW_EXPOSURE_COMMENT:
      return {
        ...state,
        commentVisibility: {
          ...state.commentVisibility,
          exceptKeys: state.commentVisibility?.defaultVisible
            ? _.without(
                state.commentVisibility?.exceptKeys ?? [],
                action.payload.key
              )
            : _.union(state.commentVisibility?.exceptKeys ?? [], [
                action.payload.key,
              ]),
        },
      };

    case actionTypes.HIDE_EXPOSURE_COMMENT:
      return {
        ...state,
        commentVisibility: {
          ...state.commentVisibility,
          exceptKeys: state.commentVisibility?.defaultVisible
            ? _.union(state.commentVisibility?.exceptKeys ?? [], [
                action.payload.key,
              ])
            : _.without(
                state.commentVisibility?.exceptKeys ?? [],
                action.payload.key
              ),
        },
      };
    case actionTypes.ADD_EXPOSURE_YEAR:
      const newData = {
        ...state.data,
      };
      newData[action.payload] = {};
      return {
        ...state,
        data: newData,
      };
    default:
      return state;
  }
};

export default exposureReducer;
