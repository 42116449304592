import * as types from "../../../actions/temp/navigation/navigationActionTypes";

export const INIT_STATE = {
  savedPersistentState: null,
};

const navigationReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case types.SAVE_PERSISTENT_STATE:
      return {
        ...state,
        savedPersistentState: action.payload,
      };
    default:
      return state;
  }
};

export default navigationReducer;
