import AppliedExcessRecorder from "./AppliedExcessRecorder";
import ClaimsColumnMapping from "./ClaimsColumnMapping";
import ClaimsValidation from "./ClaimsValidation";
import ExcludeMapping from "./ExcludeMapping";
import LossTypeMapping from "./LossTypeMapping";
import OpenClosedMapping from "./OpenClosedMapping";
import SheetSelector from "./SheetSelector";
import { Warning as WarningIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import { useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/input/claims/claimsActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const ColumnMapper = ({ columnsState, setNextButtonDisabled }) => {
  const status = columnsState?.status;
  if (status === "running") {
    return (
      <DefaultLoader
        color={"#dc7f4c"}
        style={{
          width: "200px",
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        size={60}
      />
    );
  } else if (status === "succeeded") {
    return (
      <form className={"form"} noValidate>
        <FormControl
          margin={"normal"}
          className={"sheet-select-form"}
          variant={"standard"}
          fullWidth
        >
          <ClaimsColumnMapping />
        </FormControl>
      </form>
    );
  } else if (status === "failed") {
    setNextButtonDisabled(true);
    return (
      <form className={"form"} noValidate>
        <FormControl
          margin={"normal"}
          className={"sheet-select-form"}
          variant={"standard"}
          fullWidth
        >
          <Alert severity={"error"}>
            {"Could not read columns from the sheet."}
          </Alert>
        </FormControl>
      </form>
    );
  } else {
    return <></>;
  }
};

const ValuesMapper = () => (
  <form className={"form"} noValidate>
    <FormControl
      margin={"normal"}
      className={"sheet-select-form"}
      variant={"standard"}
      fullWidth
    >
      <LossTypeMapping />
      <OpenClosedMapping />
      <ExcludeMapping />
    </FormControl>
  </form>
);

const AppliedExcessRecorderForm = ({ onValidation }) => (
  <form className={"form"} noValidate>
    <FormControl
      margin={"normal"}
      className={"sheet-select-form"}
      variant={"standard"}
      fullWidth
    >
      <AppliedExcessRecorder onValidation={onValidation} />
    </FormControl>
  </form>
);

const Validator = () => (
  <form className={"form"} noValidate>
    <FormControl
      margin={"normal"}
      className={"sheet-select-form"}
      variant={"standard"}
      fullWidth
    >
      <ClaimsValidation />
    </FormControl>
  </form>
);

const Content = ({ steps, step, columnsState, setNextButtonDisabled }) => {
  switch (steps?.[step]?.key) {
    case "mapColumns":
      return (
        <ColumnMapper
          columnsState={columnsState}
          setNextButtonDisabled={setNextButtonDisabled}
        />
      );
    case "mapValues":
      return <ValuesMapper />;
    case "recordExcess":
      return (
        <AppliedExcessRecorderForm
          onValidation={(isValid) => setNextButtonDisabled(!isValid)}
        />
      );
    case "validate":
      return <Validator />;
    case "selectSheet":
    default:
      return <SheetSelector setHasError={setNextButtonDisabled} />;
  }
};

const ClaimsFileUploadDialog = (props) => {
  const [forceNextButtonDisabled, setForceNextButtonDisabled] = useState(false);

  const steps = [
    {
      key: "selectSheet",
      name: "Select sheet",
    },
    {
      key: "mapColumns",
      name: "Map Columns",
      nextButtonDisabled: props.noColumnsMapped,
      nextButtonWarning: props.noColumnsMapped,
      nextButtonWarningMessage: "There are no mapped columns.",
    },
    {
      key: "mapValues",
      name: "Map Values",
      nextButtonWarning: !props.allValuesMapped,
      nextButtonWarningMessage: props.noValuesMapped
        ? "There are no mapped claims"
        : "There are unmapped claims.",
    },
    ...(props.userConfig?.componentVisibility?.[
      "<ClaimsFileUpload>.recordExcess"
    ] ?? true
      ? [
          {
            key: "recordExcess",
            name: "Record Excess",
          },
        ]
      : []),
    {
      key: "validate",
      name: "Validate",
      nextButtonDisabled: true,
    },
  ];

  const getNextButtonWarningMessage = (step) => {
    return steps[step]?.nextButtonWarningMessage ?? "";
  };
  const getNextButtonDisabled = (step) => {
    return forceNextButtonDisabled || steps[step]?.nextButtonDisabled || false;
  };
  const getNextButtonWarning = (step) => {
    return steps[step]?.nextButtonWarning ?? false;
  };

  return (
    <Dialog
      open={props.sheetsDialogIsOpen}
      aria-labelledby={"max-width-dialog-title"}
      maxWidth={false}
      fullWidth
      className={"claims-file-upload-dialog"}
      data-testid={"mapper"}
    >
      <DialogTitle id={"max-width-dialog-title"}>{"Claims Data"}</DialogTitle>
      <DialogContent>
        {props.error ? (
          <Alert severity={"error"}>{props.error}</Alert>
        ) : props.sheets === undefined ? (
          <DefaultLoader
            color={"#dc7f4c"}
            style={{
              width: "200px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            size={60}
          />
        ) : (
          <>
            <Stepper activeStep={props.step}>
              {steps.map((label) => {
                return (
                  <Step key={label.name}>
                    <StepLabel>{label.name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Content
              steps={steps}
              step={props.step}
              columnsState={props.columnsState}
              setNextButtonDisabled={setForceNextButtonDisabled}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.claimsDialogClosed}
          disableElevation
          variant={"contained"}
          color={"secondary"}
        >
          {"Cancel"}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={() => {
            props.prevStep();
            setForceNextButtonDisabled(false);
          }}
          disableElevation
          variant={"contained"}
          color={"primary"}
          isDisabled={props.step === 0 || !!props.error}
        >
          {"Prev"}
        </Button>
        <Button
          onClick={() => {
            props.nextStep();
            setForceNextButtonDisabled(false);
          }}
          tooltip={getNextButtonWarningMessage(props.step)}
          tooltipRequired={getNextButtonWarning(props.step)}
          disableElevation
          variant={"contained"}
          color={"primary"}
          isDisabled={getNextButtonDisabled(props.step) || props.error}
        >
          {getNextButtonWarning(props.step) ? <WarningIcon /> : <></>}
          {"Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  claimsDialogClosed: actions.claimsDialogClosed,
  nextStep: actions.nextStep,
  prevStep: actions.prevStep,
};

const mapStateToProps = (state) => {
  return {
    ...(state.input.claims.activeFile || {}),
    sheetsDialogIsOpen:
      state.input.claims.activeFile != null &&
      !state.input.claims.activeFile.fileIsProcessing &&
      !state.input.claims.fileUploadCancelled,
    allValuesMapped:
      (claimsSelectors.selectTotalOfLossTypeCounts(state) ===
        claimsSelectors.selectSumOfSelectedLossTypeCounts(state) ||
        claimsSelectors.selectLossTypeAllRowMapped(state)) &&
      (claimsSelectors.selectTotalOfOpenClosedCounts(state) ===
        claimsSelectors.selectSumOfSelectedOpenClosedCounts(state) ||
        state.input.claims.activeFile?.openClosedMapping == null),
    noValuesMapped:
      claimsSelectors.selectSumOfSelectedLossTypeCounts(state) === 0,
    userConfig: userSelectors.selectUserConfig(state),
    noColumnsMapped:
      Object.keys(claimsSelectors.getColumnMappings(state)).length === 0,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimsFileUploadDialog);
