import Mapper from "components/common/Mapper";
import * as config from "config";
import { connect } from "react-redux";
import { openClosedMappingUpdated } from "store/actions/input/claims/claimsActions";
import { selectOpenClosedFractionDisplayString } from "store/selectors/input/claims/claimsSelectors";

const mapDispatchToProps = {
  mappingUpdated: openClosedMappingUpdated,
};

const mapStateToProps = (state) => ({
  targets: [
    { display: "Open", key: "O" },
    { display: "Closed", key: "C" },
    { display: "N/A", key: config.CLAIMS_MAPPING_IGNORE_VALUE },
  ],
  sources: state.input.claims.activeFile?.openClosed,
  sourcesMeta: state.input.claims.activeFile?.openClosedMeta,
  metaFields: [
    { field: "count", display: "Count" },
    {
      field: "count",
      display: "Mapped",
      mapping: selectOpenClosedFractionDisplayString(state),
      mapper: (value) =>
        Math.round(
          (value / state.input.claims.activeFile?.openClosedTotal) * 100
        ) + "%",
    },
  ],
  mapping: state.input.claims.activeFile?.openClosedMapping,
  suggested: state.input.claims.activeFile?.openClosedMappingSuggestions,
  isTransposed: true,
  maxHeight: "50vh",
});

export default connect(mapStateToProps, mapDispatchToProps)(Mapper);
