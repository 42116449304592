import DirListTreeItem from "./DirListTreeItem";
import { DirListTreeSupport } from "./DirListTreeSupport";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeView } from "@mui/x-tree-view";
import { UPLOAD_CLAIMS_LOSS_FILES_VIA_FILES_SERVICE } from "flags";
import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as actions from "store/actions/input/attachments/attachmentsActions";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import * as hazardActions from "store/actions/input/exposure/hazardActions";
import * as selectors from "store/selectors/input/attachments/attachmentsSelectors";
import * as tempSelectors from "store/selectors/temp/attachments/tempAttachmentsSelectors";

export const FileListTree = ({
  setAttachmentName,
  processHazardFile,
  processLossFileInFiles,
  processLossFileFromFiles,
  fileTrees,
  attachments,
  refreshMetadata,
  markAttachmentHidden,
  markDownloadState,
  getDownloadState,
  createFileButtons,
  createDirButtons,
  deleteDir,
  setDirName,
  addDir,
  moveFileToDirectory,
  moveDirectory,
  moveFileToFile,
  embeddedFiles,
  startFileProcessingRef,
}) => {
  const [expanded, setExpanded] = useState([""]);
  const [selected, setSelected] = useState([]);
  const expand = (id) => {
    if (!expanded.includes(id)) {
      setExpanded([...expanded, id]);
    }
  };
  const uploadClaimsViaFiles = staticDataService.useFeatureFlag(
    UPLOAD_CLAIMS_LOSS_FILES_VIA_FILES_SERVICE,
    true
  );
  const processLossFile = uploadClaimsViaFiles
    ? processLossFileInFiles
    : processLossFileFromFiles;
  const getExpanded = () => expanded;
  const fileSystem = new DirListTreeSupport(
    attachments,
    {
      addDir,
      deleteDir,
      setDirName,
      createFileButtons,
      moveDirectory,
      createDirButtons,
      getExpanded,
      expand,
    },
    {
      initiateRefresh: refreshMetadata,
      processHazardFile,
      processLossFile,
      markAttachmentHidden,
      setAttachmentName,
      markDownloadState,
      getDownloadState,
      noFileEditing: false,
      moveFileToDirectory,
      moveFileToFile,
      embeddedFiles,
      processClaimsV2LossFile: (fileId, filename) => {
        startFileProcessingRef.current.start(fileId, filename);
      },
    },
    fileTrees
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <TreeView
        multiSelect={true}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        className={"file-tree"}
        expanded={expanded}
        onNodeToggle={(e, nodeIds) => {
          const meta =
            navigator.platform.startsWith("Mac") ||
            navigator.platform === "iPad";
          const targetKey = (meta ? e.metaKey : e.ctrlKey) || e.shiftKey;
          if (!targetKey) {
            setExpanded(nodeIds);
          }
        }}
        onNodeSelect={(event, nodeIds) => {
          const reduceFunc = (acc, current) => {
            for (const support of [
              ...(current.children ?? []),
              ...(current.files ?? []),
              ...(current.directories ?? []),
            ]) {
              reduceFunc(acc, support);
            }
            if (nodeIds.includes(current.getUniqueId())) {
              acc.push(current);
            }
            return acc;
          };
          setSelected(
            [...fileSystem.files, ...fileSystem.directories].reduce(
              reduceFunc,
              []
            )
          );
        }}
        aria-label={"file system navigator"}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ flexGrow: 1, overflowY: "auto" }}
      >
        <DirListTreeItem
          selected={selected}
          setSelected={setSelected}
          dirSupport={fileSystem}
        ></DirListTreeItem>
      </TreeView>
    </DndProvider>
  );
};

const mapDispatchToProps = {
  setAttachmentName: actions.updateAttachmentName,
  processHazardFile: hazardActions.filePreloaded,
  processLossFileFromFiles: actions.lossFileAttachmentFile,
  processLossFileInFiles: claimsActions.filesFileUploaded,
  refreshMetadata: actions.metadataRefresh,
  markAttachmentHidden: actions.removeFileFromDirectory,
  deleteDir: actions.removeDirectory,
  setDirName: actions.renameDirectory,
  addDir: actions.createDirectory,
  moveFileToDirectory: actions.moveFileToDirectory,
  moveDirectory: actions.moveDirectory,
  moveFileToFile: actions.moveFileToFile,
};

const mapStateToProps = (state) => ({
  fileTrees: tempSelectors.attachedFileTrees(state),
  attachments: selectors.getAttachments(state),
  embeddedFiles: (fileId) => tempSelectors.embeddedFiles(state, fileId),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileListTree);
