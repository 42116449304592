export const selectNotesConfig = (_) => [
  {
    id: "riskSummary",
    label: "Risk Summary",
    section: "Additional Notes",
  },
  {
    id: "program",
    label: "Program Notes",
    section: "Additional Notes",
  },
  {
    id: "claims",
    label: "Claims",
    section: "Additional Notes",
  },
  {
    id: "exposure",
    label: "Exposure",
    section: "Additional Notes",
  },
  {
    id: "scenarios",
    label: "Scenarios",
    section: "Additional Notes",
  },
  {
    id: "lossFrequency",
    label: "Loss Frequency",
    section: "Additional Notes",
  },
  {
    id: "layerPricing",
    label: "Layer Pricing",
    section: "Additional Notes",
  },
  {
    id: "towerPricing",
    label: "Tower Pricing",
    section: "Additional Notes",
  },
  {
    id: "comparison",
    label: "Comparison",
    section: "Additional Notes",
  },
  {
    id: "riskConsiderations",
    label: "Risk Considerations of Note",
    section: "Summary",
  },
  {
    id: "termsAndConditions",
    label: "Terms and Conditions",
    section: "Summary",
  },
  {
    id: "capacityUtilization",
    label: "Capacity Utilization",
    section: "Summary",
  },
  {
    id: "pricingComments",
    label: "Pricing Comments",
    section: "Summary",
  },
  {
    id: "considerationsForNextRenewal",
    label: "Considerations for Next Renewal",
    section: "Summary",
  },
  {
    id: "considerationsFromLastRenewal",
    label: "Considerations from Last Renewal",
    section: "Summary",
  },
  {
    id: "peerReview",
    label: "Peer Review",
    section: "Summary",
  },
];

export const selectNotes = (state) => ({
  riskSummary: state?.input?.program?.riskSummary ?? null,
  program: state?.input?.program?.notes ?? null,
  claims: state?.input?.claims?.note ?? null,
  exposure: state?.input?.exposure?.note ?? null,
  scenarios: state?.input?.scenarios?.note ?? null,
  lossFrequency: state?.pricing?.frequency?.note ?? null,
  layerPricing: state?.pricing?.note ?? null,
  towerPricing: state?.pricing?.tower?.note ?? null,
  comparison: state?.peers?.note ?? null,
  riskConsiderations: state?.summary?.notes?.riskConsiderations ?? null,
  termsAndConditions: state?.summary?.notes?.termsAndConditions ?? null,
  capacityUtilization: state?.summary?.notes?.capacityUtilization ?? null,
  pricingComments: state?.summary?.notes?.pricingComments ?? null,
  considerationsForNextRenewal:
    state?.summary?.notes?.considerationsForNextRenewal ?? null,
  considerationsFromLastRenewal:
    state?.summary?.considerationsFromLastRenewal ?? null,
  peerReview: state?.summary?.peerReview?.commentary ?? null,
});
