import AdminDashboard from "./AdminDashboard";
import {
  AutoFixNormal as DefaultIcon,
  Refresh as RefetchIcon,
  DesktopAccessDisabled as DisabledIcon,
  ThumbUp as UnsetIcon,
  Warning as WarnIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Component from "components/Component";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import DashboardContent from "components/layout/DashboardContent";
import { useState } from "react";
import * as adminService from "services/adminService";

const UnsetMaintenanceControlAction = ({
  maintenanceStatus,
  updateMaintenanceStatus,
}) => {
  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader title={"Unset Maintenance Control"} />
      <CardContent></CardContent>
      <CardActions>
        <Button
          onClick={() =>
            updateMaintenanceStatus({
              ...maintenanceStatus,
              disableNow: false,
              showWarningNow: false,
              message: "",
            })
          }
        >
          {"Unset"}
        </Button>
      </CardActions>
    </Card>
  );
};

const ShowWarningMessageAction = ({
  maintenanceStatus,
  updateMaintenanceStatus,
}) => {
  const [message, setMessage] = useState("");
  const defaultMessage =
    "Marmalade will be unavailable for about half an hour this evening around 21:00 AST for maintenance.";
  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader title={"Show Warning Message"} />
      <CardContent>
        <TextField
          fullWidth
          label={"Message"}
          variant={"filled"}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton
                  variant={"bright"}
                  icon={DefaultIcon}
                  tooltip={"Use the default message"}
                  onClick={() => setMessage(defaultMessage)}
                />
              </InputAdornment>
            ),
          }}
        />
      </CardContent>
      <CardActions>
        <Button
          onClick={() =>
            updateMaintenanceStatus({
              ...maintenanceStatus,
              disableNow: false,
              showWarningNow: true,
              message,
            })
          }
          isDisabled={!message?.length}
        >
          {"Show Message"}
        </Button>
      </CardActions>
    </Card>
  );
};

const DisableAppAction = ({ maintenanceStatus, updateMaintenanceStatus }) => {
  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader title={"Disable App"} />
      <CardContent></CardContent>
      <CardActions>
        <Button
          onClick={() =>
            updateMaintenanceStatus({
              ...maintenanceStatus,
              disableNow: true,
              showWarningNow: false,
              message: "",
            })
          }
        >
          {"Disable App"}
        </Button>
      </CardActions>
    </Card>
  );
};

const ForceReloadAction = ({ maintenanceStatus, updateMaintenanceStatus }) => {
  return (
    <Card sx={{ padding: 2 }}>
      <CardHeader title={"Force Reload"} />
      <CardContent></CardContent>
      <CardActions>
        <Button
          onClick={() =>
            updateMaintenanceStatus({
              ...maintenanceStatus,
              disableNow: false,
              showWarningNow: false,
              message: "",
              versionNumber: new Date().toISOString(),
            })
          }
        >
          {"Force Reload"}
        </Button>
      </CardActions>
    </Card>
  );
};

const Badge = ({ state }) => {
  if (state === "disabled") {
    return <Chip color={"error"} icon={<DisabledIcon />} label={"Disabled"} />;
  } else if (state === "warn") {
    return (
      <Chip color={"warning"} icon={<WarnIcon />} label={"Showing warning"} />
    );
  } else {
    return (
      <Chip
        color={"success"}
        icon={<UnsetIcon />}
        label={"No maintenance control set"}
      />
    );
  }
};

const CurrentState = ({ state, maintenanceStatus }) => {
  return (
    <Stack direction={"column"} spacing={1} sx={{ paddingBottom: 5 }}>
      <Stack direction={"row"} alignContent={"flex-start"}>
        <Badge state={state} />
      </Stack>
      {state === "warn" && (
        <Typography>
          <b>{"Message: "}</b>
          {maintenanceStatus?.message}
        </Typography>
      )}
    </Stack>
  );
};

const extractState = (maintenanceStatus) => {
  if (maintenanceStatus?.disableNow) {
    return "disabled";
  } else if (maintenanceStatus?.showWarningNow) {
    return "warn";
  } else {
    return "unset";
  }
};

const getAvailableActions = (state) => {
  if (state === "unset") {
    return [ShowWarningMessageAction, DisableAppAction, ForceReloadAction];
  } else if (state === "disabled") {
    return [ForceReloadAction];
  } else if (state === "warn") {
    return [DisableAppAction, ForceReloadAction, UnsetMaintenanceControlAction];
  } else {
    return [];
  }
};

const MaintenanceStatus = () => {
  const {
    data: maintenanceStatus,
    isFetching,
    refetch,
  } = adminService.useAdminRetrieveMaintenanceStatusQuery();

  const [
    adminUpdateMaintenanceStatus,
    { isFetching: isUpdating },
  ] = adminService.useAdminUpdateMaintenanceStatusMutation();

  const updateMaintenanceStatus = async (newMaintenanceStatus) => {
    await adminUpdateMaintenanceStatus(newMaintenanceStatus);
    refetch();
  };

  const state = extractState(maintenanceStatus);

  const availableActions = getAvailableActions(state);

  return (
    <AdminDashboard>
      <DashboardContent isFullWidth={false}>
        <Component
          title={"Maintenance Status"}
          isFetching={isFetching || isUpdating}
          options={
            <IconButton
              variant={"bright"}
              icon={RefetchIcon}
              tooltip={"Refetch latest maintenance status"}
              onClick={refetch}
            />
          }
        >
          <Stack direction={"column"} spacing={2}>
            <CurrentState state={state} maintenanceStatus={maintenanceStatus} />
            {(availableActions ?? []).map((Action, index) => (
              <Action
                key={`${state}-${index}`}
                maintenanceStatus={maintenanceStatus}
                updateMaintenanceStatus={updateMaintenanceStatus}
              />
            ))}
            <Box sx={{ paddingTop: 10 }}>
              <Accordion>
                <AccordionSummary sx={{ bgcolor: "#F5F5F5" }}>
                  <Typography variant={"body1"}>
                    {"Raw Maintenance Status"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <pre>{JSON.stringify(maintenanceStatus, null, 4)}</pre>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        </Component>
      </DashboardContent>
    </AdminDashboard>
  );
};

export default MaintenanceStatus;
