import SelectedIcon from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import IconButton from "components/common/IconButton";
import { GROUPING_MODE } from "components/scenarios/constants";
import { useState } from "react";

const SettingsMenu = ({
  anchorEl,
  closeMenu,
  grouping,
  changeGrouping,
  sortByInsured,
  changeSort,
}) => {
  const setSortByScenarios = () => changeSort(false);
  const setSortByInsured = () => changeSort(true);

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl !== null}
      onClose={(_, reason) => {
        if (reason === "backdropClick") closeMenu();
      }}
    >
      <MenuItem disabled>
        <Typography variant={"caption"}>{"Grouping"}</Typography>
      </MenuItem>

      <MenuItem onClick={() => changeGrouping(GROUPING_MODE.SUMMARY)}>
        {grouping === GROUPING_MODE.SUMMARY && (
          <ListItemIcon>
            <SelectedIcon fontSize={"small"} />
          </ListItemIcon>
        )}
        <ListItemText inset={grouping !== GROUPING_MODE.SUMMARY}>
          {"Summary"}
        </ListItemText>
      </MenuItem>

      <MenuItem onClick={() => changeGrouping(GROUPING_MODE.BY_SCENARIO)}>
        {grouping === GROUPING_MODE.BY_SCENARIO && (
          <ListItemIcon>
            <SelectedIcon fontSize={"small"} />
          </ListItemIcon>
        )}
        <ListItemText inset={grouping !== GROUPING_MODE.BY_SCENARIO}>
          {"By Scenario"}
        </ListItemText>
      </MenuItem>

      <MenuItem onClick={() => changeGrouping(GROUPING_MODE.BY_INSURED)}>
        {grouping === GROUPING_MODE.BY_INSURED && (
          <ListItemIcon>
            <SelectedIcon fontSize={"small"} />
          </ListItemIcon>
        )}
        <ListItemText inset={grouping !== GROUPING_MODE.BY_INSURED}>
          {"By Insured"}
        </ListItemText>
      </MenuItem>

      <Divider />

      <MenuItem disabled>
        <Typography variant={"caption"}>{"Sort By"}</Typography>
      </MenuItem>

      {grouping === GROUPING_MODE.BY_INSURED ? (
        [
          <MenuItem onClick={() => setSortByScenarios()} key={"SORT_SCENARIO"}>
            {!sortByInsured && (
              <ListItemIcon>
                <SelectedIcon fontSize={"small"} />
              </ListItemIcon>
            )}
            <ListItemText inset={sortByInsured}>{"Total Risk"}</ListItemText>
          </MenuItem>,
          <MenuItem onClick={() => setSortByInsured()} key={"SORT_INSURED"}>
            {sortByInsured && (
              <ListItemIcon>
                <SelectedIcon fontSize={"small"} />
              </ListItemIcon>
            )}
            <ListItemText inset={!sortByInsured}>{"Insured Name"}</ListItemText>
          </MenuItem>,
        ]
      ) : (
        <MenuItem disabled>
          <ListItemIcon>
            <SelectedIcon fontSize={"small"} />
          </ListItemIcon>
          <ListItemText>{"Scenario Name"}</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
};

const FilterBarOptions = ({
  date,
  grouping,
  sortByInsured,
  onChangeDate,
  onChangeGrouping,
  onChangeSort,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const changeGrouping = (mode) => {
    onChangeGrouping(mode);
    closeMenu();
  };

  const changeSort = (sortByInsured) => {
    onChangeSort(sortByInsured);
    closeMenu();
  };

  return (
    <Stack direction={"row"} spacing={1}>
      <Tooltip title={"Effective Date"}>
        <FormattedDatePicker
          label={"In-Force Date"}
          compact={true}
          value={date}
          onChangeDate={onChangeDate}
        />
      </Tooltip>
      <IconButton
        variant={"bright"}
        icon={SettingsIcon}
        tooltip={"Group & Sort Settings"}
        onClick={openMenu}
      />

      <SettingsMenu
        anchorEl={anchorEl}
        closeMenu={closeMenu}
        grouping={grouping}
        changeGrouping={changeGrouping}
        sortByInsured={sortByInsured}
        changeSort={changeSort}
      />
    </Stack>
  );
};

export default FilterBarOptions;
