import IsMappedIcon from "./IsMappedIcon";
import { Tooltip } from "@mui/material";
import { truncateStringWithEllipsis } from "common/strings";

const MappingColumnHeader = ({
  display,
  mapping,
  showMappingSummary,
  showMappingSummaryIcon,
}) => {
  return (
    <div className={"horizontal"}>
      <div className={"vertical"}>
        <div className={"vertical-writing"}>
          <Tooltip title={display}>
            <span>
              {truncateStringWithEllipsis(display, { maxLength: 32 })}
            </span>
          </Tooltip>
        </div>
      </div>
      {showMappingSummary && (
        <div className={"vertical"}>
          {showMappingSummaryIcon && <IsMappedIcon isMapped={!!mapping} />}
          {mapping && (
            <div className={"vertical-writing"}>
              <span className={"faded"}>
                <Tooltip title={mapping}>
                  <span>
                    {truncateStringWithEllipsis(mapping, { maxLength: 32 })}
                  </span>
                </Tooltip>
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MappingColumnHeader;
