import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.user(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const userServiceApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    whereAmI: builder.query({
      query: () => ({
        url: "where-am-i",
      }),
    }),
    attributes: builder.query({
      query: () => ({
        url: "attributes",
      }),
    }),
    retrieveConfig: builder.query({
      query: ({ companyId }) => ({
        url: `user-config/${companyId}`,
      }),
    }),
  }),
});

export const {
  useWhereAmIQuery,
  useAttributesQuery,
  useRetrieveConfigQuery,
} = userServiceApi;
