import JsonDiffEditor from "./JsonDiffEditor";
import * as support from "./JsonDiffEditor.support";
import {
  EditRounded as EditIcon,
  SettingsBackupRestore as ResetIcon,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as submissionsService from "services/submissionsService";
import * as transferService from "services/transferService";

const EXISTING_ACTION_OPTIONS = [
  {
    key: "NEW",
    label: "New",
  },
  {
    key: "RENEW",
    label: "Renew",
  },
  {
    key: "MERGE",
    label: "Merge",
  },
  {
    key: "MERGE_AND_DUPLICATE",
    label: "Merge & Dupe",
  },
];

const EXISTING_ACTIONS_REQUIRING_SUBMISSION = [
  "RENEW",
  "MERGE",
  "MERGE_AND_DUPLICATE",
];

const SubmissionSelector = ({ companyId, submissionId, setSubmissionId }) => {
  const {
    data: submissions,
    isLoading,
  } = submissionsService.useListSubmissionsQuery({
    impersonateCompanyId: companyId,
  });

  return (
    <Autocomplete
      value={
        submissions?.filter(
          (submission) => submission.submissionId === submissionId
        )?.[0] ?? (submissionId == null ? null : { insured: "<Unknown>" })
      }
      onChange={(_, value) => setSubmissionId(value?.submissionId ?? null)}
      options={submissions ?? []}
      getOptionKey={({ submissionId }) => submissionId}
      getOptionLabel={({ submissionId, insured, inception }) =>
        `[${inception}] ${insured} (${submissionId})`
      }
      renderInput={(params) => (
        <TextField {...params} label={"Submission"} error={!submissionId} />
      )}
      autoHighlight
      sx={{ width: "50%" }}
      loading={isLoading}
    />
  );
};

const EditExisting = ({ companyId, existing, setExisting, onClose }) => {
  const [_createSubmission] = submissionsService.useCreateSubmissionMutation();
  const [
    _retrieveSubmission,
  ] = submissionsService.useLazyRetrieveSubmissionQuery();

  const [action, setAction] = useState(existing?.action);
  const [submissionId, setSubmissionId] = useState(existing?.submissionId);

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const requireResponse = (func, name) => async (args) => {
    const response = await func(args);
    if (response.error) {
      throw new Error(`Error in ${name}: ${response.error}`);
    } else if (!response.data) {
      throw new Error(`Empty response in ${name}`);
    } else {
      return response.data;
    }
  };

  const createSubmission = () =>
    requireResponse(
      _createSubmission,
      "createSubmission"
    )({ impersonateCompanyId: companyId });
  const renewSubmission = (renewFromSubmission) =>
    requireResponse(
      _createSubmission,
      "createSubmission"
    )({ impersonateCompanyId: companyId, renewFromSubmission });
  const retrieveSubmission = (submissionId) =>
    requireResponse(
      _retrieveSubmission,
      "retrieveSubmission"
    )({
      submissionId,
      impersonateCompanyId: companyId,
    });

  const _loadNew = async () => {
    const submission = await createSubmission();
    return {
      action,
      submission,
    };
  };
  const _loadRenew = async (submissionId) => {
    const renewFromSubmission = await retrieveSubmission(submissionId);
    const submission = await renewSubmission(renewFromSubmission);
    return {
      action,
      submissionId,
      version: renewFromSubmission?.savedToken,
      submission,
    };
  };
  const _loadMerge = async (submissionId) => {
    const submission = await retrieveSubmission(submissionId);
    return {
      action,
      submissionId,
      version: submission?.savedToken,
      submission,
    };
  };
  const _loadMergeAndDuplicate = async (submissionId) => {
    throw new Error("Merge & Dupe not yet supported");
  };

  const load = async () => {
    const requireSubmissionId = () => {
      if (!submissionId) {
        throw new Error("Must select a submission");
      }
      return submissionId;
    };
    if (action === "NEW") {
      return await _loadNew();
    } else if (action === "RENEW") {
      return await _loadRenew(requireSubmissionId());
    } else if (action === "MERGE") {
      return await _loadMerge(requireSubmissionId());
    } else if (action === "MERGE_AND_DUPLICATE") {
      return await _loadMergeAndDuplicate(requireSubmissionId());
    } else {
      throw new Error(`Unknown action: ${action}`);
    }
  };

  const onSelect = async () => {
    try {
      setIsFetching(true);
      const existing = await load();
      setExisting(existing);
      onClose();
    } catch (e) {
      setError(String(e));
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Dialog
      open
      fullWidth
      onKeyUp={(e) => {
        if (e.key === "Enter" && e.ctrlKey) {
          onSelect();
        }
      }}
    >
      <DialogTitle>{"Set Existing"}</DialogTitle>
      <DialogContent>
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          spacing={2}
        >
          <Autocomplete
            value={
              EXISTING_ACTION_OPTIONS.filter(
                (option) => option.key === action
              )?.[0] ?? (action == null ? null : { label: "<Unknown>" })
            }
            onChange={(_, value) => setAction(value?.key)}
            options={EXISTING_ACTION_OPTIONS}
            renderInput={(params) => (
              <TextField {...params} label={"Action"} error={!action} />
            )}
            autoHighlight
            disableClearable
            sx={{ width: "50%" }}
          />
          {EXISTING_ACTIONS_REQUIRING_SUBMISSION.includes(action) && (
            <SubmissionSelector
              companyId={companyId}
              submissionId={submissionId}
              setSubmissionId={setSubmissionId}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          sx={{ width: "100%" }}
          spacing={2}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={1}
            sx={{ width: "100%" }}
          >
            <Button
              color={"secondary"}
              onClick={onClose}
              isDisabled={isFetching}
            >
              {"Cancel"}
            </Button>
            <Button
              color={"primary"}
              onClick={onSelect}
              isLoading={isFetching}
              isDisabled={
                EXISTING_ACTIONS_REQUIRING_SUBMISSION.includes(action) &&
                !submissionId
              }
            >
              {"Select"}
            </Button>
          </Stack>
          {error && (
            <Alert severity={"error"} sx={{ width: "100%" }}>
              {error}
            </Alert>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

const Existing = ({ companyId, existing, setExisting }) => {
  const action = existing?.action;
  const actionName = action
    ? EXISTING_ACTION_OPTIONS.filter(({ key }) => action === key)?.[0]?.label ??
      "[Unknown]"
    : "[Not Set]";

  const [isEditing, setIsEditing] = useState(false);

  const isSelectionRequired = action === "NEW" && existing?.submission == null;

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          padding: 2,
        }}
      >
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          spacing={2}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%" }}
          >
            <Typography variant={"body1"} sx={{ fontWeight: "bold" }}>
              {actionName}
            </Typography>
            <IconButton
              scale={"small"}
              icon={EditIcon}
              onClick={() => setIsEditing(true)}
            />
          </Stack>
          {EXISTING_ACTIONS_REQUIRING_SUBMISSION.includes(action) && (
            <>
              <Typography variant={"body1"}>
                {"Submission ID: "}
                {existing?.submissionId ?? "[Not Set]"}
              </Typography>
              <Typography variant={"body1"}>
                {existing?.submission?.insured}
              </Typography>
              <Typography variant={"body1"}>
                {existing?.submission?.inception}
              </Typography>
            </>
          )}
        </Stack>
      </Paper>
      {(isEditing || isSelectionRequired) && (
        <EditExisting
          companyId={companyId}
          existing={existing}
          setExisting={setExisting}
          onClose={() => setIsEditing(false)}
        />
      )}
    </>
  );
};

const Content = ({ companyId, mergeDefinition, updateMergeDefinition }) => {
  const [mergedString, setMergedString] = useState("");
  const [existing, setExisting] = useState({});

  useEffect(() => {
    setMergedString(support.stringify(mergeDefinition?.merged?.submission));
    setExisting(mergeDefinition?.existing ?? {});
  }, [mergeDefinition, setMergedString]);

  const incomingString = useMemo(
    () => support.stringify(mergeDefinition?.incoming?.submission ?? {}),
    [mergeDefinition]
  );
  const existingString = useMemo(
    () => support.stringify(existing?.submission ?? {}),
    [existing]
  );

  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={2}
    >
      <Existing
        companyId={companyId}
        existing={existing}
        setExisting={setExisting}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{ width: "100%" }}
      >
        <Button
          onClick={() => {
            const newMergeDefinition = {
              ...mergeDefinition,
              existing,
              merged: {
                submission: JSON.parse(mergedString),
              },
            };
            updateMergeDefinition(newMergeDefinition);
          }}
        >
          {"Save"}
        </Button>
      </Stack>
      <JsonDiffEditor
        incoming={incomingString}
        existing={existingString}
        merged={mergedString}
        setMerged={setMergedString}
      />
      <TextField
        value={mergedString}
        onChange={(e) => setMergedString(e.target.value)}
        hiddenLabel
        fullWidth
        multiline
        rows={50}
      />
    </Stack>
  );
};

const Merge = () => {
  const { transferId, companyId } = useParams();

  const {
    currentData: mergeDefinition,
    isFetching: isMergeDefinitionFetching,
    error,
    refetch,
  } = transferService.useRetrieveMergeDefinitionQuery({
    transferId,
    impersonateCompanyId: companyId,
  });

  const [
    _updateMergeDefinition,
    { isFetching: isUpdateMergeDefinitionFetching },
  ] = transferService.useUpdateMergeDefinitionMutation();

  const updateMergeDefinition = async (newMergeDefinition) => {
    await _updateMergeDefinition({
      mergeDefinition: newMergeDefinition,
      impersonateCompanyId: companyId,
    });
    refetch();
  };

  const isFetching =
    isMergeDefinitionFetching || isUpdateMergeDefinitionFetching;

  return (
    <Component
      title={"Merge"}
      options={
        <IconButton
          variant={"bright"}
          icon={ResetIcon}
          onClick={refetch}
          tooltip={"Reload the transfer"}
        />
      }
    >
      {isFetching ? (
        <BigLoader />
      ) : error ? (
        <Alert severity={"error"}>{error}</Alert>
      ) : (
        <Content
          companyId={companyId}
          mergeDefinition={mergeDefinition}
          updateMergeDefinition={updateMergeDefinition}
        />
      )}
    </Component>
  );
};

export default Merge;
