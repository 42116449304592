import * as actions from "../../../store/actions/input/program/programActions";
import Component from "../../Component";
import DefaultLoader from "../../common/DefaultLoader";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Disabled from "components/common/Disabled";
import React from "react";
import { connect } from "react-redux";
import * as staticDataHooks from "store/hooks/staticDataHooks";

const NO_SELECTION_KEY = "__NO_SELECTION__";

export const IndustryClassComponent = (props) => {
  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  return (
    <Component
      title={translateLabel("INDUSTRY_CLASS", { default: "Industry Class" })}
      subtitle={translateLabel("INDUSTRY_CLASS_SUBTITLE", {
        default:
          "The industry class determines which Exposure input metrics are appropriate.",
      })}
      {...props.componentProps}
    >
      {props.loadingIndustryClasses ? (
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            width: "100%",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          size={60}
        />
      ) : (
        <Disabled ifReadOnly>
          <FormControl variant={"filled"} fullWidth>
            <InputLabel className={"input-label"}>
              {translateLabel("INDUSTRY_CLASS", { default: "Industry Class" })}
            </InputLabel>
            <Select
              id={"industry-class-selector"}
              value={props.industryClass ?? NO_SELECTION_KEY}
              onChange={(event) => {
                const value =
                  event.target.value === NO_SELECTION_KEY
                    ? null
                    : event.target.value;
                props.updateIndustryClass(value);
              }}
              inputProps={{
                name: "program-inputs-industry-class",
                id: "program-inputs-industry-class",
              }}
              name={"industry-class"}
              className={"program-inputs-industry-class"}
            >
              {props.industryClasses.map((industryClass) => {
                return (
                  <MenuItem
                    key={`Industry_Class_${industryClass.key}`}
                    value={industryClass.key}
                  >
                    {industryClass.name}
                  </MenuItem>
                );
              })}
              <MenuItem key={"Industry_Class_NULL"} value={NO_SELECTION_KEY} />
            </Select>
          </FormControl>
        </Disabled>
      )}
    </Component>
  );
};

const mapDispatchToProps = {
  updateIndustryClass: actions.updateIndustryClass,
};

const mapStateToProps = (state) => {
  return {
    industryClass: state.input.program.industryClass,
    industryClasses: state.temp.exposureMetrics?.industryClassChoices || [],
    loadingIndustryClasses: state.temp.exposureMetrics?.loadingIndustryClasses,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndustryClassComponent);
