import Comment from "./Comment";
import * as support from "./CommentsSupport";
import { SendRounded as SendIcon } from "@mui/icons-material";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import DefaultLoader from "components/common/DefaultLoader";
import { useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as submissionsService from "services/submissionsService";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const BlankSlate = () => (
  <Box
    sx={{
      position: "absolute",
      top: "4rem",
      bottom: "4rem",
      left: 0,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Typography color={"gray"} maxWidth={"20ch"} textAlign={"center"}>
      {"Nobody's commented on this submission yet"}
    </Typography>
  </Box>
);

const Comments = ({ isPollingEnabled }) => {
  const submissionId = useSelector(persistenceSelectors.selectSubmissionId);

  const [firstLoad, setFirstLoad] = useState(true);
  const commentsRef = useRef(null);
  const inputRef = useRef(null);

  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const [showError, setShowError] = useState(false);

  const [text, setText] = useState("");
  const hasText = (text || "").trim() !== "";

  const {
    data,
    isLoading,
    refetch: refetchComments,
  } = submissionsService.useListCommentsQuery(
    { submissionId },
    {
      pollingInterval: 3000,
      skipPollingIfUnfocused: true,
      skip: !isPollingEnabled,
    }
  );

  const {
    data: config,
    isLoading: isConfigLoading,
  } = staticDataService.useConfigQuery("comments");

  const [createComment] = submissionsService.useCreateCommentMutation();

  const dataToDisplay = support.dataToDisplay(data ?? [], config);
  const showBlankSlate = dataToDisplay.length === 0;

  useEffect(() => {
    if ((data ?? []).length > 0 && firstLoad) {
      setFirstLoad(false);
      scrollToLatestComment({ animated: false });
    }
  }, [data, firstLoad]);

  const scrollToLatestComment = ({ delayMs = 10, animated = true } = {}) => {
    setTimeout(() => {
      commentsRef.current?.scrollTo({
        top: commentsRef.current?.scrollHeight,
        behavior: animated ? "smooth" : "instant",
      });
    }, delayMs);
  };

  const postComment = async () => {
    setIsCreatingComment(true);
    setShowError(false);
    try {
      const { error } = await createComment({
        submissionId,
        content: text,
        path: "",
        submissionVersionId: "",
      });
      if (!!error) {
        setShowError(true);
      } else {
        await refetchComments();
        scrollToLatestComment({ delayMs: 250 });
        setText("");
      }
    } finally {
      setIsCreatingComment(false);
      setTimeout(() => {
        inputRef.current.querySelector("textarea").focus();
      }, 200);
    }
  };

  return (
    <Box sx={{ minWidth: "23rem" }} elevation={4}>
      <Stack>
        {isLoading || isConfigLoading ? (
          <Stack alignItems={"center"}>
            <DefaultLoader
              color={"#dc7f4c"}
              size={50}
              style={{ marginTop: "2rem" }}
            />
          </Stack>
        ) : showBlankSlate ? (
          <BlankSlate />
        ) : (
          <Stack
            ref={commentsRef}
            sx={{
              position: "absolute",
              top: "3rem",
              bottom: 0,
              left: 0,
              right: 0,
              padding: "0 1rem 4rem 1rem",
              overflowY: "scroll",
            }}
          >
            {dataToDisplay.map((x, idx) => (
              <Comment key={idx} comment={x} />
            ))}
          </Stack>
        )}
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "#fcfdfd",
            borderTop: "1px solid #dce2eb",
            zIndex: 200,
          }}
        >
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
            sx={{ padding: showError ? "0.5rem 0.5rem 0 0.5rem" : "0.5rem" }}
          >
            <TextField
              ref={inputRef}
              value={text}
              fullWidth
              multiline
              size={"small"}
              sx={{ background: "white" }}
              placeholder={"Add a comment…"}
              disabled={isCreatingComment}
              onChange={(e) => setText(e.target.value)}
              onKeyDown={(e) => {
                if (hasText && (e.key === "Enter") & !e.shiftKey) {
                  e.preventDefault();
                  postComment();
                }
              }}
            />
            <Button
              variant={"contained"}
              color={"primary"}
              disableElevation
              onClick={() => postComment()}
              disabled={!hasText || isCreatingComment}
              sx={{
                minWidth: "40px",
                minHeight: "40px",
                padding: "6px",
                borderRadius: "50%",
              }}
            >
              {isCreatingComment ? (
                <Loader
                  type={"TailSpin"}
                  height={18}
                  width={18}
                  color={"gray"}
                  style={{ display: "flex" }}
                />
              ) : (
                <SendIcon />
              )}
            </Button>
          </Stack>
          {showError && (
            <Typography
              variant={"caption"}
              color={"error"}
              sx={{
                display: "block",
                maxWidth: "45ch",
                padding: "0.5rem 0.75rem 0.75rem 0.75rem",
                lineHeight: 1.375,
              }}
            >
              {
                "There was a problem saving changes to Marmalade, please try again in a moment"
              }
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Comments;
