import Disabled from "./Disabled";
import ExpandMenuIcon from "@mui/icons-material/ExpandMore";
import QuickSwitchIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as userSelectors from "store/selectors/user/userSelectors";

const selectMenuItems = (labels, filterVariant) => {
  const items = filterVariant
    ? [
        { statusKey: "ANY_STATUS", icon: "WILDCARD", displayName: "*" },
        { statusKey: "DIVIDER" },
      ]
    : [];
  (labels || []).forEach((label) => {
    items.push(label);
  });
  return items;
};

const StatusSelect = ({
  status,
  onChange,
  variant,
  disabled,
  showQuickSwitch,
}) => {
  const isUserReadOnly = useSelector(userSelectors.isUserReadOnly);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const compact = (variant || "").trim().toLowerCase() === "compact";
  const filter = (variant || "").trim().toLowerCase() === "filter";
  const { data: statusLabels, isLoading } = staticDataService.useConfigQuery(
    "layer_status_labels_v2"
  );
  const labels = isLoading ? [] : selectMenuItems(statusLabels?.labels, filter);
  const label = isLoading ? null : labels.find((x) => x.statusKey === status);

  return (
    <Stack
      className={`select-button ${compact ? "compact" : ""}`}
      sx={{ display: "inline-flex" }}
    >
      {!compact && (
        <Typography variant={"caption"} style={{ marginBottom: "2px" }}>
          {"Status"}
        </Typography>
      )}

      <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
        <Disabled ifReadOnly>
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={openMenu}
            disabled={disabled}
          >
            <StatusBadgeV2 meta={label} />
            <ExpandMenuIcon className={"expand-icon"} />
          </Button>
        </Disabled>
        {showQuickSwitch && label?.nextStatus && (
          <Tooltip title={"Quickly switch to next status"}>
            <QuickSwitchIcon
              className={!isUserReadOnly ? "hover-icon" : ""}
              onClick={(e) => {
                if (onChange && !isUserReadOnly) {
                  onChange(label.nextStatus);
                }
                e.stopPropagation();
              }}
            />
          </Tooltip>
        )}
      </Stack>
      <Menu
        anchorEl={anchorEl}
        open={anchorEl !== null}
        onClose={(_, reason) => {
          if (reason === "backdropClick") closeMenu();
        }}
      >
        {labels.map((label, index) => {
          return label.statusKey === "DIVIDER" ? (
            <Divider key={`${label.statusKey}_${index}`} />
          ) : (
            <MenuItem
              key={label.statusKey}
              selected={label.statusKey === status}
              onClick={() => {
                if (label.statusKey !== status && onChange) {
                  onChange(label.statusKey);
                }
                closeMenu();
              }}
            >
              <StatusBadgeV2 meta={label} />
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};

export default StatusSelect;
