import * as types from "../../../actions/temp/images/imagesActionTypes";

export const INIT_STATE = {
  cache: {},
};

const imagesReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case types.IMAGE_CACHED:
      return {
        ...state,
        cache: {
          ...(state.cache || {}),
          [action.payload.submissionId]: {
            ...(state.cache[action.payload.submissionId] || {}),
            [action.payload.imageId]: action.payload.url,
          },
        },
      };
    default:
      return state;
  }
};

export default imagesReducer;
