import {
  RECEIVED_ATTACHMENT_TREE,
  REQUEST_ATTACHMENT_TREE,
  REQUESTED_ATTACHMENT_TREE,
} from "./attachmentsTempActionTypes";

export const requestAttachmentTree = (fileId) => ({
  type: REQUEST_ATTACHMENT_TREE,
  payload: {
    fileId,
  },
});

export const attachmentTreeRequested = (fileId) => ({
  type: REQUESTED_ATTACHMENT_TREE,
  payload: {
    fileId,
  },
});

export const receivedAttachmentTree = (fileId, tree) => ({
  type: RECEIVED_ATTACHMENT_TREE,
  payload: {
    fileId,
    tree,
  },
});
