import * as colors from "@mui/material/colors";
import * as fileUtils from "fileUtils";
import { BsFileEarmarkExcelFill as ExcelIcon } from "react-icons/bs";
import { BsFillFileEarmarkFill as GenericFileIcon } from "react-icons/bs";
import { BsFillFileEarmarkPdfFill as PdfIcon } from "react-icons/bs";
import { BsFillFileEarmarkWordFill as WordIcon } from "react-icons/bs";
import { BsFillFileEarmarkZipFill as ZipIcon } from "react-icons/bs";
import { BsFillFileEarmarkPptFill as PowerPointIcon } from "react-icons/bs";
import { BsFillFileImageFill as ImageIcon } from "react-icons/bs";
import { BsFileEarmarkTextFill as TextIcon } from "react-icons/bs";

const defColours = {
  primary: colors.blueGrey[400],
  secondary: colors.blueGrey[50],
  background: "transparent",
};
const ordinaryColours = {
  primary: colors.blueGrey[400],
  secondary: colors.blueGrey[50],
  background: "transparent",
};
const imgColours = {
  primary: colors.amber[600],
  secondary: colors.amber[50],
  background: "transparent",
};
const spreadsheetColours = {
  primary: colors.green[600],
  secondary: colors.green[50],
  background: "transparent",
};
const docColours = {
  primary: colors.blue[600],
  secondary: colors.blue[50],
  background: "transparent",
};
const presColours = {
  primary: colors.deepOrange[500],
  secondary: colors.deepOrange[50],
  background: "transparent",
};
const pdfColours = {
  primary: colors.red[500],
  secondary: colors.red[50],
  background: "transparent",
};
const archColours = {
  primary: colors.blueGrey[400],
  secondary: colors.blueGrey[50],
  background: "transparent",
};

export const getIconFromMime = (mime) => {
  if (!mime) return GenericFileIcon;
  if (mime.startsWith("image/")) return ImageIcon;
  if (mime.startsWith("text/")) return TextIcon;
  if (fileUtils.spreadsheetMimeTypes.includes(mime)) return ExcelIcon;
  if (fileUtils.docMimeTypes.includes(mime)) return WordIcon;
  if (fileUtils.presMimeTypes.includes(mime)) return PowerPointIcon;
  if (fileUtils.pdfMimeTypes.includes(mime)) return PdfIcon;
  if (fileUtils.archMimeTypes.includes(mime)) return ZipIcon;
  return GenericFileIcon;
};

export class FileListTreeSupport {
  constructor(fileId, dirId, parentSupport, hidden, renamed, fileMetaNode) {
    this.isLoading = fileMetaNode.status === "LOADING";
    this.fileId = fileId;
    this.dirId = dirId;
    this.parentSupport = parentSupport;
    this.fileMeta = fileMetaNode.meta;
    this.extraction = fileMetaNode.extractionDefn;
    this.children = (fileMetaNode.childMetas || []).map(
      (child) =>
        new FileListTreeSupport(
          child.meta.id,
          dirId,
          parentSupport,
          hidden,
          renamed,
          child
        )
    );
    this.hidden = hidden;
    this.renamed = renamed;
  }

  isFilenameEditable() {
    if (this.isLoading) return false;
    return !this.parentSupport.noFileEditing;
  }

  previewable() {
    const viewType = fileUtils.getViewTypeFromMime(this.mimeType());
    if (viewType === "html" && !this.isHtmlReady()) {
      return null;
    }
    return viewType;
  }

  processHazardFile() {
    const ps = this.parentSupport;
    if (ps.processHazardFile) ps.processHazardFile(this.fileId);
  }

  canProcessHazardFile() {
    if (!this.isFileLoaded() || !this.isTabularFormat() || this.isNotReadable())
      return false;
    return !!this.parentSupport.processHazardFile;
  }

  processLossFile() {
    if (this.isLoading) return "... loading";
    const ps = this.parentSupport;
    if (ps.processLossFile)
      ps.processLossFile(this.fileId, this.fileMeta.originalFilename);
  }

  processClaimsV2LossFile() {
    if (!this.isLoading) {
      const ps = this.parentSupport;
      if (ps.processClaimsV2LossFile)
        ps.processClaimsV2LossFile(this.fileId, this.filename());
    }
  }

  canProcessLossFile() {
    if (
      !this.isFileLoaded() ||
      !this.isTabularFormat() ||
      this.isNotReadable() ||
      this.ext() === "xls"
    )
      return false;
    return !!this.parentSupport.processLossFile;
  }

  canProcessClaimsV2LossFile() {
    if (!this.isFileLoaded() || !this.isTabularFormat() || this.isNotReadable())
      return false;
    return !!this.parentSupport.processClaimsV2LossFile;
  }

  markAttachmentHidden() {
    const ps = this.parentSupport;
    if (ps.markAttachmentHidden)
      ps.markAttachmentHidden(this.fileId, this.dirId);
  }

  canHide() {
    return !!this.parentSupport.markAttachmentHidden;
  }
  setAttachmentName(newName) {
    const ps = this.parentSupport;
    if (ps.setAttachmentName)
      ps.setAttachmentName(this.fileId, this.dirId, newName);
  }

  fileHidden() {
    return this.hidden[this.fileId] || false;
  }

  isFileLoadingFailed() {
    if (this.isLoading) return false;
    return this.fileMeta.status === "NOT_VALID";
  }
  isFileLoaded() {
    if (this.isLoading) return false;
    return this.fileMeta.status === "LOADED";
  }
  isFileLoading() {
    return (
      this.isLoading ||
      ["NEW", "INITIALISED", "LOADING"].includes(this.fileMeta.status)
    );
  }
  isFileExpanding() {
    if (this.isLoading) return false;
    return ["NEW", "INITIALISED", "LOADING"].includes(
      this.fileMeta.expansionStatus
    );
  }
  hasFileLoadErrored() {
    if (this.isLoading) return false;
    return this.fileMeta.status === "NOT_VALID";
  }

  isNotReadable() {
    if (this.isLoading) return false;
    return (
      this.fileMeta.expansionStatus === "NOT_READABLE" ||
      this.fileMeta.htmlStatus === "NOT_READABLE" ||
      this.fileMeta.textStatus === "NOT_READABLE" ||
      fileUtils.encyptedMimeTypes.includes(this.mimeType())
    );
  }
  isHtmlReady() {
    return this.isFileLoaded() && "LOADED" === this.fileMeta.htmlStatus;
  }

  isHtmlNotAvailable() {
    if (this.isLoading) return false;
    return (
      this.isFileLoadingFailed() ||
      (this.isFileLoaded() &&
        ["NOT_AVAILABLE", "NOT_READABLE"].includes(this.fileMeta.htmlStatus))
    );
  }

  hasHtmlLoadFailed() {
    if (this.isLoading) return false;
    return (
      this.isFileLoadingFailed() ||
      (this.isFileLoaded() &&
        ["NOT_VALID", "CONVERSION_FAILED"].includes(this.fileMeta.htmlStatus))
    );
  }

  /** return CLEANING, CLEANED, CLEANABLE or NOT_AVAILABLE */
  microsoftFileCleanStatus() {
    if (this.isLoading || this.parentSupport.noFileEditing)
      return "NOT_AVAILABLE";
    return this.fileMeta.cleaningStatus;
  }

  mimeType() {
    if (this.isLoading) return "text/plain";
    return this.fileMeta.mimeType;
  }
  getColours() {
    const mime = this.mimeType();
    if (!mime) return defColours;
    if (mime.startsWith("image/")) return imgColours;
    if (mime.startsWith("text/")) return ordinaryColours;
    if (fileUtils.spreadsheetMimeTypes.includes(mime))
      return spreadsheetColours;
    if (fileUtils.docMimeTypes.includes(mime)) return docColours;
    if (fileUtils.presMimeTypes.includes(mime)) return presColours;
    if (fileUtils.pdfMimeTypes.includes(mime)) return pdfColours;
    if (fileUtils.archMimeTypes.includes(mime)) return archColours;
    return defColours;
  }

  getIcon() {
    const mime = this.mimeType();
    return getIconFromMime(mime);
  }

  refresh() {
    if (this.parentSupport) {
      if (typeof this.parentSupport.initiateRefresh === "function") {
        this.parentSupport.initiateRefresh(this.fileId);
      }
    }
  }

  getFileId() {
    return this.fileId;
  }

  getDirId() {
    return this.dirId;
  }

  filename() {
    const ren = this.renamed?.[this.fileId] || null;
    if (ren) return ren;
    const path = this.extraction?.path ?? "";
    const original = this.originalFilename();
    if (path) return `${path}/${original}`;
    return original;
  }

  ext() {
    if (this.isLoading) return "";
    return this.fileMeta.originalExtension;
  }

  originalFilename() {
    if (this.isLoading) return "...Loading";
    return this.fileMeta.originalFilename;
  }

  isLegacyMicrosoftFile() {
    return fileUtils.legacyMicrosoft.includes(this.mimeType());
  }

  isTabularFormat() {
    return fileUtils.tabularMimeTypes.includes(this.mimeType());
  }

  metadata() {
    return this.fileMeta;
  }

  getUniqueId() {
    return `file-${this.dirId}-${this.fileId}`;
  }

  moveFileToDirectory(toDirId) {
    const embeddedFiles = this.parentSupport.embeddedFiles(this.fileId) || [];
    this.parentSupport.moveFileToDirectory(
      this.fileId,
      this.dirId,
      toDirId,
      embeddedFiles
    );
  }

  moveFileToFile(toDirId) {
    const embeddedFiles = this.parentSupport.embeddedFiles(this.fileId) || [];
    this.parentSupport.moveFileToFile(
      this.fileId,
      this.dirId,
      toDirId,
      embeddedFiles
    );
  }

  hasFile(id) {
    const localHasFile = (flts) => {
      if (flts.fileId === id) return true;
      for (const i in flts.children) {
        if (localHasFile(flts.children[i])) return true;
      }
      return false;
    };
    const fnd = localHasFile(this);
    return fnd;
  }
}
