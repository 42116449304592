import * as numbers from "../../common/numbers";
import TextField from "@mui/material/TextField";

const NumberTextField = ({ onChange, ...props }) => {
  return (
    <TextField
      {...props}
      type={"text"}
      onChange={(e) => {
        if (onChange) {
          const newValue = e.target.value;
          if (numbers.isNumberPrefix(newValue)) {
            onChange(newValue);
          }
        }
      }}
    />
  );
};

export default NumberTextField;
