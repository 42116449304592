import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import BigLoader from "components/common/BigLoader";
import * as config from "config";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import * as userActions from "store/actions/user/userActions";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    signOut().then(() => {
      dispatch(userActions.logoutUser());
      navigate("/");
      navigate(0);
    });
  }, [dispatch, navigate]);

  return <BigLoader />;
};

const Validator = ({ children }) => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: config.COGNITO_USER_POOL_ID,
        userPoolClientId: config.COGNITO_USER_POOL_CLIENT_ID,
        loginWith: {
          email: true,
          phone: false,
          username: false,
        },
        passwordFormat: {
          minLength: 16,
          requireLowercase: true,
          requireUppercase: true,
          requireNumbers: true,
          requireSpecialCharacters: false,
        },
      },
    },
  });
  return (
    <Authenticator hideSignUp variation={"modal"}>
      {children}
    </Authenticator>
  );
};

const AmplifyAuthenticator = ({ children }) => {
  return (
    <Routes>
      <Route path={"logout"} element={<Logout />} />
      <Route path={"*"} element={<Validator children={children} />} />
    </Routes>
  );
};

export default AmplifyAuthenticator;
