export const selectMaintenanceStatus = (state) => state.maintenanceStatus;

export const selectIsDisabled = (state) =>
  state.maintenanceStatus.disableNow ||
  state.maintenanceStatus.changeCompleted ||
  state.maintenanceStatus.forceReload ||
  state.maintenanceStatus.versionChanged;

export const selectIsShowingWarning = (state) =>
  state.maintenanceStatus.showWarningNow &&
  !state.maintenanceStatus.warningClosed;

export const selectMessage = (state) => state.maintenanceStatus.message;

export const selectIsChangeComplete = (state) =>
  state.maintenanceStatus.changeCompleted ||
  state.maintenanceStatus.forceReload;
