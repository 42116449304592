import CircleIcon from "@mui/icons-material/Circle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const getColour = (rag) => {
  switch (rag.trim().toLowerCase()) {
    case "r":
      return "crimson";
    case "a":
      return "orange";
    case "g":
      return "mediumseagreen";
    default:
      return "lightslategrey";
  }
};

const getBackground = (rag) => {
  switch (rag.trim().toLowerCase()) {
    case "r":
      return "mistyrose";
    case "a":
      return "antiquewhite";
    case "g":
      return "honeydew";
    default:
      return "whitesmoke";
  }
};

const stackStyle = (rag, variant) => {
  if ((variant || "").toLowerCase() !== "filled") {
    return {};
  }

  return {
    background: getBackground(rag),
    padding: "4px",
    borderRadius: "4px",
  };
};

const labelStyle = () => {
  return {
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: "0.5px",
  };
};

const RagIcon = ({ rag }) => (
  <CircleIcon sx={{ color: getColour(rag), fontSize: "1.375rem" }} />
);

const RagBadge = ({ rag, title, variant }) => {
  if (!title) {
    return <RagIcon rag={rag} />;
  }

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      spacing={"5px"}
      style={stackStyle(rag, variant)}
    >
      <RagIcon rag={rag} />
      <Typography variant={"caption"} sx={labelStyle}>
        {title}
      </Typography>
    </Stack>
  );
};

export default RagBadge;
