import IncludedLossTypes from "./IncludedLossTypes";
import { FormControl, Stack } from "@mui/material";
import { validatedDateStr } from "common/dates";
import Component from "components/Component";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import Visible from "components/common/Visible";
import ClaimsChart from "components/inputs/claims/ClaimsChart";
import FilteredClaims from "components/inputs/claims/FilteredClaims";
import InflationModelSelector from "components/inputs/claims/InflationModelSelector";
import * as claims from "domain/claims";
import { connect, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as pricingActions from "store/actions/pricing/pricingActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const SummaryCharts = ({ claimsQuery }) => {
  const { currentData, isFetching, error } = claimsService.useSummaryChartQuery(
    claimsQuery
  );
  return (
    <>
      <ClaimsChart
        title={"Loss Frequency"}
        subtitle={"Number of losses per year"}
        chart={currentData?.frequency}
        loading={isFetching}
        error={error}
        dataName={"Frequency"}
      />
      <ClaimsChart
        title={"Loss Severity"}
        subtitle={"Maximum loss in a year"}
        chart={currentData?.severity}
        dataName={"Severity"}
        loading={isFetching}
        error={error}
        ultimateName={"Development & Inflation"}
      />
      <ClaimsChart
        title={"Loss Aggregate"}
        subtitle={"Total loss per year"}
        chart={currentData?.aggregate}
        loading={isFetching}
        error={error}
        dataName={"Aggregate"}
        ultimateName={"Development & Inflation"}
      />
    </>
  );
};

const AllClaimsChart = ({ claimsQuery, updateClaimsDateRange }) => {
  const claimsAbove = useSelector(claimsSelectors.selectClaimsThreshold);
  const { currentData } = claimsService.useAllClaimsChartQuery({
    claimsQuery,
    claimsAbove,
  });
  return (
    <FilteredClaims
      data={currentData}
      disabled
      onZoom={(start, end) => {
        const makeDateFromMonths = (months) => {
          if (months == null) {
            return null;
          }
          const month = Math.floor(months % 12) + 1;
          const year = Math.floor(months / 12);
          return `${year}-${String(month).padStart(2, "0")}-01`;
        };
        updateClaimsDateRange({
          start: makeDateFromMonths(start),
          end: makeDateFromMonths(end),
        });
      }}
      clearZoomLabel={"Clear Filter"}
    />
  );
};

const validDate = (v) => {
  if (v == null || validatedDateStr(v) == null) {
    return false;
  }
  const year = v.substring(0, 4);
  return year > 1899 && year <= new Date().getFullYear();
};

const normalizeDate = (v) => (v == null || v === "" ? null : v);

const DateRangeSelector = ({ start, end, onChange }) => {
  return (
    <FormControl variant={"filled"} style={{ minWidth: "200px" }}>
      <Stack direction={"row"} spacing={1.5}>
        <FormattedDatePicker
          label={"Start"}
          onChangeDate={(date) => {
            const newStart = normalizeDate(date);
            const revisedEnd =
              validDate(newStart) && newStart > end ? newStart : end;
            onChange([newStart, revisedEnd]);
          }}
          value={start ?? ""}
        />
        <FormattedDatePicker
          label={"End"}
          onChangeDate={(date) => {
            const newEnd = normalizeDate(date);
            const revisedStart =
              validDate(newEnd) && newEnd < start ? newEnd : start;
            onChange([revisedStart, newEnd]);
          }}
          value={end ?? ""}
        />
      </Stack>
    </FormControl>
  );
};

const ClaimsSelector = (props) => {
  const pricingClaimsQuery = useDebouncedValue(
    useSelector(pricingSelectors.selectPricingClaims)
  );
  return (
    <>
      <Component title={"Model Settings"}>
        <Stack direction={"row"} spacing={1.5}>
          <InflationModelSelector />
          <DateRangeSelector
            start={props.start}
            end={props.end}
            onChange={([start, end]) => {
              props.updateClaimsDateRange({ start, end });
            }}
          />
        </Stack>
      </Component>
      <Visible
        byTag={"pricing.claimsSelector.includedLossTypes"}
        defaultVisible={false}
      >
        <IncludedLossTypes />
      </Visible>
      {claims.isClaimsQueryEmpty(pricingClaimsQuery) || (
        <>
          <AllClaimsChart
            claimsQuery={pricingClaimsQuery}
            updateClaimsDateRange={props.updateClaimsDateRange}
          />
          <SummaryCharts claimsQuery={pricingClaimsQuery} />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  updateClaimsDateRange: pricingActions.updateClaimsDateRange,
};

const mapStateToProps = (state) => ({
  ...pricingSelectors.selectClaimsDateRange(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsSelector);
