import { tabularMimeTypes } from "../../../fileUtils";
import FilesFileUpload from "components/FilesFileUpload";
import Notes from "components/common/Notes";
import SICDownloader from "components/inputs/sic/SICDownloader";
import SICFileUploadDialog from "components/inputs/sic/SICFileUploadDialog";
import SICSummary from "components/inputs/sic/SICSummary";
import React from "react";
import { connect } from "react-redux";
import * as hazardActions from "store/actions/input/exposure/hazardActions";

export const SICComponent = (props) => {
  const onDrop = (id, file) => {
    props.uploadFile(id, file);
  };

  return (
    <>
      {(props.hazardProfile?.files ?? []).length > 0 && (
        <SICSummary hazardSummary={props.hazardSummary ?? null} />
      )}
      <SICFileUploadDialog />
      <FilesFileUpload
        onDrop={onDrop}
        size={{ md: 4 }}
        asAtDateNotRequired={true}
        oneAtATime={true}
        waitText={true}
        mimeTypes={tabularMimeTypes}
      />
      <SICDownloader />
      <Notes
        md_width={12}
        lg_width={6}
        update={props.updateNote}
        value={props.hazardProfile?.note}
      />
    </>
  );
};

const mapDispatchToProps = {
  updateNote: hazardActions.updateHazardNote,
  uploadFile: hazardActions.uploadFile,
};

const mapStateToProps = (state) => {
  return {
    hazardProfile: state?.input?.exposure?.hazardProfile ?? null,
    hazardSummary: state?.temp?.exposure?.hazardSummary ?? null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SICComponent);
