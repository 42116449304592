import {
  AddBox as AddBoxIcon,
  Delete as DeleteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import IconButton from "components/common/IconButton";
import TextField from "components/common/TextField";
import flags from "flags";
import { useState, Fragment } from "react";

const CustomKeyInput = ({ onAddKey }) => {
  const [value, setValue] = useState("");
  return (
    <TextField
      hiddenLabel
      placeholder={"Press <Enter> to add"}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onAddKey(value);
          setValue("");
        }
      }}
    />
  );
};

const OnStateRow = ({
  title,
  component,
  type,
  stateVisibilities,
  onEditStateVisibility,
  noBottomBorder,
}) => {
  return (
    <TableRow>
      <TableCell style={{ borderBottom: noBottomBorder ? "none" : "" }}>
        {title}
      </TableCell>
      <TableCell>
        <Stack direction={"column"}>
          {[...(stateVisibilities?.[component]?.[type] ?? []), ["", []]].map(
            ([key, values], i) => {
              return (
                <Stack direction={"row"} key={i}>
                  <TextField
                    variant={"filled"}
                    size={"small"}
                    value={key}
                    onChange={(e) => {
                      onEditStateVisibility(
                        component,
                        type,
                        i,
                        e.target.value,
                        values
                      );
                    }}
                  />
                  <div
                    style={{
                      height: "50px",
                      minWidth: "120px",
                      textAlign: "center",
                      lineHeight: "50px",
                    }}
                  >
                    {i === 0 && noBottomBorder && "Values"}
                  </div>
                  <Stack direction={"column"}>
                    {[...(values ?? []), ""].map((value, index1, arr) => {
                      return (
                        <TextField
                          size={"small"}
                          value={value}
                          style={{ paddingBottom: "10px" }}
                          key={index1}
                          onChange={(e) => {
                            onEditStateVisibility(component, type, i, key, [
                              ...(values ?? [])
                                .map((v, index2) =>
                                  index1 === index2 ? e.target.value : v
                                )
                                .filter((v) => v !== null && v.length > 0),
                              ...(index1 === arr.length - 1
                                ? [e.target.value]
                                : []),
                            ]);
                          }}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
              );
            }
          )}
        </Stack>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

const DictionaryEditor = ({
  dict,
  stateVisibilities,
  onEditKey,
  onDeleteKey,
  onEditStateVisibility,
  keyName = "key",
  valueName = "value",
  searchText = "",
}) => {
  const [editing, setEditing] = useState({});
  const knownFlags = Object.fromEntries(flags.visible.map((f) => [f.tag, f]));

  const tags = [
    ...new Set([...flags.visible.map((f) => f.tag), ...Object.keys(dict)]),
  ].sort((x, y) => {
    if (knownFlags[x] == null && knownFlags[y] == null) {
      return x.localeCompare(y);
    }
    if (knownFlags[x] == null) {
      return 1;
    }
    if (knownFlags[y] == null) {
      return -1;
    }
    const priorityX = knownFlags[x]?.priority ?? 0;
    const priorityY = knownFlags[y]?.priority ?? 0;
    if (priorityX !== priorityY) {
      return priorityY - priorityX;
    }
    return (knownFlags[x].displayName ?? x).localeCompare(
      knownFlags[y].displayName ?? y
    );
  });

  const tagsToDisplay =
    searchText === ""
      ? tags
      : tags.filter((x) => {
          const meta = knownFlags[x];
          return (meta?.displayName ?? x).toLowerCase().includes(searchText);
        });

  return (
    <TableContainer>
      <Table size={"small"} className={"flags"}>
        <TableHead>
          <TableRow>
            <TableCell width={"15%"} />
            <TableCell width={"65%"}>{keyName}</TableCell>
            <TableCell width={"20%"}>{valueName}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tagsToDisplay.map((key) => {
            const value = dict[key];
            const controlled = dict[key] != null;
            const meta = knownFlags[key];
            return (
              <Fragment key={key}>
                <TableRow sx={meta ? {} : { border: 1, borderColor: "red" }}>
                  <TableCell
                    style={{ borderBottom: editing[key] ? "none" : "" }}
                  >
                    {controlled ? (
                      <Stack direction={"row"}>
                        <IconButton
                          icon={DeleteIcon}
                          onClick={() => onDeleteKey(key)}
                        />
                        {editing[key] ? (
                          <IconButton
                            icon={ExpandLessIcon}
                            onClick={() => {
                              setEditing({ ...editing, [key]: false });
                            }}
                          />
                        ) : (
                          <IconButton
                            icon={ExpandMoreIcon}
                            onClick={() => {
                              setEditing({ ...editing, [key]: true });
                            }}
                          />
                        )}
                      </Stack>
                    ) : (
                      <IconButton
                        icon={AddBoxIcon}
                        onClick={() => onEditKey(key, true)}
                      />
                    )}
                  </TableCell>
                  <Tooltip title={meta?.tooltip ?? ""}>
                    <TableCell
                      style={{ borderBottom: editing[key] ? "none" : "" }}
                    >
                      {meta?.displayName ?? key}
                    </TableCell>
                  </Tooltip>
                  <TableCell
                    style={{ borderBottom: editing[key] ? "none" : "" }}
                  >
                    {controlled && (
                      <Switch
                        checked={value}
                        onChange={() => onEditKey(key, !value)}
                      />
                    )}
                  </TableCell>
                </TableRow>
                {editing[key] ? (
                  <>
                    <OnStateRow
                      title={"Show On State"}
                      component={key}
                      type={"showOnState"}
                      stateVisibilities={stateVisibilities}
                      onEditStateVisibility={onEditStateVisibility}
                      noBottomBorder={true}
                    />
                    <OnStateRow
                      title={"Hide On State"}
                      component={key}
                      type={"hideOnState"}
                      stateVisibilities={stateVisibilities}
                      onEditStateVisibility={onEditStateVisibility}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Fragment>
            );
          })}
          <TableRow>
            <TableCell />
            <TableCell>
              <CustomKeyInput onAddKey={(key) => onEditKey(key, true)} />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DictionaryEditor;
