import attachmentsReducer from "./attachments/attachmentsReducer";
import claimsReducer from "./claims/claimsReducer";
import expiringProgramReducer from "./expiringProgram/expiringProgramReducer";
import exposureReducer from "./exposure/exposureReducer";
import flagsReducer from "./flags/flagsReducer";
import imagesReducer from "./images/imagesReducer";
import programReducer from "./program/programReducer";
import scenariosReducer from "./scenarios/scenariosReducer";
import { combineReducers } from "redux";

export default combineReducers({
  program: programReducer,
  claims: claimsReducer,
  exposure: exposureReducer,
  flags: flagsReducer,
  expiringProgram: expiringProgramReducer,
  scenarios: scenariosReducer,
  attachments: attachmentsReducer,
  images: imagesReducer,
});
