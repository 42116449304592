import * as types from "./programActionTypes";
import * as exposureActions from "store/actions/input/exposure/exposureActions";

export const updateProgram = (values) => ({
  type: types.UPDATE_PROGRAM,
  payload: values,
});

export const updateInsured = (insuredName) => ({
  type: types.UPDATE_INSURED_NAME,
  payload: insuredName,
});

export const updateRiskDescription = (riskDescription) => ({
  type: types.UPDATE_RISK_DESCRIPTION,
  payload: riskDescription,
});

export const updateInception = (inception) => ({
  type: types.UPDATE_INCEPTION,
  payload: inception,
});

export const updateExpiration = (expiration) => ({
  type: types.UPDATE_EXPIRATION,
  payload: expiration,
});

export const updateProRataAdjustment = (adjustment) => ({
  type: types.UPDATE_PRO_RATA_ADJUSTMENT,
  payload: adjustment,
});

export const updateReference = (reference) => ({
  type: types.UPDATE_REFERENCE,
  payload: reference,
});

export const updateWhetherInUS = (inUS) => ({
  type: types.UPDATE_WHETHER_IN_US,
  payload: inUS,
});

export const updateTurnover = (turnover) => ({
  type: types.UPDATE_TURNOVER,
  payload: turnover,
});

export const updateModel = (model) => ({
  type: types.UPDATE_MODEL,
  payload: model,
});

export const updateBrokerage = (brokerage) => ({
  type: types.UPDATE_BROKERAGE,
  payload: brokerage,
});

export const updateStateOfFiling = (state) => ({
  type: types.UPDATE_STATE_OF_FILING,
  payload: state,
});

export const updateRequestedBrokerage = (brokerage) => ({
  type: types.UPDATE_REQUESTED_BROKERAGE,
  payload: brokerage,
});

export const updateOtherAcquisitionCosts = (costs) => ({
  type: types.UPDATE_OTHER_ACQUISITION_COSTS,
  payload: costs,
});

export const updateProfit = (profit) => ({
  type: types.UPDATE_PROFIT,
  payload: profit,
});

export const updateExpenses = (expenses) => ({
  type: types.UPDATE_EXPENSES,
  payload: expenses,
});

export const updateIndustryClass = (industryCategory) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.UPDATE_INDUSTRY_CLASS,
    payload: industryCategory,
  });
  dispatch(exposureActions.updateExposureMeasures([industryCategory]));
};

export const updateInsuredAddress = (address) => ({
  type: types.UPDATE_INSURED_ADDRESS,
  payload: address,
});

export const updatePriorSubmission = (priorSubmissionFilename) => ({
  type: types.UPDATE_PRIOR_SUBMISSION,
  payload: priorSubmissionFilename,
});

export const updateCurrency = (currency) => ({
  type: types.UPDATE_CURRENCY,
  payload: currency,
});

export const updateReassured = (reassured) => ({
  type: types.UPDATE_REASSURED,
  payload: reassured,
});

export const updatePaymentTerms = (paymentTerms) => ({
  type: types.UPDATE_PAYMENT_TERMS,
  payload: paymentTerms ?? null,
});

export const addDuns = (duns) => ({
  type: types.ADD_DUNS,
  payload: duns,
});

export const removeDuns = (duns) => ({
  type: types.REMOVE_DUNS,
  payload: duns,
});

export const clearCompanyNaics = () => ({
  type: types.CLEAR_COMPANY_NAICS,
});

export const updateNotes = (notes) => ({
  type: types.UPDATE_PROGRAM_NOTES,
  payload: notes,
});

export const updateRiskSummary = (summary) => ({
  type: types.UPDATE_RISK_SUMMARY,
  payload: summary,
});

export const updateDataQuality = (quality) => ({
  type: types.UPDATE_DATA_QUALITY,
  payload: quality,
});
