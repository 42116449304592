import {
  AddRounded as AddIcon,
  CloseRounded as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import * as logger from "common/logger";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import * as config from "config";
import { useSnackbar } from "notistack";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as notesService from "services/notesService";
import * as programSelectors from "store/selectors/input/program/programSelectors";

const useNotifyError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notifyError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      autoHideDuration: 10_000,
      action: (messageId) => (
        <Button
          color={"error"}
          size={"small"}
          onClick={() => closeSnackbar(messageId)}
        >
          {"Dismiss"}
        </Button>
      ),
    });
  };

  return { notifyError };
};

const Controls = ({ addNote, isAddingNote }) => {
  return (
    <Grid item container xs={12}>
      <Stack direction={"column"} alignItems={"center"} sx={{ width: "100%" }}>
        <Button
          startIcon={<AddIcon />}
          sx={{
            borderRadius: 15,
          }}
          isLoading={isAddingNote}
          onClick={addNote}
        >
          {"Add Note"}
        </Button>
      </Stack>
    </Grid>
  );
};

const BlankSlate = ({ addNote, isAddingNote }) => (
  <Grid item container xs={12} direction={"column"} alignItems={"center"}>
    <Box
      sx={{
        width: "50vw",
        background: "white",
        border: "4px solid lightgray",
        borderRadius: 3,
        padding: "50px",
      }}
    >
      <Stack
        direction={"column"}
        sx={{ width: "100%" }}
        alignItems={"center"}
        spacing={2}
      >
        <Typography color={"gray"} sx={{ whiteSpace: "nowrap" }}>
          {"Nobody's added any meeting notes yet"}
        </Typography>
        <Button
          sx={{
            borderRadius: 15,
          }}
          isLoading={isAddingNote}
          onClick={addNote}
        >
          {"Add the first note"}
        </Button>
      </Stack>
    </Box>
  </Grid>
);

const Note = ({
  submissionId,
  noteId,
  deleteNote,
  setIsUpdating,
  setIsEditing,
}) => {
  const { notifyError } = useNotifyError();

  const {
    currentData: note,
    isFetching,
    isError,
    refetch,
  } = notesService.useRetrieveNoteQuery({
    submissionId,
    noteId,
  });

  const [
    _updateNote,
    { isLoading: isUpdating },
  ] = notesService.useUpdateNoteMutation();

  const updateNote = async (content, title) => {
    try {
      setIsUpdating(true);
      const response = await _updateNote({
        submissionId,
        noteId,
        title,
        content,
      });
      if (response.error) {
        throw new Error(`Could not update note: ${response}`);
      }
      refetch();
    } catch (e) {
      logger.exception(e);
      notifyError(
        `Error updating note. Please retry or contact ${config.SUPPORT_EMAIL}.`
      );
      throw new Error("Could not update note");
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Notes
      value={note?.content ?? ""}
      title={note?.title ?? ""}
      isTitleEditable={true}
      update={updateNote}
      loading={isFetching || isUpdating || isError}
      onDelete={() => deleteNote({ noteId })}
      onEnterEditMode={() => setIsEditing(true)}
      onExitEditMode={() => setIsEditing(false)}
    />
  );
};

const Content = ({ setIsEditing, setIsUpdating }) => {
  const { submissionId } = useParams();

  const { notifyError } = useNotifyError();

  const {
    data: _notes,
    isLoading: isLoadingNotes,
    isError: isErrorListingNotes,
    refetch: refetchNotes,
  } = notesService.useListNotesQuery({
    submissionId,
  });

  const [optimisticallyDeleted, setOptimisticallyDeleted] = useState({});

  const [_deleteNote] = notesService.useDeleteNoteMutation();

  const deleteNote = async ({ noteId }) => {
    try {
      setOptimisticallyDeleted((current) => ({ ...current, [noteId]: true }));
      const response = await _deleteNote({
        submissionId,
        noteId,
      });
      if (response.error) {
        logger.error(response.error);
        notifyError(
          `Error deleting note. Please retry or contact ${config.SUPPORT_EMAIL}.`
        );
      }
      await refetchNotes();
    } catch (e) {
      logger.exception(e);
      notifyError(
        `Error deleting note. Please retry or contact ${config.SUPPORT_EMAIL}.`
      );
    } finally {
      setOptimisticallyDeleted((current) => {
        const { [noteId]: _, ...rest } = current;
        return rest;
      });
    }
  };

  const [_createNote] = notesService.useCreateNoteMutation();
  const [isAddingNote, setIsAddingNote] = useState(false);

  const addNote = async () => {
    try {
      setIsAddingNote(true);
      const response = await _createNote({
        submissionId,
        title: "",
        content: "",
      });
      if (response.error) {
        logger.error(response.error);
        notifyError(
          `Error adding a new note. Please retry or contact ${config.SUPPORT_EMAIL}.`
        );
      } else {
        await refetchNotes();
      }
    } catch (e) {
      logger.exception(e);
      notifyError(
        `Error adding a new note. Please retry or contact ${config.SUPPORT_EMAIL}.`
      );
    } finally {
      setIsAddingNote(false);
    }
  };

  const notes = useMemo(
    () =>
      [...(_notes ?? [])]
        .filter(({ noteId }) => !optimisticallyDeleted[noteId])
        .sort((a, b) => b.createTime?.localeCompare(a.createTime)),
    [_notes, optimisticallyDeleted]
  );

  if (isLoadingNotes || isErrorListingNotes) {
    return (
      <Grid item container xs={12} direction={"column"} alignItems={"center"}>
        <BigLoader />
      </Grid>
    );
  } else if (notes.length === 0) {
    return <BlankSlate addNote={addNote} isAddingNote={isAddingNote} />;
  } else {
    return (
      <>
        <Controls addNote={addNote} isAddingNote={isAddingNote} />
        {notes.map(({ noteId }) => (
          <Note
            key={noteId}
            submissionId={submissionId}
            noteId={noteId}
            deleteNote={deleteNote}
            setIsEditing={setIsEditing(noteId)}
            setIsUpdating={setIsUpdating(noteId)}
          />
        ))}
      </>
    );
  }
};

const MeetingNotes = ({ isOpen, onClose }) => {
  const program = useSelector(programSelectors.selectResolvedProgram);

  const [isUpdatingByNoteId, setIsUpdatingByNoteId] = useState({});
  const [isEditingByNoteId, setIsEditingByNoteId] = useState({});

  const setIsUpdating = (noteId) => (isUpdating) =>
    setIsUpdatingByNoteId({
      ...isUpdatingByNoteId,
      [noteId]: isUpdating,
    });

  const setIsEditing = (noteId) => (isEditing) =>
    setIsEditingByNoteId({
      ...isEditingByNoteId,
      [noteId]: isEditing,
    });

  const makeSubmissionName = () => {
    const insured = program?.insured;
    if (!insured) {
      return "Unknown Submission";
    }
    const inception = program?.inception;
    return inception ? `${insured} (${inception})` : insured;
  };

  const anyNotesEditing = Object.values(isEditingByNoteId).some(
    (isEditing) => isEditing
  );
  const anyNotesUpdating = Object.values(isUpdatingByNoteId).some(
    (isUpdating) => isUpdating
  );

  return (
    <Dialog
      open={isOpen}
      fullScreen
      onClose={() => {}}
      sx={{
        padding: 5,
      }}
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant={"h4"}
          >{`Meeting Notes - ${makeSubmissionName()}`}</Typography>
          <IconButton
            icon={CloseIcon}
            onClick={onClose}
            disabled={anyNotesEditing || anyNotesUpdating}
          />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Content setIsUpdating={setIsUpdating} setIsEditing={setIsEditing} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MeetingNotes;
