import React from "react";

class ShortcutListener extends React.Component {
  shortcutActive = (e) => {
    if (e.key !== this.props.shortcut.toLowerCase()) {
      return false;
    }
    const meta =
      navigator.platform.startsWith("Mac") || navigator.platform === "iPad";
    return meta ? e.metaKey : e.ctrlKey;
  };
  handleKeyDown = (e) => {
    if (this.shortcutActive(e)) {
      if (!this.props.disabled && !this.hasFiredSinceLastKeyUp) {
        this.props.action();
        this.hasFiredSinceLastKeyUp = true;
      }
      e.preventDefault();
    }
  };
  handleKeyUp = () => {
    // There is a peculiarity on Mac that `keyup` is not fired if the Meta key is held
    // (see, eg, https://github.com/electron/electron/issues/5188). Ideally, we would
    // listen just for the shortcut. But it can be that that doesn't happen. So instead
    // we listen for any keyup. This might mean the action is trigger more often than
    // ideal, but probably not a big deal (certainly for the current Ctrl + S use-case).
    this.hasFiredSinceLastKeyUp = false;
  };
  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("keyup", this.handleKeyUp);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("keyup", this.handleKeyUp);
  }
  render() {
    return null;
  }
}

export default ShortcutListener;
