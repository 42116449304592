import Button from "@mui/material/Button";
import DefaultLoader from "components/common/DefaultLoader";
import Disabled from "components/common/Disabled";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";

export const UpdateLossFrequencyButton = (props) => {
  return (
    <Disabled ifReadOnly>
      <Button
        onClick={() => props.recalculateInsuredExpectedEvents()}
        disableElevation
        variant={"contained"}
        color={"primary"}
        className={"button button-fixed-width"}
      >
        {props.loadingInsuredExpectedEvents ? (
          <DefaultLoader
            style={{
              display: "inline-block",
              position: "relative",
              top: "3px",
            }}
            size={16}
          />
        ) : (
          "Update"
        )}
      </Button>
    </Disabled>
  );
};

const mapDispatchToProps = {
  recalculateInsuredExpectedEvents:
    pricingActions.recalculateInsuredExpectedEvents,
};

const mapStateToProps = (state) => ({
  loadingInsuredExpectedEvents:
    state.pricing?.frequency?.loadingInsuredExpectedEvents ?? false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateLossFrequencyButton);
