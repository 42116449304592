import { createApi } from "@reduxjs/toolkit/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as utils from "utils";

const makeUserFacingError = (exception) => {
  if (exception?.status === 400 && exception?.description) {
    return {
      error: String(exception.description),
    };
  } else {
    logger.exception(exception);
    return {
      error: "Unknown error",
    };
  }
};

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.claims(args.url),
      {
        header: {
          "Content-Type": "application/json",
        },
        method: args.method ?? "post",
        body: args.body,
      }
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return makeUserFacingError(e);
  }
};

export const claimsApi = createApi({
  reducerPath: "claimsApi",
  baseQuery,
  endpoints: (builder) => ({
    claims: builder.query({
      query: ({ claimsQuery, limit, years, includeExcluded }) => ({
        url: "download/json",
        body: JSON.stringify({
          query: claimsQuery,
          limit,
          years,
          includeExcluded,
        }),
      }),
    }),
    summaryChart: builder.query({
      query: (claimsQuery) => ({
        url: "charts/summary",
        body: JSON.stringify(claimsQuery),
      }),
    }),
    allClaimsChart: builder.query({
      queryFn: async ({ claimsQuery, claimsAbove }) => {
        try {
          let data = {};
          let more = true;
          let page = 0;
          while (more) {
            const result = await utils.authenticatedFetch(
              config.endpoints.claims("charts/all-claims"),
              {
                body: JSON.stringify({ ...claimsQuery, claimsAbove, page }),
                method: "post",
              }
            );
            const dataSlice = await result.json();
            data = {
              ...data,
              ...dataSlice,
              values: [...(data?.values ?? []), ...(dataSlice.values ?? [])],
            };
            more = dataSlice.more;
            page = dataSlice.page + 1;
          }
          return { data };
        } catch (e) {
          return makeUserFacingError(e);
        }
      },
    }),
    claimsComparison: builder.query({
      queryFn: async ({
        currentQuery,
        priorQuery,
        primaryMatch,
        requiredMatchColumns,
        matchColumns,
        textDeltaColumns,
        numericDeltaColumns,
        page = 0,
        limit = 100,
        pollingIntervalSeconds = 1,
      }) => {
        try {
          let response = await utils.authenticatedFetch(
            config.endpoints.claims("charts/trigger-claims-comparison"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                currentQuery,
                priorQuery,
                primaryMatch,
                requiredMatchColumns,
                matchColumns,
                textDeltaColumns,
                numericDeltaColumns,
                page,
                limit,
              }),
            }
          );
          const creationResponse = await response.json();
          const reportId = creationResponse.id;

          const delay = (seconds) =>
            new Promise((res) => setTimeout(res, 1000 * seconds));

          let status = null;
          while (
            status == null ||
            ["pending", "running"].includes(status.state)
          ) {
            await delay(pollingIntervalSeconds);
            response = await utils.authenticatedFetch(
              config.endpoints.claims("charts/retrieve-claims-comparison"),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: reportId }),
              }
            );
            status = await response.json();
          }
          if (status.state !== "succeeded") {
            throw new Error(status);
          }
          response = await fetch(status.url);
          const data = await response.json();
          return { data };
        } catch (e) {
          return makeUserFacingError(e);
        }
      },
    }),
    recommendedBaseLayer: builder.query({
      query: (claimsQuery) => ({
        url: "pricing/recommended-layer",
        body: JSON.stringify(claimsQuery),
      }),
    }),
    downloadExcelClaims: builder.mutation({
      queryFn: async ({
        claimsQuery,
        filename,
        pollingIntervalSeconds = 1,
      }) => {
        try {
          let response = await utils.authenticatedFetch(
            config.endpoints.claims("create-claims-excel"),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(claimsQuery),
            }
          );
          const creationResponse = await response.json();
          const id = creationResponse.id;

          const delay = (seconds) =>
            new Promise((res) => setTimeout(res, 1000 * seconds));

          let status = null;
          while (
            status == null ||
            ["pending", "running"].includes(status.state)
          ) {
            await delay(pollingIntervalSeconds);
            response = await utils.authenticatedFetch(
              config.endpoints.claims("retrieve-claims-excel"),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ id }),
              }
            );
            status = await response.json();
          }
          if (status.state !== "succeeded") {
            throw new Error(status);
          }
          response = await fetch(status.downloadUrl);
          const blob = await response.blob();
          FileSaver.saveAs(blob, filename);
          return { data: status };
        } catch (e) {
          return makeUserFacingError(e);
        }
      },
    }),
    industryClasses: builder.query({
      query: () => ({
        url: "exposure/industry-classes",
        method: "get",
      }),
    }),
  }),
});

export const {
  useClaimsQuery,
  useSummaryChartQuery,
  useAllClaimsChartQuery,
  useClaimsComparisonQuery,
  useRecommendedBaseLayerQuery,
  useDownloadExcelClaimsMutation,
  useIndustryClassesQuery,
} = claimsApi;
