import ExtractFileDownloader from "../../common/files/ExtractedFileDownloader";
import { connect } from "react-redux";
import * as hazardActions from "store/actions/input/exposure/hazardActions";
import { selectHazardProfileFiles } from "store/selectors/input/exposure/exposureSelectors";
import { getInsured } from "store/selectors/input/program/programSelectors";

const mapDispatchToProps = {
  hideFile: hazardActions.hideHazardFile,
};

const mapStateToProps = (state) => ({
  files: selectHazardProfileFiles(state),
  insured: getInsured(state),
  title: "Download SIC Data",
  outputFilePrefix: "Hazard-profile",
  parquetColumnMap: {
    _STATE: "State",
    _SIC: "SIC Code",
    _PREMIUM: "Premium",
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtractFileDownloader);
