import { Button } from "@mui/material";
import Disabled from "components/common/Disabled";
import React from "react";

const ClaimsV1LossRunButton = ({ support }) => {
  if (support.canProcessLossFile()) {
    return (
      <Disabled ifReadOnly>
        <Button
          variant={"contained"}
          size={"small"}
          disableElevation
          onClick={(e) => {
            support.processLossFile();
            e.stopPropagation();
          }}
        >
          {"Loss Run"}
        </Button>
      </Disabled>
    );
  } else {
    return <></>;
  }
};

export default ClaimsV1LossRunButton;
