import TableCell from "@mui/material/TableCell";
import DefaultLoader from "components/common/DefaultLoader";
import NumberFormat from "react-number-format";

const GoodBadTableCell = (props) => {
  const { isLoading, cellStyle, good, ...nfProps } = props;
  const dealQuality = (good) => {
    if (good == null) {
      return "";
    }
    if (good < 0) {
      return "table-cell-bad-deal";
    }
    if (good > 0) {
      return "table-cell-good-deal";
    }
    return "";
  };
  return (
    <TableCell align={"right"} style={cellStyle}>
      {props.isLoading ? (
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            display: "inline-block",
            position: "relative",
            top: "2px",
          }}
          size={16}
        />
      ) : props.value == null ? (
        ""
      ) : (
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          defaultValue={""}
          decimalScale={0}
          className={`monospace ${dealQuality(good)}`}
          {...nfProps}
        />
      )}
    </TableCell>
  );
};

export default GoodBadTableCell;
