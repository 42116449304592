import {
  UPDATE_BROKER,
  UPDATE_SURPLUS_BROKER,
  UPDATE_BROKER_CONTACT,
  UPDATE_BROKER_HOUSE,
  UPDATE_BROKER_NAME,
  UPDATE_PREFERRED_UNDERWRITER,
  UPDATE_PREFERRED_UNDERWRITER_NAME,
  UPDATE_SECONDARY_UNDERWRITER,
} from "./peopleActionTypes";

export const updateBroker = (broker) => ({
  type: UPDATE_BROKER,
  payload: broker,
});

export const updateSurplusBroker = (broker) => ({
  type: UPDATE_SURPLUS_BROKER,
  payload: broker,
});

export const updateBrokerName = (brokerName) => ({
  type: UPDATE_BROKER_NAME,
  payload: brokerName,
});

export const updateBrokerContact = (contact) => ({
  type: UPDATE_BROKER_CONTACT,
  payload: contact,
});

export const updateBrokerHouse = (house) => ({
  type: UPDATE_BROKER_HOUSE,
  payload: house,
});

export const updatePreferredUnderwriter = (underwriter) => ({
  type: UPDATE_PREFERRED_UNDERWRITER,
  payload: underwriter,
});

export const updatePreferredUnderwriterName = (underwriterName) => ({
  type: UPDATE_PREFERRED_UNDERWRITER_NAME,
  payload: underwriterName,
});

export const updateSecondaryUnderwriter = (underwriter) => {
  return {
    type: UPDATE_SECONDARY_UNDERWRITER,
    payload: underwriter,
  };
};
