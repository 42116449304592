import { Box } from "@mui/material";

const SeverityBadge = ({ level, size, marginless }) => {
  size = size || 10;

  const colours = ["", "deepskyblue", "gold", "orange", "red"];

  const sx = {
    display: "inline-block",
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: `${size / 2}px`,
    background: level == null ? "gainsboro" : colours[level],
    flexShrink: 0,
  };

  if (!marginless) {
    sx.marginRight = `${Math.floor(size / 3)}px`;
  }

  return <Box sx={sx}></Box>;
};

export default SeverityBadge;
