export const UPDATE_SUMMARY_NOTE = "UPDATE_SUMMARY_NOTE";
export const UPDATE_SUMMARY_PEER_REVIEW_COMMENT =
  "UPDATE_SUMMARY_PEER_REVIEW_COMMENT";
export const UPDATE_SUMMARY_PEER_REVIEWER = "UPDATE_SUMMARY_PEER_REVIEWER";
export const UPDATE_SUMMARY_PEER_REVIEW_ATTENDEES =
  "UPDATE_SUMMARY_PEER_REVIEW_ATTENDEES";
export const UPDATE_SUMMARY_PEER_REVIEW_DATE =
  "UPDATE_SUMMARY_PEER_REVIEW_DATE";
export const UPDATE_SUMMARY_PEER_REVIEW_COMPLETE =
  "UPDATE_SUMMARY_PEER_REVIEW_COMPLETE";
