import * as actionTypes from "../../../actions/input/attachments/attachmentsActionTypes";
import * as persistenceActionTypes from "../../../actions/persistence/persistenceActionTypes";
import * as ars from "./attachmentsReducerSupport";
import {
  attachedFileUploaded,
  moveFileToFile,
} from "./attachmentsReducerSupport";

export const INIT_STATE = {
  attachedFiles: [],
  attachedFileNameOverrides: {},
  attachedFilesHidden: {},
  subDirectories: [],
};

const attachmentsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  const pld = action.payload;
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.attachments,
      };
    case actionTypes.CREATE_DIRECTORY:
      return ars.addDirectory(state, pld.parentId, pld.name);

    case actionTypes.REMOVE_DIRECTORY:
      return ars.removeDirectory(state, pld.directoryId);

    case actionTypes.ADD_FILE_TO_DIRECTORY:
      return ars.addFileToDirectory(state, pld.fileId, pld.directoryId);

    case actionTypes.REMOVE_FILE_FROM_DIRECTORY:
      return ars.removeFileFromDirectory(state, pld.fileId, pld.directoryId);

    case actionTypes.RENAME_DIRECTORY:
      return ars.renameDirectory(state, pld.directoryId, pld.name);

    case actionTypes.SET_NAME_OF_FILE_IN_DIRECTORY:
      return ars.setFileNameInDirectory(
        state,
        pld.fileId,
        pld.directoryId,
        pld.name
      );

    case actionTypes.MOVE_DIRECTORY_TO_DIRECTORY:
      return ars.moveDirectory(state, pld.dirId, pld.toDirId);

    case actionTypes.MOVE_FILE_TO_DIRECTORY:
      return ars.moveFileToDirectory(
        state,
        pld.fileId,
        pld.fromDirId,
        pld.toDirId,
        pld.embeddedFiles
      );

    case actionTypes.MOVE_FILE_TO_FILE:
      return moveFileToFile(
        state,
        pld.fileId,
        pld.fromDirId,
        pld.toDirId,
        pld.embeddedFiles
      );

    case actionTypes.ATTACHMENT_UPLOADED:
      return attachedFileUploaded(state, pld.fileId);

    case persistenceActionTypes.NEW_SUBMISSION:
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...INIT_STATE,
      };
    default:
      return state;
  }
};

export default attachmentsReducer;
