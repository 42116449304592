import Component from "components/Component";
import { makeSearch } from "components/search/SearchTextField";
import * as config from "config";

const CategoryCodeSearch = makeSearch((state) => ({
  searchState: state.search.categoryCode,
  active: state.input.program.categoryCode,
  meta: config.CATEGORY_CODE_SEEKER,
  displayResultText: (result) => `(${result._CODE}) ${result._TITLE}`,
  renderValue: (result) => `(${result.code}) ${result.title}`,
  label: "GICS Code",
}));

const CategoryCode = (props) => {
  return (
    <Component
      title={"GICS Code"}
      subtitle={"The Global Industry Classification Standard "}
      {...props.componentProps}
    >
      <CategoryCodeSearch />
    </Component>
  );
};

export default CategoryCode;
