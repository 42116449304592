const SvgContent = (size, percentage, color, secondaryColor) => {
  const cx = size / 2;
  const cy = size / 2;
  const radius = size / 2;
  const outlineWidth = Math.max(Math.ceil(size / 25), 1.5);

  const outline = (
    <circle
      cx={cx}
      cy={cy}
      r={radius - outlineWidth / 2}
      fill={"none"}
      stroke={color}
      stroke-location={"inside"}
      stroke-width={outlineWidth}
    />
  );

  if (percentage <= 0) {
    return outline;
  }

  if (percentage >= 1) {
    const size = cx * 2;
    const tickPath = [
      "M",
      0.8125 * size,
      0.25 * size,
      "L",
      0.375 * size,
      0.75 * size,
      "l",
      -0.1875 * size,
      -0.1875 * size,
    ];

    return (
      <>
        <circle cx={cx} cy={cy} r={radius} />
        <path
          d={tickPath.join(" ")}
          stroke={secondaryColor}
          strokeWidth={outlineWidth}
          strokeLinejoin={"round"}
        />
      </>
    );
  }

  const endAngle = 2 * Math.PI * percentage;
  const largeArcFlag = endAngle > Math.PI ? 1 : 0;
  const endX = cx + radius * Math.sin(endAngle);
  const endY = cy - radius * Math.cos(endAngle);

  const path = [
    "M",
    cx,
    cy,
    "L",
    cx,
    cy - radius,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    1,
    endX,
    endY,
    "L",
    cx,
    cy,
    "Z",
  ];

  return (
    <>
      {outline}
      <path d={path.join(" ")} />
    </>
  );
};

const CompletionIndicator = ({ size, color, secondaryColor, percentage }) => {
  size = Math.max(size ?? 0, 16);
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={color}
      stroke={"none"}
      stroke-linecap={"round"}
      width={size + "px"}
      height={size + "px"}
    >
      {SvgContent(size, percentage, color, secondaryColor)}
    </svg>
  );
};

export default CompletionIndicator;
