import * as config from "../../../config";
import * as types from "../../actions/search/searchActionTypes";
import { combineReducers } from "redux";

export const INIT_STATE = {
  outstanding: [],
  query: null,
  results: [],
  error: false,
};

export const instance = (searchType) => (state = INIT_STATE, action) => {
  if (searchType !== action?.payload?.searchType) {
    return state;
  }
  switch (action.type) {
    case types.SEARCH_DISPATCHED:
      return {
        ...state,
        outstanding: [...state.outstanding, action.payload.searchTerm],
        query: action.payload.searchTerm,
      };
    case types.SEARCH_RECEIVED:
      if (!state.outstanding.includes(action.payload.searchTerm)) {
        return state;
      }
      return {
        ...state,
        outstanding: state.outstanding.slice(
          state.outstanding.indexOf(action.payload.searchTerm) + 1
        ),
        results: action.payload.results,
        error: false,
      };
    case types.SEARCH_FAILED:
      if (!state.outstanding.includes(action.payload.searchTerm)) {
        return state;
      }
      return {
        ...state,
        outstanding: state.outstanding.slice(
          state.outstanding.indexOf(action.payload.searchTerm) + 1
        ),
        error: true,
      };
    case types.SELECT_SEARCH_VALUE:
      return {
        ...state,
        query: null,
        outstanding: [],
        results: [],
      };
    case types.SEARCH_CANCELLED:
      return {
        ...state,
        query: null,
        outstanding: [],
        results: [],
      };
    default:
      return state;
  }
};

export default combineReducers({
  naics: instance(config.NAICS_SEEKER.key),
  companyNaics: instance(config.COMPANY_NAICS_SEEKER.key),
  peerNaics: instance(config.PEER_NAICS_SEEKER.key),
  peers: instance(config.PEER_SEEKER.key),
  categoryCode: instance(config.CATEGORY_CODE_SEEKER.key),
  priorSubmission: instance(config.PRIOR_SUBMISSION.key),
});
