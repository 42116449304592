export const isString = (value) => typeof value === "string";

export const hasValue = (value) =>
  value != null && typeof value === "string" && value.trim().length > 0;

export const truncateStringWithEllipsis = (value, { maxLength }) => {
  if (!isString(value)) {
    return "";
  } else if (value.length <= maxLength) {
    return value;
  } else {
    const truncatedLength = Math.max(0, maxLength - 3);
    return value.substring(0, truncatedLength) + "...";
  }
};

export const setEmptyToNull = (value) => {
  if (value == null || value === "") {
    return null;
  } else {
    return value;
  }
};

export const toSentenceCase = (value, delimiter = "_") => {
  if (!isString(value)) {
    return value;
  } else {
    const tokens = value.trim().split(delimiter);
    return tokens
      .map(
        (token) =>
          token.substring(0, 1).toUpperCase() + token.substring(1).toLowerCase()
      )
      .join(" ");
  }
};

export const extractNameFromEmail = (email) => {
  if (!isString(email) || !email.includes("@")) {
    return { name: "Unknown", initials: "?" };
  }

  const fullName = [];
  const initials = [];
  const tokens = email.toLowerCase().split("@")[0].split(".");

  tokens.forEach((token) => {
    let initial = token.substring(0, 1).toUpperCase();
    let name = initial + token.substring(1);
    fullName.push(name.split("+")[0]);
    initials.push(initial);
  });

  return {
    name: fullName.join(" "),
    initials: initials.join(""),
  };
};

export const stringToColor = (value) => {
  if (!isString(value)) return "#000000";

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < value.length; i += 1) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const onlyContainsEmoji = (value) => {
  if (!isString(value)) return false;
  if (/[a-z0-9*#]+/i.test(value)) return false;
  return /\p{Extended_Pictographic}/u.test(value);
};

export const humanizedJoin = (values, { useOxfordCommas = true } = {}) => {
  if (!Array.isArray(values)) {
    return "";
  } else if (values.length <= 2) {
    return values.join(" and ");
  } else {
    const andSeparator = useOxfordCommas ? ", and " : " and ";
    return (
      values.slice(0, -1).join(", ") + andSeparator + values[values.length - 1]
    );
  }
};
