import Toggle from "./Toggle";

const Visible = ({ children, byTag, defaultVisible = false }) => {
  return (
    <Toggle
      trueChild={children}
      falseChild={<></>}
      byTag={byTag}
      defaultToggle={defaultVisible}
    />
  );
};

export default Visible;
