import * as dates from "common/dates";
import * as strings from "common/strings";

export const getScenarioLabels = (scenarioConfig) => {
  const scenarioLabels = {};

  if (scenarioConfig != null) {
    scenarioConfig.scenarios.forEach((category) => {
      category.items.forEach((x) => (scenarioLabels[x.id] = x.label));
    });
  }

  return scenarioLabels;
};

export const getScenarioCategories = (scenarioConfig) => {
  const scenarioCategories = {};

  if (scenarioConfig != null) {
    scenarioConfig.scenarios.forEach((category) => {
      category.items.forEach((x) => (scenarioCategories[x.id] = category.name));
    });
  }

  return scenarioCategories;
};

const applyDateFilter = (scenarios, inForceDate) => {
  if (!inForceDate || dates.validatedDateStr(inForceDate) == null) {
    return scenarios;
  }

  return scenarios.filter((x) =>
    dates.strDateInRange(inForceDate, x.inception, x.expiration)
  );
};

const applyAttributeFilters = (scenarios, filters) => {
  const searchTerms = filters.filter(
    (item) =>
      item.context !== "Status" &&
      item.context !== "Severity" &&
      item.value.length !== 0
  );

  if (searchTerms.length === 0) {
    return scenarios;
  } else {
    return scenarios.filter((x) => {
      let matchCount = 0;

      searchTerms.forEach((search) => {
        if (search.context === "Scenario") {
          matchCount += search.value.includes(x.scenario_key) ? 1 : 0;
        } else if (search.context === "Insured") {
          matchCount += x.insured && search.value.includes(x.insured) ? 1 : 0;
        } else if (search.context === "Industry") {
          matchCount +=
            x.industry_class && search.value.includes(x.industry_class) ? 1 : 0;
        }
      });

      return matchCount === searchTerms.length;
    });
  }
};

const getFilterValue = (context, filters) =>
  filters.filter((x) => x.context === context)[0]?.value ?? [];

const applySearchFilter = (scenarios, scenarioLabels, searchText, filters) => {
  if (searchText === "") {
    return scenarios;
  } else {
    const searchTokens = searchText
      .split(" ")
      .filter((token) => token.trim() !== "");

    return scenarios.filter((x) => {
      if (getFilterValue("Scenario", filters).length === 0) {
        if (
          searchTokens.some((token) =>
            (scenarioLabels[x.scenario_key] || x.scenario_key)
              .toLowerCase()
              .includes(token)
          )
        ) {
          return true;
        }
      }

      let fullText = `${x.inception}|${x.expiration}|${x.limit}|${x.attachment}`;

      if (getFilterValue("Insured", filters).length === 0) {
        fullText += `|${x.insured}`;
      }
      if (getFilterValue("Industry", filters).length === 0) {
        fullText += `|${x.industry_class}`;
      }
      return searchTokens.some((token) =>
        fullText.toLowerCase().includes(token)
      );
    });
  }
};

export const filterScenarios = (
  scenarios,
  scenarioLabels,
  searchTerm,
  inForceDate,
  filters
) => {
  const matchedByDate = applyDateFilter(scenarios, inForceDate);
  const matchedByFilter = applyAttributeFilters(matchedByDate, filters);
  const matchedBySearch = applySearchFilter(
    matchedByFilter,
    scenarioLabels,
    searchTerm,
    filters
  );

  const statusFilter = getFilterValue("Status", filters);
  const matchedByStatus =
    statusFilter.length === 0
      ? matchedBySearch
      : matchedBySearch.filter((x) => statusFilter.includes(x.status));

  const severityFilter = getFilterValue("Severity", filters);
  return severityFilter.length === 0
    ? matchedByStatus
    : matchedByStatus.filter((x) => severityFilter.includes(x.scenario_level));
};

export const getFilterItems = (
  context,
  scenarios,
  scenarioLabels,
  searchTerm,
  inForceDate,
  filters
) => {
  const filteredScenarios = filterScenarios(
    scenarios,
    scenarioLabels,
    searchTerm,
    inForceDate,
    filters.filter((item) => item.context !== context)
  );

  if (context === "Status") {
    const items = [...new Set(filteredScenarios.map((x) => x.status))];
    return items.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  } else if (context === "Severity") {
    const items = [...new Set(filteredScenarios.map((x) => x.scenario_level))];
    items.sort((a, b) => b - a);
    return items.map((item) => {
      return {
        value: item,
        label: ["", "Low", "Medium", "High", "Max"][item],
      };
    });
  } else if (context === "Scenario") {
    const items = [...new Set(filteredScenarios.map((x) => x.scenario_key))];
    return items.map((item) => {
      return {
        value: item,
        label: scenarioLabels[item] || strings.toSentenceCase(item),
      };
    });
  } else if (context === "Insured") {
    const items = [...new Set(filteredScenarios.map((x) => x.insured))];
    return items.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  } else if (context === "Industry") {
    const items = [...new Set(filteredScenarios.map((x) => x.industry_class))];
    return items.map((item) => {
      return {
        value: item,
        label: strings.toSentenceCase(item),
      };
    });
  }
};
