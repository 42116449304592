import Grid from "@mui/material/Grid";
import React from "react";

const DashboardContent = ({ children, isFullWidth }) => {
  return (
    <div className={"dashboard-content"}>
      <div className={`${isFullWidth ? "" : "container"}`}>
        <Grid container spacing={3}>
          {children}
        </Grid>
      </div>
    </div>
  );
};

export default DashboardContent;
