const NULL_MAPPING = {
  fileId: "",
  filename: "",
  origin: { column: 0, row: 0 },
  mapping: [],
  appliedExcess: null,
  dateConversionTypes: {},
  substitutions: [],
  rowDeletions: [],
};
export const clearMapping = () => NULL_MAPPING;

export const setFilename = (cd, { filename }) => {
  if (cd?.filename === filename) {
    return cd;
  }
  if (!filename) {
    return {
      ...NULL_MAPPING,
    };
  }
  return {
    ...NULL_MAPPING,
    fileId: cd?.fileId || NULL_MAPPING.fileId,
    filename,
  };
};

export const setFileId = (cd, { fileId }) => {
  if (cd.fileId === fileId) {
    return cd;
  }
  return {
    ...NULL_MAPPING,
    fileId,
    filename: cd.filename || NULL_MAPPING.filename,
  };
};

export const setTableName = (cd, { tableName }) => {
  if (cd.tableName === tableName) {
    return cd;
  }
  return {
    ...NULL_MAPPING,
    fileId: cd.fileId,
    filename: cd.filename,
    tableName,
  };
};

export const setOrigin = (cd, { origin }) => {
  if (cd.origin?.column === origin.column && cd.origin?.row === origin.row) {
    return cd;
  }
  return {
    ...NULL_MAPPING,
    fileId: cd.fileId,
    tableName: cd.tableName,
    filename: cd.filename,
    origin,
  };
};

export const setHeaderRows = (cd, { headerRows }) => {
  if (cd.headerRows === headerRows) {
    return cd;
  }
  return {
    fileId: cd.fileId,
    filename: cd.filename,
    tableName: cd.tableName,
    origin: cd.origin,
    headerRows,
  };
};

export const setAppliedExcess = (cd, { appliedExcess }) => {
  if (JSON.stringify(cd.appliedExcess) === JSON.stringify(appliedExcess)) {
    return cd;
  }
  return {
    ...cd,
    appliedExcess: appliedExcess,
  };
};

const changesMapping = (
  mapping,
  { inputColumn, outputName, conversionType }
) => {
  if (
    mapping.inputColumn !== inputColumn ||
    mapping.outputName !== outputName ||
    mapping.conversions == null
  ) {
    return true;
  }
  return !(mapping.conversions ?? []).some(
    (conversion) => conversion.conversionType === conversionType
  );
};

const newMapping = (
  mapping = [],
  currentIndex,
  { inputColumn, outputName, conversions }
) => [
  ...mapping.slice(0, currentIndex === -1 ? mapping.length : currentIndex),
  ...(inputColumn == null
    ? []
    : [
        {
          inputColumn,
          outputName,
          conversions,
        },
      ]),
  ...mapping.slice(currentIndex === -1 ? mapping.length : currentIndex + 1),
];

export const updateColumnMapping = (
  cd,
  { inputColumn, outputName, conversionType }
) => {
  const safeMapping = cd.mapping ?? [];
  const currentIndex = safeMapping.findIndex(
    (mapping) => mapping.outputName === outputName
  );
  if (
    currentIndex >= 0 &&
    !changesMapping(safeMapping[currentIndex], {
      inputColumn,
      outputName,
      conversionType,
    })
  ) {
    return cd;
  }

  return {
    ...cd,
    mapping: newMapping(safeMapping, currentIndex, {
      inputColumn,
      outputName,
      conversions: [{ conversionType }],
    }),
  };
};

export const updateConversionMapping = (
  cd,
  { outputName, valueKey, mappedKey }
) => {
  const safeMapping = cd.mapping ?? [];
  const currentIndex = safeMapping.findIndex(
    (mapping) => mapping.outputName === outputName
  );
  const conversions = safeMapping[currentIndex]?.conversions ?? [];
  const allowedConversionTypes = [
    "CONVERT",
    "IN_DELETE_ROW",
    "NOT_IN_DELETE_ROW",
  ];
  const conversionIndex = conversions.findIndex((conversion) =>
    allowedConversionTypes.includes(conversion.conversionType)
  );
  if (conversionIndex === -1) {
    throw new Error(
      `Cannot update conversion mapping for types other than ${allowedConversionTypes.join(
        ", "
      )}`
    );
  }
  if (conversions[conversionIndex]?.conversions?.[valueKey] === mappedKey) {
    return cd;
  }
  return {
    ...cd,
    mapping: newMapping(safeMapping, currentIndex, {
      inputColumn: safeMapping[currentIndex].inputColumn,
      outputName,
      conversions: [
        ...conversions.slice(0, conversionIndex),
        {
          ...conversions[conversionIndex],
          conversions: {
            ...conversions[conversionIndex].conversions,
            [valueKey]: mappedKey,
          },
        },
        ...conversions.slice(conversionIndex + 1),
      ],
    }),
  };
};

export const updateDeleteInRowMapping = (
  cd,
  { outputName, valueKey, deleteInRow }
) => {
  const safeMapping = cd.mapping ?? [];
  const currentIndex = safeMapping.findIndex(
    (mapping) => mapping.outputName === outputName
  );
  const conversions = safeMapping[currentIndex]?.conversions ?? [];
  const conversionIndex = conversions.findIndex(
    (conversion) => conversion.conversionType === "IN_DELETE_ROW"
  );
  if (conversionIndex === -1) {
    throw new Error(
      "Cannot update delete in row mapping for type other than DELETE_IN_ROW"
    );
  }
  if (conversions[conversionIndex].match?.includes(valueKey) === deleteInRow) {
    return cd;
  }
  return {
    ...cd,
    mapping: newMapping(safeMapping, currentIndex, {
      inputColumn: safeMapping[currentIndex].inputColumn,
      outputName,
      conversions: [
        ...conversions.slice(0, conversionIndex),
        {
          ...conversions[conversionIndex],
          match: [
            ...(conversions[conversionIndex].match || []).filter((key) =>
              key === valueKey ? deleteInRow : true
            ),
            ...(deleteInRow ? [valueKey] : []),
          ],
        },
        ...conversions.slice(conversionIndex + 1),
      ],
    }),
  };
};

export const updateConstantConversionValue = (cd, { outputName, value }) => {
  const safeMapping = [...(cd.mapping ?? [])];
  const currentIndex = safeMapping.findIndex(
    (mapping) => mapping.outputName === outputName
  );

  if (currentIndex === -1) {
    if (value == null) {
      return cd;
    } else {
      safeMapping.push({
        inputColumn: 0,
        outputName,
        conversions: [{ conversionType: "CONSTANT", value: value }],
      });
      return { ...cd, mapping: [...safeMapping] };
    }
  }

  const conversionType =
    safeMapping[currentIndex].conversions?.[0].conversionType;
  if (conversionType !== "CONSTANT") {
    throw new Error(
      "Cannot update constant conversion value for type other than CONSTANT"
    );
  }

  safeMapping.splice(currentIndex, 1);

  if (value != null) {
    safeMapping.push({
      inputColumn: 0,
      outputName,
      conversions: [{ conversionType: "CONSTANT", value: value }],
    });
  }

  return { ...cd, mapping: safeMapping };
};

export const updateDateConversionType = (cd, { column, type }) => {
  const dcTypes = cd?.dateConversionTypes || {};
  if (dcTypes[column] === type) return cd;

  return {
    ...cd,
    dateConversionTypes: {
      ...dcTypes,
      [column]: type,
    },
  };
};

export const setSubstitutions = (cd, { substitutions }) => {
  if (JSON.stringify(cd.substitutions) === JSON.stringify(substitutions)) {
    return cd;
  }
  return {
    ...cd,
    substitutions: substitutions ?? [],
  };
};

export const setRowDeletions = (cd, { rowDeletions }) => {
  if (JSON.stringify(cd.rowDeletions) === JSON.stringify(rowDeletions)) {
    return cd;
  }
  return {
    ...cd,
    rowDeletions: rowDeletions ?? [],
  };
};
