import DefaultLoader from "./common/DefaultLoader";
import { Grid, Stack } from "@mui/material";
import * as config from "config";
import * as staticDataHooks from "store/hooks/staticDataHooks";

const Component = ({
  title,
  titleComponent,
  subtitle,
  children,
  error,
  growVertically,
  compact,
  options,
  titleStyles,
  loading,
  onClick,
  onDoubleClick,
  isFetching,
  titleTranslationKey,
  ...props
}) => {
  const translateTitle = staticDataHooks.useTranslator("uiLabels");
  title = translateTitle(titleTranslationKey ?? `title.${title}`, {
    default: title,
  });

  const getClassName = () => {
    let className = "component";
    if (error) {
      className += " component-error";
    }
    if (growVertically) {
      className += " component-stretch-vertically";
    }
    if (compact) {
      className += " compact";
    }
    return className;
  };

  const hasHeader = Boolean(title || titleComponent || subtitle || options);

  if (isFetching) {
    title = (
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <div
          style={{
            marginTop: "13px",
            whiteSpace: "nowrap",
            alignItems: "center",
          }}
        >
          {title}
        </div>
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "13px",
            marginLeft: "8px",
            marginRight: "auto",
          }}
          size={14}
        />
      </Stack>
    );
  }

  return (
    <Grid
      item
      xs={props.xs_width ?? 12}
      sm={props.sm_width ?? props.xs_width ?? 12}
      md={props.md_width ?? props.sm_width ?? props.xs_width ?? 12}
      lg={
        props.lg_width ??
        props.md_width ??
        props.sm_width ??
        props.xs_width ??
        12
      }
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <div className={getClassName()}>
        {hasHeader ? (
          <div className={"component-header-wrapper"}>
            <div className={"component-header"}>
              {titleComponent ? <>{titleComponent}</> : <></>}
              {title ? (
                <h2 className={"component-title"} style={titleStyles}>
                  {title}
                </h2>
              ) : (
                <></>
              )}
              {subtitle ? (
                <span className={"component-subtitle"}>{subtitle}</span>
              ) : (
                <></>
              )}
            </div>
            {options ? options : <></>}
          </div>
        ) : (
          <></>
        )}
        {(loading || children) && (
          <div
            className={hasHeader ? "component-child-wrapper" : ""}
            style={{ opacity: isFetching ? config.FETCHING_OPACITY : 1 }}
          >
            {loading ? (
              <DefaultLoader
                color={"#dc7f4c"}
                size={60}
                style={{
                  display: "block",
                  textAlign: "center",
                }}
              />
            ) : (
              <>{children}</>
            )}
          </div>
        )}
      </div>
    </Grid>
  );
};

export default Component;
