import TransferConfirmationForm from "./TransferConfirmationForm";
import {
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  IconButton as MaterialIconButton,
  Link,
  Typography,
} from "@mui/material";
import * as dates from "common/dates";
import * as logger from "common/logger";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import SmallLoader from "components/common/SmallLoader";
import * as config from "config";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as exchangeService from "services/exchangeService";
import * as persistenceActions from "store/actions/persistence/persistenceActions";
import * as persistenceSelectors from "store/selectors/persistence/persistenceSelectors";

const TransferCard = ({ submissionId, senderId, recipient }) => {
  const dispatch = useDispatch();

  const {
    data: transfers,
    isFetching: isFetchingTransfers,
    refetch: refetchTransfers,
  } = exchangeService.useTransfersQuery({
    senderId,
    recipientId: recipient.id,
    senderSubmissionId: submissionId,
  });

  const {
    data: exchangeConfig,
    isLoading: isExchangeConfigLoading,
  } = exchangeService.useClientConfigQuery();

  const [isTransferring, setIsTransferring] = useState(false);
  const [isShowingConfirm, setIsShowingConfirm] = useState(false);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const persistedState = useSelector(persistenceSelectors.selectPersistedState);
  const originalSubmission = {
    state: persistedState,
  };

  const [view, setView] = useState(null);

  const [transferSubmission] = exchangeService.useTransferSubmissionMutation();

  const onTransfer = async () => {
    try {
      setIsShowingConfirm(false);
      setIsTransferring(true);
      const response = await transferSubmission({
        senderId: exchangeConfig.participantId,
        recipientId: recipient.id,
        senderSubmissionId: submissionId,
        originalSubmission,
        view,
      });
      if (response.error) {
        throw new Error("Transfer failed");
      }
      enqueueSnackbar(
        `Sent submission data to ${recipient.name}. Reference: ${response.data?.id}`,
        {
          variant: "success",
          autoHideDuration: 5_000, // ms
          action: (msgId) => (
            <MaterialIconButton
              size={"small"}
              onClick={() => closeSnackbar(msgId)}
            >
              <CloseIcon />
            </MaterialIconButton>
          ),
        }
      );
      dispatch(persistenceActions.saveState.requested());
    } catch (e) {
      logger.exception(e);
      enqueueSnackbar(
        `Failed to send. Please contact ${config.SUPPORT_EMAIL}.`,
        {
          variant: "error",
          autoHideDuration: 20_000, // ms
          action: (msgId) => (
            <MaterialIconButton
              size={"small"}
              onClick={() => closeSnackbar(msgId)}
            >
              <CloseIcon />
            </MaterialIconButton>
          ),
        }
      );
    } finally {
      refetchTransfers();
      setIsTransferring(false);
    }
  };

  if (isExchangeConfigLoading) {
    return <BigLoader />;
  }

  return (
    <>
      <Card
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 0,
          paddingBottom: 1,
        }}
      >
        <CardContent>
          <Typography variant={"h6"}>{recipient.name}</Typography>
          <Disabled ifReadOnly>
            <Button
              onClick={() => setIsShowingConfirm(true)}
              isLoading={isTransferring}
              sx={{ marginTop: 1, marginBottom: 1 }}
              fullWidth
            >
              {`Send to ${recipient.name}`}
            </Button>
          </Disabled>
          {transfers?.map((transfer, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  {index === 0 ? "Shared " : "Updated "}
                  {dates.formatDate(transfer.createTime)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Box component={"span"} fontWeight={"bold"}>
                    {"Reference: "}
                  </Box>
                  {transfer.id}
                </Typography>
                <Typography>
                  <Box component={"span"} fontWeight={"bold"}>
                    {"Timestamp: "}
                  </Box>
                  {transfer.createTime}
                </Typography>
                <Link href={`/transfers/${transfer.id}`} target={"_blank"}>
                  {"Open"}
                </Link>
              </AccordionDetails>
            </Accordion>
          ))}
          {isFetchingTransfers && <SmallLoader />}
        </CardContent>
      </Card>
      {isShowingConfirm && (
        <TransferConfirmationForm
          recipient={recipient}
          onTransfer={onTransfer}
          view={view}
          setView={setView}
          onClose={() => setIsShowingConfirm(false)}
        />
      )}
    </>
  );
};

export default TransferCard;
