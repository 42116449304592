import { Autocomplete, TextField } from "@mui/material";
import * as staticDataService from "services/staticDataService";

const StateOfFiling = ({ value, onChange }) => {
  const { data: geographyConfig } = staticDataService.useConfigQuery(
    "geography"
  );

  const options = [
    {
      code: "__NA__",
      name: "N/A",
    },
    ...(geographyConfig?.usStates ?? []),
  ];

  return (
    <Autocomplete
      options={options}
      getOptionKey={(option) => option.code}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={"filled"}
          label={"State of Filing"}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
      onChange={(event, newValue) => onChange(newValue ? newValue.code : null)}
      value={options.find((state) => state.code === value) ?? null}
      fullWidth
    />
  );
};

export default StateOfFiling;
