import Component from "../../Component";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";

const threeSFString = (number) => {
  if (number === undefined || number === null) {
    return "N/A";
  }
  return number.toLocaleString("en-US", {
    maximumSignificantDigits: 3,
  });
};

export const ImprovexAverageComponent = (props) => {
  return (
    <Component
      title={"Model Average"}
      subtitle={"Based on NAICS Code for Pricing, domicile and turnover."}
    >
      <Table aria-label={"Model Averages"} size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>{""}</TableCell>
            <TableCell align={"right"}>{"Frequency"}</TableCell>
            <TableCell align={"right"}>{"# of Losses"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={"improvex-averages-all"} id={"improvex-averages-all"}>
            <TableCell component={"th"} scope={"row"}>
              <Typography>{"Market"}</Typography>
            </TableCell>
            <TableCell align={"right"}>
              <Typography className={"monospace"}>
                {threeSFString(props.frequency)}
              </Typography>
            </TableCell>
            <TableCell align={"right"}>
              <Typography className={"monospace"}>
                {threeSFString(props.claims)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Component>
  );
};

const mapDispatchToProps = {};

export const mapStateToProps = (state) => {
  return {
    claims: state.pricing.frequency.improvex?.claims,
    frequency: state.pricing.frequency.improvex?.frequency,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImprovexAverageComponent);
