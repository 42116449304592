import * as config from "config";
import React from "react";

const AdminDashboard = ({ children }) => {
  return (
    <div className={`dashboard admin-env-${config.ENVIRONMENT}`}>
      {children}
    </div>
  );
};

export default AdminDashboard;
