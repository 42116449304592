import UpdateLossFrequencyButton from "./UpdateLossFrequencyButton";
import { Alert } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { cleanXS, abbreviated } from "common/numbers";
import Component from "components/Component";
import { useSelector } from "react-redux";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

export const UpdateLossFrequency = (props) => {
  const diff = useSelector(
    pricingSelectors.selectRecalculatedInsuredExpectedEventsRequestDiff
  );
  const request = useSelector(
    pricingSelectors.selectRecalculatedInsuredExpectedEventsRequest
  );
  const previousRequest = useSelector(
    (state) => state.pricing?.frequency?.request
  );
  const baseLayer = useSelector(pricingSelectors.getBaseLayer);
  const lossesForPricing = useSelector(
    pricingSelectors.getNumberOfLossesForPricingNumber
  );
  const lossesMessage = () => {
    if (lossesForPricing >= 1) {
      return (
        <>
          {
            "The chosen number of Losses for Pricing suggests this company will have "
          }
          <b>{Math.floor(lossesForPricing * 100) / 100.0}</b>
          {" losses in excess of "}
          <b>{baseLayer.attachment.toLocaleString()}</b>
          {" every year."}
        </>
      );
    }
    return (
      <>
        {
          "The chosen number of Losses for Pricing suggests this company will have a loss in excess of "
        }
        <b>{baseLayer.attachment.toLocaleString()}</b>
        {" every "}
        <b>{Math.floor((1.0 / lossesForPricing) * 100) / 100.0}</b>
        {" years."}
      </>
    );
  };
  const hasError = !!request.error;
  const hasAlert =
    Object.keys({
      ...diff.added,
      ...diff.deleted,
      ...diff.updated,
    }).length > 0;
  return (
    <Component error={hasError}>
      <Grid container spacing={2}>
        {baseLayer?.attachment && lossesForPricing && (
          <Grid item xs={12} lg={props.isCompact && !hasAlert ? 10 : 12}>
            <Typography>{lossesMessage()}</Typography>
          </Grid>
        )}
        {hasAlert && (
          <Grid item xs={12}>
            <Alert
              severity={"error"}
              sx={{ whiteSpace: "pre-line", width: "100%" }}
            >
              {
                "The Loss Frequency has changed since it was calculated. Please see below for more details:"
              }
              <br />
              <ul>
                {diff?.updated?.baseLayer && (
                  <li>
                    <b>
                      {"The base layer has changed from "}
                      {cleanXS(previousRequest.baseLayer)}
                      {"."}
                    </b>
                  </li>
                )}
                {(diff?.updated?.exposureSeries ||
                  diff?.deleted?.exposureSeries) && (
                  <li>{"The exposure series data changed."}</li>
                )}
                {diff?.updated?.turnoverCategory && (
                  <li>
                    {"The turnover Category has changed from "}
                    {previousRequest.turnoverCategory}
                    {" to "}
                    {request.turnoverCategory}
                    {"."}
                  </li>
                )}
                {diff?.updated?.naicsCode && (
                  <li>
                    {"The NAICS code has changed from "}
                    {previousRequest.naicsCode}
                    {" to "}
                    {request.naicsCode}
                    {"."}
                  </li>
                )}
                {diff?.updated?.in_US && (
                  <li>
                    {"Whether this company is in the US has changed from "}
                    {previousRequest.in_US ? "US" : "not US"}
                    {" to "}
                    {request.in_US ? "US" : "not US"}
                    {"."}
                  </li>
                )}
                {diff?.updated?.currentTurnover && (
                  <li>
                    {"The current turnover has changed from "}
                    {abbreviated(previousRequest.currentTurnover)}
                    {" to "}
                    {abbreviated(request.currentTurnover)}
                    {"."}
                  </li>
                )}
                {diff?.updated?.claimsQuery && (
                  <li>
                    {
                      "The information about the claims has changed, this may only be that a recalculation would now include more zero years."
                    }
                  </li>
                )}
              </ul>
            </Alert>
          </Grid>
        )}
        {hasError ? (
          <Grid item xs={12}>
            <Alert
              severity={"error"}
              sx={{ whiteSpace: "pre-line", width: "100%" }}
            >
              {request.error?.message ||
                "There is an error with the ability to update the loss frequency."}
            </Alert>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={12}
            lg={props.isCompact && !hasAlert ? 2 : 12}
            justifyContent={"flex-end"}
            alignContent={"flex-end"}
          >
            <UpdateLossFrequencyButton />
          </Grid>
        )}
      </Grid>
    </Component>
  );
};

export default UpdateLossFrequency;
