import * as logger from "./logger";
import * as time from "./time";

export const fetchWithRetry = ({
  maxAttempts = 3,
  backoffMilliseconds = 100,
  backoffExponentialFactor = 2,
  retryOnStatusCodes = null,
  retryOnThrow = true,
}) => {
  const _fetchAttempt = async (url, payload, { attempt }) => {
    const currentBackoffMilliseconds =
      backoffMilliseconds * backoffExponentialFactor ** (attempt - 1);

    const breadcrumb = {
      attempt,
      currentBackoffMilliseconds,
      maxAttempts,
      backoffMilliseconds,
      backoffExponentialFactor,
      retryOnStatusCodes,
      retryOnThrow,
      online: window?.navigator?.onLine,
    };

    const _retry = async () => {
      if (currentBackoffMilliseconds > 0) {
        await time.sleep(currentBackoffMilliseconds);
      }
      return _fetchAttempt(url, payload, { attempt: attempt + 1 });
    };

    try {
      const response = await fetch(url, payload);
      const statusCode = response.status;
      if (
        retryOnStatusCodes &&
        retryOnStatusCodes.includes(statusCode) &&
        attempt < maxAttempts
      ) {
        logger.breadcrumb({
          ...breadcrumb,
          message: `Retry on status code: ${statusCode}`,
        });
        return _retry();
      }
      return response;
    } catch (e) {
      if (retryOnThrow && attempt < maxAttempts) {
        logger.breadcrumb({
          ...breadcrumb,
          message: `Retry on exception: ${e}`,
        });
        return _retry();
      } else {
        logger.exception(e, { breadcrumb });
        throw e;
      }
    }
  };

  return async (url, payload) => {
    return _fetchAttempt(url, payload, { attempt: 1 });
  };
};
