import { Stack, Typography } from "@mui/material";
import { hasItems } from "common/arrays";
import { Fragment } from "react";

export const STYLE = {
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",
  NEUTRAL: "NEUTRAL",
};

const SummaryText = ({ counts, defaultText, includeZeroes = false }) => {
  if (!hasItems(counts)) {
    return <Typography>{defaultText}</Typography>;
  }

  const colors = {
    [STYLE.POSITIVE]: "seagreen",
    [STYLE.NEGATIVE]: "crimson",
    [STYLE.NEUTRAL]: "lightslategrey",
  };

  return (
    <Stack flexDirection={"row"} gap={1}>
      {counts.map((item, index) =>
        item.count === 0 && !includeZeroes ? (
          <Fragment key={index}></Fragment>
        ) : (
          <Typography key={index} color={colors[item.style] + " !important"}>
            <b>{item.count}</b> {item.label}
          </Typography>
        )
      )}
    </Stack>
  );
};

export default SummaryText;
