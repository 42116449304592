import CheckboxCell from "./mapper/CheckboxCell";
import MappingColumnHeader from "./mapper/MappingColumnHeader";
import MappingRowHeader from "./mapper/MappingRowHeader";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DefaultLoader from "components/common/DefaultLoader";
import React, { useState } from "react";

export const Mapper = (props) => {
  const [highlight, setHighlight] = useState(-1);

  const mappedTo = (key) => {
    const mapping = props.mapping?.[key];
    if (mapping != null) return mapping;
    if (mapping === null) return undefined;
    const suggest = props.suggested?.[key];
    if (suggest != null) return suggest;

    return undefined;
  };

  if (props.sources === null) {
    return (
      <DefaultLoader
        color={"#dc7f4c"}
        style={{
          width: "200px",
          height: "200px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        size={50}
      />
    );
  }
  let rows = props.sources?.map((source) => ({ key: source, display: source }));
  if (rows === undefined && props.allowSelectWithoutSources) {
    rows = [{ key: "__ALL__", display: "Value for All Rows" }];
  }
  if (rows === undefined || props.targets === undefined) {
    return <></>;
  }
  return (
    <TableContainer
      sx={{ maxHeight: props.maxHeight ?? undefined }}
      className={"mapper"}
    >
      <Table spacing={2} t={10} stickyHeader>
        <TableHead>
          <TableRow justify={"center"} spacing={1}>
            {props.firstHeaderCell && props.firstHeaderCell}
            <TableCell key={`target_column_empty_title`}></TableCell>
            {props.sourcesMeta &&
              (props.metaFields ?? []).map((field, fieldIndex) => (
                <TableCell key={fieldIndex} align={"center"}>
                  <MappingColumnHeader
                    display={field.display}
                    mapping={field.mapping}
                    showMappingSummary
                  />
                </TableCell>
              ))}
            {(props.targets ?? []).map((target, targetIndex) => (
              <TableCell key={targetIndex} align={"center"}>
                <MappingColumnHeader
                  display={target.display}
                  showMappingSummary={!props.isTransposed}
                  showMappingSummaryIcon
                  mapping={!props.isTransposed && mappedTo(target.key)}
                />
              </TableCell>
            ))}
            <TableCell>{/* Empty cell to take up any spare space */}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((source, sourceIndex) => {
            const mappingKey = props.isTransposed && mappedTo(source.key);
            const mapping =
              mappingKey &&
              (props.targets ?? []).find((x) => x.key === mappingKey)?.display;
            return (
              <TableRow key={sourceIndex}>
                <TableCell width={250}>
                  <MappingRowHeader
                    display={source.display}
                    showMappingSummary={props.isTransposed}
                    mapping={mapping}
                  />
                </TableCell>
                {props.firstBodyCell &&
                  props.firstBodyCell(source, sourceIndex)}
                {props.sourcesMeta &&
                  (props.metaFields ?? []).map((field, fieldIndex) => (
                    <TableCell key={fieldIndex} align={"center"} width={1}>
                      {field?.mapper != null
                        ? field.mapper(
                            props.sourcesMeta[sourceIndex]?.[field.field]
                          )
                        : props.sourcesMeta[sourceIndex]?.[field.field]}
                    </TableCell>
                  ))}
                {(props.targets ?? []).map((target, targetIndex) => {
                  const mapped = props.isTransposed
                    ? props.mapping[source.key]
                    : props.mapping[target.key];
                  const suggested = props.isTransposed
                    ? props.suggested?.[source.key]
                    : props.suggested?.[target.key];
                  const value = props.isTransposed ? target.key : source.key;
                  const isSuggestion =
                    mapped === undefined && suggested === value;
                  const checked = mapped === value || isSuggestion;
                  return (
                    <CheckboxCell
                      key={targetIndex}
                      source={source.key}
                      target={target.key}
                      setHighlight={setHighlight}
                      i={targetIndex}
                      highlighted={highlight === targetIndex}
                      isSuggestion={isSuggestion}
                      checked={checked}
                      mappingUpdated={props.mappingUpdated}
                      width={1}
                    />
                  );
                })}
                <TableCell>
                  {/* Empty cell to take up any spare space */}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MapperInBox = (props) => (
  <Box mt={4}>
    <Mapper {...props} />
  </Box>
);

export default MapperInBox;
