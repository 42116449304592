export const makeClaimsYears = ({ summaryCharts, lossYears }) => {
  const frequencyData = summaryCharts?.frequency?.data ?? [];
  const frequencyDataYears = frequencyData.map((item) => item.year);
  const frequencyDataMissingYears = (lossYears ?? []).filter(
    (year) => !frequencyDataYears.includes(year)
  );
  const claimsYears = frequencyData.map(({ year, value }) => ({
    name: String(year),
    value,
  }));
  if (frequencyDataMissingYears) {
    return claimsYears
      .concat(
        frequencyDataMissingYears.map((year) => ({
          name: year.toString(),
          value: 0,
        }))
      )
      .sort((a, b) => parseInt(a.name) - parseInt(b.name));
  } else {
    return claimsYears;
  }
};
