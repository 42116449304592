import * as actionTypes from "./summaryActionTypes";

export const updateSummaryNote = (key, note) => ({
  type: actionTypes.UPDATE_SUMMARY_NOTE,
  payload: { key, note },
});

export const updateSummaryPeerReviewComment = (comment) => ({
  type: actionTypes.UPDATE_SUMMARY_PEER_REVIEW_COMMENT,
  payload: comment,
});

export const updateSummaryPeerReviewer = (person) => ({
  type: actionTypes.UPDATE_SUMMARY_PEER_REVIEWER,
  payload: person,
});

export const updateSummaryPeerReviewAttendees = (attendees) => ({
  type: actionTypes.UPDATE_SUMMARY_PEER_REVIEW_ATTENDEES,
  payload: attendees,
});

export const updateSummaryPeerReviewerFromName = (name) => {
  if (name) return updateSummaryPeerReviewer({ name: name });
  return updateSummaryPeerReviewer({});
};

export const updateSummaryPeerReviewDate = (date) => ({
  type: actionTypes.UPDATE_SUMMARY_PEER_REVIEW_DATE,
  payload: date,
});

export const updateSummaryPeerReviewComplete = (complete) => ({
  type: actionTypes.UPDATE_SUMMARY_PEER_REVIEW_COMPLETE,
  payload: complete,
});
