import Grid from "@mui/material/Grid";
import Visible from "components/common/Visible";
import PrintNote from "components/summary/program/PrintNote";

const PeerReview = ({ peerReview }) => {
  const isLegacyReview = typeof peerReview === "string";
  const reviewerMissing =
    !isLegacyReview && (peerReview.peerReviewer ?? "") === "";

  if (peerReview == null || reviewerMissing) {
    return <></>;
  }

  const markdown = isLegacyReview
    ? `Reviewed by ${peerReview}`
    : `Reviewed by ${peerReview.peerReviewer.name} on ${peerReview.date}\n\n${peerReview.commentary}`;

  return <PrintNote title={"Peer Review"} markdown={markdown} />;
};

const Notes = ({ summaryNotes, otherNotes, peerReview, spacing }) => {
  const hasAdditionalNotes = Object.values(otherNotes).some(
    (content) => (content || "").trim() !== ""
  );

  return (
    <div className={"page"} data-color-mode={"light"}>
      <div className={"print-notes"}>
        <Grid container spacing={spacing} direction={"column"}>
          {hasAdditionalNotes && (
            <>
              <PrintNote
                title={"Program Notes"}
                markdown={otherNotes.program}
              />
              <PrintNote title={"Claims"} markdown={otherNotes.claims} />
              <PrintNote title={"Exposure"} markdown={otherNotes.exposure} />
              <PrintNote title={"Scenarios"} markdown={otherNotes.scenarios} />
            </>
          )}
          <PrintNote
            title={"Risk Considerations"}
            markdown={summaryNotes.riskConsiderations}
          />
          <PrintNote
            title={"Capacity Utilization"}
            markdown={summaryNotes.capacityUtilization}
          />
          <PrintNote
            title={"Terms and Conditions"}
            markdown={summaryNotes.termsAndConditions}
          />
          <PrintNote
            title={"Pricing Comments"}
            markdown={summaryNotes.pricingComments}
          />
          <PrintNote
            title={"Considerations for Next Renewal"}
            markdown={summaryNotes.considerationsForNextRenewal}
          />
          <Visible byTag={"<Print>.peerReview"} defaultVisible={false}>
            <PeerReview peerReview={peerReview} />
          </Visible>
        </Grid>
      </div>
    </div>
  );
};

export default Notes;
