import Component from "../../Component";
import ClaimsV2Mapping from "../ingestion/ClaimsV2Mapping";
import ClaimsMapping from "./ClaimsMapping";
import InflationModelSelector from "./InflationModelSelector";
import DeleteIcon from "@mui/icons-material/Delete";
import GetApp from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import { Fragment } from "react";
import { connect } from "react-redux";
import * as claimsService from "services/claimsService";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";

const DownloadClaimsButton = ({ claimsQuery, filename }) => {
  const [
    triggerDownload,
    { isLoading, isError },
  ] = claimsService.useDownloadExcelClaimsMutation();

  return (
    <Button
      onClick={() => triggerDownload({ claimsQuery, filename })}
      startIcon={<GetApp />}
      isLoading={isLoading}
      hasError={isError}
    >
      {"Download"}
    </Button>
  );
};

const ClaimsDownloader = (props) => {
  return (
    <Component
      title={"Download Claims Data"}
      sm_width={12}
      md_width={8}
      growVertically
      options={
        <Grid container item xs={true} justifyContent={"right"}>
          <InflationModelSelector />
        </Grid>
      }
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding={"checkbox"} />
              <TableCell padding={"checkbox"} />
              <TableCell padding={"checkbox"} />
              <Tooltip title={"The filename of the file that was uploaded."}>
                <TableCell>{"Filename"}</TableCell>
              </Tooltip>
              <Tooltip title={"The sheet of the file that was uploaded."}>
                <TableCell>{"Sheet"}</TableCell>
              </Tooltip>
              <Tooltip
                title={
                  "The file after it has been mapped and how it is used for pricing."
                }
              >
                <TableCell>{"Transformed"}</TableCell>
              </Tooltip>
              <Tooltip title={"The original file that was uploaded."}>
                <TableCell>{"Original"}</TableCell>
              </Tooltip>
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.files || []).map((file) => {
              return (
                <Fragment key={file.claimsKey}>
                  <TableRow>
                    <TableCell padding={"checkbox"}>
                      <IconButton
                        icon={DeleteIcon}
                        onClick={() => props.hideClaimsFile(file.claimsKey)}
                        data-testid={`delete-claims-file-${file.prettyName}`}
                      />
                    </TableCell>
                    <TableCell padding={"checkbox"}>
                      <Checkbox
                        checked={file.checked}
                        inputProps={{ padding: 8 }}
                        onChange={(e) =>
                          props.claimsFileChecked(
                            file.claimsKey,
                            e.target.checked
                          )
                        }
                      />
                    </TableCell>
                    <TableCell padding={"checkbox"}>
                      <IconButton
                        icon={VisibilityIcon}
                        tooltip={"View Column Mappings"}
                        onClick={() => {
                          if (
                            props.claimsDisplayRef.claimsKey === file.claimsKey
                          )
                            props.closeClaimsMappingDialog();
                          else props.openClaimsMappingDialog(file.claimsKey);
                        }}
                      />
                    </TableCell>
                    <TableCell>{file.prettyName}</TableCell>
                    <TableCell>{file.sheetName}</TableCell>
                    <TableCell data-testid={"transformed-claims-download"}>
                      <DownloadClaimsButton
                        claimsQuery={{
                          ...props.claimsQuery,
                          keys: [file.claimsKey],
                          manualClaims: null,
                          exclusions: null,
                          overrides: null,
                          excludedKeys: null,
                        }}
                        filename={`${props.insured} - ${file.prettyName} - ${file.sheetName}.xlsx`}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={(e) => {
                          if (file.isFilesFile) {
                            props.downloadOriginalFromFiles(
                              file.fileId,
                              file.uploadedFilename,
                              file.claimsKey
                            );
                          } else {
                            props.downloadOriginal(
                              file.uploadedFilename,
                              file.prettyName,
                              file.claimsKey
                            );
                          }
                          e.stopPropagation();
                        }}
                        startIcon={<GetApp />}
                        isLoading={file.originalDownloading}
                        hasError={file.originalDownloadHasError}
                      >
                        {"Download"}
                      </Button>
                    </TableCell>
                  </TableRow>
                  {props.claimsDisplayRef.claimsKey === file.claimsKey ? (
                    <TableRow>
                      <TableCell colSpan={"7"}>
                        {file.fileBackendV2 ? (
                          <ClaimsV2Mapping fileId={file.claimsKey} />
                        ) : (
                          <ClaimsMapping />
                        )}
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </Fragment>
              );
            })}
            <TableRow>
              <TableCell padding={"checkbox"} />
              <TableCell padding={"checkbox"} />
              <TableCell padding={"checkbox"} />
              <TableCell />
              <TableCell />
              <TableCell colSpan={2}>
                <DownloadClaimsButton
                  claimsQuery={props.claimsQuery}
                  filename={`${props.insured}.xlsx`}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

const mapDispatchToProps = {
  openClaimsMappingDialog: claimsActions.showClaimsFileColumnMappingDisplay,
  closeClaimsMappingDialog: claimsActions.hideClaimsFileColumnMappingDisplay,
  downloadOriginal: claimsActions.downloadOriginal,
  downloadOriginalFromFiles: claimsActions.downloadOriginalFromFiles,
  claimsFileChecked: claimsActions.claimsFileChecked,
  hideClaimsFile: claimsActions.hideClaimsFile,
  editClaimsFile: claimsActions.editClaimsFile,
};

const mapStateToProps = (state) => ({
  claimsDisplayRef: claimsSelectors.selectClaimsFileColumnMappingDisplay(state),
  files: claimsSelectors.selectDownloadFiles(state),
  insured: state?.input?.program?.insured || "",
  claimsQuery: claimsSelectors.selectInputClaims(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsDownloader);
