import * as actionTypes from "store/actions/input/flags/flagsActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

export const INIT_STATE = {
  judgments: [],
  exposures: [],
  stateId: null,
};

const flagsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.flags,
      };
    case actionTypes.UPDATE_UW_JUDGMENT:
      return {
        ...state,
        judgments: [
          ...state.judgments.slice(0, action.payload.row),
          {
            ...state.judgments[action.payload.row],
            [action.payload.column]: action.payload.value,
          },
          ...state.judgments.slice(action.payload.row + 1),
        ],
      };
    case actionTypes.UPDATE_EXPOSURE_FLAG:
      return {
        ...state,
        exposures: [
          ...state.exposures.slice(0, action.payload.row),
          {
            ...state.exposures[action.payload.row],
            [action.payload.column]: action.payload.value,
          },
          ...state.exposures.slice(action.payload.row + 1),
        ],
      };
    case actionTypes.DELETE_UW_JUDGMENT:
      return {
        ...state,
        judgments: [
          ...state.judgments.slice(0, action.payload),
          ...state.judgments.slice(action.payload + 1),
        ],
      };
    case actionTypes.DELETE_EXPOSURE:
      return {
        ...state,
        exposures: [
          ...state.exposures.slice(0, action.payload),
          ...state.exposures.slice(action.payload + 1),
        ],
      };
    case actionTypes.UPDATE_STATE_ID:
      return {
        ...state,
        stateId: action.payload,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
        judgments: _insertDefaultJudgments({
          existingJudgments: [],
          defaultJudgmentNames:
            userSelectors.selectUserConfig(action.payload?.priorState ?? {})
              .defaultJudgmentNames ?? [],
        }),
        exposures:
          staticDataSelectors.selectAllConfig(action.payload?.priorState ?? {})
            ?.flags?.defaultExposureFlags ?? [],
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...INIT_STATE,
        ...state,
        judgments: _insertDefaultJudgments({
          existingJudgments: state.judgments ?? [],
          defaultJudgmentNames:
            userSelectors.selectUserConfig(action.payload?.priorState ?? {})
              .defaultJudgmentNames ?? [],
        }),
        exposures: _insertDefaultExposures({
          existing: staticDataSelectors.selectAllConfig(
            action.payload?.priorState ?? {}
          )?.flags?.resetExposureFlagsOnRenewal
            ? []
            : state.exposures ?? [],
          default:
            staticDataSelectors.selectAllConfig(
              action.payload?.priorState ?? {}
            )?.flags?.defaultExposureFlags ?? [],
        }),
      };
    default:
      return state;
  }
};

const _insertDefaultJudgments = ({
  existingJudgments,
  defaultJudgmentNames,
}) => {
  const defaultJudgments = defaultJudgmentNames
    .map(
      (judgmentName) =>
        existingJudgments.filter(
          (judgment) => judgment.name === judgmentName
        )[0] ?? {
          name: judgmentName,
          value: "",
          comments: "",
          canDelete: false,
          canEditName: false,
        }
    )
    .map((judgment) => ({
      value: "",
      comments: "",
      ...judgment,
      canDelete: false,
      canEditName: false,
    }));

  const defaultJudgmentIndices = defaultJudgmentNames.map((judgmentName) =>
    existingJudgments.findIndex((judgment) => judgment.name === judgmentName)
  );
  const otherJudgments = existingJudgments
    .filter((judgment, index) => !defaultJudgmentIndices.includes(index))
    .map((judgment) => ({
      value: "",
      comments: "",
      ...judgment,
      canDelete: true,
      canEditName: true,
    }));

  return [...defaultJudgments, ...otherJudgments];
};

const _insertDefaultExposures = ({ existing, default: default_ }) => {
  if (!existing) {
    return default_ ?? [];
  }
  if (!default_) {
    return existing ?? [];
  }

  const typesInDefaultsMap = Object.fromEntries(
    default_.map((flag) => [flag.type, true])
  );
  const typeToExistingMap = Object.fromEntries(
    existing.map((flag) => [flag.type, flag])
  );

  const defaulted = default_.map(
    (flag) => typeToExistingMap[flag.type] ?? flag
  );
  const remaining = existing.filter((flag) => !typesInDefaultsMap[flag.type]);

  return [...defaulted, ...remaining];
};

export default flagsReducer;
