import { createApi } from "@reduxjs/toolkit/query/react";
import * as hashes from "common/hashes";
import * as logger from "common/logger";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.analytics(args.url),
      args.payload || {}
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    submissionAnalytics: builder.query({
      queryFn: async ({ submission, engine, metric }) => {
        const result = await utils.authenticatedFetch(
          config.endpoints.analytics("calculate-submission-analytics"),
          {
            body: JSON.stringify({
              submission,
              engine,
              metric,
            }),
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const responseJson = await result.json();
        const submissionHash = await hashes.hashDict(submission);
        return {
          data: {
            ...responseJson,
            submissionHash,
          },
        };
      },
    }),
    rateChangeAnalytics: builder.query({
      query: ({ submission, engine, args }) => ({
        url: "calculate-rate-change-analytics",
        payload: {
          body: JSON.stringify({
            submission,
            engine,
            args,
          }),
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        },
      }),
    }),
  }),
});

export const {
  useSubmissionAnalyticsQuery,
  useLazySubmissionAnalyticsQuery,
  useLazyRateChangeAnalyticsQuery,
} = analyticsApi;
