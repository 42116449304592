import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import Component from "components/Component";
import Options from "components/common/Options";
import { useDispatch, useSelector } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const IncludedLossTypes = () => {
  const dispatch = useDispatch();

  const allLossTypes =
    useSelector(exposureSelectors.selectLossCategories) ?? [];

  const includedLossTypes = useSelector(
    pricingSelectors.selectClaimsIncludedLossTypes
  );

  const items = allLossTypes.map(({ key, display }) => ({
    id: key,
    label: display,
    selected: !!includedLossTypes?.includes(key),
  }));

  const disableFiltering = () =>
    dispatch(pricingActions.updateClaimsIncludedLossTypes(null));

  const selectOnlyLossTypes = (lossType) =>
    dispatch(pricingActions.updateClaimsIncludedLossTypes([lossType]));

  const selectAllLossTypes = () =>
    dispatch(
      pricingActions.updateClaimsIncludedLossTypes(
        allLossTypes.map(({ key }) => key)
      )
    );

  const selectNoLossTypes = () =>
    dispatch(pricingActions.updateClaimsIncludedLossTypes([]));

  const toggleAllLossTypes = (allSelected) =>
    allSelected ? selectNoLossTypes() : selectAllLossTypes();

  const toggleLossType = ({ id }) => {
    const newIncludedLossTypes = includedLossTypes?.includes(id)
      ? includedLossTypes.filter((x) => x !== id)
      : [...(includedLossTypes ?? []), id];

    if (!newIncludedLossTypes.length) {
      // Don't allow the user to toggle the last selected loss type.
      return;
    }

    dispatch(
      pricingActions.updateClaimsIncludedLossTypes(newIncludedLossTypes)
    );
  };

  const isFiltering = includedLossTypes != null;

  return (
    <Component
      title={"Loss Type Filter"}
      subtitle={
        "Filter some Loss Types from the claims that are used in pricing."
      }
      loading={!allLossTypes?.length}
      options={
        <div>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isFiltering}
                  onChange={(event) =>
                    event.target.checked
                      ? selectAllLossTypes()
                      : disableFiltering()
                  }
                  size={"small"}
                />
              }
              label={"Filter out some Loss Types"}
              size={"small"}
            />
          </FormGroup>
        </div>
      }
    >
      {isFiltering ? (
        <Options
          items={items}
          onClick={toggleLossType}
          onDoubleClick={(item) => selectOnlyLossTypes(item.id)}
          onToggleAll={toggleAllLossTypes}
          variant={"button"}
        />
      ) : (
        <Typography>
          {"Not filtering. All loss types are being included."}
        </Typography>
      )}
    </Component>
  );
};

export default IncludedLossTypes;
