import SubmissionSelector from "components/common/SubmissionSearch";
import { connect } from "react-redux";
import * as peersActions from "store/actions/peers/peersActions";
import * as peersSelectors from "store/selectors/peers/peersSelectors";

export const PeersSearch = ({ peers, addPeer }) => {
  return (
    <SubmissionSelector
      label={"Peers"}
      excludeFilenames={peers?.map((peer) => peer.filename)}
      onSelect={(submission) => addPeer(submission)}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    peers: peersSelectors.getPeers(state),
  };
};

const mapDispatchToProps = {
  addPeer: peersActions.loadPeer,
};

export default connect(mapStateToProps, mapDispatchToProps)(PeersSearch);
