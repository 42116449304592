export const buildGridData = ({ stateTiv, overrides }) => {
  const states = [
    ...Object.keys(stateTiv ?? {}),
    ...Object.keys(overrides ?? {}).filter(
      (state) => stateTiv?.[state] == null
    ),
  ];

  const grid = states.map((state) => ({
    ...overrides?.[state],
    id: state,
    state,
    calculatedTiv: stateTiv?.[state],
    overriddenTiv: overrides?.[state]?.tiv,
    tiv: overrides?.[state]?.tiv ?? stateTiv?.[state] ?? null,
    isCalculated: stateTiv?.[state] != null,
  }));

  return grid.sort((a, b) => (b.tiv ?? 0) - (a.tiv ?? 0));
};
