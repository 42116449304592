import ClearIcon from "@mui/icons-material/Clear";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Stack } from "@mui/material";
import Component from "components/Component";
import CategorisedOptions from "components/common/CategorisedOptions";
import IconButton from "components/common/IconButton";
import * as config from "config";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as scenariosActions from "store/actions/input/scenarios/scenariosActions";
import * as programSelectors from "store/selectors/input/program/programSelectors";

const LevelledScenarios = () => {
  const dispatch = useDispatch();

  const setScenarioLevels = (levels) =>
    dispatch(scenariosActions.setScenarioLevels(levels));

  const scenarios = useSelector(
    (state) => state.input.scenarios?.scenarios || {}
  );
  const program = useSelector(programSelectors.selectResolvedProgram);

  const {
    data: scenarioConfig,
    isSuccess: isScenarioConfigSuccess,
  } = staticDataService.useConfigQuery("scenarios");

  const options = (
    scenarioConfig?.scenarios ??
    (isScenarioConfigSuccess ? config.SCENARIOS : [])
  ).map((category) => {
    return {
      name: category.name,
      items: category.items.map((item) => {
        return {
          ...item,
          level: scenarios[item.id],
        };
      }),
    };
  });

  const defaultScenarios =
    scenarioConfig?.defaultScenarioLevelsByIndustryClass?.[
      program?.industryClass
    ] ?? null;

  return (
    <Component
      title={"Scenarios"}
      subtitle={"The disaster scenarios to which this insured is exposed."}
    >
      <CategorisedOptions
        levels={[
          "",
          "low-severity",
          "medium-severity",
          "high-severity",
          "maximum-severity",
        ]}
        categorisedItems={options}
        onClick={({ id, level }) =>
          setScenarioLevels({
            ...scenarios,
            [id]: level ? level - 1 : 4,
          })
        }
        variant={"button"}
        actions={
          <Stack direction={"row"} spacing={config.GRID_SPACING} padding={0}>
            {defaultScenarios && (
              <IconButton
                variant={"bright"}
                icon={SettingsBackupRestoreIcon}
                tooltip={"Reset to defaults"}
                scale={"small"}
                onClick={() => setScenarioLevels(defaultScenarios)}
              />
            )}
            <IconButton
              variant={"bright"}
              icon={ClearIcon}
              tooltip={"Clear all scenarios"}
              scale={"small"}
              onClick={() => setScenarioLevels({})}
            />
          </Stack>
        }
      />
    </Component>
  );
};

export default LevelledScenarios;
