import * as actionTypes from "../../actions/user/userActionTypes";

export const ATTRIBUTES_STATUS = {
  IDLE: "idle",
  RUNNING: "running",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

export const INITIAL_STATE = {
  attributes: {},
  attributesStatus: ATTRIBUTES_STATUS.IDLE,
};

const userReducer = (state = INITIAL_STATE, action) => {
  if (state === undefined) {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case actionTypes.LOAD_USER_ATTRIBUTES_STARTED:
      return {
        ...state,
        attributes: {},
        attributesStatus: ATTRIBUTES_STATUS.RUNNING,
      };
    case actionTypes.LOAD_USER_ATTRIBUTES_RETURNED:
      return {
        ...state,
        attributes: action.payload,
        attributesStatus: ATTRIBUTES_STATUS.SUCCEEDED,
      };
    default:
      return state;
  }
};

export default userReducer;
