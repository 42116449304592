import { Alert, Box, Stack } from "@mui/material";
import * as numbers from "common/numbers";
import Component from "components/Component";
import Button from "components/common/Button";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import * as analyticsService from "services/analyticsService";
import * as staticDataService from "services/staticDataService";
import * as baseLayerBurnStore from "store/baseLayerBurn";
import * as submissionSelector from "store/selectors/submission/submissionSelector";

const CalculateProgramPricingButton = ({ updateBaseLayerBurn }) => {
  const {
    data: config,
    isLoading: isConfigLoading,
  } = staticDataService.useConfigQuery("analytics");

  const submission = useSelector(
    submissionSelector.selectResolvedSubmissionWithoutInput
  );

  const [
    _triggerPricing,
    { isFetching: isPricingFetching },
  ] = analyticsService.useLazySubmissionAnalyticsQuery();

  const triggerPricing = async () => {
    updateBaseLayerBurn({ pricingCalculation: null });
    const request = {
      engine: config?.programPricing?.engine ?? "united_xs_v1",
      metric: "program_pricing",
      submission,
    };
    const response = await _triggerPricing(request);
    updateBaseLayerBurn({
      pricingCalculation: {
        request,
        response: response?.data,
      },
    });
  };

  return (
    <Button
      onClick={triggerPricing}
      isLoading={isPricingFetching}
      disabled={isConfigLoading}
    >
      {"Calculate"}
    </Button>
  );
};

const Row = ({ children }) => (
  <Stack
    direction={"row"}
    alignItems={"center"}
    justifyContent={"flex-start"}
    spacing={1}
  >
    {children}
  </Stack>
);

const TitleCell = ({ children }) => (
  <Box sx={{ width: 200, textAlign: "right", fontWeight: "bold" }}>
    {children}
  </Box>
);

const ValueCell = ({ children }) => (
  <Box sx={{ width: 200, textAlign: "right" }}>{children}</Box>
);

const Pricing = ({ disabled, updateBaseLayerBurn }) => {
  const baseLayerBurn = useSelector(baseLayerBurnStore.selectBaseLayerBurn);

  return (
    <Component title={"Base Layer Pricing"}>
      <Stack direction={"column"} spacing={2}>
        <Row>
          <TitleCell>{"Simple Burn"}</TitleCell>
          <ValueCell>
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              defaultValue={""}
              decimalScale={0}
              className={"monospace"}
              fixedDecimalScale
              value={baseLayerBurn?.pricing?.values?.simpleBaseLayerBurn ?? ""}
            />
          </ValueCell>
        </Row>
        <Row>
          <TitleCell>{"Selected Price"}</TitleCell>
          <ValueCell>
            <PrettyNumberTextField
              variant={"filled"}
              hiddenLabel
              size={"small"}
              fullWidth
              disabled={disabled}
              value={baseLayerBurn?.selectedBaseLayerPrice}
              onChangeNumber={(baseLayerPriceOverride) =>
                updateBaseLayerBurn({ baseLayerPriceOverride })
              }
              inputProps={{ style: { textAlign: "right" } }}
              allowClear={baseLayerBurn?.baseLayerPriceOverride != null}
              offFormatter={numbers.dpString(0)}
            />
          </ValueCell>
        </Row>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"top"}
          width={"100%"}
          spacing={2}
        >
          {baseLayerBurn?.pricing?.failure && (
            <Alert severity={"error"} sx={{ width: "100%" }}>
              {baseLayerBurn?.pricing?.failure}
            </Alert>
          )}
          <CalculateProgramPricingButton
            updateBaseLayerBurn={updateBaseLayerBurn}
          />
        </Stack>
      </Stack>
    </Component>
  );
};

export default Pricing;
