import * as stateHooks from "./stateHooks";
import { useEffect, useState } from "react";

export const useLocalStorageStringState = (key, { default: default_ }) => {
  const [internalState, setInternalState] = useState(default_);

  const normalize = (key) => {
    key = key.trim();
    const prefix = "MMM.";
    return key.startsWith(prefix) ? key : prefix + key;
  };

  useEffect(() => {
    const value = localStorage.getItem(normalize(key));
    if (value !== null) {
      setInternalState(value);
    }
  }, [key]);

  useEffect(() => {
    const handleStorageChange = (e) => {
      const changeKey = e.detail?.key ?? e.key;
      const changeValue = e.detail?.value ?? e.newValue;
      if (changeKey === normalize(key) && changeValue !== internalState) {
        setInternalState(changeValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("storageSamePage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("storageSamePage", handleStorageChange);
    };
  }, [key, internalState]);

  const setState = (value) => {
    localStorage.setItem(normalize(key), value);
    setInternalState(value);
    const event = new CustomEvent("storageSamePage", {
      detail: { key: normalize(key), value },
    });
    window.dispatchEvent(event);
  };

  return [internalState, setState];
};

export const useLocalStorageJsonState = (key, { default: default_ } = {}) => {
  const parse = (x) => (!x ? null : JSON.parse(x));
  const stringify = (x) => (!x ? "" : JSON.stringify(x));

  const [state, setState] = stateHooks.useLocalStorageStringState(key, {
    default: stringify(default_),
  });

  return [parse(state), (x) => setState(stringify(x))];
};
