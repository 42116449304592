import { Alert } from "@mui/material";
import { connect } from "react-redux";
import * as maintenanceStatusActions from "store/actions/temp/maintenance/maintenanceStatusActions";
import * as maintenanceSelectors from "store/selectors/temp/maintenanceStatus/maintenanceStatusSelectors";

const MaintenanceWarning = ({ message, display, close }) =>
  display ? (
    <Alert severity={"warning"} onClose={close}>
      {message}
    </Alert>
  ) : (
    <></>
  );

const mapDispatchToProps = {
  close: maintenanceStatusActions.closeWarning,
};

const mapStateToProps = (state) => ({
  message: maintenanceSelectors.selectMessage(state),
  display: maintenanceSelectors.selectIsShowingWarning(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceWarning);
