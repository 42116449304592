import * as actionTypes from "./pricingActionTypes";
import * as actions from "./pricingActions";
import { createAction } from "@reduxjs/toolkit";
import { HandledError } from "common/errors";
import * as ids from "common/ids";
import * as logger from "common/logger";
import * as config from "config";
import * as _ from "lodash";
import * as errorActions from "store/actions/error/errorActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as submissionSelectors from "store/selectors/submission/submissionSelector";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as utils from "utils";

export const updateComment = (comment) => {
  return {
    type: actionTypes.UPDATE_LAYER_COMMENT,
    payload: comment,
  };
};

export const updateLayer = ({ layerId, values }) => {
  return {
    type: actionTypes.UPDATE_LAYER,
    payload: { layerId, values },
  };
};

export const updateActiveLayerLimit = (limit) => {
  return {
    type: actionTypes.UPDATE_ACTIVE_LAYER_LIMIT,
    payload: limit,
  };
};

export const updateActiveLayerAttachment = (attachment) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_ATTACHMENT,
  payload: attachment,
});

export const updateActiveLayerLineSizeAbsolute = (line_size_absolute) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_LINE_SIZE_ABSOLUTE,
  payload: line_size_absolute,
});

export const setActiveLayerPricingSelectedModel = createAction(
  "pricing/setActiveLayerPricingSelectedModel",
  ({ model }) => {
    return {
      payload: { model },
    };
  }
);

export const setActiveLayerPricingSelectedPrice = createAction(
  "pricing/setActiveLayerPricingSelectedPrice",
  ({ model, value }) => {
    return {
      payload: { model, value },
    };
  }
);

export const refreshActiveLayerPricing = () => (dispatch, getState) => {
  const state = getState();
  const backend = staticDataSelectors.selectAnalyticsConfig(state)?.layerPricing
    ?.backend;
  if (backend === "analytics") {
    return _refreshActiveLayerPricing_analytics(dispatch, getState);
  } else {
    return _refreshActiveLayerPricing_claims(dispatch, getState);
  }
};

const _refreshActiveLayerPricing_analytics = (dispatch, getState) => {
  dispatch({
    type: actionTypes.REFRESH_ACTIVE_LAYER_PRICING,
  });
  const state = getState();
  const layerPricingConfig =
    staticDataSelectors.selectAnalyticsConfig(state)?.layerPricing ?? {};
  const resolvedSubmission = submissionSelectors.selectResolvedSubmission(
    state
  );

  const layer = pricingSelectors.getActiveLayer(state);
  const query = {
    limit: layer.limit,
    attachment: layer.attachment,
    targetLayer: layer.index,
  };

  Promise.all(
    layerPricingConfig.models.map((modelConfig) =>
      utils
        .authenticatedFetch(
          config.endpoints.analytics("calculate-submission-analytics"),
          {
            body: JSON.stringify({
              engine: modelConfig.engine ?? "default",
              metric: "layer_pricing",
              submission: resolvedSubmission,
            }),
            method: "post",
          }
        )
        .then((result) => result.json())
    )
  )
    .then((results) => {
      if (results.some((result) => result.failure)) {
        const failures = results.map((_) => _.failure).filter((_) => !!_);
        dispatch(
          errorActions.handleError(null, {
            title: "Unable to calculate pricing.",
            message: failures.join("\n"),
            type: "PRICING_ERROR",
          })
        );
        dispatch(
          actions.activeLayerPricingFailed(
            query,
            "Failed to calculate layer.",
            { failures }
          )
        );
        return;
      }
      dispatch(
        actions.activeLayerPricingReturned({
          query,
          prices: Object.fromEntries(
            _.zip(
              results,
              layerPricingConfig.models
            ).map(([result, modelConfig]) => [modelConfig.key, result.values])
          ),
          selectedMeasures: Object.fromEntries(
            layerPricingConfig.models.map((modelConfig) => [
              modelConfig.key,
              modelConfig.selectedPrice?.defaultMeasure,
            ])
          ),
          defaultModel: layerPricingConfig.defaultModel,
        })
      );
    })
    .catch((error) => {
      if (error.status === 400) {
        dispatch(
          errorActions.handleError(null, {
            title: "Unable to calculate pricing.",
            message: error.description,
            type: "PRICING_ERROR",
          })
        );
      }
      dispatch(
        actions.activeLayerPricingFailed(
          query,
          "Failed to understand response from server.",
          error
        )
      );
    });
};

const _refreshActiveLayerPricing_claims = (dispatch, getState) => {
  dispatch({
    type: actionTypes.REFRESH_ACTIVE_LAYER_PRICING,
  });
  const state = getState();
  const layer = pricingSelectors.getActiveLayer(state);
  const query = {
    limit: layer.limit,
    attachment: layer.attachment,
    targetLayer: layer.index,
  };

  const purePremiumKeyToPricingKeyMap = {
    [config.PURE_BURN_COST_KEY]: "burningCost",
    [config.ILF_FROM_BASE_KEY]: "ilfFromBase",
    [config.PURE_MODEL]: "modelPrice",
    [config.SCALED_EXPERIENCE]: "pricingModelledLossCost",
    [config.RECOMMENDED_BLEND]: "recommendedPrice",
    [config.UW_ADJUSTED]: "uwAdjustedPrice",
  };

  utils
    .authenticatedFetch(config.endpoints.claims("pricing/pure-premium"), {
      body: JSON.stringify(pricingSelectors.purePremiumRequest(state)),
      method: "post",
    })
    .then((result) => result.json())
    .then((data) => {
      dispatch(
        actions.activeLayerPricingReturned({
          query,
          prices: {
            __v1__: Object.fromEntries(
              Object.entries(data)
                .filter(([key, _]) => key in purePremiumKeyToPricingKeyMap)
                .map(([key, value]) => [
                  purePremiumKeyToPricingKeyMap[key],
                  value,
                ])
            ),
          },
          selectedMeasures: {
            __v1__: "uwAdjustedPrice",
          },
          defaultModel: "__v1__",
        })
      );
    })
    .catch((error) => {
      if (error.status === 400) {
        dispatch(
          errorActions.handleError(null, {
            title: "Unable to calculate pricing.",
            message: error.description,
            type: "PURE_PREMIUM_PRICING_ERROR",
          })
        );
      }
      dispatch(
        actions.activeLayerPricingFailed(
          query,
          "Failed to understand response from server.",
          error
        )
      );
    });
};

export const activeLayerPricingReturned = ({
  query,
  prices,
  selectedMeasures,
  defaultModel,
}) => ({
  type: actionTypes.ACTIVE_LAYER_PRICING_RETURNED,
  payload: {
    query,
    prices,
    selectedMeasures,
    defaultModel,
  },
});

export const activeLayerPricingFailed = (query, message, error) => {
  logger.exception(error);
  return {
    type: actionTypes.ACTIVE_LAYER_PRICING_FAILED,
    payload: {
      ...query,
      message,
    },
  };
};

export const updateBaseLayerLimit = (limit) => ({
  type: actionTypes.UPDATE_BASE_LAYER_LIMIT,
  payload: limit,
});

export const updateBaseLayerFrom = (from) => {
  return {
    type: actionTypes.UPDATE_BASE_LAYER_FROM,
    payload: from,
  };
};

export const updateBaseLayerTo = (to) => {
  return {
    type: actionTypes.UPDATE_BASE_LAYER_TO,
    payload: to,
  };
};

export const updateBaseLayerAttachment = (attachment) => ({
  type: actionTypes.UPDATE_BASE_LAYER_ATTACHMENT,
  payload: attachment,
});

export const updateActiveLayerGrossPremium = (premium) => ({
  type: actionTypes.UPDATE_GROSS_PREMIUM,
  payload: premium,
});

export const updateActiveLayerGrossPPM = (ppm) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_GROSS_PPM,
  payload: ppm,
});

export const updateActiveLayerShareOfLine = (shareOfLine) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_SHARE_OF_LINE,
  payload: shareOfLine,
});

export const updateActiveLayerTRIA = (TRIA) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_TRIA,
  payload: TRIA,
});

export const updateActiveLayerShareOfPremium = (shareOfPremium) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_SHARE_OF_PREMIUM,
  payload: shareOfPremium,
});

export const updateActiveLayerAuthorizedLimit = (authorizedLimit) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_AUTHORIZED_LIMIT,
  payload: authorizedLimit,
});

export const updateActiveLayerBrokerage = (brokerage) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_BROKERAGE,
  payload: brokerage,
});

export const updateActiveLayerOtherAcquisitionCosts = (costs) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_OTHER_ACQUISITION_COSTS,
  payload: costs,
});

export const updateActiveLayerProfit = (profit) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_PROFIT,
  payload: profit,
});

export const updateActiveLayerExpenses = (expenses) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_EXPENSES,
  payload: expenses,
});

export const updateInsuredWeight = (weight) => ({
  type: actionTypes.UPDATE_INSURED_WEIGHT,
  payload: weight,
});

export const updateImprovexWeight = (weight) => ({
  type: actionTypes.UPDATE_IMPROVEX_WEIGHT,
  payload: weight,
});

export const updateChosenExposureMetric = (metric) => ({
  type: actionTypes.UPDATE_CHOSEN_EXPOSURE_METRIC,
  payload: metric,
});

export const updateBaseLayerExpectedNumberOfBlendedLosses = (
  numberOfEvents
) => ({
  type: actionTypes.UPDATE_BASE_LAYER_EXPECTED_NUMBER_OF_BLENDED_LOSSES,
  payload: numberOfEvents,
});

export const updateNumberOfEventsAtInsuredImpactingBaseLayer = (
  numberOfEvents
) => ({
  type: actionTypes.UPDATE_NUMBER_OF_EVENTS_AT_INSURED_IMPACTING_BASE_LAYER,
  payload: numberOfEvents,
});

export const makeRecalculateInsuredExpectedEventsBody = (state) => {
  const naicsCode = state?.input?.program?.naics?.code;
  if (naicsCode == null) {
    throw new HandledError({
      title: "MISSING_NAICS_CODE",
      message: "A NAICS Code is required.",
    });
  }
  const baseLayer = {
    limit: state.pricing?.baseLayer?.limit,
    attachment: state.pricing?.baseLayer?.attachment,
  };
  if (baseLayer.limit == null || baseLayer.attachment == null) {
    throw new HandledError({
      title: "MISSING_BASE_LAYER",
      message: "A base layer is required.",
    });
  }
  return {
    averagesOverLastYears: [6, 3],
    exposureSeries: utils.exposureSeries(
      state.input.exposure.data,
      state.pricing.selectedExposureMetric.key
    ),
    turnoverCategory: state.input.program.turnoverCategory,
    baseLayer,
    naicsCode,
    currentTurnover: utils.latestTurnover(state.input.exposure.data),
    in_US: state.input.program.inUS,
    claimsQuery: pricingSelectors.selectPricingClaims(state),
  };
};

export const recalculateInsuredExpectedEvents = () => ({
  type: actionTypes.RECALCULATE_INSURED_EXPECTED_EVENTS,
});

export const updateActive = (index) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER,
  payload: index,
});

export const deleteLayer = (index) => ({
  type: actionTypes.DELETE_LAYER,
  payload: index,
});

export const addLayer = () => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: actionTypes.ADD_LAYER,
    payload: { state },
  });
};

export const updateActiveLayerReference = (reference) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_REFERENCE,
  payload: reference,
});

export const updateActiveLayerStatus = (status) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_STATUS,
  payload: status,
});

export const updateActiveLayerPaymentTerms = (paymentTerms) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_PAYMENT_TERMS,
  payload: paymentTerms ?? null,
});

export const updateActiveLayerReinsurance = (reinsurance) => ({
  type: actionTypes.UPDATE_ACTIVE_LAYER_REINSURANCE,
  payload: reinsurance,
});

export const updateAllLayersReinsurance = (reinsurance) => ({
  type: actionTypes.UPDATE_ALL_LAYERS_REINSURANCE,
  payload: reinsurance,
});

export const setAsBaseLayerForTowerPricingScaling = (index) => ({
  type: actionTypes.SET_AS_BASE_LAYER_FOR_TOWER_PRICING_SCALING,
  payload: index,
});

export const updateLayerStatus = (index, status) => ({
  type: actionTypes.UPDATE_LAYER_STATUS,
  payload: { index, status },
});

export const updateLayerPricingNote = (note) => ({
  type: actionTypes.UPDATE_LAYER_PRICING_NOTE,
  payload: note,
});

export const updateFrequencyPricingNote = (note) => ({
  type: actionTypes.UPDATE_FREQUENCY_PRICING_NOTE,
  payload: note,
});

export const updateActiveLayerMinimumEarnedPremium = (
  minimumEarnedPremium
) => ({
  type: actionTypes.UPDATE_MINIMUM_EARNED_PREMIUM,
  payload: minimumEarnedPremium,
});

export const setActiveLayerPolicyForm = (policyForm) => ({
  type: actionTypes.SET_ACTIVE_LAYER_POLICY_FORM,
  payload: policyForm,
});

export const addPremiumAdjustment = createAction(
  "pricing/addPremiumAdjustment",
  ({ layerId }) => {
    return {
      payload: {
        layerId,
        premiumAdjustment: {
          id: ids.makeNewId(),
        },
      },
    };
  }
);

export const editPremiumAdjustment = createAction(
  "pricing/editPremiumAdjustment",
  ({ layerId, premiumAdjustmentId, edits }) => {
    return {
      payload: { layerId, premiumAdjustmentId, edits },
    };
  }
);

export const deletePremiumAdjustment = createAction(
  "pricing/deletePremiumAdjustment",
  ({ layerId, premiumAdjustmentId }) => {
    return {
      payload: { layerId, premiumAdjustmentId },
    };
  }
);

export const deleteActiveLayerCustomOverrides = createAction(
  "pricing/deleteActiveLayerCustomOverrides"
);

export const deletePremiumAdjustmentCustomOverrides = createAction(
  "pricing/deletePremiumAdjustmentCustomOverrides",
  ({ layerId, premiumAdjustmentId }) => {
    return {
      payload: { layerId, premiumAdjustmentId },
    };
  }
);

export const updatePricingInput = ({ key, layerId = null, values }) => {
  return {
    type: actionTypes.UPDATE_PRICING_INPUT,
    payload: { key, layerId, values },
  };
};

export const setPricingAssumption = (name, value) => ({
  type: actionTypes.SET_PRICING_ASSUMPTION,
  payload: { [name]: value },
});

export const updateClaimsDateRange = ({ start, end }) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_CLAIMS_DATE_RANGE,
    payload: { start, end },
  });
};

export const updateClaimsIncludedLossTypes = (includedLossTypes) => (
  dispatch
) => {
  dispatch({
    type: actionTypes.UPDATE_CLAIMS_INCLUDED_LOSS_TYPES,
    payload: includedLossTypes,
  });
};
