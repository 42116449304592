import * as types from "store/actions/persistence/persistenceActionTypes";

const INITIAL_STATE = {
  saveTransferInProgress: false,
  saveTransferCompleted: false,
};
const tempTransferReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SAVE_ACCEPTED_TRANSMISSION:
      return {
        ...state,
        saveTransferInProgress: true,
      };
    case types.ACCEPTED_TRANSMISSION_SAVED:
      return {
        ...state,
        saveTransferCompleted: true,
      };
    case types.ACCEPTED_TRANSMISSION_CLEANUP:
      return {
        ...state,
        saveTransferInProgress: false,
        saveTransferCompleted: false,
      };
    default:
      return state;
  }
};

export default tempTransferReducer;
