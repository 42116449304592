import TopNav from "./TopNav";

const DashboardHeader = ({
  tabs,
  isFullWidth,
  parentPath,
  navigationState,
  setNavigationState,
  topNavBorderless,
}) => {
  return (
    <div className={"dashboard-header"}>
      <TopNav
        tabs={tabs}
        parentPath={parentPath}
        navigationState={navigationState}
        setNavigationState={setNavigationState}
        isFullWidth={isFullWidth}
        borderless={topNavBorderless}
      />
    </div>
  );
};

export default DashboardHeader;
