import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { threeSFString } from "common/numbers";
import Component from "components/Component";
import ExposureSelector from "components/common/ExposureSelector";
import NumberTextField from "components/common/NumberTextField";
import Visible from "components/common/Visible";
import UpdateLossFrequencyButton from "components/pricing/lossFrequency/UpdateLossFrequencyButton";
import * as config from "config";
import React from "react";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

export const InsuredAveragesComponent = (props) => {
  return (
    <Component
      title={"Insured Averages"}
      subtitle={
        "Based on the insured’s claims data and the selected exposure metric."
      }
      error={props.loadingInsuredExpectedEventsFailed}
    >
      <Table aria-label={"Insured Averages"} size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>{""}</TableCell>
            <TableCell align={"right"}>{"Frequency"}</TableCell>
            <TableCell align={"right"}>{"# of Losses"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={"insured-averages-all"} id={"insured-averages-all"}>
            <TableCell component={"th"} scope={"row"}>
              <Typography>{"All"}</Typography>
            </TableCell>
            <TableCell align={"right"}>
              <Typography className={"monospace"}>
                {threeSFString(props.overallAverageNumberOfClaims?.frequency)}
              </Typography>
            </TableCell>
            <TableCell align={"right"}>
              <Typography className={"monospace"}>
                {threeSFString(props.overallAverageNumberOfClaims?.claims)}
              </Typography>
            </TableCell>
          </TableRow>
          {props.averageNumberOfClaims.map((claimFrequency) => {
            return (
              <TableRow
                key={`insured-averages-${claimFrequency.label}`}
                id={`insured-averages-${claimFrequency.label}`}
              >
                <TableCell component={"th"} scope={"row"}>
                  <Typography>{`Last ${claimFrequency.label} Years`}</Typography>
                </TableCell>
                <TableCell align={"right"}>
                  <Typography className={"monospace"}>
                    {threeSFString(claimFrequency?.frequency)}
                  </Typography>
                </TableCell>
                <TableCell align={"right"}>
                  <Typography className={"monospace"}>
                    {threeSFString(claimFrequency?.claims)}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Box mt={2}>
        <Grid container spacing={config.GRID_SPACING}>
          <Grid item xs={12} lg={6}>
            <ExposureSelector
              selectedExposureMetric={props.selectedExposureMetric}
              exposureRows={props.exposureRows}
              updateChosenExposureMetric={props.updateChosenExposureMetric}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <NumberTextField
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { textAlign: "right" } }}
              label={"Selected # of Losses"}
              autoComplete={"off"}
              variant={"filled"}
              onChange={props.updateNumberOfEventsAtInsuredImpactingBaseLayer}
              value={
                props.underwriterSelectedNumberOfClaims ||
                threeSFString(props.averageNumberOfClaims[0]?.claims, {
                  emptyValue: "",
                })
              }
              helperText={`Recommended: ${threeSFString(
                props.averageNumberOfClaims[0]?.claims
              )}`}
              FormHelperTextProps={{ sx: { marginLeft: "auto" } }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Visible byTag={"<InsuredAverages>.update"}>
        <UpdateLossFrequencyButton />
      </Visible>
    </Component>
  );
};

const mapDispatchToProps = {
  updateChosenExposureMetric: pricingActions.updateChosenExposureMetric,
  updateNumberOfEventsAtInsuredImpactingBaseLayer:
    pricingActions.updateNumberOfEventsAtInsuredImpactingBaseLayer,
  recalculateInsuredExpectedEvents:
    pricingActions.recalculateInsuredExpectedEvents,
};

export const mapStateToProps = (state) => {
  return {
    averageNumberOfClaims: state.pricing.frequency.averageNumberOfClaims || [],
    selectedExposureMetric: state.pricing.selectedExposureMetric,
    overallAverageNumberOfClaims:
      state.pricing.frequency.overallAverageNumberOfClaims,
    exposureRows: exposureSelectors.getExposuresUsed(state),
    underwriterSelectedNumberOfClaims:
      state.pricing.frequency.underwriterSelectedNumberOfClaims,
    loadingInsuredExpectedEvents:
      state.pricing.frequency.loadingInsuredExpectedEvents,
    loadingInsuredExpectedEventsFailed:
      state.pricing.frequency.loadingInsuredExpectedEventsFailed,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsuredAveragesComponent);
