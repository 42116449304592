import * as exposureSelectors from "./exposure/exposureSelectors";
import * as programSelectors from "./program/programSelectors";
import { createSelector } from "reselect";

export const ilfQuery = createSelector(
  [
    programSelectors.getPricingNaicsCode,
    programSelectors.getTurnoverCategory,
    programSelectors.getInUs,
    exposureSelectors.getLatestTurnover,
  ],
  (naics, turnoverCategory, inUs, turnover) => ({
    naics,
    turnoverCategory,
    inUs,
    turnover,
  })
);
