import * as expiringProgramActions from "../../../store/actions/input/expiringProgram/expiringProgramActions";
import * as expiringProgramSelectors from "../../../store/selectors/input/expiringProgram/expiringProgramSelectors";
import Component from "../../Component";
import PrettyNumberTextField from "../../common/PrettyNumberTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "components/common/IconButton";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";

const columnHeaders = [
  { title: "", width: 0, align: "right", description: "" },
  {
    title: "Carrier",
    width: 200,
    align: "left",
    description: "The name of the insurer.",
  },
  {
    title: "Limit",
    width: 150,
    align: "right",
    description: "The layer limit.",
  },
  {
    title: "Attachment",
    width: 150,
    align: "right",
    description: "The layer attachment.",
  },
  {
    title: "Market GWP",
    width: 150,
    align: "right",
    description: "The 100% bound premium.",
  },
  {
    title: "Market RPM",
    width: 80,
    align: "right",
    description: "100% bound premium / limit.",
  },
  {
    title: "Rate Relativity",
    width: 80,
    align: "right",
    description: "Market RPM relative to the layer below (%).",
  },
];

const makeTableHeaderCell = (title, width, align, index) => (
  <TableCell
    align={align}
    valign={"bottom"}
    style={{ minWidth: width, verticalAlign: "bottom" }}
    key={`EXPIRING_PROGRAM_HEADER_COLUMN_${index}`}
  >
    {title}
  </TableCell>
);

const makeTableHeader = ({ title, width, align, description }, index) =>
  description === "" ? (
    makeTableHeaderCell(title, width, align, index)
  ) : (
    <Tooltip
      title={description}
      key={`EXPIRING_PROGRAM_HEADER_TOOLTIP_${index}`}
    >
      {makeTableHeaderCell(title, width, align, index)}
    </Tooltip>
  );

export const ExpiringProgramRow = (props) => {
  var carrier = "";
  var disabled = false;
  if (props.shares) {
    for (const share of props.shares) {
      if (carrier) carrier += " / ";
      if (!share.carrier || !share.carrier.trim()) carrier += "[Unknown]";
      else carrier += share.carrier;
      disabled = true;
    }
  }
  if (!carrier) {
    carrier = props.carrier || "";
  }
  return (
    <TableRow style={props.isSelectedRow ? { background: "#E6E7F0" } : {}}>
      <TableCell align={"left"}>
        {props.newRow ? (
          <></>
        ) : (
          <IconButton icon={DeleteIcon} onClick={(e) => props.deleteLayer()} />
        )}
      </TableCell>
      <TableCell align={"left"}>
        <TextField
          value={carrier}
          fullWidth
          autoComplete={"off"}
          name={"carrier"}
          onChange={(e) => props.updateCarrier(e.target.value)}
          variant={"filled"}
          size={"small"}
          hiddenLabel
          disabled={disabled}
        />
      </TableCell>
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={props.limit || ""}
          fullWidth
          onChangeNumber={props.updateLimit}
          name={"limit"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={props.attachment || ""}
          fullWidth
          onChangeNumber={props.updateAttachment}
          name={"attachment"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <TableCell align={"right"} style={{ minWidth: "150px" }}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={props.grossPremium || ""}
          fullWidth
          onChangeNumber={props.updateMarketGrossWrittenPremium}
          name={"market-gwp"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
        />
      </TableCell>
      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          defaultValue={""}
          decimalScale={0}
          className={"monospace"}
          value={props.grossPPM ?? ""}
        />
      </TableCell>
      <TableCell align={"right"}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          defaultValue={""}
          fixedDecimalScale={3}
          suffix={"%"}
          className={"monospace"}
          value={
            props.grossPPMRelativeToLayerBelow != null
              ? 100 * props.grossPPMRelativeToLayerBelow
              : ""
          }
        />
      </TableCell>
    </TableRow>
  );
};

export const ExpiringProgramComponent = (props) => {
  return (
    <>
      <Component
        title={"Expiring Program"}
        subtitle={"The expiring program layers."}
        lg_width={12}
      >
        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>{columnHeaders.map(makeTableHeader)}</TableRow>
            </TableHead>
            <TableBody>
              {props.layers.map((layer, index) => (
                <ExpiringProgramRow
                  rowIndex={index}
                  isSelectedRow={props.selectedIndex === index}
                  {...layer}
                  key={`EXPIRING_PROGRAM_ROW_${index}`}
                  updateLimit={(limit) => props.updateLimit(index, limit)}
                  updateAttachment={(attachment) =>
                    props.updateAttachment(index, attachment)
                  }
                  updateMarketGrossWrittenPremium={(grossWrittenPremium) =>
                    props.updateMarketGrossWrittenPremium(
                      index,
                      grossWrittenPremium
                    )
                  }
                  updateCarrier={(carrier) =>
                    props.updateCarrier(index, carrier)
                  }
                  deleteLayer={() => props.deleteLayer(index)}
                  newRow={index === props.layers.length - 1}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Component>
    </>
  );
};

export const mapDispatchToProps = {
  updateLimit: expiringProgramActions.updateLimit,
  updateAttachment: expiringProgramActions.updateAttachment,
  updateMarketGrossWrittenPremium:
    expiringProgramActions.updateMarketGrossWrittenPremium,
  updateCarrier: expiringProgramActions.updateCarrier,
  deleteLayer: expiringProgramActions.deleteLayer,
};

export const mapStateToProps = (state) => {
  return {
    layers: expiringProgramSelectors.getExpiringProgramLayersToDisplay(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpiringProgramComponent);
