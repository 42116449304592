import * as numbers from "common/numbers";
import * as exposures from "domain/exposures";
import { useSelector } from "react-redux";
import * as rateChangeStore from "store/rateChange";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

export const calculateExposureChanges = ({
  rateChange,
  layerRateChange,
  currentExposureData,
  priorExposureData,
  exposureList,
  inceptionYear,
}) => {
  const components = (exposureList ?? [])
    .map((exposure) => {
      const key = exposure.key;
      return {
        key,
        exposure,
        selected: !!layerRateChange?.selectedExposures?.[key],
        priorValue: rateChange?.usePriorYearExposuresFromCurrentSubmission
          ? exposures.latestExposure(currentExposureData, key, {
              asAtYear: inceptionYear - 1,
            })
          : exposures.latestExposure(priorExposureData, key),
        currentValue: exposures.latestExposure(currentExposureData, key),
        deltaPctOverride: numbers.parseFloatOrNull(
          layerRateChange?.exposureOverrides?.[key]
        ),
      };
    })
    .filter(
      (component) =>
        component.priorValue != null && component.currentValue != null
    )
    .map((component) => ({
      ...component,
      deltaPct:
        component.deltaPctOverride ??
        100 * (component.currentValue / component.priorValue - 1),
    }));

  const totalOverride = numbers.parseFloatOrNull(
    layerRateChange?.exposureOverrides?.["TOTAL"]
  );

  const totalRatio =
    totalOverride != null
      ? 1 + totalOverride / 100
      : components
          .filter((component) => component.selected)
          .reduce(
            (total, component) =>
              total == null || component.deltaPct == null
                ? null
                : total * (1 + component.deltaPct / 100),
            1
          );

  return {
    components,
    totalRatio,
  };
};

export const useActiveLayerExposureChanges = () => {
  const rateChange = useSelector(rateChangeStore.selectRateChange);
  const layerRateChange = useSelector(
    rateChangeStore.selectActiveLayerRateChange
  );
  const currentExposureData = useSelector(exposureSelectors.getData);
  const priorExposureData = useSelector((state) =>
    exposureSelectors.getData(stateSelectors.selectPriorState(state))
  );
  const exposureList = useSelector(exposureSelectors.getExposureList);
  const inceptionYear = useSelector(programSelectors.getInceptionYear);

  return calculateExposureChanges({
    rateChange,
    layerRateChange,
    currentExposureData,
    priorExposureData,
    exposureList,
    inceptionYear,
  });
};
