import * as dates from "./dates";
import * as errors from "./errors";
import * as Sentry from "@sentry/react";

const WEB_ERROR_CODES = {
  400: "BAD_REQUEST",
  401: "NOT_AUTHORIZED",
  404: "NOT_FOUND",
  500: "INTERNAL_SERVER_ERROR",
};

const ERROR_TITLES = {
  BAD_REQUEST: "Request incorrectly formatted",
  INTERNAL_SERVER_ERROR: "Server Error Occurred",
  NOT_FOUND: "Website Not Found",
  NOT_AUTHORIZED: "Unauthorized Access",
};
const UNKNOWN_ERROR_MESSAGE =
  "Something completely unexpected went wrong, please contact support.";
const ERROR_MESSAGES = {
  BAD_REQUEST: "Request is not correct.",
  INTERNAL_SERVER_ERROR: "Something went wrong, please contact support.",
  NOT_FOUND:
    "The endpoint or service you were looking for was not found, please contact support.",
  NOT_AUTHORIZED:
    "Access has been denied, try logging out with the 'Sign Out' button at the bottom of the page and then logging in again.",
  UNKNOWN_WEB_EXCEPTION:
    "Something completely unexpected went wrong, please contact support.",
};

export const getMessage = (type) => {
  return ERROR_MESSAGES[type] || UNKNOWN_ERROR_MESSAGE;
};

export const getTitle = (type) => {
  return ERROR_TITLES[type] || type;
};

export class HandledError extends Error {
  constructor({ title, message, type, level, date, tags, stack, status }) {
    super(type);
    this.title = title;
    this.description = message;
    this.name = type;
    this.tags = tags;
    this.stack = stack || this.stack;
    this.level = level || "ERROR";
    this.date = date || dates.getDate();
    this.status = status ?? null;
  }
}

export const buildErrorFromResponse = async (response) => {
  let type = WEB_ERROR_CODES[response.status];
  let message = getMessage(type);
  if (response.status === 400) {
    try {
      const data = await response.json();
      type = data.type || type;
      message = data.message || message;
    } catch (e) {
      Sentry.captureMessage("Client Exception returned with no JSON payload");
    }
  }
  return new HandledError({
    title:
      type == null
        ? `Unknown Web Exception (${response.status})`
        : errors.getTitle(type),
    message: message || UNKNOWN_ERROR_MESSAGE,
    type: type || "UNKNOWN_WEB_EXCEPTION",
    status: response.status,
  });
};

export const createErrorFromHandledError = (
  error,
  { type, title, message, tags, stack, status } = {}
) => {
  return new HandledError({
    title: title || error.message,
    message: message || error.description,
    type: type || error.name,
    tags: tags || error.tags,
    stack: stack || error.stack,
    status: status || error.status,
  });
};

export const createErrorFromError = (
  error,
  { type, title, message, tags, stack } = {}
) => {
  return new HandledError({
    title: title || getTitle(type) || error.message,
    message: message || getMessage(type),
    type,
    tags,
    stack,
  });
};

const createErrorFromString = (
  string,
  { type, title, message, tags, stack } = {}
) => {
  return new HandledError({
    type: type || string,
    title: title || string,
    message: message || "Please contact support.",
    tags,
    stack,
  });
};

const createErrorFromObject = (
  object,
  { type, title, message, tags, stack } = {}
) => {
  return new HandledError({
    type: type || "UNEXPECTED_ERROR",
    title: title || "Something Went Wrong",
    message: message || "Please contact support.",
    tags,
    stack,
  });
};

export const createError = (error, meta) => {
  if (error instanceof HandledError) {
    return createErrorFromHandledError(error, meta);
  }
  if (error instanceof Error) {
    return createErrorFromError(error, meta);
  }
  if (typeof error === "string") {
    return createErrorFromString(error, meta);
  }
  if (error instanceof Object) {
    return createErrorFromObject(error, meta);
  }
  return new HandledError({
    type: meta?.type || "UNEXPECTED_ERROR",
    title: meta?.title || "Something Went Wrong",
    message: meta?.message || "Please contact support.",
    tags: meta?.tags,
    stack: meta?.stack,
  });
};

export const downloadState = (state) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(JSON.stringify(state))
  );
  element.setAttribute("download", "state.json");
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
