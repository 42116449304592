import CompanySelector from "./CompanySelector";
import MaintenanceStatus from "./MaintenanceStatus";
import NewCompany from "./NewCompany";
import {
  AccountBalance as CompaniesIcon,
  Announcement as MaintenanceStatusIcon,
} from "@mui/icons-material";
import Footer from "components/common/Footer";
import Navigation from "components/common/Navigation";
import { Navigate, Route, Routes } from "react-router-dom";

const GlobalConsole = () => {
  const menuItems = [
    {
      text: "Maintenance Status",
      icon: <MaintenanceStatusIcon />,
      route: "maintenance-status",
    },
    { text: "Companies", icon: <CompaniesIcon />, route: "new-company" },
  ];

  return (
    <div className={"app"}>
      <Navigation
        menuItems={menuItems}
        rightMenuComponent={<CompanySelector companyId={"global"} />}
        parentRoute={"/admin/global"}
      />
      <Routes>
        <Route path={"maintenance-status/*"} element={<MaintenanceStatus />} />
        <Route path={"new-company/*"} element={<NewCompany />} />
        <Route
          path={"*"}
          element={<Navigate to={"maintenance-status"} replace={true} />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default GlobalConsole;
