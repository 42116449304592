import * as _ from "lodash";

export const maybeExtractSubmissionIdFromPath = (path) => {
  if (!path) {
    return null;
  }
  const matched = /^\/submissions\/([0-9a-f-]{36})/.exec(path);

  return matched ? matched[1] : null;
};

export const isSaveExpected = (persistedState, curState) => {
  return !_.isEqualWith(persistedState, curState);
};

export const isLeavingSubmission = ({ previousLocation, location }) => {
  if (!previousLocation?.pathname) {
    return false;
  }

  const previousSubmissionId = maybeExtractSubmissionIdFromPath(
    previousLocation?.pathname
  );
  const submissionId = maybeExtractSubmissionIdFromPath(location?.pathname);

  return previousSubmissionId != null && submissionId !== previousSubmissionId;
};
