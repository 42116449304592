import CoveragesGrid from "./CoveragesGrid";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import { useSelector } from "react-redux";
import * as programPricingStore from "store/programPricing";

const NonCatCoverages = () => {
  const programPricing = useSelector(programPricingStore.select);

  return (
    <Component title={"Non-Cat Coverages"}>
      <Disabled ifReadOnly>
        <CoveragesGrid
          perils={programPricing?.coverageConfig?.nonCatCoverageGrid}
        />
      </Disabled>
    </Component>
  );
};

export default NonCatCoverages;
