import Disabled from "../../common/Disabled";
import FeatureFlagged from "../../common/FeatureFlagged";
import IconButton from "../../common/IconButton";
import SmallLoader from "../../common/SmallLoader";
import Visible from "../../common/Visible";
import DownloadOriginalButton from "../../common/files/DownloadOriginalButton";
import ClaimsV1LossRunButton from "./ClaimsV1LossRunButton";
import ClaimsV2LossRunButton from "./ClaimsV2LossRunButton";
import CleanMicrosoftFileButton from "./CleanMicrosoftFileButton";
import { DRAG_N_DROP_TYPE_DIR, DRAG_N_DROP_TYPE_FILE } from "./DirListTreeItem";
import EditableFilename from "./EditableFilename";
import Block from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WarningIcon from "@mui/icons-material/WarningRounded";
import { Avatar, Button, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { TreeItem } from "@mui/x-tree-view";
import React from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";

const Blank = () => <div style={{ width: "40px" }}>&nbsp;</div>;

export const PreviewButton = ({ support, ...props }) => {
  return support.previewable() ? (
    <div style={{ width: "40px" }}>
      <IconButton
        icon={VisibilityIcon}
        onClick={() => {
          window.open(
            "/attachments/fileView?" +
              new URLSearchParams({
                fileId: support.getFileId(),
                filename: support.filename(),
                mime: support.mimeType(),
                type: support.previewable(),
              }),
            "_blank"
          );
        }}
        {...props}
      />
    </div>
  ) : support.isHtmlNotAvailable() || support.hasHtmlLoadFailed() ? (
    <Blank />
  ) : (
    <SmallLoader />
  );
};

export const DeleteButton = ({ support, ...props }) => {
  if (support.canHide()) {
    if (support.isFileLoading()) {
      return <SmallLoader />;
    }
    return (
      <Disabled ifReadOnly>
        <IconButton
          icon={DeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            support.markAttachmentHidden();
          }}
          {...props}
        />
      </Disabled>
    );
  }
  return <Blank />;
};

const CleanButton = ({ support }) => {
  const stat = support.microsoftFileCleanStatus();
  return ["NOT_READABLE", "NOT_AVAILABLE"].includes(stat) ? (
    <Blank />
  ) : stat === "CLEANED" ? (
    <DoneIcon style={{ width: "40px" }} />
  ) : stat === "CLEANING" ? (
    <SmallLoader />
  ) : (
    <Disabled ifReadOnly>
      <CleanMicrosoftFileButton support={support} />
    </Disabled>
  );
};

const DownloadButton = ({ support, ...props }) => {
  if (support.isFileLoadingFailed()) {
    return <Blank />;
  }
  if (support.isFileLoaded()) {
    return (
      <Disabled ifReadOnly>
        <DownloadOriginalButton
          sourceMetadata={support.metadata()}
          smallButton={true}
          newName={support.filename()}
          {...props}
        />
      </Disabled>
    );
  }
  return <SmallLoader />;
};

const FileAvatar = ({ support }) => {
  const colours = support.getColours();
  const Icon = support.getIcon();
  const avatar = (
    <Avatar
      variant={"rounded"}
      sx={{
        bgcolor: colours.background,
        width: "30px",
        height: "30px",
      }}
    >
      <Icon support={support} color={colours.primary} />
    </Avatar>
  );

  let resp = avatar;

  let tooltip = null;
  if (support.isLegacyMicrosoftFile()) {
    tooltip = `'${support.filename()}' is of a legacy file format`;
    resp = (
      <Box sx={{ position: "relative" }}>
        {resp}
        <Box
          sx={{
            position: "absolute",
            bottom: "-4px",
            right: "-5px",
            width: "16px",
            height: "16px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WarningIcon fontSize={"0.75rem"} sx={{ color: "#45494e" }} />
        </Box>
      </Box>
    );
  }
  if (support.isNotReadable()) {
    if (tooltip) {
      tooltip += " and is read protected";
    } else {
      tooltip = `'${support.filename()}' is read protected`;
    }
    resp = (
      <Box sx={{ position: "relative" }}>
        {resp}
        <Box
          sx={{
            position: "absolute",
            bottom: "-4px",
            left: "-2px",
            width: "16px",
            height: "16px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Block fontSize={"0.75rem"} sx={{ color: "#ff5555" }} />
        </Box>
      </Box>
    );
  }
  if (tooltip) {
    resp = <Tooltip title={tooltip}>{resp}</Tooltip>;
  }
  return resp;
};

const ProcessButtons = ({ support }) => {
  return support.canProcessHazardFile() || support.canProcessLossFile() ? (
    <Stack direction={"row"}>
      <Visible byTag={"input.attachment.sic"}>
        {support.canProcessHazardFile() ? (
          <Button
            sx={{ mr: 1 }}
            variant={"contained"}
            size={"small"}
            disableElevation
            onClick={(e) => {
              e.stopPropagation();
              support.processHazardFile();
            }}
          >
            {"SIC"}
          </Button>
        ) : (
          <></>
        )}
      </Visible>
      <Visible byTag={"input.attachment.lossRun"} defaultVisible>
        <FeatureFlagged
          flag={"ui/useClaimsV2Frontend"}
          on={ClaimsV2LossRunButton}
          off={ClaimsV1LossRunButton}
          support={support}
        />
      </Visible>
    </Stack>
  ) : (
    <></>
  );
};

const FileListTreeItem = ({
  support,
  createFileButtons,
  selected,
  setSelected,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [, dragRef] = useDrag({
    type: DRAG_N_DROP_TYPE_FILE,
    item: () => ({
      type: DRAG_N_DROP_TYPE_FILE,
      dirId: support.getDirId(),
      fileId: support.getFileId(),
    }),
    canDrag: (monitor) => support.isFileLoaded() && !isEditing,
    end: (item, monitor) => {
      const dropRes = monitor.getDropResult();
      if (dropRes) {
        const selectedIds = selected.map((support) => support.getUniqueId());
        if (selectedIds.includes(support.getUniqueId())) {
          for (const currentSupport of selected) {
            if (currentSupport.isDir) {
              currentSupport.moveDir(dropRes.dir);
            } else {
              if (dropRes.type === DRAG_N_DROP_TYPE_DIR)
                currentSupport.moveFileToDirectory(dropRes.dir);
              else {
                currentSupport.moveFileToFile(dropRes.dir);
              }
            }
          }
        } else {
          if (dropRes.type === DRAG_N_DROP_TYPE_DIR)
            support.moveFileToDirectory(dropRes.dir);
          else {
            support.moveFileToFile(dropRes.dir);
          }
        }
      }
      setSelected([]);
    },
  });

  const [dropProps, dropRef] = useDrop({
    accept: [DRAG_N_DROP_TYPE_FILE],
    drop: (item, monitor) => {
      return {
        type: DRAG_N_DROP_TYPE_FILE,
        fileId: support.getFileId(),
        dir: support.getDirId(),
      };
    },
    canDrop: (item, monitor) => {
      const has = support.hasFile(item.fileId);
      return has && item.fileId !== support.getFileId();
    },
    collect: (monitor) => ({
      highlighted: monitor.canDrop() && monitor.isOver(),
    }),
  });

  const dragFix = (el) => {
    el?.addEventListener("focusin", (e) => e.stopImmediatePropagation());
  };

  const dragDropRef = (el) => {
    dragRef(el);
    dropRef(el);
  };
  const itemButtons = [
    createFileButtons
      ? createFileButtons({ support })
      : [
          <ProcessButtons key={"lossRun"} {...{ support }} />,
          <CleanButton support={support} key={"fix-button"} />,
          <PreviewButton
            support={support}
            key={"preview"}
            data-testid={`preview-file-${support.filename()}`}
          />,
          <DeleteButton
            support={support}
            key={"delete"}
            data-testid={`delete-file-${support.filename()}`}
          />,
          <DownloadButton
            support={support}
            key={"download"}
            data-testid={`download-file-${support.filename()}`}
          />,
        ],
  ];
  const dropStyleProps = {};
  if (dropProps.highlighted) dropStyleProps.border = "4px solid orange";
  return (
    <TreeItem
      ref={dragFix}
      key={support.getUniqueId()}
      nodeId={support.getUniqueId()}
      label={
        <Box
          style={dropStyleProps}
          sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}
        >
          <div
            ref={dragDropRef}
            style={{ flexGrow: "2", display: "flex", flexDirection: "row" }}
          >
            <Box sx={{ mr: 1 }}>
              {support.isFileExpanding() ? (
                <SmallLoader />
              ) : (
                <FileAvatar support={support} />
              )}
            </Box>
            <EditableFilename
              value={support.filename()}
              extension={support.ext()}
              onSubmit={(newName) => support.setAttachmentName(newName)}
              id={`editable-filename-${support.getFileId()}`}
              uploaded={support.isFileLoaded()}
              style={{ fontWeight: "inherit", flexGrow: 1 }}
              noEditing={!support.isFilenameEditable()}
              onEdit={() => {
                setEditing(true);
              }}
              onClose={() => {
                setEditing(false);
              }}
            />
          </div>
          {itemButtons}
        </Box>
      }
    >
      {support.children
        .map((child, index) => {
          return child.fileHidden() ? null : (
            <FileListTreeItem
              support={child}
              createFileButtons={createFileButtons}
              selected={selected}
              setSelected={setSelected}
              key={`FILE_${support.getDirId()}-${support.getFileId()}_${index}`}
            />
          );
        })
        .filter((node) => node)}
    </TreeItem>
  );
};

export default FileListTreeItem;
