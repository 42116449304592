import { ErrorDialogSupport } from "./ErrorDialogSupport";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { connect } from "react-redux";
import {
  dismissAllErrors,
  dismissError,
  hideErrors,
} from "store/actions/error/errorActions";
import { errorDialogIsOpen } from "store/selectors/error/errorSelectors";

const ErrorDialog = ({
  dismissAllErrors,
  dismissError,
  hideErrors,
  messages,
  isOpen,
}) => {
  const support = new ErrorDialogSupport(messages);
  return (
    <Dialog
      open={isOpen}
      onClose={hideErrors}
      aria-labelledby={"error-dialog-title"}
      maxWidth={"lg"}
      fullWidth
      scroll={"paper"}
      className={"error-dialog"}
    >
      <DialogTitle id={"error-dialog-title"}>{support.getTitle()}</DialogTitle>
      <DialogContent dividers={true}>
        <div className={"errors"}>
          {support.formattedMsgs.map((error, i) => (
            <div
              className={`error-wrapper ${error?.level?.toLowerCase()}`}
              key={`ERROR_${i}`}
            >
              <div className={"header"}>
                <div className={"title-and-date"}>
                  <span className={"title"}>{error.title}</span>
                  <span className={"date"}>
                    {support.formatDate(error.date)}
                  </span>
                </div>
                <Button
                  onClick={() => dismissError(i)}
                  disableElevation
                  variant={"contained"}
                  color={"secondary"}
                >
                  {"Dismiss"}
                </Button>
              </div>
              <div className={"error-content"}>
                <span className={"message"}>{error.description}</span>
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={hideErrors} disableElevation variant={"contained"}>
          {"Hide"}
        </Button>
        <span className={"spacer"} style={{ flex: "1 1 auto" }}></span>
        <Button
          onClick={dismissAllErrors}
          disableElevation
          variant={"contained"}
          color={"secondary"}
        >
          {"Dismiss All"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  dismissError,
  dismissAllErrors,
  hideErrors,
};

const mapStateToProps = (state) => {
  return {
    messages: state.temp.error?.messages || [],
    isOpen: errorDialogIsOpen(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorDialog);
