import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "components/common/Button";
import Options from "components/common/Options";
import { useState } from "react";

const SumTrianglesDialog = ({ onClose, onSumTriangles, triangles }) => {
  const [selected, setSelected] = useState({});
  const items = Object.values(triangles).map((t) => ({
    key: t.id,
    label: t.name,
    selected: selected[t.id],
  }));
  const toggleTriangle = (triangle) => {
    setSelected({ ...selected, [triangle.key]: !selected[triangle.key] });
  };
  const multipleSelections =
    Object.values(selected).reduce((acc, cur) => acc + (cur ? 1 : 0), 0) > 1;

  return (
    <Dialog open={true} maxWidth={"sm"} fullWidth>
      <DialogTitle>{"Sum Triangles"}</DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ paddingTop: 0 }}>
        <Typography marginBottom={2}>
          {"Select the triangles you'd like to combine:"}
        </Typography>
        <Options items={items} onClick={toggleTriangle} variant={"bright"} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSumTriangles(
              Object.entries(selected)
                .filter(([_, value]) => value)
                .map(([key, _]) => key)
            );
          }}
          isDisabled={!multipleSelections}
          variant={"contained"}
        >
          {"Ok"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SumTrianglesDialog;
