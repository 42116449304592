import DefaultLoader from "./DefaultLoader";
import ClearIcon from "@mui/icons-material/CancelRounded";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from "react";

const useShowFetchingSpinner = (isFetching) => {
  const [isSmoothedFetching, setIsSmoothedFetching] = useState(isFetching);
  const [didTextChange, setDidTextChange] = useState(false);

  useEffect(() => {
    if (isFetching) {
      setIsSmoothedFetching(true);
    } else {
      const timer = setTimeout(() => setIsSmoothedFetching(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isFetching, setIsSmoothedFetching]);

  useEffect(() => {
    if (didTextChange) {
      const timer = setTimeout(() => setDidTextChange(false), 500);
      return () => clearTimeout(timer);
    }
  }, [didTextChange, setDidTextChange]);

  const onChangeText = () => {
    setDidTextChange(true);
  };

  return {
    showFetchingSpinner: isFetching || isSmoothedFetching || didTextChange,
    onChangeText,
  };
};

const InlineSearch = forwardRef((props, ref) => {
  const {
    placeholder,
    onChange,
    sx,
    enableTrimming = true,
    noBackground = false,
    fullWidth = false,
    isFetching,
  } = props;

  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const hasSearchText = (searchText || "").trim() !== "";
  const inputRef = useRef(null);

  const hasFetchingSpinner = typeof isFetching === "boolean";
  const { showFetchingSpinner, onChangeText } = useShowFetchingSpinner(
    isFetching
  );

  const updateSearchText = (text) => {
    setSearchText(text);
    onChange(enableTrimming ? (text || "").trim() : text);
    onChangeText();
  };

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current.focus(),
    updateText: (text) => updateSearchText(text),
    clearText: () => updateSearchText(""),
  }));

  const className =
    "inline-search" +
    (active ? " active" : "") +
    (noBackground ? " no-background" : "") +
    (fullWidth ? " full-width" : "");

  return (
    <Stack
      className={className}
      direction={"row"}
      alignItems={"center"}
      sx={sx}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: 18, padding: 2 }}
      >
        {hasFetchingSpinner && showFetchingSpinner ? (
          <DefaultLoader color={"#fa7b35"} type={"TailSpin"} size={16} />
        ) : (
          <SearchIcon className={"search-icon"} />
        )}
      </Stack>

      <input
        value={searchText}
        ref={inputRef}
        onFocus={(_) => setActive(true)}
        onBlur={(_) => setActive(false)}
        onChange={(e) => updateSearchText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            updateSearchText("");
            e.target.blur();
          }
          e.stopPropagation();
        }}
        autoComplete={"off"}
        placeholder={placeholder}
      />
      <ClearIcon
        className={"clear-icon"}
        onClick={(e) => {
          updateSearchText("");
          inputRef.current.focus();
        }}
        sx={{
          opacity: hasSearchText ? 1 : 0,
        }}
      />
    </Stack>
  );
});

export default InlineSearch;
