export const NEW_SUBMISSION = "NEW_SUBMISSION";
export const RENEW_SUBMISSION = "RENEW_SUBMISSION";
export const DUPLICATE_SUBMISSION = "DUPLICATE_SUBMISSION";
export const LOAD_SUBMISSION_STARTED = "LOAD_SUBMISSION_STARTED";
export const LOAD_SUBMISSION_SUCCEEDED = "LOAD_SUBMISSION_SUCCEEDED";
export const LOAD_SUBMISSION_FAILED = "LOAD_SUBMISSION_FAILED";
export const RENEW_SUBMISSION_DIALOG = "RENEW_SUBMISSION_DIALOG";
export const CANCEL_RENEW_SUBMISSION = "CANCEL_RENEW_SUBMISSION";
export const RECEIVED_PRIOR_SUBMISSION = "RECEIVED_PRIOR_SUBMISSION";
export const SAVE_ACCEPTED_TRANSMISSION = "SAVE_ACCEPTED_TRANSMISSION";
export const ACCEPTED_TRANSMISSION_SAVED = "ACCEPTED_TRANSMISSION_SAVED";
export const ACCEPTED_TRANSMISSION_CLEANUP = "ACCEPTED_TRANSMISSION_CLEANUP";
