const extractBaseValuesByKey = (data, { keys }) => {
  if (!data?.length || !keys?.length) {
    return {};
  }
  const rowsWithAllKeys = data.filter((row) =>
    keys.every((key) => {
      const value = row?.[key];
      return Number.isFinite(value) && value !== 0;
    })
  );
  if (rowsWithAllKeys.length) {
    // If there is some row that has all keys, use that as the base year.
    return Object.fromEntries(
      keys.map((key) => [key, rowsWithAllKeys[0][key]])
    );
  } else {
    // If there isn't a single base year available, rebase everything to their
    // first appearance.
    const reversedData = [...data].reverse();
    return Object.fromEntries(
      reversedData.flatMap((row) =>
        keys
          .map((key) => [key, row[key]])
          .filter(([, value]) => Number.isFinite(value))
      )
    );
  }
};

export const rebase = (data, { selectedMetrics }) => {
  if (!data?.length) {
    return [];
  }
  const keys = (selectedMetrics ?? []).map(({ key }) => key);
  const baseValuesByKey = extractBaseValuesByKey(data, { keys });

  return data.map((row) =>
    Object.fromEntries(
      Object.entries(row ?? {})
        .filter(([, value]) => Number.isFinite(value))
        .map(([key, value]) => [key, value / (baseValuesByKey[key] ?? 1)])
    )
  );
};
