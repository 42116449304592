import LayerPricing from "./LayerPricing";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import PeerReviewNotes from "components/common/PeerReviewNotes";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import TextNote from "components/common/TextNote";
import * as config from "config";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as programActions from "store/actions/input/program/programActions";
import * as programPricingStore from "store/programPricing";
import * as programSelectors from "store/selectors/input/program/programSelectors";

const Inputs = () => {
  const dispatch = useDispatch();

  const programPricing = useSelector(programPricingStore.select);
  const resolvedProgram = useSelector(programSelectors.selectResolvedProgram);

  const updateProgramPricing = (values) => {
    dispatch(programPricingStore.update(values));
  };

  const updateBrokerage = (value) => {
    dispatch(programActions.updateBrokerage(value));
  };

  const updateExpenses = (value) => {
    dispatch(programActions.updateExpenses(value));
  };

  return (
    <>
      <Component title={"Pricing Loads"} md_width={6}>
        <Grid container spacing={config.GRID_SPACING}>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"ALAE"}
                value={programPricing?.pricingLoads?.ALAE}
                onChangeNumber={(value) =>
                  updateProgramPricing({
                    pricingLoads: {
                      ...programPricing.pricingLoads,
                      ALAE: value,
                    },
                  })
                }
                variant={"filled"}
              />
            </Disabled>
          </Grid>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"ITV"}
                value={programPricing?.pricingLoads?.ITV}
                onChangeNumber={(value) =>
                  updateProgramPricing({
                    pricingLoads: {
                      ...programPricing.pricingLoads,
                      ITV: value,
                    },
                  })
                }
                variant={"filled"}
              />
            </Disabled>
          </Grid>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"Growth"}
                value={programPricing?.pricingLoads?.GROWTH}
                onChangeNumber={(value) =>
                  updateProgramPricing({
                    pricingLoads: {
                      ...programPricing.pricingLoads,
                      GROWTH: value,
                    },
                  })
                }
                variant={"filled"}
              />
            </Disabled>
          </Grid>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"UW Load"}
                value={programPricing?.pricingLoads?.UW_LOAD}
                onChangeNumber={(value) =>
                  updateProgramPricing({
                    pricingLoads: {
                      ...programPricing.pricingLoads,
                      UW_LOAD: value,
                    },
                  })
                }
                variant={"filled"}
              />
            </Disabled>
          </Grid>
        </Grid>
      </Component>
      <Component title={"Expense Loads"} md_width={6}>
        <Grid container spacing={config.GRID_SPACING}>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"Brokerage"}
                value={resolvedProgram?.brokerage ?? 0}
                onChangeNumber={updateBrokerage}
                variant={"filled"}
              />
            </Disabled>
          </Grid>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"Helix Fee"}
                value={programPricing?.expenseLoads?.HELIX_FEE}
                onChangeNumber={(value) =>
                  updateProgramPricing({
                    expenseLoads: {
                      ...programPricing.expenseLoads,
                      HELIX_FEE: value,
                    },
                  })
                }
                variant={"filled"}
              />
            </Disabled>
          </Grid>
          <Grid item xs={3}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"General Expense"}
                value={resolvedProgram?.expenses ?? 0}
                onChangeNumber={updateExpenses}
                variant={"filled"}
              />
            </Disabled>
          </Grid>
        </Grid>
      </Component>
    </>
  );
};

const AdditionalInputs = () => {
  const dispatch = useDispatch();

  const programPricing = useSelector(programPricingStore.select);

  const updateProgramPricing = (values) => {
    dispatch(programPricingStore.update(values));
  };

  return (
    <>
      <Component title={"Expected Loss Ratios"} xs_width={6}>
        <Grid container spacing={config.GRID_SPACING}>
          <Grid item xs={6}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"Cat ELR"}
                value={programPricing?.CAT_ELR}
                onChangeNumber={(CAT_ELR) => updateProgramPricing({ CAT_ELR })}
                variant={"filled"}
              />
            </Disabled>
          </Grid>
          <Grid item xs={6}>
            <Disabled ifReadOnly>
              <PrettyNumberTextField
                suffix={"%"}
                InputLabelProps={{ shrink: true }}
                label={"Non-Cat ELR"}
                value={programPricing?.non_CAT_ELR}
                onChangeNumber={(non_CAT_ELR) =>
                  updateProgramPricing({ non_CAT_ELR })
                }
                variant={"filled"}
              />
            </Disabled>
          </Grid>
        </Grid>
      </Component>
      <Component title={"Scales"} xs_width={6}>
        <Grid container spacing={config.GRID_SPACING}>
          <Grid item xs={6}>
            <Disabled ifReadOnly>
              <FormControl variant={"filled"} fullWidth>
                <InputLabel shrink={true}>{"Cat Scale"}</InputLabel>
                <Select
                  value={programPricing?.catScale ?? "__NULL__"}
                  onChange={(e) => {
                    let catScale = e.target.value;
                    if (catScale === "__NULL__") {
                      catScale = null;
                    }
                    updateProgramPricing({ catScale });
                  }}
                >
                  <MenuItem value={"__NULL__"}>{"---"}</MenuItem>
                  <MenuItem value={"C"}>{"C Scale"}</MenuItem>
                  <MenuItem value={"MODEL"}>{"Modelling"}</MenuItem>
                  <MenuItem value={"CAT"}>{"Cat Scale"}</MenuItem>
                </Select>
              </FormControl>
            </Disabled>
          </Grid>
          <Grid item xs={6}>
            <Disabled ifReadOnly>
              <FormControl variant={"filled"} fullWidth>
                <InputLabel shrink={true}>{"Non-Cat Scale"}</InputLabel>
                <Select
                  value={programPricing?.nonCatScale ?? "__NULL__"}
                  onChange={(e) => {
                    let nonCatScale = e.target.value;
                    if (nonCatScale === "__NULL__") {
                      nonCatScale = null;
                    }
                    updateProgramPricing({ nonCatScale });
                  }}
                >
                  <MenuItem value={"__NULL__"}>{"---"}</MenuItem>
                  <MenuItem value={"A"}>{"A Scale"}</MenuItem>
                  <MenuItem value={"B"}>{"B Scale"}</MenuItem>
                  <MenuItem value={"C"}>{"C Scale"}</MenuItem>
                  <MenuItem value={"D"}>{"D Scale"}</MenuItem>
                  <MenuItem value={"N"}>{"N Scale"}</MenuItem>
                </Select>
              </FormControl>
            </Disabled>
          </Grid>
        </Grid>
      </Component>
    </>
  );
};

const DnfLayerPricing = () => {
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
  return (
    <>
      <Inputs />
      {showAdditionalInputs && <AdditionalInputs />}
      <>
        <Grid item xs={12} lg={6}>
          <LayerPricing
            showAdditionalInputs={showAdditionalInputs}
            setShowAdditionalInputs={setShowAdditionalInputs}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Stack
            direction={"column"}
            spacing={config.GRID_SPACING}
            justifyContent={"flex-start"}
          >
            <TextNote noteKey={"summary"} title={"Notes"} />
            <TextNote noteKey={"program"} title={"Risk/Program Notes"} />
            <TextNote noteKey={"riskControl"} title={"Risk Control Comments"} />
            <TextNote noteKey={"claims"} title={"Loss History"} />
            <TextNote noteKey={"modeling"} title={"Modeling Notes"} />
            <Disabled ifReadOnly>
              <PeerReviewNotes
                title={"Peer Review"}
                lg_width={12}
                growVertically={false}
              />
            </Disabled>
          </Stack>
        </Grid>
      </>
    </>
  );
};

export default DnfLayerPricing;
