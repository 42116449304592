export const EX_ANTE_AVG_LAST_3_YEARS = "exAnteAvgLast3yrs";
export const EX_ANTE_AVG_LAST_5_YEARS = "exAnteAvgLast5yrs";
export const EX_ANTE_AVG = "exAnteAvg";
export const AVG_LAST_3_YEARS = "avgLast3yrs";
export const AVG_LAST_5_YEARS = "avgLast5yrs";
export const AVG = "avg";

export const supportedAverages = [
  EX_ANTE_AVG_LAST_3_YEARS,
  EX_ANTE_AVG_LAST_5_YEARS,
  EX_ANTE_AVG,
  AVG_LAST_3_YEARS,
  AVG_LAST_5_YEARS,
  AVG,
];

export const yearBasedAverage = (values, years, average) => {
  const yearsToUse = () => {
    switch (average) {
      case EX_ANTE_AVG_LAST_3_YEARS:
        return years.slice(-4).slice(0, -1);
      case EX_ANTE_AVG_LAST_5_YEARS:
        return years.slice(-6).slice(0, -1);
      case EX_ANTE_AVG:
        return years.slice(0, -1);
      case AVG_LAST_3_YEARS:
        return years.slice(-3);
      case AVG_LAST_5_YEARS:
        return years.slice(-5);
      case AVG:
      default:
        return years;
    }
  };
  const selectedYears = yearsToUse();
  return (
    selectedYears.map((year) => values[year] ?? 0).reduce((a, b) => a + b, 0) /
    selectedYears.length
  );
};
