export const selectCurrencyCodes = (state) =>
  state?.temp?.staticData?.currencyCodesList ?? null;

export const selectAnalyticsConfig = (state) =>
  state?.temp?.staticData?.analyticsConfig ?? null;

export const selectSovTortCaps = (state) =>
  state?.temp?.staticData?.sovTortCaps ?? null;

export const selectLayerStatusLabels = (state) =>
  state?.temp?.staticData?.layerStatusLabels ?? null;

export const selectAllConfig = (state) =>
  state?.temp?.staticData?.config ?? null;

export const selectConfig = (key) => (state) =>
  selectAllConfig(state)?.[key] ?? null;

export const selectTranslationsForDomain = (domain) => (state) =>
  selectConfig("translations")(state)?.[domain] ?? null;

export const pricingAssumptionsDefVals = (state) =>
  selectConfig("pricing_assumptions")(state)?.["defaultValues"] ?? {};

export const pricingAssumptionsConfig = (state) =>
  selectConfig("pricing_assumptions")(state)?.["definitions"] ?? [];

export const pricingAssumptionsEditability = (state) =>
  selectConfig("pricing_assumptions")(state)?.["editability"] ?? {};

export const selectUnderwriterList = (state) =>
  selectConfig("underwriters")(state)?.all ?? null;

export const selectGeneralConfiguration = (state) =>
  selectConfig("general_configuration")(state) ?? {};

export const selectUnderwriterFromUser = (state) => {
  const underwriterList = selectUnderwriterList(state) ?? [];
  const user_id = state.user.attributes.id;
  for (const underwriter of underwriterList) {
    if (underwriter.id === user_id) {
      return underwriter;
    }
  }
  return null;
};
