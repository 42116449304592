import ExtractFileDownloader from "components/common/files/ExtractedFileDownloader";
import { connect } from "react-redux";
import * as actions from "store/actions/input/exposure/premDeductActions";
import * as selectors from "store/selectors/input/exposure/premDeductSelectors";
import * as progSelectors from "store/selectors/input/program/programSelectors";

const mapDispatchToProps = {
  hideFile: actions.hidePremDeductFile,
};

const mapStateToProps = (state) => ({
  files: selectors.files(state),
  insured: progSelectors.getInsured(state),
  title: "Download Premium Profile Files",
  parquetColumnMap: {
    _PREMIUM: "Premium",
    _DEDUCTION: "Deduction",
  },
  outputFilePrefix: "Premium-Profile",
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtractFileDownloader);
