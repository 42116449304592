const WHITESPACE_CHARACTER_REGEX = /\s/;

const isWhitespaceCharacter = (char) => WHITESPACE_CHARACTER_REGEX.test(char);

export const trimSelection = ({ text, selection }) => {
  if (text && text.length) {
    let start = selection.start;
    let end = selection.end;
    while (start < end && isWhitespaceCharacter(text[start])) {
      start++;
    }
    while (end > start && isWhitespaceCharacter(text[end - 1])) {
      end--;
    }
    return { start, end };
  } else {
    return selection;
  }
};

export const withTrimmedSelection = (command) => ({
  ...command,
  execute: (state, api) => {
    const newSelection = trimSelection({
      text: state.text,
      selection: state.selection,
    });
    const state1 = api.setSelectionRange(newSelection);
    command.execute({ command, ...state1 }, api);
  },
});
