import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as modelDataResolvers from "domain/modelDataResolver";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const modelDataSlice = createSlice({
  name: "modelData",
  initialState: {},
  reducers: {
    set: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...values,
      };
    },
    update: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => action?.payload?.data?.state?.modelData ?? {}
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, (_, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.modelData,
        };
      })
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (state, action) => {
        return {
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.modelData,
        };
      });
  },
});

export const selectModelDataInput = (state) => state?.modelData ?? null;

export const selectModelData = createSelector(
  [selectModelDataInput],
  (modelDataInput) => modelDataResolvers.resolve(modelDataInput)
);

export const { set, update } = modelDataSlice.actions;

export default modelDataSlice;
