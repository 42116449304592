import * as logger from "../common/logger";
import * as config from "../config";
import * as utils from "../utils";
import { createApi } from "@reduxjs/toolkit/dist/query/react";

const baseSuggestionQuery = async (args, api, extraOptions) => {
  const payload = { ...args.payload };
  if (args.payload?.body) {
    if (!payload.method) payload.method = "POST";
    if (!payload.headers)
      payload.headers = {
        "Content-Type": "application/json",
      };
    payload.body = JSON.stringify(payload.body);
  }
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.mappingSuggestions(args.url),
      payload
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

const baseHintsQuery = async (args, api, extraOptions) => {
  const payload = { ...args.payload };
  if (args.payload?.body) {
    if (!payload.method) payload.method = "POST";
    if (!payload.headers)
      payload.headers = {
        "Content-Type": "application/json",
      };
    payload.body = JSON.stringify(payload.body);
  }
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.claims(args.url),
      payload
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    logger.exception(e);
    return {
      error: String(e),
    };
  }
};

const suggestionMetaFromColumnTypes = (columnTypes) => {
  const suggestionsMeta = {};
  const simplifyTypes = (typesMap) => {
    const simpleTypes = {};
    for (const type in typesMap) {
      switch (type) {
        case "int":
        case "float":
          simpleTypes["num"] = 1;
          break;
        case "date":
        case "datetime":
          simpleTypes["date"] = 1;
          break;
        default:
          simpleTypes["str"] = 1;
      }
    }
    return Object.keys(simpleTypes);
  };
  for (const index in columnTypes) {
    const typeEntry = columnTypes[index];
    suggestionsMeta[typeEntry.name] = simplifyTypes(typeEntry.types);
  }
  return suggestionsMeta;
};

export const suggestionsApi = createApi({
  reducerPath: "suggestionsApi",
  baseQuery: baseSuggestionQuery,
  endpoints: (builder) => ({
    getColumnMappingSuggestions: builder.query({
      queryFn: async (columnTypes) => {
        const payload = {
          body: {
            hintType: "columnMapping",
            keys: columnTypes.map((columnTypes) => columnTypes.name),
            meta: suggestionMetaFromColumnTypes(columnTypes),
            inverse: false,
          },
        };
        const columnSuggestions = await baseSuggestionQuery({
          url: "suggest-mappings",
          payload: payload,
        });
        const suggestions = {};
        for (const name in columnSuggestions.data.suggestions) {
          const originalSuggestions = columnSuggestions.data.suggestions[name];
          const renamed = originalSuggestions.map((suggested) => ({
            suggestion: suggested.column,
            confidence: suggested.confidence,
          }));
          suggestions[name] = renamed;
        }
        return {
          data: {
            suggestions,
          },
        };
      },
    }),
    getOpenClosedMappingSuggestions: builder.query({
      queryFn: async (values) => {
        return await callHints("openClosedMapping", values);
      },
    }),
    getLossTypeMappingSuggestions: builder.query({
      queryFn: async (values) => {
        return await callHints("lossTypeMapping", values);
      },
    }),
    getNamedMappingSuggestions: builder.query({
      queryFn: async ({ name, values }) => {
        return await callHints(name, values);
      },
    }),
  }),
});

const callHints = async (type, values) => {
  const response = await baseHintsQuery({
    url: "excel/mapping-hints",
    payload: {
      body: {
        hintType: type,
        keys: values.map((e) => e.key),
        inverse: true,
      },
    },
  });
  const suggestions = {};
  for (const name in response.data) {
    suggestions[name] = [{ suggestion: response.data[name], confidence: 1 }];
  }
  return {
    data: {
      suggestions: suggestions,
    },
  };
};

export const {
  /** takes is its paramater a list of column types
   * this looks like [
   * {name: "column name", types: {
   *     "int": ["count": 23, fraction: 0.1],
   *     "float": ["count": 207, fraction: 0.9]
   *   }
   * }
   * Though most of this structure is filtered out  as it is not required
   *   for the current backend service.
   *
   * The output is an object which contains an attribut suggestions:
   * {
   *   "suggestions": {
   *     "target-column": [
   *       {"suggestion": "source-column-name", "confidence": 0.1},
   *       {"suggestion": "another-source-column-name", "confidence": 0.01},
   *     ]
   *   }
   * }
   *
   * The target columns are ordered by confidence, greatest confidence first
   */
  useGetColumnMappingSuggestionsQuery,
  /**
   * both useGetLossTypeMappingSuggestionsQuery, and useGetOpenClosedMappingSuggestionsQuery
   * takes is its paramater a list of potential values to map
   * this looks like
   * {
   *   name: "value1", types: {count: 48, fraction: 0.96},
   *   name: "value2", types: {count: 2, fraction: 0.04},
   * }
   *
   * The output is an object which contains an attribut suggestions:
   * {
   *   "suggestions": {
   *     "source-value": [
   *       {"suggestion": "target-value1", "confidence": 0.1},
   *       {"suggestion": "target-value-2", "confidence": 0.01},
   *     ]
   *   }
   * }
   *
   * The suggestions are ordered by confidence, greatest confidence first
   */
  useGetLossTypeMappingSuggestionsQuery,
  useGetOpenClosedMappingSuggestionsQuery,
  useGetNamedMappingSuggestionsQuery,
} = suggestionsApi;
