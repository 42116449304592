import * as config from "../../../config";
import * as peersActions from "../../../store/actions/peers/peersActions";
import * as peersSelectors from "../../../store/selectors/peers/peersSelectors";
import * as utils from "../../../utils";
import Component from "../../Component";
import { makeSearch } from "../../search/SearchTextField";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as submissionsService from "services/submissionsService";

const PeerNAICSSearch = makeSearch((state) => ({
  searchState: state.search.peerNaics,
  active: peersSelectors.getPeerNaics(state),
  meta: config.PEER_NAICS_SEEKER,
  displayResultText: (result) => `(${result._CODE}) ${result._TITLE}`,
  renderValue: (result) => `(${result.code}) ${result.title}`,
}));

export const compareStringItems = (item) => (a, b) =>
  String(a?.[item] ?? "").localeCompare(String(b?.[item] ?? ""));

export const filterPeers = (submissions, { naicsCode, existingPeers }) => {
  const existingPeerFilenames = existingPeers.map((p) => p.filename);
  return Array.from(submissions ?? [])
    .filter((s) => (s.naicsCode ?? "").startsWith(naicsCode))
    .filter((s) => !existingPeerFilenames.includes(s.filename))
    .toSorted(
      (a, b) =>
        -compareStringItems("inception")(a, b) ||
        -compareStringItems("savedAt")(a, b) ||
        compareStringItems("submissionId")(a, b)
    )
    .splice(0, 25);
};

const NAICSPeers = ({ peerNaics, peers, addPeer }) => {
  const {
    data: submissions,
    refetch: refetchSubmissions,
  } = submissionsService.useListSubmissionsQuery();

  useEffect(() => {
    refetchSubmissions();
  }, [refetchSubmissions]);

  const availablePeers = filterPeers(submissions ?? [], {
    naicsCode: String(peerNaics?.code ?? ""),
    existingPeers: peers,
  });

  return (
    <Component
      title={"NAICS Peers"}
      subtitle={"Choose Peers by NAICS code."}
      xs_width={12}
      lg_width={6}
    >
      <Box mb={2}>
        <PeerNAICSSearch label={"NAICS code for Peers."} />
      </Box>
      {availablePeers.length > 0 ? (
        <TableContainer style={{ maxHeight: 450 }}>
          <Table stickyHeader className={"peers-table"}>
            <TableBody>
              {availablePeers.map((submission) => (
                <TableRow
                  key={submission.submissionId}
                  xs={12}
                  className={"search-row"}
                  onClick={() => addPeer(submission)}
                >
                  <TableCell className={"search-cell search-result-insured"}>
                    <span>{submission.insured}</span>
                  </TableCell>
                  <TableCell className={"search-cell search-result-inception"}>
                    <span>{utils.formatDate(submission.inception)}</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <span>{"No more NAICS peers available to add."}</span>
      )}
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    peerNaics: peersSelectors.getPeerNaics(state),
    peers: peersSelectors.getPeers(state),
  };
};

const mapDispatchToProps = {
  addPeer: peersActions.loadPeer,
};

export default connect(mapStateToProps, mapDispatchToProps)(NAICSPeers);
