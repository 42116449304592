import * as peopleSelectors from "../people/peopleSelectors";
import { createSelector, createStructuredSelector } from "reselect";
import * as SOV from "store/SOV";
import * as baseLayerBurn from "store/baseLayerBurn";
import * as catExposures from "store/catExposures";
import * as endorsements from "store/endorsements";
import * as layerDefaults from "store/layerDefaults";
import * as lossDevelopmentStore from "store/lossDevelopment";
import { selectModelData } from "store/modelDataStore";
import * as programPricing from "store/programPricing";
import * as rateChangeStore from "store/rateChange";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as flagsSelectors from "store/selectors/input/flags/flagsSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as peersSelectors from "store/selectors/peers/peersSelectors";
import {
  selectSubmissionId,
  selectFilename,
} from "store/selectors/persistence/persistenceSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";

export const selectResolvedSubmissionWithoutInput = createStructuredSelector({
  submissionId: selectSubmissionId,
  program: programSelectors.selectResolvedProgram,
  pricingInputs: pricingSelectors.selectResolvedPricingInputs,
  layers: pricingSelectors.selectResolvedLayers,
  tower: towerPricingSelectors.selectResolvedTower,
  claimsQuery: pricingSelectors.selectPricingClaims,
  rateChange: rateChangeStore.selectRateChange,
  layerDefaults: layerDefaults.select,
  lossDevelopment: lossDevelopmentStore.selectLossDevelopment,
  baseLayerBurn: baseLayerBurn.selectBaseLayerBurn,
  exposure: exposureSelectors.selectResolvedExposure,
  peers: peersSelectors.selectResolvedPeers,
  sov: SOV.selectSOV,
  programPricing: programPricing.select,
  modelData: selectModelData,
  flags: flagsSelectors.selectResolvedFlags,
  people: peopleSelectors.selectResolvedPeople,
  catExposures: catExposures.select,
  endorsements: endorsements.select,
});

export const selectResolvedSubmission = createSelector(
  [selectResolvedSubmissionWithoutInput, (state) => state, selectFilename],
  (resolvedSubmission, state, filename) => {
    return {
      ...resolvedSubmission,
      submission: { state, filename },
    };
  }
);
