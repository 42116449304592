import * as staticDataService from "services/staticDataService";
import * as stateHooks from "store/hooks/stateHooks";

export const useFeatureFlag = (flag, { default: _default = false } = {}) => {
  const {
    data: featureFlags,
    isFeatureFlagsLoading,
  } = staticDataService.useFeatureFlagsQuery();
  const {
    data: featuresConfig,
    isConfigLoading,
  } = staticDataService.useConfigQuery("features");

  const isLoading = isFeatureFlagsLoading || isConfigLoading;

  const [betaFlags, setBetaFlags] = stateHooks.useLocalStorageJsonState(
    "featureFlagBetaFlags"
  );

  const setBetaFlag = (betaFlag) => {
    setBetaFlags({ ...betaFlags, [flag]: betaFlag });
  };

  const isFeatureFlagOn = !!(featureFlags?.[flag] ?? _default);

  const betaConfig = featuresConfig?.betaFlags?.[flag] ?? {};
  const isBetaFlaggingEnabled = betaConfig.isEnabled ?? false;
  const isBetaFlagSet = betaFlags?.[flag] != null;
  const isBetaFlagOn = betaFlags?.[flag] ?? betaConfig?.default ?? false;

  return {
    isOn: isFeatureFlagOn && (!isBetaFlaggingEnabled || isBetaFlagOn),
    isFeatureFlagOn,
    isBetaFlaggingEnabled,
    isBetaFlagSet,
    isBetaFlagOn,
    isLoading,
    setBetaFlag,
  };
};
