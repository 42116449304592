import SmallLoader from "../common/SmallLoader";
import { Task as AcceptIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import Markdown from "components/common/Markdown";
import { connect } from "react-redux";
import * as exchangeService from "services/exchangeService";
import * as transferSelectors from "store/selectors/temp/transfer/transferSelectors";

const AcceptTransferDialog = ({
  showTransferAcceptencaDialog = true,
  saveTransferInProgress,
  saveTransferCompleted,
  open = false,
  onAccept,
  onClose,
  alreadySaved = false,
}) => {
  const { data: exchangeConfig } = exchangeService.useClientConfigQuery();
  const acceptanceConfig = exchangeConfig?.acceptanceWorkflow;

  return (
    <Dialog open={open} onClose={() => {}} fullWidth maxWidth={"md"}>
      <DialogTitle>
        {"Accept Transfer"}
        <IconButton
          icon={CloseIcon}
          aria-label={"close"}
          onClick={onClose}
          sx={{ position: "absolute", right: 16, top: 14 }}
          isDisabled={saveTransferInProgress}
        />
      </DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          {showTransferAcceptencaDialog && acceptanceConfig?.dialogWarning && (
            <Alert severity={"warning"}>{acceptanceConfig.dialogWarning}</Alert>
          )}
          {showTransferAcceptencaDialog && acceptanceConfig?.dialogNotice && (
            <Card sx={{ padding: 2 }}>
              <Stack direction={"column"} spacing={2}>
                <Markdown
                  editMode={false}
                  value={acceptanceConfig.dialogNotice}
                />
              </Stack>
            </Card>
          )}
          {saveTransferInProgress && !saveTransferCompleted && (
            <Card sx={{ padding: 2 }}>
              <Stack direction={"row"} justifyContent={"left"} spacing={2}>
                <Typography>
                  {
                    "Bringing the submission data into your instance of Marmalade"
                  }
                </Typography>
                <SmallLoader />
              </Stack>
            </Card>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {showTransferAcceptencaDialog && (
          <Button
            endIcon={<AcceptIcon />}
            onClick={onAccept}
            isLoading={saveTransferInProgress}
          >
            {alreadySaved ? "Overwrite" : "Accept"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    saveTransferInProgress: transferSelectors.saveTransferInProgress(state),
    saveTransferCompleted: transferSelectors.saveTransferCompleted(state),
  };
};
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcceptTransferDialog);
