import * as actionTypes from "store/actions/input/flags/flagsActionTypes";

export const updateJudgment = (column, row, value) => ({
  type: actionTypes.UPDATE_UW_JUDGMENT,
  payload: { column, row, value },
});

export const updateExposureFlag = (column, row, value) => ({
  type: actionTypes.UPDATE_EXPOSURE_FLAG,
  payload: { column, row, value },
});

export const updateStateId = (id) => ({
  type: actionTypes.UPDATE_STATE_ID,
  payload: id,
});

export const deleteJudgment = (row) => ({
  type: actionTypes.DELETE_UW_JUDGMENT,
  payload: row,
});

export const deleteExposure = (row) => ({
  type: actionTypes.DELETE_EXPOSURE,
  payload: row,
});
