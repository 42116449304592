import PricingAssumptionsTable from "./PricingAssumptionsTable";
import Component from "components/Component";
import React from "react";
import { connect } from "react-redux";

export const PricingAssumptions = ({ configReady, pricingReady }) => {
  return (
    <Component
      title={"Pricing Assumptions"}
      loading={!configReady || !pricingReady}
    >
      <PricingAssumptionsTable />
    </Component>
  );
};

export const mapStateToProps = (state) => ({
  config: state.temp?.staticData?.config?.pricing_assumptions,
  configReady: !!state.temp?.staticData?.config?.pricing_assumptions,
  pricingReady: !!state.pricing,
});

export default connect(mapStateToProps)(PricingAssumptions);
