import * as numbers from "../../../common/numbers";
import * as config from "../../../config";
import * as pricingSelectors from "../../../store/selectors/pricing/pricingSelectors";
import { authenticatedFetch } from "../../../utils";
import Component from "../../Component";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import useDebouncedValue from "store/hooks/useDebouncedValue";

const STATS_ROWS = [
  { name: "Mean", key: "mean" },
  { name: "Std Dev", key: "stddev" },
  { name: "Min", key: "min" },
  { name: "Max", key: "max" },
  { name: "Median", key: "median" },
  { name: "1st Quartile", key: "q1" },
  { name: "3rd Quartile", key: "q3" },
];

const PIE_COLORS = ["#FFAB53", "#2E9996", "#2E4E99", "#682E99", "#992E4F"];

const Histogram = ({ title, subtitle, data, isLoading, targetGridWidth }) => (
  <Component
    title={title}
    subtitle={subtitle}
    lg_width={targetGridWidth}
    md_width={12}
    loading={isLoading}
  >
    <ResponsiveContainer width={"100%"} height={300}>
      <BarChart data={data} margin={{ left: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray={"3 3"} />
        <XAxis dataKey={"period"}>
          <Label value={"Delay (Months)"} position={"bottom"} />
        </XAxis>
        <YAxis>
          <Label value={"Count"} position={"left"} angle={270} />
        </YAxis>
        <Tooltip />
        <Bar dataKey={"count"} fill={"#FFAB53"} />
      </BarChart>
    </ResponsiveContainer>
  </Component>
);

export const TailAnalysisComponent = () => {
  const [chartsData, setChartsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const claimsQuery = useDebouncedValue(
    useSelector(pricingSelectors.selectPricingClaims)
  );

  const fetchChartsData = useCallback(async (claimsQuery) => {
    try {
      setIsLoading(true);
      setChartsData({});
      const response = await authenticatedFetch(
        config.endpoints.root("claims/charts/tail-analysis"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(claimsQuery),
        }
      );
      const chartsData = await response.json();
      setChartsData(chartsData);
    } catch (error) {
      Sentry.captureException(error);
      setChartsData({});
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (claimsQuery) {
      fetchChartsData(claimsQuery);
    }
  }, [fetchChartsData, claimsQuery]);

  const censoredClosedYearlyTotal = (
    chartsData.censoredClosedYearlyHist ?? []
  ).reduce((total, elem) => total + (elem.count ?? 0), 0);
  const censoredClosedYearlyOver1y = (chartsData.censoredClosedYearlyHist ?? [])
    .filter((elem) => (elem.period ?? 0) >= 1)
    .reduce((total, elem) => total + (elem.count ?? 0), 0);
  const percentCensoredClosedYearlyOver1y =
    (100 * censoredClosedYearlyOver1y) / censoredClosedYearlyTotal;

  return (
    <>
      <Component
        title={"Tail Stats"}
        subtitle={"Summary statistics of the times to notification and close."}
        xs_width={12}
        loading={isLoading}
      >
        <Table size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>
                {"Notification Delay (Days)"}
                <div style={{ fontSize: "small", color: "grey" }}>
                  {"Loss Date -> Notification Date"}
                </div>
              </TableCell>
              <TableCell>
                {"Settlement Delay (Days)"}
                <div style={{ fontSize: "small", color: "grey" }}>
                  {"Notification Date -> Closed Date"}
                </div>
              </TableCell>
              <TableCell>
                {"Currently Open (Days)"}
                <div style={{ fontSize: "small", color: "grey" }}>
                  {"Notification Date -> "}
                  {chartsData.asAtDateString}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{"Count"}</TableCell>
              <TableCell>
                {chartsData.notifiedDailyStats?.count}
                {(chartsData.notifiedDailyStats?.count ?? 0) <
                  (chartsData.occurredDailyStats?.count ?? 0) && (
                  <span style={{ color: "red" }}>
                    {" (of "}
                    {chartsData.occurredDailyStats?.count} {"total claims)"}
                  </span>
                )}
              </TableCell>
              <TableCell>{chartsData.closedDailyStats?.count}</TableCell>
              <TableCell>{chartsData.openDailyStats?.count}</TableCell>
            </TableRow>
            {STATS_ROWS.map((rowDefn, i) => (
              <TableRow key={`DAILY_STATS_TABLE_ROW_${i}`}>
                <TableCell>{rowDefn.name}</TableCell>
                <TableCell>
                  {numbers.dpString(0)(
                    chartsData.notifiedDailyStats?.[rowDefn.key]
                  )}
                </TableCell>
                <TableCell>
                  {numbers.dpString(0)(
                    chartsData.closedDailyStats?.[rowDefn.key]
                  )}
                </TableCell>
                <TableCell>
                  {numbers.dpString(0)(
                    chartsData.openDailyStats?.[rowDefn.key]
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Component>
      <Histogram
        title={"Notification Delay"}
        subtitle={
          "Count of claims grouped by months from loss date to notification date."
        }
        data={chartsData.notifiedMonthlyHist}
        isLoading={isLoading}
        targetGridWidth={4}
      />
      <Histogram
        title={"Settlement Delay"}
        subtitle={
          "Count of claims grouped by months from notified date to closed date."
        }
        data={chartsData.closedMonthlyHist}
        isLoading={isLoading}
        targetGridWidth={4}
      />
      <Histogram
        title={"Currently Open"}
        subtitle={`Count of currently open claims grouped by months from notified date to ${chartsData.asAtDateString}.`}
        data={chartsData.openMonthlyHist}
        isLoading={isLoading}
        targetGridWidth={4}
      />
      <Component
        title={"Time to Settle"}
        subtitle={`Percent of claims by years spent open. Note that currently-open claims are right-censored and are counted as the number of years from the notification date to ${chartsData.asAtDateString}.`}
        lg_width={6}
        md_width={12}
        loading={isLoading}
      >
        <ResponsiveContainer width={"100%"} height={300}>
          <PieChart>
            <Pie
              data={chartsData.censoredClosedYearlyHist ?? []}
              dataKey={"count"}
              nameKey={"period"}
              label={false}
              legendType={"circle"}
              isAnimationActive={false}
              innerRadius={"50%"}
              outerRadius={"80%"}
            >
              {(chartsData.censoredClosedYearlyHist ?? []).map(
                (entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={PIE_COLORS[index % PIE_COLORS.length]}
                  />
                )
              )}
              <Label
                value={`${numbers.dpString(1)(
                  percentCensoredClosedYearlyOver1y
                )}% > 1yr`}
                position={"center"}
              />
            </Pie>
            <Legend />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Component>
    </>
  );
};

export default TailAnalysisComponent;
