import * as hashes from "common/hashes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as analyticsService from "services/analyticsService";
import * as staticDataService from "services/staticDataService";
import * as analyticsStore from "store/analytics";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as submissionSelector from "store/selectors/submission/submissionSelector";
import * as userSelectors from "store/selectors/user/userSelectors";

const useSubmissionHash = (submission) => {
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    (async () => {
      const hash = await hashes.hashDict(submission);
      setCurrent(hash);
    })();
  }, [submission, setCurrent]);

  return current;
};

export const usePricing = () => {
  const dispatch = useDispatch();

  const submission = useDebouncedValue(
    useSelector(submissionSelector.selectResolvedSubmissionWithoutInput)
  );

  const isUserReadOnly = useSelector(userSelectors.isUserReadOnly);

  const latestSubmissionHash = useSelector(analyticsStore.select)
    ?.programPricing?.submissionHash;

  const submissionHash = useSubmissionHash(submission);

  const { data: analyticsConfig } = staticDataService.useConfigQuery(
    "analytics"
  );

  const out = analyticsService.useSubmissionAnalyticsQuery(
    {
      engine: analyticsConfig?.programPricing?.engine,
      metric: "program_pricing",
      submission,
    },
    {
      skip:
        isUserReadOnly ||
        !analyticsConfig?.programPricing?.engine ||
        (latestSubmissionHash != null &&
          latestSubmissionHash === submissionHash),
    }
  );

  const data = out?.data;

  useEffect(() => {
    if (data != null && !isUserReadOnly) {
      dispatch(analyticsStore.update({ programPricing: data }));
    }
  }, [data, isUserReadOnly, dispatch]);

  return out;
};
