import {
  genErrorListByInputColumn,
  mergeCells,
} from "./ClaimsTransformerSupport";
import {
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as dates from "common/dates";
import * as numbers from "common/numbers";
import * as strings from "common/strings";
import Button from "components/common/Button";
import { useState } from "react";

const WarningTable = ({ errors, setDateFormat, dateConversionTypeList }) => {
  if (!errors?.warnings) return null;
  const hasMmDd = errors.warnings.some(
    (w) => w.code === "DATES_CONVERTED_MM_DD_YYYY"
  );
  const hasDdMm = errors.warnings.some(
    (w) => w.code === "DATES_CONVERTED_DD_MM_YYYY"
  );
  const hasIndeterminate = errors.warnings.some(
    (w) => w.code === "DATE_STRING_TYPE_INDETERMINATE"
  );

  if (hasDdMm) {
    setDateFormat(errors.column, "DDMM");
  } else if (hasMmDd) {
    setDateFormat(errors.column, "MMDD");
  }

  return (
    <Paper elevation={0} sx={{ marginBottom: "0.5rem", padding: "0.5rem" }}>
      {
        <table>
          <tbody>
            {hasIndeterminate && (
              <tr>
                <td>
                  <h2>{"Please select a date format"}</h2>
                </td>
                <td rowSpan={errors.warnings.length}>
                  <Select
                    value={
                      dateConversionTypeList?.[errors.column] ?? "ISO_ONLY"
                    }
                    onChange={(val) => {
                      setDateFormat(errors.column, val?.target?.value);
                    }}
                  >
                    <MenuItem value={"ISO_ONLY"}>{"Automatic"}</MenuItem>
                    <MenuItem value={"MMDD"}>{"Month/Day/Year"}</MenuItem>
                    <MenuItem value={"DDMM"}>{"Day/Month/Year"}</MenuItem>
                  </Select>
                </td>
              </tr>
            )}
            {errors.warnings.map((w) => (
              <tr>
                <td>
                  <Typography variant={"description"}>{w.message}</Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </Paper>
  );
};

const DataEditor = ({
  value,
  isDate,
  isValid,
  isIgnored,
  onChange,
  onToggleIgnored,
}) => {
  return (
    <Stack direction={"row"} alignItems={"center"} gap={4}>
      <TextField
        size={"small"}
        value={value}
        placeholder={isDate ? "YYYY-MM-DD" : null}
        error={!isValid}
        onChange={(e) => onChange(e.target.value)}
        disabled={isIgnored}
        InputProps={{
          sx: { background: isIgnored ? "#edf0f3" : null },
        }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={isIgnored}
            tabIndex={-1}
            onChange={(e) => onToggleIgnored(e.target.checked)}
          />
        }
        label={"Ignore this row"}
      />
    </Stack>
  );
};

const ErrorsTable = ({
  errors,
  substitutions,
  rowDeletions,
  onSubstitutionsChanged,
  onRowDeletionsChanged,
  onReProcess,
}) => {
  const isDateErrorContext = (errors?.entries?.[0]?.errorCode ?? []).includes(
    "TO_DATE"
  );

  const parse = (value) =>
    isDateErrorContext
      ? value
      : (numbers.parseNumberString(value) ?? "").toString();

  const isValid = (value) =>
    isDateErrorContext
      ? dates.parseDateStringFromDateOrYear(value, {}) != null
      : !isNaN(Number(value));

  const updateMeta = (col, row, newValue) => {
    const parsed = parse(newValue);
    newValue = parsed === "" ? newValue : parsed;

    const item = {
      column: col,
      row: row,
      value: newValue,
      isValid: isValid(newValue),
    };
    const index =
      substitutions?.findIndex((x) => x.column === col && x.row === row) ?? -1;

    if (index === -1) {
      onSubstitutionsChanged([...substitutions, item]);
    } else {
      const updated = [...substitutions];
      updated.splice(index, 1);
      if (strings.hasValue(newValue)) {
        updated.push(item);
      }
      onSubstitutionsChanged(updated);
    }
  };

  const updateRowDeletions = (row, isIgnored) => {
    const inList = (rowDeletions ?? []).includes(row);
    if (isIgnored && !inList) {
      onRowDeletionsChanged([...(rowDeletions ?? []), row]);
    } else if (!isIgnored && inList) {
      onRowDeletionsChanged(rowDeletions?.filter((x) => x !== row) ?? []);
    }
  };

  const errorsWithSubstitutions = errors?.entries.map((error) => {
    const overlay =
      substitutions?.filter(
        (x) => x.column === error.column && x.row === error.row
      )?.[0] ?? {};
    return {
      ...error,
      ...overlay,
    };
  });

  return (
    <Paper elevation={0}>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{"Details"}</TableCell>
              <TableCell>{"Cell"}</TableCell>
              <TableCell>{"Spreadsheet Value"}</TableCell>
              <TableCell>{"Override"}</TableCell>
              <TableCell>
                <Button onClick={onReProcess} sx={{ float: "right" }}>
                  {"Apply Changes"}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errorsWithSubstitutions.map((error) => {
              const isIgnored = (rowDeletions ?? []).includes(error.row);
              return (
                <TableRow key={errors.colChar + error.rowNumber}>
                  <TableCell key={"error"}>{error.error.join("; ")}</TableCell>
                  <TableCell key={"Cell"}>
                    {errors.colChar}
                    {error.rowNumber}
                  </TableCell>
                  <TableCell
                    key={"value"}
                    sx={{
                      color: isIgnored ? "silver" : null,
                      textDecoration: isIgnored ? "line-through" : null,
                    }}
                  >
                    {error.originalValue}
                  </TableCell>
                  <TableCell colSpan={2}>
                    <DataEditor
                      value={error.value}
                      isDate={isDateErrorContext}
                      isValid={error.isValid ?? true}
                      isIgnored={isIgnored}
                      onChange={(newValue) => {
                        updateMeta(error.column, error.row, newValue);
                      }}
                      onToggleIgnored={(isIgnored) => {
                        updateRowDeletions(error.row, isIgnored);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const DataValidation = ({
  conversionErrors,
  headings,
  origin,
  substitutions,
  rowDeletions,
  setDateConversionType,
  dateConversionTypeList,
  onReProcess,
  onSubstitutionsChanged,
  onRowDeletionsChanged,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const errorsByInputColumn = mergeCells(
    genErrorListByInputColumn(conversionErrors, headings, origin),
    origin
  );
  const errors = errorsByInputColumn?.[activeIndex];

  return (
    <Stack direction={"column"} spacing={2}>
      {(errorsByInputColumn?.length ?? 0) > 0 && (
        <Stack direction={"column"} spacing={1}>
          <Typography>
            {"Marmalade encountered the following problems during import:"}
          </Typography>
          <Stack direction={"row"} flexWrap={"wrap"} gap={1}>
            {errorsByInputColumn.map((error, index) => {
              return (
                <Card
                  variant={"outlined"}
                  key={index}
                  className={index === activeIndex ? "active" : null}
                  sx={{
                    width: "33%",
                    maxWidth: "14rem",
                    "&.active": {
                      borderColor: "#fa7b35",
                      background: "#fff1e9",
                    },
                  }}
                >
                  <CardActionArea
                    sx={{ height: "100%" }}
                    onClick={() => setActiveIndex(index)}
                  >
                    <CardContent>
                      <Typography fontWeight={"bold"}>
                        {error.inputColumnName}
                      </Typography>
                      <small>
                        {error.entries.length}
                        {error.entries.length === 1 ? " issue" : " issues"}
                      </small>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </Stack>
          <Stack>
            <WarningTable
              errors={errors}
              dateConversionTypeList={dateConversionTypeList}
              setDateFormat={setDateConversionType}
            />
            <ErrorsTable
              errors={errors}
              substitutions={substitutions}
              rowDeletions={rowDeletions}
              onSubstitutionsChanged={onSubstitutionsChanged}
              onRowDeletionsChanged={onRowDeletionsChanged}
              onReProcess={onReProcess}
            />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DataValidation;
