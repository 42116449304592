import * as logger from "common/logger";
import * as config from "config";
import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actions from "store/actions/pricing/tower/towerPricingActions";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as utils from "utils";

const validateTowerPricingRequest = (request) => {
  if (!request) {
    return ["Empty request"];
  }

  return [
    [request?.naicsCode, "Input \u203A Program \u203A Pricing NAICS"],
    [request?.in_US, "Input \u203A Program \u203A Location"],
    [
      request?.turnoverCategory,
      "Input \u203A Program \u203A Turnover Category",
    ],
    [
      request?.baseLayer?.limit,
      "Pricing \u203A Loss Frequency \u203A Pricing Parameters \u203A Base Layer Limit",
    ],
    [
      request?.baseLayer?.attachment,
      "Pricing \u203A Loss Frequency \u203A Pricing Parameters \u203A Base Layer Attachment",
    ],
    [
      request?.selectedLayer,
      "Pricing \u203A Tower Pricing \u203A Tower of Layer \u203A Selected Layer",
    ],
    [
      request?.expectedBaseLayerLosses,
      "Pricing \u203A Loss Frequency \u203A Pricing Parameters \u203A Losses for Pricing",
    ],
  ]
    .map(([value, error]) => (value == null ? error : null))
    .filter((x) => x != null);
};

export function* _calculateTowerPricing_dedicatedImpl() {
  try {
    yield put(actions.calculateTowerPricing.started());

    const towerPricingRequest = yield select(
      towerPricingSelectors.getTowerPricingRequest
    );

    const towerPricingRequestErrors = validateTowerPricingRequest(
      towerPricingRequest
    );
    if (towerPricingRequestErrors.length) {
      yield put(
        actions.calculateTowerPricing.failed(
          `Missing one or more pricing inputs:\n${towerPricingRequestErrors
            .map((x) => "\u2022 " + x)
            .join("\n")}`
        )
      );
      return;
    }

    const response = yield call(
      utils.authenticatedFetch,
      config.endpoints.claims("pricing/tower-pricing"),
      {
        method: "post",
        body: JSON.stringify(towerPricingRequest),
      }
    );
    const data = yield call([response, response.json]);
    yield put(actions.calculateTowerPricing.succeeded(data));
  } catch (e) {
    yield put(actions.calculateTowerPricing.failed("Pricing failed"));
    logger.exception(e);
  }
}

export function* calculateTowerPricing() {
  const analyticsConfig = yield select(
    staticDataSelectors.selectAnalyticsConfig
  );
  if (analyticsConfig?.towerPricing?.backend === "analytics") {
    return;
  }

  yield* _calculateTowerPricing_dedicatedImpl();
}

export default function* towerPricingSaga() {
  yield takeLatest(
    actions.calculateTowerPricing.requested,
    calculateTowerPricing
  );
}
