export const move = (array, { fromIndex, toIndex }) => {
  if (
    !Number.isInteger(fromIndex) ||
    !Number.isInteger(toIndex) ||
    fromIndex < 0 ||
    fromIndex >= array.length ||
    toIndex < 0 ||
    fromIndex === toIndex
  ) {
    return array;
  } else if (fromIndex < toIndex) {
    return [
      ...array.slice(0, fromIndex),
      ...array.slice(fromIndex + 1, toIndex),
      array[fromIndex],
      ...array.slice(toIndex),
    ];
  } else {
    return [
      ...array.slice(0, toIndex),
      array[fromIndex],
      ...array.slice(toIndex, fromIndex),
      ...array.slice(fromIndex + 1),
    ];
  }
};

export const hasItems = (array) =>
  array != null && Array.isArray(array) && array.length > 0;
