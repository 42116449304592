import _ from "lodash";
import { Rectangle } from "recharts";

export const numberDistanceBetweenTicks = (range) => {
  const base = Math.floor(Math.log10(range * 2));
  const mantissa = (range * 2) / Math.pow(10, base);
  if (mantissa < 2) {
    return Math.pow(10, base - 1);
  }
  if (mantissa < 5) {
    return 2 * Math.pow(10, base - 1);
  }
  return 5 * Math.pow(10, base - 1);
};

export const makeTicksForNumericDomain = (domain) => {
  const range = domain[1] - domain[0];
  const d = numberDistanceBetweenTicks(range);
  let start = Math.floor(domain[0] / d) * d;
  let stop = (1 + Math.ceil(domain[1] / d)) * d;
  const dCheck = numberDistanceBetweenTicks(stop - start);
  if (dCheck > d) {
    start = Math.floor(domain[0] / dCheck) * dCheck;
    stop = (1 + Math.ceil(domain[1] / dCheck)) * dCheck;
  }
  return _.range(start, stop, dCheck);
};

export const dateTickFormatter = (number) => {
  const month = Math.round(number);
  if (month % 12 === 0) {
    return `${month / 12}`;
  }
  switch (month % 12) {
    case 1:
      return "Feb";
    case 2:
      return "Mar";
    case 3:
      return "Apr";
    case 4:
      return "May";
    case 5:
      return "Jun";
    case 6:
      return "Jul";
    case 7:
      return "Aug";
    case 8:
      return "Sep";
    case 9:
      return "Oct";
    case 10:
      return "Nov";
    default:
      return "Dec";
  }
};

export const minWidthBarFactory = (minWidth) => ({
  fill,
  x,
  y,
  width,
  height,
}) => {
  return (
    <Rectangle
      x={x}
      y={y}
      width={Math.max(minWidth, width)}
      height={height}
      fill={fill}
    />
  );
};

export function getNumberDomain(...args) {
  const charts = Array.from(args);
  const smallestClaim = Math.min(
    0,
    ...charts.map((c) => c?.smallestClaim || 0)
  );
  const biggestClaim = Math.max(0, ...charts.map((c) => c?.biggestClaim || 0));
  return [smallestClaim, biggestClaim];
}

export function getDateDomain(...args) {
  const chartLowerBounds = args
    .map((c) => c?.domain?.[0])
    .filter((x) => x != null);
  const chartUpperBounds = args
    .map((c) => c?.domain?.[1])
    .filter((x) => x != null);

  const chartLowerBound = chartLowerBounds?.length
    ? Math.min(...chartLowerBounds)
    : null;
  const chartUpperBound = chartUpperBounds?.length
    ? Math.max(...chartUpperBounds)
    : null;

  const lowerBound = chartLowerBound ?? chartUpperBound ?? 0;
  const upperBound = chartUpperBound ?? lowerBound;
  return [Math.min(lowerBound, upperBound), Math.max(lowerBound, upperBound)];
}

export const distanceBetweenDateTicks = (range) => {
  if (range < 15) return 1;
  if (range < 30) return 3;
  if (range < 60) return 6;
  if (range < 120) return 12;
  if (range < 240) return 24;
  return 60;
};

export const getDateTicks = (domain) => {
  if (domain[1] === Infinity || domain[0] === -Infinity) {
    return [];
  }
  const min = domain[0];
  const max = domain[1];
  const range = max - min;
  if (range > 0) {
    const tickGap = distanceBetweenDateTicks(range);
    const start = Math.floor(min / tickGap) * tickGap;
    return [...Array(Math.floor(range / tickGap) + 1).keys()]
      .map((x) => x * tickGap + start)
      .filter((x) => x >= min && x <= max);
  }
  return [];
};
