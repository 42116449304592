export const extractSubmissionIdFromFilename = (filename) => {
  if (
    typeof filename !== "string" ||
    !filename.endsWith(".json") ||
    filename.length <= 5
  ) {
    throw new Error(`Invalid Submission filename: ${filename}`);
  }
  return filename.substring(0, filename.length - 5);
};
