import * as userSelectors from "../store/selectors/user/userSelectors";
import Console from "./admin/Console";
import AccessDeniedDialog from "./common/AccessDeniedDialog";
import { connect } from "react-redux";

const AdminApp = ({ isSuperUser }) => {
  return isSuperUser ? (
    <Console />
  ) : (
    <AccessDeniedDialog
      message={"Your account does not have administrator privileges."}
    />
  );
};

const mapStateToProps = (state) => ({
  isSuperUser: userSelectors.isSuperUser(state),
});

export default connect(mapStateToProps)(AdminApp);
