import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as excel from "common/excel";
import { useState } from "react";

const RangeSelectionSpreadsheet = ({ data, range, setRange, sx }) => {
  const columnCount = data?.[0]?.length ?? 0;

  const [selection, setSelection] = useState(null);

  const setRangeFromCells = (cell1, cell2) => {
    setRange({
      topLeft: {
        row: Math.min(cell1.row, cell2.row),
        column: Math.min(cell1.column, cell2.column),
      },
      bottomRight: {
        row: Math.max(cell1.row, cell2.row),
        column: Math.max(cell1.column, cell2.column),
      },
    });
    setSelection(null);
  };

  const isRangeSet = !!range?.topLeft && !!range?.bottomRight;

  return (
    <TableContainer
      sx={{
        width: "100%",
        overflow: "scroll",
        ...sx,
      }}
    >
      <Table
        size={"small"}
        sx={{
          width: "100%",
          borderCollapse: "separate",
          borderSpacing: 0,
          "& th": {
            height: "1.5ex",
            background: "lightgrey",
            borderTop: "1px solid grey",
            borderBottom: "1px solid grey",
            borderRight: "1px solid grey",
            padding: "0.1em",
            position: "sticky",
            top: 0,
            textAlign: "center",
            zIndex: 1,
          },
          "& td": {
            borderBottom: "1px solid grey",
            borderRight: "1px solid grey",
            padding: "0.1em",
          },
          "& th:first-of-type": {
            borderLeft: "1px solid grey",
            position: "sticky",
            left: 0,
          },
          "& td:first-of-type": {
            background: "lightgrey",
            borderLeft: "1px solid grey",
            position: "sticky",
            left: 0,
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell />
            {Array.from(Array(columnCount).keys()).map((columnIndex) => (
              <TableCell key={columnIndex}>
                {excel.getColumnName(columnIndex)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, rowIndex) => {
            const doesRowIntersectRange =
              isRangeSet &&
              rowIndex >= range.topLeft.row &&
              rowIndex <= range.bottomRight.row;
            return (
              <TableRow key={rowIndex}>
                <TableCell>{rowIndex + 1}</TableCell>
                {row?.map((value, columnIndex) => {
                  const isSelected =
                    selection?.row === rowIndex &&
                    selection?.column === columnIndex;
                  const isInRange =
                    doesRowIntersectRange &&
                    columnIndex >= range.topLeft.column &&
                    columnIndex <= range.bottomRight.column;
                  const className = isSelected
                    ? "sheet-preview-cell-active"
                    : isInRange
                    ? "sheet-preview-cell-content"
                    : "";
                  return (
                    <TableCell
                      key={columnIndex}
                      onClick={() => {
                        const cell = { row: rowIndex, column: columnIndex };
                        if (selection) {
                          setRangeFromCells(selection, cell);
                        } else {
                          setRange({});
                          setSelection(cell);
                        }
                      }}
                      className={className}
                    >
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RangeSelectionSpreadsheet;
