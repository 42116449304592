export const calculateSearchScore = (object, { searchStrings }) => {
  return (searchStrings ?? [])
    .map((searchString) =>
      Object.values(object)
        .map((value) =>
          typeof value === "string" && value.includes(searchString) ? 1 : 0
        )
        .reduce((a, b) => a + b, 0)
    )
    .filter((matchCount) => matchCount > 0)
    .map((matchCount) => 1 / (1 + Math.exp(-matchCount)))
    .reduce((a, b) => a + b, 0);
};

export const search = (
  objects,
  { search = null, keys = null, caseSensitive = false }
) => {
  if (!search?.length) {
    return [];
  }

  const sanitizeString = caseSensitive
    ? (s) => s.trim()
    : (s) => s.toLowerCase().trim();

  const searchStrings = (search ?? []).map(sanitizeString);
  if (searchStrings.length === 1 && searchStrings[0] === "") {
    return objects ?? [];
  }

  const sanitizeObjectForSearch = (object) =>
    Object.fromEntries(
      Object.entries(object)
        .filter(([k]) => keys == null || keys.includes(k))
        .filter(([_, v]) => typeof v === "string")
        .map(([k, v]) => [k, sanitizeString(v)])
    );

  return (objects ?? [])
    .map((object) => [object, sanitizeObjectForSearch(object)])
    .map(([object, objectToSearch]) => [
      object,
      calculateSearchScore(objectToSearch, { searchStrings }),
    ])
    .filter(([, score]) => score > 0)
    .sort(([, aScore], [, bScore]) => bScore - aScore)
    .map(([object, _]) => object);
};

export const flattenKeys = (
  obj,
  { parentKey = "", result = {}, separator = "." } = {}
) => {
  if (Array.isArray(obj)) {
    result[parentKey ? `${parentKey}${separator}length` : "length"] =
      obj.length;
  }
  for (let [key, value] of Object.entries(obj)) {
    let newKey = parentKey ? `${parentKey}${separator}${key}` : key;

    if (value && typeof value === "object" && value !== null) {
      flattenKeys(value, { parentKey: newKey, result, separator });
    } else {
      result[newKey] = value;
    }
  }
  return result;
};

// See: https://gist.github.com/davidfurlong/463a83a33b70a3b6618e97ec9679e490
const _stringifyReducer_sortKeys = (key, value) =>
  value instanceof Object && !(value instanceof Array)
    ? Object.keys(value)
        .sort()
        .reduce((sorted, key) => {
          sorted[key] = value[key];
          return sorted;
        }, {})
    : value;

export const stringifyReducers = {
  sortKeys: _stringifyReducer_sortKeys,
};
