import step from "./step";
import * as chartUtils from "chartUtils";
import * as numbers from "common/numbers";
import Component from "components/Component";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import {
  Bar,
  XAxis,
  Cell,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ComposedChart,
  Tooltip,
  Line,
} from "recharts";

const BAD_COLOR = "#F5BEB7";
const GOOD_COLOR = "#B7F4BA";

const getColor = (entry) => {
  if (entry?.direction === "DOWN") {
    return BAD_COLOR;
  }
  if (entry?.direction === "UP") {
    return GOOD_COLOR;
  }
  return "#FFAB53";
};

const tooltipFormatter = (value, name, props) => {
  if (name === "line") {
    return ["", ""];
  }
  if (value == null) {
    return "N/A";
  }
  const formatter = numbers.dpString(0);
  const percentFormatter = numbers.dpString(1, {
    suffix: "%",
  });
  if (Array.isArray(value)) {
    const delta = value[1] - value[0];
    const base = props?.payload?.base;
    if (!base) {
      return [formatter(delta), ""];
    }
    return [
      `${formatter(delta)} (${percentFormatter((delta * 100) / base)})`,
      "",
    ];
  }
  return [formatter(value), ""];
};

const transform = (c) => {
  if (c == null) {
    return c;
  }
  return (c.decomposition ?? []).map(({ key, name, value, base, delta }) =>
    delta == null
      ? {
          name: { start: "Expiring", end: "Premium" }[key] ?? name,
          value,
          base,
          line: value,
        }
      : {
          name,
          value: [value, value + delta],
          base,
          direction: delta >= 0 ? "UP" : "DOWN",
          line: value + delta,
        }
  );
};

const WaterfallChart = ({ activeLayerRateChange, calculate, isLoading }) => {
  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  const transformedChartData = transform(activeLayerRateChange?.chartData);

  return (
    <Component
      title={"Rate Change"}
      loading={isLoading}
      options={
        <Disabled ifReadOnly>
          <Button onClick={calculate} isLoading={isLoading}>
            {"Calculate"}
          </Button>
        </Disabled>
      }
    >
      <ResponsiveContainer width={"100%"} height={400}>
        <ComposedChart
          data={transformedChartData}
          barCategoryGap={0}
          margin={{
            top: 0,
            right: 50,
            left: 0,
            bottom: 60,
          }}
          ref={chartRef}
        >
          <CartesianGrid strokeDasharray={"3 3"} />
          <XAxis dataKey={"name"} angle={-45} textAnchor={"end"} />
          <YAxis tickFormatter={chartUtils.tickFormatter} />
          <Tooltip
            formatter={tooltipFormatter}
            separator={""}
            position={tooltipPosition}
            isAnimationActive={false}
          />
          <Bar dataKey={"value"}>
            {transformedChartData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(entry)} />
            ))}
          </Bar>
          <Line dataKey={"line"} type={step} dot={false} stroke={"black"} />
        </ComposedChart>
      </ResponsiveContainer>
    </Component>
  );
};

export default WaterfallChart;
