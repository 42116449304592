import * as types from "./maintenanceStatusActionTypes";
import * as Sentry from "@sentry/react";
import * as config from "config";
import * as utils from "utils";

export const updateMaintenanceStatus = (updates) => ({
  type: types.UPDATE_MAINTENANCE_STATUS,
  payload: updates,
});

export const closeWarning = () => (dispatch) => {
  dispatch({ type: types.CLOSE_MAINTENANCE_WARNING });
};

export const loadMaintenanceStatus = (url) => (dispatch) => {
  if (!url) {
    return;
  }
  const run = () => {
    utils
      .plainFetch(url, [404])
      .then((body) => {
        if (body.status === 404) {
          setTimeout(run, config.MAINTENANCE_STATE_POLL_INTERVAL_SECS * 1000);
          dispatch({ type: types.LOAD_MAINTENANCE_STATUS_FAIL });
        } else {
          body
            .json()
            .then((json) => {
              setTimeout(
                run,
                config.MAINTENANCE_STATE_POLL_INTERVAL_SECS * 1000
              );
              dispatch({
                type: types.LOAD_MAINTENANCE_STATUS_OK,
                payload: json,
              });
            })
            .catch((error) => {
              setTimeout(
                run,
                config.MAINTENANCE_STATE_ERROR_RETRY_TIME_SECS * 1000
              );
              dispatch({ type: types.LOAD_MAINTENANCE_STATUS_FAIL });
            });
        }
      })
      .catch((error) => {
        setTimeout(run, config.MAINTENANCE_STATE_ERROR_RETRY_TIME_SECS * 1000);
        dispatch({ type: types.LOAD_MAINTENANCE_STATUS_FAIL });
        Sentry.captureException(error);
      });
  };
  run();
};
