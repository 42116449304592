import Tooltip from "@mui/material/Tooltip";

const ConditionalTooltip = (props) => {
  if (props.conditional) {
    return <Tooltip title={props.title}>{props.children}</Tooltip>;
  }
  return <>{props.children}</>;
};

export default ConditionalTooltip;
