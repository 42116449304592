export const formatSavedValue = (value) => {
  const reducer = (combined, [key, values]) => {
    if (key.length > 0) {
      values.forEach((value) => {
        if (!combined[key]?.includes(value) && value.length > 0) {
          combined[key] = [...(combined[key] ?? []), value];
        }
      });
    }
    return combined;
  };

  return {
    ...value,
    componentOnStateVisibility: Object.fromEntries(
      Object.entries(value.componentOnStateVisibility || {}).map(
        ([key, oldDict]) => {
          const newDict = {
            showOnState: oldDict.showOnState?.reduce(reducer, {}),
            hideOnState: oldDict.hideOnState?.reduce(reducer, {}),
          };
          return [key, newDict];
        }
      )
    ),
  };
};
