import * as config from "../../../../config";
import * as utils from "../../../../utils";
import { selectSubmissionId } from "../../../selectors/persistence/persistenceSelectors";
import { handleError } from "../../error/errorActions";
import { cacheImage } from "../../temp/images/imagesActions";
import * as types from "./imagesActionTypes";

export const uploadedImage = (localId, submissionId, imageId, imageType) => ({
  type: types.UPLOADED_IMAGE,
  payload: { localId, submissionId, imageId, imageType },
});

export const getImageType = (mimeType) => {
  switch ((mimeType || "").toLowerCase()) {
    case "image/jpeg":
      return "JPEG";
    case "image/png":
      return "PNG";
    case "image/bmp":
      return "BMP";
    case "image/gif":
      return "GIF";
    default:
      return undefined;
  }
};

export const uploadImage = (file, localId) => (dispatch, getState) => {
  const imageType = getImageType(file.type);
  if (imageType == null) {
    dispatch(
      handleError("UNSUPPORTED_IMAGE_FORMAT", {
        title: `Unsupported Image Format: ${file.type}`,
        message: `${file.name} is of an unsupported file type. Try uploading a .bmp,.jpg,.gif or .png`,
      })
    );
    return;
  }
  const submissionId = selectSubmissionId(getState());
  utils
    .authenticatedFetch(config.endpoints.persistence("image"), {
      method: "post",
      body: JSON.stringify({ submissionId, imageType }),
    })
    .then((response) => response.json())
    .then((data) => {
      let formData = new FormData();
      [
        "key",
        "policy",
        "AWSAccessKeyId",
        "x-amz-security-token",
        "signature",
      ].forEach((attribute) => {
        formData.append(attribute, data.aws.fields[attribute]);
      });
      formData.append("file", file);
      fetch(data.aws.url, {
        method: "post",
        cache: "no-cache",
        body: formData,
      }).then(() => {
        dispatch(uploadedImage(localId, submissionId, data.imageId, imageType));
        dispatch(cacheImage(submissionId, data.imageId, imageType));
      });
    });
};
