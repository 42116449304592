import {
  CheckBoxOutlineBlankOutlined as UncheckedIcon,
  CheckBoxOutlined as CheckedIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Component from "components/Component";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import { connect } from "react-redux";
import * as flagActions from "store/actions/input/flags/flagsActions";
import * as flagSelectors from "store/selectors/input/flags/flagsSelectors";

const LABEL_VARIANT = "body2";
const LABEL_INDENT_WIDTH = 4;

const makeTypeLabel = (flag) => {
  return flag?.displayName ?? flag?.type ?? "";
};

const TextFlagCells = ({ flag, updateType, updateCategory }) => {
  const isTypeEditable = typeof updateType === "function" && !flag?.isDefault;
  const isCategoryEditable = typeof updateCategory === "function";
  const typeLabel = makeTypeLabel(flag);

  return (
    <>
      <TableCell
        sx={{ verticalAlign: "center", textAlign: "left" }}
        className={"cell-condensed-padding-md"}
      >
        <Tooltip title={typeLabel}>
          {!isTypeEditable ? (
            <Typography
              variant={LABEL_VARIANT}
              sx={{
                paddingLeft: (flag?.indent ?? 0) * LABEL_INDENT_WIDTH,
              }}
            >
              {typeLabel}
            </Typography>
          ) : (
            <TextField
              value={flag?.type}
              onChange={(e) => updateType(e.target.value)}
              size={"small"}
              variant={"filled"}
              autoComplete={"off"}
              hiddenLabel
              fullWidth
            />
          )}
        </Tooltip>
      </TableCell>
      <TableCell
        sx={{ verticalAlign: "center", textAlign: "left" }}
        className={"cell-condensed-padding-md"}
      >
        {!isCategoryEditable ? (
          <Typography variant={LABEL_VARIANT}>
            {flag?.category ?? ""}
          </Typography>
        ) : (
          <TextField
            value={flag?.category ?? ""}
            onChange={(e) => updateCategory(e.target.value)}
            size={"small"}
            variant={"filled"}
            autoComplete={"off"}
            hiddenLabel
            fullWidth
          />
        )}
      </TableCell>
    </>
  );
};

const NoneFlagCells = ({ flag, updateType }) => {
  const isTypeEditable = typeof updateType === "function" && !flag?.isDefault;
  const typeLabel = makeTypeLabel(flag);

  return (
    <TableCell
      sx={{ verticalAlign: "center", textAlign: "left" }}
      className={"cell-condensed-padding-md"}
      colSpan={2}
    >
      <Tooltip title={typeLabel}>
        {!isTypeEditable ? (
          <Typography
            variant={LABEL_VARIANT}
            sx={{
              paddingLeft: (flag?.indent ?? 0) * LABEL_INDENT_WIDTH,
            }}
          >
            {typeLabel}
          </Typography>
        ) : (
          <TextField
            value={flag?.type}
            onChange={(e) => updateType(e.target.value)}
            size={"small"}
            variant={"filled"}
            autoComplete={"off"}
            hiddenLabel
            fullWidth
          />
        )}
      </Tooltip>
    </TableCell>
  );
};

const BooleanFlagCells = ({ flag, updateCategory }) => {
  const isCategoryEditable = typeof updateCategory === "function";
  const typeLabel = makeTypeLabel(flag);

  if (!isCategoryEditable) {
    return (
      <>
        <TableCell
          sx={{ verticalAlign: "center", textAlign: "left" }}
          className={"cell-condensed-padding-md"}
        >
          <Typography
            variant={LABEL_VARIANT}
            sx={{
              paddingLeft: (flag?.indent ?? 0) * LABEL_INDENT_WIDTH,
            }}
          >
            {typeLabel}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ verticalAlign: "center", textAlign: "left" }}
          className={"cell-condensed-padding-md"}
        >
          {flag?.category ? (
            <CheckedIcon fontSize={"small"} />
          ) : (
            <UncheckedIcon fontSize={"small"} />
          )}
        </TableCell>
      </>
    );
  }

  const checked = !!flag?.category;

  return (
    <TableCell
      sx={{ verticalAlign: "center", textAlign: "left" }}
      className={"cell-condensed-padding-md"}
      colSpan={2}
    >
      <Tooltip title={typeLabel}>
        <Stack
          direction={"row"}
          spacing={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          onClick={() => updateCategory(!checked)}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            variant={LABEL_VARIANT}
            sx={{
              paddingLeft: (flag?.indent ?? 0) * LABEL_INDENT_WIDTH,
            }}
          >
            {typeLabel}
          </Typography>
          <Checkbox
            size={"small"}
            checked={checked}
            disabled={!isCategoryEditable}
          />
        </Stack>
      </Tooltip>
    </TableCell>
  );
};

const SelectFlagCells = ({ flag, updateCategory }) => {
  const isCategoryEditable = typeof updateCategory === "function";
  const typeLabel = makeTypeLabel(flag);

  const NULL_KEY = "__NULL__";

  if (!isCategoryEditable) {
    const category =
      flag?.categoryOptions?.filter(({ key }) => key === flag?.category)?.[0]
        ?.display ?? "";
    return (
      <>
        <TableCell
          sx={{ verticalAlign: "center", textAlign: "left" }}
          className={"cell-condensed-padding-md"}
        >
          <Typography
            variant={LABEL_VARIANT}
            sx={{
              paddingLeft: (flag?.indent ?? 0) * LABEL_INDENT_WIDTH,
            }}
          >
            {typeLabel}
          </Typography>
        </TableCell>
        <TableCell
          sx={{ verticalAlign: "center", textAlign: "left" }}
          className={"cell-condensed-padding-md"}
        >
          <Typography variant={LABEL_VARIANT}>{category}</Typography>
        </TableCell>
      </>
    );
  }

  return (
    <TableCell
      sx={{ verticalAlign: "center", textAlign: "left" }}
      className={"cell-condensed-padding-md"}
      colSpan={2}
    >
      <Tooltip title={typeLabel}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel shrink>{typeLabel}</InputLabel>
          <Select
            label={typeLabel}
            notched
            variant={"outlined"}
            size={"small"}
            value={flag?.category ?? NULL_KEY}
            onChange={(e) => updateCategory(e.target.value)}
            disabled={!isCategoryEditable}
          >
            {flag?.nullCategoryOption && (
              <MenuItem value={NULL_KEY}>{flag?.nullCategoryOption}</MenuItem>
            )}
            {flag?.categoryOptions.map(({ key, display }) => (
              <MenuItem key={key} value={key}>
                {display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </TableCell>
  );
};

export const ExposureFlagCells = ({ flag, updateType, updateCategory }) => {
  const FlagCells =
    flag?.categoryType === "none"
      ? NoneFlagCells
      : flag?.categoryType === "boolean" || flag?.isBooleanFlag
      ? BooleanFlagCells
      : flag?.categoryType === "select"
      ? SelectFlagCells
      : TextFlagCells;

  return (
    <FlagCells
      flag={flag}
      updateType={updateType}
      updateCategory={updateCategory}
    />
  );
};

const ExposureFlag = ({
  flag,
  deleteExposure,
  updateType,
  updateCategory,
  updateComment,
}) => {
  return (
    <TableRow>
      <TableCell>
        {!flag?.isDefault && flag?.canDelete ? (
          <IconButton icon={DeleteIcon} onClick={deleteExposure} />
        ) : (
          <></>
        )}
      </TableCell>
      <ExposureFlagCells
        flag={flag}
        updateType={updateType}
        updateCategory={updateCategory}
      />
      <TableCell
        style={{ verticalAlign: "top" }}
        className={"cell-condensed-padding-md"}
      >
        {!flag?.noComments && (
          <Notes
            key={`ExposureFlagComments-${flag?.type}`}
            title={null}
            hasEditButton={false}
            update={updateComment}
            value={flag?.comments}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export const ExposureFlagsComponent = ({
  flags,
  deleteExposureFlag,
  updateExposureFlag,
}) => {
  return (
    <Component
      title={"Exposure Flags"}
      subtitle={
        "Important exposure details that factors into risk assessment. These inputs do not factor into pricing calculations."
      }
    >
      <TableContainer>
        <Table size={"small"} className={"flags"}>
          <TableHead>
            <TableRow>
              <TableCell width={"5%"} sx={{ minWidth: "30px" }} />
              <TableCell width={"25%"}>{"Type"}</TableCell>
              <TableCell width={"20%"} align={"center"}>
                {"Category"}
              </TableCell>
              <TableCell width={"50%"}>{"Comments"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flags?.map((flag, i) => (
              <ExposureFlag
                key={i}
                flag={flag}
                deleteExposure={() => deleteExposureFlag(i)}
                updateType={(type) => updateExposureFlag("type", i, type)}
                updateCategory={(category) =>
                  updateExposureFlag("category", i, category)
                }
                updateComment={(comment) =>
                  updateExposureFlag("comments", i, comment)
                }
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

const mapDispatchToProps = {
  updateExposureFlag: flagActions.updateExposureFlag,
  deleteExposureFlag: flagActions.deleteExposure,
};

const mapStateToProps = (state) => {
  return {
    flags: flagSelectors.getExposuresForDisplay(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExposureFlagsComponent);
