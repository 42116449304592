import * as strings from "common/strings";

export const resolve = (input, { layers }) => {
  if (input == null) {
    return null;
  }

  const resolved = {
    ...input,
    ..._resolveTIV(input),
    sublimits: _resolveSublimits(input),
  };

  return {
    ...resolved,
    layers: {
      ...resolved.layers,
      ...Object.fromEntries(
        (layers ?? []).map((layer) => [
          layer.id,
          _resolveLayer(resolved, { layer }),
        ])
      ),
    },
  };
};

const _resolveTIV = ({ TIV, BI, PD }) => {
  if (PD != null && BI != null) {
    return {
      PD,
      BI,
      TIV: Number(PD) + Number(BI),
      calculated: "TIV",
    };
  } else if (PD != null && TIV != null) {
    return {
      PD,
      BI: Number(TIV) - Number(PD),
      TIV,
      calculated: "BI",
    };
  } else if ((BI != null) & (TIV != null)) {
    return {
      PD: Number(TIV) - Number(BI),
      BI,
      TIV,
      calculated: "PD",
    };
  } else {
    return {
      calculated: null,
    };
  }
};

const _resolveSublimits = (input) => {
  const perilDefinitions = input?.coverageConfig?.perils ?? {};

  const calcSublimit = (key) => {
    const perilDefinition = perilDefinitions[key] ?? {};
    const sublimit = input?.sublimits?.[key] ?? null;
    if (perilDefinition.parent) {
      const parentSublimit = calcSublimit(perilDefinition.parent);
      if (sublimit != null && parentSublimit != null) {
        return Math.min(sublimit, parentSublimit);
      } else {
        return parentSublimit ?? sublimit;
      }
    } else {
      return sublimit;
    }
  };

  const transformValue =
    input?.riskCode === "DIFFERENCE_IN_CONDITION"
      ? (value) => value ?? 0
      : (value) => value;

  return Object.fromEntries(
    Object.keys(perilDefinitions)
      .map((key) => [key, calcSublimit(key)])
      .map(([key, value]) => [key, transformValue(value)])
  );
};

const _resolveLayer = (input, { layer }) => {
  return {
    ...input?.layers?.[layer.id],
    exposedPerils: _calculateExposedPerils({
      definition: input?.coverageConfig?.layerExposedPerils,
      input,
      layer,
    }),
    perilsDescription: _makePerilsDescription(input, { layer }),
    limitDescription: _makeLimitDescription(input, { layer }),
  };
};

const _calculateExposedPerils = ({ definition, input, layer }) => {
  return (definition ?? []).map(({ peril, children }) => {
    const attachment = layer?.attachment ?? 0;
    const sublimit = input?.sublimits?.[peril] ?? null;

    return {
      peril,
      exposed: sublimit == null || sublimit > attachment,
      children: (children ?? []).map((subperil) => {
        const sublimit = input?.sublimits?.[subperil] ?? null;
        return {
          peril: subperil,
          exposed: sublimit == null || sublimit > attachment,
        };
      }),
    };
  });
};

const _makePerilsDescription = (input, { layer }) =>
  input?.riskCode === "DIFFERENCE_IN_CONDITION"
    ? _makeDicPerilsDescription(input, { layer })
    : _makeAllRisksPerilsDescription(input, { layer });

const _makeAllRisksPerilsDescription = (input, { layer }) => {
  const exposedPerils = _calculateExposedPerils({
    definition: input?.coverageConfig?.layerPerilDescription,
    input,
    layer,
  });

  const lookupPerilName = (peril) =>
    input?.coverageConfig?.perils?.[peril]?.name ?? peril;

  const includedPerils = exposedPerils
    .filter(({ exposed }) => exposed)
    .map(({ peril }) => lookupPerilName(peril));

  const excludedPerils = exposedPerils
    .flatMap(({ peril, exposed, children }) =>
      exposed
        ? children.filter(({ exposed }) => !exposed).map(({ peril }) => peril)
        : [peril]
    )
    .map(lookupPerilName);

  const includedPerilsDescription = includedPerils.length
    ? " including " +
      strings.humanizedJoin(includedPerils, { useOxfordCommas: false })
    : "";
  const excludedPerilsDescription = excludedPerils.length
    ? " excluding " +
      strings.humanizedJoin(excludedPerils, { useOxfordCommas: false })
    : "";

  const perilsDescription =
    "All Risks of Direct Physical Loss or Damage" +
    includedPerilsDescription +
    ";" +
    excludedPerilsDescription +
    " as more fully defined in the Policy Form";

  return perilsDescription;
};

const _makeDicPerilsDescription = (input, { layer }) => {
  const exposedPerils = _calculateExposedPerils({
    definition: input?.coverageConfig?.layerPerilDescription,
    input,
    layer,
  });

  const lookupPerilName = (peril) =>
    input?.coverageConfig?.perils?.[peril]?.name ?? peril;

  const includedPerils = exposedPerils
    .filter(
      ({ exposed, children }) =>
        exposed || children?.some(({ exposed }) => exposed)
    )
    .map(({ peril }) => lookupPerilName(peril));

  if (!includedPerils?.length) {
    return "N/A";
  }

  return includedPerils?.length
    ? "Direct Physical Loss or Damage caused by " +
        strings.humanizedJoin(includedPerils, { useOxfordCommas: false }) +
        " as more fully defined in the Policy Form"
    : "N/A";
};

const _makeLimitDescription = (input, { layer }) => {
  const aggregatePerils = input?.coverageConfig?.limitAggregatePerils ?? [];

  const exposedPerils = _calculateExposedPerils({
    definition: input?.coverageConfig?.limitPerilsDescription ?? [],
    input,
    layer,
  }).filter(({ exposed }) => exposed);

  const hasAggregatePeril = exposedPerils.some(({ peril }) =>
    aggregatePerils.includes(peril)
  );

  const hasNonAggregatePeril = exposedPerils.some(
    ({ peril }) => !aggregatePerils.includes(peril)
  );

  const lookupPerilName = (peril) =>
    input?.coverageConfig?.perils?.[peril]?.name ?? peril;

  const exposedAggregatePerilNames = exposedPerils
    .filter(({ peril }) => aggregatePerils.includes(peril))
    .map(({ peril }) => lookupPerilName(peril));

  if (hasAggregatePeril && hasNonAggregatePeril) {
    return (
      "per Occurrence and in the Annual Aggregate as respects " +
      strings.humanizedJoin(exposedAggregatePerilNames, {
        useOxfordCommas: true,
      })
    );
  } else if (hasAggregatePeril && !hasNonAggregatePeril) {
    return "per Occurrence and in the Annual Aggregate";
  } else {
    return "per Occurrence";
  }
};
