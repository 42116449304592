import {
  Alert,
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import TileOptions from "components/common/TileOptions";
import { useState } from "react";

export const PRICING_METHODS = {
  UNKNOWN: "unknown",
  FGU: "fgu",
  EXCESS: "excess",
};

const defaultAppliedExcess = {
  type: PRICING_METHODS.EXCESS,
  excess: null,
  excessRemoved: false,
};

const AppliedExcessRecorder = ({
  appliedExcess,
  updateAppliedExcess,
  setAppliedExcessValid,
}) => {
  const [error, setError] = useState(null);

  const updateExcess = (excess) => {
    updateAppliedExcess(
      excess == null
        ? {
            ...defaultAppliedExcess,
          }
        : {
            ...(appliedExcess ?? defaultAppliedExcess),
            excess,
          }
    );
  };

  const updateIsExcessRemoved = (excessRemoved) =>
    updateAppliedExcess({
      ...(appliedExcess ?? defaultAppliedExcess),
      excessRemoved: excessRemoved,
    });

  const hasNonZeroExcess = (appliedExcess?.excess ?? 0) !== 0;

  const exampleWithExcess = ((appliedExcess?.excess ?? 0) + 1).toLocaleString(
    "en-US"
  );

  const tileConfig = [
    {
      id: PRICING_METHODS.UNKNOWN,
      label: "Unknown",
      description:
        "The data provider has not indicated whether losses are ground up or excess.",
      width: 350,
    },
    {
      id: PRICING_METHODS.FGU,
      label: "Ground Up",
      description:
        "Losses represent the total amount incurred without applying any deductibles or excess.",
      width: 350,
    },
    {
      id: PRICING_METHODS.EXCESS,
      label: "Excess Of",
      description:
        "Losses have been adjusted to apply the relevant deductible or excess.",
      width: 350,
    },
  ];

  const updatePricingMethod = (method) => {
    setError(null);
    setAppliedExcessValid(true);

    if (method === PRICING_METHODS.FGU) {
      updateAppliedExcess({ type: PRICING_METHODS.FGU });
    } else if (method === PRICING_METHODS.EXCESS) {
      updateAppliedExcess(defaultAppliedExcess);
    } else {
      updateAppliedExcess(null);
    }
  };

  return (
    <Stack spacing={hasNonZeroExcess || error ? 1 : 0}>
      <TileOptions
        title={"FGU vs Excess"}
        description={"Are the losses ground up or in excess of a given amount?"}
        initialActiveId={appliedExcess?.type ?? PRICING_METHODS.UNKNOWN}
        options={tileConfig}
        onChange={(method) => updatePricingMethod(method)}
        sx={{ marginBottom: "1.25rem" }}
      />
      {appliedExcess?.type === PRICING_METHODS.EXCESS && (
        <>
          <PrettyNumberTextField
            label={"Excess"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            autoComplete={"off"}
            variant={"filled"}
            onChangeNumber={(e) => updateExcess(e)}
            onValidation={(isValid) => {
              setError(
                isValid
                  ? null
                  : "Invalid value for the excess. Must be a number."
              );
              setAppliedExcessValid(isValid);
            }}
            value={appliedExcess?.excess ?? null}
            fullWidth
          />
          <Collapse in={hasNonZeroExcess}>
            <FormControl>
              <RadioGroup>
                <FormControlLabel
                  value={"ignore"}
                  label={"Ignore the excess during import"}
                  control={<Radio />}
                  checked={!(appliedExcess.excessRemoved ?? false)}
                  onChange={(e) => updateIsExcessRemoved(false)}
                />
                <FormControlLabel
                  value={"include"}
                  label={`Add the excess to all claims, e.g. a $1 total loss in the file will be treated as $${exampleWithExcess} in Marmalade`}
                  control={<Radio />}
                  checked={appliedExcess.excessRemoved ?? false}
                  onChange={(e) => updateIsExcessRemoved(true)}
                />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </>
      )}
      {!!error && <Alert severity={"error"}>{error}</Alert>}
    </Stack>
  );
};

export default AppliedExcessRecorder;
