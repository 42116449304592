import { useCallback, useMemo } from "react";
import { useRetrieveNavigationConfigQuery } from "services/staticDataService";

export const useOrderSubTabs = ({ tabName }) => {
  const {
    data: navigationConfig,
    isLoading,
  } = useRetrieveNavigationConfigQuery();

  const orderedSubTabNames = useMemo(
    () =>
      (navigationConfig?.tabOrder ?? []).find(
        ([thisTabName, _]) => thisTabName === tabName
      )?.[1] ?? [],
    [navigationConfig, tabName]
  );

  const orderSubTabs = useCallback(
    (subTabs) => {
      const configOrderedSubTabs = orderedSubTabNames
        .map((subTabName) => {
          return subTabs.find((subTab) => subTab?.title === subTabName);
        })
        .filter((x) => x !== undefined);

      const configOrderedSubTabNames = configOrderedSubTabs.map((t) => t.title);

      return configOrderedSubTabs.concat(
        subTabs.filter(
          (subTab) => !configOrderedSubTabNames.includes(subTab.title)
        )
      );
    },
    [orderedSubTabNames]
  );

  return {
    orderSubTabs,
    isSubTabOrderLoading: isLoading,
  };
};
