import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { stringToColor } from "common/strings";
import React from "react";

const isNameUnknown = (name) => {
  return (
    name == null || name === "" || name === "-" || typeof name !== "string"
  );
};

const PersonMonogram = ({ person }) => {
  let name = person.name;
  if (isNameUnknown(name)) {
    name = person?.company ?? "?";
  }
  const initials = name
    .split(" ")
    .map((x) => x[0])
    .join("");

  return <Avatar sx={{ bgcolor: stringToColor(name) }}>{initials}</Avatar>;
};

const PersonSummaryText = ({
  person,
  showEmail = true,
  showCompany = true,
  showLicenseNumber = false,
}) => {
  const displayName = person?.name || person?.company || "Unknown";
  const displayCompany = person?.name ? person?.company : "";

  return (
    <Grid container className={"person-summary-text"}>
      <Grid item xs={12} className={"name"}>
        {displayName}
      </Grid>
      {showCompany && (
        <Grid item xs={12} className={"company"}>
          {displayCompany}
        </Grid>
      )}
      {showEmail && person.email && (
        <Grid item xs={12} className={"email"}>
          <a href={`mailto:${person.email}`}>{person.email}</a>
        </Grid>
      )}
      {showLicenseNumber && person.licenseNumber && (
        <Grid item xs={12} className={"company"}>
          {"License: "}
          {person.licenseNumber}
        </Grid>
      )}
    </Grid>
  );
};

const PersonSummary = ({
  person,
  showEmail = true,
  showCompany = true,
  showMonogram = false,
  showLicenseNumber = false,
}) => {
  if (showMonogram) {
    return (
      <Grid container className={"person-summary"} spacing={2}>
        <Grid
          item
          container
          xs={"auto"}
          className={"monogram"}
          alignItems={"center"}
        >
          <PersonMonogram person={person} />
        </Grid>
        <Grid item container xs={true} alignItems={"center"}>
          <PersonSummaryText
            person={person}
            showEmail={showEmail}
            showCompany={showCompany}
            showLicenseNumber={showLicenseNumber}
          />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <PersonSummaryText
        person={person}
        showEmail={showEmail}
        showCompany={showCompany}
        showLicenseNumber={showLicenseNumber}
      />
    );
  }
};

export default PersonSummary;
