import ClearIcon from "@mui/icons-material/CancelRounded";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import ConditionalTooltip from "components/common/ConditionalTooltip";

/**
 *
 * @typedef {{label: string, selected: boolean?, id: string?, tip: string?}} Item
 *
 */

/**
 *
 * @param {Object} props
 * @param {Item[]} props.items
 * @param {"deletable" | "button" | "bright" | null} props.variant
 *
 */
const Options = ({
  items,
  onClick,
  onDoubleClick,
  variant,
  onToggleAll,
  levels,
}) => {
  variant = (variant || "").trim().toLowerCase();
  const deletable = variant === "deletable";
  const bright = variant === "bright";
  const buttonStyle = variant === "button";
  const baseClass = buttonStyle
    ? "option"
    : bright
    ? "option round bright"
    : "option round";
  const showToggle = typeof onToggleAll === "function";
  const allSelected = showToggle && items.every((x) => x.selected);
  const getClassName = (item) => {
    if (!levels) {
      return `${baseClass} ${
        item.selected ? "selected" : item?.faded ? "faded" : ""
      }`;
    } else if (item.level == null) {
      return `${baseClass} severity`;
    } else {
      return `${baseClass} ${levels[item.level].split("-").join(" ")}`;
    }
  };
  return (
    <>
      {showToggle && items.length > 1 && (
        <Link
          component={"button"}
          variant={"body2"}
          color={"secondary"}
          onClick={() => {
            onToggleAll(allSelected);
          }}
          sx={{ display: "block", marginBottom: "8px" }}
        >
          {allSelected ? "Select None" : "Select All"}
        </Link>
      )}
      <div className={"options"}>
        {items?.map((item, index) => (
          <ConditionalTooltip
            title={item.tip}
            conditional={!!item.tip}
            key={item.id ?? index}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              className={getClassName(item)}
              onClick={() => onClick(item)}
              onDoubleClick={onDoubleClick ? () => onDoubleClick(item) : null}
            >
              {item.label}
              {deletable && (
                <ClearIcon
                  fontSize={"smaller"}
                  sx={{ margin: "1px 0 0 6px" }}
                />
              )}
            </Stack>
          </ConditionalTooltip>
        ))}
      </div>
    </>
  );
};
export default Options;
