import NavigationConfigOverlay from "./userConfig/NavigationConfigOverlay";
import StaticDataEditor from "./userConfig/StaticDataEditor";
import TemplateEditor from "./userConfig/TemplateEditor";
import UserConfigOverlay from "./userConfig/UserConfigOverlay";
import AdminDashboard from "components/admin/AdminDashboard";
import DashboardContent from "components/layout/DashboardContent";
import TopNav from "components/layout/TopNav";
import { Navigate, Route, Routes } from "react-router-dom";

const TABS = [
  {
    title: "Components",
    path: "visibility",
    element: <UserConfigOverlay />,
  },
  {
    title: "Navigation",
    path: "navigation",
    element: <NavigationConfigOverlay />,
  },
  {
    title: "Configuration",
    path: "static-data",
    element: <StaticDataEditor />,
  },
  {
    title: "Templates",
    path: "templates",
    element: <TemplateEditor />,
  },
];

const UserConfig = ({ navigationState, setNavigationState }) => {
  return (
    <>
      <AdminDashboard>
        <TopNav
          tabs={TABS}
          parentPath={"settings"}
          navigationState={navigationState}
          setNavigationState={setNavigationState}
        />
        <DashboardContent isFullWidth={false}>
          <Routes>
            {TABS.map(({ element, path }) => (
              <Route key={path} path={`${path}/*`} element={element} />
            ))}
            <Route
              path={"*"}
              element={
                <Navigate
                  to={
                    navigationState?.["settings"] != null &&
                    TABS.some((tab) => tab.path === navigationState["settings"])
                      ? navigationState["settings"]
                      : TABS[0].path
                  }
                  replace={true}
                />
              }
            />
          </Routes>
        </DashboardContent>
      </AdminDashboard>
    </>
  );
};

export default UserConfig;
