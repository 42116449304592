import BooleanInput from "./BooleanInput";
import DateInput from "./DateInput";
import DateRangeInput from "./DateRangeInput";
import IndustryClassInput from "./IndustryClassInput";
import MultiSelectInput from "./MultiSelectInput";
import { GetApp as DownloadIcon } from "@mui/icons-material";
import {
  Alert,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
} from "@mui/material";
import Button from "components/common/Button";
import * as config from "config";
import { useState } from "react";
import * as reportService from "services/reportService";

const ReportOption = ({ defn, options, setOptions }) => {
  const Component = {
    BooleanOption: BooleanInput,
    DateOption: DateInput,
    DateRangeOption: DateRangeInput,
    IndustryClassOption: IndustryClassInput,
    MultiSelectOption: MultiSelectInput,
  }[defn?.type];

  return Component ? (
    <Component defn={defn} options={options} setOptions={setOptions} />
  ) : (
    <></>
  );
};

const ReportDownload = ({ reportDefinition }) => {
  const [options, setOptions] = useState({
    ...reportDefinition.defaultValues,
  });

  const [
    trigger,
    { isLoading, isError, error },
  ] = reportService.useDownloadReportMutation();

  const downloadReport = () => {
    trigger({
      type: reportDefinition.type,
      filename: reportDefinition.filename,
      format: reportDefinition.format,
      args: {
        ...options,
      },
    });
  };

  return (
    <Card sx={{ padding: 0, boxShadow: "none" }}>
      <CardHeader title={reportDefinition.name} />
      <CardContent>
        <Stack direction={"column"} spacing={config.GRID_SPACING}>
          {(reportDefinition.optionDefns ?? []).map((optionDefn, i) => (
            <ReportOption
              key={i}
              defn={optionDefn}
              options={options}
              setOptions={setOptions}
            />
          ))}
        </Stack>
      </CardContent>
      <CardActions sx={{ display: "flex", justifyContent: "right" }}>
        {isError && (
          <Alert
            severity={"error"}
            sx={{
              width: "100%",
              marginRight: 2,
            }}
          >
            {error ??
              `Error running report. Please contact ${config.SUPPORT_EMAIL}`}
          </Alert>
        )}
        <Button
          onClick={downloadReport}
          isLoading={isLoading}
          startIcon={<DownloadIcon />}
        >
          {"Download"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default ReportDownload;
