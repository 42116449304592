import * as searchActions from "../../store/actions/search/searchActions";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import React, { useRef } from "react";
import { connect } from "react-redux";

export const SearchTextField = (props) => {
  const open = (props.searchState?.results || []).length > 0;

  const value =
    props.searchState?.query != null
      ? props.searchState.query
      : props.active
      ? props.renderValue(props.active)
      : "";

  const textInputRef = useRef(null);

  return (
    <ClickAwayListener
      onClickAway={() => props.searchCancelled(props.meta.key)}
    >
      <div className={"search-text-field"}>
        <TextField
          type={"text"}
          InputLabelProps={{ shrink: true }}
          InputProps={props.textFieldInputProps}
          variant={"filled"}
          autoComplete={"off"}
          label={props.label}
          data-testid={props["data-testid"]}
          fullWidth
          onChange={(event) => {
            props.updateSearch(props.meta, event.target.value);
            if (props.onClear && event.target.value === "") {
              props.onClear(event);
            }
          }}
          error={props.error}
          value={value}
          inputRef={textInputRef}
        />
        <Popper
          open={open}
          role={undefined}
          transition
          placement={"bottom-start"}
          disablePortal
          className={"search-text-field-results"}
          style={{
            position: "absolute",
            zIndex: 99,
            top: 56,
            minWidth: "100%",
          }}
          anchorEl={textInputRef.current}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <MenuList className={"result-list"}>
                  {props.searchState.results.map((result, idx) => (
                    <MenuItem
                      onClick={(event) => {
                        props.selectSearchValue(props.meta.key, result);
                        if (props.onSelectWithDispatch) {
                          props.onSelectWithDispatch(result);
                        }
                        if (props.onSelect) {
                          props.onSelect(result);
                        }
                      }}
                      key={`${props.meta.key}_MENU_ITEM_${idx}`}
                    >
                      {(props.displayResult || props.displayResultText)(result)}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

const mapDispatchToProps = (onSelect, onClear) => ({
  updateSearch: searchActions.updateSearch,
  selectSearchValue: searchActions.selectSearchValue,
  onSelectWithDispatch: onSelect,
  searchCancelled: searchActions.searchCancelled,
  onClear,
});

export const makeSearch = (mapStateToProps, onSelect, onClear) =>
  connect(
    mapStateToProps,
    mapDispatchToProps(onSelect, onClear)
  )(SearchTextField);
