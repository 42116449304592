export const UPDATE_EXPOSURE_VALUE = "UPDATE_EXPOSURE_VALUE";
export const UPDATING_EXPOSURE_MEASURES_LIST = "UPDATING_EXPOSURE_VALUE";
export const UPDATED_EXPOSURE_MEASURES_LIST = "UPDATED_EXPOSURE_VALUE";
export const UPDATE_EXPOSURE_NOTE = "UPDATE_EXPOSURE_NOTE";
export const UPDATE_EXPOSURE_COMMENT = "UPDATE_EXPOSURE_COMMENT";
export const SHOW_ALL_EXPOSURE_COMMENTS = "SHOW_ALL_EXPOSURE_COMMENTS";
export const HIDE_ALL_EXPOSURE_COMMENTS = "HIDE_ALL_EXPOSURE_COMMENTS";
export const SHOW_EXPOSURE_COMMENT = "SHOW_EXPOSURE_COMMENT";
export const HIDE_EXPOSURE_COMMENT = "HIDE_EXPOSURE_COMMENT";
export const DELETE_EXTRA_EXPOSURE_METRIC = "DELETE_EXTRA_EXPOSURE_METRIC";
export const ADD_EXTRA_EXPOSURE_METRIC = "ADD_EXTRA_EXPOSURE_METRIC";
export const UPDATE_CUSTOM_MEASURE_NAME = "UPDATE_CUSTOM_MEASURE_NAME";
export const UPDATE_CUSTOM_MEASURE_UNIT = "UPDATE_CUSTOM_MEASURE_UNIT";
export const LOAD_ALL_EXPOSURE_METRICS_STARTED =
  "LOAD_ALL_EXPOSURE_METRICS_STARTED";
export const LOAD_ALL_EXPOSURE_METRICS_RETURNED =
  "LOAD_ALL_EXPOSURE_METRICS_RETURNED";
export const LOAD_ALL_EXPOSURE_METRICS_FAILED =
  "LOAD_ALL_EXPOSURE_METRICS_FAILED";
export const LOAD_ALL_INDUSTRY_CLASSES_STARTED =
  "LOAD_ALL_INDUSTRY_CLASSES_STARTED";
export const LOAD_ALL_INDUSTRY_CLASSES_RETURNED =
  "LOAD_ALL_INDUSTRY_CLASSES_RETURNED";
export const LOAD_ALL_INDUSTRY_CLASSES_FAILED =
  "LOAD_ALL_INDUSTRY_CLASSES_FAILED";
export const UPDATED_LOSS_CATEGORIES_LIST = "UPDATED_LOSS_CATEGORIES_LIST";
export const HIDE_TEMPLATE_EXPOSURE_METRIC = "HIDE_TEMPLATE_EXPOSURE_METRIC";
export const UNHIDE_TEMPLATE_EXPOSURE_METRIC =
  "UNHIDE_TEMPLATE_EXPOSURE_METRIC";
export const DELETE_CUSTOM_MEASURE = "DELETE_CUSTOM_MEASURE";
export const ADD_EXPOSURE_YEAR = "ADD_EXPOSURE_YEAR";
