import AdminDashboard from "./AdminDashboard";
import Merge from "./transfers/Merge";
import SelectTransfer from "./transfers/SelectTransfer";
import DashboardContent from "components/layout/DashboardContent";
import { Navigate, Route, Routes } from "react-router-dom";

const Transfers = () => {
  return (
    <AdminDashboard>
      <DashboardContent isFullWidth={false}>
        <Routes>
          <Route path={":transferId/*"} element={<Merge />} />
          <Route path={"/"} element={<SelectTransfer />} />
          <Route path={"*"} element={<Navigate to={"/"} replace={true} />} />
        </Routes>
      </DashboardContent>
    </AdminDashboard>
  );
};

export default Transfers;
