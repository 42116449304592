import * as logger from "./logger";
import { delay } from "redux-saga/effects";

export function* retry(effect, { maxAttempts = 3, initialDelay = 100 }) {
  let attempt = 1;
  let currentDelay = initialDelay;
  while (attempt <= maxAttempts) {
    try {
      return yield effect;
    } catch (e) {
      if (attempt === maxAttempts) {
        throw e;
      } else {
        logger.exception(e, {
          breadcrumb: {
            attempt,
            currentDelay,
            maxAttempts,
            initialDelay,
          },
        });
      }
      yield delay(currentDelay);
      currentDelay *= 2;
    }
    attempt += 1;
  }
}
