import * as actionTypes from "../../../actions/error/errorActionTypes";

export const INIT_STATE = {
  hidden: false,
  messages: [],
};

const errorsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case actionTypes.DISMISS_ALL_ERRORS:
      return {
        ...state,
        messages: [],
      };
    case actionTypes.HIDE_ERRORS:
      return {
        ...state,
        hidden: true,
      };
    case actionTypes.SHOW_ERRORS:
      return {
        ...state,
        hidden: false,
      };
    case actionTypes.DISMISS_ERROR:
      return {
        ...state,
        messages: [
          ...state.messages.slice(0, action.payload),
          ...state.messages.slice(action.payload + 1),
        ],
      };
    case actionTypes.NEW_ERROR:
      return {
        ...state,
        messages: [
          ...state.messages.filter(
            (message) =>
              action.payload.name == null ||
              message.name !== action.payload.name
          ),
          action.payload,
        ],
      };
    default:
      return state;
  }
};

export default errorsReducer;
