import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DefaultLoader from "components/common/DefaultLoader";
import Disabled from "components/common/Disabled";
import * as config from "config";
import React from "react";
import { connect } from "react-redux";
import { useConfigQuery } from "services/staticDataService";
import * as actions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

export const PolicyFormComponent = (props) => {
  const { data: policyForms, isLoading } = useConfigQuery("policy_forms");
  return (
    <Grid item xs={12}>
      <FormControl variant={"filled"} fullWidth>
        <Disabled ifReadOnly>
          <InputLabel className={"input-label"}>{"Policy Form"}</InputLabel>
          <Select
            id={"policy-form-selector"}
            value={isLoading ? "LOADING" : props.policyForm}
            onChange={(event) => props.setPolicyForm(event.target.value)}
            disabled={isLoading || props.disabled}
            fullWidth
          >
            {isLoading ? (
              <MenuItem key={"LOADING"} value={"LOADING"}>
                <DefaultLoader
                  color={"#dc7f4c"}
                  size={16}
                  style={{
                    display: "block",
                    textAlign: "center",
                  }}
                />
              </MenuItem>
            ) : (
              <MenuItem key={`Policy_Form_NULL`} value={"NO_SELECTION"} />
            )}
            {(policyForms?.policy_forms || config.POLICY_FORMS).map(
              (policyForm) => {
                return (
                  <MenuItem
                    key={`Policy_Form_${policyForm.code}`}
                    value={policyForm.code}
                  >
                    {policyForm.name}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </Disabled>
      </FormControl>
    </Grid>
  );
};

const mapDispatchToProps = {
  setPolicyForm: actions.setActiveLayerPolicyForm,
};

const mapStateToProps = (state) => {
  return {
    policyForm:
      pricingSelectors.getActiveLayer(state).policyForm || "NO_SELECTION",
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyFormComponent);
