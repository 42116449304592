import Comments from "./Comments";
import { CloseRounded as CloseIcon } from "@mui/icons-material";
import { Drawer, Stack, Typography } from "@mui/material";
import IconButton from "components/common/IconButton";

const CommentsDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer
      open={isOpen}
      variant={"persistent"}
      anchor={"right"}
      onClose={onClose}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ padding: "0.5rem 0.5rem 0.5rem 1rem" }}
      >
        <Typography variant={"h6"}>{"Comments"}</Typography>
        <IconButton icon={CloseIcon} className={"link"} onClick={onClose} />
      </Stack>
      <Comments isPollingEnabled={isOpen} />
    </Drawer>
  );
};

export default CommentsDrawer;
