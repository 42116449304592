import Button from "@mui/material/Button";
import ShortcutListener from "components/common/ShortcutListener";

const ShortcutButton = ({ disabled, shortcut, ...props }) => {
  return (
    <Button disabled={disabled} {...props}>
      <ShortcutListener
        disabled={disabled}
        shortcut={shortcut}
        action={props.onClick}
      />
      {props.children}
    </Button>
  );
};

export default ShortcutButton;
