import * as support from "./JsonDiffEditor.support";
import {
  SouthRounded as DownIcon,
  EastRounded as RightIcon,
  CheckCircleOutlineRounded as TickIcon,
  DeleteRounded as DeleteIcon,
} from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useState } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";

const INCOMING = "incoming";
const EXISTING = "existing";
const MERGED = "merged";

const DIFF_VIEWER_STYLES = {
  diffContainer: {
    fontSize: "0.75rem",
    fontFamily:
      "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
    pre: {
      lineHeight: "1rem",
    },
  },
};

const JsonDiffEditor = ({ incoming, existing, merged, setMerged }) => {
  const [diffBase, setDiffBase] = useState(INCOMING);

  const [selectedObjectLines, setSelectedObjectLines] = useState(null);
  const [highlightLines, setHighlightLines] = useState([]);

  const deleteObject = ({ key, start, end }) => {
    if (key !== MERGED) {
      return;
    }
    const prefix = merged.split("\n").slice(0, start).join("\n");
    const suffix = merged
      .split("\n")
      .slice(end + 1)
      .join("\n");
    setMerged(support.combineJson({ prefix, suffix }));
    clearHighlight();
  };

  const insertObject = ({ start, end, line, above }) => {
    const prefix = merged
      .split("\n")
      .slice(0, line - (above ? 1 : 0))
      .join("\n");
    const suffix = merged
      .split("\n")
      .slice(line - (above ? 1 : 0))
      .join("\n");
    const insert = getText(diffBase)
      .split("\n")
      .slice(start, end + 1)
      .join("\n");
    setMerged(support.insertJson({ prefix, suffix, insert }));
    clearHighlight();
  };

  const clearHighlight = () => {
    setHighlightLines([]);
    setSelectedObjectLines(null);
  };

  const getText = (key) => {
    if (key === INCOMING) {
      return incoming;
    }
    if (key === EXISTING) {
      return existing;
    }
    if (key === MERGED) {
      return merged;
    }
    return undefined;
  };

  const highlightObject = (line, side) => {
    if (line == null) {
      return line;
    }
    const key = side === "L" ? diffBase : MERGED;
    const objectLines = support.findObjectLinesInText(line - 1, getText(key));
    if (objectLines == null) {
      setSelectedObjectLines(null);
      return;
    }
    const [start, end] = objectLines;
    setSelectedObjectLines({
      key,
      start,
      end,
    });
    const highlightLines = [...Array(end - start + 1).keys()].map(
      (i) => `${side}-${start + i + 1}`
    );
    setHighlightLines(highlightLines);
  };

  const renderGutter = (data) => {
    const iconSize = "1rem";

    if (data.type == null) {
      return <td></td>;
    }
    let icon = <></>;
    let onClick = () => {
      clearHighlight();
    };

    if (selectedObjectLines == null) {
      if (data.prefix === "R") {
        icon = <DeleteIcon sx={{ fontSize: iconSize }} />;
      } else if (data.prefix === "L") {
        icon = <RightIcon sx={{ fontSize: iconSize }} />;
      }
      onClick = () => highlightObject(data.lineNumber, data.prefix);
    } else if (data.prefix === "R") {
      if (
        selectedObjectLines.key === MERGED &&
        selectedObjectLines.end === data.lineNumber - 1
      ) {
        icon = <TickIcon sx={{ fontSize: iconSize }} />;
        onClick = () => {
          deleteObject(selectedObjectLines);
        };
      } else if (selectedObjectLines.key !== MERGED) {
        icon = <DownIcon sx={{ fontSize: iconSize }} />;
        onClick = () => {
          insertObject({
            start: selectedObjectLines.start,
            end: selectedObjectLines.end,
            line: data.lineNumber,
            above: false,
          });
        };
      }
    }

    return (
      <td>
        <IconButton
          size={"small"}
          sx={{ minWidth: "1.625rem", minHeight: "1.625rem" }}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      </td>
    );
  };

  return (
    <Stack
      direction={"column"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      spacing={2}
    >
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        sx={{ width: "100%" }}
      >
        <FormControl>
          <FormLabel id={"diff-label"}>{"Diff relative to"}</FormLabel>
          <RadioGroup
            row
            value={diffBase}
            onChange={(e) => setDiffBase(e.target.value)}
          >
            <FormControlLabel
              value={INCOMING}
              control={<Radio />}
              label={"Incoming"}
            />
            <FormControlLabel
              value={EXISTING}
              control={<Radio />}
              label={"Existing"}
            />
          </RadioGroup>
        </FormControl>
      </Stack>
      <ReactDiffViewer
        oldValue={getText(diffBase)}
        newValue={merged}
        splitView={true}
        showDiffOnly={false}
        leftTitle={diffBase === INCOMING ? "Incoming" : "Existing"}
        rightTitle={"Merged"}
        compareMethod={"diffJson"}
        styles={DIFF_VIEWER_STYLES}
        renderGutter={renderGutter}
        highlightLines={highlightLines}
      />
    </Stack>
  );
};

export default JsonDiffEditor;
