import Visible from "./Visible";
import { WifiOffRounded as OfflineIcon } from "@mui/icons-material";
import { Alert } from "@mui/material";

const OfflineBanner = () => {
  const offline = !window.navigator.onLine;

  return offline ? (
    <Visible byTag={"offlineBanner"} defaultVisible={true}>
      <Alert
        className={"delay-reveal"}
        icon={<OfflineIcon />}
        severity={"error"}
        sx={{
          position: "fixed",
          top: "1rem",
          left: "calc(50% - 9rem)",
          width: "18rem",
          justifyContent: "center",
          zIndex: 99,
        }}
      >
        {"Your device appears to be offline"}
      </Alert>
    </Visible>
  ) : (
    <></>
  );
};

export default OfflineBanner;
