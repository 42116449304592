import {
  Close as CloseIcon,
  DriveFolderUpload as UploadIcon,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import * as logger from "common/logger";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import { createFileEntry } from "fileUtils";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const useSnackbarError = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const postSnackbarError = useCallback(
    (message) => {
      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 10_000,
        action: (msgId) => (
          <IconButton size={"small"} onClick={() => closeSnackbar(msgId)}>
            <CloseIcon />
          </IconButton>
        ),
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  return postSnackbarError;
};

const SelectFile = ({ transformStatus, applyTransform }) => {
  const [loading, setLoading] = useState(false);

  const postSnackbarError = useSnackbarError();

  const onDrop = async (files) => {
    setLoading(true);
    const file = files[0];
    try {
      const fileId = await createFileEntry(file);
      await applyTransform({ fileId });
    } catch (e) {
      logger.error(e);
      postSnackbarError(`File "${file.name}" cannot be uploaded.`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (transformStatus.status === "failed") {
      postSnackbarError(`Failed to read file.`);
    }
  }, [transformStatus, postSnackbarError]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  return loading || transformStatus?.status === "running" ? (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%" }}
    >
      <BigLoader />
    </Box>
  ) : (
    <Box
      {...getRootProps({
        className: `dropzone ${isDragActive ? "active" : ""} ${
          isDragAccept ? "accept" : ""
        } ${isDragReject ? "reject" : ""}`,
      })}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ marginBottom: 1 }}
    >
      <input {...getInputProps()} />
      <UploadIcon sx={{ fontSize: 50 }} />
      <Typography className={"message"}>
        {"Drop an .xlsx, .csv, or .tsv file here to upload"}
      </Typography>
      <Divider style={{ width: "100%", marginTop: 30, marginBottom: 46 }}>
        {"OR"}
      </Divider>
      <Disabled ifReadOnly>
        <Button variant={"contained"} disableElevation color={"primary"}>
          {"BROWSE FILES"}
        </Button>
      </Disabled>
    </Box>
  );
};

export default SelectFile;
