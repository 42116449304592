import MemoizedTowerTableRow from "./MemoizedTowerTableRow";
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  MessageOutlined as MessageOutlinedIcon,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import * as chartUtils from "chartUtils";
import AutoCompleteTextField from "components/common/AutoCompleteTextField";
import ConditionalTooltip from "components/common/ConditionalTooltip";
import FeatureFlagged from "components/common/FeatureFlagged";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import * as config from "config";
import { DYNAMIC_AUTO_COMPLETE } from "flags";
import NumberFormat from "react-number-format";
import { connect, useSelector } from "react-redux";
import * as staticDataHooks from "store/hooks/staticDataHooks";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";

const findMatchingPriorLayer = (currentLayer, priorLayers) => {
  if (!currentLayer || !priorLayers) return null;

  return priorLayers.find(
    (pl) =>
      pl.attachment === currentLayer.attachment &&
      pl.limit === currentLayer.limit
  );
};

const tickCross = (tertile) => {
  switch (tertile) {
    case 2:
      return (
        <CheckCircleIcon
          className={"tertile-icon"}
          style={{ color: "#70de70" }}
        />
      );
    case 1:
      return (
        <ErrorIcon className={"tertile-icon"} style={{ color: "#7C809D" }} />
      );
    case 0:
      return (
        <CancelIcon className={"tertile-icon"} style={{ color: "#e8694c" }} />
      );
    default:
      return <></>;
  }
};

const dealQuality = (good) => {
  if (good == null) {
    return "";
  }
  if (good < 0) {
    return "table-cell-bad-deal";
  }
  if (good > 0) {
    return "table-cell-good-deal";
  }
  return "";
};

const shareCarrierVal = (share) => {
  const carrier = share?.carrier;
  if (carrier && carrier.trim()) return carrier;
  return "[Unknown]";
};

const NumberCell = ({ value, cellStyle, good, ...numberFormatProps }) => {
  if (value == null || isNaN(value)) {
    return <TableCell align={"right"} />;
  } else {
    return (
      <TableCell align={"right"} style={cellStyle}>
        <NumberFormat
          displayType={"text"}
          thousandSeparator={true}
          defaultValue={""}
          decimalScale={0}
          className={`monospace ${dealQuality(good)}`}
          {...numberFormatProps}
          value={value}
        />
      </TableCell>
    );
  }
};

const COLUMNS = [
  {
    key: "addLayer",
    title: "",
    width: 0,
    align: "right",
    description: "",
    visibilityTag: "pricing.towerPricing.addLayer",
    Cell: ({ layerIndex, isNewRow, dispatch }) => (
      <TableCell align={"left"}>
        {isNewRow ? (
          <></>
        ) : (
          <IconButton
            icon={AddIcon}
            onClick={() => {
              dispatch?.setIsExpanded?.(false);
              dispatch?.addLayer?.(layerIndex);
            }}
          />
        )}
      </TableCell>
    ),
  },
  {
    key: "deleteLayer",
    title: "",
    width: 0,
    align: "right",
    description: "",
    visibilityTag: "pricing.towerPricing.deleteLayer",
    Cell: ({ layerIndex, isNewRow, dispatch }) => (
      <TableCell align={"left"}>
        {isNewRow ? (
          <></>
        ) : (
          <IconButton
            icon={DeleteIcon}
            onClick={() => {
              dispatch?.setIsExpanded?.(false);
              dispatch?.deleteLayer?.(layerIndex);
            }}
          />
        )}
      </TableCell>
    ),
  },
  {
    key: "numLayer",
    title: "",
    width: 0,
    align: "left",
    description: "",
    visibilityTag: "pricing.towerPricing.index",
    defaultVisibility: true,
    Cell: ({ layerIndex }) => <NumberCell value={layerIndex + 1} />,
  },
  {
    key: "carrier",
    title: "Carrier",
    width: 200,
    align: "left",
    description: "The name of the insurer.",
    visibilityTag: "pricing.towerPricing.carrier",
    Cell: ({ layer, layerIndex, dispatch }) => {
      const carrierChanged = (value) => {
        const carrier = value || "";
        if (carrier.includes("/")) {
          dispatch?.setIsExpanded?.(true);
          carrier.split("/").forEach((c, i) => {
            dispatch?.updateShare?.({
              layerIndex,
              shareIndex: i,
              values: {
                carrier: c,
                share: null,
              },
            });
          });
        } else {
          dispatch?.updateShare?.({
            layerIndex,
            shareIndex: 0,
            values: {
              carrier: value,
              share: 100,
            },
          });
        }
      };
      return (
        <TableCell align={"left"}>
          <ConditionalTooltip
            conditional={(layer?.shares ?? []).length > 1}
            title={"Click to see the shares."}
          >
            {(layer?.shares ?? []).length > 1 ? (
              <TextField
                value={layer?.shares.map(shareCarrierVal).join(" / ")}
                fullWidth
                onClick={() => dispatch?.setIsExpanded?.(true)}
                variant={"filled"}
                autoComplete={"off"}
                size={"small"}
                hiddenLabel
                disabled
              />
            ) : (
              <FeatureFlagged
                on={AutoCompleteTextField}
                off={TextField}
                flag={DYNAMIC_AUTO_COMPLETE}
                category={"carriers"}
                value={layer?.shares?.[0]?.carrier || ""}
                fullWidth
                onChange={(e) => carrierChanged(e.target.value)}
                onChangeValue={carrierChanged}
                variant={"filled"}
                autoComplete={"off"}
                size={"small"}
                hiddenLabel
              />
            )}
          </ConditionalTooltip>
        </TableCell>
      );
    },
  },
  {
    key: "expandShare",
    title: "",
    width: 0,
    align: "left",
    description: "Expand the layer to see the shares.",
    visibilityTag: "pricing.towerPricing.expandShare",
    Cell: ({ layer, isNewRow, isExpanded, dispatch }) => (
      <TableCell align={"left"}>
        {layer?.shares?.some((share) => share.comments?.length > 0) && (
          <MessageOutlinedIcon />
        )}
        {isNewRow ? (
          <></>
        ) : isExpanded ? (
          <IconButton
            icon={ExpandLessIcon}
            onClick={() => dispatch?.setIsExpanded?.(false)}
          />
        ) : (
          <IconButton
            icon={ExpandMoreIcon}
            onClick={() => dispatch?.setIsExpanded?.(true)}
          />
        )}
      </TableCell>
    ),
  },
  {
    key: "tertile",
    title: "",
    width: 0,
    align: "right",
    description: "",
    visibilityTag: "pricing.towerPricing.tertile",
    Cell: ({ layer }) => (
      <TableCell align={"right"} className={"cell-condensed-padding"}>
        {tickCross(layer?.towerPricing?.tertile)}
      </TableCell>
    ),
  },
  {
    key: "limit",
    title: "Limit",
    width: 150,
    align: "right",
    description: "The layer limit.",
    visibilityTag: "pricing.towerPricing.limit",
    Cell: ({ layer, layerIndex, dispatch }) => (
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer?.limit || ""}
          fullWidth
          onChangeNumber={(limit) => dispatch?.updateLimit?.(layerIndex, limit)}
          name={"limit"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
          data-testid={`tower-limit-layer-${layerIndex}`}
        />
      </TableCell>
    ),
  },
  {
    key: "attachment",
    title: "Attachment",
    width: 150,
    align: "right",
    description: "The layer attachment.",
    visibilityTag: "pricing.towerPricing.attachment",
    Cell: ({ layer, layerIndex, dispatch }) => (
      <TableCell align={"right"}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer?.attachment || ""}
          fullWidth
          onChangeNumber={(attachment) =>
            dispatch?.updateAttachment?.(layerIndex, attachment)
          }
          name={"attachment"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
          data-testid={`tower-attachment-layer-${layerIndex}`}
        />
      </TableCell>
    ),
  },
  {
    key: "grossPremium",
    title: "Market GWP",
    width: 150,
    align: "right",
    description: "The 100% bound premium.",
    visibilityTag: "pricing.towerPricing.grossPremium",
    Cell: ({ layer, layerIndex, dispatch }) => (
      <TableCell align={"right"} style={{ minWidth: "150px" }}>
        <PrettyNumberTextField
          inputProps={{ style: { textAlign: "right" } }}
          value={layer?.grossPremium || ""}
          fullWidth
          onChangeNumber={(grossPremium) =>
            dispatch?.updateMarketGrossWrittenPremium?.(
              layerIndex,
              grossPremium
            )
          }
          name={"market-gwp"}
          variant={"filled"}
          size={"small"}
          hiddenLabel
          data-testid={`tower-gwp-layer-${layerIndex}`}
        />
      </TableCell>
    ),
  },
  {
    key: "premiumChange",
    title: "Δ Premium",
    width: 150,
    align: "right",
    description: "The year-over-year change in premium (current - prior).",
    visibilityTag: "pricing.towerPricing.premiumChange",
    defaultVisibility: false,
    hideUnlessRenewal: true,
    Cell: ({ layer, priorLayers }) => {
      if (!layer?.grossPremium)
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const currentPremium = parseFloat(layer.grossPremium);
      if (isNaN(currentPremium))
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const priorLayer = findMatchingPriorLayer(layer, priorLayers);
      if (!priorLayer?.grossPremium)
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const priorPremium = parseFloat(priorLayer.grossPremium);
      if (isNaN(priorPremium))
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const change = currentPremium - priorPremium;
      const percentChange = ((change / priorPremium) * 100).toFixed(2);
      const tooltipText = `${percentChange}% change`;

      return (
        <Tooltip title={tooltipText}>
          <TableCell align={"right"}>
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              defaultValue={""}
              decimalScale={0}
              prefix={change > 0 ? "+" : ""}
              className={`monospace ${dealQuality(
                change > 0 ? 1 : change < 0 ? -1 : 0
              )}`}
              value={change}
            />
          </TableCell>
        </Tooltip>
      );
    },
  },
  {
    key: "premiumChangePercent",
    title: "Δ Premium %",
    width: 150,
    align: "right",
    description:
      "The year-over-year percentage change in premium ((current - prior) / prior * 100).",
    visibilityTag: "pricing.towerPricing.premiumChangePercent",
    defaultVisibility: false,
    hideUnlessRenewal: true,
    Cell: ({ layer, priorLayers }) => {
      if (!layer?.grossPremium)
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const currentPremium = parseFloat(layer.grossPremium);
      if (isNaN(currentPremium))
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const priorLayer = findMatchingPriorLayer(layer, priorLayers);
      if (!priorLayer?.grossPremium)
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const priorPremium = parseFloat(priorLayer.grossPremium);
      if (isNaN(priorPremium))
        return <TableCell align={"right"}>{"-"}</TableCell>;

      const change = currentPremium - priorPremium;
      const percentChange = (change / priorPremium) * 100;
      const tooltipText = `${
        change > 0 ? "+" : ""
      }${change.toLocaleString()} change`;

      return (
        <Tooltip title={tooltipText}>
          <TableCell align={"right"}>
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              defaultValue={""}
              decimalScale={2}
              suffix={"%"}
              prefix={percentChange > 0 ? "+" : ""}
              className={`monospace ${dealQuality(
                percentChange > 0 ? 1 : percentChange < 0 ? -1 : 0
              )}`}
              value={percentChange}
            />
          </TableCell>
        </Tooltip>
      );
    },
  },
  {
    key: "brokerage",
    title: "% Brokerage",
    width: 80,
    align: "right",
    description: "The effective brokerage for the layer.",
    visibilityTag: "pricing.towerPricing.brokerage",
    hideGross: true,
    Cell: ({ layer }) => (
      <NumberCell value={layer?.brokerage} decimalScale={1} />
    ),
  },
  {
    key: "netPremium",
    title: "Market NWP",
    width: 100,
    align: "right",
    description: "The net 100% bound premium.",
    visibilityTag: "pricing.towerPricing.netPremium",
    hideGross: true,
    Cell: ({ layer }) =>
      layer?.errors?.netPremium ? (
        <TableCell sx={{ width: 150 }} align={"right"}>
          <Tooltip title={layer.errors.netPremium}>
            <ErrorIcon
              color={"error"}
              sx={{ fontSize: "1rem", marginLeft: 0.5 }}
            />
          </Tooltip>
        </TableCell>
      ) : (
        <NumberCell value={layer?.netPremium} />
      ),
  },
  {
    key: "grossRPM",
    title: "Market RPM",
    width: 80,
    align: "right",
    description: "100% bound premium / limit.",
    visibilityTag: "pricing.towerPricing.grossRPM",
    hideNet: true,
    Cell: ({ layer }) => <NumberCell value={layer?.grossRPM} />,
  },
  {
    key: "netRPM",
    title: "Market Net RPM",
    width: 80,
    align: "right",
    description: "100% bound premium / limit.",
    visibilityTag: "pricing.towerPricing.netRPM",
    hideGross: true,
    Cell: ({ layer }) => <NumberCell value={layer?.netRPM} />,
  },
  {
    key: "grossAsIfRPM",
    title: "As if RPM",
    width: 80,
    align: "right",
    description:
      "The scaled RPM for a layer using the UW Selected Required 100% Premium from the As If layer on the Layer Pricing tab.",
    visibilityTag: "pricing.towerPricing.grossAsIfRPM",
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell
        value={layer?.towerPricing?.ratePerMillionScaledFromUwPricedLayer}
      />
    ),
  },
  {
    key: "grossAsIfTP",
    title: "As if TP",
    width: 80,
    align: "right",
    description: `The Target Price assuming the UW Selected price is correct for chosen layer. ${config.DEFINITION_TP}`,
    visibilityTag: "pricing.towerPricing.grossAsIfTP",
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell
        suffix={"%"}
        decimalScale={2}
        fixedDecimalScale
        value={layer?.targetPrice}
        good={layer?.targetPrice - 100}
      />
    ),
  },
  {
    key: "selectLayer",
    title: "",
    width: 0,
    align: "right",
    description: "",
    visibilityTag: "pricing.towerPricing.selectLayer",
    Cell: ({ layerIndex, isNewRow, isSelectedRow, dispatch }) => (
      <TableCell align={"right"} className={"cell-condensed-padding"}>
        {isNewRow ? (
          <></>
        ) : (
          <Checkbox
            checked={isSelectedRow}
            onChange={(e) => {
              if (e.target.checked) {
                dispatch?.updateSelected?.(layerIndex);
              }
            }}
          />
        )}
      </TableCell>
    ),
  },
  {
    key: "grossSelectedRPM",
    title: "Selected RPM",
    width: 80,
    align: "right",
    description:
      "The scaled RPM for a layer assuming that the layer with the checkbox selected is priced correctly.",
    visibilityTag: "pricing.towerPricing.grossSelectedRPM",
    cellStyle: { background: config.SELECTED_ROW_BACKGROUND_COLOR },
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell
        value={layer?.towerPricing?.ratePerMillionRelativeToSelectedLayer ?? ""}
        cellStyle={{ background: "#dbdce5" }}
      />
    ),
  },
  {
    key: "netSelectedRPM",
    title: "Selected Net RPM",
    width: 80,
    align: "right",
    description:
      "The scaled net RPM for a layer assuming that the layer with the checkbox selected is priced correctly.",
    visibilityTag: "pricing.towerPricing.netSelectedRPM",
    cellStyle: { background: config.SELECTED_ROW_BACKGROUND_COLOR },
    hideGross: true,
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell value={""} cellStyle={{ background: "#dbdce5" }} />
    ),
  },
  {
    key: "grossSelectedMargin",
    title: "Selected Margin",
    width: 80,
    align: "right",
    description:
      "The proportion of the Market GWP that would be excess profit compared to the implied price of the selected layer produced by scaling via the ILF. (1 - Selected RPM / Market RPM) x 100%",
    visibilityTag: "pricing.towerPricing.grossSelectedMargin",
    cellStyle: { background: config.SELECTED_ROW_BACKGROUND_COLOR },
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell
        suffix={"%"}
        decimalScale={2}
        fixedDecimalScale
        value={layer?.towerPricing?.marginOnSelectedLayerPrice * 100}
        cellStyle={{ background: "#dbdce5" }}
      />
    ),
  },
  {
    key: "grossModelledMargin",
    title: "Modelled Margin",
    width: 80,
    align: "right",
    makeDescription: ({ baseLayerLosses, baseLayerAttachment }) =>
      `The proportion of the Market GWP that would be excess profit compared to the 'Pricing Modelled' price for a company with ${baseLayerLosses} losses in excess of $${chartUtils.tickFormatter(
        baseLayerAttachment
      )}. This is the price without reference to claims data. (1 - Pricing Modelled RPM / Market RPM) x 100%`,
    visibilityTag: "pricing.towerPricing.grossModelledMargin",
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell
        suffix={"%"}
        decimalScale={2}
        fixedDecimalScale
        value={layer?.marginOnRequiredModelled * 100}
      />
    ),
  },
  {
    key: "grossRelativeRate",
    title: "Rate Relative to Layer Below",
    width: 80,
    align: "right",
    description: "The ratio of the RPM of this layer with the layer below.",
    visibilityTag: "pricing.towerPricing.grossRelativeRate",
    hideNet: true,
    Cell: ({ layer }) => (
      <NumberCell
        suffix={"%"}
        decimalScale={2}
        fixedDecimalScale
        value={(layer?.grossRPMRelativeToLayerBelow ?? NaN) * 100}
      />
    ),
  },
  {
    key: "netRelativeRate",
    title: "Net Rate Relative to Layer Below",
    width: 80,
    align: "right",
    description: "The ratio of the net RPM of this layer with the layer below.",
    visibilityTag: "pricing.towerPricing.netRelativeRate",
    hideGross: true,
    Cell: ({ layer }) => (
      <NumberCell
        suffix={"%"}
        decimalScale={2}
        fixedDecimalScale
        value={(layer?.netRPMRelativeToLayerBelow ?? NaN) * 100}
      />
    ),
  },
  {
    key: "inception",
    title: "Inception",
    width: 80,
    description: "Inception date of the layer.",
    visibilityTag: "pricing.towerPricing.inception",
    defaultVisibility: false,
    Cell: ({ layer, layerIndex, dispatch, cellStyle }) => (
      <TableCell align={"right"} style={cellStyle}>
        <FormattedDatePicker
          compact
          sx={{
            minWidth: 160,
          }}
          onChangeDate={(inception) =>
            dispatch?.updateLayer?.(layerIndex, { inception })
          }
          value={layer?.inception ?? ""}
          fullWidth
          clearable={false}
        />
      </TableCell>
    ),
  },
  {
    key: "expiration",
    title: "Expiration",
    width: 80,
    description: "Expiration date of the layer.",
    visibilityTag: "pricing.towerPricing.expiration",
    defaultVisibility: false,
    Cell: ({ layer, layerIndex, dispatch, cellStyle }) => (
      <TableCell align={"right"} style={cellStyle}>
        <FormattedDatePicker
          compact
          sx={{
            minWidth: 160,
          }}
          onChangeDate={(expiration) =>
            dispatch?.updateLayer?.(layerIndex, { expiration })
          }
          value={layer?.expiration}
          clearable={false}
        />
      </TableCell>
    ),
  },
];

export const TowerTable = ({
  isNet,
  layers,
  selectedIndex,
  baseLayerLosses,
  baseLayerAttachment,
  componentVisibility,
  priorLayers,
}) => {
  const translateLabel = staticDataHooks.useTranslator("uiLabels");

  const isRenewal = !!useSelector(programSelectors.getPriorSubmissionId);

  const visibleColumns = COLUMNS.filter(
    (c) =>
      (isNet ? !c.hideNet : !c.hideGross) &&
      (!c.hideUnlessRenewal || isRenewal) &&
      (c.visibilityTag === undefined ||
        (componentVisibility?.[c.visibilityTag] ?? c.defaultVisibility ?? true))
  );

  return (
    <TableContainer>
      <Table size={"small"} className={"condensed-table"}>
        <TableHead>
          <TableRow>
            {visibleColumns.map(
              (
                {
                  key,
                  title,
                  description,
                  makeDescription,
                  align,
                  width,
                  cellStyle,
                },
                i
              ) => {
                if (makeDescription) {
                  description = makeDescription({
                    baseLayerLosses,
                    baseLayerAttachment,
                  });
                }
                return (
                  <ConditionalTooltip
                    key={key}
                    conditional={description}
                    title={description}
                  >
                    <TableCell
                      align={align}
                      valign={"bottom"}
                      style={{
                        minWidth: width,
                        verticalAlign: "bottom",
                        ...cellStyle,
                      }}
                      key={i}
                    >
                      {translateLabel(`TOWER_TABLE.${key}`, { default: title })}
                    </TableCell>
                  </ConditionalTooltip>
                );
              }
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...(layers ?? []), {}].map((layer, layerIndex) => (
            <MemoizedTowerTableRow
              columns={visibleColumns}
              key={layerIndex}
              layer={layer}
              layerIndex={layerIndex}
              isSelectedRow={selectedIndex === layerIndex}
              isNewRow={layerIndex === layers?.length}
              priorLayers={priorLayers}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const mapStateToProps = (state) => {
  const priorState = stateSelectors.selectPriorState(state);
  return {
    layers: towerPricingSelectors.getTower(state),
    selectedIndex: state?.pricing?.tower?.selectedLayer ?? null,
    baseLayerLosses:
      pricingSelectors.getNumberOfLossesForPricingPretty(state) ?? "unknown",
    baseLayerAttachment: state?.pricing?.baseLayer?.attachment ?? null,
    componentVisibility:
      userSelectors.selectUserConfig(state).componentVisibility ?? {},
    priorLayers: towerPricingSelectors.getTower(priorState),
  };
};

export default connect(mapStateToProps)(TowerTable);
