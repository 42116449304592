import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as exposureActions from "store/actions/input/exposure/exposureActions";

const IndustryClassSelector = ({
  value,
  onChange,
  industryClassChoices,
  loadAllIndustryClasses,
  label = "Industry Class Filter",
  sx = {},
}) => {
  useEffect(() => {
    if ((industryClassChoices ?? []).length === 0) {
      loadAllIndustryClasses();
    }
  }, [loadAllIndustryClasses, industryClassChoices]);

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_, value) => onChange(value)}
      options={industryClassChoices ?? []}
      getOptionLabel={(option) => option.name}
      sx={sx}
      renderInput={(params) => (
        <TextField
          {...params}
          type={"text"}
          variant={"filled"}
          label={label}
          InputLabelProps={{ shrink: true }}
          fullWidth
        />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    industryClassChoices:
      state.temp?.exposureMetrics?.industryClassChoices ?? [],
  };
};

const mapDispatchToProps = {
  loadAllIndustryClasses: exposureActions.loadAllIndustryClasses,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndustryClassSelector);
