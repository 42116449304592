import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from "@mui/material";
import * as errors from "common/errors";
import Button from "components/common/Button";
import * as config from "config";
import { connect } from "react-redux";
import * as maintenanceStatusSelectors from "store/selectors/temp/maintenanceStatus/maintenanceStatusSelectors";

const CustomMessage = ({ text }) => {
  const paragraphs = String(text).split("\n");
  return (
    <>
      {paragraphs.map((paragraph, i) => (
        <p key={i}>{paragraph}</p>
      ))}
    </>
  );
};

const MaintenanceDialog = ({ maintenanceStatus, changeCompleted, state }) => {
  const title =
    maintenanceStatus.dialogTitle ??
    (changeCompleted
      ? "System update completed"
      : "System maintenance in progress");

  const message =
    maintenanceStatus?.dialogMessage ??
    (changeCompleted
      ? "The system maintenance is now complete.\nThe application has to be restarted to pick up the changes."
      : "Due to necessary maintenance the system is unavailable at the moment.");

  return (
    <Dialog open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <CustomMessage text={message} />
        <p>
          {
            "If you have any unsaved work in the system you will not be able to save it, however you can download it to a file and email it to us and we will save it on your behalf."
          }
        </p>
        <p>
          {
            "Please click the 'Download State' button below and email the file it creates to "
          }
          <Link href={`mailto:${config.SUPPORT_EMAIL}`}>
            {config.SUPPORT_EMAIL}
          </Link>
          {", and we will be able to ensure you do not lose any unsaved work."}
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => errors.downloadState(state)} color={"secondary"}>
          {"Download State"}
        </Button>
        {changeCompleted && (
          <Button onClick={() => window.location.reload()}>
            {"Restart Application"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  maintenanceStatus:
    maintenanceStatusSelectors.selectMaintenanceStatus(state) ?? {},
  changeCompleted: maintenanceStatusSelectors.selectIsChangeComplete(state),
  state,
});

export default connect(mapStateToProps)(MaintenanceDialog);
