import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import Notes from "components/common/Notes";
import Visible from "components/common/Visible";
import FilteredClaims from "components/inputs/claims/FilteredClaims";
import BaseLayer from "components/pricing/layerPricing/BaseLayer";
import PriorPricing from "components/pricing/layerPricing/PriorPricing";
import ImprovexAverage from "components/pricing/lossFrequency/ImprovexAverage";
import InsuredAverages from "components/pricing/lossFrequency/InsuredAverages";
import LossFrequencyChart from "components/pricing/lossFrequency/LossFrequencyChart";
import UpdateLossFrequency from "components/pricing/lossFrequency/UpdateLossFrequency";
import * as config from "config";
import * as claims from "domain/claims";
import { connect, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as pricingActions from "store/actions/pricing/pricingActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

export const LossFrequencyComponent = (props) => {
  const claimsQuery = useDebouncedValue(
    useSelector(pricingSelectors.selectPricingClaims)
  );
  const claimsAbove = useSelector(claimsSelectors.selectClaimsThreshold);
  const { currentData: allClaims } = claimsService.useAllClaimsChartQuery({
    claimsQuery,
    claimsAbove,
  });

  const isClaimsQueryEmpty = claims.isClaimsQueryEmpty(claimsQuery);

  return (
    <>
      <Grid item xs={12}>
        {!isClaimsQueryEmpty && (
          <Disabled ifReadOnly>
            <FilteredClaims data={allClaims} zoomIdentifier={"lossfrequency"} />
          </Disabled>
        )}
      </Grid>
      <Grid item xs={12} md={5}>
        <Grid container direction={"column"} spacing={3}>
          <PriorPricing />
          <BaseLayer />
          <InsuredAverages />
          <ImprovexAverage />
          <Component
            title={"Loss Frequency Blend"}
            subtitle={
              "Weight insured claims data and Model to estimate annual loss frequency."
            }
          >
            <Grid container spacing={config.GRID_SPACING}>
              <Grid item xs={12} lg={5}>
                <TextField
                  type={"text"}
                  InputLabelProps={{ shrink: true }}
                  label={"Insured Weight"}
                  id={"insured-weight"}
                  autoComplete={"off"}
                  variant={"filled"}
                  onChange={(e) => props.updateInsuredWeight(e.target.value)}
                  value={props.insuredWeight}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={5}>
                <TextField
                  type={"text"}
                  InputLabelProps={{ shrink: true }}
                  label={"Model Weight"}
                  id={"improvex-weight"}
                  autoComplete={"off"}
                  variant={"filled"}
                  onChange={(e) => props.updateImprovexWeight(e.target.value)}
                  value={props.improvexWeight}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} lg={2} className={"centered"}>
                <Typography className={"monospace"}>
                  {utils.sfString(props.blendedExpected)}
                </Typography>
              </Grid>
            </Grid>
          </Component>
        </Grid>
      </Grid>
      <Grid item xs={12} md={7}>
        <Grid container spacing={config.GRID_SPACING} direction={"column"}>
          <Visible
            byTag={"pricing.lossFrequency.notesAtTop"}
            defaultVisible={true}
          >
            <Notes update={props.updateNote} value={props.note} rows={10} />
          </Visible>
          <LossFrequencyChart />
          <UpdateLossFrequency />
          <Visible
            byTag={"pricing.lossFrequency.notesAtTop"}
            defaultVisible={false}
          >
            <Notes update={props.updateNote} value={props.note} rows={10} />
          </Visible>
        </Grid>
      </Grid>
    </>
  );
};

const mapDispatchToProps = {
  updateInsuredWeight: pricingActions.updateInsuredWeight,
  updateImprovexWeight: pricingActions.updateImprovexWeight,
  updateNote: pricingActions.updateFrequencyPricingNote,
};

const mapStateToProps = (state) => ({
  insuredWeight: state.pricing.insuredWeight,
  improvexWeight: state.pricing.improvexWeight,
  error: state.pricing.claimsWeightsProbableError,
  blendedExpected: pricingSelectors.getBlendedExpected(state),
  note: state.pricing.frequency.note || "",
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LossFrequencyComponent);
