export const resolveBaseLayerBurn = (input) => {
  if (input == null) {
    return null;
  }

  const { pricingCalculation, ...other } = input;

  const pricing = pricingCalculation?.response ?? {};

  return {
    ...other,
    pricing,
    selectedBaseLayerPrice:
      input?.baseLayerPriceOverride ??
      pricing?.values?.simpleBaseLayerBurn ??
      null,
  };
};
