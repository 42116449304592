export const UPDATE_HAZARD_AS_AT_DATE = "UPDATE_HAZARD_AS_AT_DATE";
export const HAZARD_FILE_DROPPED = "HAZARD_FILE_DROPPED";
export const HAZARD_FILE_PRELOADED = "HAZARD_FILE_PRELOADED";
export const HAZARD_FILE_UPLOADED = "HAZARD_FILE_UPLOADED";
export const HAZARD_FILE_UPLOAD_FAILED = "HAZARD_FILE_UPLOAD_FAILED";
export const UPDATE_HAZARD_NOTE = "UPDATE_HAZARD_NOTE";
export const HAZARD_DIALOG_PREV_STEP = "HAZARD_DIALOG_PREV_STEP";
export const HAZARD_DIALOG_NEXT_STEP = "HAZARD_DIALOG_NEXT_STEP";
export const HAZARD_DIALOG_CLOSED = "HAZARD_DIALOG_CLOSED";
export const HAZARD_SHEET_LIST_RETURNED = "HAZARD_SHEET_LIST_RETURNED";
export const HAZARD_ACTIVE_SHEET_CHANGED = "HAZARD_ACTIVE_SHEET_CHANGED";
export const HAZARD_SHEET_SELECTED = "HAZARD_SHEET_SELECTED";
export const HAZARD_UPDATE_COLUMNS = "HAZARD_UPDATE_COLUMNS";
export const HAZARD_COLUMN_NAMES_REQUESTED = "HAZARD_COLUMN_NAMES_REQUESTED";
export const HAZARD_COLUMN_NAMES_RETURNED = "HAZARD_COLUMN_NAMES_RETURNED";
export const HAZARD_COLUMN_NAMES_FAILED = "HAZARD_COLUMN_NAMES_FAILED";
export const HAZARD_FILE_MAPPING_FAILED = "HAZARD_FILE_MAPPING_FAILED";
export const HAZARD_MAPPING_SUGGESTIONS_RETURNED =
  "HAZARD_MAPPING_SUGGESTIONS_RETURNED";
export const ACTIVE_HAZARD_SHEET_TOP_LEFT_CHANGED =
  "ACTIVE_HAZARD_SHEET_TOP_LEFT_CHANGED";
export const HAZARD_SHEET_TOP_LEFT_SELECTED = "HAZARD_SHEET_TOP_LEFT_SELECTED";
export const HAZARD_COLUMN_MAPPING_UPDATED = "HAZARD_COLUMN_MAPPING_UPDATED";
export const UPDATE_HAZARD_STATE = "UPDATE_HAZARD_STATE";
export const HIDE_HAZARD_FILE = "HIDE_HAZARD_FILE";
export const UPDATE_HAZARD_PROFILE_SUMMARY = "UPDATE_HAZARD_PROFILE_SUMMARY";
export const UPDATE_HAZARD_PROFILE_SUMMARY_STARTED =
  "UPDATE_HAZARD_PROFILE_SUMMARY_STARTED";
export const UPDATE_HAZARD_PROFILE_SUMMARY_SUCCEEDED =
  "UPDATE_HAZARD_PROFILE_SUMMARY_SUCCEEDED";
export const UPDATE_HAZARD_PROFILE_SUMMARY_FAILED =
  "UPDATE_HAZARD_PROFILE_SUMMARY_FAILED";
