import { resolvePeople } from "../../../domain/peopleResolver";
import {
  extractSurplusBrokerFromPeople,
  exrtractNthUnderwriterFromPeople,
  extractBrokerFromPeople,
} from "domain/people";

export const selectResolvedPeople = (state) => resolvePeople(state);

export const selectBroker = (state) => {
  const people = resolvePeople(state);
  return extractBrokerFromPeople(people);
};

export const selectSurplusBroker = (state) => {
  const people = resolvePeople(state);
  return extractSurplusBrokerFromPeople(people);
};
export const selectNthUnderwriter = (n) => (state) => {
  const people = resolvePeople(state);
  return exrtractNthUnderwriterFromPeople(n)(people);
};

export const selectPreferredUnderwriter = (state) =>
  selectNthUnderwriter(0)(state);

export const selectSecondaryUnderwriter = (state) =>
  selectNthUnderwriter(1)(state);
