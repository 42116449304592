import {
  ArrowForward as InProgressIcon,
  Check as BoundIcon,
  Circle as WildcardIcon,
  CircleOutlined as DraftIcon,
  Close as NotTakenUpIcon,
  Remove as MiniClosedIcon,
  RemoveCircleOutline as ClosedIcon,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as staticDataService from "services/staticDataService";

const icons = {
  OPPORTUNITY: {
    class: "gold",
    icon: <DraftIcon />,
    miniIcon: <></>,
  },
  CLOSED: {
    class: "grey",
    icon: <ClosedIcon sx={{ transform: "rotate(-45deg)" }} />,
    miniIcon: <MiniClosedIcon sx={{ transform: "rotate(-45deg)" }} />,
  },
  IN_PROGRESS: {
    class: "blue",
    icon: <InProgressIcon style={{ padding: "1px" }} />,
  },
  NOT_TAKEN_UP: {
    class: "purple",
    icon: <NotTakenUpIcon style={{ padding: "1px" }} />,
  },
  BOUND: {
    class: "green",
    icon: <BoundIcon style={{ padding: "1px" }} />,
  },
  UNKNOWN: {
    class: "grey",
    icon: <DraftIcon />,
    miniIcon: <></>,
  },
  WILDCARD: {
    class: "darkgrey",
    icon: <WildcardIcon />,
  },
};

const isVariantType = (variant, type) =>
  (variant || "").trim().toLowerCase() === type;

const isIcon = (variant) => isVariantType(variant, "icon");
const isDot = (variant) => isVariantType(variant, "dot");
const isChip = (variant) => isVariantType(variant, "chip");
const isListItem = (variant) => isVariantType(variant, "listitem");

const Label = ({ title }) => (
  <Typography
    variant={"caption"}
    sx={{ fontWeight: "600", whiteSpace: "nowrap" }}
  >
    {title}
  </Typography>
);

const MiniIcon = ({ className, icon }) => (
  <Box className={`${className} mini`}>{icon.miniIcon ?? icon.icon}</Box>
);

const StatusBadge = ({ status, meta, variant }) => {
  const { data: statusLabels, isLoading } = staticDataService.useConfigQuery(
    "layer_status_labels_v2"
  );
  const statusKey =
    status ?? (isLoading ? null : statusLabels?.defaultStatusKey);
  if (!meta) {
    meta = statusLabels?.labels?.find((x) => x.statusKey === statusKey) ?? {
      icon: "UNKNOWN",
      displayName: "?",
    };
  }
  const icon = icons[meta?.icon] ?? icons.UNKNOWN;
  const className = `status-badge ${icon.class}`;

  if (isIcon(variant)) {
    return <MiniIcon className={className} icon={icon} />;
  }

  if (isDot(variant)) {
    return <Box className={className + " dot"} />;
  }

  if (isChip(variant)) {
    return (
      <div className={className}>
        {icon.icon}
        <Label title={meta.displayName} />
      </div>
    );
  }

  if (isListItem(variant)) {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography>{meta.displayName}</Typography>
        <MiniIcon className={className + " large"} icon={icon} />
      </Stack>
    );
  }

  return (
    <Stack direction={"row"} alignItems={"center"} spacing={"5px"}>
      <MiniIcon className={className} icon={icon} />
      <Label title={meta.displayName} />
    </Stack>
  );
};

export default StatusBadge;
