import { createApi } from "@reduxjs/toolkit/dist/query/react";
import * as logger from "common/logger";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.sov(args.url),
      { method: "post", ...args.payload }
    );
    return { data: await response.json() };
  } catch (e) {
    return { error: String(e) };
  }
};

export const sovApi = createApi({
  reducerPath: "sovApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createSovFile: builder.mutation({
      query: ({ fileId }) => ({
        url: "create-sov-file",
        payload: {
          body: JSON.stringify({ fileId }),
        },
      }),
    }),
    retrieveSovFile: builder.query({
      query: ({ sovFileId }) => ({
        url: "retrieve-sov-file",
        payload: {
          body: JSON.stringify({ sovFileId }),
        },
      }),
    }),
    downloadSov: builder.mutation({
      queryFn: async ({
        sovInput,
        outputType,
        filename,
        pollingIntervalSeconds = 1,
      }) => {
        try {
          let response = await utils.authenticatedFetch(
            config.endpoints.sov("trigger-sov-download"),
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                sovInput,
                outputType,
              }),
            }
          );
          const creationResponse = await response.json();
          const downloadId = creationResponse.id;

          const delay = (seconds) =>
            new Promise((res) => setTimeout(res, 1000 * seconds));

          let status = null;
          while (status == null || status.status === "PROCESSING") {
            await delay(pollingIntervalSeconds);
            response = await utils.authenticatedFetch(
              config.endpoints.sov("retrieve-sov-download-status"),
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ id: downloadId }),
              }
            );
            status = await response.json();
          }
          if (status.status !== "READY") {
            throw new Error(status);
          }
          response = await fetch(status.downloadUrl);
          const blob = await response.blob();
          FileSaver.saveAs(blob, filename);
          return { data: "READY" };
        } catch (e) {
          logger.exception(e);
          return {
            error: `Error generating report. "+
            "Please contact ${config.SUPPORT_EMAIL}`,
          };
        }
      },
    }),
  }),
});

export const {
  useCreateSovFileMutation,
  useRetrieveSovFileQuery,
  useDownloadSovMutation,
} = sovApi;
