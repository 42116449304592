import Error from "./Error";
import { Amplify } from "aws-amplify";
import {
  signOut,
  getCurrentUser,
  signInWithRedirect,
  AuthError,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import * as logger from "common/logger";
import BigLoader from "components/common/BigLoader";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

const ERROR_MESSAGE_KEY =
  "Authenticator.Hub.auth.cognitoHostedUI_failure.message";
const ERROR_TIMESTAMP_KEY =
  "Authenticator.Hub.auth.cognitoHostedUI_failure.timestamp";
const ERRORS_PERSIST_FOR = 1000 * 60 * 5;

Hub.listen("auth", ({ payload: { data, event } }) => {
  switch (event) {
    case "cognitoHostedUI_failure":
      setErrorMessage(data);
      break;
    case "cognitoHostedUI":
      clearErrorMessage();
      break;
    default:
      break;
  }
});

const getErrorMessage = () => {
  const timestampStr = localStorage.getItem(ERROR_TIMESTAMP_KEY);
  if (timestampStr == null) {
    return null;
  }
  const timestamp = new Date(timestampStr);
  if (Date.now() - timestamp.getTime() > ERRORS_PERSIST_FOR) {
    return null;
  }
  return localStorage.getItem(ERROR_MESSAGE_KEY);
};

const setErrorMessage = (data) => {
  const decoded = decodeURIComponent(data.message.replace(/\+/g, " "));
  localStorage.setItem(ERROR_MESSAGE_KEY, decoded);
  localStorage.setItem(ERROR_TIMESTAMP_KEY, new Date().toISOString());
};

const clearErrorMessage = () => {
  localStorage.removeItem(ERROR_MESSAGE_KEY);
  localStorage.removeItem(ERROR_TIMESTAMP_KEY);
};

const Logout = () => {
  useEffect(() => {
    signOut().then(() => {
      const amplifyConfig = Amplify.getConfig()?.Auth?.Cognito;
      const cognitoHostedUiDomain = amplifyConfig?.loginWith?.oauth?.domain;
      const cognitoHostedUiClientId = amplifyConfig?.userPoolClientId;
      const redirectUrl = window.location.origin;

      window.location.replace(
        `https://${cognitoHostedUiDomain}/logout?client_id=${cognitoHostedUiClientId}&redirect_uri=${redirectUrl}&response_type=code`
      );
    });
  }, []);

  return <BigLoader />;
};

const Validator = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const trySetCurrentUser = async () => {
      try {
        const user = await getCurrentUser();
        setUser(user);
        return true;
      } catch {
        return false;
      }
    };

    const signInIfNotAlready = async () => {
      try {
        await signInWithRedirect();
      } catch (e) {
        const isUserAlreadyAuthed =
          e instanceof AuthError &&
          e.name === "UserAlreadyAuthenticatedException";
        if (!isUserAlreadyAuthed) {
          throw e;
        }
      }
    };

    (async () => {
      try {
        setLoading(true);
        if (!(await trySetCurrentUser())) {
          await signInIfNotAlready();
          await trySetCurrentUser();
        }
      } catch (e) {
        logger.exception(e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const errorMessage = getErrorMessage();

  if (loading) {
    return <BigLoader />;
  } else if (errorMessage) {
    return (
      <Error
        title={"Unable to login."}
        description={errorMessage}
        buttons={[
          {
            label: "Sign In",
            variant: "contained",
            color: "primary",
            onClick: () => window.location.replace("/login"),
          },
          {
            label: "Sign Out",
            variant: "contained",
            color: "secondary",
            onClick: () => window.location.replace("/logout"),
          },
        ]}
      />
    );
  } else if (user) {
    return <>{children}</>;
  } else {
    return <BigLoader />;
  }
};

const CognitoHostedUiAuthenticator = ({ children }) => {
  return (
    <Routes>
      <Route path={"logout"} element={<Logout />} />
      <Route path={"*"} element={<Validator children={children} />} />
    </Routes>
  );
};

export default CognitoHostedUiAuthenticator;
