import { Button, Grid, TextField } from "@mui/material";
import Component from "components/Component";
import Options from "components/common/Options";
import * as config from "config";
import { useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/input/program/programActions";

export const DunsComponent = (props) => {
  const [duns, setDuns] = useState("");

  const handleAddDuns = () => {
    if (!duns || duns.trim() === "") {
      return;
    }
    props.addDuns(duns);
    setDuns("");
  };

  const hasDuns = props.duns && props.duns.length > 0;

  const optionItems = props.duns.map((dun) => {
    return { label: dun, selected: true };
  });

  return (
    <Component
      title={"DUNS"}
      subtitle={"The DUNS numbers associated with this insured."}
      md_width={12}
      lg_width={12}
    >
      <Grid container spacing={config.GRID_SPACING}>
        {hasDuns && (
          <Grid item xs={12}>
            <Options
              variant={"deletable"}
              items={optionItems}
              onClick={(item) => props.removeDuns(item.label)}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"DUNS Number"}
            autoComplete={"off"}
            variant={"filled"}
            fullWidth
            value={duns}
            onChange={(e) => setDuns(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                setDuns(e.target.value);
                handleAddDuns();
              }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleAddDuns}
            variant={"contained"}
            disableElevation
            color={"primary"}
            className={"button"}
          >
            {"Add"}
          </Button>
        </Grid>
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {
  addDuns: actions.addDuns,
  removeDuns: actions.removeDuns,
};

const mapStateToProps = (state) => {
  return {
    duns: state.input.program.duns,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DunsComponent);
