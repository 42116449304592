import * as React from "react";

const HEIGHT = 30;
const WIDTH = 96;

const SheetPreviewCell = ({
  column,
  row,
  setCellDimensions,
  data,
  DataViewer,
}) => {
  const rootRef = React.createRef();

  React.useEffect(() => {
    setCellDimensions(
      { row, column },
      {
        height: HEIGHT,
        width: WIDTH,
        left: WIDTH * (column + 1),
        top: HEIGHT * (row + 1),
      }
    );
  }, [setCellDimensions, column, row]);

  return <DataViewer rootRef={rootRef} row={row} column={column} data={data} />;
};

export default SheetPreviewCell;
