import { DATE_MODE, FILTER_MODE, SORT_ORDER } from "./constants";
import { hasItems } from "common/arrays";
import * as dates from "common/dates";
import { inYear } from "common/dates";
import { abbreviated } from "common/numbers";
import { hasValue, toSentenceCase } from "common/strings";
import * as people from "domain/people";
import { extractSubmissionIdFromFilename } from "domain/submissions";
import moment from "moment";
import { formatDate } from "utils";

const dateName = (date, placeholder) => {
  if (!date || date === "") return placeholder;
  const formatted = formatDate(date);
  const year = formatted.slice(-4);
  const dayMonth = formatted.slice(0, -4);
  return dayMonth + year;
};

const industryName = (key, industryClasses, placeholder) => {
  if (industryClasses == null) {
    return null;
  } else if (key == null) {
    return placeholder;
  } else {
    return industryClasses[key] ?? toSentenceCase(key);
  }
};

const configName = (key, config) => {
  if (key == null) {
    return null;
  } else if (config == null) {
    return key;
  } else {
    const item = config.filter((x) => x.key === key);
    return item?.[0]?.name;
  }
};

export const augmentSubmissions = (
  submissions,
  industryClasses,
  businessUnits,
  reinsurers
) => {
  if (!hasItems(submissions)) {
    return [];
  }

  const currentYear = new Date().getFullYear();
  const priorSubmissionIds = submissions
    .filter((s) => s.priorSubmission != null)
    .map((s) => extractSubmissionIdFromFilename(s.priorSubmission));

  return submissions.map((submission) => {
    const inCurrentYear = inYear(submission.inception, currentYear);
    const preferredUnderwriter = people.extractPreferredUnderwriterFromProgram(
      submission.people
    );
    const secondaryUnderwriter = people.extractSecondaryUnderwriterFromProgram(
      submission.people
    );
    const broker = people.extractBrokerFromPeople(submission.people);
    const layers = (submission.layers ?? []).map((layer) => {
      return {
        ...layer,
        layerName:
          layer.limit != null && layer.attachment != null
            ? abbreviated(layer.limit) + " xs " + abbreviated(layer.attachment)
            : null,
        businessUnitName: configName(layer.businessUnit, businessUnits),
        paperProviderName: configName(layer.paperProvider, reinsurers),
      };
    });

    return {
      ...submission,
      insured: submission.insured || "Untitled",
      inceptionName: dateName(submission.inception, "Missing Inception"),
      inCurrentYear: inCurrentYear,
      year: inCurrentYear ? currentYear : null,
      isLatest: !priorSubmissionIds.includes(submission.submissionId),
      industryName: industryName(
        submission.industryClass,
        industryClasses,
        "No industry class specified"
      ),
      preferredUnderwriterName: preferredUnderwriter?.name ?? null,
      secondaryUnderwriterName: secondaryUnderwriter?.name ?? null,
      brokerName: broker?.name ?? null,
      brokerCompany: broker?.company ?? null,
      layers: layers,
    };
  });
};

export const compareStringItems = (item) => (a, b) =>
  String(a?.[item] ?? "").localeCompare(String(b?.[item] ?? ""));

export const tokenize = (searchQuery) => {
  let tailIndex = -1;
  const protectedMap = new Array(searchQuery.length).fill(false);
  searchQuery.split("").forEach((character, headIndex) => {
    if (character === '"') {
      if (tailIndex !== -1) {
        if (headIndex - tailIndex > 1) {
          for (let j = tailIndex + 1; j < headIndex; j++) {
            protectedMap[j] = true;
          }
        }
        tailIndex = -1;
      } else {
        tailIndex = headIndex;
      }
    }
  });

  let tailCharacter = " ";
  tailIndex = 0;
  let searchTokens = [];
  searchQuery.split("").forEach((character, headIndex) => {
    if (!protectedMap[headIndex]) {
      if (character.match(/[^a-zA-Z0-9&:"]/)) {
        if (headIndex - tailIndex > 0) {
          searchTokens.push(searchQuery.substring(tailIndex, headIndex));
        }
        tailIndex = headIndex + 1;
        tailCharacter = character;
      } else if (character === ":") {
        if (headIndex - tailIndex > 0) {
          searchTokens.push(searchQuery.substring(tailIndex, headIndex + 1));
        }
        tailIndex = headIndex + 1;
        tailCharacter = character;
      } else if (character === '"') {
        if (headIndex - tailIndex > 0) {
          searchTokens.push(searchQuery.substring(tailIndex, headIndex));
          tailCharacter = "";
        } else if (headIndex - tailIndex === 0 && tailCharacter === '"') {
          searchTokens.push("");
          tailCharacter = "";
        } else {
          tailCharacter = '"';
        }
        tailIndex = headIndex + 1;
      }
    }
  });
  if (searchQuery.length - tailIndex > 1) {
    searchTokens.push(
      searchQuery.substring(tailIndex, searchQuery.length).toLowerCase()
    );
  }

  const searchFields = [];
  const fieldList = [];
  searchTokens.forEach((token, i) => {
    if (token.includes(":")) {
      if (i < searchTokens.length - 1) {
        searchFields.push({
          field: token.replaceAll(":", "").toLowerCase(),
          value: searchTokens[i + 1].includes(":")
            ? ""
            : searchTokens[i + 1].toLowerCase(),
        });
        fieldList.push(i, i + 1);
      } else {
        searchFields.push({
          field: token.replaceAll(":", "").toLowerCase(),
          value: "",
        });
        fieldList.push(i);
      }
    }
  });

  searchTokens
    .filter((v, index) => !fieldList.includes(index))
    .forEach((value) => {
      searchFields.push({
        field: "__ALL__",
        value: value.toLowerCase(),
      });
    });

  return searchFields;
};

const parseTokensToMatcher = (tokens) => {
  const matchableFields = [
    "insured",
    "riskDescription",
    "inception",
    "reference",
    "inceptionName",
    "industryName",
    "preferredUnderwriterName",
    "secondaryUnderwriterName",
    "brokerName",
    "brokerCompany",
    "transaction",
  ];

  const matchableLayerFields = [
    "layerName",
    "status",
    "reference",
    "businessUnitName",
    "paperProviderName",
  ];

  const allMatchableFields = [...matchableFields, ...matchableLayerFields];
  const unmatchedTokens = tokens
    .filter(({ field }) => field === "__ALL__")
    .map(({ value }) => value);
  const matchedFieldTokens = [];

  tokens
    .filter(({ field }) => field !== "__ALL__")
    .forEach(({ field: tokenField, value }) => {
      let match = allMatchableFields.find(
        (field) => tokenField.toLowerCase() === field.toLowerCase()
      );
      if (match) {
        matchedFieldTokens.push({ field: match, value });
      }
    });

  const coalesceDictionaries = (dictionaries) => {
    const coalescedDictionary = {};
    dictionaries.forEach((dictionary) => {
      Object.entries(dictionary).forEach(([key, value]) => {
        coalescedDictionary[key] = [
          ...(coalescedDictionary?.[key] ?? []),
          value,
        ];
      });
    });
    return coalescedDictionary;
  };

  return (submission) => {
    const coalessedFields = coalesceDictionaries([
      ...[...(submission?.layers ?? []), {}].reduce((layersAcc, layer) => {
        return [
          ...layersAcc,
          matchableLayerFields.reduce((acc, field) => {
            return {
              ...acc,
              [field]: String(layer?.[field] ?? "").toLowerCase(),
            };
          }, {}),
        ];
      }, []),
      matchableFields.reduce((acc, field) => {
        return {
          ...acc,
          [field]: String(submission?.[field] ?? "").toLowerCase(),
        };
      }, {}),
    ]);
    const coalessedValues = Object.values(coalessedFields).reduce(
      (acc, currentArray) => {
        return [...acc, ...currentArray];
      }
    );

    return (
      unmatchedTokens.every((token) =>
        coalessedValues.some((value) => value.includes(token))
      ) &&
      matchedFieldTokens.every(({ field, value: tokenValue }) =>
        coalessedFields[field].some((value) => value.includes(tokenValue))
      )
    );
  };
};

export const parseSearchQuery = (searchQuery) => {
  const tokens = tokenize(searchQuery);
  return parseTokensToMatcher(tokens);
};

export const makeQueryMatcher = (searchQuery) => {
  if (!hasValue(searchQuery)) {
    return (_) => true;
  }
  const matcher = parseSearchQuery(searchQuery);
  return matcher;
};

const addMonths = (count) => {
  const today = moment.parseZone(dates.getDate()).format("YYYY-MM-DD");
  const endDate = moment
    .parseZone(today)
    .add(count, "months")
    .format("YYYY-MM-DD");
  return { today, endDate };
};

export const searchSubmissions = (
  submissions,
  { searchQuery, filter, dateMode, sort, userEmail, limit = 50 }
) => {
  if (!submissions) {
    return {
      recentlyEditedSubmissions: [],
      renewingSubmissions: [],
    };
  }

  if (dateMode === DATE_MODE.LATEST) {
    submissions = submissions.filter((s) => s.isLatest);
  } else if (dateMode === DATE_MODE.THIS_YEAR) {
    submissions = submissions.filter((s) => s.inCurrentYear);
  }

  if (filter === FILTER_MODE.SAVED_BY_ME) {
    submissions = submissions.filter((s) => s.savedBy === userEmail);
  } else if (filter === FILTER_MODE.RENEWING_SOON) {
    const { today, endDate } = addMonths(3);
    submissions = submissions.filter(
      (s) => s.expiration > today && s.expiration <= endDate
    );
  }

  if (searchQuery) {
    const matchesQuery = makeQueryMatcher(searchQuery);
    submissions = submissions.filter(matchesQuery);
  }

  const sortKey =
    sort === SORT_ORDER.INSURED_NAME
      ? "insured"
      : sort === SORT_ORDER.EFFECTIVE_DATE
      ? "effectiveDate"
      : filter === FILTER_MODE.RENEWING_SOON
      ? "expiration"
      : "savedAt";

  const sortDirection =
    sort === SORT_ORDER.INSURED_NAME
      ? 1
      : filter === FILTER_MODE.RENEWING_SOON
      ? 1
      : -1;

  return [...submissions]
    .sort((a, b) => sortDirection * compareStringItems(sortKey)(a, b))
    .slice(0, limit);
};
