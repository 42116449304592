import { requestAttachmentTree } from "../temp/attachments/attachmentsTempActions";
import * as types from "./persistenceActionTypes";
import * as actions from "./persistenceActions";
import { createAction } from "@reduxjs/toolkit";
import * as dates from "common/dates";
import * as ids from "common/ids";
import * as logger from "common/logger";
import * as config from "config";
import * as errorActions from "store/actions/error/errorActions";
import * as exposureActions from "store/actions/input/exposure/exposureActions";
import * as hazardActions from "store/actions/input/exposure/hazardActions";
import * as peersActions from "store/actions/peers/peersActions";
import * as maintenanceStatusActions from "store/actions/temp/maintenance/maintenanceStatusActions";
import { setSavedPersistentState } from "store/actions/temp/navigation/navigationActions";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import { selectPersistedState } from "store/selectors/persistence/persistenceSelectors";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as utils from "utils";

export { upgradeState } from "./upgrade";

export const cancelRenewSubmission = () => ({
  type: types.CANCEL_RENEW_SUBMISSION,
});

const validateCreateSubmission = ({ renewFromSubmission } = {}) => async (
  dispatch,
  getState
) => {
  try {
    const expected = selectPersistedState(getState());
    await utils.authenticatedFetch(
      config.endpoints.persistence("validate-create-submission"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ expected, renewFromSubmission }),
      }
    );
  } catch (e) {
    logger.exception(e);
  }
};

export const newSubmission = ({ navigate }) => async (dispatch, getState) => {
  const priorState = getState();
  const featureFlags = staticDataSelectors.selectConfig("feature_flags")(
    priorState
  );
  const newViaBackend = featureFlags?.["ui/newViaBackend"] ?? false;
  if (newViaBackend) {
    try {
      dispatch({ type: types.LOAD_SUBMISSION_STARTED });
      const response = await utils.authenticatedFetch(
        config.endpoints.persistence("create-submission"),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const submission = await response.json();
      dispatch({
        type: types.LOAD_SUBMISSION_SUCCEEDED,
        payload: {
          data: submission,
        },
      });
    } catch (e) {
      logger.exception(e);
      dispatch({ type: types.LOAD_SUBMISSION_FAILED });
    } finally {
      navigate("/submissions/new");
    }
  } else {
    dispatch({
      type: types.NEW_SUBMISSION,
      payload: {
        priorState,
        filename: ids.getFilename(),
      },
    });
    dispatch(validateCreateSubmission());
    navigate("/submissions/new");
  }
};

export const renewSubmission = ({ navigate, force = false }) => (
  dispatch,
  getState
) => {
  const priorState = getState();
  const renewingInception = new Date(programSelectors.getInception(priorState));
  const renewDirectly = force || renewingInception < dates.getDate();
  if (renewDirectly) {
    dispatch({
      type: types.RENEW_SUBMISSION,
      payload: {
        priorState,
        filename: ids.getFilename(),
      },
    });
    const renewFromSubmission = {
      state: selectPersistedState(priorState),
      filename: priorState?.persistence?.filename,
    };
    dispatch(validateCreateSubmission({ renewFromSubmission }));
    navigate("/submissions/new");
  } else {
    dispatch({
      type: types.RENEW_SUBMISSION_DIALOG,
      payload: {
        priorState,
      },
    });
  }
};

export const duplicateSubmission = ({ navigate }) => (dispatch) => {
  dispatch({
    type: types.DUPLICATE_SUBMISSION,
    payload: {
      filename: ids.getFilename(),
    },
  });
  navigate("/submissions/new");
};

const _loadSubmission = (querySubmission) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.LOAD_SUBMISSION_STARTED });
    const rawData = await querySubmission();
    const data = actions.upgradeState(rawData);
    const schemaVersion = data?.state?.schemaVersion;
    const state = getState();
    if (schemaVersion) {
      if (schemaVersion > config.CURRENT_FILE_SCHEMA_VERSION) {
        logger.error(
          "Loaded file with schema version greater than current schema version",
          {
            breadcrumb: {
              schemaVersion,
              currentSchemaVersion: config.CURRENT_FILE_SCHEMA_VERSION,
              filename: rawData?.filename,
            },
          }
        );
        const featureFlags = staticDataSelectors.selectConfig("feature_flags")(
          state
        );
        const disableSchemaVersionValidation =
          featureFlags?.["ui/disableSchemaVersionValidation"] ?? false;
        if (!disableSchemaVersionValidation) {
          dispatch(
            maintenanceStatusActions.updateMaintenanceStatus({
              forceReload: true,
              dialogTitle: "Please reload the application",
              dialogMessage:
                "Please reload the application. Following an update, this version is no longer compatible.",
            })
          );
          return;
        }
      }
      if (schemaVersion < config.CURRENT_FILE_SCHEMA_VERSION) {
        logger.error(
          "Loaded file with schema version less than current schema version",
          {
            breadcrumb: {
              schemaVersion,
              currentSchemaVersion: config.CURRENT_FILE_SCHEMA_VERSION,
              filename: rawData?.filename,
            },
          }
        );
      }
    }
    dispatch({
      type: types.LOAD_SUBMISSION_SUCCEEDED,
      payload: {
        data,
      },
    });
    dispatch((dispatch, getState) =>
      dispatch(setSavedPersistentState(selectPersistedState(getState())))
    );
    (data?.state?.peers?.submissions || []).forEach((submission) => {
      dispatch(peersActions.loadPeer(submission));
    });
    dispatch(hazardActions.updateHazardProfileSummary());
    if (data?.state?.input?.program?.industryClass) {
      dispatch(
        exposureActions.updateExposureMeasures([
          data.state.input.program.industryClass,
        ])
      );
    }
    if (data?.state?.input?.program?.priorSubmission) {
      dispatch(
        actions.loadPriorSubmission(
          data?.state?.input?.program?.priorSubmission
        )
      );
    }
    const refreshAttachmentTreeMetadat = (dir) => {
      (dir?.attachedFiles || [])
        .filter((fid) => fid)
        .forEach((fileId) => dispatch(requestAttachmentTree(fileId)));
      (dir?.subDirectories || []).forEach((subDir) =>
        refreshAttachmentTreeMetadat(subDir)
      );
    };
    refreshAttachmentTreeMetadat(data?.state?.input?.attachments);
  } catch (e) {
    logger.exception(e);
    dispatch({ type: types.LOAD_SUBMISSION_FAILED });
  }
};

export const loadExistingSubmission = (submissionId) =>
  _loadSubmission(async () => {
    const response = await utils.authenticatedFetch(
      config.endpoints.persistence(
        "get?" + new URLSearchParams({ filename: `${submissionId}.json` })
      )
    );
    return await response.json();
  });

export const loadExistingSubmissionVersion = (submissionId, versionId) =>
  _loadSubmission(async () => {
    const response = await utils.authenticatedFetch(
      config.endpoints.persistence("retrieve-version"),
      {
        method: "POST",
        body: JSON.stringify({
          submissionId,
          versionId,
        }),
      }
    );
    const submission = await response.json();
    return {
      ...submission,
      state: {
        ...submission?.state,
        meta: {
          ...submission?.state?.meta,
          overlay: {
            type: "FromVersion",
            versionId,
          },
        },
      },
    };
  });

export const loadTransferredSubmission = (
  transferId,
  { asTransferred, useTransferService = true }
) =>
  useTransferService
    ? _loadSubmission(async () => {
        const response = await utils.authenticatedFetch(
          config.endpoints.transfer("retrieve-merge-definition"),
          {
            method: "POST",
            body: JSON.stringify({
              transferId,
            }),
          }
        );
        const mergeDefinition = await response.json();
        return mergeDefinition?.merged?.submission;
      })
    : _loadSubmission(async () => {
        const response = await utils.authenticatedFetch(
          config.endpoints.exchange("retrieve-transferred-submission"),
          {
            method: "POST",
            body: JSON.stringify({
              transferId,
              asTransferred,
            }),
          }
        );
        return await response.json();
      });

export const loadPriorSubmission = (filename) => (dispatch) => {
  utils
    .authenticatedFetch(
      config.endpoints.persistence("get?" + new URLSearchParams({ filename }))
    )
    .then((result) => result.json())
    .then((rawData) => actions.upgradeState(rawData))
    .then((data) => {
      dispatch({
        type: types.RECEIVED_PRIOR_SUBMISSION,
        payload: data,
      });
    })
    .catch((error) => {
      errorActions.handleError(error, {
        title: "Unable to open Prior Submission",
        message: `Could not load: ${filename}`,
        type: "UNABLE_TO_OPEN_SUBMISSION",
      });
    });
};

export const saveAcceptedTransmission = (
  submissionId,
  submissionTransferId
) => ({
  type: types.SAVE_ACCEPTED_TRANSMISSION,
  payload: { submissionId, submissionTransferId },
});

export const acceptedTransmissionSaved = (submissionId) => ({
  type: types.ACCEPTED_TRANSMISSION_SAVED,
  payload: { submissionId },
});

export const transferCleanup = () => ({
  type: types.ACCEPTED_TRANSMISSION_CLEANUP,
});
export const saveState = {
  requested: createAction("persistence/saveState"),
  accept_transmission: createAction("persistence/acceptTransmission"),
  transmission_accepted: createAction("persistence/acceptTransmission"),
  started: createAction("persistence/saveState/started"),
  succeeded: createAction(
    "persistence/saveState/succeeded",
    ({ savedAt, filename, savedBy, overwritten, savedToken }) => {
      return {
        payload: {
          savedAt,
          filename,
          savedBy,
          overwritten,
          savedToken,
        },
      };
    }
  ),
  failed: createAction("persistence/saveState/failed", (errorMessage) => {
    return {
      payload: errorMessage,
    };
  }),
};
