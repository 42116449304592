export const FILTER_MODE = {
  EVERYTHING: "EVERYTHING",
  SAVED_BY_ME: "SAVED_BY_ME",
  RENEWING_SOON: "RENEWING_SOON",
};

export const DATE_MODE = {
  EVERYTHING: "EVERYTHING",
  THIS_YEAR: "THIS_YEAR",
  LATEST: "LATEST",
};

export const SORT_ORDER = {
  DATE: "DATE",
  INSURED_NAME: "INSURED_NAME",
  EFFECTIVE_DATE: "EFFECTIVE_DATE",
};
