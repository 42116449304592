import * as chartUtils from "chartUtils";
import * as numbers from "common/numbers";
import Component from "components/Component";
import React from "react";
import { connect } from "react-redux";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Cell,
  Text,
  ReferenceLine,
} from "recharts";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as userSelectors from "store/selectors/user/userSelectors";
import * as utils from "utils";

export const formatToolTip = (value) => utils.sfString(value);

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x}
      y={y}
      width={75}
      textAnchor={"middle"}
      fill={"#313d5e"}
      verticalAnchor={"start"}
    >
      {payload.value}
    </Text>
  );
};

const red = "#FF634E";
const yellow = "#FFAB53";

const CustomToolTip = (props) => {
  const { payload, formatter } = props;

  const tooltip = {
    backgroundColor: "white",
    opacity: "1",
    border: "1px solid #CECECE",
    borderRadius: "4px",
    paddingLeft: "20px",
    paddingRight: "20px",
  };

  return (
    <div>
      <div className={"custom-tooltip"} style={tooltip}>
        {(payload ?? []).map((item, i) => {
          return (
            <div key={i}>
              <p
                style={{
                  color: item.payload.label === "UW Selected" ? red : yellow,
                }}
                key={i}
              >
                {item.payload.label}
                {":"} <strong>{formatter(item.payload.value)}</strong>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const LayerPricingChartComponent = (props) => {
  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();
  const isVisible = ({ byTag, defaultVisible = false }) => {
    return props.userConfig.componentVisibility?.[byTag] ?? defaultVisible;
  };

  const percentTickFormatter = (value) => {
    return !props.layer.grossPremium
      ? ""
      : (
          (100 - props.layer.profit - props.layer.expenses) *
          (value / props.layer.grossPremium)
        ).toFixed(0) + "%";
  };

  const toolTipFormatter = (value) => {
    return (
      "$" +
      value.toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })
    );
  };

  const percentToolTipFormatter = (value) => {
    return (
      (value * 100).toLocaleString(undefined, {
        maximumFractionDigits: 3,
      }) + "%"
    );
  };

  return (
    <Component
      title={"Required Premium"}
      subtitle={
        "The premium required to cover the expected loss cost computed via different pricing methods."
      }
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingBottom: "500px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <BarChart
              width={500}
              height={500}
              data={props.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 20,
              }}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray={"3 3"} />
              <XAxis
                dataKey={"label"}
                interval={0}
                angle={-45}
                textAnchor={"end"}
                tick={<CustomizedAxisTick />}
                height={80}
              />
              {isVisible({
                byTag: "pricing.layerPricing.chart.purePremium",
                defaultVisible: true,
              }) && (
                <YAxis
                  label={{
                    value: "Required 100% Premium",
                    angle: -90,
                    position: "left",
                    style: { textAnchor: "middle" },
                    fill: "#313d5e",
                  }}
                  domain={[() => 0, () => props.chartMaximum]}
                  tickFormatter={chartUtils.tickFormatter}
                />
              )}
              {isVisible({
                byTag: "pricing.layerPricing.chart.purePremiumPercent",
                defaultVisible: false,
              }) && (
                <YAxis
                  label={{
                    value: "Pure Premium",
                    angle: -90,
                    position: "left",
                    style: { textAnchor: "middle" },
                    fill: "#313d5e",
                  }}
                  domain={[() => 0, () => props.chartMaximum]}
                  tickFormatter={(x) =>
                    chartUtils.tickFormatter(numbers.toPercentFromDecimal(x)) +
                    "%"
                  }
                />
              )}
              {isVisible({
                byTag: "pricing.layerPricing.chart.GNULR",
                defaultVisible: true,
              }) && (
                <YAxis
                  label={{
                    value: "GNULR",
                    angle: 90,
                    position: "right",
                    style: { textAnchor: "middle" },
                    fill: "#313d5e",
                  }}
                  yAxisId={1}
                  domain={[() => 0, () => props.chartMaximum]}
                  tickFormatter={percentTickFormatter}
                  orientation={"right"}
                />
              )}
              {isVisible({
                byTag: "pricing.layerPricing.chart.percentTooltip",
                defaultVisible: false,
              }) && (
                <Tooltip
                  position={tooltipPosition}
                  isAnimationActive={false}
                  content={({ payload, label }) => (
                    <CustomToolTip
                      payload={payload}
                      label={label}
                      formatter={percentToolTipFormatter}
                    />
                  )}
                />
              )}
              {isVisible({
                byTag: "pricing.layerPricing.chart.dollarTooltip",
                defaultVisible: true,
              }) && (
                <Tooltip
                  position={tooltipPosition}
                  isAnimationActive={false}
                  content={({ payload, label }) => (
                    <CustomToolTip
                      payload={payload}
                      label={label}
                      formatter={toolTipFormatter}
                    />
                  )}
                />
              )}
              <Legend />
              <Bar
                dataKey={"value"}
                fill={"#82ca9d"}
                legendType={"none"}
                name={"Gross Premium"}
              >
                {props.data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index === props.data.length - 1 ? red : yellow}
                  />
                ))}
              </Bar>
              {isVisible({
                byTag: "pricing.layerPricing.chart.grossPremium",
                defaultVisible: true,
              }) && (
                <ReferenceLine
                  y={props.layer.grossPremium}
                  stroke={"#000000"}
                  label={{
                    value: `100% Bound Premium - $${chartUtils.tickFormatter(
                      props.layer.grossPremium
                    )}`,
                    position: "insideBottom",
                    fill: "#000000",
                  }}
                  strokeWidth={4}
                  ifOverflow={"extendDomain"}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Component>
  );
};

const mapDispatchToProps = {};

export const mapStateToProps = (state) => {
  const data = pricingSelectors.formatGrossPremiumChartData(state);
  const userConfig = userSelectors.selectUserConfig(state);

  const includeGrossPremiumOnChart =
    userConfig.componentVisibility?.[
      "pricing.layerPricing.chart.grossPremium"
    ] ?? true;

  return {
    data,
    layer: pricingSelectors.getActiveLayer(state) ?? {},
    chartMaximum: includeGrossPremiumOnChart
      ? pricingSelectors.getChartMaximum(state)
      : numbers.roundUpNice(Math.max(5e-6, ...data.map((x) => x.value))),
    userConfig,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerPricingChartComponent);
