import * as persistenceActionTypes from "../../actions/persistence/persistenceActionTypes";
import {
  BROKER_TYPE,
  SURPLUS_BROKER_TYPE,
  EMPTY_PERSON,
  UNDERWRITER_TYPE,
} from "domain/people";
import {
  UPDATE_BROKER,
  UPDATE_BROKER_CONTACT,
  UPDATE_BROKER_HOUSE,
  UPDATE_BROKER_NAME,
  UPDATE_PREFERRED_UNDERWRITER,
  UPDATE_PREFERRED_UNDERWRITER_NAME,
  UPDATE_SECONDARY_UNDERWRITER,
  UPDATE_SURPLUS_BROKER,
} from "store/actions/people/peopleActionTypes";

export const INIT_STATE = {};

const updatePeople = (state, which, type, callback) => {
  const people = (state.people || []).map((person) => ({ ...person }));
  const subgroup = people.filter((p) => p.type === type);
  let person = null;
  if (subgroup.length <= which) {
    while (subgroup.length <= which) {
      person = {
        ...EMPTY_PERSON,
        type: type,
      };
      people.push(person);
      subgroup.push(person);
    }
  } else {
    person = subgroup[which];
  }
  callback(person);
  return {
    ...state,
    people,
  };
};

const updateUnderwriter = (state, which, callback) => {
  return updatePeople(state, which, UNDERWRITER_TYPE, callback);
};

const updateSurplusBroker = (state, which, callback) => {
  return updatePeople(state, which, SURPLUS_BROKER_TYPE, callback);
};

const updateBroker = (state, which, callback) => {
  return updatePeople(state, which, BROKER_TYPE, callback);
};

const peopleReducer = (state, action) => {
  if (state === undefined) {
    state = {
      ...INIT_STATE,
    };
  }
  switch (action.type) {
    case UPDATE_PREFERRED_UNDERWRITER:
      return updateUnderwriter(state, 0, (underwriter) => {
        if (
          !action.payload ||
          (!action.payload.name && !action.payload.id && !action.payload.email)
        ) {
          underwriter.name = EMPTY_PERSON.name;
          underwriter.id = EMPTY_PERSON.id;
          underwriter.email = EMPTY_PERSON.email;
          underwriter.company = EMPTY_PERSON.company;
        } else {
          underwriter.name = action.payload?.name ?? underwriter.name;
          underwriter.id = action.payload?.id ?? underwriter.id;
          underwriter.email = action.payload?.email ?? underwriter.email;
        }
      });
    case UPDATE_PREFERRED_UNDERWRITER_NAME:
      return updateUnderwriter(state, 0, (underwriter) => {
        underwriter.name = action.payload ?? EMPTY_PERSON.name;
      });
    case UPDATE_SECONDARY_UNDERWRITER:
      return updateUnderwriter(state, 1, (underwriter) => {
        underwriter.name = action.payload?.name ?? EMPTY_PERSON.name;
        underwriter.id = action.payload?.id ?? EMPTY_PERSON.id;
        underwriter.email = action.payload?.email ?? EMPTY_PERSON.email;
      });
    case UPDATE_BROKER_NAME:
      return updateBroker(state, 0, (broker) => {
        broker.name = action.payload ?? EMPTY_PERSON.name;
      });
    case UPDATE_BROKER_CONTACT:
      return updateBroker(state, 0, (broker) => {
        broker.email = action.payload ?? EMPTY_PERSON.email;
      });
    case UPDATE_BROKER_HOUSE:
      return updateBroker(state, 0, (broker) => {
        broker.company = action.payload ?? EMPTY_PERSON.company;
      });
    case UPDATE_SURPLUS_BROKER:
      return updateSurplusBroker(state, 0, (broker) => {
        broker.id = action.payload?.id ?? EMPTY_PERSON.id;
        broker.name = action.payload?.name ?? EMPTY_PERSON.name;
        broker.email = action.payload?.email ?? EMPTY_PERSON.email;
        broker.company = action.payload?.company ?? EMPTY_PERSON.company;
        broker.licenseNumber =
          action.payload?.licenseNumber ?? EMPTY_PERSON.licenseNumber;
      });
    case UPDATE_BROKER:
      return updateBroker(state, 0, (broker) => {
        broker.id = action.payload?.id ?? EMPTY_PERSON.id;
        broker.name = action.payload?.name ?? EMPTY_PERSON.name;
        broker.email = action.payload?.email ?? EMPTY_PERSON.email;
        broker.company = action.payload?.company ?? EMPTY_PERSON.company;
      });
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...state,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.people,
      };

    default:
      return state;
  }
};

export default peopleReducer;
