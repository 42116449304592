import { Stack, Typography } from "@mui/material";
import DefaultLoader from "components/common/DefaultLoader";

const CentredLoader = ({ label }) => {
  const hasLabel = label != null;

  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        pointerEvents: "none",
      }}
    >
      <DefaultLoader
        color={hasLabel ? "#fa7b35" : "#dc7f4c"}
        type={hasLabel ? "TailSpin" : null}
        style={{
          width: "200px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        size={60}
      />
      {hasLabel && (
        <Typography className={"pulse"} color={"secondary"} mt={3}>
          {label}
        </Typography>
      )}
    </Stack>
  );
};

export default CentredLoader;
