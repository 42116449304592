import AttachmentFileViewer from "./attachments/AttachmentFileViewer";
import Dashboard from "./layout/Dashboard";
import DashboardContent from "./layout/DashboardContent";
import React from "react";
import { Route, Routes } from "react-router-dom";

const AttachmentsApp = () => {
  return (
    <div className={"app"}>
      <Dashboard>
        <DashboardContent isFullWidth={true}>
          <Routes>
            <Route path={"view/*"} element={<AttachmentFileViewer />} />
            <Route path={"fileView/*"} element={<AttachmentFileViewer />} />
          </Routes>
        </DashboardContent>
      </Dashboard>
    </div>
  );
};

export default AttachmentsApp;
