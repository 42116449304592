export const latestExposure = (data, exposureKey, { asAtYear } = {}) => {
  if (data == null) {
    return undefined;
  }
  const key = Object.keys(data)
    .filter((x) => data[x][exposureKey] != null)
    .filter((x) => (asAtYear ? x <= asAtYear : true))
    .sort()
    .reverse()[0];
  return data[key]?.[exposureKey];
};

export const latestTurnover = (data) => latestExposure(data, "REVENUE");

export const exposureSeries = (data, exposureMetric) => {
  return Object.fromEntries(
    Object.entries(data)
      .filter(
        ([_, v]) =>
          v[exposureMetric] !== undefined && v[exposureMetric] !== null
      )
      .map(([k, v]) => [k, v[exposureMetric]])
  );
};

export const extractAllKeys = (data) => {
  const notAnObject = (atom) => {
    if (atom === null) {
      return true;
    }
    return typeof atom !== "object" || atom instanceof Array;
  };

  const keys = new Set();

  if (notAnObject(data)) {
    return keys;
  }

  for (const datum of Object.values(data)) {
    if (notAnObject(datum)) {
      continue;
    }
    for (const [key, value] of Object.entries(datum)) {
      if (keys.has(key)) {
        continue;
      }
      if (value || value === false || value === 0) {
        keys.add(key);
      }
    }
  }

  return keys;
};
