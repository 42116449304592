import DownloadOriginalButton from "./DownloadOriginalButton";
import { GetApp, Delete as DeleteIcon } from "@mui/icons-material";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Component from "components/Component";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import {
  useGetExtractMetadataQuery,
  useGetFileMetadataQuery,
  useDownloadExcelTableMutation,
} from "services/filesService";

const DownloadParquetFilesButton = ({
  fileIds,
  columnMap,
  fileName,
  sheetName,
  isDisabled,
}) => {
  const [
    triggerDownload,
    { isLoading, isError },
  ] = useDownloadExcelTableMutation();

  return (
    <Button
      onClick={() =>
        triggerDownload({ fileIds, sheetName, columnMap, fileName })
      }
      startIcon={<GetApp />}
      isLoading={isLoading}
      isDisabled={isDisabled}
      hasError={isError}
    >
      {"Download"}
    </Button>
  );
};

const FileRow = ({
  fileId,
  insured,
  hideFile,
  parquetColumnMap,
  outputFilePrefix,
}) => {
  const { data: metadata, status: metadataStatus } = useGetExtractMetadataQuery(
    fileId,
    {
      skip: !fileId,
    }
  );

  const {
    data: sourceMetadata,
    status: sourceMetadataStatus,
  } = useGetFileMetadataQuery(metadata?.id, {
    skip: !metadata?.id,
  });

  const loadingMetadata = metadataStatus !== "fulfilled";
  const loading = loadingMetadata || sourceMetadataStatus !== "fulfilled";

  return (
    <TableRow>
      <TableCell padding={"checkbox"}>
        <IconButton icon={DeleteIcon} onClick={() => hideFile(fileId)} />
      </TableCell>
      <TableCell>
        {loading ? (
          <DefaultLoader color={"#dc7f4c"} />
        ) : (
          sourceMetadata?.originalFilename
        )}
      </TableCell>
      <TableCell>
        {loadingMetadata ? (
          <DefaultLoader color={"#dc7f4c"} />
        ) : (
          metadata?.sheetName
        )}
      </TableCell>
      <TableCell>
        <DownloadParquetFilesButton
          fileIds={[fileId]}
          columnMap={parquetColumnMap}
          fileName={`${outputFilePrefix}-${insured}-${
            sourceMetadata?.originalFilename ?? ""
          }-${metadata?.sheetName ?? ""}.xlsx`}
          sheetName={metadata?.sheetName}
          isDisabled={loadingMetadata}
        />
      </TableCell>
      <TableCell>
        <DownloadOriginalButton
          {...{
            isDisabled: loading,
            sourceMetadata,
          }}
        />
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

const extractedFileDownloader = ({
  title,
  files,
  insured,
  hideFile,
  parquetColumnMap,
  outputFilePrefix,
}) => {
  return (
    <Component title={title} sm_width={12} md_width={8} growVertically>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding={"checkbox"} />
              <Tooltip title={"The filename of the file that was uploaded."}>
                <TableCell>{"Filename"}</TableCell>
              </Tooltip>
              <Tooltip title={"The sheet of the file that was uploaded."}>
                <TableCell>{"Sheet"}</TableCell>
              </Tooltip>
              <Tooltip
                title={
                  "The file after it has been mapped and how it is used for pricing."
                }
              >
                <TableCell>{"Transformed"}</TableCell>
              </Tooltip>
              <Tooltip title={"The original file that was uploaded."}>
                <TableCell>{"Original"}</TableCell>
              </Tooltip>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((fileId) => (
              <FileRow
                {...{
                  key: fileId,
                  fileId,
                  insured,
                  hideFile,
                  parquetColumnMap,
                  outputFilePrefix,
                }}
              />
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell colSpan={4}>
                <DownloadParquetFilesButton
                  fileIds={files}
                  columnMap={parquetColumnMap}
                  fileName={`All-${outputFilePrefix}-${insured}.xlsx`}
                  sheetName={outputFilePrefix}
                >
                  <GetApp />
                  {"Download All"}
                </DownloadParquetFilesButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

export default extractedFileDownloader;
