import { Box, Tooltip } from "@mui/material";

/**
 * @typedef {import("@mui/material").SxProps} SxProps
 * @param {SxProps} sx
 */
export const makeRenderCellWithTooltip = () =>
  /**
   * @typedef {import("@mui/x-data-grid").GridRenderCellParams} GridRenderCellParams
   * @param {GridRenderCellParams} params
   */
  (params) => {
    return (
      <Tooltip title={params.value ?? ""}>
        <Box component={"span"}>{params.formattedValue}</Box>
      </Tooltip>
    );
  };
