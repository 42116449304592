import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { InputCell } from "components/inputs/lossDevelopment/Cell";
import * as config from "config";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

export const ProgramInputs = ({ pricingInputs, updatePricingInput }) => {
  return (
    <Grid container item xs={12} spacing={config.GRID_SPACING}>
      <Grid container item xs={12} spacing={config.GRID_SPACING}>
        <Grid item xs={4}>
          <FormControl variant={"filled"} fullWidth>
            <InputLabel>{"Rate Basis"}</InputLabel>
            <Select
              value={pricingInputs?.burningCost?.rateBasis ?? "__NA__"}
              onChange={(e) => {
                const rateBasis = e.target.value;
                updatePricingInput({
                  key: "burningCost",
                  values: {
                    rateBasis: rateBasis === "__NA__" ? null : rateBasis,
                  },
                });
              }}
            >
              <MenuItem value={"__NA__"}>{"---"}</MenuItem>
              <MenuItem value={"FROM_GROUND_UP"}>{"From Ground Up"}</MenuItem>
              <MenuItem value={"ASSUMED"}>{"Assumed"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Typography>{"Ground Up Loss Ratio"}</Typography>
            <InputCell
              percentage
              onChangeNumber={(x) =>
                updatePricingInput({
                  key: "burningCost",
                  values: {
                    manualGroundUpLossRatio: x,
                  },
                })
              }
              value={pricingInputs?.burningCost?.groundUpLossRatio}
              active={pricingInputs?.burningCost?.manualGroundUpLossRatio}
            />
          </Stack>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={config.GRID_SPACING}>
        <Grid item xs={4}>
          <FormControl variant={"filled"} fullWidth>
            <InputLabel>{"Premium Basis"}</InputLabel>
            <Select
              value={pricingInputs?.burningCost?.premiumBasis ?? "__NA__"}
              onChange={(e) => {
                const value = e.target.value;
                updatePricingInput({
                  key: "burningCost",
                  values: {
                    premiumBasis: value === "__NA__" ? null : value,
                  },
                });
              }}
            >
              <MenuItem value={"__NA__"}>{"---"}</MenuItem>
              <MenuItem value={"ACTUAL"}>{"Actual"}</MenuItem>
              <MenuItem value={"ON_LEVEL"} disabled>
                {"On-Level"}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            label={"Use trended claims?"}
            control={
              <Checkbox
                checked={pricingInputs?.burningCost?.useTrendedClaims ?? false}
                disabled
                onChange={(e) =>
                  updatePricingInput({
                    key: "burningCost",
                    values: {
                      useTrendedClaims: e.target.checked,
                    },
                  })
                }
              />
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  updatePricingInput: pricingActions.updatePricingInput,
};

const mapStateToProps = (state) => ({
  pricingInputs: pricingSelectors.selectResolvedPricingInputs(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramInputs);
