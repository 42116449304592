import DownloadSOVButton from "./DownloadSOVButton";
import SovGrid from "./SovGrid";
import SovMetrics from "./SovMetrics";
import UploadSov from "./UploadSov";
import { FilterListRounded as FilterIcon } from "@mui/icons-material";
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import * as strings from "common/strings";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import { usePricing } from "components/customPricing/dnfModel1/pricingHooks";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as sovService from "services/sovService";
import * as staticDataService from "services/staticDataService";
import * as SOVStore from "store/SOV";
import * as analyticsStore from "store/analytics";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

const useProgramPricing = () => {
  const { isFetching } = usePricing();

  const programPricing = useSelector(analyticsStore.select)?.programPricing;

  return {
    programPricing,
    isProgramPricingFetching: isFetching,
  };
};

const useResolvedSov = ({ sovInput }) => {
  const [resolvedSov, setResolvedSov] = useState({
    locations: [],
    overrides: {},
  });

  const {
    data: sovFile,
    isFetching: isSovFileFetching,
  } = sovService.useRetrieveSovFileQuery(
    { sovFileId: sovInput?.sovFileId },
    {
      skip: !sovInput?.sovFileId,
    }
  );

  useEffect(() => {
    const overrides = { ...sovInput?.overrides };
    const locations = (sovFile?.locations ?? []).map((row, rowIndex) => {
      // Until we are sourcing the table from the SOV service, this ID needs to be
      // kept in sync with the ID being generated by the SOV service.
      const id = `${sovFile.id}:${rowIndex}`;
      const location = {
        ...row,
        id,
      };
      if (overrides[id]) {
        overrides[id] = Object.fromEntries(
          Object.entries(overrides[id]).map(([field, { value }]) => {
            const original = location[field];
            location[field] = value;
            return [field, { value, original }];
          })
        );
      }
      return location;
    });
    setResolvedSov({ locations, overrides });
  }, [sovFile, sovInput, setResolvedSov]);

  return {
    resolvedSov,
    isResolvedSovFetching: isSovFileFetching,
  };
};

const useBuildTableRows = ({ resolvedSov, programPricing }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const newRows = (resolvedSov?.locations ?? [])
      .map((row) => ({
        ...row,
        ...programPricing?.values?.sov?.[row.id],
      }))
      .map((row) => ({
        ...row,
        _GOOGLE_EARTH_LINK: makeGoogleEarthLink(row),
      }));
    setRows(newRows);
  }, [setRows, resolvedSov, programPricing]);

  return {
    rows,
  };
};

const makeGoogleEarthLink = ({ _ADDRESS }) => {
  if (!strings.hasValue(_ADDRESS)) {
    return null;
  }
  return `https://earth.google.com/web/search/${encodeURIComponent(_ADDRESS)}`;
};

const useBuildValidations = ({ rows, selectedLayerExcess }) => {
  const [validations, setValidations] = useState({});

  useEffect(() => {
    const validations = {};
    const isMissingValue = (column) => (row) =>
      row[column] == null || row[column] === "";
    rows?.forEach((row) => {
      const requiredColumns = [
        "_TIV",
        ["_OCCUPANCY_SEGMENT", "_MANUAL_RATE_PCT"],
        "_ADDRESS",
      ];
      const fieldErrors = Object.fromEntries(
        requiredColumns
          .filter((columnSpec) =>
            Array.isArray(columnSpec)
              ? columnSpec.every((c) => isMissingValue(c)(row))
              : isMissingValue(columnSpec)(row)
          )
          .flatMap((columnSpec) =>
            Array.isArray(columnSpec)
              ? columnSpec.map((column) => [column, "Missing value"])
              : [[columnSpec, "Missing value"]]
          )
      );
      if (Object.keys(fieldErrors).length) {
        validations[row.id] = {
          fieldErrors,
          severity: row._TIV >= selectedLayerExcess ? "error" : "warning",
        };
      }
    });
    setValidations(validations);
  }, [rows, setValidations, selectedLayerExcess]);

  return {
    validations,
  };
};

const NonCatValidationSummary = ({
  validations,
  isFiltered,
  setIsFiltered,
}) => {
  const validationCount = Object.keys(validations ?? {}).length;
  return isFiltered || validationCount > 0 ? (
    <Chip
      color={"error"}
      variant={isFiltered ? "filled" : "outlined"}
      label={`${validationCount} ${
        validationCount === 1 ? "row" : "rows"
      } missing info for non-cat pricing`}
      clickable
      onClick={() => setIsFiltered(!isFiltered)}
      icon={<FilterIcon />}
    />
  ) : (
    <></>
  );
};

const SOV = () => {
  const dispatch = useDispatch();

  const [isFilteredToNonCatErrors, setIsFilteredToNonCatErrors] = useState(
    false
  );
  const [validationLayerId, setValidationLayerId] = useState(null);

  const sovInput = useSelector(SOVStore.selectSOV);
  const layers = useSelector(pricingSelectors.selectResolvedLayers);

  const { data: sovConfig } = staticDataService.useConfigQuery("sov");

  const { resolvedSov, isResolvedSovFetching } = useResolvedSov({ sovInput });

  const { programPricing, isProgramPricingFetching } = useProgramPricing();

  const { rows } = useBuildTableRows({ resolvedSov, programPricing });

  const selectedLayerExcess =
    layers
      ?.filter((layer) => layer.id === validationLayerId)
      ?.map((layer) => layer.attachment)?.[0] ?? 0;

  const { validations } = useBuildValidations({ rows, selectedLayerExcess });

  useEffect(() => {
    if (
      isFilteredToNonCatErrors &&
      Object.keys(validations ?? {}).length === 0
    ) {
      setIsFilteredToNonCatErrors(false);
    }
  }, [isFilteredToNonCatErrors, setIsFilteredToNonCatErrors, validations]);

  const filteredRows = isFilteredToNonCatErrors
    ? rows.filter(({ id }) => validations?.[id] != null)
    : rows;

  const updateOverrides = (newRow, oldRow) => {
    const newOverrides = {
      overrides: {
        ...sovInput?.overrides,
        [newRow.id]: {
          ...sovInput?.overrides?.[newRow.id],
          ...Object.fromEntries(
            Object.entries(newRow ?? {})
              .filter(([k, v]) => oldRow[k] !== v)
              .map(([k, v]) => [k, { value: v }])
          ),
        },
      },
    };
    dispatch(SOVStore.updateSOV(newOverrides));
  };

  const deleteOverride = (rowId, colId) => {
    const over = sovInput?.overrides ?? {};
    const row = over[rowId] ?? {};
    const cell = row[colId];
    if (!cell) return;
    const newRow = Object.entries(row).filter((r) => r[0] !== colId);
    const delRow = newRow.length === 0;
    const newOverrides = {
      overrides: {
        ...(delRow
          ? Object.fromEntries(
              Object.entries(over).filter((r) => r[0] !== rowId)
            )
          : over),
      },
    };
    if (!delRow) newOverrides.overrides[rowId] = Object.fromEntries(newRow);
    dispatch(SOVStore.updateSOV(newOverrides));
  };

  const hasSovInput = sovInput?.sovFileId;

  if (!hasSovInput) {
    return <UploadSov />;
  }

  return (
    <>
      <Component
        title={"SOV"}
        isFetching={isProgramPricingFetching}
        options={
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <NonCatValidationSummary
              validations={validations}
              isFiltered={isFilteredToNonCatErrors}
              setIsFiltered={setIsFilteredToNonCatErrors}
            />
            <DownloadSOVButton />
            {hasSovInput && (
              <Disabled ifReadOnly>
                <Button
                  size={"small"}
                  onClick={() => dispatch(SOVStore.setSOV({}))}
                >
                  {"Clear"}
                </Button>
              </Disabled>
            )}
            <FormControl variant={"filled"} size={"small"}>
              <InputLabel>{"Validate for Layer"}</InputLabel>
              <Select
                value={validationLayerId ?? "__NONE__"}
                onChange={(e) => setValidationLayerId(e.target.value)}
                sx={{
                  minWidth: 180,
                }}
              >
                <MenuItem value={"__NONE__"}>{"Ground-Up"}</MenuItem>
                {layers.map((layer) => {
                  return (
                    <MenuItem key={layer.id} value={layer.id}>
                      {utils.shortLayerName(layer)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Stack>
        }
      >
        {isResolvedSovFetching ? (
          <BigLoader />
        ) : (
          <Stack direction={"column"} spacing={2}>
            <SovMetrics locations={rows} />
            <Disabled ifReadOnly>
              <SovGrid
                columnsConfig={sovConfig?.columns ?? []}
                rows={filteredRows}
                paginationOptions={{
                  pageSize: 15,
                  pageSizeOptions: [5, 10, 15, 100],
                }}
                enumConfig={{
                  enums: sovConfig?.enums ?? {},
                }}
                onRowUpdate={updateOverrides}
                onCellValueDelete={deleteOverride}
                overrides={resolvedSov?.overrides}
                validations={validations}
                isFetching={isProgramPricingFetching}
              />
            </Disabled>
          </Stack>
        )}
      </Component>
    </>
  );
};

export default SOV;
