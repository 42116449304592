import PeersSearch from "../PeersSearch";
import IndustryClassPeers from "./IndustryClassPeers";
import NAICSPeers from "./NAICSPeers";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Checkbox,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
} from "@mui/material";
import Component from "components/Component";
import DefaultLoader from "components/common/DefaultLoader";
import ExposureSelector from "components/common/ExposureSelector";
import GoodBadTableCell from "components/common/GoodBadTableCell";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import * as config from "config";
import { Fragment } from "react";
import { connect } from "react-redux";
import * as peersActions from "store/actions/peers/peersActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";
import * as peersSelectors from "store/selectors/peers/peersSelectors";

const PercentFormatted = (props) => (
  <GoodBadTableCell
    suffix={"%"}
    decimalScale={2}
    fixedDecimalScale
    {...props}
  />
);

const DollarFormatted = (props) => <GoodBadTableCell prefix={"$"} {...props} />;

const ReviewComponent = (props) => {
  const LayerlessRow = ({ submission }) => {
    return (
      <TableRow>
        <TableCell>
          <IconButton
            icon={DeleteIcon}
            onClick={() => props.deletePeer(submission.filename)}
          />
        </TableCell>
        <TableCell>{submission.insured}</TableCell>
        <TableCell className={"tablecell-inception"}>
          {submission.inception}
        </TableCell>
        <TableCell colSpan={10}>
          {"No Layers with positive line size."}
        </TableCell>
      </TableRow>
    );
  };

  const LoadingRow = ({ submission }) => {
    return (
      <TableRow>
        <TableCell>
          <IconButton
            icon={DeleteIcon}
            onClick={() => props.deletePeer(submission.filename)}
          />
        </TableCell>
        <TableCell>{submission.insured}</TableCell>
        <TableCell className={"tablecell-inception"}>
          {submission.inception}
        </TableCell>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
          <TableCell align={"right"} key={i}>
            <DefaultLoader
              color={"#dc7f4c"}
              style={{
                display: "inline-block",
                position: "relative",
                top: "2px",
              }}
              size={16}
            />
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const LayerRow = ({ submission, layer, layerIndex, isLast, peerIndex }) => {
    return (
      <TableRow style={peerIndex === 0 ? { background: "#dbdce5" } : {}}>
        <TableCell style={isLast ? {} : { borderBottom: "none" }}>
          {layerIndex > 0 || peerIndex === 0 ? (
            ""
          ) : (
            <IconButton
              icon={DeleteIcon}
              onClick={() => props.deletePeer(submission.filename)}
            />
          )}
        </TableCell>
        <TableCell style={isLast ? {} : { borderBottom: "none" }}>
          {layerIndex > 0 ? "" : submission.insured}
        </TableCell>
        <TableCell
          className={"tablecell-inception"}
          style={isLast ? {} : { borderBottom: "none" }}
        >
          {layerIndex > 0 ? "" : submission.inception}
        </TableCell>
        <TableCell>
          {peerIndex === 0 ? (
            <></>
          ) : (
            <Checkbox
              checked={layer.weighting > 0}
              onChange={(e) =>
                props.updatePricingSelection(
                  submission.filename,
                  layer.unfiltered_index,
                  e.target.checked ? 1 : 0
                )
              }
            />
          )}
        </TableCell>
        <GoodBadTableCell
          cellStyle={isLast ? {} : { borderBottom: "none" }}
          value={layerIndex > 0 ? "" : submission.fixedPeerMetricValue}
        />
        <GoodBadTableCell
          cellStyle={isLast ? {} : { borderBottom: "none" }}
          value={layerIndex > 0 ? "" : submission.exposure}
        />
        <GoodBadTableCell
          cellStyle={isLast ? {} : { borderBottom: "none" }}
          value={layerIndex > 0 ? "" : submission.exposure2}
        />
        <DollarFormatted value={layer.lineSize} />
        <DollarFormatted value={layer.limit} />
        <DollarFormatted value={layer.attachment} />
        <DollarFormatted value={layer.grossPPM} />
        <PercentFormatted value={layer.tp} good={(layer.tp ?? 100) - 100} />
        <PercentFormatted value={layer.totalAcquisition} />
      </TableRow>
    );
  };

  const headers = [
    { title: "", tooltip: "", align: "left" },
    { title: "Insured", tooltip: "", align: "left" },
    { title: "Inception", tooltip: "", align: "left" },
    {
      title: "Price",
      tooltip: "Select which layers to use in pricing",
      align: "center",
    },
    { title: props.fixedPeerMetricDefn.name, tooltip: "", align: "right" },
    { title: props.exposureMetric?.name, tooltip: "", align: "right" },
    { title: props.exposureMetric2?.name, tooltip: "", align: "right" },
    { title: "Our Limit", tooltip: "", align: "right" },
    { title: "Part Of", tooltip: "", align: "right" },
    { title: "Attachment", tooltip: "", align: "right" },
    { title: "Gross PPM", tooltip: "", align: "right" },
    {
      title: "Target Price (TP)",
      tooltip: config.DEFINITION_TP,
      align: "right",
    },
    {
      title: "TAC",
      tooltip: config.DEFINITION_TAC,
      align: "right",
    },
  ];

  return (
    <>
      <Component
        xs_width={12}
        title={"Peer Comparison"}
        subtitle={"Compare important metrics across similar insureds."}
      >
        <TableContainer>
          <Table size={"small"}>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => {
                  const cell = (
                    <TableCell key={i} align={header.align}>
                      {header.title}
                    </TableCell>
                  );
                  if (header.tooltip) {
                    return (
                      <Tooltip key={i} title={header.tooltip}>
                        {cell}
                      </Tooltip>
                    );
                  }
                  return cell;
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.peerData.map((submission, peerIndex) => {
                if (!submission.loaded) {
                  return <LoadingRow key={peerIndex} submission={submission} />;
                } else if (!submission?.layers?.length) {
                  return (
                    <LayerlessRow key={peerIndex} submission={submission} />
                  );
                } else {
                  return (
                    <Fragment key={peerIndex}>
                      {submission.layers.map((layer, layerIndex) => (
                        <LayerRow
                          key={layerIndex}
                          submission={submission}
                          layer={layer}
                          layerIndex={layerIndex}
                          isLast={layerIndex + 1 === submission?.layers?.length}
                          peerIndex={peerIndex}
                        />
                      ))}
                    </Fragment>
                  );
                }
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Component>
      <Component
        title={"Exposure Metrics"}
        subtitle={
          "Select the exposure metrics to display in the Peer Comparison table."
        }
        lg_width={4}
        md_width={12}
      >
        <Grid container spacing={config.GRID_SPACING} direction={"column"}>
          <Grid item xs={12}>
            <ExposureSelector
              selectedExposureMetric={props.exposureMetric}
              exposureRows={props.exposureRows}
              updateChosenExposureMetric={props.updateChosenExposureMetric}
            />
          </Grid>
          <Grid item xs={12}>
            <ExposureSelector
              selectedExposureMetric={props.exposureMetric2}
              exposureRows={props.exposureRows}
              updateChosenExposureMetric={props.updateChosenExposureMetric2}
              title={"Exposure Metric 2"}
            />
          </Grid>
        </Grid>
      </Component>
      <Component
        title={"Add Peers"}
        subtitle={"Search for peers to add to the Peer Comparison table."}
        lg_width={4}
        md_width={12}
      >
        <PeersSearch label={"Insured"} />
      </Component>
      <Notes
        md_width={12}
        lg_width={4}
        update={props.updateNote}
        value={props.note}
      />
      <IndustryClassPeers />
      <NAICSPeers />
    </>
  );
};

const mapDispatchToProps = {
  updateChosenExposureMetric: peersActions.updateChosenExposureMetric,
  updateChosenExposureMetric2: peersActions.updateChosenExposureMetric2,
  updatePricingSelection: peersActions.updatePricingSelection,
  deletePeer: peersActions.deletePeer,
  updateNote: peersActions.updatePeersNote,
};

const mapStateToProps = (state) => {
  const fixedPeerMetricDefn = peersSelectors.selectFixedPeerMetricDefn(state);
  return {
    peerData: peersSelectors.getPeers(state),
    exposureMetric: state.peers.exposureMetric,
    exposureMetric2: state.peers.exposureMetric2,
    fixedPeerMetricDefn,
    exposureRows: exposureSelectors
      .getExposuresInAllSubmissionsUsed(state)
      .filter((x) => x.key !== fixedPeerMetricDefn.key),
    note: state.peers.note,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewComponent);
