import { tableText, wrapTextInBreaks } from "../../../../common/markdown";
import SquareOutlinedIcon from "@mui/icons-material/SquareOutlined";
import WindowIcon from "@mui/icons-material/Window";
import React from "react";

export const insertTable = (rows, columns) => ({
  name: "insertTable",
  keyCommand: "insertTable",
  buttonProps: {
    "aria-label": `Insert a ${columns} by ${rows} table`,
    title: `Insert a ${columns} by ${rows} table`,
  },
  icon: <SquareOutlinedIcon />,
  execute: (state, api) => {
    const newText = tableText(rows, columns);
    api.replaceSelection(wrapTextInBreaks(state, newText));
  },
});

export const insert2x2Table = {
  ...insertTable(2, 2),
  icon: <WindowIcon fontSize={"inherit"} />,
};
