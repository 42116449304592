import * as objects from "common/objects";

export const stringify = (value) =>
  JSON.stringify(value ?? {}, objects.stringifyReducers.sortKeys, 4);

export const combineJson = ({ prefix, suffix }) => {
  let newJson = validJson(prefix + suffix);
  if (newJson != null) {
    return newJson;
  }
  if (prefix.endsWith(",")) {
    newJson = validJson(prefix.slice(0, -1) + suffix);
    if (newJson != null) {
      return newJson;
    }
  }
  alert("Can't delete");
};

export const insertJson = ({ prefix, suffix, insert }) => {
  const inserts = [
    ["", ""],
    [",", ""],
    ["", ","],
    [",", ","],
  ];
  for (let i = 0; i < inserts.length; i++) {
    const [pre, suf] = inserts[i];
    const newJson = validJson(prefix + pre + insert + suf + suffix);
    if (newJson != null) {
      return newJson;
    }
  }
  alert("Unable to combine Json");
};

export const isValidJson = (text) => {
  try {
    return JSON.parse(text);
  } catch (e) {
    return null;
  }
};

export const validJson = (text) => {
  try {
    return stringify(JSON.parse(text));
  } catch (e) {
    return null;
  }
};

export const findObjectLinesInText = (startLine, text) => {
  const lines = text.split("\n");
  const leadingSpaces = lines[startLine].match(/^\s*/)[0].length;
  const regex = new RegExp(`^ {${leadingSpaces}}\\S.*`);
  for (let i = startLine; i < lines.length; i++) {
    if (regex.test(lines[i])) {
      let testJson = lines.slice(startLine, i + 1).join("\n");
      if (testJson.endsWith(",")) {
        testJson = testJson.slice(0, -1);
      }
      if (isValidJson(testJson) || isValidJson("{" + testJson + "}")) {
        return [startLine, i];
      }
    }
  }
  return null;
};
