import { FormControlLabel, Stack, Switch } from "@mui/material";
import * as dates from "common/dates";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import { useEffect, useState } from "react";

const DateInput = ({ defn, options, setOptions }) => {
  const [enabled, setEnabled] = useState(true);

  const value = options[defn.name];
  const setValue = (x) =>
    setOptions({
      ...options,
      [defn.name]: x,
    });

  const normalizeValue = (v) => (v == null || v === "" ? null : v);

  useEffect(() => {
    if (enabled && normalizeValue(value) == null) {
      const currentDate = dates.getDateStringFromDateTimeString(
        dates.getDate().toISOString()
      );
      if (currentDate != null) {
        setValue(currentDate);
      }
    }
  });

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {defn.isOptional && (
        <FormControlLabel
          label={enabled ? "" : defn.optionLabel ?? `Include ${defn.label}`}
          control={
            <Switch
              checked={enabled}
              onChange={() => {
                if (enabled) {
                  setEnabled(false);
                  setValue(null);
                } else {
                  setEnabled(true);
                }
              }}
            />
          }
        />
      )}
      {enabled && (
        <FormattedDatePicker
          label={defn.label}
          onChangeDate={(date) => setValue(normalizeValue(date))}
          value={value ?? ""}
          sx={{ minWidth: 180 }}
        />
      )}
    </Stack>
  );
};

export default DateInput;
