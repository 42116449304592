import InlineSearch from "./InlineSearch";
import SeverityBadge from "./SeverityBadge";
import StatusBadgeV2 from "./StatusBadgeV2";
import {
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import ReviewStatusBadge from "components/common/ReviewStatusBadge";
import { useEffect, useState } from "react";

const WildcardLabel = ({ context }) => {
  if (context === "Status") {
    return (
      <StatusBadgeV2
        status={"ANY_STATUS"}
        meta={{ icon: "WILDCARD", displayName: "Any Status" }}
        variant={"listItem"}
      />
    );
  } else if (context === "Severity") {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography>{"Any Level"}</Typography>
        <SeverityBadge level={null} size={14} />
      </Stack>
    );
  } else if (context === "Review Status") {
    return (
      <ReviewStatusBadge
        status={null}
        label={"Any Status"}
        variant={"listItem"}
      />
    );
  } else {
    return <Typography>{"Any " + context}</Typography>;
  }
};

const ItemLabel = ({ context, item }) => {
  if (context === "Status") {
    return <StatusBadgeV2 status={item.value} variant={"listItem"} />;
  } else if (context === "Severity") {
    return (
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
      >
        <Typography>{item.label}</Typography>
        <SeverityBadge level={item.value} size={14} />
      </Stack>
    );
  } else if (context === "Review Status") {
    return <ReviewStatusBadge status={item.value} variant={"listItem"} />;
  } else {
    return <Typography>{item.label}</Typography>;
  }
};

const FilterSelect = ({
  context,
  items,
  initialSelected,
  open,
  onClose,
  onChange,
  sortItems = true,
}) => {
  useEffect(() => {
    if (open) {
      setSearchText("");
      setSelected(initialSelected ?? []);
    }
  }, [open, initialSelected]);

  const [selected, setSelected] = useState(initialSelected ?? []);
  const [searchText, setSearchText] = useState("");

  if (!open) {
    return <></>;
  }

  if (sortItems) {
    items = [...items].sort((a, b) => a.label.localeCompare(b.label));
  }

  const anySelected = selected.length === 0;
  const hasSearchText = (searchText ?? "").trim() !== "";
  const filteredItems = hasSearchText
    ? items.filter((x) =>
        x.label.toLowerCase().includes(searchText.toLowerCase().trim())
      )
    : items;
  const searchHasNoMatches = hasSearchText && filteredItems.length === 0;

  const toggleAll = () => {
    const updated = anySelected ? items.map((x) => x.value) : [];
    setSelected(updated);
    onChange(context, updated);
  };

  const toggleItem = (item) => {
    let updated;
    const idx = selected.findIndex((x) => x === item);
    if (idx === -1) {
      updated = [...selected, item];
    } else {
      updated = selected.filter((x) => x !== item);
    }
    setSelected(updated);
    onChange(context, updated);
  };

  const checkboxSx = { padding: "6px", marginLeft: "8px" };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogContent sx={{ background: "white", padding: "12px" }}>
        <Stack>
          <InlineSearch
            placeholder={"Search"}
            onChange={(searchText) => setSearchText(searchText)}
            noBackground={true}
            sx={{ marginBottom: "6px" }}
          />

          {!hasSearchText && (
            <>
              <Divider sx={{ marginBottom: "4px" }} />
              <FormControlLabel
                onChange={() => toggleAll()}
                control={<Checkbox checked={anySelected} sx={checkboxSx} />}
                label={<WildcardLabel context={context} />}
                disableTypography
                sx={{ width: "100%" }}
              />
            </>
          )}
          <Divider sx={{ margin: "4px 0" }} />
          {filteredItems.map((item) => {
            return (
              <FormControlLabel
                key={item.value}
                onChange={() => toggleItem(item.value)}
                control={
                  <Checkbox
                    checked={
                      anySelected ? false : selected.includes(item.value)
                    }
                    sx={checkboxSx}
                  />
                }
                label={<ItemLabel context={context} item={item} />}
                disableTypography
                sx={{ width: "100%" }}
              />
            );
          })}
          {searchHasNoMatches && (
            <Typography margin={2} color={"dimgray"} align={"center"}>
              {"No matches for '"}
              {searchText.trim()}
              {"'"}
            </Typography>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default FilterSelect;
