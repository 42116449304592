import { Box, Stack, Typography } from "@mui/material";
import { Area, AreaChart, ResponsiveContainer } from "recharts";

const changeStatus = {
  up: "up",
  down: "down",
  flat: "flat",
  invalid: "invalid",
};

const lastItem = (items, offset) => {
  offset = offset || 0;
  const hasItems = Array.isArray(items) && items.length > 0 + offset;
  return hasItems ? items[items.length - 1 - offset] : null;
};

const getMeta = (items) => {
  const meta = {
    status: changeStatus.invalid,
    current: lastItem(items),
    prior: lastItem(items, 1),
    percentChange: null,
    stroke: "lightsteelblue",
    fill: "#fafafa",
  };

  if (meta.current == null || meta.prior == null) {
    return meta;
  }

  if (meta.current > meta.prior) {
    meta.status = changeStatus.up;
    meta.stroke = "limegreen";
    meta.fill = "honeydew";
  } else if (meta.current < meta.prior) {
    meta.status = changeStatus.down;
    meta.stroke = "crimson";
    meta.fill = "mistyrose";
  } else {
    meta.status = changeStatus.flat;
  }

  if (meta.prior === 0 && meta.current !== 0) {
    meta.percentChange = Math.sign(meta.current) * Infinity;
  } else {
    const change = meta.current / meta.prior - 1;
    meta.percentChange = Math.round(change * 10_000) / 100;
  }

  return meta;
};

const percentLabel = (meta) => {
  if (meta.status === changeStatus.invalid) {
    return null;
  } else {
    const prefix = meta.percentChange > 0 ? "+" : "";
    const suffix = Math.abs(meta.percentChange) === Infinity ? "" : "%";
    return `${prefix}${meta.percentChange}${suffix}`;
  }
};

const getStyle = (borderColour, clickable) => {
  const sx = {
    position: "relative",
    width: "275px",
    height: "150px",
    paddingBottom: "10px",
    flexShrink: 0,
    overflow: "hidden",
    background: "white",
    borderRadius: "0.5rem",
    userSelect: "none",
    cursor: clickable ? "pointer" : null,
  };

  if (borderColour != null) {
    sx.border = `1px solid ${borderColour}`;
    sx.boxShadow = `0 0 0 2px ${borderColour}`;
  } else {
    sx.border = "1px solid gainsboro";
  }

  return sx;
};

const TrendCard = ({ data, onClick, borderColour }) => {
  const plotData = data.values.map((value) => {
    return { value: value };
  });
  const clickable = typeof onClick == "function";
  const meta = getMeta(data.values);

  return (
    <Box
      onClick={() => {
        onClick && onClick(data.key, data.name);
      }}
      sx={getStyle(borderColour, clickable)}
    >
      <Stack
        sx={{ margin: "0.5rem 0.75rem", zIndex: 9999 }}
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Stack>
          <Typography variant={"subtitle2"}>{data.name}</Typography>
          <Typography variant={"h6"}>
            {(meta.current || 0).toLocaleString("en-US")}
          </Typography>
        </Stack>
        <Typography
          variant={"subtitle2"}
          sx={{ color: meta.stroke }}
          paddingLeft={1}
        >
          {percentLabel(meta)}
        </Typography>
      </Stack>
      <ResponsiveContainer height={70}>
        <AreaChart
          data={plotData}
          margin={0}
          style={{
            position: "absolute",
            bottom: "0",
            cursor: clickable ? "pointer" : null,
          }}
        >
          <Area
            dataKey={"value"}
            isAnimationActive={false}
            fill={meta.fill}
            fillOpacity={0.8}
            stroke={meta.stroke}
            strokeWidth={2}
            style={{ borderRadius: "10px" }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TrendCard;
