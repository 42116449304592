import rootReducer from "./store/reducers/rootReducer";
import rootSaga from "./store/sagas/rootSaga";
import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import thunkMiddleware from "redux-thunk";
import allApis from "services/all";

const sagaMiddleware = createSagaMiddleware();

const composedEnhancer = composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
    sagaMiddleware,
    ...allApis.map((x) => x.middleware)
  )
);
const store = createStore(rootReducer, composedEnhancer);

sagaMiddleware.run(rootSaga);

export default store;
