import { createApi } from "@reduxjs/toolkit/query/react";
import * as config from "config";
import * as utils from "utils";

const baseQuery = async (args, api, extraOptions) => {
  try {
    const response = await utils.authenticatedFetch(
      config.endpoints.bff(args.url),
      {
        method: "POST",
        ...args.payload,
      }
    );
    return {
      data: await response.json(),
    };
  } catch (e) {
    return {
      error: String(e),
    };
  }
};

export const bffApi = createApi({
  reducerPath: "bffApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    createInsuredSummary: builder.query({
      query: ({ submissionId }) => ({
        url: "create-insured-summary",
        payload: {
          body: JSON.stringify({ submissionId }),
        },
      }),
    }),
  }),
});

export const { useCreateInsuredSummaryQuery } = bffApi;
