import * as cells from "./cells";
import * as numbers from "common/numbers";

const makePrettyNumberValueSetter = (
  key,
  { multiplier = 1, extraValues } = {}
) => (value, row) => {
  try {
    if (extraValues?.includes(value)) {
      return { ...row, [key]: value };
    }

    const parsedValue = numbers.parseNumberString(value);
    return isFinite(parsedValue)
      ? { ...row, [key]: parsedValue / multiplier }
      : row;
  } catch {
    return row;
  }
};

/**
 * @typedef {import("@mui/x-data-grid").GridColDef} GridColDef
 * @param {GridColDef} colProps
 * @param {number} dp
 * @param {number} prefix
 * @param {number} suffix
 * @param {number} multiplier
 */
export const makePrettyNumberColDef = ({
  field,
  editable = true,
  sortable = false,
  headerAlign = "right",
  align = "right",
  dp = 0,
  multiplier = 1,
  prefix = "",
  suffix = "",
  // A list of non-numeric values that are accepted
  extraValues = null,
  ...colProps
}) => {
  return {
    field,
    type: "string",
    headerAlign,
    align,
    sortable,
    editable,
    valueFormatter: (value) =>
      value != null && isFinite(value)
        ? `${prefix}${numbers.dpString(dp)(value * multiplier)}${suffix}`
        : "",
    valueSetter: makePrettyNumberValueSetter(field, {
      multiplier,
      extraValues,
    }),
    renderCell: cells.makeRenderCellWithTooltip(),
    ...colProps,
  };
};
