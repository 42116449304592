import * as userActionTypes from "../actions/user/userActionTypes";
import inputReducer from "./input/inputReducer";
import metaReducer from "./meta/metaReducer";
import peersReducer from "./peers/peersReducer";
import peopleReducer from "./people/peopleReducer";
import persistenceReducer from "./persistence/persistenceReducer";
import pricingReducer from "./pricing/pricingReducer";
import printReducer from "./print/printReducer";
import searchReducer from "./search/searchReducer";
import summaryReducer from "./summary/summaryReducer";
import maintenanceStatusReducer from "./temp/maintenance/maintenanceStatusReducer";
import tempReducer from "./temp/tempReducer";
import userReducer from "./user/userReducer";
import { combineReducers } from "redux";
import allApis from "services/all";
import SOVSlice from "store/SOV";
import analyticsSlice from "store/analytics";
import baseLayerBurnSlice from "store/baseLayerBurn";
import catExposuresSlice from "store/catExposures";
import currencySlice from "store/currencySlice";
import endorsementsSlice from "store/endorsements";
import layerDefaultsSlice from "store/layerDefaults";
import lossDevelopmentSlice from "store/lossDevelopment";
import modelDataSlice from "store/modelDataStore";
import notesSlice from "store/notes";
import programPricingSlice from "store/programPricing";
import rateChangeSlice from "store/rateChange";

const appReducer = combineReducers({
  meta: metaReducer,
  input: inputReducer,
  persistence: persistenceReducer,
  pricing: pricingReducer,
  search: searchReducer,
  peers: peersReducer,
  summary: summaryReducer,
  print: printReducer,
  temp: tempReducer,
  user: userReducer,
  maintenanceStatus: maintenanceStatusReducer,
  people: peopleReducer,

  analytics: analyticsSlice.reducer,
  baseLayerBurn: baseLayerBurnSlice.reducer,
  catExposures: catExposuresSlice.reducer,
  currency: currencySlice.reducer,
  endorsements: endorsementsSlice.reducer,
  layerDefaults: layerDefaultsSlice.reducer,
  lossDevelopment: lossDevelopmentSlice.reducer,
  programPricing: programPricingSlice.reducer,
  modelData: modelDataSlice.reducer,
  notes: notesSlice.reducer,
  rateChange: rateChangeSlice.reducer,
  SOV: SOVSlice.reducer,

  ...Object.fromEntries(allApis.map((x) => [x.reducerPath, x.reducer])),
});

const rootReducer = (state, action) => {
  if (action.type === userActionTypes.LOGOUT_USER) {
    return appReducer(undefined, action);
  } else {
    return appReducer(state, action);
  }
};

export default rootReducer;
