import Tooltip from "@mui/material/Tooltip";
import { useWhereAmIQuery } from "services/userService";

const FlagComponent = () => {
  const getFlagEmoji = (country) => {
    if (!country || country === "XX") return "";
    const codePoints = country
      .toUpperCase()
      .split("")
      .map((char) => 127397 + char.charCodeAt());
    return String.fromCodePoint(...codePoints);
  };

  const { data, isFetching } = useWhereAmIQuery();
  if (isFetching) return <></>;
  return (
    <Tooltip title={data?.ip ?? "Unknown"}>
      <div>{getFlagEmoji(data?.country)}</div>
    </Tooltip>
  );
};

export default FlagComponent;
