import { createSelector as _createSelector } from "reselect";

export const createSelector = (inputSelectors, outputSelector) => {
  return _createSelector(inputSelectors, outputSelector, {
    memoizeOptions: {
      maxSize: 10,
    },
  });
};

export const selectPriorState = (state) => state?.temp?.priorState?.state ?? {};

export const dynamicStateSelector = (path) => {
  const parts = path == null ? [] : path.split(".");
  return (state) => parts.reduce((state, part) => state?.[part], state);
};
