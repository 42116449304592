import { Alert, MenuItem, Select, Stack } from "@mui/material";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import RangeSelectionSpreadsheet from "components/common/RangeSelectionSpreadsheet";
import * as config from "config";
import { useCallback, useEffect, useState } from "react";
import * as ingestService from "services/ingestService";

const SelectRange = ({ transformStatus, applyTransform }) => {
  const { data: hints, isError } = ingestService.useRetrieveTransformHintsQuery(
    {
      ingestionId: transformStatus?.ingestionId,
      transformId: transformStatus?.transformId,
    },
    {
      skip: !transformStatus?.ingestionId || !transformStatus?.transformId,
    }
  );

  const [sheetName, _setSheetName] = useState(null);
  const [range, setRange] = useState({});

  const setSheetName = useCallback(
    (name) => {
      setRange({});
      _setSheetName(name);
    },
    [setRange, _setSheetName]
  );

  useEffect(() => {
    if (sheetName == null && hints?.sheetNames?.[0]) {
      setSheetName(hints?.sheetNames?.[0]);
    }
  }, [hints, sheetName, setSheetName]);

  if (isError) {
    return (
      <Alert severity={"error"}>
        {`Error parsing file. Please try another file or contact ${config.SUPPORT_EMAIL}.`}
      </Alert>
    );
  }
  if (!hints || transformStatus?.status === "running") {
    return <BigLoader />;
  }

  const rangeSelected = !!range?.topLeft && !!range?.bottomRight;

  return (
    <Stack direction={"column"} alignContent={"center"}>
      <Select
        value={sheetName ?? ""}
        onChange={(e) => setSheetName(e.target.value)}
      >
        {hints?.sheetNames?.map((name, i) => (
          <MenuItem key={i} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
      <RangeSelectionSpreadsheet
        data={hints?.values?.[sheetName] ?? []}
        range={range}
        setRange={setRange}
        sx={{ maxHeight: "40vh" }}
      />
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        sx={{
          marginTop: 2,
        }}
      >
        <Button
          isDisabled={!rangeSelected}
          onClick={() => {
            applyTransform({
              sheetName,
              topLeft: [range?.topLeft?.row, range?.topLeft?.column],
              bottomRight: [
                range?.bottomRight?.row,
                range?.bottomRight?.column,
              ],
            });
          }}
        >
          {"Use Selected Range"}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SelectRange;
