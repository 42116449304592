import * as chartUtils from "chartUtils";
import Component from "components/Component";
import { connect } from "react-redux";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Cell,
  Text,
  ReferenceLine,
} from "recharts";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

export const formatToolTip = (value) => utils.sfString(value);

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <Text
      x={x}
      y={y}
      width={75}
      textAnchor={"middle"}
      fill={"#313d5e"}
      verticalAnchor={"start"}
    >
      {payload.value}
    </Text>
  );
};

const red = "#FF634E";
const yellow = "#FFAB53";

const CustomToolTip = (props) => {
  const { payload, formatter } = props;

  const tooltip = {
    backgroundColor: "white",
    opacity: "1",
    border: "1px solid #CECECE",
    borderRadius: "4px",
    paddingLeft: "20px",
    paddingRight: "20px",
  };

  return (
    <div>
      <div className={"custom-tooltip"} style={tooltip}>
        {payload.map((item, i) => {
          return (
            <div key={i}>
              <p
                style={{
                  color: item.payload.isPeer ? yellow : red,
                }}
                key={i}
              >
                {item.payload.label}
                {":"} <strong>{"$" + formatter(item.payload.value)}</strong>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const PeerGroupChartComponent = (props) => {
  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  const percentTickFormatter = (value) => {
    return ((100 * value) / props.average).toFixed(0) + "%";
  };

  const toolTipFormatter = (value) => {
    return value.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    });
  };

  return (
    <Component
      title={props.title}
      subtitle={
        "The layers selected for peer comparison have been scaled by the ILF."
      }
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          paddingBottom: "500px",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          <ResponsiveContainer width={"100%"} height={"100%"}>
            <BarChart
              width={500}
              height={500}
              data={props.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 20,
              }}
              ref={chartRef}
            >
              <CartesianGrid strokeDasharray={"3 3"} />
              <XAxis
                dataKey={"label"}
                interval={0}
                angle={-45}
                textAnchor={"end"}
                tick={<CustomizedAxisTick />}
                height={80}
              />
              <YAxis
                label={{
                  value: "Scaled Premium",
                  angle: -90,
                  position: "left",
                  style: { textAnchor: "middle" },
                  fill: "#313d5e",
                }}
                domain={[() => 0, () => props.chartMaximum || 0]}
                tickFormatter={chartUtils.tickFormatter}
              />
              <YAxis
                label={{
                  value: "Comparison Price",
                  angle: 90,
                  position: "right",
                  style: { textAnchor: "middle" },
                  fill: "#313d5e",
                }}
                yAxisId={1}
                domain={[() => 0, () => props.chartMaximum || 0]}
                tickFormatter={percentTickFormatter}
                orientation={"right"}
              />
              <Tooltip
                position={tooltipPosition}
                isAnimationActive={false}
                content={({ payload, label }) => (
                  <CustomToolTip
                    payload={payload}
                    label={label}
                    formatter={toolTipFormatter}
                  />
                )}
              />
              <Legend />
              <Bar
                dataKey={"value"}
                fill={"#82ca9d"}
                legendType={"none"}
                name={"Gross Premium"}
              >
                {props.data.map((_, index) => (
                  <Cell key={index} fill={index === 0 ? red : yellow} />
                ))}
              </Bar>
              {props.average == null ? (
                <></>
              ) : (
                <ReferenceLine
                  y={props.average}
                  stroke={"#000000"}
                  label={{
                    value: `Average of Peers - $${chartUtils.tickFormatter(
                      props.average
                    )}`,
                    position: "insideBottom",
                    fill: "#000000",
                  }}
                  strokeWidth={4}
                  ifOverflow={"extendDomain"}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Component>
  );
};

const mapDispatchToProps = {};

export const mapStateToProps = (state) => {
  return {
    data: pricingSelectors.formatGrossPremiumChartData(state),
    layer: pricingSelectors.getActiveLayer(state),
    chartMaximum: pricingSelectors.getChartMaximum(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeerGroupChartComponent);
