import * as types from "../../../actions/input/attachments/attachmentsActionTypes";
import * as actions from "../../../actions/input/attachments/attachmentsActions";
import * as persistenceTypes from "../../../actions/persistence/persistenceActionTypes";
import * as tempActions from "../../../actions/temp/attachments/attachmentsTempActionTypes";
import _ from "lodash";

export const INIT_STATE = {
  cache: [],
  monitoringEmails: [],
  receivedEmails: [],
  requestingEmail: false,
  attachmentFileTrees: {},
};

const tempAttachmentsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case persistenceTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case tempActions.REQUESTED_ATTACHMENT_TREE:
      return {
        ...state,
        attachmentFileTrees: {
          ...state.attachmentFileTrees,
          [action.payload.fileId]: {
            ...(state.attachmentFileTrees[action.payload.fileId] || {}),
            status: "LOADING",
          },
        },
      };
    case tempActions.RECEIVED_ATTACHMENT_TREE:
      return {
        ...state,
        attachmentFileTrees: {
          ...state.attachmentFileTrees,
          [action.payload.fileId]: {
            ...action.payload.tree,
            status: "LOADED",
          },
        },
      };
    case types.CACHING_ATTACHMENT:
      return {
        ...state,
        cache: [
          ...(state.cache || []).filter(
            (item) => !_.isEqual(item.download, action.payload.download)
          ),
          { download: action.payload.download, url: null },
        ],
      };
    case types.CACHED_ATTACHMENT:
      return {
        ...state,
        cache: [
          ...(state.cache || []).filter(
            (item) => !_.isEqual(item.download, action.payload.download)
          ),
          action.payload,
        ],
      };
    case actions.newEmailAddress.returned.toString():
      return {
        ...state,
        monitoringEmails: [
          ...(state.monitoringEmails || []),
          { ...action.payload, fraction: 1 },
        ],
        requestingEmail: false,
      };
    case actions.newEmailAddress.requested.toString():
      return {
        ...state,
        requestingEmail: true,
      };
    case actions.monitorEmail.emailFound.toString(): {
      const monitoringEmails = (state.monitoringEmails || []).filter(
        (email) => email.target !== action.payload.target
      );
      if ((state.monitoringEmails || []).length === monitoringEmails.length) {
        return state;
      }
      return {
        ...state,
        monitoringEmails,
        receivedEmails: [
          ...(state.receivedEmails || []),
          action.payload.savedEmail.email,
        ],
      };
    }
    case actions.attachFromEmail.started.toString():
      return {
        ...state,
        receivedEmails: state.receivedEmails.map((email) => {
          if (email.target === action.payload.target) {
            return {
              ...email,
              attachments: email.attachments.map((attachment) => {
                if (attachment.filename === action.payload.filename) {
                  return {
                    ...attachment,
                    processing: true,
                  };
                }
                return attachment;
              }),
            };
          }
          return email;
        }),
      };
    case actions.attachFromEmail.finished.toString():
      return {
        ...state,
        receivedEmails: state.receivedEmails.map((email) => {
          if (email.target === action.payload.target) {
            return {
              ...email,
              attachments: email.attachments.map((attachment) => {
                if (attachment.filename === action.payload.filename) {
                  return {
                    ...attachment,
                    processing: false,
                    attached: true,
                  };
                }
                return attachment;
              }),
            };
          }
          return email;
        }),
      };
    case types.UPDATE_EMAIL_TIMER:
      return {
        ...state,
        monitoringEmails: state.monitoringEmails.map((email) => {
          if (email.target === action.payload.target) {
            return {
              address: email.address,
              target: email.target,
              fraction: action.payload.fraction,
              timeRemaining: action.payload.timeRemaining,
            };
          }
          return email;
        }),
      };
    case types.REMOVE_EXPIRED_EMAIL:
      return {
        ...state,
        monitoringEmails: state.monitoringEmails.filter(
          (email) => email.target !== action.payload
        ),
      };
    case types.OPEN_ATTACHMENT_DIALOG:
      return {
        ...state,
        selectingAttachment: true,
      };
    case types.CLOSE_ATTACHMENT_DIALOG:
      return {
        ...state,
        selectingAttachment: false,
      };
    case persistenceTypes.RENEW_SUBMISSION:
    case persistenceTypes.NEW_SUBMISSION:
      return INIT_STATE;
    default:
      return state;
  }
};

export default tempAttachmentsReducer;
