import moment from "moment";

export const resolvePaymentTerms = (paymentTerms, { fromDateString }) => {
  if (!paymentTerms) {
    return null;
  }

  if (paymentTerms.days != null) {
    const paymentTermsDueDateString = moment
      .parseZone(fromDateString)
      .add(paymentTerms.days, "day")
      .format("YYYY-MM-DD");
    return {
      ...paymentTerms,
      dueDate: paymentTermsDueDateString,
    };
  } else {
    return paymentTerms;
  }
};
