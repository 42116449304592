import SICColumnMapping from "./SICColumnMapping";
import SICValidation from "./SICValidation";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import DefaultLoader from "components/common/DefaultLoader";
import UsStatesSelector from "components/common/UsStatesSelector";
import SheetSelector from "components/common/files/SheetSelector";
import React from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/input/exposure/hazardActions";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

const ColumnMapper = () => {
  return (
    <form className={"form"} noValidate>
      <FormControl
        margin={"normal"}
        className={"sheet-select-form"}
        variant={"standard"}
        fullWidth
      >
        <SICColumnMapping />
      </FormControl>
    </form>
  );
};

const StateMapper = ({ onChange }) => (
  <form className={"form"} noValidate>
    <FormControl
      margin={"normal"}
      className={"sheet-select-form"}
      variant={"standard"}
      fullWidth
    >
      {"A state column has not been mapped so you must select a state:"}
      <UsStatesSelector onChange={onChange} />
    </FormControl>
  </form>
);

const Validator = () => (
  <form className={"form"} noValidate>
    <FormControl
      margin={"normal"}
      className={"sheet-select-form"}
      variant={"standard"}
      fullWidth
    >
      <SICValidation />
    </FormControl>
  </form>
);

const Content = ({
  step,
  columnsState,
  changeState,
  sheetSummaries,
  activeSheet,
  topLeft,
  sheetTopLeftSelected,
  setActiveSheet,
}) => {
  switch (step) {
    case 1:
      return <ColumnMapper columnsState={columnsState} />;
    case 2:
      return <StateMapper onChange={changeState} />;
    case 3:
    case 4:
      return <Validator />;
    case 0:
    default:
      return (
        <SheetSelector
          {...{
            name: "SIC Data",
            sheetSummaries,
            activeSheet,
            topLeft,
            sheetTopLeftSelected,
            setActiveSheet,
          }}
        />
      );
  }
};

const steps = [
  {
    name: "Select sheet",
    nextDisable: (props) => !props.sheetSummaries,
    prevDisable: true,
  },
  {
    name: "Map Columns",
    nextDisable: (props) => {
      const tst = (k) =>
        props.columnMapping?.[k] ||
        (props.columnMapping?.[k] === undefined &&
          props.columnMappingSuggestions?.[k]);

      return !(tst("_SIC") && tst("_PREMIUM"));
    },
    prevDisable: false,
  },
  {
    name: "Override State",
    nextDisable: (props) => !props.state,
    prevDisable: false,
  },
  {
    name: "Validate",
    nextDisable: () => true,
    prevDisable: true,
  },
  {
    name: "Complete",
    nextDisable: () => false,
    prevDisable: true,
  },
];

const SICFileUploadDialog = (props) => {
  const nextDisabled = steps[props.step || 0].nextDisable(props) || props.error;
  const prevDisabled = steps[props.step || 0].prevDisable || props.error;
  const nextStepClick = () => {
    if (props.step === 4) {
      props.hazardDialogClosed();
    } else {
      props.nextStep();
    }
  };
  return (
    <Dialog
      open={props.sheetsDialogIsOpen}
      aria-labelledby={"max-width-dialog-title"}
      maxWidth={"lg"}
      fullWidth
      className={"claims-file-upload-dialog"}
      onClose={(e, b) => {
        if (props.step === 4 || b !== "backdropClick") {
          props.hazardDialogClosed();
        }
      }}
    >
      <DialogTitle id={"max-width-dialog-title"}>{"SIC Data"}</DialogTitle>
      <DialogContent>
        {props.error ? (
          <Alert severity={"error"}>{props.error}</Alert>
        ) : props.sheetSummaries === undefined ? (
          <DefaultLoader
            color={"#dc7f4c"}
            style={{
              width: "200px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            size={60}
          />
        ) : (
          <>
            <Stepper activeStep={props.step}>
              {steps.map((stepEntry) => {
                return (
                  <Step key={stepEntry.name}>
                    <StepLabel>{stepEntry.name}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Content
              step={props.step}
              columnsState={props.columnsState}
              changeState={(stateId) => props.setHazardState(stateId)}
              sheetSummaries={props.sheetSummaries}
              activeSheet={props.activeSheet}
              topLeft={props.topLeft}
              sheetTopLeftSelected={props.sheetTopLeftSelected}
              setActiveSheet={(sheet) =>
                props.setActiveSheet(props.fileId, sheet)
              }
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.hazardDialogClosed}
          disableElevation
          variant={"contained"}
          className={"button-muted"}
          color={"secondary"}
          disabled={props.step === 4}
        >
          {"Cancel"}
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={props.prevStep}
          disableElevation
          variant={"contained"}
          color={"primary"}
          disabled={prevDisabled}
        >
          {"Prev"}
        </Button>
        <Button
          onClick={nextStepClick}
          disableElevation
          variant={"contained"}
          color={"primary"}
          disabled={nextDisabled}
        >
          {props.step === 4 ? "Close" : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  hazardDialogClosed: actions.hazardDialogClosed,
  nextStep: actions.nextStep,
  prevStep: actions.prevStep,
  setHazardState: actions.setHazardState,
  setActiveSheet: actions.sheetSelected,
  sheetTopLeftSelected: actions.sheetTopLeftSelected,
};

const mapStateToProps = (state) => {
  const activeFile = exposureSelectors.getActiveFile(state);
  return {
    ...(activeFile || {}),
    sheetsDialogIsOpen: exposureSelectors.getActiveFileValid(state),
    topLeft: activeFile?.topLeft || {
      row: 0,
      column: 0,
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SICFileUploadDialog);
