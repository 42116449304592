import * as aws from "common/aws";
import * as hashes from "common/hashes";
import * as logger from "common/logger";
import * as config from "config";
import * as FileSaver from "file-saver";
import * as utils from "utils";

export const spreadsheetMimeTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.oasis.opendocument.spreadsheet",
];
export const docMimeTypes = [
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.oasis.opendocument.text",
];
export const presMimeTypes = [
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
  "application/vnd.oasis.opendocument.presentation",
];
export const legacyMicrosoft = [
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
];
export const pdfMimeTypes = ["application/pdf"];
export const archMimeTypes = [
  "application/zip",
  "application/gzip",
  "application/x-tar",
];
export const tabularMimeTypes = [
  ...spreadsheetMimeTypes,
  "text/csv",
  "text/tsv",
  "text/psv",
];
export const rawViewableMimeTypes = [...pdfMimeTypes, "text/html"];
export const htmlViewableMimeTypes = [
  ...spreadsheetMimeTypes,
  ...docMimeTypes,
  ...presMimeTypes,
  ...legacyMicrosoft,
  "text/csv",
  "text/psv",
  "text/tsv",
  "text/plain",
  "text/base64",
  "text/xml",
];

export const encyptedMimeTypes = ["application/encrypted"];

const getUrl = (path) => config.endpoints.files(path);
export const getUploadUrlUrl = () => getUrl("get-upload-url");
export const getDownloadUrlUrl = (id) => getUrl(`get-download-url/${id}`);
export const notifyUrl = (id) => getUrl(`file-loaded/${id}`);
export const statusUrl = (id) => getUrl(`get-status/${id}`);
export const statusTreeUrl = (id) => getUrl(`get-status-tree/${id}`);
export const tableListUrl = (id) => getUrl(`table-list/${id}`);
export const subTableColumnList = () => getUrl("extract-subtable-columns");
export const subTableColumnMeta = () => getUrl("extract-subtable-columns-meta");
export const tableExtractUrl = () => getUrl("table-extract");
export const uploadFromS3 = () => getUrl("upload-from-s3");
export const cleanOfficeFileUrl = (id) => getUrl(`clean-office-file/${id}`);
export const uniqueColumnValuesUrl = () =>
  getUrl("extract-unique-column-values");

const TEXT_STATUS_COMPLETE = [
  "TEXT_FILE",
  "NOT_AVAILABLE",
  "LOADED",
  "NOT_READABLE",
  "CONVERSION_FAILED",
  "NOT_VALID",
];
const TEXT_STATUS_SUCCESS = ["TEXT_FILE", "LOADED"];
const STATUS_COMPLETE = [
  "LOADED",
  "NOT_VALID",
  "NOT_AVAILABLE",
  "NOT_READABLE",
];
export const fileReady = async (
  id,
  waitText,
  impersonateCompanyId = undefined,
  expectedMimeTypes = null
) => {
  const url = statusUrl(id);
  let meta = {};

  const ready = () => {
    const loadComplete = STATUS_COMPLETE.includes(meta.status);
    if (
      loadComplete &&
      expectedMimeTypes &&
      !expectedMimeTypes.includes(meta.mimeType)
    )
      return true;
    if (!waitText || !loadComplete) return loadComplete;
    if (meta.status !== "LOADED") return true;
    return TEXT_STATUS_COMPLETE.includes(meta.textStatus);
  };
  while (!ready()) {
    await new Promise((res) => setTimeout(res, 500));
    const resp = await utils.authenticatedFetch(
      url,
      undefined,
      impersonateCompanyId
    );
    meta = await resp.json();
  }
  return [
    meta.status === "LOADED",
    !waitText || TEXT_STATUS_SUCCESS.includes(meta.textStatus),
    meta.mimeType,
  ];
};

export const fileStatus = async (id) => {
  const resp = await utils.authenticatedFetch(statusUrl(id));
  return await resp.json();
};

export const fileStatusTree = async (id) => {
  const resp = await utils.authenticatedFetch(statusTreeUrl(id));
  return await resp.json();
};

export const createFileEntry = async (
  file,
  waitText = false,
  expectedMimeTypes = null,
  impersonateCompanyId = undefined
) => {
  const filename = file.name;
  const base64Sha256 = await hashes.hashFile(file, {
    algorithm: "SHA-256",
    format: "base64",
  });
  const body = {
    filename: filename,
    base64_sha256: base64Sha256,
  };
  const url = getUploadUrlUrl();
  try {
    const uploadResp = await utils.authenticatedFetch(
      url,
      {
        body: JSON.stringify(body),
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      },
      impersonateCompanyId
    );
    const uploadSpec = await uploadResp.json();
    await aws.uploadFile(file, uploadSpec.postingSpec);
    const noResponseRequired = () => {};
    utils
      .authenticatedFetch(
        notifyUrl(uploadSpec.id),
        undefined,
        impersonateCompanyId
      )
      .then(noResponseRequired);
    const [load_ok, text_ok, mimeType] = await fileReady(
      uploadSpec.id,
      waitText,
      impersonateCompanyId,
      expectedMimeTypes
    );
    if (!load_ok) throw Error("File load failed");
    if (expectedMimeTypes && !expectedMimeTypes.includes(mimeType))
      throw Error("Loaded file type is not as expected");
    if (!text_ok) throw Error("Conversion to text failed");
    return uploadSpec.id;
  } catch (error) {
    logger.exception(error, {
      breadcrumb: { file, base64Sha256, filename },
    });
    throw error;
  }
};

export const getDownloadUrl = async (id, impersonateCompanyId = undefined) => {
  const url = getDownloadUrlUrl(id);
  try {
    const resp = await utils.authenticatedFetch(
      url,
      undefined,
      impersonateCompanyId
    );
    const json = await resp.json();
    return await json.url;
  } catch (error) {
    logger.exception(error, {
      breadcrumb: { call: "getDownloadUrl", id },
    });
    throw error;
  }
};

export const saveFile = async (
  id,
  filename,
  impersonateCompanyId = undefined
) => {
  const url = await getDownloadUrl(id, impersonateCompanyId);
  const resp = await utils.plainFetch(url);
  const blob = await resp.blob();
  await FileSaver.saveAs(blob, filename);
};

export const getTableList = async (id) => {
  const url = tableListUrl(id);
  const resp = await utils.authenticatedFetch(url);
  return await resp.json();
};

export const getSubtableColumnList = async (extractionSpec) => {
  const body = {
    id: extractionSpec.fileId,
    sheet_name: extractionSpec.sheet.name,
    skip_rows: extractionSpec.skipRows,
    skip_columns: extractionSpec.skipColumns,
  };
  const url = subTableColumnList();
  const resp = await utils.authenticatedFetch(url, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};

export const getSubtableColumnMeta = async (extractionSpec) => {
  const body = {
    id: extractionSpec.fileId,
    sheet_name: extractionSpec.sheet.name,
    skip_rows: extractionSpec.skipRows,
    skip_columns: extractionSpec.skipColumns,
  };
  const url = subTableColumnMeta();
  const resp = await utils.authenticatedFetch(url, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};

export const getUniqueColumnValues = async (extractionSpec) => {
  const body = {
    id: extractionSpec.fileId,
    sheetName: extractionSpec.sheetName,
    skipRows: extractionSpec.skipRows,
    skipColumns: extractionSpec.skipColumns,
    columnName: extractionSpec.columnName,
    ignoreBlanks: extractionSpec.ignoreBlanks,
  };
  const url = uniqueColumnValuesUrl();
  const resp = await utils.authenticatedFetch(url, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};

export const extractTable = async (extractCriteria) => {
  const url = tableExtractUrl();
  const body = {
    id: extractCriteria.fileId,
    sheet_name: extractCriteria.sheetName,
    skip_rows: extractCriteria.skipRows,
    skip_columns: extractCriteria.skipColumns,
    mapping: extractCriteria.columnMapping,
  };
  const resp = await utils.authenticatedFetch(url, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};

export const uploadEmailFromFiles = async (listener, identifier, filename) => {
  const url = uploadFromS3();
  const body = {
    source_file_type: "EMAIL",
    email_request: {
      email_listener_identifier: listener,
      email_identifier: identifier,
      filename: filename,
    },
  };
  const resp = await utils.authenticatedFetch(url, {
    body: JSON.stringify(body),
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await resp.json();
};

export const cleanOfficeFile = async (fileId) => {
  try {
    const url = cleanOfficeFileUrl(fileId);
    const resp = await utils.authenticatedFetch(url);
    return await resp.json();
  } catch (error) {
    logger.exception(error, {
      breadcrumb: { call: "cleanOfficeFileUrl", fileId },
    });
    throw error;
  }
};

export const getViewTypeFromMime = (mime) => {
  if (!mime) return null;
  if (mime.startsWith("image/")) return "raw";
  if (rawViewableMimeTypes.includes(mime)) return "raw";
  if (htmlViewableMimeTypes.includes(mime)) return "html";
  return null;
};
