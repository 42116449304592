import * as utils from "./utils";
import { Alert, Grid, TextField } from "@mui/material";
import Component from "components/Component";
import DictionaryEditor from "components/admin/editors/DictionaryEditor";
import BigLoader from "components/common/BigLoader";
import Button from "components/common/Button";
import InlineSearch from "components/common/InlineSearch";
import * as config from "config";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as adminService from "services/adminService";
import * as userService from "services/userService";
import * as adminActions from "store/actions/temp/admin/adminActions";
import * as adminSelectors from "store/selectors/temp/admin/adminSelectors";

const formatLoadedValue = (value) => {
  return value == null || value?.componentOnStateVisibility == null
    ? value
    : {
        ...value,
        componentOnStateVisibility: Object.fromEntries(
          Object.entries(value?.componentOnStateVisibility).map(
            ([key, oldDict]) => {
              const newDict = {
                showOnState:
                  oldDict.showOnState && Object.entries(oldDict?.showOnState),
                hideOnState:
                  oldDict.hideOnState && Object.entries(oldDict?.hideOnState),
              };
              return [key, newDict];
            }
          )
        ),
      };
};

const UserConfigOverlay = ({
  saveUserLogMessage,
  hasUserLogMessage,
  userLogMessage,
}) => {
  const [newValue, setNewValue] = useState(null);
  const [searchText, setSearchText] = useState("");

  const { companyId } = useParams();

  const {
    currentData: userConfig,
    isLoading: isUserConfigLoading,
    isError: isUserConfigError,
    refetch: refetchUserConfig,
  } = userService.useRetrieveConfigQuery({ companyId }, { skip: !companyId });

  const formattedUserConfig = useMemo(() => formatLoadedValue(userConfig), [
    userConfig,
  ]);

  const [
    updateUserConfig,
    { isLoading: isUpdateRunning, isError: isUpdateError },
  ] = adminService.useUpdateUserConfigMutation();

  useEffect(() => {
    setNewValue(formattedUserConfig);
  }, [formattedUserConfig]);

  const setComponentVisibility = (component, visibility) => {
    setNewValue({
      ...newValue,
      componentVisibility: {
        ...newValue.componentVisibility,
        [component]: visibility,
      },
    });
  };

  const setComponentOnStateVisiblity = (
    component,
    type,
    index,
    key,
    values
  ) => {
    setNewValue({
      ...newValue,
      componentOnStateVisibility: {
        ...newValue.componentOnStateVisibility,
        [component]: {
          ...newValue?.componentOnStateVisibility?.[component],
          [type]: [
            ...(
              newValue.componentOnStateVisibility?.[component]?.[type] ?? []
            ).map(([oldKey, oldValues], i) =>
              i === index ? [key, values] : [oldKey, oldValues]
            ),
            ...(index >
            (newValue.componentOnStateVisibility?.[component]?.[type] ?? [])
              .length -
              1
              ? [[key, values]]
              : []),
          ].filter(
            ([key, values], i, arr) =>
              i === arr.length - 1 ||
              key.length > 0 ||
              (values.length > 0 && values[0].length > 0)
          ),
        },
      },
    });
  };

  const deleteComponentVisibility = (component) => {
    const componentVisibility = {
      ...newValue?.componentVisibility,
    };
    if (component in componentVisibility) {
      delete componentVisibility[component];
      setNewValue({
        ...newValue,
        componentVisibility,
      });
    }
  };

  return (
    <Component
      title={"Component Visibility"}
      spacing={config.GRID_SPACING}
      options={
        <InlineSearch
          placeholder={"Quick Filter"}
          onChange={(searchText) => setSearchText(searchText.toLowerCase())}
        />
      }
    >
      {isUserConfigLoading || isUpdateRunning ? (
        <BigLoader />
      ) : isUserConfigError ? (
        <Alert severity={"error"}>{"Failed to load."}</Alert>
      ) : !!formattedUserConfig ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DictionaryEditor
              dict={newValue?.componentVisibility ?? {}}
              stateVisibilities={newValue?.componentOnStateVisibility}
              keyName={"Component"}
              valueName={"Is Visible?"}
              onEditKey={setComponentVisibility}
              onEditStateVisibility={setComponentOnStateVisiblity}
              onDeleteKey={deleteComponentVisibility}
              searchText={searchText}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={10}>
              <TextField
                variant={"filled"}
                xs={10}
                label={"Reason for change"}
                fullWidth
                onChange={(e) => saveUserLogMessage(e.target.value)}
                value={userLogMessage}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={async () => {
                  await updateUserConfig({
                    companyId,
                    userLogMessage,
                    value: utils.formatSavedValue(newValue),
                  });
                  refetchUserConfig();
                }}
                disabled={!newValue || isUpdateRunning || !hasUserLogMessage}
                isLoading={isUpdateRunning}
                hasError={isUpdateError}
                errorTooltip={"Failed to save"}
              >
                {"Save"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogMessage: adminSelectors.userLogMessage(state),
    hasUserLogMessage: adminSelectors.hasUserLogMessage(state),
  };
};

const mapDispatchToProps = {
  saveUserLogMessage: adminActions.userLogMessage.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserConfigOverlay);
