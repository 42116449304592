import * as support from "./RateChange.support";
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import Component from "components/Component";
import ChangeArrow from "components/common/ChangeArrow";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";
import * as programSelectors from "store/selectors/input/program/programSelectors";

export const getGoodBad = (valueStr) => {
  if (valueStr == null) {
    return "";
  }
  const value = parseFloat(valueStr);
  if (isNaN(value)) {
    return "";
  }
  if (value === 0) {
    return "";
  }
  return value < 0 ? "good" : "bad";
};

const YearOnYearExposures = ({
  inceptionYear,
  rateChange,
  activeLayerRateChange,
  updateLayerRateChange,
  updateRateChange,
}) => {
  const exposureChanges = support.useActiveLayerExposureChanges();

  return (
    <Component
      title={"Exposure Change"}
      options={
        <FormControlLabel
          control={
            <Switch
              checked={rateChange?.usePriorYearExposuresFromCurrentSubmission}
              onChange={(e) =>
                updateRateChange({
                  usePriorYearExposuresFromCurrentSubmission: e.target.checked,
                })
              }
            />
          }
          label={"Use Renewed"}
          labelPlacement={"start"}
        />
      }
      xs_width={12}
    >
      <TableContainer>
        <Table className={"exposure-inputs"}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{"Measure"}</TableCell>
              <TableCell align={"right"}>{"Unit"}</TableCell>
              <TableCell align={"right"}>
                {`Renewed (${inceptionYear})`}
              </TableCell>
              <TableCell className={"arrow-column"} />
              <TableCell align={"right"}>
                {exposureChanges?.usePriorYearExposuresFromCurrentSubmission
                  ? "Renewed"
                  : "Expired"}
                {` (${inceptionYear - 1})`}
              </TableCell>
              <TableCell align={"right"}>{"Rate Impact Override(%)"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exposureChanges?.components?.map((component) => {
              return (
                <TableRow key={component.key}>
                  <TableCell>
                    <Checkbox
                      checked={component.selected ?? false}
                      onClick={() =>
                        updateLayerRateChange({
                          layerId: activeLayerRateChange.layerId,
                          field: "selectedExposures",
                          value: {
                            ...activeLayerRateChange?.selectedExposures,
                            [component.key]: !(component?.selected ?? false),
                          },
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>{component?.exposure?.name}</TableCell>
                  <TableCell align={"right"}>
                    {component?.exposure?.units}
                  </TableCell>
                  <TableCell align={"right"}>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={component.currentValue}
                    />
                  </TableCell>
                  <TableCell className={"arrow-column"}>
                    <ChangeArrow
                      currentValue={component.currentValue}
                      priorValue={component.priorValue}
                      invertColours
                    />
                  </TableCell>
                  <TableCell align={"right"}>
                    <NumberFormat
                      displayType={"text"}
                      thousandSeparator={true}
                      value={component.priorValue}
                    />
                  </TableCell>
                  <TableCell align={"right"}>
                    <PrettyNumberTextField
                      fullWidth
                      type={"text"}
                      hiddenLabel
                      size={"small"}
                      variant={"filled"}
                      inputProps={{ style: { textAlign: "right" } }}
                      onChangeNumber={(x) => {
                        updateLayerRateChange({
                          layerId: activeLayerRateChange.layerId,
                          field: "exposureOverrides",
                          value: {
                            ...activeLayerRateChange?.exposureOverrides,
                            [component.key]: x,
                          },
                        });
                      }}
                      value={
                        activeLayerRateChange.exposureOverrides?.[
                          component.key
                        ] ?? null
                      }
                      className={getGoodBad(
                        activeLayerRateChange.exposureOverrides?.[
                          component.key
                        ] ?? null
                      )}
                    />
                  </TableCell>
                </TableRow>
              );
            })}

            <TableRow>
              <TableCell />
              <TableCell>{"Total"}</TableCell>
              <TableCell align={"right"}>{"%"}</TableCell>
              <TableCell align={"right"} />
              <TableCell className={"arrow-column"}>
                <ChangeArrow
                  currentValue={exposureChanges?.totalRatio}
                  invertColours
                  priorValue={1}
                />
              </TableCell>
              <TableCell align={"right"} />
              <TableCell align={"right"}>
                <PrettyNumberTextField
                  fullWidth
                  type={"text"}
                  hiddenLabel
                  size={"small"}
                  variant={"filled"}
                  inputProps={{ style: { textAlign: "right" } }}
                  onChangeNumber={(x) => {
                    updateLayerRateChange({
                      exposureOverrides: {
                        ...activeLayerRateChange?.exposureOverrides,
                        TOTAL: x,
                      },
                    });
                  }}
                  allowClear
                  value={
                    activeLayerRateChange?.exposureOverrides?.["TOTAL"] ?? null
                  }
                  className={getGoodBad(
                    activeLayerRateChange?.exposureOverrides?.["TOTAL"]
                  )}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
};

const mapDispatchToProps = {
  updateRateChange: rateChangeSlice.updateRateChangeState,
  updateLayerRateChange: rateChangeSlice.updateLayerProperties,
};

const mapStateToProps = (state) => ({
  inceptionYear: programSelectors.getInceptionYear(state),
  rateChange: rateChangeSlice.selectRateChange(state),
  activeLayerRateChange: rateChangeSlice.selectActiveLayerRateChange(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YearOnYearExposures);
