import * as strings from "../../common/strings";
import * as utils from "../../utils";
import Grid from "@mui/material/Grid";
import React from "react";

const PrettyInceptionDate = ({ date }) => {
  const formattedDate = utils.formatDate(date);
  const yearStr = formattedDate.slice(-4);
  const prettyDayAndMonth = formattedDate.slice(0, -4);
  const isCurrentYear =
    new Date().getFullYear() === new Date(date).getFullYear();
  return (
    <div className={"inception"}>
      {prettyDayAndMonth}
      <span className={isCurrentYear ? "inception-current-year" : ""}>
        {yearStr}
      </span>
    </div>
  );
};

const SubmissionSummary = ({ program }) => {
  return (
    <div className={"submission-summary"}>
      <Grid container>
        <Grid item container xs={6}>
          <Grid item xs={12}>
            <div className={"insured"}>
              {program?.insured ?? "Unknown Submission"}
            </div>
          </Grid>
          <Grid item xs={12}>
            <PrettyInceptionDate date={program?.inception} />
          </Grid>
        </Grid>
        <Grid item xs={6} className={"risk-description"}>
          {strings.truncateStringWithEllipsis(
            program?.riskDescription || program?.riskdescription,
            { maxLength: 100 }
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SubmissionSummary;
