import { Box } from "@mui/material";
import Metrics from "components/common/Metrics";

const SovMetrics = ({ locations }) => {
  const metrics = [
    {
      label: "Location Count",
      value: locations?.length ?? 0,
      dp: 0,
    },
    {
      label: "Total TIV",
      value:
        locations
          ?.map(({ _TIV }) => _TIV)
          ?.reduce((acc, curr) => acc + curr, 0) ?? null,
      dp: 0,
      prefix: "$",
    },
    {
      label: "Total BI",
      value:
        locations
          ?.map(({ _BI }) => _BI)
          ?.reduce((acc, curr) => acc + curr, 0) ?? null,
      dp: 0,
      prefix: "$",
    },
  ];

  return (
    <Box
      sx={{
        display: "inline-block",
        margin: "0.5rem 1rem",
        padding: "0.875rem 0.375rem",
        background: "#edf0f3",
        borderRadius: "0.625rem",
      }}
    >
      <Metrics metrics={metrics} />
    </Box>
  );
};

export default SovMetrics;
