import * as config from "../../../config";
import * as utils from "../../../utils";
import { zip, shortLayerName, roundUpNice } from "../../../utils";
import * as peersSelector from "../../selectors/peers/peersSelectors";
import * as persistenceActions from "../persistence/persistenceActions";
import * as types from "./peersActionTypes";
import * as actions from "./peersActions";
import * as Sentry from "@sentry/react";

export const updateChosenExposureMetric = (metric) => ({
  type: types.UPDATE_CHOSEN_PEER_EXPOSURE_METRIC,
  payload: metric,
});

export const updateChosenExposureMetric2 = (metric) => ({
  type: types.UPDATE_CHOSEN_PEER_EXPOSURE_METRIC_2,
  payload: metric,
});

export const receivedSubmission = (data) => ({
  type: types.LOAD_PEER,
  payload: persistenceActions.upgradeState(data),
});

export const updatePricingSelection = (peerFilename, layerIndex, weighting) => (
  dispatch,
  getState
) => {
  dispatch({
    type: types.UPDATE_SELECTED_PEERS,
    payload: {
      filename: peerFilename,
      layerIndex,
      weighting,
    },
  });
  dispatch({
    type: types.RECALCULATE_PEER_PRICING,
    payload: peersSelector.getPeerPricingRequest(getState()),
  });
};

export const updatePeerExposure = (chosenMetric) => (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_PEER_CHOSEN_EXPOSURE_METRIC,
    payload: chosenMetric,
  });
  dispatch({
    type: types.RECALCULATE_PEER_PRICING,
    payload: peersSelector.getPeerPricingRequest(getState()),
  });
};

export const failedToLoadSubmission = (filename, error) => {
  Sentry.addBreadcrumb(`Failed to load: ${filename}`);
  Sentry.captureException(error);
  return {
    type: types.LOAD_PEER_FAILED,
    payload: filename,
  };
};

export const loadPeer = (peer) => (dispatch) => {
  dispatch({
    type: types.ADD_PEER,
    payload: peer,
  });
  utils
    .authenticatedFetch(
      config.endpoints.persistence(
        "get?" + new URLSearchParams({ filename: peer.filename || peer.guid })
      )
    )
    .then((result) => {
      result
        .json()
        .then((data) => {
          dispatch(actions.receivedSubmission(data));
        })
        .catch((error) => {
          dispatch(actions.failedToLoadSubmission(peer.filename, error));
        });
    })
    .catch((error) => {
      dispatch(actions.failedToLoadSubmission(peer.filename, error));
    });
};

export const deletePeer = (filename) => ({
  type: types.DELETE_PEER,
  payload: filename,
});

export const updatePeersNote = (note) => ({
  type: types.UPDATE_PEERS_NOTE,
  payload: note,
});

export const recalculatePeers = (request) => ({
  type: types.RECALCULATE_PEER_PRICING,
  payload: request,
});

const createBaseLayer = (layer, peers) => {
  return {
    title: shortLayerName(layer.terms),
    data: [
      {
        label: `${layer.insured} - ${shortLayerName(layer.terms)}`,
        value: layer.premium,
        isPeer: false,
      },
      ...peers.map((peer) => ({
        label: `${peer.insured} - ${shortLayerName(peer.terms)}`,
        value: 0,
        isPeer: true,
      })),
    ],
    chartMaximum: layer.premium,
  };
};

export const calculateChartMaximum = (values, average, premium) => {
  const validValues = [...(values || []), average, premium].filter(
    (value) => !Number.isNaN(value)
  );
  return roundUpNice(Math.max(...validValues));
};

const blendBaseAndValue = (base, values, average) => {
  return values == null
    ? base
    : {
        ...base,
        chartMaximum: calculateChartMaximum(values, average, base.chartMaximum),
        average,
        data: [
          base.data[0],
          ...zip(base.data.slice(1), values).map(([row, value]) => ({
            ...row,
            value,
          })),
        ],
      };
};

export const receivedPeerPricing = (data) => {
  const bases = data.request.terms.map((layer) =>
    createBaseLayer(layer, data.request.peers)
  );
  const payload = {
    group: zip(bases, data.layers).map(([base, layer]) => {
      return blendBaseAndValue(
        base,
        layer.adjustedPremium,
        layer.impliedPremium
      );
    }),
    exposure: zip(bases, data.layers).map(([base, layer]) =>
      blendBaseAndValue(
        base,
        layer.exposureAdjustedPremium,
        layer.exposureImpliedPremium
      )
    ),
  };
  return {
    type: types.PEER_PRICING_RECEIVED,
    payload,
  };
};

export const updateIndustryClass = (industryCategory) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PEERS_INDUSTRY_CLASS,
    payload: industryCategory,
  });
};

export const peerPricingFailed = () => ({ type: types.PEER_PRICING_FAILED });
export const updatingPeerPricing = () => ({
  type: types.PEER_PRICING_UPDATING,
});
