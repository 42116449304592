export class PricingAssumptionsTableSupport {
  constructor(configs, defaults, values, setValue, newVals, setNewVals) {
    this.configs = configs;
    this.defaults = defaults;
    this.values = values;
    this.setValue = setValue;
    this.newVals = newVals;
    this.setNewVals = setNewVals;
  }

  formatter(decimals, percentage, withGrouping) {
    const dec = decimals ? decimals : 0;
    return Intl.NumberFormat("en-US", {
      style: percentage ? "percent" : "decimal",
      minimumFractionDigits: dec,
      maximumFractionDigits: dec,
      useGrouping: withGrouping,
    });
  }

  format(conf, val) {
    if (conf.base_type === "number") {
      if (!val && val !== 0) return "";
      const percent = conf.display_type === "percentage";
      return this.formatter(conf.decimals, percent, true).format(val);
    } else {
      return val;
    }
  }

  formatDefault(conf) {
    return this.format(conf, this.defaults[conf.code]);
  }
  formatCurrent(conf) {
    return this.format(conf, this.values[conf.code]);
  }

  formatForInput(conf, source) {
    const val = source[conf.code];
    if (!val && val !== 0) return "";
    const number = conf.base_type === "number";
    const percent = number && conf.display_type === "percentage";
    if (percent)
      return this.formatter(conf.decimals, false, false).format(val * 100);
    if (number) return this.formatter(conf.decimals, false, false).format(val);
    return val;
  }

  saveIfNeeded(config) {
    const toNum = (strVal) =>
      strVal || strVal === "0" ? Number(strVal.replaceAll(",", "")) : null;
    const toNumDiv100 = (strVal) =>
      strVal || strVal === "0"
        ? Number(strVal.replaceAll(",", "")) / 100
        : null;

    const rawNv = this.newVals[config.code];
    const nv =
      config.base_type === "number"
        ? config.display_type === "percentage"
          ? toNumDiv100(rawNv)
          : toNum(rawNv)
        : rawNv;
    const ov = this.values[config.code];
    const needChange = (nv || nv === 0) && nv !== ov;
    if (needChange) this.setValue(config.code, nv);
  }

  store() {
    this.configs.forEach((conf) => this.saveIfNeeded(conf));
    this.setNewVals({});
  }

  setDefault() {
    const newVs = {};
    this.configs.forEach((config) => {
      newVs[config.code] = this.formatForInput(config, this.defaults);
    });
    this.setNewVals(newVs);
  }

  clear() {
    this.setNewVals({});
  }

  onChange(e, assumption) {
    this.setNewVals({ ...this.newVals, [assumption.code]: e.target.value });
  }
}
