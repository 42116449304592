import { getIconFromMime } from "../inputs/attachments/FileListTreeSupport";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/SendRounded";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Component from "components/Component";
import Button from "components/common/Button";
import IconButton from "components/common/IconButton";
import Notes from "components/common/Notes";
import Options from "components/common/Options";
import SmallLoader from "components/common/SmallLoader";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as filesService from "services/filesService";
import * as attachmentsSelectors from "store/selectors/input/attachments/attachmentsSelectors";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as notesSelectors from "store/selectors/notes/notesSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

const SUBSECTION_MARGIN_LEFT = "2rem";

const Section = ({ label, checked, indeterminate, onChange, sx, children }) => {
  return (
    <Box sx={sx}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            checked={checked}
            indeterminate={indeterminate}
            disabled={indeterminate}
            onChange={(e) => onChange(e.target.checked)}
          />
        }
      />
      {children}
    </Box>
  );
};

const AttachedFileToggle = ({ fileId, isSelected, onClick }) => {
  const {
    data: meta,
    status: metaStatus,
  } = filesService.useGetFileMetadataQuery(fileId);

  const Icon = metaStatus === "fulfilled" ? getIconFromMime(meta.mime) : <></>;
  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        transition: "200ms",
        opacity: isSelected ? 1 : 0.35,
        "&:hover": {
          background: "#feefe6",
          borderRadius: "6px",
        },
      }}
      onClick={onClick}
    >
      <Box style={{ display: "flex", alignItems: "center" }}>
        {meta != null ? (
          <>
            <Box sx={{ ml: 0.5, mr: 0.75 }}>
              <Icon />
            </Box>
            <Typography>{meta.originalFilename}</Typography>
          </>
        ) : (
          <SmallLoader />
        )}
      </Box>
      <Checkbox checked={isSelected} />
    </Box>
  );
};

const TransferConfirmationForm = ({
  view,
  setView,
  recipient,
  onTransfer,
  onClose,
}) => {
  const program = useSelector(programSelectors.selectResolvedProgram);
  const layers = useSelector(pricingSelectors.selectResolvedLayers);
  const attachedFiles = useSelector(attachmentsSelectors.getAllAttachedFiles);
  const notesConfig = useSelector(notesSelectors.selectNotesConfig);
  const notes = useSelector(notesSelectors.selectNotes);

  const nonEmptyNotesConfig = notesConfig.filter(
    (n) =>
      (notes?.[n.id] || "").trim() !== "" && !["riskSummary"].includes(n.id)
  );

  const allLayers = (layers ?? []).map((l) => l.id);
  const allNotes = nonEmptyNotesConfig.map((n) => n.id);
  const allAttachedFiles = attachedFiles;

  useEffect(() => {
    if (view == null) {
      setView({
        riskSummary: program.riskSummary,
        includeClaims: true,
        includeExposures: true,
        includeExposureComments: true,
        includedLayers: allLayers,
        includeTower: true,
        includedNotes: [],
        includedAttachedFiles: allAttachedFiles,
      });
    }
  }, [view, setView, program.riskSummary, allLayers, allAttachedFiles]);

  const [includeLayers, setIncludeLayers] = useState(true);
  const [includeNotes, setIncludeNotes] = useState(false);
  const [includeFiles, setIncludeFiles] = useState(true);

  const hasLayers =
    (layers ?? []).length > 1 ||
    ((layers ?? []).length === 1 && layers[0].limit != null);
  const hasNotes = allNotes.length > 0;
  const hasFiles = attachedFiles.length > 0;
  const allFilesSelected =
    view?.includedAttachedFiles?.length === allAttachedFiles.length;

  const update = (key) => (value) =>
    setView({
      ...view,
      [key]: value,
    });

  const toggleItem = (key) => ({ id }) => {
    const isSelected = (view[key] ?? []).includes(id);
    const others = (view[key] ?? []).filter((x) => x !== id);
    update(key)(!isSelected ? [...others, id] : others);
  };

  if (view == null) {
    return <></>;
  }

  return (
    <Dialog open={true} onClose={() => {}} fullWidth maxWidth={"md"}>
      <DialogTitle>
        {"To: "}
        {recipient.name}
        <IconButton
          icon={CloseIcon}
          aria-label={"close"}
          onClick={onClose}
          sx={{ position: "absolute", right: 16, top: 14 }}
        />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={4}>
          <Stack>
            <Notes
              title={"Risk Summary"}
              hasEditButton={true}
              value={view.riskSummary}
              update={update("riskSummary")}
            />
            <Typography variant={"caption"} mt={1} ml={1}>
              {
                "This text is only visible to the receiving party and can be different to the risk summary on the submission"
              }
            </Typography>
          </Stack>

          <Component
            title={"Data"}
            subtitle={"Select the items you'd like to send to the other party"}
          >
            <Section
              label={"Claims"}
              checked={view.includeClaims}
              onChange={(checked) => {
                update("includeClaims")(checked);
              }}
            />

            <Section
              label={"Exposures"}
              checked={view.includeExposures}
              onChange={(checked) =>
                setView({
                  ...view,
                  includeExposures: checked,
                  includeExposureComments: checked,
                })
              }
            />

            {view.includeExposures && (
              <Section
                label={"Include comments?"}
                checked={view.includeExposureComments}
                onChange={(checked) => {
                  update("includeExposureComments")(checked);
                }}
                sx={{ marginLeft: SUBSECTION_MARGIN_LEFT }}
              />
            )}

            <Section
              label={"Tower"}
              checked={view.includeTower}
              onChange={(checked) => {
                update("includeTower")(checked);
              }}
            />

            <Section
              label={"Layers"}
              checked={includeLayers}
              onChange={() => {
                update("includedLayers")(includeLayers ? [] : allLayers);
                setIncludeLayers(!includeLayers);
              }}
              indeterminate={!hasLayers}
            >
              {!hasLayers && (
                <Alert
                  severity={"info"}
                  color={"warning"}
                  sx={{ marginLeft: SUBSECTION_MARGIN_LEFT }}
                >
                  {"No layers have been added to this deal at present"}
                </Alert>
              )}
              {hasLayers && includeLayers && (
                <Box sx={{ marginLeft: SUBSECTION_MARGIN_LEFT }}>
                  <Options
                    items={layers.map((layer) => ({
                      id: layer.id,
                      label: utils.shortLayerName(layer),
                      selected: view.includedLayers.includes(layer.id),
                    }))}
                    onClick={toggleItem("includedLayers")}
                    onToggleAll={(allSelected) => {
                      update("includedLayers")(allSelected ? [] : allLayers);
                    }}
                  />
                </Box>
              )}
            </Section>

            <Section
              label={"Notes"}
              checked={includeNotes}
              onChange={() => {
                update("includedNotes")(includeNotes ? [] : allNotes);
                setIncludeNotes(!includeNotes);
              }}
              indeterminate={!hasNotes}
            >
              {!hasNotes && (
                <Alert
                  severity={"info"}
                  color={"warning"}
                  sx={{ marginLeft: SUBSECTION_MARGIN_LEFT }}
                >
                  {"No notes have been added to this deal at present"}
                </Alert>
              )}
              {hasNotes && includeNotes && (
                <Box sx={{ marginLeft: SUBSECTION_MARGIN_LEFT }}>
                  <Options
                    items={nonEmptyNotesConfig.map((note) => ({
                      ...note,
                      selected: view.includedNotes.includes(note.id),
                    }))}
                    onClick={toggleItem("includedNotes")}
                    onToggleAll={(allSelected) => {
                      update("includedNotes")(allSelected ? [] : allNotes);
                    }}
                  />
                </Box>
              )}
            </Section>

            <Section
              label={"Files"}
              checked={includeFiles}
              onChange={() => {
                setIncludeFiles(!includeFiles);
              }}
              indeterminate={!hasFiles}
            >
              {!hasFiles && (
                <Alert
                  severity={"info"}
                  color={"warning"}
                  sx={{ marginLeft: SUBSECTION_MARGIN_LEFT }}
                >
                  {"No files are attached to this deal at present"}
                </Alert>
              )}
              {hasFiles && includeFiles && (
                <Stack
                  alignItems={"flex-start"}
                  spacing={0.5}
                  sx={{ paddingLeft: SUBSECTION_MARGIN_LEFT }}
                >
                  <Link
                    component={"button"}
                    variant={"body2"}
                    color={"secondary"}
                    onClick={() => {
                      update("includedAttachedFiles")(
                        allFilesSelected ? [] : allAttachedFiles
                      );
                    }}
                  >
                    {allFilesSelected ? "Select None" : "Select All"}
                  </Link>
                  <Box fullWidth sx={{ width: "100%" }}>
                    {attachedFiles.map((id) => (
                      <AttachedFileToggle
                        key={id}
                        fileId={id}
                        isSelected={view.includedAttachedFiles.includes(id)}
                        onClick={() =>
                          toggleItem("includedAttachedFiles")({ id })
                        }
                      />
                    ))}
                  </Box>
                </Stack>
              )}
            </Section>
          </Component>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onTransfer}
          startIcon={<SendIcon />}
          sx={{ margin: "4px 16px 2px 0" }}
        >
          {"Send"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferConfirmationForm;
