import * as config from "../../../config";
import * as utils from "../../../utils";
import * as types from "./companyActionTypes";
import * as actions from "./companyActions";
import * as logger from "common/logger";

export const codesReturned = () => ({
  type: types.HAS_CODE_CATEGORY_VALUES,
  payload: true,
});

export const checkCategoryCodes = () => (dispatch) => {
  utils
    .authenticatedFetch(
      config.endpoints.claims(
        "pricing/classifications/query?" + new URLSearchParams({ q: "" })
      )
    )
    .then((result) => {
      result.json().then((data) => {
        dispatch(actions.codesReturned());
      });
    })
    .catch((error) => {
      logger.exception(error);
    });
};
