import Component from "components/Component";
import Options from "components/common/Options";

const roundNumber = (value) => {
  return (value / 1e6).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};

const shortLayerName = (layer) => {
  return `${roundNumber(layer.limit)}x${roundNumber(
    layer.attachment
  )} ($${roundNumber(layer.grossPremium)}m)`;
};

const LayerSelector = ({
  title,
  subtitle,
  loading,
  layers,
  selectedId,
  onSelectId,
}) => {
  const options = (layers ?? []).map((layer) => ({
    id: layer.id,
    label: shortLayerName(layer),
    selected: selectedId === layer.id,
  }));

  return (
    <Component title={title} subtitle={subtitle} loading={loading}>
      <Options
        items={options}
        onClick={({ id }) => {
          onSelectId(selectedId === id ? null : id);
        }}
        variant={"button"}
      />
    </Component>
  );
};

export default LayerSelector;
