import Disabled from "components/common/Disabled";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { Fragment } from "react";

const NumberInput = ({ definition, value, setValue }) => {
  return (
    <Disabled ifReadOnly>
      <PrettyNumberTextField
        label={definition.label}
        InputLabelProps={{ shrink: true }}
        InputProps={{ spellCheck: "false" }}
        inputProps={{ style: { textAlign: "right" } }}
        size={"small"}
        variant={"filled"}
        sx={{ maxWidth: 200 }}
        value={value ?? ""}
        onChangeNumber={setValue}
      />
    </Disabled>
  );
};

const makeExtraInputComponent = (type) => {
  switch (type) {
    case "number":
      return NumberInput;
    default:
      return Fragment;
  }
};

const ExtraInput = ({ definition, value, setValue }) => {
  const ExtraInputComponent = makeExtraInputComponent(definition?.type);
  return (
    <ExtraInputComponent
      definition={definition}
      value={value}
      setValue={setValue}
    />
  );
};

export default ExtraInput;
