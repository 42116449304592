import SmallLoader from "../../common/SmallLoader";
import CentredLoader from "./CentredLoader";
import { Alert, Tab, Tabs, Tooltip } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import classnames from "classnames";
import SheetPreviewCell from "components/inputs/claims/SheetPreviewCell";
import { useEffect, useMemo, useState } from "react";
import Spreadsheet from "react-spreadsheet";
import * as tablesService from "services/tablesService";
import { useGetTableMetadataQuery } from "services/tablesService";
import { formatDate } from "utils";

const PageTab = ({ fileId, tableName, ...props }) => {
  const {
    data: tableMeta,
    status: tableMetaStatus,
  } = useGetTableMetadataQuery({ fileId, tableName });
  let tabLabel = "";
  let tabIcon = "";
  if (tableMetaStatus === "fulfilled") {
    if (tableMeta?.status === "LOADED") {
      tabLabel = `${tableName} (${tableMeta?.rows} rows)`;
    } else {
      tabLabel = `${tableName} - FAILED: ${
        tableMeta.message ? tableMeta.message : ""
      }`;
    }
  } else {
    tabLabel = `${tableName}`;
    tabIcon = <SmallLoader />;
  }
  return (
    <Tab
      {...props}
      key={tableName}
      label={tabLabel}
      icon={tabIcon}
      iconPosition={"end"}
      sx={{
        fontSize: "13px",
        borderTopLeftRadius: "0.5rem",
        borderTopRightRadius: "0.5rem",
        borderRight: "0.5px solid gainsboro",
        background: "#f5f5f5",
        "&.Mui-selected": { background: "white !important" },
      }}
    />
  );
};

export const formatCellValue = (cell) => {
  if (cell == null) {
    return { value: "", type: "blank" };
  }
  const { value, type } = cell;
  if (type === "str") {
    return {
      displayValue: value.length > 20 ? value.substring(0, 17) + "..." : value,
      tooltip: value.length > 20 ? value : undefined,
      value,
      type,
    };
  }
  if (type === "date") {
    return {
      displayValue: formatDate(new Date(value)),
      value,
      type,
    };
  }
  return cell;
};

const SheetSelector = ({
  fileId,
  tableName,
  origin,
  setTableName,
  setOrigin,
  setTablesList,
}) => {
  const {
    data: sheetNames,
    status: sheetNamesStatus,
  } = tablesService.useGetTableListQuery(fileId, { skip: !fileId });

  const [tableCheckList, setTableCheckList] = useState(null);
  useEffect(() => {
    if (sheetNamesStatus === "fulfilled") {
      const sheetList = Object.entries(sheetNames.tables)
        .map(([sheet, { sheetIndex }]) => ({ sheet, sheetIndex }))
        .sort((a, b) => a.sheetIndex ?? 0 - b.sheetIndex ?? 0)
        .map((e) => e.sheet);
      if (
        JSON.stringify(tableCheckList || []) !== JSON.stringify(sheetList || [])
      ) {
        setTablesList(sheetList);
        setTableCheckList(sheetList);
      }
    }
  }, [sheetNames, sheetNamesStatus, setTablesList, tableCheckList]);
  const {
    data: page,
    status: pageStatus,
  } = tablesService.useGetTableObjectsPageQuery(
    { fileId: fileId, tableName: tableName },
    { skip: !fileId || !tableName }
  );

  const tableNames = useMemo(
    () =>
      Object.entries(sheetNames?.tables ?? {})
        .sort(([, a], [, b]) => (a?.sheetIndex ?? 0) - (b?.sheetIndex ?? 0))
        .map(([tableName]) => tableName),
    [sheetNames]
  );

  const [tableIndex, setTableIndex] = useState(0);
  useEffect(() => {
    if ((tableNames?.length ?? 0) > 0) {
      const newIndex = (tableNames ?? []).indexOf(tableName);
      if (newIndex < 0) {
        setTableName(tableNames[0]);
        setTableIndex(0);
      } else {
        if (newIndex !== tableIndex) {
          setTableIndex(newIndex);
        }
      }
    }
  }, [tableName, tableNames, setTableIndex, setTableName, tableIndex]);

  useEffect(() => {
    if (tableName == null && tableNames?.length) {
      setTableName(tableNames[0]);
    }
  }, [tableName, tableNames, setTableName]);

  useEffect(() => {
    if (origin == null) {
      setOrigin({ row: 0, column: 0 });
    }
  }, [origin, setOrigin]);

  const HighlightedDataViewer = ({ row, column, data, rootRef }) => {
    const topRow = row === origin?.row;
    const pastTopRow = row > origin?.row;
    const topColumn = column === origin?.column;
    const pastTopColumn = column > origin?.column;

    const active = topRow && topColumn;
    const border = (topRow && pastTopColumn) || (topColumn && pastTopRow);
    const inside = pastTopRow && pastTopColumn;

    const cell = (
      <td
        ref={rootRef}
        className={classnames(
          "sheet-preview-cell",
          active && "sheet-preview-cell-active",
          border && "sheet-preview-cell-border",
          inside && "sheet-preview-cell-content",
          data?.type && `sheet-preview-cell-type-${data.type}`,
          data?.className
        )}
        tabIndex={0}
        onMouseDown={() => setOrigin({ row, column })}
      >
        <span>{data?.displayValue ?? data?.value ?? ""}</span>
      </td>
    );
    return data?.tooltip ? (
      <Tooltip title={data.tooltip}>{cell}</Tooltip>
    ) : (
      cell
    );
  };

  const hasData = page?.page?.length;
  const hasError = sheetNamesStatus === "rejected" || pageStatus === "rejected";

  if (!fileId) {
    return <></>;
  }

  if (sheetNamesStatus !== "fulfilled" || tableName == null) {
    return <CentredLoader />;
  }

  return (
    <>
      <DialogContentText marginTop={1} marginBottom={1}>
        {
          "Please choose the top corner of the region you'd like to extract from the spreadsheet"
        }
      </DialogContentText>
      <Tabs
        value={tableIndex}
        variant={"scrollable"}
        onChange={(_, newTableIndex) =>
          setTableName(tableNames?.[newTableIndex])
        }
      >
        {tableNames.map((tableName) => (
          <PageTab key={tableName} tableName={tableName} fileId={fileId} />
        ))}
      </Tabs>
      {hasError ? (
        <Alert severity={"error"}>
          {"Sorry, there was an error reading this sheet"}
        </Alert>
      ) : pageStatus !== "fulfilled" ? (
        <CentredLoader />
      ) : hasData ? (
        <Spreadsheet
          data={(page?.page ?? []).map((row) => row.map(formatCellValue))}
          Cell={SheetPreviewCell}
          DataViewer={HighlightedDataViewer}
        />
      ) : (
        <Alert severity={"info"}>
          {"This sheet doesn't appear to contain any data"}
        </Alert>
      )}
    </>
  );
};

export default SheetSelector;
