import {
  useGetClaimsColumnsQuery,
  useGetHeaderRowQuery,
  useGetTransformMetadataQuery,
} from "../../../services/tablesService";
import BigLoader from "../../common/BigLoader";
import { useEffect, useState } from "react";

const ClaimsV2Mapping = ({ fileId }) => {
  const {
    data: mappingData,
    status: mappingDataStatus,
  } = useGetTransformMetadataQuery(fileId, { skip: !fileId });
  const { data: columns, status: columnsStatus } = useGetClaimsColumnsQuery();

  const [columnMap, setColumnMap] = useState(null);
  useEffect(() => {
    if (columnsStatus === "fulfilled")
      setColumnMap(
        Object.fromEntries(columns?.columns?.map((e) => [e.column, e]))
      );
  }, [mappingData, columns, columnsStatus, mappingDataStatus]);

  const {
    data: inputColumns,
    status: inputColumnsStatus,
  } = useGetHeaderRowQuery(
    {
      ...mappingData?.mapping,
      fileId: mappingData?.mapping?.id,
    },
    {
      skip:
        !fileId ||
        mappingDataStatus !== "fulfilled" ||
        !mappingData?.mapping?.tableName,
    }
  );

  const [displayTable, setDisplayTable] = useState(null);
  useEffect(() => {
    if (
      inputColumnsStatus === "fulfilled" &&
      mappingDataStatus === "fulfilled" &&
      columnsStatus === "fulfilled"
    ) {
      const mappingList = mappingData?.mapping?.["mapping"] || [];
      const rawList = [];
      mappingList.forEach((e) => {
        const outEntry = columnMap?.[e.outputName];
        const outName = outEntry?.display;
        const inpName = inputColumns?.header?.[e.inputColumn];
        const hasSumRow = e.conversions.filter(
          (c) => c.conversionType === "SUM"
        );
        const getSum = () => {
          if (hasSumRow?.length) {
            return hasSumRow
              .map((x) => {
                const constVal = x.constant || 0;
                if (constVal > 0) return " +" + constVal.toLocaleString();
                if (constVal < 0) return " -" + (-constVal).toLocaleString();
                return "";
              })
              .join("");
          }
          return "";
        };
        const hasDeletRow = e.conversions.filter((c) =>
          ["NOT_IN_DELETE_ROW", "IN_DELETE_ROW"].includes(c.conversionType)
        );
        if (hasDeletRow.length > 0) {
          hasDeletRow.forEach((x) => {
            if (x.conversionType === "IN_DELETE_ROW") {
              x.match.forEach((m) => {
                const val = outEntry?.enumVals?.[m];
                rawList.push([
                  "[DELETE ROWS with]",
                  inpName,
                  val ? val : m,
                  "",
                ]);
              });
            }
            if (x.conversionType === "NOT_IN_DELETE_ROW") {
              x.match.forEach((m) => {
                const val = outEntry?.enumVals?.[m];
                rawList.push([
                  "[DELETE ROWS without]",
                  inpName,
                  val ? val : m,
                  "",
                ]);
              });
            }
          });
        }
        const hasConstOrConvert = e.conversions.filter((c) =>
          ["CONSTANT", "CONVERT"].includes(c.conversionType)
        );
        if (hasConstOrConvert.length > 0) {
          hasConstOrConvert.forEach((x) => {
            if (x.conversionType === "CONSTANT") {
              const outVal = outEntry?.enumVals?.[x.value]
                ? outEntry.enumVals[x.value]
                : x.value;
              rawList.push([outName, "", "", outVal]);
            }
            if (x.conversionType === "CONVERT") {
              Object.entries(x.conversions).forEach((y) => {
                const outVal = outEntry?.enumVals?.[y[1]]
                  ? outEntry.enumVals[y[1]]
                  : y[1];
                rawList.push([outName, inpName, y[0] || "[BLANK]", outVal]);
              });
            }
          });
        } else {
          rawList.push([
            outName,
            inpName + (hasSumRow ? getSum() : ""),
            "",
            "",
          ]);
        }
      });
      const filteredList = rawList
        .filter((row) => row[0])
        .sort((a, b) => {
          const as = a.toString();
          const bs = b.toString();
          return as < bs ? -1 : bs < as ? 1 : 0;
        })
        .reduce(
          (acc, row) => {
            const out = [...row];
            for (let i = 0; i < 4; i++) {
              if (out[i] === acc.last[i]) out[i] = "";
              else break;
            }
            return { last: row, output: [...acc.output, out] };
          },
          { last: [null, null, null, null], output: [] }
        ).output;
      setDisplayTable(filteredList.filter((r) => r.some((e) => !!e)));
    } else {
      setDisplayTable(null);
    }
  }, [
    columnMap,
    columnsStatus,
    inputColumns,
    inputColumnsStatus,
    mappingData,
    mappingDataStatus,
  ]);

  if (
    mappingDataStatus !== "fulfilled" ||
    columnsStatus !== "fulfilled" ||
    inputColumnsStatus !== "fulfilled" ||
    !displayTable
  ) {
    return <BigLoader />;
  } else {
    return (
      <table className={"claims-v2-mapping-table"}>
        <thead>
          <tr>
            <th colSpan={2} style={{ textAlign: "left" }}>
              {"Column Mappings"}
            </th>
            <th colSpan={2} style={{ textAlign: "left" }}>
              {"Value Mappings"}
            </th>
          </tr>
          <tr>
            <th style={{ textAlign: "left" }}>{"Marmalade"}</th>
            <th style={{ textAlign: "left" }}>{"Spreadsheet"}</th>
            <th style={{ textAlign: "left" }}>{"Cell Value"}</th>
            <th style={{ textAlign: "left" }}>{"Treat As"}</th>
          </tr>
        </thead>
        <tbody>
          {displayTable.map((e) => (
            <tr>
              <td>{e[0]}</td>
              <td>{e[1]}</td>
              <td>{e[2]}</td>
              <td>{e[3]}</td>{" "}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
};

export default ClaimsV2Mapping;
