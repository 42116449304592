import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
} from "@mui/material";
import Component from "components/Component";
import Disabled from "components/common/Disabled";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { useSelector, useDispatch } from "react-redux";
import * as modelStore from "store/modelDataStore";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

const DEFAULLT_REGIONS = [
  "Miami-Dade",
  "Pinellas",
  "Gulf",
  "Carolinas",
  "Northeast",
  "New Madrid",
  "Los Angeles",
  "San Francisco",
];

function RDSContributionEditor() {
  const dispatch = useDispatch();
  const modelData = useSelector(modelStore.selectModelData);
  const layers = useSelector(pricingSelectors.selectResolvedLayers);

  const data =
    modelData?.rdsData ?? DEFAULLT_REGIONS.map((region) => ({ region }));

  const handleInputChange = (index, field, value) => {
    const updatedData = data.map((row, i) =>
      i === index ? { ...row, [field]: value } : row
    );

    dispatch(
      modelStore.update({
        ...modelData,
        rdsData: updatedData,
      })
    );
  };

  return (
    <Component title={"RDS Contributions Input"}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{"Region"}</TableCell>
              <TableCell align={"right"}>{"Contribution ($)"}</TableCell>
              <TableCell align={"right"}>{"Contribution (%)"}</TableCell>
              <TableCell>{"Layer"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.region}</TableCell>
                <TableCell align={"right"}>
                  <Disabled ifReadOnly>
                    <PrettyNumberTextField
                      type={"text"}
                      InputLabelProps={{ shrink: true }}
                      label={"Contribution"}
                      value={row.contribution}
                      onChangeNumber={(value) =>
                        handleInputChange(index, "contribution", value)
                      }
                      variant={"filled"}
                    />
                  </Disabled>
                </TableCell>
                <TableCell align={"center"}>
                  <Disabled ifReadOnly>
                    <PrettyNumberTextField
                      type={"text"}
                      suffix={"%"}
                      InputLabelProps={{ shrink: true }}
                      label={"Contribution"}
                      value={row.percentage}
                      onChangeNumber={(value) =>
                        handleInputChange(index, "percentage", value)
                      }
                      variant={"filled"}
                    />
                  </Disabled>
                </TableCell>
                <TableCell>
                  <Disabled ifReadOnly>
                    <Select
                      value={row.layer ?? "__NULL__"}
                      onChange={(e) =>
                        handleInputChange(index, "layer", e.target.value)
                      }
                      variant={"standard"}
                      fullWidth
                    >
                      {!row.layer && (
                        <MenuItem value={"__NULL__"}>
                          {"--- Please Select ---"}
                        </MenuItem>
                      )}
                      {(layers ?? []).map((layer) => (
                        <MenuItem key={layer.id} value={layer.id}>
                          {utils.shortLayerName(layer)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Disabled>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Component>
  );
}

export default RDSContributionEditor;
