import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as actionTypes from "store/actions/summary/summaryActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

export const INIT_STATE = {
  considerationsFromLastRenewal: "",
  notes: {
    riskConsiderations: null,
    termsAndConditions: null,
    capacityUtilization: null,
    pricingComments: null,
    considerationsForNextRenewal: null,
  },
  peerReview: {
    commentary: "",
    peerReviewer: "",
    date: null,
  },
};

const prependWithRenewalNote = (note) => {
  if (note == null || !note.trim()) {
    return null;
  }
  return `**Carried forward from the prior submission**\n\n${note}`;
};

const summaryReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.summary,
      };
    case actionTypes.UPDATE_SUMMARY_NOTE:
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.payload.key]: action.payload.note,
        },
      };
    case actionTypes.UPDATE_SUMMARY_PEER_REVIEW_COMMENT:
      return {
        ...state,
        peerReview: {
          ...state.peerReview,
          commentary: action.payload,
        },
      };
    case actionTypes.UPDATE_SUMMARY_PEER_REVIEW_DATE:
      return {
        ...state,
        peerReview: {
          ...state.peerReview,
          date: action.payload || null,
        },
      };
    case actionTypes.UPDATE_SUMMARY_PEER_REVIEWER:
      return {
        ...state,
        peerReview: {
          ...state.peerReview,
          peerReviewer: action.payload,
        },
      };
    case actionTypes.UPDATE_SUMMARY_PEER_REVIEW_ATTENDEES:
      return {
        ...state,
        peerReview: {
          ...state.peerReview,
          attendees: action.payload,
        },
      };
    case actionTypes.UPDATE_SUMMARY_PEER_REVIEW_COMPLETE:
      return {
        ...state,
        peerReview: {
          ...state.peerReview,
          complete: action.payload.complete,
          date: state.peerReview.date || action.payload.date || null,
          changedBy: action.payload.changedBy,
          changedAt: action.payload.changedAt,
        },
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
        notes: {
          ...INIT_STATE.notes,
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.summary?.notes,
        },
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...INIT_STATE,
        considerationsFromLastRenewal:
          state.notes?.considerationsForNextRenewal,
        notes: {
          ...INIT_STATE.notes,
          riskConsiderations: prependWithRenewalNote(
            state.notes?.riskConsiderations
          ),
          termsAndConditions: prependWithRenewalNote(
            state.notes?.termsAndConditions
          ),
          capacityUtilization: prependWithRenewalNote(
            state.notes?.capacityUtilization
          ),
          pricingComments: prependWithRenewalNote(state.notes?.pricingComments),
        },
      };
    default:
      return state;
  }
};

export default summaryReducer;
