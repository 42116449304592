import { readableDate } from "common/dates";
import { extractNameFromEmail } from "common/strings";

const emailCache = {};

const emailToInitials = (email) => {
  let initials = emailCache[email];
  if (initials == null) {
    initials = extractNameFromEmail(email).initials;
    emailCache[email] = initials;
  }
  return initials;
};

const label = (email, config) => {
  if (config == null) return email;

  const meta = config.usersByEmail?.[email];

  if (meta == null || meta?.name == null) {
    return email;
  } else if (meta.company == null) {
    return meta.name;
  } else {
    const enDashChar = "\u2013";
    return `${meta.name} ${enDashChar} ${meta.company}`;
  }
};

export const dataToDisplay = (data, config) => {
  const result = [];
  let prevDate, prevTime, prevUser;

  for (let i = 0; i < data.length; i++) {
    const comment = data[i];
    const date = new Date(comment.createTime);
    const time = date.getTime();
    result.push({
      ...comment,
      initials: emailToInitials(comment.userEmail),
      label: label(comment.userEmail, config),
      dateLabel:
        comment.createTime.substring(0, 10) === prevDate
          ? null
          : readableDate(date),
      time:
        comment.userId !== prevUser || time - prevTime > 60_000
          ? date.toLocaleTimeString()
          : null,
    });
    prevDate = comment.createTime.substring(0, 10);
    prevTime = time;
    prevUser = comment.userId;
  }

  return result;
};
