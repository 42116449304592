import ClaimsComparisonSettingsDialog from "./ClaimsComparisonSettingsDialog";
import ClaimsSlider from "./ClaimsSlider";
import FilteredClaimsChart from "./FilteredClaimsChart";
import { Settings as SettingsIcon } from "@mui/icons-material";
import { Alert, Button, Box, Stack, Typography } from "@mui/material";
import * as chartUtils from "chartUtils";
import * as charts from "common/charts";
import * as dates from "common/dates";
import { currencyFormat } from "common/numbers";
import Component from "components/Component";
import BigLoader from "components/common/BigLoader";
import IconButton from "components/common/IconButton";
import * as config from "config";
import * as _ from "lodash";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { DefaultTooltipContent } from "recharts/lib/component/DefaultTooltipContent";
import * as claimsService from "services/claimsService";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

const tooltipLabelFormatter = (value, props) => {
  return dates.formatDate(props?.[0]?.payload?.date);
};

const makeTooltipPayload = (originalPayload) => {
  const diff = originalPayload?.[0]?.payload;

  if (diff == null) {
    return [];
  }

  const { delta, status, date, prior, current } = diff;

  const payload = [
    {
      name: "Date",
      value: <span>{date}</span>,
    },
    {
      name: "Status",
      value: (
        <span
          style={{
            ...{
              new: {
                background: "#cfc",
                border: "2px solid #0c0",
                borderRadius: "4px",
                color: "#0a0",
              },
              missing: {
                background: "#fcdfcf",
                border: "2px solid #fcae83",
                borderRadius: "4px",
                color: "#fa7b35",
              },
              different: {
                background: "#fcc",
                border: "2px solid #f00",
                borderRadius: "4px",
                color: "#f00",
              },
            }[status],
          }}
        >
          {status?.toUpperCase()}
        </span>
      ),
    },
  ];

  if (status === "different") {
    payload.push({
      name: "Delta",
      value: (
        <span style={{ color: delta < 0 ? "#d00" : "#0a0" }}>
          {currencyFormat(delta)}
        </span>
      ),
    });

    const oldValue = prior?.numericDeltas?.[0];
    payload.push({
      name: "Old Value",
      value: <span>{oldValue != null ? currencyFormat(oldValue) : ""}</span>,
    });

    const newValue = current?.numericDeltas?.[0];
    payload.push({
      name: "New Value",
      value: <span>{newValue != null ? currencyFormat(newValue) : ""}</span>,
    });
  } else {
    payload.push({
      name: "Value",
      value: (
        <span style={{ color: delta < 0 ? "#d00" : "#0a0" }}>
          {currencyFormat(delta)}
        </span>
      ),
    });
  }

  return payload;
};

const CustomTooltipContent = (props) => {
  return (
    <DefaultTooltipContent
      {...props}
      payload={makeTooltipPayload(props?.payload)}
    />
  );
};

const tooltipFormatter = (value) => {
  if (typeof value === "number") {
    return currencyFormat(value);
  }
  return value;
};

const WithLoading = ({ isLoading, children }) =>
  isLoading ? <BigLoader /> : children;

const WithError = ({ error, children }) =>
  error ? <Alert severity={"error"}>{error}</Alert> : children;

const ClaimsAndDeltaChart = ({ query, setQuery }) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [claimsThreshold, setClaimsThreshold] = useState(100_000);
  const [zoom, setZoom] = useState();

  const zoomIdentifier = "ClaimsAndDeltaChart";
  const chartZoom = useSelector((state) => state.temp?.claims?.chartZoom)?.[
    zoomIdentifier
  ];

  const cartesianGridId = useRef("cartesian-grid");

  const dispatch = useDispatch();

  const priorQuery = useDebouncedValue(
    useSelector((state) =>
      claimsSelectors.selectInputClaims(stateSelectors.selectPriorState(state))
    )
  );
  const currentQuery = useDebouncedValue(
    useSelector(claimsSelectors.selectInputClaims)
  );
  const hasPriorState = !_.isEmpty(
    useSelector(stateSelectors.selectPriorState)
  );

  const { data, isFetching, isError } = claimsService.useClaimsComparisonQuery(
    {
      ...query,
      priorQuery,
      currentQuery,
    },
    {
      skip: !hasPriorState || !priorQuery || !currentQuery,
    }
  );

  const {
    currentData: allClaimsChart,
    isFetching: isAllClaimsChartFetching,
  } = claimsService.useAllClaimsChartQuery({
    claimsQuery: currentQuery,
    claimsAbove: claimsThreshold,
  });

  const {
    currentData: priorAllClaimsChart,
    isFetching: isPriorAllClaimsChartFetching,
  } = claimsService.useAllClaimsChartQuery({
    claimsQuery: priorQuery,
    claimsAbove: claimsThreshold,
  });

  const {
    chartRef,
    tooltipPosition,
  } = chartUtils.useTooltipPositionerFixingBottomRightToChartTopRight();

  if (!hasPriorState) {
    return <></>;
  }

  const [, biggestClaim] = charts.getNumberDomain(
    priorAllClaimsChart,
    allClaimsChart
  );

  const numberTicks = charts.makeTicksForNumericDomain([0, biggestClaim]);
  const numberDomain = [0, numberTicks.at(-1)];

  let doubleNumberTicks = numberTicks.slice(1).map((x) => -x);
  doubleNumberTicks.reverse();
  doubleNumberTicks = [...doubleNumberTicks, ...numberTicks];
  const doubleNumberDomain = [-biggestClaim, biggestClaim];

  const dateDomain = charts.getDateDomain(priorAllClaimsChart, allClaimsChart);

  const zoomedDomain = chartZoom ? [chartZoom.from, chartZoom.to] : dateDomain;

  const getMonthOfEvent = (e) => {
    if (e?.chartX == null) {
      return undefined;
    }
    const gridEl = document.getElementById(cartesianGridId);
    if (gridEl == null) {
      return undefined;
    }
    const gridX = e.chartX - gridEl.getAttribute("x");
    const gridWidth = gridEl.getAttribute("width");
    const along = Math.min(Math.max(0, gridX / gridWidth), 1);
    const month = zoomedDomain[0] + along * (zoomedDomain[1] - zoomedDomain[0]);
    return month;
  };

  const includedDeltas = (data?.comparisons ?? []).filter((comparison) => {
    const delta = comparison.delta ?? 0;
    const prior = comparison.prior?.numericDeltas?.[0] ?? 0;
    const current = comparison.current?.numericDeltas?.[0] ?? 0;

    return (
      Math.abs(delta) > 0 &&
      (prior > claimsThreshold || current > claimsThreshold) &&
      comparison.months > zoomedDomain[0] &&
      comparison.months < zoomedDomain[1]
    );
  });

  return (
    <Component
      title={"Claims Delta"}
      options={
        <IconButton
          variant={"bright"}
          scale={"small"}
          icon={SettingsIcon}
          tooltip={"Settings"}
          onClick={() => setIsSettingsOpen(true)}
        />
      }
    >
      <ClaimsComparisonSettingsDialog
        open={isSettingsOpen}
        onClose={() => setIsSettingsOpen(false)}
        query={query}
        setQuery={setQuery}
      />
      <Stack spacing={2}>
        <Stack>
          <Typography variant={"subtitle1"}>{"Current"}</Typography>
          <WithLoading isLoading={isAllClaimsChartFetching}>
            <FilteredClaimsChart
              data={allClaimsChart}
              dateDomain={dateDomain}
              numberTicks={numberTicks}
              numberDomain={numberDomain}
              filter={{ attachment: claimsThreshold }}
              zoomIdentifier={zoomIdentifier}
              disabled
            />
          </WithLoading>
          <Typography variant={"subtitle1"}>{"Prior"}</Typography>
          <WithLoading isLoading={isPriorAllClaimsChartFetching}>
            <FilteredClaimsChart
              data={priorAllClaimsChart}
              dateDomain={dateDomain}
              numberTicks={numberTicks}
              numberDomain={numberDomain}
              filter={{ attachment: claimsThreshold }}
              zoomIdentifier={zoomIdentifier}
              disabled
            />
          </WithLoading>
          <Typography variant={"subtitle1"}>
            {query?.limit ? `Delta (Top ${query.limit})` : "Delta"}
          </Typography>
          <WithLoading isLoading={isFetching}>
            <WithError
              error={
                isError
                  ? `Error running claims comparison. Please contact ${config.SUPPORT_EMAIL}.`
                  : null
              }
            >
              <ResponsiveContainer width={"100%"} height={600}>
                <BarChart
                  ref={chartRef}
                  height={600}
                  data={includedDeltas}
                  onMouseDown={(e) => {
                    const monthOfEvent = getMonthOfEvent(e);
                    if (monthOfEvent != null) {
                      setZoom({
                        from: monthOfEvent,
                      });
                    }
                  }}
                  onMouseMove={(e) => {
                    const monthOfEvent = getMonthOfEvent(e);
                    if (monthOfEvent != null && zoom?.from != null) {
                      setZoom({
                        from: zoom.from,
                        to: monthOfEvent,
                        selecting: true,
                      });
                    }
                  }}
                  onMouseUp={() => {
                    if (zoom?.from != null && zoom?.to != null) {
                      const [from, to] = [
                        Math.min(zoom.from, zoom.to),
                        Math.max(zoom.from, zoom.to),
                      ];
                      dispatch(
                        claimsActions.updateChartZoom(zoomIdentifier, {
                          from,
                          to,
                        })
                      );
                    }
                    setZoom({});
                  }}
                  isAnimationActive={false}
                >
                  <CartesianGrid strokeDasharray={"3 3"} id={cartesianGridId} />
                  <Tooltip
                    formatter={tooltipFormatter}
                    labelFormatter={tooltipLabelFormatter}
                    content={<CustomTooltipContent />}
                    position={tooltipPosition}
                    isAnimationActive={false}
                  />
                  <Bar
                    dataKey={"delta"}
                    barSize={5}
                    shape={charts.minWidthBarFactory(5)}
                  >
                    {includedDeltas.map((comparison, index) => (
                      <Cell
                        key={index}
                        fill={comparison.delta < 0 ? "#f00" : "#0c0"}
                      />
                    ))}
                  </Bar>
                  {zoom?.from != null && zoom?.to != null && (
                    <ReferenceArea
                      x1={zoom.from}
                      x2={zoom.to}
                      ifOverflow={"hidden"}
                      fillOpacity={0.25}
                    />
                  )}
                  <XAxis
                    dataKey={"months"}
                    type={"number"}
                    tickFormatter={charts.dateTickFormatter}
                    ticks={charts.getDateTicks(zoomedDomain)}
                    domain={zoomedDomain}
                    axisLine={false}
                    style={zoom?.selecting ? { userSelect: "none" } : {}}
                  />
                  {/* Draw the x-axis line at y=0. */}
                  <ReferenceLine y={0} stroke={"#000000"} />
                  <YAxis
                    domain={doubleNumberDomain}
                    ticks={doubleNumberTicks}
                    tickFormatter={chartUtils.tickFormatter}
                    style={zoom?.selecting ? { userSelect: "none" } : {}}
                  />
                </BarChart>
              </ResponsiveContainer>
            </WithError>
          </WithLoading>
        </Stack>
        <Box>
          <ClaimsSlider
            title={"Attachment"}
            value={claimsThreshold}
            onChangeNumber={setClaimsThreshold}
            min={0}
            max={biggestClaim || 1e9}
          />
        </Box>
        {chartZoom && (
          <Stack direction={"row"} justifyContent={"right"}>
            <Button
              disableElevation
              variant={"contained"}
              color={"secondary"}
              onClick={() => {
                dispatch(claimsActions.clearChartZoom(zoomIdentifier));
              }}
            >
              {"Clear Zoom"}
            </Button>
          </Stack>
        )}
      </Stack>
    </Component>
  );
};

export default ClaimsAndDeltaChart;
