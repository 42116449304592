import * as support from "./ClaimsComparisonSettingsDialog.support";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Button from "components/common/Button";

const ColumnMatchSetting = ({
  columnName,
  columnKey,
  query,
  setQuery,
  disabled = false,
}) => {
  const value = support.extractColumnMatchStateFromQuery({ query, columnKey });

  const setValue = (value) => {
    setQuery(support.setColumnMatchState({ query, columnKey, state: value }));
  };

  return (
    <Stack
      direction={"row"}
      spacing={2}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Typography minWidth={200}>{columnName}</Typography>
      <ToggleButtonGroup
        color={"primary"}
        value={value}
        exclusive
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
      >
        <ToggleButton value={"IGNORED"}>{"Ignored"}</ToggleButton>
        <ToggleButton value={"CONSIDERED"}>{"Considered"}</ToggleButton>
        <ToggleButton value={"REQUIRED"}>{"Required"}</ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};

const ClaimsComparisonSettings = ({ open, onClose, query, setQuery }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{"Settings"}</DialogTitle>
      <DialogContent>
        <Stack direction={"column"} spacing={2}>
          <ColumnMatchSetting
            disabled
            columnName={"Date of Loss"}
            columnKey={"_DATE_OF_LOSS"}
            query={{ requiredMatchColumns: ["_DATE_OF_LOSS"] }}
            setQuery={() => {}}
          />
          <ColumnMatchSetting
            columnName={"LOB"}
            columnKey={"_COVERAGE"}
            query={query}
            setQuery={setQuery}
          />
          <ColumnMatchSetting
            columnName={"Claimant"}
            columnKey={"_CLAIMANT_NAME"}
            query={query}
            setQuery={setQuery}
          />
          <ColumnMatchSetting
            columnName={"Description"}
            columnKey={"_DESCRIPTION"}
            query={query}
            setQuery={setQuery}
          />
          <ColumnMatchSetting
            columnName={"O/C"}
            columnKey={"_OPEN_CLOSED"}
            query={query}
            setQuery={setQuery}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={"contained"} color={"primary"} onClick={onClose}>
          {"OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClaimsComparisonSettings;
