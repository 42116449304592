import PersonSummary from "./PersonSummary";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as objects from "common/objects.js";
import IconButton from "components/common/IconButton";
import React, { useState } from "react";

const PersonSearch = ({
  label,
  personList,
  onUpdatePerson,
  onCreateNewPerson,
  canCreateNewPerson,
  onBlur,
  defaultOpen = false,
  disabled = false,
}) => {
  const [open, setOpen] = useState(defaultOpen);

  return (
    <Autocomplete
      options={personList?.filter((x) => !x.isDeleted) ?? []}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          type={"text"}
          variant={"filled"}
          InputLabelProps={{ shrink: true }}
          label={label ?? "Broker"}
          autoFocus={defaultOpen}
          fullWidth
        />
      )}
      filterOptions={(options, state) => {
        const filteredOptions = objects.search(options, {
          search: (state?.inputValue ?? "").trim().split(/\s+/),
          keys: ["name", "company", "email"],
        });
        return canCreateNewPerson
          ? [...filteredOptions, { addNew: true }]
          : filteredOptions;
      }}
      getOptionLabel={(option) =>
        `${option.name} @ ${option.company}, ${option.id}`
      }
      renderOption={(props, option) => {
        if (option.addNew) {
          return (
            <Box
              {...props}
              sx={{ width: "90%", margin: 1 }}
              className={"person-summary-selector"}
            >
              <Grid container className={"person-summary"}>
                <Grid item xs={12} className={"name"}>
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <AddIcon />
                    <Typography>{"Add new broker"}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          );
        } else {
          return (
            <Box
              {...props}
              sx={{ width: "90%", margin: 1 }}
              className={"person-summary-selector"}
            >
              <PersonSummary
                person={option}
                showEmail={false}
                showMonogram={false}
              />
            </Box>
          );
        }
      }}
      onChange={(event, value, reason) => {
        if (reason === "selectOption" && value) {
          if (value.addNew) {
            onCreateNewPerson();
          } else {
            onUpdatePerson(value);
          }
        }
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={(event, reason) => {
        onBlur && onBlur();
        setOpen(false);
      }}
    />
  );
};

const PersonCard = ({
  person,
  onClearPerson,
  onEditPerson,
  showCompany = true,
  showEmail = true,
  showMonogram = true,
  showEdit = false,
  showLicenseNumber = false,
  label = null,
  disabled = false,
}) => {
  return (
    <Card>
      <Grid container alignItems={"center"}>
        <Grid item container xs>
          {label && (
            <Grid item xs={12}>
              <InputLabel shrink sx={{ marginTop: 1, marginLeft: 1 }}>
                {label}
              </InputLabel>
            </Grid>
          )}
          <Grid item xs={12}>
            <PersonSummary
              person={person}
              showMonogram={showMonogram}
              showEmail={showEmail}
              showCompany={showCompany}
              showLicenseNumber={showLicenseNumber}
            />
          </Grid>
        </Grid>
        <Grid item xs={"auto"}>
          {showEdit && (
            <IconButton
              variant={"bright"}
              icon={EditIcon}
              onClick={onEditPerson}
            />
          )}
          {!disabled && (
            <IconButton
              variant={"bright"}
              icon={ClearIcon}
              onClick={onClearPerson}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

const PersonSelect = ({
  label,
  person,
  personList,
  onUpdatePerson,
  onUpdatePersonList,
  PersonCreationComponent = null,
  showCompany = true,
  showEmail = true,
  showMonogram = true,
  showLicenseNumber = false,
  showLabelOnCard = false,
  showEdit = false,
  disabled = false,
}) => {
  const [isForcedDisplayingSearch, setIsForcedDisplayingSearch] = useState(
    false
  );
  const [
    isDisplayingPersonCreationForm,
    setIsDisplayingPersonCreationForm,
  ] = useState(false);

  const hasPerson = !!(
    person?.id ||
    person?.name ||
    person?.company ||
    person?.email
  );

  const isShowingPerson = hasPerson && !isForcedDisplayingSearch;

  const onUpdatePersonRaw = onUpdatePerson;
  onUpdatePerson = (...args) => {
    setIsForcedDisplayingSearch(false);
    onUpdatePersonRaw(...args);
  };

  const canCreateNewPerson = PersonCreationComponent != null;

  return (
    <>
      {isShowingPerson ? (
        <PersonCard
          person={person}
          onClearPerson={() => onUpdatePerson(null)}
          onEditPerson={() => setIsForcedDisplayingSearch(true)}
          showCompany={showCompany}
          showEmail={showEmail}
          showMongram={showMonogram}
          showEdit={showEdit}
          showLicenseNumber={showLicenseNumber}
          label={showLabelOnCard ? label : null}
          disabled={disabled}
        />
      ) : !disabled && canCreateNewPerson && isDisplayingPersonCreationForm ? (
        <PersonCreationComponent
          onCreate={(person) => {
            onUpdatePersonList();
            onUpdatePerson(person);
            setIsDisplayingPersonCreationForm(false);
          }}
          onClose={() => setIsDisplayingPersonCreationForm(false)}
        />
      ) : (
        <PersonSearch
          label={label}
          personList={personList}
          onUpdatePerson={onUpdatePerson}
          onCreateNewPerson={() => setIsDisplayingPersonCreationForm(true)}
          canCreateNewPerson={canCreateNewPerson}
          onBlur={() => setIsForcedDisplayingSearch(false)}
          defaultOpen={isForcedDisplayingSearch}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default PersonSelect;
