import * as actionTypes from "./hazardActionTypes";
import * as actions from "./hazardActions";
import { createAction } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import * as logger from "common/logger";
import * as config from "config";
import * as fileUtils from "fileUtils";
import * as errorActions from "store/actions/error/errorActions";

export const fileUploading = (filename) => ({
  type: actionTypes.HAZARD_FILE_DROPPED,
  payload: filename,
});

export const transformHazardFile = {
  requested: createAction("activeHazardFile/transform"),
  started: createAction("activeHazardFile/transform/started"),
  succeeded: createAction(
    "activeHazardFile/transform/succeeded",
    ({ fileId }) => {
      return {
        payload: { fileId },
      };
    }
  ),
  failed: createAction(
    "activeHazardFile/transform/failed",
    ({ errorMessage }) => {
      return {
        payload: {
          errorMessage,
        },
      };
    }
  ),
};

export const fileUploaded = (fileId) => (dispatch) => {
  dispatch({
    type: actionTypes.HAZARD_FILE_UPLOADED,
    payload: {
      fileId,
    },
  });
  updateSheets(fileId, dispatch);
};

export const filePreloaded = (fileId) => (dispatch) => {
  dispatch({
    type: actionTypes.HAZARD_FILE_PRELOADED,
    payload: {
      fileId,
    },
  });
  updateSheets(fileId, dispatch);
};

export const fileUploadFailed = (filename, error) => (dispatch) => {
  Sentry.addBreadcrumb({
    message: `Uploading file failed: ${filename}`,
  });
  dispatch(
    errorActions.handleError(error, {
      title: "File Upload Failed",
      message:
        error?.description ||
        `File upload failed please contact ${config.SUPPORT_EMAIL}.`,
      type: actionTypes.HAZARD_FILE_UPLOAD_FAILED,
    })
  );
  dispatch({
    type: actionTypes.HAZARD_FILE_UPLOAD_FAILED,
  });
};

export const uploadFile = (fileId) => async (dispatch) => {
  dispatch(actions.fileUploading(fileId));

  try {
    dispatch(actions.fileUploaded(fileId));
  } catch (error) {
    dispatch(actions.fileUploadFailed(fileId, error));
  }
};
export const nextStep = () => ({
  type: actionTypes.HAZARD_DIALOG_NEXT_STEP,
});

export const prevStep = () => ({
  type: actionTypes.HAZARD_DIALOG_PREV_STEP,
});

export const setHazardState = (state) => ({
  type: actionTypes.UPDATE_HAZARD_STATE,
  payload: state,
});

export const hazardDialogClosed = () => ({
  type: actionTypes.HAZARD_DIALOG_CLOSED,
});

export const updateHazardNote = (note) => ({
  type: actionTypes.UPDATE_HAZARD_NOTE,
  payload: note,
});

export const mapping = {
  updated: createAction(
    "hazard/mapping/updated",
    (column, target, selected) => ({
      payload: {
        target,
        column,
        selected,
      },
    })
  ),
};

export const sheetTopLeftSelected = ({ row, column }) => ({
  type: actionTypes.ACTIVE_HAZARD_SHEET_TOP_LEFT_CHANGED,
  payload: {
    row,
    column,
  },
});

export const sheetsReturned = (fileId, sheetSummaries) => ({
  type: actionTypes.HAZARD_SHEET_LIST_RETURNED,
  payload: { fileId, sheetSummaries },
});

export const sheetSelected = (fileId, sheet) => ({
  type: actionTypes.HAZARD_ACTIVE_SHEET_CHANGED,
  payload: {
    fileId,
    sheet,
  },
});

export const mapHazardFileFailed = (error) => ({
  type: actionTypes.HAZARD_FILE_MAPPING_FAILED,
  payload: {
    error,
  },
});

const updateSheets = (fileId, dispatch) => {
  fileUtils
    .getTableList(fileId)
    .then((data) => {
      dispatch(actions.sheetsReturned(fileId, data));
      if (data && data.length > 0) {
        dispatch(actions.sheetSelected(fileId, 0));
      }
    })
    .catch((error) => {
      logger.exception(error);
      dispatch(
        actions.mapHazardFileFailed(
          `Failed to extract sheets from input file. Please either convert the file to a .csv, try another file, or contact ${config.SUPPORT_EMAIL}.`
        )
      );
    });
};

export const hideHazardFile = (key) => (dispatch) => {
  dispatch({
    type: actionTypes.HIDE_HAZARD_FILE,
    payload: key,
  });
  dispatch(actions.updateHazardProfileSummary());
};

export const updateHazardProfileSummary = () => ({
  type: actionTypes.UPDATE_HAZARD_PROFILE_SUMMARY,
});

export const updateHazardProfileSummaryStarted = () => ({
  type: actionTypes.UPDATE_HAZARD_PROFILE_SUMMARY_STARTED,
});

export const updateHazardProfileSummarySucceeded = (hazardProfileSummary) => ({
  type: actionTypes.UPDATE_HAZARD_PROFILE_SUMMARY_SUCCEEDED,
  payload: hazardProfileSummary,
});

export const updateHazardProfileSummaryFailed = () => ({
  type: actionTypes.UPDATE_HAZARD_PROFILE_SUMMARY_FAILED,
});
