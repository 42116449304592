export const sfString = (number, sf = 3) => {
  if (number == null) {
    return "N/A";
  }
  return number.toLocaleString("en-US", {
    maximumSignificantDigits: sf,
  });
};

export const threeSFString = (number, { emptyValue = "N/A" } = {}) => {
  if (number == null || isNaN(number)) {
    return emptyValue;
  }
  return number.toLocaleString("en-US", {
    maximumSignificantDigits: 3,
  });
};

export const dpString = (dp, params = {}) => (number) => {
  if (number == null) {
    return "N/A";
  }
  const formatted = number.toLocaleString("en-US", {
    maximumFractionDigits: dp,
    minimumFractionDigits: dp,
  });
  return `${params.prefix || ""}${formatted}${params.suffix || ""}`;
};

export const percentage = (dp) => (number) => {
  if (number == null || !isFinite(number)) {
    return "N/A";
  }

  return `${(number * 100).toLocaleString("en-US", {
    maximumFractionDigits: dp,
    minimumFractionDigits: dp,
  })}%`;
};

export const currencyFormat = (value) => {
  if (value == null) {
    return null;
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(value);
};

const alphabet = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];
export const numberToAlphabetical = (value) => {
  return (
    (value >= 26 ? numberToAlphabetical(((value / 26) >> 0) - 1) : "") +
    alphabet[value % 26 >> 0]
  );
};

const defaultTicks = [1.2, 1.6, 2, 2.4, 3.2, 4, 5, 6, 8, 10];

export const roundUpNice = (value, ticks = defaultTicks) => {
  if (value === Infinity) {
    return Infinity;
  }
  const log = Math.log10(value);
  const integerPart = Math.floor(log);
  const fraction = log - integerPart;
  const tick = ticks.find((tick) => fraction < Math.log10(tick));
  return tick * Math.pow(10, integerPart);
};

export const isNumberPrefix = (value) => {
  return /^-?[0-9]*\.?[0-9]*$/.test(value);
};

export const parseFloatOrNull = (value) => {
  if (typeof value === "number") {
    return isNaN(value) ? null : value;
  } else if (typeof value === "string") {
    if (!isNumberPrefix(value.trim())) {
      return null;
    } else {
      const parsedValue = parseFloat(value);
      return isNaN(parsedValue) ? null : parsedValue;
    }
  } else {
    return null;
  }
};

export const approxEqual = (value1, value2, { epsilon = 1e-6 } = {}) => {
  return Math.abs(value2 - value1) < epsilon;
};

export const trimZeroes = (value) => {
  if ((!value && value !== 0) || typeof value === "boolean" || isNaN(value)) {
    return null;
  }
  if (typeof value !== "string") {
    value = value.toString();
  }
  if (!value.includes(".")) {
    return value;
  }
  let trimmed = value;
  while (trimmed.substr(trimmed.length - 1, 1) === "0") {
    trimmed = trimmed.substr(0, trimmed.length - 1);
  }
  if (trimmed.endsWith(".")) {
    trimmed = trimmed.substr(0, trimmed.length - 1);
  }
  return trimmed;
};

export const abbreviated = (value) => {
  if (typeof value !== "number" || isNaN(value)) {
    return null;
  }

  const fix = (value, precision) =>
    trimZeroes(value.toFixed(precision).toString());

  const abs = Math.abs(value);

  if (abs < 10_000) {
    const result = fix(value, 0) + "";
    return result === "10000" ? "10k" : result;
  } else if (abs < 1_000_000) {
    const result = fix(value / 1_000, 1) + "k";
    return result === "1000k" ? "1m" : result;
  } else if (abs < 1_000_000_000) {
    const result = fix(value / 1_000_000, 2) + "m";
    return result === "1000m" ? "1b" : result;
  } else {
    return fix(value / 1_000_000_000, 2) + "b";
  }
};

export const rangesOverlap = (minA, maxA, minB, maxB) => {
  const allOfType = (type, ...values) => {
    if (values.length === 0) {
      return false;
    }
    for (const key in values) {
      if (typeof values[key] !== type) {
        return false;
      }
    }
    return true;
  };

  if (!allOfType("number", minA, maxA, minB, maxB)) {
    return false;
  } else if (minA > maxA || minB > maxB) {
    return false;
  }

  if (maxA <= minB || minA >= maxB) {
    return false;
  } else if (minA >= minB && minA <= maxB) {
    return true;
  } else if (maxA >= minB && maxA <= maxB) {
    return true;
  } else if (minB >= minA && maxB <= maxA) {
    return true;
  }

  return false;
};

export const toDecimalFromPercent = (x) => {
  return x == null ? x : x / 100;
};

export const toPercentFromDecimal = (x) => {
  return x == null ? x : x * 100;
};

export const normaliseRatio = (a, b, def) => {
  const na = Number(a);
  const nb = Number(b);
  if (!na || !nb) return def;
  const denom = nb / na;
  return "1 : " + denom.toFixed(1);
};

export const normaliseQuotient = (a, b, def) => {
  const na = Number(a);
  const nb = Number(b);
  if (!na || !nb) return def;
  return "" + Math.ceil(nb / na);
};

export const distribute = (value, slots) => {
  if (value == null || slots == null || slots === 0) {
    return null;
  }
  if (value === 0) {
    return Array(slots).fill(0);
  }
  const negative = value < 0;
  value = Math.abs(value);
  const mod = value % slots;
  const lcd = (value - mod) / slots;
  const out = Array(slots).fill(lcd);
  const step = Math.ceil(slots / (mod + 1));
  for (let i = mod; i > 0; i--) {
    const index = Math.min(i * step - 1, out.length - 1);
    out[index]++;
  }
  return negative ? out.map((x) => -x) : out;
};

export const quantiles = (values, groups) => {
  if (
    values == null ||
    groups == null ||
    groups < 2 ||
    values.length === 0 ||
    values.length < groups
  ) {
    return null;
  }
  const sorted = [...values].sort((a, b) => a - b);
  groups = Math.min(groups, sorted.length);
  const step = Math.ceil(sorted.length / groups);
  const quantiles = [];
  for (let i = 1; i < groups; i++) {
    const index = i * step - 1;
    quantiles.push(sorted[index]);
  }
  return quantiles;
};

export const clamp = (value, lower, upper) => {
  if (value == null || lower == null || upper == null) {
    return null;
  }
  if (lower > upper) {
    const temp = upper;
    upper = lower;
    lower = temp;
  }
  return Math.max(Math.min(value, upper), lower);
};

export const cleanXS = ({ limit, attachment }) => {
  return `${abbreviated(limit)} x ${abbreviated(attachment)}`;
};

export const parseNumberString = (str) => {
  const validPattern = /^-?[0-9, ]*\.?[0-9, ]*(e[0-9]+)?[kKmMbBtT]?$/;

  const getMultiplicand = (lastChar) => {
    switch (lastChar) {
      case "k":
        return 1e3;
      case "m":
        return 1e6;
      case "b":
        return 1e9;
      case "t":
        return 1e12;
      default:
        return 1;
    }
  };

  if (str === "") {
    return null;
  }
  if (!validPattern.exec(str)) {
    return null;
  }
  str = str.replace(/[ ,]/g, "");
  const parsedFloat = parseFloat(str);
  const multiplicand = getMultiplicand(str.slice(-1).toLowerCase());

  if (!isNaN(parsedFloat)) {
    return parsedFloat * multiplicand;
  }
  return null;
};
