import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DefaultLoader from "components/common/DefaultLoader";
import * as staticDataService from "services/staticDataService";

const LOADING_KEY = "__LOADING__";
const NONE_KEY = "__NONE__";

const BusinessUnitSelector = ({ value, onChange, disabled }) => {
  const { data: config, isLoading } = staticDataService.useConfigQuery(
    "business_units"
  );

  return (
    <FormControl variant={"filled"} fullWidth>
      <InputLabel className={"input-label"}>{"Business Unit"}</InputLabel>
      <Select
        value={isLoading ? LOADING_KEY : !!value ? value : NONE_KEY}
        onChange={(event) => {
          const value = event?.target?.value ?? NONE_KEY;
          if (!value || value === NONE_KEY) {
            onChange(null);
          } else if (value !== LOADING_KEY) {
            onChange(value);
          }
        }}
        disabled={isLoading || disabled}
        fullWidth
      >
        {isLoading && (
          <MenuItem value={LOADING_KEY}>
            <DefaultLoader
              color={"#dc7f4c"}
              size={16}
              style={{
                display: "block",
                textAlign: "center",
              }}
            />
          </MenuItem>
        )}
        <MenuItem value={NONE_KEY}>{"None"}</MenuItem>
        {(config?.active ?? []).map(({ key, name }) => (
          <MenuItem key={key} value={key}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BusinessUnitSelector;
