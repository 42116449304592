export const hashFile = async (
  file,
  { algorithm = "SHA-256", format = "base64" } = {}
) => {
  return new Promise((resolve, reject) => {
    if (!["SHA-1", "SHA-256", "SHA-384", "SHA-512"].includes(algorithm)) {
      reject(`Unsupported algorithm '${algorithm}' received in hashFile`);
      return;
    }
    if (!["base64", "hex"].includes(format)) {
      reject(`Unsupported format '${format}' received in hashFile`);
      return;
    }

    let reader = new FileReader();

    reader.onload = async () => {
      const digest = await crypto.subtle.digest(
        algorithm,
        new Uint8Array(reader.result)
      );
      const hashArray = Array.from(new Uint8Array(digest));
      const hash =
        format === "hex"
          ? hashArray.map((h) => h.toString(16).padStart(2, "0")).join("")
          : btoa(String.fromCharCode(...hashArray));
      resolve(hash);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
};

export const hashString = async (str) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export const hashDict = async (dict) => {
  const hash = await hashString(JSON.stringify(dict));
  return hash;
};
