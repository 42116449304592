import * as claimsActionTypes from "store/actions/input/claims/claimsActionTypes";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

export const INIT_STATE = {
  chartZoom: {},
  claimsFileColumnMappingDisplay: {
    claimsKey: null,
    state: "IDLE",
    data: {},
  },
};

const tempClaimsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case claimsActionTypes.CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOADED:
      if (
        action.payload["claims-key"] !==
        state.claimsFileColumnMappingDisplay.claimsKey
      )
        return state;
      return {
        ...state,
        claimsFileColumnMappingDisplay: {
          ...state.claimsFileColumnMappingDisplay,
          state: "LOADED",
          data: action.payload,
        },
      };
    case claimsActionTypes.SHOW_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY:
      return {
        ...state,
        claimsFileColumnMappingDisplay: {
          claimsKey: action.payload,
          state: "LOADING",
          data: {},
        },
      };
    case claimsActionTypes.CLAIMS_FILE_COLUMN_MAPPING_DISPLAY_LOAD_FAILED:
      return {
        ...state,
        claimsFileColumnMappingDisplay: {
          ...state.claimsFileColumnMappingDisplay,
          state: "FAILED",
        },
      };
    case claimsActionTypes.HIDE_CLAIMS_FILE_COLUMN_MAPPING_DISPLAY:
      return {
        ...state,
        claimsFileColumnMappingDisplay: {
          claimsKey: null,
          state: "IDLE",
          data: {},
        },
      };
    case persistenceActionTypes.NEW_SUBMISSION:
    case persistenceActionTypes.RENEW_SUBMISSION:
      return INIT_STATE;
    case claimsActionTypes.CLAIMS_FILE_DROPPED:
      return {
        ...INIT_STATE,
        sheetSummaries: null,
      };
    case claimsActionTypes.FILE_SHEET_NAMES_RETURNED:
      const summaries = action.payload.sheets.map((sheet) => ({
        name: sheet.name,
        rowCount: sheet.rows,
        topLeft: sheet.data.map((row) => row.map((cell) => ({ value: cell }))),
      }));
      return {
        ...state,
        sheetSummaries: summaries,
      };
    case claimsActionTypes.SHEET_NAMES_RETURNED:
      return {
        ...state,
        sheetSummaries: action.payload.sheets,
      };
    case claimsActionTypes.UPDATE_CLAIMS_ZOOM:
      return {
        ...state,
        chartZoom: {
          ...state?.chartZoom,
          [action.payload.name]: action.payload.zoom,
        },
      };
    case claimsActionTypes.CLEAR_CHART_ZOOM:
      return {
        ...state,
        chartZoom: {
          ...state?.chartZoom,
          [action.payload]: undefined,
        },
      };
    default:
      return state;
  }
};

export default tempClaimsReducer;
