import * as types from "store/actions/input/exposure/hazardActionTypes";
import * as actions from "store/actions/input/exposure/hazardActions";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

export const INIT_STATE = {};

const hazardProfileReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.exposures?.hazardProfile,
      };
    case types.HAZARD_MAPPING_SUGGESTIONS_RETURNED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columnMappingSuggestions: action.payload.suggestions,
        },
      };
    case types.HAZARD_SHEET_TOP_LEFT_SELECTED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          topLeft: action.payload,
          columns: undefined,
        },
      };
    case types.UPDATE_HAZARD_NOTE:
      return {
        ...state,
        note: action.payload,
      };
    case types.HAZARD_FILE_MAPPING_FAILED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          error: action.payload.error,
        },
      };
    case types.UPDATE_HAZARD_AS_AT_DATE:
      return {
        ...state,
        asAtDate: action.payload,
      };
    case types.HAZARD_FILE_DROPPED:
      return {
        ...state,
        activeFile: {
          fileIsUploading: true,
          sheetsDialogIsOpen: true,
          file: action.payload,
          step: 0,
        },
      };
    case types.HAZARD_COLUMN_MAPPING_UPDATED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columnMapping: {
            ...state.activeFile.columnMapping,
            [action.payload.target]: action.payload.selected
              ? action.payload.column
              : state.activeFile.columnMapping[action.payload.target] ===
                action.payload.column
              ? null
              : state.activeFile.columnMapping[action.payload.target],
          },
        },
      };
    case types.HAZARD_DIALOG_NEXT_STEP:
      if (!state.activeFile) return state;
      const cmState = state.activeFile.columnMapping?.["_STATE"];
      const sugState = state.activeFile.columnMappingSuggestions?.["_STATE"];
      const hasState = cmState || (cmState === undefined && sugState);
      const inc = state?.activeFile?.step === 1 && hasState ? 2 : 1;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          step: (state.activeFile?.step || 0) + inc,
        },
      };
    case actions.transformHazardFile.started.toString():
      if (state.activeFile) {
        return {
          ...state,
          activeFile: {
            ...state.activeFile,
            transformStatus: "RUNNING",
            transformError: null,
          },
        };
      } else {
        return state;
      }
    case actions.transformHazardFile.succeeded.toString():
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          transformStatus: "SUCCEEDED",
          transformError: null,
        },
        files: [...(state.files ?? []), action.payload.fileId],
      };
    case actions.transformHazardFile.failed.toString():
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          transformStatus: "FAILED",
          transformError: action.payload.errorMessage,
        },
      };
    case types.UPDATE_HAZARD_STATE:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          state: action.payload,
        },
      };
    case types.HAZARD_DIALOG_PREV_STEP:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          step: state.activeFile.step === 0 ? 0 : state.activeFile.step - 1,
        },
      };
    case types.HAZARD_FILE_UPLOAD_FAILED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: undefined,
      };
    case types.HAZARD_SHEET_SELECTED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          activeSheet: action.payload,
          topLeft: { row: 0, column: 0 },
          columns: undefined,
        },
      };
    case types.HAZARD_DIALOG_CLOSED:
      return {
        ...state,
        activeFile: undefined,
      };
    case types.HAZARD_SHEET_LIST_RETURNED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          activeSheet: 0,
          sheetSummaries: action.payload.sheetSummaries,
          columns: undefined,
          columnMapping: {},
        },
      };
    case types.HAZARD_FILE_UPLOADED:
      if (!state.activeFile) return state;
    // fallthrough intentional
    case types.HAZARD_FILE_PRELOADED:
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          ...action.payload,
          fileIsUploading: false,
          originFileId: action.payload.fileId,
        },
      };
    case types.HAZARD_COLUMN_NAMES_REQUESTED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columns: null,
          columnsState: {
            status: "running",
          },
        },
      };
    case types.HAZARD_COLUMN_NAMES_RETURNED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columns: action.payload,
          columnsState: {
            status: "succeeded",
          },
        },
      };
    case types.HAZARD_COLUMN_NAMES_FAILED:
      if (!state.activeFile) return state;
      return {
        ...state,
        activeFile: {
          ...state.activeFile,
          columns: null,
          columnsState: {
            status: "failed",
          },
        },
      };
    case types.HIDE_HAZARD_FILE:
      return {
        ...state,
        files: (state.files ?? []).filter(
          (fileId) => fileId !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default hazardProfileReducer;
