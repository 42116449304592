import { createAction } from "@reduxjs/toolkit";

export const retrieveValidClaimsFileFormats = {
  requested: createAction("staticData/retrieveValidClaimsFileFormats"),
  succeeded: createAction(
    "staticData/retrieveValidClaimsFileFormats/succeeded",
    (validClaimsFileFormats) => ({
      payload: validClaimsFileFormats,
    })
  ),
};

export const retrieveAnalyticsConfig = {
  requested: createAction("staticData/retrieveAnalyticsConfig"),
  succeeded: createAction("staticData/retrieveAnalyticsConfig/succeeded"),
};

export const retrieveInflationModelConfig = {
  requested: createAction("staticData/retrieveInflationModelConfig"),
  succeeded: createAction(
    "staticData/retrieveInflationModelConfig/succeeded",
    (config) => ({
      payload: config,
    })
  ),
};

export const retrieveSovTortCaps = {
  requested: createAction("staticData/retrieveSovTortCaps"),
  succeeded: createAction(
    "staticData/retrieveSovTortCaps/succeeded",
    (sovTortCaps) => ({
      payload: sovTortCaps,
    })
  ),
};

export const retrieveLayerStatusLabels = {
  requested: createAction("staticData/retrieveLayerStatusLabels"),
  succeeded: createAction(
    "staticData/retrieveLayerStatusLabels/succeeded",
    (labels) => ({
      payload: labels,
    })
  ),
};

export const retrieveConfig = {
  requested: createAction("staticData/retrieveConfig"),
  succeeded: createAction("staticData/retrieveConfig/succeeded"),
};
