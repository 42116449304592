import * as logger from "../common/logger";
import FileUpload from "./FileUpload";
import { Button } from "@mui/material";
import { createFileEntry } from "fileUtils";
import { useSnackbar } from "notistack";
import { useImperativeHandle, useState } from "react";
import * as uuid from "uuid";

const FilesFileUpload = ({
  onDrop,
  oneAtATime = true,
  disabled = false,
  size,
  asAtDateNotRequired,
  dropMessage = "Drop an .xlsx, .csv, or .tsv file here to upload",
  updateAsAtDate,
  asAtDate,
  recommendedAsAtDate,
  uploadInitiated = null,
  waitText = false,
  mimeTypes = null,
  aborter = null,
  options = null,
}) => {
  const [runningFiles, setRunningFiles] = useState({});

  const addFile = (fileControl) => {
    setRunningFiles((rf) => {
      rf[fileControl.entry] = fileControl;
      return rf;
    });
  };
  const removeFile = (fileControl) => {
    setRunningFiles((rf) => {
      delete rf[fileControl.entry];
      return rf;
    });
  };

  useImperativeHandle(
    aborter,
    () => ({
      abort: () => {
        setLoadingFiles(false);
        for (const index in runningFiles) {
          const fileControl = runningFiles[index];
          fileControl.abort = true;
        }
      },
    }),
    [runningFiles]
  );

  const {
    enqueueSnackbar: queueMessage,
    closeSnackbar: closeMessage,
  } = useSnackbar();
  const [loadingFiles, setLoadingFiles] = useState(false);
  const dropCatch = (files) => {
    setLoadingFiles(true);
    let filecount = files.length;
    const dec = () => {
      filecount -= 1;
      if (filecount === 0) {
        setLoadingFiles(false);
      }
    };
    const processFile = (file) => {
      const fileControl = {
        entry: uuid.v4(),
        file,
        abort: false,
      };
      if (typeof uploadInitiated === "function") uploadInitiated(file);
      createFileEntry(file, waitText, mimeTypes)
        .then((id) => {
          removeFile(fileControl);
          if (fileControl.abort) return;
          onDrop(id, file);
          dec();
        })
        .catch((error) => {
          removeFile(fileControl);
          if (fileControl.abort) return;
          logger.error("ERROR", error);
          dec();
          queueMessage(`File "${file.name}" cannot be uploaded [${error}].`, {
            variant: "error",
            autoHideDuration: 10_000,
            action: (msgId) => (
              <Button
                color={"secondary"}
                size={"small"}
                onClick={() => closeMessage(msgId)}
              >
                {"Dismiss"}
              </Button>
            ),
          });
          logger.error("Error uploading file", { breadcrumb: { file } });
        });
      return fileControl;
    };
    if (files.size !== 0) {
      if (oneAtATime) {
        filecount = 1;
        addFile(processFile(files[0]));
      } else {
        files.forEach((f) => addFile(processFile(f)));
      }
    } else setLoadingFiles(false);
  };
  return (
    <FileUpload
      {...{
        onDrop: dropCatch,
        disabled,
        loading: loadingFiles,
        size,
        asAtDateNotRequired,
        dropMessage,
        updateAsAtDate,
        asAtDate,
        recommendedAsAtDate,
        options,
      }}
    />
  );
};

export default FilesFileUpload;
