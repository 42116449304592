import AuthenticatedApp from "./components/AuthenticatedApp";
import ErrorBoundary from "./components/ErrorBoundary";
import { loadMaintenanceStatus } from "./store/actions/temp/maintenance/maintenanceStatusActions";
import "./styles/styles.scss";
import { getMaintenanceControlUrl } from "config";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    loadMaintenanceStatus(getMaintenanceControlUrl())(dispatch);
  });
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AuthenticatedApp />
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
