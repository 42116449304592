import * as arrays from "common/arrays";
import * as numbers from "common/numbers";

const LAYER_STATUS_STAGE = {
  SHELVED: "IGNORE",
  DRAFT: "DRAFT",
  QUOTE_REQUESTED: "DRAFT",
  DECLINED_TO_QUOTE: "DRAFT",
  QUOTED: "QUOTED",
  BOUND: "BOUND",
  NOT_TAKEN_UP: "QUOTED",
};

export const extractInPlayLayerIds = (layers) => {
  if (!arrays.hasItems(layers)) {
    return [];
  }

  const layerInfo = layers
    .map((layer) => ({
      id: layer.id,
      layer,
      stage: LAYER_STATUS_STAGE?.[layer?.status] ?? null,
    }))
    .filter(({ id, stage }) => id != null && stage !== "IGNORE");

  if (layerInfo.some(({ stage }) => stage === "BOUND")) {
    return layerInfo
      .filter(({ stage }) => stage == null || stage === "BOUND")
      .map(({ id }) => id);
  } else if (layerInfo.some(({ stage }) => stage === "QUOTED")) {
    return layerInfo
      .filter(({ stage }) => stage == null || stage === "QUOTED")
      .map(({ id }) => id);
  } else {
    return layerInfo.map(({ id }) => id);
  }
};

export const extractProgramStatus = (layers) => {
  if (!arrays.hasItems(layers)) {
    return "DRAFT";
  }
  const layerStatuses = layers
    .map((layer) => layer.status)
    .filter((status) => status != null);
  for (const status of [
    "BOUND",
    "QUOTED",
    "QUOTE_REQUESTED",
    "NOT_TAKEN_UP",
    "DECLINED_TO_QUOTE",
  ]) {
    if (layerStatuses.includes(status)) {
      return status;
    }
  }
  return "DRAFT";
};

export const shortLayerName = (layer) =>
  `$${numbers.abbreviated(layer?.limit)} xs $${numbers.abbreviated(
    layer?.attachment
  )}`;
