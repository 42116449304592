import * as numbers from "common/numbers";
import * as towerResolvers from "domain/towerResolvers";
import { createSelector } from "reselect";
import * as towers from "store/selectors/common/towers";
import * as programSelectors from "store/selectors/input/program/programSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

const getTowerLayers = (state) => state.pricing?.tower?.layers || [];
export const getPrimariesLayers = (state) =>
  state.pricing?.primaryLayers?.layers || [];

export const getTower = createSelector(
  [getTowerLayers, programSelectors.selectResolvedProgram],
  (layers, program) =>
    towerResolvers
      .resolveLayers(towers.fillAttachmentInLayers(layers), { program })
      .map((layer) => {
        const technicalFactor =
          (100 - program.brokerage - program.otherAcquisitionCosts) / 100;
        const profitFactor = (100 - program.profit - program.expenses) / 100;
        const requiredImprovexRPM =
          layer.towerPricing?.improvexRatePerMillion /
          technicalFactor /
          profitFactor;

        return {
          ...layer,
          targetPrice: numbers.parseFloatOrNull(
            (layer.grossRPM /
              layer.towerPricing?.ratePerMillionScaledFromUwPricedLayer) *
              100
          ),
          marginOnRequiredModelled: numbers.parseFloatOrNull(
            1 - requiredImprovexRPM / layer.grossRPM
          ),
          carrier: (layer.shares || []).map((_) => _.carrier).join(" / "),
        };
      })
);

export const getInvertedTower = createSelector([getTower], (tower) => {
  const inverted = tower
    .filter((x) => !!x.limit)
    .sort((a, b) => b.attachment - a.attachment);

  if (inverted.length > 0 && inverted[inverted.length - 1].attachment !== 0) {
    inverted.push({
      attachment: 0,
      limit: inverted[inverted.length - 1].attachment,
      shares: [
        {
          carrier: null,
          shareOfLimit: inverted[inverted.length - 1].attachment,
        },
      ],
    });
  }

  return inverted;
});

export const getSelectedLayerIndex = (state) =>
  state.pricing.tower.selectedLayer;

const getBaseLayer = (state) => state.pricing.baseLayer;

const getILFParams = (state) => ({
  naicsCode: state.input.program.naics?.code,
  turnoverCategory: state.input.program.turnoverCategory,
  in_US: state.input.program.inUS,
});

export const getTowerPricingScalingLayerForRequest = createSelector(
  [pricingSelectors.getTowerPricingScalingLayer],
  (towerPricingScalingLayer) => {
    if (
      towerPricingScalingLayer &&
      towerPricingScalingLayer.limit &&
      towerPricingScalingLayer.attachment != null &&
      towerPricingScalingLayer.uwSelectedMeasure.requiredPremium
    ) {
      return {
        limit: towerPricingScalingLayer.limit,
        attachment: towerPricingScalingLayer.attachment,
        marketGrossWrittenPremium:
          towerPricingScalingLayer.uwSelectedMeasure.requiredPremium,
      };
    }

    return undefined;
  }
);

export const getTowerPricingRequest = createSelector(
  [
    getSelectedLayerIndex,
    getBaseLayer,
    getTower,
    getILFParams,
    pricingSelectors.getNumberOfLossesForPricingNumber,
    getTowerPricingScalingLayerForRequest,
  ],
  (
    selectedLayer,
    baseLayer,
    tower,
    ilfParams,
    expectedBaseLayerLosses,
    towerPricingScalingLayerForRequest
  ) => {
    return {
      selectedLayer,
      baseLayer: {
        attachment: baseLayer?.attachment ?? null,
        limit: baseLayer?.limit ?? null,
      },
      layers: (tower ?? []).map((layer) => ({
        limit: layer?.limit ?? null,
        attachment: layer?.attachment ?? null,
        marketGrossWrittenPremium: layer?.grossPremium ?? null,
      })),
      ...ilfParams,
      expectedBaseLayerLosses,
      uwPricedLayer: towerPricingScalingLayerForRequest,
    };
  }
);

const selectTower = (state) => state?.pricing?.tower ?? {};

export const selectResolvedTower = stateSelectors.createSelector(
  [selectTower, getTower],
  (towerInput, resolvedTowerLayers) => {
    return {
      ...towerInput,
      layers: resolvedTowerLayers,
    };
  }
);
