import * as actionTypes from "./expiringProgramActionTypes";

export const updateLimit = (index, limit) => ({
  type: actionTypes.UPDATE_EXPIRING_PROGRAM_LIMIT,
  payload: { index, limit },
});

export const updateCarrier = (index, carrier) => ({
  type: actionTypes.UPDATE_EXPIRING_PROGRAM_CARRIER,
  payload: { index, carrier },
});

export const updateAttachment = (index, attachment) => ({
  type: actionTypes.UPDATE_EXPIRING_PROGRAM_ATTACHMENT,
  payload: { index, attachment },
});

export const updateMarketGrossWrittenPremium = (index, grossPremium) => ({
  type: actionTypes.UPDATE_EXPIRING_PROGRAM_MARKET_GROSS_WRITTEN_PREMIUM,
  payload: { index, grossPremium },
});

export const deleteLayer = (index) => ({
  type: actionTypes.DELETE_EXPIRING_PROGRAM_LAYER,
  payload: index,
});
