import * as fileUtils from "../../../fileUtils";
import AutoFixNormalIcon from "@mui/icons-material/AutoFixNormal";
import IconButton from "components/common/IconButton";
import SmallLoader from "components/common/SmallLoader";
import { useState } from "react";

const CleanMicrosoftFileButton = ({ support, isDisabled }) => {
  const [cleanState, setCleanState] = useState(null);
  const clean = () => {
    setCleanState("CLEANING");
    fileUtils
      .cleanOfficeFile(support.getFileId())
      .then(() => {
        setCleanState("CLEANED");
        support.refresh();
      })
      .catch((error) => {
        setCleanState("ERRORED");
      });
  };
  return (
    <div style={{ width: "40px" }}>
      {cleanState === "CLEANING" || !support.isFileLoaded() ? (
        <SmallLoader />
      ) : (
        <IconButton
          icon={AutoFixNormalIcon}
          onClick={clean}
          isDisabled={isDisabled}
          error={cleanState === "ERRORED" && "Failed to clean"}
        />
      )}
    </div>
  );
};

export default CleanMicrosoftFileButton;
