import { ClickableCell, HeadingCell, ValueCell } from "./Cell";
import { Stack } from "@mui/material";
import { abbreviated } from "common/numbers";
import { useSelector } from "react-redux";
import * as lossDevelopmentStore from "store/lossDevelopment";

function castToString(value) {
  if (value === null || value === undefined) {
    return String(value);
  }
  return value.toString();
}

const Ultimate = ({
  id,
  label,
  headingStyles,
  centered,
  onHeaderClick,
  isHeaderActive,
  onCellClick,
  isCellActive,
  policyYears,
  method,
}) => {
  const data = useSelector(lossDevelopmentStore.selectLossDevelopment)
    .triangles?.[id];
  const ultimates = data?.ultimateLossesByPolicyYear[method];

  return (
    <Stack alignItems={centered ? "center" : ""}>
      <HeadingCell
        centered={true}
        sx={headingStyles}
        label={label || "Ultimate"}
        onClick={() => onHeaderClick({ method })}
        active={isHeaderActive && isHeaderActive({ method })}
      />

      {(policyYears || data?.policyYears)?.map((year) => {
        const index = (data?.policyYears || [])
          .map(castToString)
          .indexOf(castToString(year));
        const ultimate = ultimates?.[index]?.value;
        if (ultimate == null) {
          return <ValueCell borderless={true} key={year} />;
        }
        if (typeof onCellClick === "function") {
          return (
            <ClickableCell
              key={year}
              active={isCellActive && isCellActive({ method, year })}
              onClick={() => onCellClick({ method, year })}
            >
              {abbreviated(ultimate)}
            </ClickableCell>
          );
        }
        return (
          <ValueCell borderless={true} key={year}>
            {abbreviated(ultimate)}
          </ValueCell>
        );
      })}
    </Stack>
  );
};

export default Ultimate;
