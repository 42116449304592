import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as SOVResolver from "domain/SOVResolver";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";

const SOVSlice = createSlice({
  name: "SOV",
  initialState: {},
  reducers: {
    setSOV: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...values,
      };
    },
    updateSOV: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      return {
        ...state,
        ...values,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) => ({
          ...action?.payload?.data?.state?.SOV,
        })
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, () => ({}))
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, () => ({}));
  },
});

export const selectSOVInput = (state) => state?.SOV ?? null;

export const selectSOV = createSelector([selectSOVInput], (SOVInput) =>
  SOVResolver.resolve(SOVInput)
);

export const { setSOV, updateSOV } = SOVSlice.actions;

export default SOVSlice;
