import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import * as charts from "common/charts";
import Component from "components/Component";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import ClaimsSlider from "components/inputs/claims/ClaimsSlider";
import FilteredClaimsChart from "components/inputs/claims/FilteredClaimsChart";
import * as config from "config.js";
import * as staticDataService from "services/staticDataService";

const buildErrorMessage = (baseLayerBurn) => {
  const missingInputs = [];
  if ((baseLayerBurn?.limit ?? 0) <= 0) {
    missingInputs.push("Base layer limit");
  }
  if ((baseLayerBurn?.attachment ?? 0) <= 0) {
    missingInputs.push("Base layer attachment");
  }

  return missingInputs.length ? (
    <>
      <Typography>{"Some fields required for pricing are missing:"}</Typography>
      <ul>
        {missingInputs.map((x, i) => (
          <li key={i}>
            <Typography>{x}</Typography>
          </li>
        ))}
      </ul>
    </>
  ) : null;
};

export const Claims = ({
  baseLayerBurn,
  updateBaseLayerBurn,
  data,
  disabled,
}) => {
  const {
    data: generalConfiguration,
    isLoading: isGeneralConfigurationLoading,
  } = staticDataService.useConfigQuery("general_configuration");

  const numberTicks = charts.makeTicksForNumericDomain(
    charts.getNumberDomain(data)
  );
  const numberDomain = [numberTicks[0], numberTicks.at(-1)];
  const dateDomain = charts.getDateDomain(data);

  const onZoom = (fromMonths, toMonths) => {
    const makeDateFromMonths = (months) => {
      if (months == null) {
        return null;
      }
      const month = Math.floor(months % 12) + 1;
      const year = Math.floor(months / 12);
      return `${year}-${String(month).padStart(2, "0")}-01`;
    };
    updateBaseLayerBurn({
      startDate: makeDateFromMonths(fromMonths),
      endDate: makeDateFromMonths(toMonths),
    });
  };

  const makeSliderMaxValue = (value) =>
    Math.min(1e9, Math.max(data?.biggestClaim ?? 0, 3 * value, 1e7));

  const errorMessage = buildErrorMessage(baseLayerBurn);

  return (
    <Component
      title={"Base Layer"}
      subtitle={"Set the base layer"}
      xs_width={12}
    >
      <Grid container spacing={config.GRID_SPACING} alignItems={"stretch"}>
        <Grid item xs={12}>
          <Box mr={6}>
            <FilteredClaimsChart
              disabled={disabled}
              data={data}
              filter={{
                limit: baseLayerBurn?.limit ?? 0,
                attachment: baseLayerBurn?.attachment ?? 0,
                from: baseLayerBurn?.startDate ?? "",
                to: baseLayerBurn?.endDate ?? "",
              }}
              dateDomain={dateDomain}
              numberTicks={numberTicks}
              numberDomain={numberDomain}
              onZoom={onZoom}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ClaimsSlider
            title={"Limit"}
            value={baseLayerBurn?.limit ?? 0}
            onChangeNumber={(limit) => updateBaseLayerBurn({ limit })}
            min={0}
            max={makeSliderMaxValue(baseLayerBurn?.limit ?? 0)}
          />
        </Grid>
        <Grid item xs={12}>
          <ClaimsSlider
            title={"Attachment"}
            value={baseLayerBurn?.attachment ?? 0}
            onChangeNumber={(attachment) => updateBaseLayerBurn({ attachment })}
            min={generalConfiguration?.baseLayerBurn?.minimumAttachment ?? 0}
            max={makeSliderMaxValue(baseLayerBurn?.attachment ?? 0)}
            disabled={isGeneralConfigurationLoading}
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <FormattedDatePicker
            label={"From"}
            onChangeDate={(date) => updateBaseLayerBurn({ startDate: date })}
            value={baseLayerBurn?.startDate ?? ""}
            fullWidth
          />
        </Grid>
        <Grid item lg={3} xs={6}>
          <FormattedDatePicker
            label={"To"}
            onChangeDate={(date) => updateBaseLayerBurn({ endDate: date })}
            value={baseLayerBurn?.endDate ?? ""}
            fullWidth
          />
        </Grid>
        {!disabled && (
          <Grid
            item
            container
            lg={6}
            spacing={config.GRID_SPACING}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <Grid item>
              <Button
                disableElevation
                variant={"contained"}
                color={"secondary"}
                onClick={() =>
                  updateBaseLayerBurn({
                    startDate: null,
                    endDate: null,
                    limit: null,
                    attachment: null,
                  })
                }
              >
                {"Reset"}
              </Button>
            </Grid>
          </Grid>
        )}
        {!!errorMessage && (
          <Grid item xs={12}>
            <Alert severity={"error"}>{errorMessage}</Alert>
          </Grid>
        )}
      </Grid>
    </Component>
  );
};

export default Claims;
