import inputSaga from "./input/inputSaga";
import peersSaga from "./peers/peersSaga";
import persistenceSaga from "./persistence/persistenceSaga";
import pricingSaga from "./pricing/pricingSaga";
import tempSaga from "./temp/tempSaga";
import { fork } from "redux-saga/effects";

export default function* rootSaga() {
  yield fork(persistenceSaga);
  yield fork(peersSaga);
  yield fork(inputSaga);
  yield fork(pricingSaga);
  yield fork(tempSaga);
}
