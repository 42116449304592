import * as config from "../../config";
import PrettyNumberTextField from "../common/PrettyNumberTextField";
import Grid from "@mui/material/Grid";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import React from "react";

const PaymentTermsForm = ({ paymentTerms, setPaymentTerms }) => {
  const updatePaymentTermsDays = (days) => {
    const newPaymentTerms = days == null ? null : { days };
    setPaymentTerms(newPaymentTerms);
  };

  return (
    <Grid item container xs={12} spacing={config.GRID_SPACING}>
      <Grid item xs={6}>
        <PrettyNumberTextField
          type={"text"}
          InputLabelProps={{ shrink: true }}
          InputProps={{ spellCheck: "false" }}
          label={"Payment Days"}
          autoComplete={"off"}
          variant={"filled"}
          onChangeNumber={updatePaymentTermsDays}
          value={paymentTerms?.days ?? ""}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormattedDatePicker
          label={"Payment Due Date"}
          value={paymentTerms?.dueDate ?? ""}
          fullWidth
          disabled
        />
      </Grid>
    </Grid>
  );
};

export default PaymentTermsForm;
