import Grid from "@mui/material/Grid";
import { threeSFString } from "common/numbers";
import Component from "components/Component";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import { connect } from "react-redux";

const PriorPricing = (props) => {
  if (!props.hasPriorSubmission) {
    return <></>;
  }
  return (
    <Component
      title={"Prior Pricing Parameters"}
      subtitle={"Reference pricing parameters from the expiring submission."}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Base Layer Limit"}
            value={props?.baseLayer?.limit}
            variant={"filled"}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Base Layer Attachment"}
            value={props?.baseLayer?.attachment}
            variant={"filled"}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Selected # of Losses"}
            value={
              props.underwriterSelectedNumberOfClaims ||
              threeSFString(props.averageNumberOfClaims[0]?.claims, {
                emptyValue: "",
              })
            }
            variant={"filled"}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
        <Grid item xs={6}>
          <PrettyNumberTextField
            label={"Model Weight"}
            value={props?.improvexWeight}
            variant={"filled"}
            fullWidth
            InputLabelProps={{ shrink: true }}
            disabled
          />
        </Grid>
      </Grid>
    </Component>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {
    hasPriorSubmission: state.temp?.priorState?.state ? true : null,
    baseLayer: state.temp?.priorState?.state?.pricing?.baseLayer ?? null,
    averageNumberOfClaims:
      state.temp?.priorState?.state?.pricing?.frequency
        ?.averageNumberOfClaims || [],
    underwriterSelectedNumberOfClaims:
      state.temp?.priorState?.state?.pricing?.frequency
        ?.underwriterSelectedNumberOfClaims ?? null,
    improvexWeight:
      state.temp?.priorState?.state?.pricing?.improvexWeight ?? null,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriorPricing);
