import * as actions from "../../../store/actions/input/claims/claimsActions";
import * as utils from "../../../utils";
import SheetPreviewCell from "./SheetPreviewCell";
import { Alert } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import classnames from "classnames";
import React from "react";
import { connect } from "react-redux";
import Spreadsheet from "react-spreadsheet";

const SheetSelector = (props) => {
  const HighlightedDataViewer = ({ row, column, data, rootRef }) => {
    const active =
      props.topLeft &&
      props.topLeft.row === row &&
      props.topLeft.column === column;
    const onBorder =
      props.topLeft &&
      ((props.topLeft.row === row && props.topLeft.column < column) ||
        (props.topLeft.column === column && props.topLeft.row < row));
    const inside =
      props.topLeft && props.topLeft.row < row && props.topLeft.column < column;
    return (
      <td
        ref={rootRef}
        className={classnames(
          "sheet-preview-cell",
          active && "sheet-preview-cell-active",
          onBorder && "sheet-preview-cell-border",
          inside && "sheet-preview-cell-content",
          data && data.className
        )}
        tabIndex={0}
        onMouseDown={() => props.sheetTopLeftSelected({ row, column })}
      >
        <span>{data?.value || ""}</span>
      </td>
    );
  };

  const activeSheetSummary = props.sheetSummaries[props.activeSheet];

  props?.setHasError?.(!!activeSheetSummary?.hasError);

  return (
    <>
      <DialogContentText>
        {"Select the sheet that contains the claims data."}
      </DialogContentText>
      <form className={"form"} noValidate>
        <FormControl
          margin={"normal"}
          className={"sheet-select-form"}
          variant={"filled"}
          fullWidth
        >
          <InputLabel>{"Selected sheet"}</InputLabel>
          <Select
            autoFocus
            value={props.activeSheet}
            onChange={(e) =>
              props.sheetSelected(props.uploadedFilename, e.target.value)
            }
            inputProps={{
              name: "claims-sheet",
              id: "claims-sheet",
            }}
            className={"claims-sheet-select-select"}
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {props.sheetSummaries.map((sheet, index) => {
              return (
                <MenuItem key={index} value={index}>
                  {`${sheet.name} (${sheet.rowCount} rows)`}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </form>
      {activeSheetSummary && activeSheetSummary.hasError && (
        <Alert severity={"error"}>{"Could not read the sheet"}</Alert>
      )}
      {activeSheetSummary && !activeSheetSummary.hasError && (
        <>
          <DialogContentText>
            {`Select the starting location for this table. Currently: ${utils.getCellName(
              props.topLeft
            )}`}
          </DialogContentText>
          <Spreadsheet
            data={props.sheetSummaries[props.activeSheet].topLeft}
            onActivate={(points) => {
              props.sheetTopLeftSelected(points);
            }}
            Cell={SheetPreviewCell}
            DataViewer={HighlightedDataViewer}
          />
        </>
      )}
    </>
  );
};

const mapDispatchToProps = {
  sheetSelected: actions.sheetSelected,
  sheetTopLeftSelected: actions.sheetTopLeftSelected,
};

const mapStateToProps = (state) => {
  return {
    uploadedFilename: state.input.claims.activeFile?.uploadedFilename,
    topLeft: state.input.claims.activeFile?.topLeft || { row: 0, column: 0 },
    sheets: state.input.claims.activeFile?.sheets,
    activeSheet: state.input.claims.activeFile?.activeSheet,
    sheetSummaries: state.temp?.claims?.sheetSummaries || [],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SheetSelector);
