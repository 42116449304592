import IsMappedIcon from "./IsMappedIcon";
import { Tooltip } from "@mui/material";
import { truncateStringWithEllipsis } from "common/strings";

const MappingRowHeader = ({ display, mapping, showMappingSummary }) => {
  return (
    <>
      <span>
        <Tooltip title={display}>
          <span>{truncateStringWithEllipsis(display, { maxLength: 32 })}</span>
        </Tooltip>
      </span>
      {showMappingSummary && (
        <div className={"horizontal-validation"}>
          <IsMappedIcon isMapped={!!mapping} />
          {mapping && (
            <span className={"faded horizontal-column-name"}>
              <Tooltip title={mapping}>
                <span>
                  {truncateStringWithEllipsis(mapping, { maxLength: 32 })}
                </span>
              </Tooltip>
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default MappingRowHeader;
