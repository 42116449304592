import StatusSelect from "./StatusSelectV2";
import { Alert, AlertTitle, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as programSelectors from "store/selectors/input/program/programSelectors";

const getFieldValue = (obj, path) => {
  return path.split(".").reduce((o, p) => (o ? o[p] : undefined), obj);
};

const getMissingRequiredFields = ({ requiredFields, layer, program }) => {
  const missingFields = [];

  requiredFields.forEach((fieldKey) => {
    const [keyRoot, ...fieldPathParts] = fieldKey.split(".");
    const fieldPath = fieldPathParts.join(".");

    switch (keyRoot) {
      case "layer": {
        const fieldValue = getFieldValue(layer, fieldPath);
        if (fieldValue == null || fieldValue === "") {
          missingFields.push(fieldKey);
        }
        break;
      }
      case "program": {
        const fieldValue = getFieldValue(program, fieldPath);
        if (fieldValue == null || fieldValue === "") {
          missingFields.push(fieldKey);
        }
        break;
      }
      default:
        missingFields.push(fieldKey);
    }
  });

  return missingFields;
};

const StatusSelectWithChecks = ({
  status,
  onChange,
  variant,
  disabled,
  showQuickSwitch,
  layer,
}) => {
  const { data: config } = staticDataService.useConfigQuery(
    "layer_status_labels_v2"
  );

  const program = useSelector(programSelectors.selectResolvedProgram);

  const [errors, setErrors] = useState(null);

  const onChangeWithCheck = (statusKey) => {
    const requiredFields = config?.requiredFields?.[statusKey];
    if (requiredFields?.length) {
      const missingFields = getMissingRequiredFields({
        requiredFields,
        layer,
        program,
      });
      if (missingFields.length > 0) {
        setErrors(
          missingFields.map((field) => config?.fieldLabels?.[field] ?? field)
        );
      }
    }
    onChange(statusKey);
  };

  return (
    <>
      <StatusSelect
        status={status}
        onChange={onChangeWithCheck}
        variant={variant}
        disabled={disabled}
        showQuickSwitch={showQuickSwitch}
      />
      <Snackbar
        open={(errors?.length ?? 0) > 0}
        onClose={() => {}}
        autoHideDuration={null}
      >
        <Alert
          onClose={() => setErrors(null)}
          severity={"error"}
          variant={"filled"}
        >
          <AlertTitle>{"Warning"}</AlertTitle>
          <Typography color={"white !important"}>
            {"The following items have not been filled in:"}
          </Typography>
          <ul style={{ paddingLeft: "1rem" }}>
            {errors?.map((error, i) => (
              <li key={i}>{error}</li>
            ))}
          </ul>
        </Alert>
      </Snackbar>
    </>
  );
};

export default StatusSelectWithChecks;
