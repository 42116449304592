import Mapper from "components/common/Mapper";
import * as config from "config";
import { connect } from "react-redux";
import { lossTypeMappingUpdated } from "store/actions/input/claims/claimsActions";
import { selectLossTypeFractionDisplayString } from "store/selectors/input/claims/claimsSelectors";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

const mapDispatchToProps = {
  mappingUpdated: lossTypeMappingUpdated,
};

const mapStateToProps = (state) => {
  const sources = state.input.claims.activeFile?.lossTypes;

  const lossCategories = exposureSelectors.selectLossCategories(state) ?? [];

  const targets =
    sources == null
      ? lossCategories
      : [
          ...lossCategories,
          { display: "N/A", key: config.CLAIMS_MAPPING_IGNORE_VALUE },
        ];

  return {
    targets,
    sources,
    sourcesMeta: state.input.claims.activeFile?.lossTypesMeta,
    metaFields: [
      { field: "count", display: "Count" },
      {
        field: "count",
        display: "Mapped",
        mapping: selectLossTypeFractionDisplayString(state),
        mapper: (value) =>
          Math.round(
            (value / state.input.claims.activeFile?.lossTypesTotal) * 100
          ) + "%",
      },
    ],
    mapping: state.input.claims.activeFile?.lossTypeMapping || {},
    suggested: state.input.claims.activeFile?.lossTypeMappingSuggestions,
    isTransposed: true,
    allowSelectWithoutSources: true,
    maxHeight: "50vh",
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapper);
