import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import DefaultLoader from "components/common/DefaultLoader";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as claimsActions from "store/actions/input/claims/claimsActions";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";

const ClaimsValidationBody = ({ transformStatus, transformError }) => {
  switch (transformStatus) {
    case "RUNNING":
      return (
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          size={50}
        />
      );
    case "SUCCEEDED":
      return (
        <CheckCircleOutlineIcon
          color={"success"}
          sx={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      );
    case "FAILED":
      return (
        <Grid container>
          <Grid item sm={12}>
            <Alert severity={"error"}>
              {"Unable to load the claims file."}
              <br />
              <br />
              <div style={{ whiteSpace: "pre" }}>{transformError}</div>
            </Alert>
          </Grid>
        </Grid>
      );
    default:
      return <></>;
  }
};

const ClaimsValidation = () => {
  const activeFile = useSelector(claimsSelectors.getActiveFile);

  const dispatch = useDispatch();

  const useAsyncClaimsTransform = staticDataService.useFeatureFlag(
    "ui/useAsyncClaimsTransform",
    { default: true }
  );

  const transformClaimsFile = useCallback(() => {
    dispatch(
      claimsActions.transformClaimsFile.requested({
        useAsync: useAsyncClaimsTransform,
      })
    );
  }, [dispatch, useAsyncClaimsTransform]);

  useEffect(() => {
    transformClaimsFile();
  }, [transformClaimsFile]);

  return (
    <ClaimsValidationBody
      transformStatus={activeFile?.transformStatus}
      transformError={activeFile?.transformError}
    />
  );
};

export default ClaimsValidation;
