import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import ChangeArrow from "components/common/ChangeArrow";
import DefaultLoader from "components/common/DefaultLoader";
import Notes from "components/common/Notes";
import React, { Fragment } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as exposureSelectors from "store/selectors/input/exposure/exposureSelectors";

const HistoricalExposuresTable = ({
  updating,
  years,
  rows,
  data,
  labels,
  comments,
  isShowingComments,
}) => {
  return (
    <Grid item xs={12}>
      <span className={"print-label"}>{"Historical Exposure"}</span>

      {updating ? (
        <DefaultLoader
          color={"#dc7f4c"}
          style={{
            width: "200px",
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          size={60}
        />
      ) : (
        <TableContainer>
          <Table className={"exposure-inputs"} size={"small"}>
            <TableHead>
              <TableRow>
                <TableCell className={"name-column"}>{"Measure"}</TableCell>
                <TableCell align={"right"} className={"units-column"}>
                  {"Unit"}
                </TableCell>

                {years?.map((year) => (
                  <Fragment key={year}>
                    <TableCell align={"right"}>
                      {labels?.[year]?.includes("projected")
                        ? "Projected"
                        : year.toString()}
                    </TableCell>
                    <TableCell className={"arrow-column"} />
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <Fragment key={row.key}>
                  <TableRow>
                    <TableCell
                      className={"name-column"}
                      scope={"row"}
                      rowSpan={isShowingComments && comments?.[row.key] ? 2 : 1}
                    >
                      <Typography variant={"subtitle1"}>{row.name}</Typography>
                    </TableCell>
                    <TableCell
                      className={"units-column"}
                      align={"right"}
                      rowSpan={isShowingComments && comments?.[row.key] ? 2 : 1}
                    >
                      <Typography variant={"subtitle2"}>{row.units}</Typography>
                    </TableCell>

                    {years?.map((year) => {
                      return (
                        <Fragment key={year}>
                          <TableCell align={"right"}>
                            <NumberFormat
                              displayType={"text"}
                              thousandSeparator={true}
                              value={data[year]?.[row.key] || null}
                            />
                          </TableCell>
                          <TableCell className={"arrow-column"}>
                            <ChangeArrow
                              currentValue={data?.[year]?.[row.key] ?? null}
                              priorValue={data?.[year - 1]?.[row.key] ?? null}
                            />
                          </TableCell>
                        </Fragment>
                      );
                    })}
                  </TableRow>
                  {isShowingComments && comments?.[row.key] && (
                    <TableRow>
                      <TableCell colSpan={7} className={"notes-column"}>
                        <Notes
                          title={null}
                          value={comments?.[row.key]}
                          disabled
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    updating: exposureSelectors.isExposureListUpdating(state),
    rows: exposureSelectors.getExposuresUsed(state),
    years: exposureSelectors.getExposuresYears(state).slice(0, 4),
    data: state?.input?.exposure?.data,
    labels: exposureSelectors.getExposuresLabels(state),
    comments: state?.input?.exposure?.comments,
    isShowingComments: !!state?.print?.elementVisibility?.exposureComments,
  };
};

export default connect(mapStateToProps)(HistoricalExposuresTable);
