import {
  Alert,
  Collapse,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import TileOptions from "components/common/TileOptions";
import { useState } from "react";
import { connect } from "react-redux";
import * as claimsActions from "store/actions/input/claims/claimsActions";

const AppliedExcessRecorder = ({
  appliedExcess,
  setClaimsMapping,
  onValidation,
}) => {
  const defaultAppliedExcess = {
    type: "excess",
    excess: null,
    "is-excess-removed": false,
  };

  const updateExcess = (excess) =>
    setClaimsMapping({
      __DEFAULT__:
        excess == null
          ? {
              ...defaultAppliedExcess,
            }
          : {
              ...(appliedExcess?.__DEFAULT__ ?? defaultAppliedExcess),
              excess,
            },
    });

  const updateIsExcessRemoved = (isExcessRemoved) =>
    setClaimsMapping({
      __DEFAULT__: {
        ...(appliedExcess?.__DEFAULT__ ?? defaultAppliedExcess),
        "is-excess-removed": isExcessRemoved,
      },
    });
  const hasNonZeroExcess = (appliedExcess?.__DEFAULT__?.excess ?? 0) !== 0;
  const exampleWithExcess = (
    (appliedExcess?.__DEFAULT__?.excess ?? 0) + 1
  ).toLocaleString("en-US");

  const [error, setError] = useState(null);

  const [pricingMethod, setPricingMethod] = useState(null);
  return (
    <Stack spacing={hasNonZeroExcess || error ? 1 : 0}>
      <TileOptions
        title={"FGU vs Excess"}
        initialActiveId={"unknown"}
        options={[
          {
            id: "unknown",
            label: "Unknown",
            description:
              "The data provider has not indicated whether losses are ground up or excess.",
            width: 350,
          },
          {
            id: "fgu",
            label: "Ground Up",
            description:
              "Losses represent the total amount incurred without applying any deductibles or excess.",
            width: 350,
          },
          {
            id: "excess",
            label: "Excess Of",
            description:
              "Losses have been adjusted to apply the relevant deductible or excess.",
            width: 350,
          },
        ]}
        onChange={(id) => {
          setPricingMethod(id);
          switch (id) {
            case "unknown":
              setClaimsMapping(null);
              break;
            case "fgu":
              setClaimsMapping({
                __DEFAULT__: {
                  type: "fgu",
                },
              });
              break;
            case "excess":
              setClaimsMapping({
                __DEFAULT__: defaultAppliedExcess,
              });
              break;
            default:
              setClaimsMapping(null);
          }
        }}
        sx={{ marginBottom: "1.25rem" }}
      />
      {pricingMethod === "excess" && (
        <>
          <PrettyNumberTextField
            label={"Excess"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            autoComplete={"off"}
            variant={"filled"}
            onChangeNumber={(e) => {
              updateExcess(e);
            }}
            onValidation={(isValid) => {
              if (isValid) {
                setError(null);
              } else {
                setError("Invalid value for the excess. Must be a number.");
              }
              onValidation(isValid);
            }}
            value={appliedExcess?.__DEFAULT__?.excess ?? null}
            fullWidth
          />
          <Collapse in={hasNonZeroExcess}>
            <FormControl>
              <RadioGroup>
                <FormControlLabel
                  value={"ignore"}
                  label={"Ignore the excess during import"}
                  control={<Radio />}
                  checked={
                    (appliedExcess?.__DEFAULT__["is-excess-removed"] ??
                      false) === false
                  }
                  onChange={(e) => updateIsExcessRemoved(false)}
                />
                <FormControlLabel
                  value={"include"}
                  label={`Add the excess to all claims, e.g. a $1 total loss in the file will be treated as $${exampleWithExcess} in Marmalade`}
                  control={<Radio />}
                  checked={
                    (appliedExcess?.__DEFAULT__["is-excess-removed"] ??
                      false) === true
                  }
                  onChange={(e) => updateIsExcessRemoved(true)}
                />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </>
      )}
      {!!error && <Alert severity={"error"}>{error}</Alert>}
    </Stack>
  );
};

const mapStateToProps = (state) => {
  return {
    appliedExcess: state?.input?.claims?.activeFile?.appliedExcess ?? null,
  };
};

const mapDispatchToProps = {
  setClaimsMapping: claimsActions.setClaimsMapping,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppliedExcessRecorder);
