import * as persistenceActionTypes from "../../../actions/persistence/persistenceActionTypes";
import * as priorStateActionTypes from "../../../actions/temp/priorState/priorStateActionTypes";

export const INIT_STATE = {};

const priorStateReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case priorStateActionTypes.CLEAR_PRIOR_STATE:
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.RECEIVED_PRIOR_SUBMISSION:
      return {
        ...INIT_STATE,
        state: action.payload.state,
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        ...INIT_STATE,
        state: action.payload.priorState,
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return INIT_STATE;
    default:
      return state;
  }
};

export default priorStateReducer;
