import Disabled from "./Disabled";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";

const ExposureSelector = (props) => {
  const selectedRow = props.exposureRows.find(
    (x) => x.key === props.selectedExposureMetric?.key
  );
  const selectedExposureMetric = {
    key: props.selectedExposureMetric?.key || "",
    name:
      props.selectedExposureMetric?.name ||
      props.selectedExposureMetric?.key ||
      "Not selected",
  };
  const exposureRows =
    selectedRow == null
      ? [selectedExposureMetric, ...props.exposureRows]
      : props.exposureRows;
  return (
    <Disabled ifReadOnly>
      <FormControl variant={"filled"} fullWidth>
        <InputLabel shrink={true}>
          {props.title || "Exposure Metric"}
        </InputLabel>
        <Select
          id={"exposure-metric-selector"}
          value={selectedExposureMetric.key}
          onChange={(event) => {
            const exposureRow = props.exposureRows.find(
              (x) => x.key === event.target.value
            );
            props.updateChosenExposureMetric(exposureRow);
          }}
          inputProps={{
            name: "exposure-metric-selector",
            id: "exposure-metric-selector",
          }}
          name={"exposure-metric-selector"}
          className={"exposure-metric-selector"}
        >
          {exposureRows.map((exposureMetric) => {
            return (
              <MenuItem
                key={`Exposure_Metric_${exposureMetric.key}`}
                value={exposureMetric.key}
              >
                {exposureMetric.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Disabled>
  );
};

export default ExposureSelector;
