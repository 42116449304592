import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

const MultiSelectInput = ({ defn, options, setOptions }) => {
  const value = options[defn.name] ?? [];

  return (
    <Stack direction={"row"} alignItems={"center"}>
      <FormControl variant={"filled"}>
        <InputLabel shrink>{defn.label}</InputLabel>
        <Select
          value={value}
          onChange={(e) => {
            const newValue = e.target.value ?? [];
            setOptions({
              ...options,
              [defn.name]:
                newValue.length === 0 && defn.emptyIsNull ? null : newValue,
            });
          }}
          multiple
          sx={{ minWidth: 300 }}
          displayEmpty
          renderValue={(selected) => {
            if (selected.length === 0 && defn.emptyIsNull) {
              return defn.nullLabel ?? "--- All values ---";
            }
            return selected.join(", ");
          }}
        >
          {defn.choices?.map((choice) => (
            <MenuItem key={choice.key} value={choice.key}>
              <Checkbox checked={value.indexOf(choice.name) > -1} />
              <ListItemText primary={choice.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default MultiSelectInput;
