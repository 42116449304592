import { createSelector, createSlice } from "@reduxjs/toolkit";
import * as endorsementsResolvers from "domain/endorsementsResolvers";
import * as persistenceActionTypes from "store/actions/persistence/persistenceActionTypes";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";

const initialState = {
  config: null,
  values: {},
};

const endorsementsSlice = createSlice({
  name: "endorsements",
  initialState,
  reducers: {
    set: (state, action) => {
      const values = action?.payload ?? {};
      if (typeof values !== "object") {
        return;
      }
      return {
        ...initialState,
        ...values,
      };
    },
    update: (state, action) => {
      const values = action?.payload;
      if (typeof values !== "object") {
        return;
      }
      const ret = {
        ...state,
        ...values,
      };
      return ret;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED,
        (_, action) =>
          action?.payload?.data?.state?.endorsements ?? initialState
      )
      .addCase(persistenceActionTypes.NEW_SUBMISSION, (_, action) => {
        return {
          ...initialState,
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.endorsements,
        };
      })
      .addCase(persistenceActionTypes.RENEW_SUBMISSION, (state, action) => {
        return {
          ...initialState,
          ...staticDataSelectors.selectConfig("submission_defaults")(
            action?.payload?.priorState
          )?.endorsements,
          ...state,
        };
      });
  },
});

export const selectInput = createSelector(
  [(state) => state?.endorsements ?? null],
  (endorsements) => endorsements
);

export const select = createSelector([selectInput], (input) =>
  endorsementsResolvers.resolveEndorsements(input)
);

export const { set, update } = endorsementsSlice.actions;

export default endorsementsSlice;
