import RawTextField from "@mui/material/TextField";

/**
 * @typedef {import("@mui/material/TextField").TextFieldProps} RawTextFieldProps
 *
 * /

/**
 * @typedef {Object} ExtraTextFieldProps
 * @property {typeof RawTextFieldProps.value} nullishValue
 *
 * /

/**
 * @typedef {RawTextFieldProps & ExtraTextFieldProps} TextFieldProps
 *
 */

/**
 * @param {TextFieldProps} props
 */
const TextField = ({
  value,
  nullishValue = "",
  variant = "filled",
  type = "text",
  ...otherProps
}) => (
  <RawTextField
    type={type}
    InputLabelProps={{ shrink: true }}
    InputProps={{ spellCheck: "false" }}
    autoComplete={"off"}
    variant={variant}
    fullWidth
    value={value ?? nullishValue}
    {...otherProps}
  />
);

export default TextField;
