import { Error as ErrorIcon } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import * as logger from "common/logger";
import * as numbers from "common/numbers";
import { toDecimalFromPercent, toPercentFromDecimal } from "common/numbers";
import Button from "components/common/Button";
import ConditionalTooltip from "components/common/ConditionalTooltip";
import Disabled from "components/common/Disabled";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import * as config from "config";
import { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as submissionSelectors from "store/selectors/submission/submissionSelector";
import * as staticDataSelectors from "store/selectors/temp/staticData/staticDataSelectors";
import * as utils from "utils";

const PURE_BURNING_COST_VIEWS = [
  { name: "All Years", key: "all_years" },
  { name: "Last 12", key: "last_12" },
  { name: "Last 10", key: "last_10" },
  { name: "Last 7", key: "last_7" },
  { name: "Last 5", key: "last_5" },
  { name: "Last 3", key: "last_3" },
  { name: "All Prior", key: "all_prior" },
  { name: "Prior 12", key: "prior_12" },
  { name: "Prior 10", key: "prior_10" },
  { name: "Prior 7", key: "prior_7" },
  { name: "Prior 5", key: "prior_5" },
  { name: "Prior 3", key: "prior_3" },
];

const SelectedBurningCost = ({ value, onChange }) => {
  const [editValue, setEditValue] = useState("");
  const [editing, setEditing] = useState(false);
  const clearButton = useRef();

  const handleSubmit = (value) => {
    onChange(numbers.parseFloatOrNull(value));
    setEditing(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(editValue);
    } else if (e.key === "Escape") {
      setEditing(false);
      setEditValue("");
    }
  };

  useEffect(() => {
    if (editing) {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  });

  const makeEditable = () => {
    const makeStringValue = () => {
      if (value === undefined || value === null) {
        return "";
      }
      if (typeof value === "string") {
        return value;
      }
      if (isNaN(value)) {
        return "";
      }
      return parseFloat(value.toPrecision(14)).toString();
    };
    setEditValue(numbers.toPercentFromDecimal(makeStringValue()));
    setEditing(true);
  };

  return editing ? (
    <TextField
      onBlur={(e) => {
        if (e.relatedTarget !== clearButton.current) {
          handleSubmit(editValue);
          setEditing(false);
        }
      }}
      onChange={(e) => {
        const value = e.target.value;
        if (numbers.isNumberPrefix(value)) {
          setEditValue(value);
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
      }}
      value={editValue}
      size={"small"}
      autoFocus
      InputProps={{
        endAdornment: (
          <InputAdornment position={"end"}>
            <IconButton
              ref={clearButton}
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit("");
              }}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  ) : (
    <Box
      onClick={makeEditable}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"right"}
      sx={{
        height: "100%",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        fontWeight: "bold",
        minHeight: "40px",
      }}
    >
      <NumberFormat
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={2}
        className={"monospace"}
        suffix={"%"}
        fixedDecimalScale
        value={toPercentFromDecimal(value)}
      />
    </Box>
  );
};

const ROWS = [
  {
    key: "limit",
    title: "Limit",
    description: "",
    makeValue: ({ layer, dispatch }) => (
      <PrettyNumberTextField
        variant={"filled"}
        size={"small"}
        hiddenLabel
        fullWidth
        disabled={!dispatch.updateLayer}
        onChangeNumber={(x) => {
          dispatch.updateLayer({
            layerId: layer.id,
            values: { limit: x },
          });
        }}
        value={layer.limit}
        inputProps={{ style: { textAlign: "right" } }}
      />
    ),
  },
  {
    key: "attachment",
    title: "Attachment",
    description: "",
    makeValue: ({ layer, dispatch }) => (
      <PrettyNumberTextField
        variant={"filled"}
        size={"small"}
        hiddenLabel
        fullWidth
        disabled={!dispatch.updateLayer}
        onChangeNumber={(x) => {
          dispatch.updateLayer({
            layerId: layer.id,
            values: { attachment: x },
          });
        }}
        value={layer.attachment}
        inputProps={{ style: { textAlign: "right" } }}
      />
    ),
  },
  {
    key: "exhaustion",
    title: "Exhaustion",
    description: "",
    makeValue: ({ layer }) => (
      <NumberFormat
        value={layer.attachment + layer.limit}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={0}
        className={"monospace"}
      />
    ),
  },
  {
    key: "coverage",
    title: "Coverage",
    description: "",
    makeValue: ({ layer, dispatch }) => (
      <Select
        variant={"filled"}
        size={"small"}
        hiddenLabel
        fullWidth
        value={layer.coverage ?? "__NA__"}
        disabled={!dispatch?.updateLayer}
        onChange={(e) => {
          const coverage = e.target.value;
          dispatch.updateLayer({
            layerId: layer.id,
            values: {
              coverage: coverage === "__NA__" ? null : coverage,
            },
          });
        }}
      >
        <MenuItem value={"__NA__"}>{"---"}</MenuItem>
        <MenuItem value={"PP"}>{"PP"}</MenuItem>
        <MenuItem value={"PO"}>{"PO"}</MenuItem>
        <MenuItem value={"PP+PO"}>{"PP+PO"}</MenuItem>
      </Select>
    ),
  },
  {
    key: "perOccurrenceMAOL",
    title: "Per Occurrence MAOL",
    description: "",
    makeValue: ({ layer, dispatch }) => (
      <Select
        variant={"filled"}
        size={"small"}
        hiddenLabel
        fullWidth
        value={layer.perOccurrenceMAOL ?? "__NA__"}
        onChange={(e) => {
          const maol = e.target.value;
          dispatch.updateLayer({
            layerId: layer.id,
            values: {
              perOccurrenceMAOL: maol === "__NA__" ? null : maol,
            },
          });
        }}
      >
        <MenuItem value={"__NA__"}>{"None"}</MenuItem>
        <MenuItem value={"1"}>{"1"}</MenuItem>
        <MenuItem value={"2"}>{"2"}</MenuItem>
        <MenuItem value={"3"}>{"3"}</MenuItem>
        <MenuItem value={"5"}>{"5"}</MenuItem>
        <MenuItem value={"7.5"}>{"7.5"}</MenuItem>
        <MenuItem value={"10"}>{"10"}</MenuItem>
      </Select>
    ),
  },
  {
    key: "ldfName",
    title: "LDF",
    description: "",
    makeValue: ({ layer, pricingInputs, dispatch }) => (
      <Select
        variant={"filled"}
        size={"small"}
        hiddenLabel
        fullWidth
        value={
          pricingInputs?.burningCost?.layers?.[layer?.id]?.ldfName ?? "__NA__"
        }
        onChange={(e) =>
          dispatch.updatePricingInput({
            key: "burningCost",
            layerId: layer.id,
            values: {
              ldfName: e.target.value,
            },
          })
        }
      >
        <MenuItem value={"__NA__"}>{"None"}</MenuItem>
        <MenuItem value={"900x100"}>{"900x100"}</MenuItem>
        <MenuItem value={"300x200"}>{"300x200"}</MenuItem>
        <MenuItem value={"800x200"}>{"800x200"}</MenuItem>
        <MenuItem value={"250x250"}>{"250x250"}</MenuItem>
        <MenuItem value={"750x250"}>{"750x250"}</MenuItem>
        <MenuItem value={"700x300"}>{"700x300"}</MenuItem>
        <MenuItem value={"650x350"}>{"650x350"}</MenuItem>
        <MenuItem value={"500x500"}>{"500x500"}</MenuItem>
        <MenuItem value={"1.5x500"}>{"1.5x500"}</MenuItem>
        <MenuItem value={"1x1"}>{"1x1"}</MenuItem>
        <MenuItem value={"1.5x1"}>{"1.5x1"}</MenuItem>
        <MenuItem value={"4x1"}>{"4x1"}</MenuItem>
        <MenuItem value={"1.5x1.5"}>{"1.5x1.5"}</MenuItem>
        <MenuItem value={"1x2"}>{"1x2"}</MenuItem>
        <MenuItem value={"3x2"}>{"3x2"}</MenuItem>
        <MenuItem value={"5x5"}>{"5x5"}</MenuItem>
      </Select>
    ),
  },
  ...PURE_BURNING_COST_VIEWS.map(({ name, key }) => ({
    key: `burningCost-${key}`,
    title: name,
    description: "",
    makeValue: ({ layer, pricingInputs, dispatch }) => {
      const pricing =
        pricingInputs?.burningCost?.layers?.[layer.id]
          ?.pureBurningCostPricing?.[key];

      const selectedBurningCostSource =
        pricingInputs?.burningCost?.layers?.[layer.id]
          ?.selectedBurningCostSource;

      const baseStyles = {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "0.2rem",
        paddingBottom: "0.2rem",
        border: "3px solid transparent",
        borderRadius: "12px",
        cursor: "pointer",
      };
      const styles =
        key === selectedBurningCostSource?.key
          ? {
              ...baseStyles,
              borderColor: "primary.main",
              bgcolor: "primary.lightest",
            }
          : {
              ...baseStyles,
              "&:hover": {
                borderColor: "lightgrey",
              },
            };

      if (!pricing) {
        return <></>;
      } else if (pricing.status === "success") {
        return (
          <Box
            sx={styles}
            onClick={() =>
              dispatch.updatePricingInput({
                key: "burningCost",
                layerId: layer.id,
                values: {
                  selectedBurningCostSource: { key },
                },
              })
            }
          >
            <NumberFormat
              displayType={"text"}
              thousandSeparator={true}
              defaultValue={""}
              decimalScale={2}
              className={"monospace"}
              suffix={"%"}
              fixedDecimalScale
              value={toPercentFromDecimal(pricing.value?.price)}
            />
          </Box>
        );
      } else {
        return (
          <Tooltip title={pricing.error?.message ?? "Unkown error"}>
            <ErrorIcon color={"error"} />
          </Tooltip>
        );
      }
    },
  })),
  {
    key: "selectedBurningCost",
    title: "Selected Burning Cost",
    description: "",
    makeValue: ({ layer, pricingInputs, dispatch }) => {
      return (
        <SelectedBurningCost
          value={
            pricingInputs?.burningCost?.layers?.[layer.id]?.selectedBurningCost
          }
          onChange={(x) => {
            dispatch.updatePricingInput({
              key: "burningCost",
              layerId: layer.id,
              values: {
                selectedBurningCostSource: {
                  override: numbers.toDecimalFromPercent(x),
                },
              },
            });
          }}
        />
      );
    },
  },
  {
    key: "burningCostWeighting",
    title: "Burning Cost Weighting",
    description: "",
    makeValue: ({ layer, pricingInputs, dispatch }) => {
      const burningCostWeighting =
        pricingInputs?.burningCost?.layers?.[layer.id]?.burningCostWeighting;
      return (
        <PrettyNumberTextField
          variant={"percentage"}
          size={"small"}
          hiddenLabel
          fullWidth
          disabled={!dispatch.updatePricingInput}
          onChangeNumber={(x) => {
            if (x == null || (x >= 0 && x <= 100)) {
              dispatch.updatePricingInput({
                key: "burningCost",
                layerId: layer.id,
                values: {
                  burningCostWeighting: toDecimalFromPercent(x),
                },
              });
            }
          }}
          value={toPercentFromDecimal(burningCostWeighting)}
          inputProps={{ style: { textAlign: "right" } }}
        />
      );
    },
  },
];

const LayerCells = ({
  layers,
  pricingInputs,
  dispatch,
  cellProps,
  children,
}) => {
  return (
    <>
      {(layers ?? []).map((layer, layerIndex) => (
        <TableCell key={layer.id} {...cellProps}>
          {children({ layer, layerIndex, pricingInputs, dispatch })}
        </TableCell>
      ))}
    </>
  );
};

export const calculatePureBurningCost = async ({
  resolvedSubmission,
  analyticsConfig,
  setIsCalculating,
  setError,
  updatePricingInput,
}) => {
  setIsCalculating(true);
  setError(null);
  try {
    const layers = resolvedSubmission?.layers ?? [];
    for (const layer of layers) {
      updatePricingInput({
        key: "burningCost",
        layerId: layer.id,
        values: {
          pureBurningCostPricing: null,
        },
      });
    }
    const response = await utils.authenticatedFetch(
      config.endpoints.analytics("calculate-submission-analytics"),
      {
        body: JSON.stringify({
          engine: analyticsConfig?.burningCosts?.engine,
          metric: "burning_costs",
          submission: resolvedSubmission,
        }),
        method: "post",
      }
    );
    const data = await response.json();
    for (const layer of layers) {
      updatePricingInput({
        key: "burningCost",
        layerId: layer.id,
        values: {
          pureBurningCostPricing: {
            ...Object.fromEntries(
              Object.entries(
                data?.values?.[layer.id] ?? {}
              ).map(([key, value]) => [
                key,
                { status: "success", value: { price: value } },
              ])
            ),
            ...Object.fromEntries(
              Object.entries(
                data?.errors?.[layer.id] ?? {}
              ).map(([key, value]) => [
                key,
                { status: "failure", error: { message: value } },
              ])
            ),
          },
        },
      });
    }
  } catch (e) {
    logger.exception(e);
    setError(String(e));
  } finally {
    setIsCalculating(false);
  }
};

const LayerTable = ({
  layers,
  pricingInputs,
  resolvedSubmission,
  analyticsConfig,
  updateLayer,
  updatePricingInput,
}) => {
  const [error, setError] = useState(null);
  const [isCalculating, setIsCalculating] = useState(false);

  return (
    <>
      <Stack direction={"row"}>
        <Disabled ifReadOnly>
          <Button
            isLoading={isCalculating}
            hasError={!!error}
            errorTooltip={error}
            onClick={async () => {
              calculatePureBurningCost({
                resolvedSubmission,
                analyticsConfig,
                setError,
                setIsCalculating,
                updatePricingInput,
              });
            }}
          >
            {"Calculate"}
          </Button>
        </Disabled>
      </Stack>
      <TableContainer>
        <Table sx={{ display: "block" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 150 }} />
              <LayerCells
                layers={layers}
                cellProps={{
                  sx: {
                    minWidth: 150,
                    textAlign: "right",
                    fontWeight: "bold",
                  },
                }}
              >
                {({ layerIndex }) => `Layer ${layerIndex + 1}`}
              </LayerCells>
            </TableRow>
          </TableHead>
          <TableBody>
            {ROWS.map((row) => (
              <TableRow key={row.key}>
                <ConditionalTooltip
                  title={row.description}
                  conditional={row.description}
                >
                  <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                    {row.title}
                  </TableCell>
                </ConditionalTooltip>
                <LayerCells
                  layers={layers}
                  pricingInputs={pricingInputs}
                  dispatch={{
                    updateLayer,
                    updatePricingInput,
                  }}
                  cellProps={{
                    sx: {
                      textAlign: "right",
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  }}
                >
                  {row.makeValue}
                </LayerCells>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const mapDispatchToProps = {
  updateLayer: pricingActions.updateLayer,
  updatePricingInput: pricingActions.updatePricingInput,
};

const mapStateToProps = (state) => ({
  layers: pricingSelectors.selectResolvedLayers(state),
  pricingInputs: pricingSelectors.selectResolvedPricingInputs(state),
  resolvedSubmission: submissionSelectors.selectResolvedSubmission(state),
  analyticsConfig: staticDataSelectors.selectAnalyticsConfig(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerTable);
