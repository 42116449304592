import SignOutButton from "./SignOutButton";
import styled from "@emotion/styled";
import DownloadIcon from "@mui/icons-material/GetAppRounded";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { downloadState } from "common/errors";
import Flag from "components/common/Flag";
import IconButton from "components/common/IconButton";
import Moment from "react-moment";
import { connect } from "react-redux";
import { showErrors } from "store/actions/error/errorActions";
import * as userSelectors from "store/selectors/user/userSelectors";

const ErrorButton = styled.span`
  border: 1px solid #a0a8ad85;
  padding: 2px 4px;
  font-size: 11px;
  color: #a0a8ad;
  border-radius: 4px;
  font-weight: bold;

  &:hover {
    background: #fff;
    cursor: pointer;
  }
`;

const MetaData = ({ label, value, fallback }) => (
  <span className={"meta-data"}>
    <span className={"label"}>{`${label}: `}</span>
    {value || fallback}
  </span>
);

const Footer = (props) => {
  const errorButtonText = `${props.messages.length} error${
    props.messages.length === 1 ? "" : "s"
  }`;

  return (
    <div className={"footer-wrapper"}>
      <div className={"container"}>
        <Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"}>
          {props.errorHidden && (
            <ErrorButton onClick={props.showErrors}>
              {errorButtonText}
            </ErrorButton>
          )}
          <MetaData
            label={"User"}
            value={props.attributes?.email}
            fallback={"Unknown"}
          />
          <Box sx={{ marginLeft: "6px" }}>
            <Flag />
          </Box>
          <MetaData
            label={"Last saved"}
            value={
              props.lastSaved ? (
                <Moment fromNow>{props.lastSaved}</Moment>
              ) : (
                "UNSAVED"
              )
            }
          />
          {props.lastSaved && (
            <MetaData label={"By"} value={props.savedBy} fallback={"-"} />
          )}
          <MetaData label={"Filename"} value={props.filename} fallback={"-"} />
          <IconButton
            onClick={() => downloadState(props.state)}
            className={"download-state"}
            icon={DownloadIcon}
            isDisabled={props.isUserReadOnly === true}
            tooltip={"Download file"}
          />
          <MetaData
            label={"Schema"}
            value={props.schemaVersion}
            fallback={"-"}
          />
          <SignOutButton />
        </Stack>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  showErrors,
};

const mapStateToProps = (state) => {
  return {
    state: state,
    filename: state.persistence.filename,
    lastSaved: state.persistence.lastSaved,
    savedBy: state.persistence.savedBy,
    saving: state.persistence.saving,
    schemaVersion: state.persistence?.schemaVersion || 1,
    attributes: state.user?.attributes || {},
    messages: state.temp.error?.messages || [],
    isUserReadOnly: userSelectors.isUserReadOnly(state),
    errorHidden:
      state.temp.error?.hidden == null ? true : state.temp.error.hidden,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
