import * as dates from "./dates";
import moment from "moment";

export const daysInMonth = (y, m) => {
  switch (m) {
    case 2:
      if (y % 400 === 0 || (y % 4 === 0 && y % 100 !== 0)) {
        return 29;
      }
      return 28;
    case 4:
    case 6:
    case 9:
    case 11:
      return 30;
    default:
      return 31;
  }
};

export const getDateStringFromDateTimeString = (datetimeString) => {
  if (!datetimeString) {
    return null;
  } else {
    const dateString = moment.parseZone(datetimeString).format("YYYY-MM-DD");
    return dateString === "Invalid date" ? null : dateString;
  }
};

export const formatDate = (date) => {
  if (date) {
    return new Intl.DateTimeFormat("en-us", {
      timeZone: "UTC",
      year: "numeric",
      month: "short",
      day: "numeric",
    }).format(new Date(date));
  }
  return "";
};

export const formatDateTime = (date) => {
  if (date) {
    return (
      new Intl.DateTimeFormat("en-us", {
        timeZone: "UTC",
        dateStyle: "medium",
        timeStyle: "short",
      }).format(new Date(date)) + " UTC"
    );
  }
  return "";
};

export const validatedDateStr = (dateStr) => {
  if (dateStr == null) {
    return null;
  }
  if (isNaN(new Date(dateStr))) {
    return null;
  }
  return dateStr;
};

export const strDateToYearMonthDay = (date) => {
  if (validatedDateStr(date) == null) {
    return null;
  }

  return {
    year: parseInt(date.substring(0, 4)),
    month: parseInt(date.substring(5, 7)),
    day: parseInt(date.substring(8, 10)),
  };
};

export const strDateToMonthsFloat = (date) => {
  const dateParts = strDateToYearMonthDay(date);

  if (dateParts == null) {
    return null;
  }

  const whole = dateParts.year * 12 + dateParts.month - 1;
  const frac =
    (dateParts.day - 1) / daysInMonth(dateParts.year, dateParts.month);

  return whole + frac;
};

export const strDateInRange = (date, start, end) => {
  date = strDateToYearMonthDay(date);
  start = strDateToYearMonthDay(start);
  end = strDateToYearMonthDay(end);

  if (date == null || start == null || end == null) {
    return null;
  }

  const datePartsToInt = (dateParts) => {
    return dateParts.year * 10_000 + dateParts.month * 100 + dateParts.day;
  };

  const dateInt = datePartsToInt(date);
  const startInt = datePartsToInt(start);
  const endInt = datePartsToInt(end);

  return dateInt >= startInt && dateInt < endInt;
};

export const getDate = () => {
  return new Date();
};

export const getLastDateStringOfMonth = (dateString) => {
  return moment.parseZone(dateString).endOf("month").format("YYYY-MM-DD");
};

export const getFirstDateStringOfNextMonth = (dateString) => {
  return moment
    .parseZone(dateString)
    .endOf("month")
    .add(1, "days")
    .format("YYYY-MM-DD");
};

export const getFirstOfNextMonth = () => {
  const now = dates.getDate();
  return new Date(now.getUTCFullYear(), now.getUTCMonth() + 1, 1);
};

export const getFirstOfNextMonthStrNoTimeZone = () => {
  return getFirstDateStringOfNextMonth(
    moment.parseZone(dates.getDate()).format("YYYY-MM-DD")
  );
};

export const getMoveForwardOneYear = (date) => {
  if (!date) {
    return undefined;
  }
  // We want to snap to EOM. Hence why we roll forward a day before rolling forward the year.
  return moment
    .parseZone(date)
    .add(1, "day")
    .add(1, "year")
    .add(-1, "day")
    .format("YYYY-MM-DD");
};

export const toISODateString = (date) => {
  if (!date) {
    return undefined;
  }
  return moment.parseZone(date).format("YYYY-MM-DD");
};

export const yearsBetween = (inceptionStr, expirationStr) => {
  const expiration = moment(expirationStr);
  const inception = moment(inceptionStr);
  if (expiration.isBefore(inception)) {
    return yearsBetween(expirationStr, inceptionStr);
  }
  const years = expiration.diff(inception, "years");
  const a = inception.clone().add(years, "years");
  if (a.isSame(expiration)) {
    return years;
  }
  const b = inception.clone().add(years + 1, "years");
  const denomDays = a.diff(b, "days");
  const numDays = a.diff(expiration, "days");
  return parseFloat((years + numDays / denomDays).toFixed(5));
};

export const parseDateStringFromDateOrYear = (
  input,
  {
    defaultMMDD = "01-01",
    minYear = 999,
    maxYear = 10000,
    defaultReturn = null,
    locale = "default",
    allowLocalFormats = false,
  }
) => {
  const [defaultMonth, defaultDay] = defaultMMDD.split("-");
  if (!input) return defaultReturn;
  var d = 0;
  var m = 0;
  var y = 0;
  const startMonth =
    new Intl.DateTimeFormat(locale).formatToParts()[0].type === "month";
  function two(val) {
    const startZero = "00" + val;
    return startZero.substring(startZero.length - 2);
  }

  if (typeof input !== "string") input = "" + input;

  if (/^\d{4}$/.test(input)) input += "-" + defaultMonth + "-" + defaultDay;
  if (/^\d{4}-\d{1,2}$/.test(input)) input += "-" + defaultDay;

  const split = input.split(/[-./]/);

  function setDM_MD() {
    if ((startMonth && Number(split[0]) <= 12) || Number(split[1]) > 12) {
      d = split[1];
      m = split[0];
    } else {
      d = split[0];
      m = split[1];
    }
  }
  if (/^\d{4}-\d{1,2}-\d{1,2}$/.test(input)) {
    y = split[0];
    m = split[1];
    d = split[2];
  } else if (
    allowLocalFormats &&
    /^\d{1,2}[-./]\d{1,2}[-./]\d{2}$/.test(input)
  ) {
    setDM_MD();
    y = "20" + split[2];
  } else if (
    allowLocalFormats &&
    /^\d{1,2}[-./]\d{1,2}[-./]\d{4}$/.test(input)
  ) {
    setDM_MD();
    y = split[2];
  } else return defaultReturn;
  const retVal = y + "-" + two(m) + "-" + two(d);
  if (isNaN(new Date(retVal))) return defaultReturn;
  if (Number(y) < minYear || Number(y) > maxYear) return defaultReturn;
  return retVal;
};

const datesMatch = (date1, date2) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const readableDate = (timestamp, { includeTime = false } = {}) => {
  const date = new Date(timestamp);
  const time = includeTime ? `, ${date.toLocaleTimeString()}` : "";

  const today = new Date();
  if (datesMatch(date, today)) {
    return `Today${time}`;
  }

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  if (datesMatch(date, yesterday)) {
    return `Yesterday${time}`;
  }

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return `${date.toLocaleDateString(undefined, options)}${time}`;
};

export const inYear = (dateStr, yearInt) => {
  if (validatedDateStr(dateStr) == null) {
    return false;
  } else if (yearInt != null && typeof yearInt !== "number") {
    yearInt = parseInt(yearInt);
  } else {
    yearInt = yearInt ?? new Date().getFullYear();
  }

  return isFinite(yearInt)
    ? moment.parseZone(dateStr).year() === yearInt
    : false;
};
