import * as types from "./imagesActionTypes";

export const cacheImage = (submissionId, imageId, imageType) => ({
  type: types.CACHE_IMAGE,
  payload: { submissionId, imageId, imageType },
});

export const imageCached = (submissionId, imageId, url) => ({
  type: types.IMAGE_CACHED,
  payload: { submissionId, imageId, url },
});
