import Claims from "./Claims";
import Pricing from "./Pricing";
import Disabled from "components/common/Disabled";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as claimsService from "services/claimsService";
import * as baseLayerBurnStore from "store/baseLayerBurn";
import useDebouncedValue from "store/hooks/useDebouncedValue";
import * as claimsSelectors from "store/selectors/input/claims/claimsSelectors";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

export const BaseLayerBurn = () => {
  const claimsQuery = useDebouncedValue(
    useSelector(pricingSelectors.selectPricingClaims)
  );
  const claimsAbove = useSelector(claimsSelectors.selectClaimsThreshold);
  const { currentData: allClaims } = claimsService.useAllClaimsChartQuery({
    claimsQuery,
    claimsAbove,
  });

  const baseLayerBurn = useSelector(baseLayerBurnStore.selectBaseLayerBurn);

  const dispatch = useDispatch();

  const setBaseLayerBurn = useCallback(
    (...args) => dispatch(baseLayerBurnStore.set(...args)),
    [dispatch]
  );
  const updateBaseLayerBurn = useCallback(
    (...args) => dispatch(baseLayerBurnStore.update(...args)),
    [dispatch]
  );

  return (
    <>
      <Disabled ifReadOnly>
        <Claims
          data={allClaims}
          baseLayerBurn={baseLayerBurn}
          setBaseLayerBurn={setBaseLayerBurn}
          updateBaseLayerBurn={updateBaseLayerBurn}
        />
      </Disabled>
      <Disabled ifReadOnly>
        <Pricing updateBaseLayerBurn={updateBaseLayerBurn} />
      </Disabled>
    </>
  );
};

export default BaseLayerBurn;
