import {
  ErrorOutline as ErrorIcon,
  MoreVert as MoreIcon,
  Star as StarIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import {
  Fade,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Button as MuiButton,
  Paper,
  Badge,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Box,
} from "@mui/material";
import * as numbers from "common/numbers";
import Component from "components/Component";
import Button from "components/common/Button";
import Disabled from "components/common/Disabled";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import StatusBadgeV2 from "components/common/StatusBadgeV2";
import { usePricing } from "components/customPricing/dnfModel1/pricingHooks";
import * as layerSupport from "domain/layerSupport";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TransitionGroup } from "react-transition-group";
import * as staticDataService from "services/staticDataService";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as analyticsStore from "store/analytics";
import * as programPricingSlice from "store/programPricing";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as utils from "utils";

const FADE_TIMEOUT = 300;

const getValue = (layer, field, calculatedValues, programPricing) => {
  if (field.value)
    return field.value({ layer, programPricing, calculatedValues });
  let rootData;
  switch (field.root) {
    case "programPricing":
      rootData = programPricing?.layers?.[layer.id];
      break;
    case "layer":
      rootData = layer;
      break;
    default:
      rootData = calculatedValues?.values?.layers?.[layer.id];
      break;
  }
  return rootData?.[field.key] ?? "";
};

const buildDataFromFields = (
  fields,
  layers,
  calculatedValues,
  programPricing
) => {
  return layers.reduce((acc, layer) => {
    const dataForLayer = fields.reduce((layerAcc, field) => {
      const value = getValue(layer, field, calculatedValues, programPricing);
      return { ...layerAcc, [field.key]: value };
    }, {});
    return { ...acc, [layer.id]: dataForLayer };
  }, {});
};

const getCellStyle = (layerId, highlightedLayers, isAnyHighlighted) => {
  if (highlightedLayers.includes(layerId)) {
    return {
      borderLeft: "1px solid orange",
      borderRight: "1px solid orange",
    };
  } else if (!isAnyHighlighted) {
    return {};
  }
  return {
    opacity: 0.3,
  };
};

const ActionMenu = ({ showAdditionalInputs, setShowAdditionalInputs }) => {
  const anchorRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"menu"}>
      <IconButton
        ref={anchorRef}
        onClick={() => setIsOpen(!isOpen)}
        variant={"text"}
        className={"toggle"}
        style={{ marginLeft: "-15px" }}
      >
        <MoreIcon />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={(_, reason) => {
          if (reason === "backdropClick") setIsOpen(!isOpen);
        }}
      >
        <MenuItem
          onClick={() => {
            setShowAdditionalInputs(!showAdditionalInputs);
            setIsOpen(!isOpen);
          }}
        >
          <ListItemText>
            {showAdditionalInputs ? "Hide" : "Show"}
            {" Additional Inputs"}
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

const Perils = ({ layerId }) => {
  const programPricing = useSelector(programPricingSlice.select);
  const exposedPerils = programPricing?.layers?.[layerId]?.exposedPerils ?? [];

  const extractName = (peril) => {
    return programPricing?.coverageConfig?.perils?.[peril]?.name ?? peril;
  };

  const extractSublimit = (peril) => {
    return programPricing?.sublimits?.[peril] ?? null;
  };

  const extractTextSublimit = (peril) => {
    const sublimit = extractSublimit(peril);
    return numbers.abbreviated(sublimit) ?? "unlimited";
  };

  return (
    <Stack
      direction={"column"}
      spacing={0.5}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        width: "100%",
      }}
    >
      {exposedPerils.map(({ peril, exposed, children }) => (
        <Tooltip
          key={peril}
          placement={"right"}
          title={
            <Stack direction={"column"} spacing={0.5}>
              <Typography variant={"subtitle1"}>
                {extractName(peril)}
              </Typography>
              <Typography variant={"body1"}>{`Sublimit: ${extractTextSublimit(
                peril
              )}`}</Typography>
              {exposed &&
                (children ?? []).some(
                  ({ exposed: subExposed }) => !subExposed
                ) && (
                  <>
                    <Typography variant={"body1"}>{"Excluding:"}</Typography>
                    {children
                      .filter(({ exposed }) => !exposed)
                      .map(({ peril: subPeril }) => (
                        <Typography key={subPeril} variant={"body2"}>
                          {`- ${extractName(
                            subPeril
                          )} (Sublimit: ${extractTextSublimit(subPeril)})`}
                        </Typography>
                      ))}
                  </>
                )}
            </Stack>
          }
        >
          <Box
            sx={{
              width: "95%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: exposed ? "primary.main" : "secondary.lightest",
              color: exposed ? "white !important" : "black !important",
              borderRadius: "20px",
            }}
          >
            {peril}
          </Box>
        </Tooltip>
      ))}
    </Stack>
  );
};

const useHiddenLayers = ({ layers, inPlayLayerIds }) => {
  const [hiddenLayers, setHiddenLayers] = useState(
    layers.map(({ id }) => id).filter((id) => !inPlayLayerIds.includes(id))
  );

  const clearHiddenLayers = () => {
    setHiddenLayers([]);
  };

  const toggleHiddenLayer = (layerId) => {
    const layer = layers.find((layer) => layer.id === layerId);

    if (!layer) {
      return;
    }

    if (hiddenLayers.includes(layerId)) {
      setHiddenLayers(hiddenLayers.filter((id) => id !== layerId));
      return;
    } else {
      setHiddenLayers([...hiddenLayers, layerId]);
      return;
    }
  };

  return { hiddenLayers, clearHiddenLayers, toggleHiddenLayer };
};

const useHighlightedLayers = ({ hiddenLayers }) => {
  const [highlightedLayers, setHighlightedLayers] = useState([]);

  const toggleHighlight = (layerId) => {
    setHighlightedLayers((prev) =>
      prev.includes(layerId)
        ? prev.filter((id) => id !== layerId)
        : [...prev, layerId]
    );
  };

  const isAnyHighlighted = highlightedLayers.length > 0;

  useEffect(() => {
    setHighlightedLayers((prev) =>
      prev.filter((layerId) => !hiddenLayers.includes(layerId))
    );
  }, [hiddenLayers]);

  return { highlightedLayers, toggleHighlight, isAnyHighlighted };
};

const LayerPricing = ({ showAdditionalInputs, setShowAdditionalInputs }) => {
  const dispatch = useDispatch();
  const calculatedValues = useSelector(analyticsStore.select)?.programPricing;
  const calculatedLayers = calculatedValues?.values?.layers ?? {};
  const layers = useSelector(pricingSelectors.selectResolvedLayers);
  const programPricing = useSelector(programPricingSlice.select);

  const { isFetching, refetch } = usePricing();

  const inPlayLayerIds = layerSupport.extractInPlayLayerIds(layers);

  const {
    hiddenLayers,
    clearHiddenLayers,
    toggleHiddenLayer,
  } = useHiddenLayers({
    layers,
    inPlayLayerIds,
  });

  const {
    highlightedLayers,
    isAnyHighlighted,
    toggleHighlight,
  } = useHighlightedLayers({
    hiddenLayers,
  });

  const { data: reinsurersConfig } = staticDataService.useConfigQuery(
    "reinsurers"
  );
  const paperProviderMapping = Object.fromEntries(
    (reinsurersConfig?.reinsurers ?? []).map(({ key, name }) => [key, name])
  );

  const fields = [
    {
      key: "paperProvider",
      label: "Paper Provider",
      renderCell: ({ layer }) => {
        const value = layer.paperProvider;
        return (
          <Typography>{paperProviderMapping?.[value] ?? value}</Typography>
        );
      },
      visible: true,
    },
    {
      key: "nonCatTechnical",
      label: "Non-Cat Technical",
      root: "layerPricing",
      valueType: "currency",
      inputType: "number",
      readOnly: true,
      visible: true,
    },
    {
      key: "catTechnical",
      label: "Cat Technical",
      root: "layerPricing",
      valueType: "currency",
      readOnly: true,
      visible: true,
    },
    {
      key: "technicalPremium",
      label: "Technical",
      root: "layerPricing",
      valueType: "currency",
      readOnly: true,
      visible: true,
    },
    {
      key: "ROL",
      label: "ROL",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "brokerTargetGrossPremium",
      label: "Broker Target",
      root: "layer",
      valueType: "currency",
      onChange: "updateLayer",
      readOnly: false,
      visible: true,
    },
    {
      key: "ROLtarget",
      label: "Broker Target ROL",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "quotedGrossPremium",
      root: "layer",
      label: "Helix Quote",
      onChange: "updateLayer",
      valueType: "currency",
      readOnly: false,
      visible: true,
    },
    {
      key: "ROLquote",
      label: "Helix Quote ROL",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "grossPremium",
      root: "layer",
      label: "FOT",
      onChange: "updateLayer",
      valueType: "currency",
      readOnly: false,
      visible: true,
    },
    {
      key: "ROLFOT",
      root: "layerPricing",
      label: "FOT ROL",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "GG_ULR_COMBINED",
      label: "Gross LR of Technical Premium",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "percentageTechnicalHelixQuote",
      label: "Helix Quote as % of Technical",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "percentageTechnicalFOT",
      label: "FOT as % of Technical",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "GGLRQuote",
      label: "Gross Gross Loss Ratio - Quote",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "GGLRFOT",
      label: "Gross Gross Loss Ratio - FOT",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "GGLRCombinedRatioQuote",
      label: "Combined UW Ratio - Quote",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "GGLRCombinedRatioFOT",
      label: "Combined UW Ratio - FOT",
      root: "layerPricing",
      valueType: "percentage",
      dp: 2,
      readOnly: true,
      visible: true,
    },
    {
      key: "quotedLineSize",
      label: "Exposure/Line Our Share - Quote",
      root: "layer",
      valueType: "currency",
      onChange: "updateLayer",
      readOnly: false,
      visible: true,
    },
    {
      key: "lineSize",
      label: "Exposure/Line Our Share - FOT",
      root: "layer",
      valueType: "currency",
      onChange: "updateLayer",
      readOnly: false,
      visible: true,
    },
    {
      key: "quotedShareOfPremium",
      label: "Gross Premium Our Share - Quote",
      root: "layer",
      valueType: "currency",
      readOnly: true,
      visible: true,
    },
    {
      key: "shareOfPremium",
      label: "Gross Premium Our Share - FOT",
      root: "layer",
      valueType: "currency",
      readOnly: true,
      visible: true,
    },
  ];

  const fieldData = buildDataFromFields(
    fields,
    layers,
    calculatedValues,
    programPricing
  );

  const renderCell = (layer, field) => {
    if (field.renderCell) {
      return field.renderCell({ layer });
    }

    const updateProgramPricing = (layerId, key, value) => {
      const updatedLayers = programPricing?.layers ?? {};
      const currentLayer = updatedLayers[layerId] ?? {};
      dispatch(
        programPricingSlice.update({
          layers: {
            ...updatedLayers,
            [layerId]: {
              ...currentLayer,
              [key]: value,
            },
          },
        })
      );
    };
    const updateLayer = (layerId, key, value) => {
      const values = {
        [key]: value,
      };
      if (key === "lineSize") {
        values.shareOfLine = null;
      }

      dispatch(
        pricingActions.updateLayer({
          layerId: layerId,
          values,
        })
      );
    };
    const value = fieldData[layer.id]?.[field.key] ?? null;
    if (field.readOnly) {
      if (value == null || value === "") {
        return <></>;
      } else if (field.valueType === "percentage") {
        return (
          <Tooltip title={value}>
            <Typography>{numbers.percentage(field.dp ?? 3)(value)}</Typography>
          </Tooltip>
        );
      } else if (field.valueType === "currency") {
        return (
          <Tooltip title={value}>
            <Typography>{numbers.currencyFormat(value)}</Typography>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={value}>
            <Typography>{numbers.dpString(field.dp ?? 0)(value)}</Typography>
          </Tooltip>
        );
      }
    }
    return (
      <Disabled ifReadOnly>
        <PrettyNumberTextField
          type={"text"}
          size={"small"}
          hiddenLabel
          suffix={field.valueType === "percentage" ? "%" : ""}
          prefix={field.valueType === "currency" ? "$" : ""}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            style: {
              textAlign: "right",
            },
          }}
          value={value}
          onChangeNumber={
            field.onChange === "updateLayer"
              ? (value) => updateLayer(layer.id, field.key, value)
              : (value) => updateProgramPricing(layer.id, field.key, value)
          }
          variant={"filled"}
          readOnly
        />
      </Disabled>
    );
  };

  return (
    <Component
      title={"Summary"}
      isFetching={isFetching}
      options={
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <MuiButton
              onClick={() => clearHiddenLayers()}
              variant={"text"}
              disabled={hiddenLayers.length === 0}
              sx={{
                m: 1,
                display: "flex",
                width: "150px",
                justifyContent: "flex-end",
                padding: "0 16px",
              }}
              endIcon={
                <VisibilityIcon
                  size={"m"}
                  color={hiddenLayers.length > 0 ? "primary" : "disabled"}
                />
              }
            >
              {hiddenLayers.length > 0 && (
                <Typography fontSize={"0.8rem"} sx={{ mr: 1 }}>
                  {hiddenLayers.length} {"hidden"}
                </Typography>
              )}
            </MuiButton>
          </Stack>
          <Disabled ifReadOnly>
            <Button onClick={refetch} isLoading={isFetching}>
              {"Calculate"}
            </Button>
          </Disabled>
          <ActionMenu
            showAdditionalInputs={showAdditionalInputs}
            setShowAdditionalInputs={setShowAdditionalInputs}
          />
        </Stack>
      }
    >
      <Stack direction={"column"} spacing={2}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align={"right"} width={200}></TableCell>
                <TransitionGroup component={null}>
                  {layers
                    .filter((layer) => !hiddenLayers.includes(layer.id))
                    .map((layer) => {
                      const errors = calculatedLayers[layer.id]?.error;
                      const errorCount = errors?.length || 0;
                      const errorMessage = errors
                        ? errors.map((error) => error.message).join(", ")
                        : "";

                      return (
                        <Fade
                          key={layer.id}
                          timeout={FADE_TIMEOUT}
                          appear={false}
                        >
                          <TableCell
                            align={"right"}
                            width={200}
                            sx={{
                              mt: 1,
                              "&:hover .hover-icons": {
                                visibility: "visible",
                              },
                            }}
                          >
                            <Tooltip title={layer.status}>
                              <Stack direction={"column"} spacing={0}>
                                <Stack
                                  direction={"row"}
                                  spacing={0}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                  sx={{
                                    ml: 1,
                                    minHeight: "40px",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => toggleHighlight(layer.id)}
                                    className={"hover-icons"}
                                    sx={{
                                      ml: 1,
                                      visibility: "hidden",
                                    }}
                                  >
                                    <StarIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => toggleHiddenLayer(layer.id)}
                                    className={"hover-icons"}
                                    sx={{
                                      ml: 1,
                                      visibility: "hidden",
                                    }}
                                  >
                                    <VisibilityOffIcon />
                                  </IconButton>
                                </Stack>
                                <Stack
                                  direction={"row"}
                                  spacing={1}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <StatusBadgeV2
                                    status={layer.status}
                                    variant={"icon"}
                                  />
                                  <Typography fontSize={"0.8rem"}>
                                    {utils.shortLayerName(layer)}
                                  </Typography>

                                  {/* Error Badge with Tooltip */}
                                  {errorCount > 0 && (
                                    <Stack
                                      direction={"row"}
                                      spacing={1}
                                      alignItems={"center"}
                                      justifyContent={"center"}
                                    >
                                      <Tooltip title={errorMessage}>
                                        <Badge
                                          badgeContent={errorCount}
                                          color={"error"}
                                        >
                                          <ErrorIcon
                                            variant={"outlined"}
                                            color={"error"}
                                          />
                                        </Badge>
                                      </Tooltip>
                                    </Stack>
                                  )}
                                </Stack>
                              </Stack>
                            </Tooltip>
                          </TableCell>
                        </Fade>
                      );
                    })}
                </TransitionGroup>
                <TableCell>{/* Extra space */}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align={"right"} sx={{ minWidth: "200px" }}>
                  <Typography fontSize={"0.7rem"}>{"Perils"}</Typography>
                </TableCell>
                <TransitionGroup component={null}>
                  {layers
                    .filter((layer) => !hiddenLayers.includes(layer.id))
                    .map((layer) => (
                      <Fade
                        key={layer.id}
                        timeout={FADE_TIMEOUT}
                        appear={false}
                      >
                        <TableCell
                          align={"right"}
                          style={getCellStyle(
                            layer.id,
                            highlightedLayers,
                            isAnyHighlighted
                          )}
                        >
                          <Perils layerId={layer.id} />
                        </TableCell>
                      </Fade>
                    ))}
                </TransitionGroup>
                <TableCell>{/* Extra space */}</TableCell>
              </TableRow>
              {fields
                .filter((field) => field.visible)
                .map((field, index) => (
                  <TableRow key={index}>
                    <TableCell align={"right"} sx={{ minWidth: "200px" }}>
                      <Typography fontSize={"0.7rem"}>{field.label}</Typography>
                    </TableCell>
                    <TransitionGroup component={null}>
                      {layers
                        .filter((layer) => !hiddenLayers.includes(layer.id))
                        .map((layer) => (
                          <Fade
                            key={layer.id}
                            timeout={FADE_TIMEOUT}
                            appear={false}
                          >
                            <TableCell
                              align={"right"}
                              style={{
                                ...getCellStyle(
                                  layer.id,
                                  highlightedLayers,
                                  isAnyHighlighted
                                ),
                              }}
                            >
                              {renderCell(layer, field)}
                            </TableCell>
                          </Fade>
                        ))}
                    </TransitionGroup>
                    <TableCell>{/* Extra space */}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Component>
  );
};

export default LayerPricing;
