import * as metaActions from "../../actions/meta/metaActions";
import * as persistenceActionTypes from "../../actions/persistence/persistenceActionTypes";

export const INITIAL_STATE = {
  deleted: false,
  pending: null,
  source: null,
};

const metaReducer = (state = INITIAL_STATE, action) => {
  if (state === undefined) {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.NEW_SUBMISSION:
    case persistenceActionTypes.RENEW_SUBMISSION:
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INITIAL_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INITIAL_STATE,
        ...action?.payload?.data?.state?.meta,
      };
    case metaActions.deleteSubmission.toString():
      return {
        ...state,
        deleted: true,
      };
    case metaActions.undeleteSubmission.toString():
      return {
        ...state,
        deleted: false,
      };
    case metaActions.acceptPendingSubmission.toString():
      return {
        ...state,
        deleted: false,
        pending: null,
      };
    default:
      return state;
  }
};

export default metaReducer;
