import * as aggregates from "./aggregates";
import { hasValue } from "./strings";
import * as logger from "common/logger";

export const METHOD = {
  ADD: "ADD",
  SUBTRACT: "SUBTRACT",
  MULTIPLY: "MULTIPLY",
  DIVIDE: "DIVIDE",
};

const logError = (text, context) => {
  if (!hasValue(context)) {
    context = "unspecified calling context";
  }
  logger.error(`${text} applying formula for ${context}`);
};

const indeterminateValue = 0;

const nonNumeric = (value) =>
  value == null || isNaN(value) || typeof value === "boolean";

export const calculate = (method, a, b, errorContext) => {
  if (!hasValue(method)) {
    logError("Missing method", errorContext);
    return indeterminateValue;
  }

  if (nonNumeric(a) || nonNumeric(b)) {
    return indeterminateValue;
  }

  a = parseFloat(a);
  b = parseFloat(b);

  if (method === METHOD.ADD) {
    return a + b;
  } else if (method === METHOD.SUBTRACT) {
    return a - b;
  } else if (method === METHOD.MULTIPLY) {
    return a * b;
  } else if (method === METHOD.DIVIDE) {
    return b === 0 ? Math.sign(a) * Infinity : a / b;
  }

  logError(`Unrecognised method '${method}'`, errorContext);
  return indeterminateValue;
};

export const validConfig = (config) => {
  if (config == null || typeof config !== "object") {
    return false;
  }
  if (!config.hasOwnProperty("method")) {
    return false;
  }
  const hasValues = config.hasOwnProperty("a") && config.hasOwnProperty("b");
  const hasKey = config.hasOwnProperty("key");
  return hasValues || hasKey;
};

const isObject = (value) =>
  value != null && typeof value === "object" && !Array.isArray(value);

export const apply = (
  config,
  objArray,
  errorContext,
  { fallback } = indeterminateValue
) => {
  if (!validConfig(config)) {
    logError("Missing or invalid config", errorContext);
    return fallback;
  }

  if (aggregates.isMethod(config.method)) {
    return aggregates.aggregatedByKey(
      config.method,
      config.key,
      objArray,
      errorContext
    );
  }

  const a = isObject(config.a)
    ? apply(config.a, objArray, errorContext, { fallback: null })
    : config.a;

  const b = isObject(config.b)
    ? apply(config.b, objArray, errorContext, { fallback: null })
    : config.b;

  return calculate(config.method, a, b, errorContext);
};
