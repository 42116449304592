import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Component from "components/Component";
import Button from "components/common/Button";
import FormattedDatePicker from "components/common/FormattedDatePicker";
import IconButton from "components/common/IconButton";
import PrettyNumberTextField from "components/common/PrettyNumberTextField";
import TextField from "components/common/TextField";
import PersonSelect from "components/inputs/program/PersonSelect";
import * as config from "config";
import { Fragment } from "react";
import { connect } from "react-redux";
import * as staticDataService from "services/staticDataService";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";

const ActiveLayerPremiumAdjustmentsEditor = ({
  title = "Active Layer Premium Adjustments",
  subtitle,
  error,
  layer,
  addPremiumAdjustment,
  editPremiumAdjustment,
  deletePremiumAdjustment,
  deletePremiumAdjustmentCustomOverrides,
}) => {
  const { data: brokers } = staticDataService.useListBrokersQuery();

  return (
    <Component title={title} subtitle={subtitle} error={error}>
      {(layer?.premiumAdjustments ?? []).map((premiumAdjustment, i) => {
        const setValue = (key, value) =>
          editPremiumAdjustment({
            layerId: layer.id,
            premiumAdjustmentId: premiumAdjustment.id,
            edits: {
              [key]: value,
            },
          });

        return (
          <Accordion key={i}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant={"subtitle1"}
                sx={{ width: "50%", flexShrink: 0 }}
              >
                {premiumAdjustment.type}
              </Typography>
              <Typography variant={"subtitle2"} sx={{ color: "$color-grey" }}>
                {premiumAdjustment.startDate}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={config.GRID_SPACING}>
                {premiumAdjustment.customOverrides && (
                  <Grid item xs={12}>
                    <Alert severity={"warning"}>
                      {"Custom overrides have been applied to the premium"}
                      {
                        "adjustment below. These will be reflected in the bordereau"
                      }
                      {"report but not in Marmalade."}
                      <br />
                      <br />
                      {Object.entries(premiumAdjustment.customOverrides).map(
                        ([k, v]) => (
                          <Fragment key={k}>
                            {k}
                            {":"}
                            <br />
                            {Object.entries(v).map(([k, v]) => (
                              <Fragment key={k}>
                                &nbsp;&nbsp;&nbsp;&nbsp;{k}
                                {": "}
                                {JSON.stringify(v)}
                                <br />
                              </Fragment>
                            ))}
                          </Fragment>
                        )
                      )}
                      <br />
                      <br />
                      <Button
                        variant={"contained"}
                        color={"error"}
                        onClick={() =>
                          deletePremiumAdjustmentCustomOverrides({
                            layerId: layer.id,
                            premiumAdjustmentId: premiumAdjustment.id,
                          })
                        }
                      >
                        {"Delete These Overrides"}
                      </Button>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl variant={"filled"} fullWidth>
                    <InputLabel>{"Type"}</InputLabel>
                    <Select
                      name={"type"}
                      value={premiumAdjustment.type ?? ""}
                      onChange={(e) => setValue("type", e.target.value)}
                      error={false}
                    >
                      <MenuItem value={"Additional Premium"}>
                        {"Additional Premium"}
                      </MenuItem>
                      <MenuItem value={"Returned Premium"}>
                        {"Returned Premium"}
                      </MenuItem>
                      <MenuItem value={"Installment"}>{"Installment"}</MenuItem>
                      <MenuItem value={""}>{"[Not Set]"}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormattedDatePicker
                    label={"Start Date"}
                    value={premiumAdjustment.startDate}
                    onChangeDate={(date) => setValue("startDate", date)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormattedDatePicker
                    label={"End Date"}
                    value={premiumAdjustment.endDate}
                    onChangeDate={(date) => setValue("endDate", date)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrettyNumberTextField
                    variant={"filled"}
                    label={"Gross Premium Change"}
                    InputLabelProps={{ shrink: true }}
                    value={premiumAdjustment.grossPremiumChange}
                    onChangeNumber={(v) => setValue("grossPremiumChange", v)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrettyNumberTextField
                    variant={"filled"}
                    label={"Share of Gross Premium Change"}
                    InputLabelProps={{ shrink: true }}
                    value={premiumAdjustment.shareOfGrossPremiumChange}
                    onChangeNumber={(v) =>
                      setValue("shareOfGrossPremiumChange", v)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <PrettyNumberTextField
                    variant={"filled"}
                    label={"Payment Terms (Days)"}
                    InputLabelProps={{ shrink: true }}
                    value={premiumAdjustment.paymentTerms?.days}
                    onChangeNumber={(v) =>
                      setValue("paymentTerms", v != null ? { days: v } : null)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormattedDatePicker
                    disabled
                    label={"Payment Terms (Due Date)"}
                    value={premiumAdjustment.paymentTerms?.dueDate}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PersonSelect
                    label={"Broker"}
                    person={premiumAdjustment.broker}
                    personList={brokers}
                    onUpdatePerson={(broker) => setValue("broker", broker)}
                    canCreateNewBroker={false}
                    showEdit={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={"Comments"}
                    value={premiumAdjustment.comments}
                    onChange={(e) => setValue("comments", e.target.value)}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
            <AccordionActions>
              <IconButton
                icon={DeleteIcon}
                onClick={() =>
                  deletePremiumAdjustment({
                    layerId: layer.id,
                    premiumAdjustmentId: premiumAdjustment.id,
                  })
                }
              />
            </AccordionActions>
          </Accordion>
        );
      })}
      <Grid container sx={{ marginTop: 2 }}>
        <Button onClick={() => addPremiumAdjustment({ layerId: layer.id })}>
          {"Add Adjustment"}
        </Button>
      </Grid>
    </Component>
  );
};

export const mapDispatchToProps = {
  addPremiumAdjustment: pricingActions.addPremiumAdjustment,
  editPremiumAdjustment: pricingActions.editPremiumAdjustment,
  deletePremiumAdjustment: pricingActions.deletePremiumAdjustment,
  deletePremiumAdjustmentCustomOverrides:
    pricingActions.deletePremiumAdjustmentCustomOverrides,
};

export const mapStateToProps = (state) => {
  return {
    layer: pricingSelectors.selectResolvedActiveLayer(state),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActiveLayerPremiumAdjustmentsEditor);
