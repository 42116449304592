import * as actionTypes from "../../../actions/input/expiringProgram/expiringProgramActionTypes";
import * as persistenceActionTypes from "../../../actions/persistence/persistenceActionTypes";

export const INIT_STATE = {
  layers: [],
};

export const expiringProgramReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }
  switch (action.type) {
    case persistenceActionTypes.LOAD_SUBMISSION_STARTED:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.LOAD_SUBMISSION_SUCCEEDED:
      return {
        ...INIT_STATE,
        ...action?.payload?.data?.state?.input?.expiringProgram,
      };
    case actionTypes.UPDATE_EXPIRING_PROGRAM_LIMIT:
      return {
        layers: [
          ...state.layers.slice(0, action.payload.index),
          {
            ...state.layers[action.payload.index],
            limit: action.payload.limit,
          },
          ...state.layers.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.UPDATE_EXPIRING_PROGRAM_CARRIER:
      return {
        layers: [
          ...state.layers.slice(0, action.payload.index),
          {
            ...state.layers[action.payload.index],
            carrier: action.payload.carrier,
          },
          ...state.layers.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.UPDATE_EXPIRING_PROGRAM_ATTACHMENT:
      return {
        layers: [
          ...state.layers.slice(0, action.payload.index),
          {
            ...state.layers[action.payload.index],
            attachment: action.payload.attachment,
          },
          ...state.layers.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.UPDATE_EXPIRING_PROGRAM_MARKET_GROSS_WRITTEN_PREMIUM:
      return {
        layers: [
          ...state.layers.slice(0, action.payload.index),
          {
            ...state.layers[action.payload.index],
            grossPremium: action.payload.grossPremium,
          },
          ...state.layers.slice(action.payload.index + 1),
        ],
      };
    case actionTypes.DELETE_EXPIRING_PROGRAM_LAYER:
      return {
        layers: [
          ...state.layers.slice(0, action.payload),
          ...state.layers.slice(action.payload + 1),
        ],
      };
    case persistenceActionTypes.NEW_SUBMISSION:
      return {
        ...INIT_STATE,
      };
    case persistenceActionTypes.RENEW_SUBMISSION:
      return {
        layers: action.payload.priorState?.pricing?.tower?.layers ?? [],
      };
    default:
      return state;
  }
};

export default expiringProgramReducer;
