import FreeformList from "./items/FreeformList";
import Toggle from "./items/Toggle";
import { Fragment } from "react";

const makeItemComponent = (item) => {
  switch (item?.type) {
    case "toggle":
      return Toggle;
    case "freeformList":
      return FreeformList;
    default:
      return Fragment;
  }
};

const Item = ({ itemKey, config }) => {
  const item = config?.items?.[itemKey];
  const ItemComponent = makeItemComponent(item);
  return <ItemComponent itemKey={itemKey} config={config} />;
};

export default Item;
