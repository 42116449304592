import Component from "components/Component";
import Options from "components/common/Options";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";

const componentDefns = [
  {
    key: "structure",
    name: "Structure",
  },
  {
    key: "commission",
    name: "Commission",
  },
  {
    key: "exposure",
    name: "Exposure",
  },
  {
    key: "uwJudgment",
    name: "UW Judgment",
  },
  {
    key: "inflation",
    name: "Inflation",
  },
];

const ComponentSelector = ({
  activeLayerRateChange,
  updateLayerRateChange,
}) => {
  const options = componentDefns.map((defn) => {
    return {
      id: defn.key,
      label: defn.name,
      selected: !activeLayerRateChange?.componentDisabled?.[defn.key],
    };
  });

  return (
    <Component
      title={"Components"}
      subtitle={"Select the commponents to include in the Rate Change calc."}
    >
      <Options
        items={options}
        onClick={({ id, selected }) =>
          updateLayerRateChange({
            componentDisabled: {
              ...activeLayerRateChange?.componentDisabled,
              [id]: selected,
            },
          })
        }
      />
    </Component>
  );
};

const mapStateToProps = (state) => {
  return {
    activeLayerRateChange: rateChangeSlice.selectActiveLayerRateChange(state),
  };
};

const mapDispatchToProps = {
  updateLayerRateChange: rateChangeSlice.updateLayerProperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComponentSelector);
