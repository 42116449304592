import { createAction } from "@reduxjs/toolkit";

export const deleteSubmission = createAction("persistence/deleteSubmission");

export const undeleteSubmission = createAction(
  "persistence/undeleteSubmission"
);

export const acceptPendingSubmission = createAction(
  "persistence/acceptPendingSubmission"
);
