import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as pricingActions from "store/actions/pricing/pricingActions";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as towerPricingSelectors from "store/selectors/pricing/tower/towerPricingSelectors";
import * as utils from "utils";

const NONE_KEY = "__NONE__";

const FollowedPolicySelector = ({ disabled }) => {
  const dispatch = useDispatch();

  const layer = useSelector(pricingSelectors.selectResolvedActiveLayer);

  const towerLayers =
    useSelector(towerPricingSelectors.selectResolvedTower)?.layers ?? [];

  const choices = towerLayers.flatMap((layer) =>
    (layer.shares ?? []).map((share) => ({
      key: share.id,
      name: `${utils.shortLayerName(layer)} - ${share.carrier}`,
    }))
  );

  return (
    <FormControl variant={"filled"} fullWidth>
      <InputLabel className={"input-label"}>{"Followed Policy"}</InputLabel>
      <Select
        value={layer?.followedPolicyTowerShareId || NONE_KEY}
        onChange={(event) => {
          const value = event?.target?.value || NONE_KEY;
          const followedPolicyTowerShareId = value === NONE_KEY ? null : value;
          dispatch(
            pricingActions.updateLayer({
              layerId: layer.id,
              values: { followedPolicyTowerShareId },
            })
          );
        }}
        disabled={disabled}
        fullWidth
      >
        <MenuItem value={NONE_KEY}>{"None"}</MenuItem>
        {(choices ?? []).map(({ key, name }) => (
          <MenuItem key={key} value={key}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FollowedPolicySelector;
