import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import GetAppIcon from "@mui/icons-material/GetApp";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeNewId } from "common/ids";
import Component from "components/Component";
import Button from "components/common/Button";
import DefaultLoader from "components/common/DefaultLoader";
import IconButton from "components/common/IconButton";
import * as config from "config";
import * as fileUtils from "fileUtils";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  BsFileEarmarkExcelFill as ExcelIcon,
  BsFileEarmarkWordFill as WordIcon,
  BsFillFileEarmarkFill as GenericFileIcon,
} from "react-icons/bs";
import { useParams } from "react-router-dom";
import {
  useLazyGetStaticDataQuery,
  useUpdateStaticDataMutation,
} from "services/adminService";

const templateIcon = (type) => {
  switch (type) {
    case "EXCEL":
      return <ExcelIcon />;
    case "WORD":
    case "WORD_JINJA":
      return <WordIcon />;
    default:
      return <GenericFileIcon />;
  }
};

const templateExtension = (type) => {
  switch (type) {
    case "EXCEL":
      return ".xlsx";
    case "WORD":
    case "WORD_JINJA":
      return ".docx";
    default:
      return ".bin";
  }
};

const EmailEditor = ({ email, setEmailParameter, deleteEmail }) => {
  return (
    <Card>
      <Stack direction={"column"} spacing={1} style={{ margin: 10 }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Name"}
            autoComplete={"off"}
            variant={"filled"}
            value={email?.name ?? ""}
            onChange={(e) => {
              setEmailParameter("name", e.target.value);
            }}
            fullWidth
          />
          {email?.name && (
            <IconButton icon={DeleteIcon} onClick={deleteEmail} />
          )}
        </Stack>
        <TextField
          type={"text"}
          InputLabelProps={{ shrink: true }}
          InputProps={{ spellCheck: "false" }}
          label={"Subject"}
          autoComplete={"off"}
          variant={"filled"}
          value={email?.subject ?? ""}
          onChange={(e) => {
            setEmailParameter("subject", e.target.value);
          }}
          fullWidth
        />
        <TextField
          type={"text"}
          InputLabelProps={{ shrink: true }}
          InputProps={{ spellCheck: "false" }}
          label={"Recipients"}
          autoComplete={"off"}
          variant={"filled"}
          value={email?.toEmails ?? ""}
          onChange={(e) => {
            setEmailParameter("toEmails", e.target.value);
          }}
          fullWidth
        />
        <TextField
          type={"text"}
          InputLabelProps={{ shrink: true }}
          InputProps={{ spellCheck: "false" }}
          label={"CC"}
          autoComplete={"off"}
          variant={"filled"}
          value={email?.ccEmails ?? ""}
          onChange={(e) => {
            setEmailParameter("ccEmails", e.target.value);
          }}
          fullWidth
        />
      </Stack>
    </Card>
  );
};

const EmailsEditor = ({ emails, setEmailParameter, deleteEmail }) => {
  const [editMode, setEditMode] = useState(false);
  return editMode ? (
    <Stack spacing={2} direction={"column"}>
      <Stack spacing={1} direction={"row"} alignItems={"center"}>
        <IconButton
          icon={ExpandLessIcon}
          onClick={() => {
            setEditMode(false);
          }}
        />
      </Stack>
      <Stack spacing={3} direction={"column"} style={{ marginLeft: 20 }}>
        {[...emails, {}].map((email, index) => (
          <EmailEditor
            email={email}
            key={index}
            setEmailParameter={setEmailParameter(index)}
            deleteEmail={() => {
              deleteEmail(index);
            }}
          />
        ))}
      </Stack>
    </Stack>
  ) : (
    <Stack spacing={1} direction={"row"} alignItems={"center"}>
      <IconButton
        icon={ExpandMoreIcon}
        onClick={() => {
          setEditMode(true);
        }}
      />
      <Typography style={{ marginLeft: 5 }}>
        {`Email Options: ${emails?.length || 0}`}
      </Typography>
    </Stack>
  );
};

const ActiveTemplate = ({ template, onSave, companyId }) => {
  const ext = templateExtension(template.form);
  const [active, setActive] = useState(template.active ?? true);
  const [title, setTitle] = useState(template.title ?? "Template");
  const [form, setForm] = useState(template.form ?? "EXCEL");
  const [filename, setFilename] = useState(
    template.filename ?? `Template${ext}`
  );
  const [emails, setEmails] = useState(
    (template?.allowedEmailTargets || []).map((email) => ({
      ...email,
      toEmails: (email?.toEmails || []).join(", "),
      ccEmails: (email?.ccEmails || []).join(", "),
    }))
  );
  const [reason, setReason] = useState("");
  const [fileId, setFileId] = useState(template.fileId);
  const [downloading, setDownloading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef(null);

  const download = async () => {
    setDownloading(true);
    await fileUtils.saveFile(
      template.fileId,
      `${template.title}${ext}`,
      companyId
    );
    setDownloading(false);
    setFileId(fileId);
  };

  const setEmailParameter = (index) => (parameter, value) => {
    let newEmails;
    if (index === emails.length) {
      newEmails = [
        ...emails,
        {
          id: makeNewId(),
          [parameter]: value,
        },
      ];
    } else {
      newEmails = [
        ...emails.slice(0, index),
        {
          ...emails?.[index],
          [parameter]: value,
        },
        ...emails.slice(index + 1, emails.length),
      ];
    }
    setEmails(
      newEmails.filter((email) => {
        return (
          email?.name ||
          email?.subject ||
          (email?.toEmails || []).length > 0 ||
          (email?.ccEmails || []).length > 0
        );
      })
    );
  };

  const deleteEmail = (index) => {
    setEmails(emails.filter((email, i) => index !== i));
  };

  const handleFileChange = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    const newFileId = await fileUtils.createFileEntry(
      file,
      false,
      null,
      companyId
    );
    setFileId(newFileId);
    setUploading(false);
  };

  return (
    <Card sx={{ padding: 1 }}>
      <input
        type={"file"}
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {templateIcon(template.form)}
            <Typography>
              {template.id ? `Id: ${template.id}` : "New Template"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Switch
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant={"filled"} size={"small"} sx={{ minWidth: 200 }}>
            <InputLabel>{"Type"}</InputLabel>
            <Select value={form} onChange={(e) => setForm(e.target.value)}>
              <MenuItem value={"EXCEL"}>{"Excel"}</MenuItem>
              <MenuItem value={"WORD"}>{"Word"}</MenuItem>
              <MenuItem value={"WORD_JINJA"}>{"Word (Jinja)"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Display Name"}
            autoComplete={"off"}
            variant={"filled"}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Output Filename"}
            autoComplete={"off"}
            variant={"filled"}
            value={filename}
            onChange={(e) => setFilename(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <EmailsEditor
            emails={emails}
            setEmailParameter={setEmailParameter}
            deleteEmail={deleteEmail}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type={"text"}
            InputLabelProps={{ shrink: true }}
            InputProps={{ spellCheck: "false" }}
            label={"Reason for change"}
            autoComplete={"off"}
            variant={"filled"}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={"row"} justifyContent={"flex-end"} spacing={2}>
            {fileId && <Typography>{`File Id: ${fileId}`}</Typography>}
            <Button
              onClick={() => fileInputRef.current?.click()}
              isLoading={uploading}
            >
              {"Upload Template"}
            </Button>
            {fileId && (
              <Button onClick={() => download()} isLoading={downloading}>
                {"Download Template"}
              </Button>
            )}
            <Button
              onClick={() =>
                onSave({
                  template: {
                    ...template,
                    active,
                    title,
                    form,
                    filename,
                    fileId,
                    allowedEmailTargets: emails.map((email) => ({
                      ...email,
                      toEmails: email.toEmails.split(/[\s, ]+/),
                      ccEmails: email.ccEmails.split(/[\s, ]+/),
                    })),
                  },
                  userLogMessage: reason,
                })
              }
              isDisabled={config.ENVIRONMENT !== "dev" && reason?.length < 4}
            >
              {template.id ? "Save" : "Create"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

const DisplayTemplate = ({ template, onDoubleClick, companyId }) => {
  const ext = templateExtension(template.form);
  const [downloading, setDownloading] = useState(false);

  const download = async () => {
    setDownloading(true);
    await fileUtils.saveFile(
      template.fileId,
      `${template.title}${ext}`,
      companyId
    );
    setDownloading(false);
  };

  return (
    <Card sx={{ padding: 1 }}>
      <Grid
        container
        spacing={2}
        alignItems={"center"}
        onDoubleClick={onDoubleClick}
      >
        <Grid item xs={11}>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {templateIcon(template.form)}
            <Typography>{`Display Name: ${template.title}`}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <Stack direction={"row"} justifyContent={"flex-end"}>
            <Switch checked={template.active ?? true} />
            {template.fileId &&
              (downloading ? (
                <Box sx={{ padding: "5px 8px" }}>
                  <DefaultLoader color={"#dc7f4c"} size={24} />
                </Box>
              ) : (
                <IconButton
                  onClick={async () => download()}
                  icon={GetAppIcon}
                />
              ))}
          </Stack>
        </Grid>

        {template.filename && (
          <Grid item xs={12}>
            <Typography>{`Filename: ${template.filename}`}</Typography>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

const updateEntry = (data, entry) => {
  if (entry.id == null) {
    return [
      ...(data ?? []),
      {
        ...entry,
        id: makeNewId(),
      },
    ];
  }
  return (data ?? []).map((e) => (e.id === entry.id ? entry : e));
};

const TemplateEditor = () => {
  const { companyId } = useParams();

  const [
    loadTemplates,
    { data: templates, isLoading },
  ] = useLazyGetStaticDataQuery();
  const [update, { isLoading: isUpdating }] = useUpdateStaticDataMutation();
  const [activeTemplate, setActiveTemplate] = useState(null);
  const refresh = useCallback(() => {
    loadTemplates({ companyId, key: "file_templates" });
    setActiveTemplate(null);
  }, [loadTemplates, setActiveTemplate, companyId]);
  const onSave = ({ template, userLogMessage }) => {
    update({
      companyId,
      key: "file_templates",
      data: updateEntry(templates ?? [], template),
      userLogMessage,
    }).then(() => {
      refresh();
    });
  };
  useEffect(() => {
    if (companyId != null) {
      refresh();
    }
  }, [companyId, refresh]);

  return (
    <>
      <Component
        title={"Submission Templates"}
        loading={isLoading || isUpdating}
      >
        {companyId && (
          <Grid container spacing={2}>
            {(templates ?? []).map((template) => (
              <Grid item xs={12} key={template.id}>
                {activeTemplate?.id === template.id ? (
                  <ActiveTemplate
                    template={activeTemplate}
                    onSave={onSave}
                    companyId={companyId}
                  />
                ) : (
                  <DisplayTemplate
                    template={template}
                    onDoubleClick={() => {
                      setActiveTemplate(template);
                    }}
                    companyId={companyId}
                  />
                )}
              </Grid>
            ))}
            <Grid item xs={12}>
              <ActiveTemplate
                template={{
                  form: "EXCEL",
                  active: true,
                  title: "New Template",
                  filename: "New Template.xlsx",
                }}
                companyId={companyId}
                onSave={onSave}
              />
            </Grid>
          </Grid>
        )}
      </Component>
    </>
  );
};

export default TemplateEditor;
