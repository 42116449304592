import SelectedIcon from "@mui/icons-material/Check";
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import {
  Box,
  Button,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

const config = [
  {
    min: 0,
    max: 0,
    label: "Not Specified",
    colour: "gray",
  },
  {
    min: 1,
    max: 19,
    default: 10,
    label: "Unusable",
    colour: "crimson",
  },
  {
    min: 20,
    max: 39,
    label: "Poor",
    colour: "darkorange",
  },
  {
    min: 40,
    max: 59,
    label: "Acceptable",
    colour: "gold",
    textColour: "goldenrod",
  },
  {
    min: 60,
    max: 79,
    label: "Good",
    colour: "mediumseagreen",
  },
  {
    min: 80,
    max: 100,
    label: "Excellent",
    colour: "dodgerblue",
  },
];

const buttonConfig = config
  .filter((x) => x.min !== 0)
  .sort((a, b) => b.min - a.min);

const getConfig = (value) =>
  config.filter((x) => value >= x.min && value <= x.max)[0];

const getDefault = (value) => {
  const config = getConfig(value);
  return config.default || config.min;
};

const ScoreSelector = ({ initialValue, onChange, disabled, label }) => {
  const [value, setValue] = useState(initialValue || 0);
  const updateValue = (newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <Box>
      {label && (
        <Typography variant={"caption"} style={{ marginBottom: "2px" }}>
          {label}
        </Typography>
      )}
      <FormControl fullWidth size={"small"} disabled={disabled}>
        <Select value={value} onChange={(e) => updateValue(e.target.value)}>
          <MenuItem value={0}>
            <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
              <CircleIcon fontSize={"small"} sx={{ color: "#edf0f3" }} />
              <Typography>{"Not Specified"}</Typography>
            </Stack>
          </MenuItem>
          <Divider />
          {buttonConfig.map((score) => {
            return (
              <MenuItem value={score.default || score.min} key={score.label}>
                <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                  <CircleIcon fontSize={"small"} sx={{ color: score.colour }} />
                  <Typography>{score.label}</Typography>
                </Stack>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

const ScoreButtons = ({ initialValue, onChange, disabled, label }) => {
  const [value, setValue] = useState(initialValue || 0);
  const updateValue = (newValue) => {
    const current = getDefault(value);
    newValue = newValue === current && value !== 0 ? 0 : newValue;
    setValue(newValue);
    onChange(newValue);
  };

  const BlankIcon = () => <Box sx={{ width: "20px" }}></Box>;

  return (
    <Stack>
      {buttonConfig.map((score) => {
        const defaultValue = score.default || score.min;
        const isActive = value >= score.min && value <= score.max;

        return (
          <Button
            key={score.label}
            variant={isActive ? "outlined" : null}
            sx={{ color: "black", textTransform: "unset" }}
            startIcon={<CircleIcon sx={{ color: score.colour }} />}
            endIcon={isActive ? <SelectedIcon /> : <BlankIcon />}
            disabled={disabled}
            onClick={() => {
              updateValue(defaultValue);
            }}
          >
            <Typography width={100} textAlign={"left"} fontWeight={500}>
              {score.label}
            </Typography>
          </Button>
        );
      })}
    </Stack>
  );
};

const ProgressBar = ({ value, colour }) => {
  const container = {
    position: "relative",
    marginTop: "5px",
    height: "6px",
    width: "100%",
    borderRadius: "3px",
    background: "#edf0f3",
  };

  const bar = {
    position: "absolute",
    left: 0,
    width: `${value}%`,
    height: "6px",
    borderRadius: "3px",
    background: colour,
  };

  return (
    <Box sx={container}>
      <Box sx={bar} />
    </Box>
  );
};

const Summary = ({ value }) => {
  value = value || 0;
  const config = getConfig(value);

  return (
    <Stack spacing={0}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          sx={{ color: config.textColour || config.colour }}
          fontWeight={"600"}
        >
          {config.label}
        </Typography>
        <Typography variant={"overline"} sx={{ lineHeight: 1 }} color={"gray"}>
          {value * 10}
        </Typography>
      </Stack>
      <ProgressBar value={value} colour={config.colour} />
    </Stack>
  );
};

const DataScore = ({ initialValue, onChange, variant, disabled, label }) => {
  const buttons = (variant || "").trim().toLowerCase() === "buttons";
  const summary = (variant || "").trim().toLowerCase() === "summary";

  if (buttons) {
    return (
      <ScoreButtons
        initialValue={initialValue}
        onChange={onChange}
        disabled={disabled}
        label={label}
      />
    );
  }

  if (summary) {
    return <Summary value={initialValue} />;
  }

  return (
    <ScoreSelector
      initialValue={initialValue}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default DataScore;
