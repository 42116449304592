import * as companyActionTypes from "../../../actions/company/companyActionTypes";

export const INIT_STATE = {
  categoryCodeVisible: false,
};

const flagsReducer = (state, action) => {
  if (state === undefined) {
    state = INIT_STATE;
  }

  switch (action.type) {
    case companyActionTypes.HAS_CODE_CATEGORY_VALUES:
      return {
        ...state,
        categoryCodeVisible: action.payload,
      };
    default:
      return state;
  }
};

export default flagsReducer;
