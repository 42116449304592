import LayerSelector from "./LayerSelector";
import * as _ from "lodash";
import { connect } from "react-redux";
import * as rateChangeSlice from "store/rateChange";
import * as pricingSelectors from "store/selectors/pricing/pricingSelectors";
import * as stateSelectors from "store/selectors/stateSelectors";

const mapStateToProps = (state) => {
  const priorState = stateSelectors.selectPriorState(state);
  return {
    title: "Comparison Layer",
    subtitle: "Select a layer from the previous program.",
    loading: _.isEmpty(priorState),
    layers: pricingSelectors.selectResolvedLayers(priorState),
    selectedId: rateChangeSlice.selectActiveLayerRateChange(state)
      ?.selectedPriorLayerId,
  };
};

const mapDispatchToProps = {
  onSelectId: rateChangeSlice.updateLayerPropertiesItem("selectedPriorLayerId"),
};

export default connect(mapStateToProps, mapDispatchToProps)(LayerSelector);
